// DO NOT EDIT - code generated from strings.ts.tpl.

import { visor } from '../protos';

// LocalizedStrings are type-safe keys to LocalizedStrings.

// "Add your Card"
export const addPaymentMethodContinueButton = "Add your Card"
// "Select a Payment Method"
export const addPaymentMethodHeader = "Select a Payment Method"
// "Save Card"
export const addPaymentMethodSaveCard = "Save Card"
// "Enter payment details:"
export const addPaymentMethodTitle = "Enter payment details:"
// "Please add your city."
export const addressFormErrorReasonEmptyCity = "Please add your city."
// "Please add your state."
export const addressFormErrorReasonEmptyState = "Please add your state."
// "Please add your street address."
export const addressFormErrorReasonEmptyStreet = "Please add your street address."
// "Please add your zip code."
export const addressFormErrorReasonEmptyZip = "Please add your zip code."
// "The state you entered is not a valid state abbreviation."
export const addressFormErrorReasonInvalidState = "The state you entered is not a valid state abbreviation."
// "Please enter a valid zip code."
export const addressFormErrorReasonInvalidZip = "Please enter a valid zip code."
// "Ship my Grid Card to a different address"
export const addressInputFormLegalAddressCheckBoxTitle = "Ship my Grid Card to a different address"
// "We need a valid address to verify your identity."
export const addressInputFormLegalAddressSubtitle = "We need a valid address to verify your identity."
// "What’s your address?"
export const addressInputFormLegalAddressTitle = "What’s your address?"
// "This is where your Grid Card will be sent."
export const addressInputFormMailingAddressSubtitle = "This is where your Grid Card will be sent."
// "What’s your shipping address?"
export const addressInputFormMailingAddressTitle = "What’s your shipping address?"
// "Update"
export const addressInputFormMailingAddressUpdateCTA = "Update"
// "Make Payment"
export const advanceCTATitleOverdueDeliveredTile = "Make Payment"
// "Get upgraded! After Level 10, you’ll be invited to Credit Line, where you can unlock up to $3,000."
export const advanceLevelsExplanationFooter = "Get upgraded! After Level 10, you’ll be invited to Credit Line, where you can unlock up to $3,000."
// "Level up every time you repay your Advance early or on time"
export const advanceLevelsExplanationSubtitle = "Level up every time you repay your Advance early or on time"
// "Cash on demand"
export const advanceMeetAdvanceSubtitle = "Cash on demand"
// "Meet Advance"
export const advanceMeetAdvanceTitle = "Meet Advance"
// "💸"
export const advanceMeetTileCashAdvanceEmoji = "💸"
// "Unlimited cash"
// "advances"
export const advanceMeetTileCashAdvanceTitle = "Unlimited cash\u000Aadvances"
// "🧾"
export const advanceMeetTileHiddenFeesEmoji = "🧾"
// "No hidden fees"
// "or “tips“"
export const advanceMeetTileHiddenFeesTitle = "No hidden fees\u000Aor “tips“"
// "levels program"
export const advanceMeetTileLevelsProgramButtonText = "levels program"
// "🌟"
export const advanceMeetTileLevelsProgramEmoji = "🌟"
// "Get up to $200 with our"
export const advanceMeetTileLevelsProgramTitle = "Get up to $200 with our"
// "💳"
export const advanceMeetTitleNoCreditCheckEmoji = "💳"
// "No credit check"
export const advanceMeetTitleNoCreditCheckTitle = "No credit check"
// "You have access to a bigger Cash Advance because you paid the last one back early"
export const advanceNewLevelSubtitle = "You have access to a bigger Cash Advance because you paid the last one back early"
// "Moving on up!"
export const advanceNewLevelTitle = "Moving on up!"
// "Get an unlimited number of advances and other amazing features with Grid+"
export const advanceOnboardingDashboardBanner = "Get an unlimited number of advances and other amazing features with Grid+"
// "Advance Now"
export const advanceOnboardingDashboardCTATitle = "Advance Now"
// "Get an Advance"
export const advanceOnboardingDashboardTitle = "Get an Advance"
// "Done"
export const advanceOnboardingFastAndDeliveryScreenCTATitle = "Done"
// ""
export const advanceOnboardingFastAndDeliveryScreenFastBannerSubtitle = ""
// ""
export const advanceOnboardingFastAndDeliveryScreenFastBannerTitle = ""
// "Fast Delivery"
// "{{amount}}"
export const advanceOnboardingFastAndDeliveryScreenFastBox = "Fast Delivery\u000A{{amount}}"
// ""
export const advanceOnboardingFastAndDeliveryScreenStandardBannerSubtitle = ""
// ""
export const advanceOnboardingFastAndDeliveryScreenStandardBannerTitle = ""
// "Standard Delivery"
// "FREE"
export const advanceOnboardingFastAndDeliveryScreenStandardBox = "Standard Delivery\u000AFREE"
// "{{amount}} Advance"
export const advanceOnboardingFastAndDeliveryScreenTitle = "{{amount}} Advance"
// "{{amount}} Advance"
export const advanceOnboardingFastAndDeliveryScreenYourOfferBannerContentA = "{{amount}} Advance"
// "Repayment Date: {{date}}"
export const advanceOnboardingFastAndDeliveryScreenYourOfferBannerContentB = "Repayment Date: {{date}}"
// "YOUR OFFER"
export const advanceOnboardingFastAndDeliveryScreenYourOfferBannerTitle = "YOUR OFFER"
// "Unlimited Grid Advances"
export const advanceOnboardingSummaryScreenIncludesBox1 = "Unlimited Grid Advances"
// "PayBoost"
export const advanceOnboardingSummaryScreenIncludesBox2 = "PayBoost"
// "Free tax projection"
export const advanceOnboardingSummaryScreenIncludesBox3 = "Free tax projection"
// "Repayment Snooze"
export const advanceOnboardingSummaryScreenIncludesBox4A = "Repayment Snooze"
// "(coming soon)"
export const advanceOnboardingSummaryScreenIncludesBox4B = "(coming soon)"
// "Grid Card"
export const advanceOnboardingSummaryScreenIncludesBox5 = "Grid Card"
// "Your Grid+ subscription includes:"
export const advanceOnboardingSummaryScreenIncludesBoxTitle = "Your Grid+ subscription includes:"
// "Sign up for Grid+ to unlock your Advance!"
export const advanceOnboardingSummaryScreenLabelA = "Sign up for Grid+ to unlock your Advance!"
// "{{amount}} Cash Advance"
export const advanceOnboardingSummaryScreenLabelB = "{{amount}} Cash Advance"
// "Overview"
export const advanceOnboardingSummaryScreenOverviewTitle = "Overview"
// "By checking this box I understand and agree to recurring monthly payments of my subscription fee of $10.00. These payments will be automatically deducted from my account unless I cancel my subscription prior to the beginning of the next billing period."
export const advanceOnboardingSummaryScreenTermsAndConsA = "By checking this box I understand and agree to recurring monthly payments of my subscription fee of $10.00. These payments will be automatically deducted from my account unless I cancel my subscription prior to the beginning of the next billing period."
// "We cannot cancel after you confirm. By confirming, you agree to the Grid+ Terms and Stripe Connect Terms, and authorize Grid to withdraw $52.99 from your next paycheck or bank account on or after your next payday."
export const advanceOnboardingSummaryScreenTermsAndConsB = "We cannot cancel after you confirm. By confirming, you agree to the Grid+ Terms and Stripe Connect Terms, and authorize Grid to withdraw $52.99 from your next paycheck or bank account on or after your next payday."
// "We cannot cancel after you confirm."
export const advanceOnboardingSummaryScreenTermsAndConsBBold = "We cannot cancel after you confirm."
// "By confirming, you agree to the Grid+ Terms and Stripe Connect Terms, and authorize Grid to withdraw $52.99 from your next paycheck or bank account on or after your next payday."
export const advanceOnboardingSummaryScreenTermsAndConsC = "By confirming, you agree to the Grid+ Terms and Stripe Connect Terms, and authorize Grid to withdraw $52.99 from your next paycheck or bank account on or after your next payday."
// "Continue"
export const advanceOnboardingSummaryScreenTermsAndConsCTATitle = "Continue"
// "https://getgrid.app/terms"
export const advanceOnboardingSummaryScreenTermsAndConsLinkA = "https://getgrid.app/terms"
// "Grid+ Terms"
export const advanceOnboardingSummaryScreenTermsAndConsLinkAText = "Grid+ Terms"
// "https://stripe.com/connect-account/legal"
export const advanceOnboardingSummaryScreenTermsAndConsLinkB = "https://stripe.com/connect-account/legal"
// "Stripe Connect Terms"
export const advanceOnboardingSummaryScreenTermsAndConsLinkBText = "Stripe Connect Terms"
// "Success! Your bank account"
// "is connected"
export const advanceOnboardingWorkflowBankAccountAdded = "Success! Your bank account\u000Ais connected"
// "Success! Your debit card"
// "is verified"
export const advanceOnboardingWorkflowDebitCardAdded = "Success! Your debit card\u000Ais verified"
// "Continue"
export const advanceOnboardingWorkflowEnterDebitCardCTATitle = "Continue"
// "Enter your {{account}} debit card"
// "info to receive your payout."
export const advanceOnboardingWorkflowEnterDebitCardTitle = "Enter your {{account}} debit card\u000Ainfo to receive your payout."
// "Unlimited cash advances"
export const advanceOnboardingWorkflowHIWBoxA = "Unlimited cash advances"
// "No credit check"
export const advanceOnboardingWorkflowHIWBoxB = "No credit check"
// "No hidden fees or “tips”"
export const advanceOnboardingWorkflowHIWBoxC = "No hidden fees or “tips”"
// "Get up to $200 with our levels program"
export const advanceOnboardingWorkflowHIWBoxD = "Get up to $200 with our levels program"
// "Continue"
export const advanceOnboardingWorkflowHIWCTATitle = "Continue"
// "Cash on demand"
export const advanceOnboardingWorkflowHIWSubitle = "Cash on demand"
// "Meet Advance"
export const advanceOnboardingWorkflowHIWTitle = "Meet Advance"
// "Three simple steps to get your Advance"
export const advanceOnboardingWorkflowStepsForYourAdvanceCTATitle = "Three simple steps to get your Advance"
// "Connect your bank via Plaid to verify consistent spending and deposits."
export const advanceOnboardingWorkflowStepsForYourAdvanceLabelA = "Connect your bank via Plaid to verify consistent spending and deposits."
// "Link your debit card associated with your connected bank account."
export const advanceOnboardingWorkflowStepsForYourAdvanceLabelB = "Link your debit card associated with your connected bank account."
// "Verify your phone number, legal name, date of birth, home address and SSN."
export const advanceOnboardingWorkflowStepsForYourAdvanceLabelC = "Verify your phone number, legal name, date of birth, home address and SSN."
// "Three simple steps to get your Advance"
export const advanceOnboardingWorkflowStepsForYourAdvanceTitle = "Three simple steps to get your Advance"
// "This should take about 10 seconds…"
export const advanceOnboardingWorkflowVerifyingBankAccountSubtitle = "This should take about 10 seconds…"
// "We’re verifying your account"
export const advanceOnboardingWorkflowVerifyingBankAccountTitle = "We’re verifying your account"
// "This should take about 10 seconds…"
export const advanceOnboardingWorkflowVerifyingDebitCardSubtitle = "This should take about 10 seconds…"
// "We’re verifying your debit card"
export const advanceOnboardingWorkflowVerifyingDebitCardTitle = "We’re verifying your debit card"
// "Arriving {{date}} to {{bank}}"
export const advanceOnboardingYourOfferScreenBoxASubtitle = "Arriving {{date}} to {{bank}}"
// "{{amount}} Advance"
export const advanceOnboardingYourOfferScreenBoxATitle = "{{amount}} Advance"
// "Auto Payback"
export const advanceOnboardingYourOfferScreenBoxBSubitle = "Auto Payback"
// "{{date}}"
export const advanceOnboardingYourOfferScreenBoxBTitle = "{{date}}"
// "Continue"
export const advanceOnboardingYourOfferScreenCTATitle = "Continue"
// "Your Advance offer:"
export const advanceOnboardingYourOfferScreenTitle = "Your Advance offer:"
// "Repay your advance so you can have access to future advances."
export const advanceOverdueBannerSubtitle = "Repay your advance so you can have access to future advances."
// "Your payment is overdue"
export const advanceOverdueBannerTitle = "Your payment is overdue"
// "Continue"
export const advancePlaidContinueButton = "Continue"
// "Three simple steps to"
// "get your Advance"
export const advancePlaidTitle = "Three simple steps to\u000Aget your Advance"
// "Something Went Wrong"
export const advanceSomethingWentWrongTitle = "Something Went Wrong"
// "Confirm"
export const advanceSubsequentBottomSheetCTAText = "Confirm"
// "There’s an issue with your bank account."
export const advanceTileAccountErrorBankAccountMsg = "There’s an issue with your bank account."
// "Account Error"
export const advanceTileAccountErrorBankAccountTitle = "Account Error"
// "There’s an issue with the debit card you provided."
export const advanceTileAccountErrorDebitCardMsg = "There’s an issue with the debit card you provided."
// "Account Error"
export const advanceTileAccountErrorDebitCardTitle = "Account Error"
// "Sorry, there was an issue with your payout."
// "We will reach out to you shortly."
export const advanceTileAccountErrorPayoutExceptionMsg = "Sorry, there was an issue with your payout.\u000AWe will reach out to you shortly."
// "Account Error"
export const advanceTileAccountErrorPayoutExceptionTitle = "Account Error"
// "We couldn’t verify your ID"
export const advanceTileAccountErrorVerifyIdTitle = "We couldn’t verify your ID"
// "Hang Tight!"
export const advanceTileCooldownCaption = "Hang Tight!"
// "Your bank may take up to 3 business days to send your payment, then you can get another advance."
export const advanceTileCooldownTitle = "Your bank may take up to 3 business days to send your payment, then you can get another advance."
// "Need Help?"
export const advanceTileDisqualifiedButton = "Need Help?"
// "Not available"
export const advanceTileDisqualifiedCaption = "Not available"
// "You don’t currently qualify for Grid Advance"
export const advanceTileDisqualifiedTitle = "You don’t currently qualify for Grid Advance"
// "Need Help?"
export const advanceTileErrorButton = "Need Help?"
// "Not available"
export const advanceTileErrorCaption = "Not available"
// "Grid Advance is coming soon"
export const advanceTileErrorTitle = "Grid Advance is coming soon"
// "Pay back early to gain levels"
export const advanceTileLevelsText = "Pay back early to gain levels"
// "Pay Now"
export const advanceTilePaymentArrivingButton = "Pay Now"
// "It should arrive by {{date}}."
export const advanceTilePaymentArrivingDeliveryCaption = "It should arrive by {{date}}."
// "It should arrive within 8 hours."
export const advanceTilePaymentArrivingFastArrivalEst = "It should arrive within 8 hours."
// "Your advance is on the way!"
export const advanceTilePaymentArrivingTitle = "Your advance is on the way!"
// "{{amt}} auto-pay {{date}}"
export const advanceTilePaymentAutopayDate = "{{amt}} auto-pay {{date}}"
// "Pay Now"
export const advanceTilePaymentDeliveredButton = "Pay Now"
// "Your advance has"
// "been delivered!"
export const advanceTilePaymentDeliveredTitle = "Your advance has\u000Abeen delivered!"
// "Advance"
// "history"
export const advanceTilePaymentHistoryText = "Advance\u000Ahistory"
// "Your advance is taking longer than expected"
export const advanceTileProcessingDelayBanner = "Your advance is taking longer than expected"
// "Your advance is processing…"
export const advanceTileProcessingTitle = "Your advance is processing…"
// "Get Another Advance"
export const advanceTileSubsequentButton = "Get Another Advance"
// "Ready for your next advance?"
export const advanceTileSubsequentCaption = "Ready for your next advance?"
// "With Grid+, you can get an advance when you need it"
export const advanceTileSubsequentTitle = "With Grid+, you can get an advance when you need it"
// "Your progress has been converted into a Credit Line"
export const advanceUnlockCreditLineSubtitle = "Your progress has been converted into a Credit Line"
// "You’ve unlocked Credit Line!"
export const advanceUnlockCreditLineTitle = "You’ve unlocked Credit Line!"
// "Unlock your Grid app"
export const appLockAuthReason = "Unlock your Grid app"
// "Logout"
export const appLockLabelButtonLogout = "Logout"
// "{{biometry_type}}"
export const appLockLabelButtonUnlock = "{{biometry_type}}"
// "Error {{error}}"
export const appLockLabelStatusError = "Error {{error}}"
// "Setting up"
export const appLockLabelStatusInitializing = "Setting up"
// "Locked"
export const appLockLabelStatusLocked = "Locked"
// "NEW"
export const appLockLabelStatusNew = "NEW"
// "UNLOCKED!"
export const appLockLabelStatusUnlocked = "UNLOCKED!"
// "Unlocking…"
export const appLockLabelStatusUnlocking = "Unlocking…"
// "Tap to rate"
export const appRatingRatingInstructions = "Tap to rate"
// "Write your review"
export const appRatingReviewInstructions = "Write your review"
// "Send"
export const appRatingSendButton = "Send"
// "Not now"
export const appRatingSkipButton = "Not now"
// "Thank you for your feedback"
export const appRatingThankYouPopupTitle = "Thank you for your feedback"
// "What can we do better?"
export const appRatingTitle = "What can we do better?"
// "Please come back in a little bit and try again. Thanks!"
export const appUnavailableMessage = "Please come back in a little bit and try again. Thanks!"
// "We’ll be right back."
export const appUnavailableTitle = "We’ll be right back."
// "Account Number"
export const bankAccountRowAccountNumber = "Account Number"
// "Bank Name"
export const bankAccountRowInstitutionName = "Bank Name"
// "This bank is set as your default"
export const bankAccountRowIsDefault = "This bank is set as your default"
// "One moment, please…"
export const bannersDefaultLoadingText = "One moment, please…"
// "Resend"
export const bannersEmailVerificationBannerAction = "Resend"
// "We’re sending an email to your inbox. Just a moment, please."
export const bannersEmailVerificationBannerLoadingText = "We’re sending an email to your inbox. Just a moment, please."
// "Please check your email for a verification link."
export const bannersEmailVerificationBannerMessage = "Please check your email for a verification link."
// "Verify your email"
export const bannersEmailVerificationBannerTitle = "Verify your email"
// "Fix"
export const bannersGridPlusSubscriptionOverdueAction = "Fix"
// "Your subscription is overdue."
export const bannersGridPlusSubscriptionOverdueMessage = "Your subscription is overdue."
// "PAYMENT DUE"
export const bannersGridPlusSubscriptionOverdueTitle = "PAYMENT DUE"
// "Update"
export const bannersPayrollIssueAction = "Update"
// "Please update your payroll account credentials."
export const bannersPayrollIssueMessage = "Please update your payroll account credentials."
// "Issue With Payroll Connection"
export const bannersPayrollIssueTitle = "Issue With Payroll Connection"
// "Begin"
export const bannersTwoFAAction = "Begin"
// "Begin two factor enrollment"
export const bannersTwoFAMessage = "Begin two factor enrollment"
// "Secure your account"
export const bannersTwoFATitle = "Secure your account"
// "We will update your W-4 with your payroll provider for you in the next few days. You should see your PayBoost by your next pay period!"
export const bannersW4AdjustmentAdpW4AppliedForClientMessage = "We will update your W-4 with your payroll provider for you in the next few days. You should see your PayBoost by your next pay period!"
// "Please deliver your W-4 to HR or update your tax withholding via your payroll provider."
export const bannersW4AdjustmentAdpW4Message = "Please deliver your W-4 to HR or update your tax withholding via your payroll provider."
// "Dismiss"
export const bannersW4AdjustmentDismiss = "Dismiss"
// "PayBoost in Progress"
export const bannersW4AdjustmentTitle = "PayBoost in Progress"
// "We need 15 minutes to calculate your PayBoost. We’ll let you know when it’s ready."
export const bannersWorkflowStepWaitForBoostInProgressMessage = "We need 15 minutes to calculate your PayBoost. We’ll let you know when it’s ready."
// "RUNNING NUMBERS…"
export const bannersWorkflowStepWaitForBoostInProgressTitle = "RUNNING NUMBERS…"
// "Begin"
export const bannersWorkflowStepWaitForBoostReadyAction = "Begin"
// "Your updated PayBoost is ready for review!"
export const bannersWorkflowStepWaitForBoostReadyMessage = "Your updated PayBoost is ready for review!"
// "READY!"
export const bannersWorkflowStepWaitForBoostReadyTitle = "READY!"
// "Bogus"
export const bogusKey = "Bogus"
// "Enroll"
export const boostAutoDeliveryEnrollCTA = "Enroll"
// "Get your tax refund delivery guaranteed by your next payday."
export const boostAutoDeliveryEnrollmentMessage = "Get your tax refund delivery guaranteed by your next payday."
// "Enroll in Auto Delivery"
export const boostAutoDeliveryEnrollmentTitle = "Enroll in Auto Delivery"
// "• Get your tax refund sooner"
export const boostAutoDeliveryInfoBenefits1 = "• Get your tax refund sooner"
// "• Earn 2x rewards"
export const boostAutoDeliveryInfoBenefits2 = "• Earn 2x rewards"
// "• Build credit"
export const boostAutoDeliveryInfoBenefits3 = "• Build credit"
// "• Unlock a $3,000 credit line"
export const boostAutoDeliveryInfoBenefits4 = "• Unlock a $3,000 credit line"
// "Card benefits:"
export const boostAutoDeliveryInfoBenefitsTitle = "Card benefits:"
// "Get Started"
export const boostAutoDeliveryInfoCardCTA = "Get Started"
// "Enroll in Auto Delivery"
export const boostAutoDeliveryInfoEnrollCTA = "Enroll in Auto Delivery"
// "We’ll handle the processing for you and deposit your tax refund onto your Grid Card."
export const boostAutoDeliveryInfoSubtitle = "We’ll handle the processing for you and deposit your tax refund onto your Grid Card."
// "Get your {{amt}} tax refund by your next payday, guaranteed."
export const boostAutoDeliveryInfoTitle = "Get your {{amt}} tax refund by your next payday, guaranteed."
// "We’re connecting to your payroll account. Your {{amt}} refund will be deposited onto your Grid Card by your next payday."
export const boostAutoDeliveryProcessingSubtitle = "We’re connecting to your payroll account. Your {{amt}} refund will be deposited onto your Grid Card by your next payday."
// "Processing your refund…"
export const boostAutoDeliveryProcessingTitle = "Processing your refund…"
// "Your Grid Card account is closed so this action is no longer available."
export const boostCardAccountClosedError = "Your Grid Card account is closed so this action is no longer available."
// "Your account must meet all the requirements get Grid Card."
export const boostCardAccountRequirementsBody = "Your account must meet all the requirements get Grid Card."
// "300 days active"
export const boostCardAccountRequirementsCriteria60Days = "300 days active"
// "Connectivity to Grid"
export const boostCardAccountRequirementsCriteriaActiveAccount = "Connectivity to Grid"
// "Consistent deposits"
export const boostCardAccountRequirementsCriteriaMinimum1KDeposits = "Consistent deposits"
// "Consistent spending"
export const boostCardAccountRequirementsCriteriaMinimumSpending = "Consistent spending"
// "Connect a different account"
export const boostCardAccountRequirementsCta = "Connect a different account"
// "BANK ACCOUNT REQUIREMENTS"
export const boostCardAccountRequirementsHeader = "BANK ACCOUNT REQUIREMENTS"
// "Bank account"
export const boostCardAccountRequirementsTitle = "Bank account"
// "Activate"
export const boostCardActivateCheckYourMailTileCTA = "Activate"
// "Once you receive your physical card, activate it to start using it."
export const boostCardActivateCheckYourMailTileSubtitle = "Once you receive your physical card, activate it to start using it."
// "Check your mail"
export const boostCardActivateCheckYourMailTileTitle = "Check your mail"
// "Get Help"
export const boostCardActivateDeliveryIssueTileCTA = "Get Help"
// "Please reach out to Support for more help on your card."
export const boostCardActivateDeliveryIssueTileSubtitle = "Please reach out to Support for more help on your card."
// "Delivery Issue"
export const boostCardActivateDeliveryIssueTileTitle = "Delivery Issue"
// "Order Card"
export const boostCardActivateOrderCardTileCTA = "Order Card"
// "Your old card was reported lost or stolen, and has been deactivated."
export const boostCardActivateOrderCardTileSubtitle = "Your old card was reported lost or stolen, and has been deactivated."
// "Order Card"
export const boostCardActivateOrderCardTileTitle = "Order Card"
// "Add it to your Wallet to pay in stores, anywhere Apple Pay is accepted."
export const boostCardActivatedAppleScreenSubtitle = "Add it to your Wallet to pay in stores, anywhere Apple Pay is accepted."
// "Your physical card is activated!"
export const boostCardActivatedAppleScreenTitle = "Your physical card is activated!"
// "Add it to your Wallet to pay in stores, anywhere Google Pay is accepted."
export const boostCardActivatedGoogleScreenSubtitle = "Add it to your Wallet to pay in stores, anywhere Google Pay is accepted."
// "Your physical card is activated!"
export const boostCardActivatedGoogleScreenTitle = "Your physical card is activated!"
// "What are the last 4 digits of the card you got in the mail?"
export const boostCardActivation4DigitsScreenSubtitle = "What are the last 4 digits of the card you got in the mail?"
// "This is the 3-digit number on the back of your physical card."
export const boostCardActivationCVVScreenSubtitle = "This is the 3-digit number on the back of your physical card."
// "What is the 3-digit CVV?"
export const boostCardActivationCVVScreenTitle = "What is the 3-digit CVV?"
// "Confirm your PIN"
export const boostCardActivationConfirmPINScreenTitle = "Confirm your PIN"
// "Physical Card Activation"
export const boostCardActivationScreenTitle = "Physical Card Activation"
// "This is the pin that will be used at the ATM."
export const boostCardActivationSetPINScreenSubtitle = "This is the pin that will be used at the ATM."
// "Set a 4-digit PIN for your card"
export const boostCardActivationSetPINScreenTitle = "Set a 4-digit PIN for your card"
// "Please reconnect your bank account to your Grid Card"
export const boostCardBankAccountDataNotAvailable = "Please reconnect your bank account to your Grid Card"
// "Close"
export const boostCardBankingAccountButtonTitle = "Close"
// "ACCOUNT"
export const boostCardBankingAccountDetailsAccountNumber = "ACCOUNT"
// "Just update your direct deposit details with your Grid account info below:"
export const boostCardBankingAccountDetailsDirectDepositContent = "Just update your direct deposit details with your Grid account info below:"
// "ROUTING"
export const boostCardBankingAccountDetailsRoutingNumber = "ROUTING"
// "Your Grid account details"
export const boostCardBankingAccountDetailsTitle = "Your Grid account details"
// "Account Numbers"
export const boostCardBankingAccountNumbers = "Account Numbers"
// "Move Money"
export const boostCardBankingActionsMoveMoney = "Move Money"
// "More actions"
export const boostCardBankingActionsTitle = "More actions"
// "This is where your card will be mailed."
export const boostCardBankingAddressSubtitle = "This is where your card will be mailed."
// "Street Address"
export const boostCardBankingAddressTextFieldPrompt = "Street Address"
// "Add Money"
export const boostCardBankingAmountViewAddMoneyCTA = "Add Money"
// "Account Balance"
export const boostCardBankingAmountViewTitle = "Account Balance"
// "Add payment method"
export const boostCardBankingCheckoutAddPaymentMethodCTA = "Add payment method"
// "Confirm Order"
export const boostCardBankingCheckoutCTA = "Confirm Order"
// "YOUR CARD"
export const boostCardBankingCheckoutCardTypeHeader = "YOUR CARD"
// "Ivory Grid+ Card"
export const boostCardBankingCheckoutCardTypeIvory = "Ivory Grid+ Card"
// "Change"
export const boostCardBankingCheckoutChangePaymentMethodCTA = "Change"
// "DELIVERY ADDRESS"
export const boostCardBankingCheckoutDeliveryAddressHeader = "DELIVERY ADDRESS"
// "Please add a payment method to continue."
export const boostCardBankingCheckoutErrorNoPaymentMethodContent = "Please add a payment method to continue."
// "One more thing…"
export const boostCardBankingCheckoutErrorNoPaymentMethodTitle = "One more thing…"
// "Please repay your advance to continue."
export const boostCardBankingCheckoutErrorOutstandingAdvance = "Please repay your advance to continue."
// "Your Order"
export const boostCardBankingCheckoutHeader = "Your Order"
// "This could take up to a minute"
export const boostCardBankingCheckoutLoadingContent = "This could take up to a minute"
// "Preparing your card order…"
export const boostCardBankingCheckoutLoadingTitle = "Preparing your card order…"
// "{{amt}} overdue"
export const boostCardBankingCheckoutOutstandingAdvanceAmount = "{{amt}} overdue"
// "Cash Advance"
export const boostCardBankingCheckoutOutstandingAdvanceContent = "Cash Advance"
// "Grid Advance"
export const boostCardBankingCheckoutOutstandingAdvanceHeader = "Grid Advance"
// "Retry"
export const boostCardBankingCheckoutRetryButtonTitle = "Retry"
// "Included"
export const boostCardBankingCheckoutSubscriptionExisting = "Included"
// "Existing Member"
export const boostCardBankingCheckoutSubscriptionExistingMember = "Existing Member"
// "$0 / month"
export const boostCardBankingCheckoutSubscriptionGridFree = "$0 / month"
// "$10 / month"
export const boostCardBankingCheckoutSubscriptionGridPlus = "$10 / month"
// "SUBSCRIPTION"
export const boostCardBankingCheckoutSubscriptionHeader = "SUBSCRIPTION"
// "New Member"
export const boostCardBankingCheckoutSubscriptionNewMember = "New Member"
// "$10 overdue"
export const boostCardBankingCheckoutSubscriptionOverdue = "$10 overdue"
// "By opening a credit card on Grid, you agree to Synapse‘s Consumer Cardholder Agreement"
export const boostCardBankingCheckoutTermsCardholderAgreementSection = "By opening a credit card on Grid, you agree to Synapse‘s Consumer Cardholder Agreement"
// "Cardholder Agreement"
export const boostCardBankingCheckoutTermsCardholderAgreementSectionBoldQuery = "Cardholder Agreement"
// "By accepting the terms of this loan on Grid, you agree to Synapse’s Consumer Loan Agreement"
export const boostCardBankingCheckoutTermsConsumerLoanAgreementSection = "By accepting the terms of this loan on Grid, you agree to Synapse’s Consumer Loan Agreement"
// "Consumer Loan Agreement"
export const boostCardBankingCheckoutTermsConsumerLoanAgreementSectionBoldQuery = "Consumer Loan Agreement"
// "I Agree"
export const boostCardBankingCheckoutTermsContinueCTA = "I Agree"
// "By opening a deposit account on Grid, you agree to Synapse’s Deposit Agreement"
export const boostCardBankingCheckoutTermsDepositAgreementSection = "By opening a deposit account on Grid, you agree to Synapse’s Deposit Agreement"
// "Deposit Agreement"
export const boostCardBankingCheckoutTermsDepositAgreementSectionBoldQuery = "Deposit Agreement"
// "The bank services are provided by Synapse‘s partner banks, Members FDIC."
export const boostCardBankingCheckoutTermsHeaderSection = "The bank services are provided by Synapse‘s partner banks, Members FDIC."
// "Terms of Service"
export const boostCardBankingCheckoutTermsTitle = "Terms of Service"
// "Add Money"
export const boostCardBankingDepositMoney = "Add Money"
// "Who was contacted (provide name)?"
export const boostCardBankingDisputesCancelingRecurringChargeContactPerson = "Who was contacted (provide name)?"
// "Date recurring charge was cancelled"
export const boostCardBankingDisputesCancelingRecurringChargeDateCancelled = "Date recurring charge was cancelled"
// "Already cancelled recurring charge"
export const boostCardBankingDisputesCancelingRecurringChargeTitle = "Already cancelled recurring charge"
// "How was the vendor contacted?"
export const boostCardBankingDisputesCancelingRecurringChargeVendorContactMethod = "How was the vendor contacted?"
// "What was the response you received?"
export const boostCardBankingDisputesCancelingRecurringChargeVendorResponse = "What was the response you received?"
// "Date card was lost/stolen?"
export const boostCardBankingDisputesCardLostOrStolenDateLostOrStolen = "Date card was lost/stolen?"
// "How was it lost/stolen?"
export const boostCardBankingDisputesCardLostOrStolenReason = "How was it lost/stolen?"
// "Card lost/stolen"
export const boostCardBankingDisputesCardLostOrStolenTitle = "Card lost/stolen"
// "Please review your responses and submit your dispute."
export const boostCardBankingDisputesConfirmationScreenTitle = "Please review your responses and submit your dispute."
// "Upload image of both transactions"
export const boostCardBankingDisputesCreditNotProcessedDuplicateAttachDisputeTransactionFiles = "Upload image of both transactions"
// "Were both transactions on the same card?"
export const boostCardBankingDisputesCreditNotProcessedDuplicateBothTransactionsSameCard = "Were both transactions on the same card?"
// "Was the merchant given a chance to resolve?"
export const boostCardBankingDisputesCreditNotProcessedDuplicateMerchantGivenChanceToResolve = "Was the merchant given a chance to resolve?"
// "Credit not processed - duplicate transaction"
export const boostCardBankingDisputesCreditNotProcessedDuplicateTitle = "Credit not processed - duplicate transaction"
// "Upload image of both transactions"
export const boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansAttachDisputeTransactionFiles = "Upload image of both transactions"
// "Were both transactions on the same card?"
export const boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansBothTransactionsSameCard = "Were both transactions on the same card?"
// "Was the merchant given a chance to resolve?"
export const boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansMerchantGivenChanceToResolve = "Was the merchant given a chance to resolve?"
// "Credit not processed - paid by other means"
export const boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansTitle = "Credit not processed - paid by other means"
// "Damaged merchandise"
export const boostCardBankingDisputesDamagedMerchandise = "Damaged merchandise"
// "Method used to contact the merchant"
export const boostCardBankingDisputesDamagedMerchandiseContactMethod = "Method used to contact the merchant"
// "Describe the damage of the service / merchandise and the merchant’s response."
export const boostCardBankingDisputesDamagedMerchandiseDescription = "Describe the damage of the service / merchandise and the merchant’s response."
// "Name of contact at the merchant"
export const boostCardBankingDisputesDamagedMerchandiseMerchantName = "Name of contact at the merchant"
// "Contact date with merchant"
export const boostCardBankingDisputesDidNotReceiveMerchandiseContactDate = "Contact date with merchant"
// "Method used to contact the merchant"
export const boostCardBankingDisputesDidNotReceiveMerchandiseContactMethod = "Method used to contact the merchant"
// "Name of contact at the merchant"
export const boostCardBankingDisputesDidNotReceiveMerchandiseContactName = "Name of contact at the merchant"
// "Was the item delivered?"
export const boostCardBankingDisputesDidNotReceiveMerchandiseItemDelivered = "Was the item delivered?"
// "Response from the merchant"
export const boostCardBankingDisputesDidNotReceiveMerchandiseMerchantResponse = "Response from the merchant"
// "Was the order cancelled?"
export const boostCardBankingDisputesDidNotReceiveMerchandiseOrderCancelled = "Was the order cancelled?"
// "When was the order expected to arrive?"
export const boostCardBankingDisputesDidNotReceiveMerchandiseOrderExpectedArrival = "When was the order expected to arrive?"
// "Was the order expected to arrive"
export const boostCardBankingDisputesDidNotReceiveMerchandiseOrderExpectedArrivalDate = "Was the order expected to arrive"
// "Was item a prepaid item?"
export const boostCardBankingDisputesDidNotReceiveMerchandisePrepaidItem = "Was item a prepaid item?"
// "Did not receive merchandise"
export const boostCardBankingDisputesDidNotReceiveMerchandiseTitle = "Did not receive merchandise"
// "Was the item delivered?"
export const boostCardBankingDisputesDidNotReceiveMerchandiseWasItemDelivered = "Was the item delivered?"
// "Was the item a prepaid item?"
export const boostCardBankingDisputesDidNotReceiveMerchandiseWasItemPrepaid = "Was the item a prepaid item?"
// "Was the order canceled?"
export const boostCardBankingDisputesDidNotReceiveMerchandiseWasOrderCanceled = "Was the order canceled?"
// "When was the order canceled?"
export const boostCardBankingDisputesDidNotReceiveMerchandiseWhenWasOrderCanceled = "When was the order canceled?"
// "I am disputing this charge for the following reason:"
export const boostCardBankingDisputesGenericDisputesDescription = "I am disputing this charge for the following reason:"
// "Transaction charged twice."
export const boostCardBankingDisputesGenericDisputesTransactionChargedTwice = "Transaction charged twice."
// "Unauthorized charge."
export const boostCardBankingDisputesGenericDisputesUnauthorizedCharge = "Unauthorized charge."
// "Submit Dispute"
export const boostCardBankingDisputesHeaderTitle = "Submit Dispute"
// "What was the authorized amount?"
export const boostCardBankingDisputesIncorrectAmountAuthorizedAmount = "What was the authorized amount?"
// "Incorrect amount"
export const boostCardBankingDisputesIncorrectAmountTitle = "Incorrect amount"
// "What is your reason for disputing this charge?"
export const boostCardBankingDisputesInitialTitle = "What is your reason for disputing this charge?"
// "Date merchant was contacted"
export const boostCardBankingDisputesMerchantContactDate = "Date merchant was contacted"
// "Name of contact"
export const boostCardBankingDisputesMerchantContactName = "Name of contact"
// "Method used to contact the merchant"
export const boostCardBankingDisputesMerchantMethodUsedToContactMerchant = "Method used to contact the merchant"
// "Response from the merchant"
export const boostCardBankingDisputesMerchantResponse = "Response from the merchant"
// "Quality of services / merchandise"
export const boostCardBankingDisputesQualityOfServicesOrMerchandise = "Quality of services / merchandise"
// "Method used to contact the merchant"
export const boostCardBankingDisputesQualityOfServicesOrMerchandiseContactMethod = "Method used to contact the merchant"
// "Describe the quality issue of the service / merchandise and the merchant’s response"
export const boostCardBankingDisputesQualityOfServicesOrMerchandiseDescription = "Describe the quality issue of the service / merchandise and the merchant’s response"
// "Name of contact at the merchant"
export const boostCardBankingDisputesQualityOfServicesOrMerchandiseMerchantName = "Name of contact at the merchant"
// "Method used to contact the merchant"
export const boostCardBankingDisputesReturnedMerchandiseContactMethod = "Method used to contact the merchant"
// "Describe what happened to the service / merchandise"
export const boostCardBankingDisputesReturnedMerchandiseDescription = "Describe what happened to the service / merchandise"
// "Please provide a description of the item"
export const boostCardBankingDisputesReturnedMerchandiseItemDescription = "Please provide a description of the item"
// "Name of contact at the merchant"
export const boostCardBankingDisputesReturnedMerchandiseMerchantName = "Name of contact at the merchant"
// "What was the reason for return or cancellation?"
export const boostCardBankingDisputesReturnedMerchandiseReasonForReturnOrCancellation = "What was the reason for return or cancellation?"
// "Returned merchandise"
export const boostCardBankingDisputesReturnedMerchandiseTitle = "Returned merchandise"
// "Was a cancellation policy provided?"
export const boostCardBankingDisputesReturnedMerchandiseWasACancellationPolicyProvided = "Was a cancellation policy provided?"
// "Was the merchant given the attempt to resolve the issue?"
export const boostCardBankingDisputesReturnedMerchandiseWasMerchantGivenChanceToResolve = "Was the merchant given the attempt to resolve the issue?"
// "Was the order canceled?"
export const boostCardBankingDisputesReturnedMerchandiseWasOrderCanceled = "Was the order canceled?"
// "Was this a recurring payment?"
export const boostCardBankingDisputesReturnedMerchandiseWasRecurringPayment = "Was this a recurring payment?"
// "Was merchandise returned?"
export const boostCardBankingDisputesReturnedMerchandiseWasReturned = "Was merchandise returned?"
// "When was the order canceled?"
export const boostCardBankingDisputesReturnedMerchandiseWhenWasOrderCanceled = "When was the order canceled?"
// "When did you contact to cancel the return payment?"
export const boostCardBankingDisputesReturnedWhenDidYouContactToCancelTheReturnPayment = "When did you contact to cancel the return payment?"
// "When was the recurring payment cancelled?"
export const boostCardBankingDisputesReturnedWhenWasTheRecurringPaymentCanceled = "When was the recurring payment cancelled?"
// "Submit"
export const boostCardBankingDisputesSubformCTA = "Submit"
// "Submit Dispute"
export const boostCardBankingDisputesSubmitCTATitle = "Submit Dispute"
// "Close"
export const boostCardBankingDisputesSuccessCTATitle = "Close"
// "You will receive a text message from our banking partner Synapse within 2 business days regarding your dispute. It may take up to 10 business days to process your provisional credit."
export const boostCardBankingDisputesSuccessContent = "You will receive a text message from our banking partner Synapse within 2 business days regarding your dispute. It may take up to 10 business days to process your provisional credit."
// "Your dispute has been submitted."
export const boostCardBankingDisputesSuccessTitle = "Your dispute has been submitted."
// "Next"
export const boostCardBankingDisputesTopLevelFormCTA = "Next"
// "Were both transactions on the same card?"
export const boostCardBankingDisputesTransactionChargedTwiceBothTransactionsSameCard = "Were both transactions on the same card?"
// "Was the merchant given a chance to resolve?"
export const boostCardBankingDisputesTransactionChargedTwiceMerchantGivenChanceToResolve = "Was the merchant given a chance to resolve?"
// "Transaction charged twice"
export const boostCardBankingDisputesTransactionChargedTwiceTitle = "Transaction charged twice"
// "I am disputing this charge because there was a duplicate charge."
export const boostCardBankingDisputesTransactionChargedTwiceTitleNoFieldsDescription = "I am disputing this charge because there was a duplicate charge."
// "Please confirm your dispute before submitting:"
export const boostCardBankingDisputesTransactionChargedTwiceTitleNoFieldsTitle = "Please confirm your dispute before submitting:"
// "Upload image"
export const boostCardBankingDisputesTransactionChargedTwiceUploadImage = "Upload image"
// "Unauthorized charge"
export const boostCardBankingDisputesUnauthorizedCharge = "Unauthorized charge"
// "I am disputing this charge because it was an unauthorized transaction that was made while I had my card in possession."
export const boostCardBankingDisputesUnauthorizedChargeNoFieldsDescription = "I am disputing this charge because it was an unauthorized transaction that was made while I had my card in possession."
// "Please review your dispute before submitting:"
export const boostCardBankingDisputesUnauthorizedChargeNoFieldsTitle = "Please review your dispute before submitting:"
// "Upload Image"
export const boostCardBankingDisputesUploadQuestionCTA = "Upload Image"
// "Upload image:"
export const boostCardBankingDisputesUploadQuestionTitle = "Upload image:"
// "No"
export const boostCardBankingDisputesYesNoQuestionNo = "No"
// "Yes"
export const boostCardBankingDisputesYesNoQuestionYes = "Yes"
// "Get started"
export const boostCardBankingInterstitialCTA = "Get started"
// "Get rewards for all your purchases and all of your friend’s purchases."
export const boostCardBankingInterstitialCarouselTileBoostCardContent = "Get rewards for all your purchases and all of your friend’s purchases."
// "Step 1"
export const boostCardBankingInterstitialCarouselTileBoostCardStepOne = "Step 1"
// "Use the Grid Card for your everyday purchases"
export const boostCardBankingInterstitialCarouselTileBoostCardStepOneContent = "Use the Grid Card for your everyday purchases"
// "Setup Direct Deposit"
export const boostCardBankingInterstitialCarouselTileBoostCardStepOneTitle = "Setup Direct Deposit"
// "Step 3"
export const boostCardBankingInterstitialCarouselTileBoostCardStepThree = "Step 3"
// "Grid reports payments to credit bureaus to build your credit"
export const boostCardBankingInterstitialCarouselTileBoostCardStepThreeContent = "Grid reports payments to credit bureaus to build your credit"
// "Build your credit to 750+"
export const boostCardBankingInterstitialCarouselTileBoostCardStepThreeTitle = "Build your credit to 750+"
// "Step 2"
export const boostCardBankingInterstitialCarouselTileBoostCardStepTwo = "Step 2"
// "Earn cash rewards every time you swipe"
export const boostCardBankingInterstitialCarouselTileBoostCardStepTwoContent = "Earn cash rewards every time you swipe"
// "Collect $40 every month"
export const boostCardBankingInterstitialCarouselTileBoostCardStepTwoTitle = "Collect $40 every month"
// "Grid Card"
export const boostCardBankingInterstitialCarouselTileBoostCardTitle = "Grid Card"
// "Get on-demand cash advances whenever you need them."
export const boostCardBankingInterstitialCarouselTileCashboostContent = "Get on-demand cash advances whenever you need them."
// "Grid Advance"
export const boostCardBankingInterstitialCarouselTileCashboostTitle = "Grid Advance"
// "Get part of your yearly tax refund on every paycheck."
export const boostCardBankingInterstitialCarouselTilePayboostContent = "Get part of your yearly tax refund on every paycheck."
// "PayBoost"
export const boostCardBankingInterstitialCarouselTilePayboostTitle = "PayBoost"
// "No more black-box, find out how much of a refund you‘ll get and file via the app."
export const boostCardBankingInterstitialCarouselTileTaxFilingContent = "No more black-box, find out how much of a refund you‘ll get and file via the app."
// "Tax Filing"
export const boostCardBankingInterstitialCarouselTileTaxFilingTitle = "Tax Filing"
// "How does Grid Card work?"
export const boostCardBankingInterstitialHowTitle = "How does Grid Card work?"
// "$10/month"
export const boostCardBankingInterstitialSubtitle = "$10/month"
// "Grid Card is included with a Grid+ subscription"
export const boostCardBankingInterstitialTitle = "Grid Card is included with a Grid+ subscription"
// "Linked Accounts"
export const boostCardBankingLinkedAccounts = "Linked Accounts"
// "Continue to Checkout"
export const boostCardBankingQualificationApprovedCTA = "Continue to Checkout"
// "You’re approved for a Grid+ credit card, the only card that lets you build credit and earn points with every swipe."
export const boostCardBankingQualificationApprovedContent = "You’re approved for a Grid+ credit card, the only card that lets you build credit and earn points with every swipe."
// "Congratulations, you’re approved!"
export const boostCardBankingQualificationApprovedTitle = "Congratulations, you’re approved!"
// "better credit."
export const boostCardBankingQualificationApprovedTitleBottom = "better credit."
// "One step closer to"
export const boostCardBankingQualificationApprovedTitleTop = "One step closer to"
// "We are working hard to expand our offering and will give you an update when we’re ready for you."
export const boostCardBankingQualificationBlockedContent = "We are working hard to expand our offering and will give you an update when we’re ready for you."
// "You don’t currently qualify for a Grid Card"
export const boostCardBankingQualificationBlockedTitle = "You don’t currently qualify for a Grid Card"
// "We’re experiencing issues with our banking partner and cannot setup your card. We’re aware of the issue and are working hard to get you your card ASAP. We’ll reach out shortly."
export const boostCardBankingQualificationProductBlockedContent = "We’re experiencing issues with our banking partner and cannot setup your card. We’re aware of the issue and are working hard to get you your card ASAP. We’ll reach out shortly."
// "Setup Error"
export const boostCardBankingQualificationProductBlockedTitle = "Setup Error"
// "Continue"
export const boostCardBankingSetupCompleteCTA = "Continue"
// "Your order is confirmed! Your card will arrive in 1-2 weeks."
export const boostCardBankingSetupCompleteTitle = "Your order is confirmed! Your card will arrive in 1-2 weeks."
// "Dispute charge"
export const boostCardBankingTransactionDisputeCta = "Dispute charge"
// "Other issue"
export const boostCardBankingTransactionOtherCta = "Other issue"
// "How can we help with this transaction?"
export const boostCardBankingTransactionProblemContentTitle = "How can we help with this transaction?"
// "Help"
export const boostCardBankingTransactionProblemTitle = "Help"
// "Unknown transaction"
export const boostCardBankingTransactionUnknownTransactionCta = "Unknown transaction"
// "PENDING"
export const boostCardBankingTransactionsListPendingSectionTitle = "PENDING"
// "{{amt}} Balance"
export const boostCardBankingTransactionsListTitle = "{{amt}} Balance"
// "Transactions"
export const boostCardBankingTransactionsTileHeader = "Transactions"
// "TODAY"
export const boostCardBankingTransactionsTileHeaderToday = "TODAY"
// "YESTERDAY"
export const boostCardBankingTransactionsTileHeaderYesterday = "YESTERDAY"
// "No transactions yet."
export const boostCardBankingTransactionsTileNoHistory = "No transactions yet."
// "View all"
export const boostCardBankingTransactionsTileSeeAllCTA = "View all"
// "Temporary error, some transactions may be missing. Try again later."
export const boostCardBankingTransactionsTileUnhandledTransactions = "Temporary error, some transactions may be missing. Try again later."
// "Amount"
export const boostCardBankingTransferAmountTitle = "Amount"
// "Enter amount"
export const boostCardBankingTransferAmountTitlePrompt = "Enter amount"
// "{{balance}} available"
export const boostCardBankingTransferBalanceAvailable = "{{balance}} available"
// "Grid Card"
export const boostCardBankingTransferBoostCardTitle = "Grid Card"
// "Review Transfer"
export const boostCardBankingTransferCTA = "Review Transfer"
// "Choose Account"
export const boostCardBankingTransferChooseAccountTitle = "Choose Account"
// "Funds will be available in up to 6 business days."
export const boostCardBankingTransferConfirmAvailabilityContent = "Funds will be available in up to 6 business days."
// "Estimated availability"
export const boostCardBankingTransferConfirmAvailabilityTitle = "Estimated availability"
// "Confirm Transfer"
export const boostCardBankingTransferConfirmCTA = "Confirm Transfer"
// "Confirm Transfer"
export const boostCardBankingTransferConfirmTitle = "Confirm Transfer"
// "Connect bank account"
export const boostCardBankingTransferConnectBankAccountTitle = "Connect bank account"
// "Credit Line"
export const boostCardBankingTransferCreditLineTitle = "Credit Line"
// "Your transfer didn’t go through. Please try again."
export const boostCardBankingTransferErrorMessageGenericFailBody = "Your transfer didn’t go through. Please try again."
// "Transfer failed"
export const boostCardBankingTransferErrorMessageGenericFailTitle = "Transfer failed"
// "Please enter an amount to transfer."
export const boostCardBankingTransferErrorMessageNoAmount = "Please enter an amount to transfer."
// "Please enter a transfer frequency."
export const boostCardBankingTransferErrorMessageNoFrequency = "Please enter a transfer frequency."
// "Please enter an account that will receive the money."
export const boostCardBankingTransferErrorMessageNoReceivingAccount = "Please enter an account that will receive the money."
// "Please enter an account to send the transfer from."
export const boostCardBankingTransferErrorMessageNoSendingAccount = "Please enter an account to send the transfer from."
// "Relink"
export const boostCardBankingTransferFixAccountTitle = "Relink"
// "Frequency"
export const boostCardBankingTransferFrequencyTitle = "Frequency"
// "From"
export const boostCardBankingTransferFromTitle = "From"
// "Ways to add money"
export const boostCardBankingTransferIntroDepositTitle = "Ways to add money"
// "Bank transfer"
export const boostCardBankingTransferIntroMethodBankTransfer = "Bank transfer"
// "1-3 business days · Free"
export const boostCardBankingTransferIntroMethodBankTransferSubtitle = "1-3 business days · Free"
// "Credit line"
export const boostCardBankingTransferIntroMethodCreditLine = "Credit line"
// "Available credit: {{available_credit}}"
export const boostCardBankingTransferIntroMethodCreditLineSubtitle = "Available credit: {{available_credit}}"
// "Direct deposit"
export const boostCardBankingTransferIntroMethodDirectDeposit = "Direct deposit"
// "Free"
export const boostCardBankingTransferIntroMethodDirectDepositSubtitle = "Free"
// "Bank transfer"
export const boostCardBankingTransferIntroMethodTransferMoneyOut = "Bank transfer"
// "Withdraw money"
export const boostCardBankingTransferIntroWithdrawalTitle = "Withdraw money"
// "By tapping confirm you are authorizing us to initiate a debit on your behalf from your {{account_name}} account for {{amt}} on {{date}}."
export const boostCardBankingTransferOnceTerms = "By tapping confirm you are authorizing us to initiate a debit on your behalf from your {{account_name}} account for {{amt}} on {{date}}."
// "By tapping confirm you are authorizing us to debit your {{account_name}} account for {{amt}} on a(n) {{frequency}} basis on {{date}}. This authorization will remain in effect unless we receive notice of cancellation. You may edit or cancel this recurring authorization at any time."
export const boostCardBankingTransferRecurringTerms = "By tapping confirm you are authorizing us to debit your {{account_name}} account for {{amt}} on a(n) {{frequency}} basis on {{date}}. This authorization will remain in effect unless we receive notice of cancellation. You may edit or cancel this recurring authorization at any time."
// "Transfer"
export const boostCardBankingTransferTitle = "Transfer"
// "To"
export const boostCardBankingTransferToTitle = "To"
// "Withdraw Money"
export const boostCardBankingWithdrawMoney = "Withdraw Money"
// "CVV {{cvv}}"
export const boostCardCVV = "CVV {{cvv}}"
// "XXX"
export const boostCardCVVHidden = "XXX"
// "Activate"
export const boostCardCardActivationActivateTileCTA = "Activate"
// "Sent {{ordinal_date}} ago"
export const boostCardCardActivationActivateTileContent = "Sent {{ordinal_date}} ago"
// "Card shipped!"
export const boostCardCardActivationActivateTileTitle = "Card shipped!"
// "Get Help"
export const boostCardCardActivationCanceledTileCTA = "Get Help"
// "Please reach out to Support for more help on your card."
export const boostCardCardActivationCanceledTileContent = "Please reach out to Support for more help on your card."
// "Delivery Issue"
export const boostCardCardActivationCanceledTileTitle = "Delivery Issue"
// "Your card will ship in a few days."
export const boostCardCardActivationProcessingTileContent = "Your card will ship in a few days."
// "Preparing your card"
export const boostCardCardActivationProcessingTileTitle = "Preparing your card"
// "Get Your Card"
export const boostCardCardOnboardingCTA = "Get Your Card"
// "To-do"
export const boostCardCardTodoTileTitle = "To-do"
// "Continue"
export const boostCardChangePinConfirmNewPinContinueCTA = "Continue"
// "Please confirm your new PIN"
export const boostCardChangePinConfirmNewPinTitle = "Please confirm your new PIN"
// "Continue"
export const boostCardChangePinCreateNewPinContinueCTA = "Continue"
// "Enter new PIN"
export const boostCardChangePinCreateNewPinTitle = "Enter new PIN"
// "Sorry, it looks like the last 4 SSN digits you entered don’t match our records. Please try again."
export const boostCardChangePinIncorrectLast4SSN = "Sorry, it looks like the last 4 SSN digits you entered don’t match our records. Please try again."
// "Sorry, it looks like you entered last 4 SSN digits wrong too many times. Please call customer service to change your PIN."
export const boostCardChangePinIncorrectLast4SSNLimitReached = "Sorry, it looks like you entered last 4 SSN digits wrong too many times. Please call customer service to change your PIN."
// "Continue"
export const boostCardChangePinNewPinConfirmedContinueCTA = "Continue"
// "Your PIN is updated!"
export const boostCardChangePinNewPinConfirmedTitle = "Your PIN is updated!"
// "Looks like the PIN numbers don’t match. Please try again."
export const boostCardChangePinNewPinsMismatch = "Looks like the PIN numbers don’t match. Please try again."
// "Continue"
export const boostCardChangePinVerifyPinContinueCTA = "Continue"
// "Enter your old PIN"
export const boostCardChangePinVerifyPinTitle = "Enter your old PIN"
// "Continue"
export const boostCardChangePinVerifySSNContinueCTA = "Continue"
// "Enter your last 4 SSN Digits"
export const boostCardChangePinVerifySSNTitle = "Enter your last 4 SSN Digits"
// "Your payment failed, please check your card details and try again."
export const boostCardCheckoutPaymentFailed = "Your payment failed, please check your card details and try again."
// "Your account needs to have a $0 balance to close it."
export const boostCardCloseAccountBalanceRemainingContent = "Your account needs to have a $0 balance to close it."
// "Withdraw Money"
export const boostCardCloseAccountBalanceRemainingCta = "Withdraw Money"
// "Your account needs to have a $0 balance to close it."
export const boostCardCloseAccountBalanceRemainingTitle = "Your account needs to have a $0 balance to close it."
// "Closing your account won’t affect your Grid+ subscription."
export const boostCardCloseAccountContent = "Closing your account won’t affect your Grid+ subscription."
// "Continue"
export const boostCardCloseAccountCta = "Continue"
// "Are you sure you want to close your Grid Card account?"
export const boostCardCloseAccountTitle = "Are you sure you want to close your Grid Card account?"
// "See your rewards"
export const boostCardCongratsScreenDoneButton = "See your rewards"
// "{{points}} points"
export const boostCardCongratsScreenPoints = "{{points}} points"
// "worth {{amt}}"
export const boostCardCongratsScreenPointsWorth = "worth {{amt}}"
// "Congratulations! You earned"
export const boostCardCongratsScreenTitle = "Congratulations! You earned"
// "{{points}} points!"
export const boostCardCongratsScreenTitlePointsSubstring = "{{points}} points!"
// "Looks like you entered the wrong 3 digit CVV. Please try again."
export const boostCardCvvIncorrect = "Looks like you entered the wrong 3 digit CVV. Please try again."
// "DEACTIVATED"
export const boostCardDeactivatedStateTitle = "DEACTIVATED"
// "Looks like you’ve entered a date in the future. Please try again."
export const boostCardDisputeDateInvalid = "Looks like you’ve entered a date in the future. Please try again."
// "Looks like your dispute is missing some information. Please try again."
export const boostCardDisputeRequestMissingFields = "Looks like your dispute is missing some information. Please try again."
// "We’ve detected an elevated dispute level on your account. High dispute rates can lead to account suspension based on Mastercard network rules. This can also negatively impact your credit score."
export const boostCardDisputesWarningBannerSubtitle = "We’ve detected an elevated dispute level on your account. High dispute rates can lead to account suspension based on Mastercard network rules. This can also negatively impact your credit score."
// "Elevated Dispute Level"
export const boostCardDisputesWarningBannerTitle = "Elevated Dispute Level"
// "EXP {{date}}"
export const boostCardExpirationDate = "EXP {{date}}"
// "00"
export const boostCardFundingWelcomePromoCents = "00"
// "$5"
export const boostCardFundingWelcomePromoDollars = "$5"
// "Add $5, Get $5"
export const boostCardFundingWelcomePromoPill = "Add $5, Get $5"
// "Get an extra $5 when you fund your account with more than $5"
export const boostCardFundingWelcomePromoTileBody = "Get an extra $5 when you fund your account with more than $5"
// "Fund account"
export const boostCardFundingWelcomePromoTileCTA = "Fund account"
// "Get $5 Welcome Boost"
export const boostCardFundingWelcomePromoTileTitle = "Get $5 Welcome Boost"
// "We’re experiencing issues with our banking partner and have locked your account. We’re working to get this resolved and will update you shortly."
export const boostCardGridLockedErrorContent = "We’re experiencing issues with our banking partner and have locked your account. We’re working to get this resolved and will update you shortly."
// "Account error"
export const boostCardGridLockedErrorTitle = "Account error"
// "Credit builder"
export const boostCardIntroInterstitialBulletOne = "Credit builder"
// "Build a $3,000 credit line"
export const boostCardIntroInterstitialBulletThree = "Build a $3,000 credit line"
// "Avg. $40 in cash rewards per month"
export const boostCardIntroInterstitialBulletTwo = "Avg. $40 in cash rewards per month"
// "Get Started"
export const boostCardIntroInterstitialContinueCTA = "Get Started"
// "Get Paid to"
// "Build Credit"
export const boostCardIntroInterstitialTitle = "Get Paid to\u000ABuild Credit"
// "There is a problem with your account"
export const boostCardKYCBannerCTA = "There is a problem with your account"
// "Next"
export const boostCardKYCContinueCTA = "Next"
// "You must add an image of the back of your document to continue."
export const boostCardKYCUploadBackImage = "You must add an image of the back of your document to continue."
// "There was a problem uploading your ID. You’ll have to upload it again."
export const boostCardKYCUploadFailedContent = "There was a problem uploading your ID. You’ll have to upload it again."
// "Upload failed"
export const boostCardKYCUploadFailedTitle = "Upload failed"
// "You must add an image of the front of your document to continue."
export const boostCardKYCUploadFrontImage = "You must add an image of the front of your document to continue."
// "Driver’s license"
export const boostCardKYCValidFormsListDriversLicense = "Driver’s license"
// "Military ID card"
export const boostCardKYCValidFormsListMilitaryID = "Military ID card"
// "Passport card"
export const boostCardKYCValidFormsListPassport = "Passport card"
// "State-issued ID card"
export const boostCardKYCValidFormsListStateIDCard = "State-issued ID card"
// "VALID FORMS OF ID"
export const boostCardKYCValidFormsListTitle = "VALID FORMS OF ID"
// "Looks like you entered the wrong 4 digits of your card. Please try again."
export const boostCardLast4DigitsIncorrect = "Looks like you entered the wrong 4 digits of your card. Please try again."
// "Link New Account"
export const boostCardLinkNewAccountOption = "Link New Account"
// "Insufficient funds"
export const boostCardMoneyInputInsufficientFunds = "Insufficient funds"
// "You can transfer up to $200 per day from your bank account to your Grid account."
export const boostCardMoneyInputMaxTransferContent = "You can transfer up to $200 per day from your bank account to your Grid account."
// "Maximum is {{amt}}"
export const boostCardMoneyInputMaxTransferExceeded = "Maximum is {{amt}}"
// "Continue"
export const boostCardMoneyInputSaveCTA = "Continue"
// "Transfer Amount"
export const boostCardMoneyInputTitle = "Transfer Amount"
// "Available Credit: {{available_credit}}"
export const boostCardMoneyInputTransferAvailableCredit = "Available Credit: {{available_credit}}"
// "This is more than your available credit line."
export const boostCardMoneyInputTransferCreditLineExceeded = "This is more than your available credit line."
// "Current Balance: {{balance}}"
export const boostCardMoneyInputTransferCreditLinePaymentBalance = "Current Balance: {{balance}}"
// "This is more than your current credit line balance."
export const boostCardMoneyInputTransferCreditLinePaymentBalanceExceeded = "This is more than your current credit line balance."
// "Balance is {{amt}}"
export const boostCardMoneyInputTransferExternalAccountBalanceExceeded = "Balance is {{amt}}"
// "This might exceed your {{bank_name}} bank account balance and cause an overdraft"
export const boostCardMoneyInputTransferExternalAccountBalanceExceededHelpText = "This might exceed your {{bank_name}} bank account balance and cause an overdraft"
// "Available Balance: {{amt}}"
export const boostCardMoneyInputTransferWithdrawalAvailableBalance = "Available Balance: {{amt}}"
// "This is more than your available Grid Card balance."
export const boostCardMoneyInputTransferWithdrawalAvailableBalanceExceeded = "This is more than your available Grid Card balance."
// "Add Funds"
export const boostCardNegativeBalanceTileCTA = "Add Funds"
// "You have a negative balance. Please add funds to continue using your card."
export const boostCardNegativeBalanceTileSbutitle = "You have a negative balance. Please add funds to continue using your card."
// "Fix your account"
export const boostCardNegativeBalanceTileTitle = "Fix your account"
// "Please fund your Grid Card account to kick off your Credit Builder!"
export const boostCardNotFundedForCreditBoostError = "Please fund your Grid Card account to kick off your Credit Builder!"
// "Account numbers"
export const boostCardOptionsAccountNumbers = "Account numbers"
// "Change pin"
export const boostCardOptionsChangePin = "Change pin"
// "Close Grid Card Account"
export const boostCardOptionsCloseAccount = "Close Grid Card Account"
// "Copy card number"
export const boostCardOptionsCopyCardNumber = "Copy card number"
// "Card number copied."
export const boostCardOptionsCopyCardNumberNumberCopied = "Card number copied."
// "Find ATM"
export const boostCardOptionsFindATM = "Find ATM"
// "Hide info"
export const boostCardOptionsHideNumbers = "Hide info"
// "Lock card"
export const boostCardOptionsLockCard = "Lock card"
// "Report problem"
export const boostCardOptionsReportProblem = "Report problem"
// "Show info"
export const boostCardOptionsShowNumbers = "Show info"
// "Unlock card"
export const boostCardOptionsUnlockCard = "Unlock card"
// "Please contact Support if you need to update your mailing address."
export const boostCardOrderScreenBadgeTitle = "Please contact Support if you need to update your mailing address."
// "Close"
export const boostCardPhysicalActivatedScreenCTAClose = "Close"
// "Continue"
export const boostCardPhysicalActivatedScreenCTAContinue = "Continue"
// "Continue"
export const boostCardReportProblemCTAContinue = "Continue"
// "My card is damaged"
export const boostCardReportProblemCTADamaged = "My card is damaged"
// "My card is lost or stolen"
export const boostCardReportProblemCTALostOrStolen = "My card is lost or stolen"
// "Let us know what your issue is so we can help fix it."
export const boostCardReportProblemContent = "Let us know what your issue is so we can help fix it."
// "Order New Card"
export const boostCardReportProblemDamagedCalloutCTA = "Order New Card"
// "New cards typically arrive within 7-10 days"
export const boostCardReportProblemDamagedCalloutContent = "New cards typically arrive within 7-10 days"
// "You can order a replacement card for free. Your old card will be terminated and will no longer work. You will get a temporary digital card to use until you receive your new card in the mail."
export const boostCardReportProblemDamagedContent = "You can order a replacement card for free. Your old card will be terminated and will no longer work. You will get a temporary digital card to use until you receive your new card in the mail."
// "My card is damaged"
export const boostCardReportProblemDamagedTitle = "My card is damaged"
// "Order New Card"
export const boostCardReportProblemLostOrStolenCalloutCTA = "Order New Card"
// "New cards typically arrive within 7-10 days"
export const boostCardReportProblemLostOrStolenCalloutContent = "New cards typically arrive within 7-10 days"
// "You can order a replacement card for free. Your old card will be terminated and will no longer work. You will get a temporary digital card to use until you receive your new card in the mail."
export const boostCardReportProblemLostOrStolenContent = "You can order a replacement card for free. Your old card will be terminated and will no longer work. You will get a temporary digital card to use until you receive your new card in the mail."
// "My card is lost or stolen"
export const boostCardReportProblemLostOrStolenTitle = "My card is lost or stolen"
// "Your physical card may take up to 3 weeks to arrive. If you don’t receive it by {{date}}, you can request a replacement. Doing so will terminate the old card that was mailed to you."
export const boostCardReportProblemNotArrivedDescription = "Your physical card may take up to 3 weeks to arrive. If you don’t receive it by {{date}}, you can request a replacement. Doing so will terminate the old card that was mailed to you."
// "If you haven’t received your card in the mail by {{date}}, you can request a replacement."
export const boostCardReportProblemNotArrivedSubtitle = "If you haven’t received your card in the mail by {{date}}, you can request a replacement."
// "My physical card hasn’t arrived"
export const boostCardReportProblemNotArrivedTitle = "My physical card hasn’t arrived"
// "Close"
export const boostCardReportProblemOrderConfirmedCTATitle = "Close"
// "Your temporary digital card is available to use immediately."
export const boostCardReportProblemOrderConfirmedInfo = "Your temporary digital card is available to use immediately."
// "Your old card has been cancelled, and your new card will be mailed out shortly. You should receive it in the mail in 1-2 weeks."
export const boostCardReportProblemOrderConfirmedSubtitle = "Your old card has been cancelled, and your new card will be mailed out shortly. You should receive it in the mail in 1-2 weeks."
// "Order Confirmed"
export const boostCardReportProblemOrderConfirmedTitle = "Order Confirmed"
// "Order New Card"
export const boostCardReportProblemOrderNewCard = "Order New Card"
// "Your old card has been cancelled, and your new card will be mailed out shortly. You should receive it in the mail in 1-2 weeks."
// ""
// "Your temporary digital card is available to use immediately."
export const boostCardReportProblemOrderPhysicalConfirmedCTATitle = "Your old card has been cancelled, and your new card will be mailed out shortly. You should receive it in the mail in 1-2 weeks.\u000A\u000AYour temporary digital card is available to use immediately."
// "Done"
export const boostCardReportProblemSuccessCTA = "Done"
// "Your new card will be on its way soon"
export const boostCardReportProblemSuccessCardTerminatedTitle = "Your new card will be on its way soon"
// "Your old card has been canceled. Your new card will be on its way soon."
export const boostCardReportProblemSuccessTitle = "Your old card has been canceled. Your new card will be on its way soon."
// "What’s going on with your card?"
export const boostCardReportProblemTitle = "What’s going on with your card?"
// "1x points"
export const boostCardRewards1XTileBadge = "1x points"
// "2x points"
export const boostCardRewards2XTileBadge = "2x points"
// "Congrats! You unlocked 2x rewards this month with your qualifying direct deposit."
export const boostCardRewardsRedemption2XCongrats = "Congrats! You unlocked 2x rewards this month with your qualifying direct deposit."
// "Make a qualifying direct deposit this month to unlock 2x points."
export const boostCardRewardsRedemption2XTeaser = "Make a qualifying direct deposit this month to unlock 2x points."
// "Available"
export const boostCardRewardsRedemptionAvailable = "Available"
// "Earning 1x points on all purchases"
export const boostCardRewardsRedemptionEarning1XBadge = "Earning 1x points on all purchases"
// "Earning 2x points on all purchases"
export const boostCardRewardsRedemptionEarning2XBadge = "Earning 2x points on all purchases"
// "On all Grid Card purchases"
export const boostCardRewardsRedemptionHelp1XContent = "On all Grid Card purchases"
// "1x Points"
export const boostCardRewardsRedemptionHelp1XTitle = "1x Points"
// "On all Grid Card purchases if you setup >$500 month direct deposit"
export const boostCardRewardsRedemptionHelp2XContent = "On all Grid Card purchases if you setup \u003E$500 month direct deposit"
// "2x Points"
export const boostCardRewardsRedemptionHelp2XTitle = "2x Points"
// "Activated"
export const boostCardRewardsRedemptionHelpBottomBannerCTA = "Activated"
// "2x points on all purchases"
export const boostCardRewardsRedemptionHelpBottomBannerTitle = "2x points on all purchases"
// "Done"
export const boostCardRewardsRedemptionHelpCTA = "Done"
// "Earn 1x points on every purchase made with your card."
export const boostCardRewardsRedemptionHelpItem1 = "Earn 1x points on every purchase made with your card."
// "Make a direct deposit of $250 or more to earn 2x points on all purchases made that month."
export const boostCardRewardsRedemptionHelpItem2 = "Make a direct deposit of $250 or more to earn 2x points on all purchases made that month."
// "Pending points will be available to redeem at the beginning of each month."
export const boostCardRewardsRedemptionHelpItem3 = "Pending points will be available to redeem at the beginning of each month."
// "Get rewards for all your purchases"
export const boostCardRewardsRedemptionHelpSubtitle = "Get rewards for all your purchases"
// "How points work"
export const boostCardRewardsRedemptionHelpTitle = "How points work"
// "Redeeming…"
export const boostCardRewardsRedemptionInProgress = "Redeeming…"
// "How points work"
export const boostCardRewardsRedemptionOpenHelpCta = "How points work"
// "Pending"
export const boostCardRewardsRedemptionPending = "Pending"
// "Pending points become available at the beginning of the following month."
export const boostCardRewardsRedemptionPendingExplanation = "Pending points become available at the beginning of the following month."
// "worth {{amt}}"
export const boostCardRewardsRedemptionPointsWorth = "worth {{amt}}"
// "Redeem Points"
export const boostCardRewardsRedemptionRedeemCta = "Redeem Points"
// "You cashed in your"
// "points!"
export const boostCardRewardsRedemptionSuccess = "You cashed in your\u000Apoints!"
// "Rewards Balance"
export const boostCardRewardsRedemptionTitle = "Rewards Balance"
// "Rewards"
export const boostCardRewardsSectionHeader = "Rewards"
// "POINTS"
export const boostCardRewardsTilePointsHeader = "POINTS"
// "10x rewards with TurboBoost"
export const boostCardRewardsTileTurboBoostTitle = "10x rewards with TurboBoost"
// "Fund account"
export const boostCardRewardsValuePropFundAccountCTA = "Fund account"
// "Get 2x rewards on every transaction when you setup $500+ monthly direct deposit."
export const boostCardRewardsValuePropFundAccountContent = "Get 2x rewards on every transaction when you setup $500+ monthly direct deposit."
// "Earn reward points with every swipe"
export const boostCardRewardsValuePropFundAccountTitle = "Earn reward points with every swipe"
// "Consistent deposits"
export const boostCardSetupConnectBankAccountRow1KDeposits = "Consistent deposits"
// "300 days active"
export const boostCardSetupConnectBankAccountRow60DaysActive = "300 days active"
// "Connectivity to Grid"
export const boostCardSetupConnectBankAccountRowConnectivityToGrid = "Connectivity to Grid"
// "Consistent spending"
export const boostCardSetupConnectBankAccountRowSpending = "Consistent spending"
// "You are qualified to get a Grid Card if you connect your main bank account"
export const boostCardSetupConnectBankAccountTitle = "You are qualified to get a Grid Card if you connect your main bank account"
// "Credit Account"
export const boostCardStatementsNodeCreditAccount = "Credit Account"
// "Credit Line"
export const boostCardStatementsNodeCreditLine = "Credit Line"
// "Deposit Account"
export const boostCardStatementsNodeDeposit = "Deposit Account"
// "Reserve Account"
export const boostCardStatementsNodeReserve = "Reserve Account"
// "Set up direct deposits and start building your spend power."
export const boostCardTileCreditBoostSetupBody = "Set up direct deposits and start building your spend power."
// "Continue"
export const boostCardTileCreditBoostSetupStart = "Continue"
// "Finish Setup"
export const boostCardTileCreditBoostSetupTitle = "Finish Setup"
// "Set up direct deposits and start building your spend power."
export const boostCardTileCreditLineSetupBody = "Set up direct deposits and start building your spend power."
// "Credit Line"
export const boostCardTileCreditLineSetupHeader = "Credit Line"
// "Your credit line application has been submitted and we will reach out to you soon."
export const boostCardTileCreditLineSetupModalApplicationSubmitted = "Your credit line application has been submitted and we will reach out to you soon."
// "Close"
export const boostCardTileCreditLineSetupModalClose = "Close"
// "Congrats! You’ve unlocked your credit line."
export const boostCardTileCreditLineSetupModalCompleted = "Congrats! You’ve unlocked your credit line."
// "Set up Direct Deposit"
export const boostCardTileCreditLineSetupModalCta = "Set up Direct Deposit"
// "Order Grid Card"
export const boostCardTileCreditLineSetupModalItemOne = "Order Grid Card"
// "Make 3 Deposits of $250+"
export const boostCardTileCreditLineSetupModalItemThree = "Make 3 Deposits of $250+"
// "Set up Direct Deposit"
export const boostCardTileCreditLineSetupModalItemTwo = "Set up Direct Deposit"
// "Progress"
export const boostCardTileCreditLineSetupModalProgress = "Progress"
// "Get up to $3,000"
export const boostCardTileCreditLineSetupModalSubtitle = "Get up to $3,000"
// "Unlock your credit line"
export const boostCardTileCreditLineSetupModalTitle = "Unlock your credit line"
// "View Progress"
export const boostCardTileCreditLineSetupProgress = "View Progress"
// "Get Started"
export const boostCardTileCreditLineSetupStart = "Get Started"
// "Unlock your credit line"
export const boostCardTileCreditLineSetupTitle = "Unlock your credit line"
// "Transfer to Grid Card"
export const boostCardTileCreditLineSetupUnlockedCTA = "Transfer to Grid Card"
// "Repay your current Grid Advance to access your credit line."
export const boostCardTileCreditLineSetupUnlockedDisabledReasonActiveAdvance = "Repay your current Grid Advance to access your credit line."
// "Your credit line will be available once you activate your card."
export const boostCardTileCreditLineSetupUnlockedDisabledReasonCardInactive = "Your credit line will be available once you activate your card."
// "STARTING CREDIT LINE"
export const boostCardTileCreditLineSetupUnlockedStartingOfferDescription = "STARTING CREDIT LINE"
// "Credit Line Unlocked"
export const boostCardTileCreditLineSetupUnlockedTitle = "Credit Line Unlocked"
// "Your {{amt}} credit line has been unlocked. Transfer it to your card to start using it."
export const boostCardTileCreditLineUnlockedBody = "Your {{amt}} credit line has been unlocked. Transfer it to your card to start using it."
// "View Credit"
export const boostCardTileCreditLineUnlockedCTA = "View Credit"
// "Congratulations!"
export const boostCardTileCreditLineUnlockedTitle = "Congratulations!"
// "Fund account"
export const boostCardTodoListFundAccountCTA = "Fund account"
// "Add money and start getting paid building your credit"
export const boostCardTodoListFundAccountContent = "Add money and start getting paid building your credit"
// "Add money"
export const boostCardTodoListFundAccountTitle = "Add money"
// "Order Card"
export const boostCardTodoOrderNewCardCTA = "Order Card"
// "Your old card was reported lost or stolen, and has been deactivated"
export const boostCardTodoOrderNewCardSubtitle = "Your old card was reported lost or stolen, and has been deactivated"
// "Order a new card"
export const boostCardTodoOrderNewCardTitle = "Order a new card"
// "Looks like you already submitted a dispute for this transaction."
export const boostCardTransactionAlreadyDisputed = "Looks like you already submitted a dispute for this transaction."
// "Please wait for this transaction to fully process before attempting to dispute it."
export const boostCardTransactionNotSettledToDispute = "Please wait for this transaction to fully process before attempting to dispute it."
// "Sorry, but it looks like you’ve hit our transfer limit."
// ""
// "Please try again in a few days, or reach out to our support team if you need help."
export const boostCardTransferLimitError = "Sorry, but it looks like you’ve hit our transfer limit.\u000A\u000APlease try again in a few days, or reach out to our support team if you need help."
// "Please enter at least one dollar to deposit or withdraw money from your Grid Card."
export const boostCardTransferMinimumNotMetError = "Please enter at least one dollar to deposit or withdraw money from your Grid Card."
// "Confirm your PIN"
export const boostCardVerifyPinTitle = "Confirm your PIN"
// "Change"
export const boostCardYourOrderChangeLabel = "Change"
// "SHIP CARD TO"
export const boostCardYourOrderShipCardTo = "SHIP CARD TO"
// "Enable"
export const boostConclusionButtonEnablePush = "Enable"
// "Not now"
export const boostConclusionButtonNotNow = "Not now"
// "If you enable push notifications, we can let you know when your payroll provider has updated your withholdings."
export const boostConclusionLabelBody = "If you enable push notifications, we can let you know when your payroll provider has updated your withholdings."
// "Enable push notifications so we can let you know when your payroll provider has updated your withholdings"
export const boostConclusionLabelBodyV2 = "Enable push notifications so we can let you know when your payroll provider has updated your withholdings"
// "Congratulations! You’re one month closer to your paycheck PayBoost!"
export const boostConclusionLabelTitle = "Congratulations! You’re one month closer to your paycheck PayBoost!"
// "You’re about to start keeping more of your hard-earned money in your own pocket! No hidden fees. No interest. Just your own money in your own pocket."
export const boostCongratulationsBody = "You’re about to start keeping more of your hard-earned money in your own pocket! No hidden fees. No interest. Just your own money in your own pocket."
// "Continue"
export const boostCongratulationsButton = "Continue"
// "You can expect to see your PayBoost within 1-2 pay periods."
export const boostCongratulationsGuidedBody = "You can expect to see your PayBoost within 1-2 pay periods."
// "Not Now"
export const boostCongratulationsGuidedButton = "Not Now"
// "You’re all set!"
export const boostCongratulationsGuidedTitle = "You’re all set!"
// "Goodbye extra taxes!"
export const boostCongratulationsTitle = "Goodbye extra taxes!"
// "Continue"
export const boostIntroButtonContinue = "Continue"
// "Next"
export const boostIntroButtonNext = "Next"
// "Get it now, or get it later: you decide how much of your tax refund you want to access early by choosing your ideal PayBoost amount."
export const boostIntroPage1Body = "Get it now, or get it later: you decide how much of your tax refund you want to access early by choosing your ideal PayBoost amount."
// "Select your PayBoost amount"
export const boostIntroPage1Title = "Select your PayBoost amount"
// "Once you choose your PayBoost, we’ll update your W-4 to make sure you get the most out of each paycheck. Sign to approve and we’ll take it from there!"
export const boostIntroPage2Body = "Once you choose your PayBoost, we’ll update your W-4 to make sure you get the most out of each paycheck. Sign to approve and we’ll take it from there!"
// "Sign your docs"
export const boostIntroPage2Title = "Sign your docs"
// "As soon as your updated W-4 takes effect, we show you the money! It may take a few weeks for your paychecks to reflect your PayBoost, but don’t worry, your boosted paycheck is on its way."
export const boostIntroPage3Body = "As soon as your updated W-4 takes effect, we show you the money! It may take a few weeks for your paychecks to reflect your PayBoost, but don’t worry, your boosted paycheck is on its way."
// "Wait for your next paycheck"
export const boostIntroPage3Title = "Wait for your next paycheck"
// "The longer you stay, the bigger your PayBoosts gets. Unlock levels by completing your check-ins every month."
export const boostIntroPagePayboostBody = "The longer you stay, the bigger your PayBoosts gets. Unlock levels by completing your check-ins every month."
// "Unlock bigger PayBoosts"
export const boostIntroPagePayboostTitle = "Unlock bigger PayBoosts"
// "How PayBoost Works"
export const boostIntroTitle = "How PayBoost Works"
// "Continue"
export const boostLevelUnlockedLevelButtonContinue = "Continue"
// "est. per year"
export const boostLevelUnlockedLevelSubtext = "est. per year"
// "Congratulations! You unlocked PayBoost {{ level_title }}."
export const boostLevelUnlockedMessage = "Congratulations! You unlocked PayBoost {{ level_title }}."
// "Your document is being finalized."
export const boostLoadingWaitingForSignedW4 = "Your document is being finalized."
// "We’re generating your updated W-4."
export const boostLoadingWaitingForUnsignedW4 = "We’re generating your updated W-4."
// "Hold tight while we create a PayBoost plan for you…"
export const boostProcessingTitle = "Hold tight while we create a PayBoost plan for you…"
// "Your financial well-being is our goal. Unfortunately, your scenario has changed and we need to update your W-4."
export const boostReasonDecreaseBody = "Your financial well-being is our goal. Unfortunately, your scenario has changed and we need to update your W-4."
// "Time for an Update"
export const boostReasonDecreaseTitle = "Time for an Update"
// "Lucky news! You’re now qualified for a bigger PayBoost. Choose an amount and get your updated form."
export const boostReasonIncreaseBody = "Lucky news! You’re now qualified for a bigger PayBoost. Choose an amount and get your updated form."
// "You’re getting a Bigger PayBoost"
export const boostReasonIncreaseTitle = "You’re getting a Bigger PayBoost"
// "We’re introducing a new feature called Streaks! The more monthly check-ins you complete, the more money you get."
export const boostReasonNewYearBody = "We’re introducing a new feature called Streaks! The more monthly check-ins you complete, the more money you get."
// "All new year, all new Paybost!"
export const boostReasonNewYearTitle = "All new year, all new Paybost!"
// "Continue"
export const boostSelectionButtonContinue = "Continue"
// "{{refund}} available"
export const boostSelectionButtonRefundResults = "{{refund}} available"
// "I want"
export const boostSelectionLabelIWant = "I want"
// "monthly PayBoost"
export const boostSelectionLabelMonthlyPayboost = "monthly PayBoost"
// "/ month"
export const boostSelectionLabelPerMonth = "/ month"
// "Your end-of-year refund"
export const boostSelectionLabelRefund = "Your end-of-year refund"
// "Done"
export const boostSelectionMoreInfoButtonClose = "Done"
// "Monthly PayBoost Breakdown"
export const boostSelectionMoreInfoLabelTitle = "Monthly PayBoost Breakdown"
// "You currently bring home {{current}} monthly."
export const boostSelectionMoreInfoMoreInfoCurrentMonthlyTakeHome = "You currently bring home {{current}} monthly."
// "Your monthly pay will increase by {{increase}}."
export const boostSelectionMoreInfoMoreInfoIncreaseInMonthlyTakeHome = "Your monthly pay will increase by {{increase}}."
// "Your monthly pay will increase by up to {{increase}}."
export const boostSelectionMoreInfoMoreInfoIncreaseInMonthlyTakeHomeApproximate = "Your monthly pay will increase by up to {{increase}}."
// "Your estimated end-of-year tax refund remaining after PayBoost is {{remaining}}."
export const boostSelectionMoreInfoMoreInfoRefundRemaining = "Your estimated end-of-year tax refund remaining after PayBoost is {{remaining}}."
// "Clear"
export const boostSignatureButtonClear = "Clear"
// "Save Signature"
export const boostSignatureButtonSave = "Save Signature"
// "Draw your signature"
export const boostSignatureTitle = "Draw your signature"
// "To continue, please print or email your updated W-4 form."
export const boostSignedW4NoActivityCompletedMessage = "To continue, please print or email your updated W-4 form."
// "Please Save your W-4"
export const boostSignedW4NoActivityCompletedTitle = "Please Save your W-4"
// "With your updated W-4, you can expect {{expected_paycheck_amount}} on your next paycheck."
export const boostSignedW4SLabelText = "With your updated W-4, you can expect {{expected_paycheck_amount}} on your next paycheck."
// "a PayBoost"
export const boostSignedW4SLabelTextExpectedPaycheckAmountFallback = "a PayBoost"
// "Updated W-4 - Please Update in Payroll"
export const boostSignedW4ShareSubject = "Updated W-4 - Please Update in Payroll"
// "Your updated W-4"
export const boostSignedW4Title = "Your updated W-4"
// "Finalize my PayBoost"
export const boostSignedW4SAutomaticButton = "Finalize my PayBoost"
// "We’ll update your withholding info with your payroll provider and you’ll see the difference!"
export const boostSignedW4SAutomaticInfo = "We’ll update your withholding info with your payroll provider and you’ll see the difference!"
// "Your new W-4 is saved under Documents"
export const boostSignedW4SAutomaticPill = "Your new W-4 is saved under Documents"
// "Not Now"
export const boostSignedW4SButtonNotNow = "Not Now"
// "Save File"
export const boostSignedW4SButtonPreview = "Save File"
// "Submit your new W-4 to your HR department and you’ll see your PayBoost in 4-6 weeks."
export const boostSignedW4SLabelSubtext = "Submit your new W-4 to your HR department and you’ll see your PayBoost in 4-6 weeks."
// "Submit your updated W-4"
export const boostSignedW4STitle = "Submit your updated W-4"
// "You completed 5 monthly check-ins, which means you qualify for a bigger PayBoost!"
export const boostStreaksAlreadyCompletedMessage = "You completed 5 monthly check-ins, which means you qualify for a bigger PayBoost!"
// "Continue"
export const boostStreaksButton = "Continue"
// "You’re on a roll!"
export const boostStreaksDefaultTitle = "You’re on a roll!"
// "Thanks for sticking with us! Check-in 5 times per year to get the maximum PayBoost. Keep in mind, levels reset each year."
export const boostStreaksMaxLevelMessage = "Thanks for sticking with us! Check-in 5 times per year to get the maximum PayBoost. Keep in mind, levels reset each year."
// "You completed 1 monthly check-in, which means you qualify for a bigger PayBoost!"
export const boostStreaksNewLevelFirstMessage = "You completed 1 monthly check-in, which means you qualify for a bigger PayBoost!"
// "You completed {{ streaks_completed }} monthly check-ins, which means you qualify for a bigger PayBoost!"
export const boostStreaksNewLevelMessage = "You completed {{ streaks_completed }} monthly check-ins, which means you qualify for a bigger PayBoost!"
// "{{ level_number }} Unlocked!"
export const boostStreaksNewLevelTitle = "{{ level_number }} Unlocked!"
// "Complete {{ streaks_remaining }} more check-ins and you’ll qualify for a bigger PayBoost!"
export const boostStreaksNextLevelDefaultMessage = "Complete {{ streaks_remaining }} more check-ins and you’ll qualify for a bigger PayBoost!"
// "You completed a 1-month streak. Complete more months for a bigger PayBoost!"
export const boostStreaksNextLevelFirstMessage = "You completed a 1-month streak. Complete more months for a bigger PayBoost!"
// "Complete next month’s check-in and you’ll qualify for a bigger PayBoost!"
export const boostStreaksNextLevelOneAwayMessage = "Complete next month’s check-in and you’ll qualify for a bigger PayBoost!"
// "Estimated Post Date"
export const boostTransactionEstimatedPostDate = "Estimated Post Date"
// "Reversal"
export const boostTransactionTypeBalanceCorrectionClawBackAchReturn = "Reversal"
// "Reversal"
export const boostTransactionTypeBalanceCorrectionClawBackNegativeSweep = "Reversal"
// "Refund"
export const boostTransactionTypeBalanceCorrectionRefundAchReturn = "Refund"
// "Refund"
export const boostTransactionTypeBalanceCorrectionRefundNegativeSweep = "Refund"
// "Grid Card Credit"
export const boostTransactionTypeBoostCardCredit = "Grid Card Credit"
// "Refund"
export const boostTransactionTypeClawBackRefund = "Refund"
// "Reversal"
export const boostTransactionTypeClawBackReversal = "Reversal"
// "ACH Deposit - Credit Line Repayment"
export const boostTransactionTypeCreditLineACHPayment = "ACH Deposit - Credit Line Repayment"
// "Grid Plus Subscription"
export const boostTransactionTypeCreditLineConversionGridPlusSubscription = "Grid Plus Subscription"
// "Subscription Reactivation"
export const boostTransactionTypeCreditLineConversionSubscriptionReactivation = "Subscription Reactivation"
// "Disbursement"
export const boostTransactionTypeCreditLineDisbursement = "Disbursement"
// "Credit Line Interest Charge"
export const boostTransactionTypeCreditLineInterestCharge = "Credit Line Interest Charge"
// "Credit Line Repayment"
export const boostTransactionTypeCreditLinePayment = "Credit Line Repayment"
// "Advance Repayment"
export const boostTransactionTypeCreditLinePaymentToCashboost = "Advance Repayment"
// "General Transaction"
export const boostTransactionTypeGeneric = "General Transaction"
// "Third Party Deposit"
export const boostTransactionTypePaymentFromThirdParty = "Third Party Deposit"
// "Provisional Credit"
export const boostTransactionTypeProvisionalCredit = "Provisional Credit"
// "Grid Card Rewards Redemption"
export const boostTransactionTypeStatementCredit = "Grid Card Rewards Redemption"
// "Grid Plus Subscription"
export const boostTransactionTypeSubscriptionPayment = "Grid Plus Subscription"
// "Sign document"
export const boostUnsignedW4ButtonContinue = "Sign document"
// "What is this?"
export const boostUnsignedW4ButtonHelp = "What is this?"
// "As an employee in the United States, it is your right to adjust your federal tax withholdings. This is the official form from the IRS that your employer must accept, in order to accurately withhold taxes on your paycheck."
export const boostUnsignedW4HelpContent = "As an employee in the United States, it is your right to adjust your federal tax withholdings. This is the official form from the IRS that your employer must accept, in order to accurately withhold taxes on your paycheck."
// "Form W-4"
export const boostUnsignedW4HelpTitle = "Form W-4"
// "Your updated W-4"
export const boostUnsignedW4Title = "Your updated W-4"
// "Continue"
export const boostW4DataElementsButtonContinue = "Continue"
// "A valid address is required to complete your W-4."
export const boostW4DataElementsErrorMessageInvalidAddress = "A valid address is required to complete your W-4."
// "A valid city, state, and zipcode are required to complete your W-4."
export const boostW4DataElementsErrorMessageInvalidCityStateZip = "A valid city, state, and zipcode are required to complete your W-4."
// "A valid first name is required to complete your W-4."
export const boostW4DataElementsErrorMessageInvalidFirstName = "A valid first name is required to complete your W-4."
// "A valid last name is required to complete your W-4."
export const boostW4DataElementsErrorMessageInvalidLastName = "A valid last name is required to complete your W-4."
// "A valid social security number is required to complete your W-4."
export const boostW4DataElementsErrorMessageInvalidSSN = "A valid social security number is required to complete your W-4."
// "Please check your entries."
export const boostW4DataElementsErrorTitle = "Please check your entries."
// "123 Main St."
export const boostW4DataElementsFieldAddressPlaceholder = "123 Main St."
// "Address"
export const boostW4DataElementsFieldAddressTitle = "Address"
// "Marietta, GA 30067"
export const boostW4DataElementsFieldCityStateZipPlaceholder = "Marietta, GA 30067"
// "City or town, state, and ZIP code"
export const boostW4DataElementsFieldCityStateZipTitle = "City or town, state, and ZIP code"
// "Alex"
export const boostW4DataElementsFieldFirstNamePlaceholder = "Alex"
// "Legal First Name"
export const boostW4DataElementsFieldFirstNameTitle = "Legal First Name"
// "Williams"
export const boostW4DataElementsFieldLastNamePlaceholder = "Williams"
// "Legal Last Name"
export const boostW4DataElementsFieldLastNameTitle = "Legal Last Name"
// "123-45-6789"
export const boostW4DataElementsFieldSSNPlaceholder = "123-45-6789"
// "Social Security Number"
export const boostW4DataElementsFieldSSNTitle = "Social Security Number"
// "Why do you need my SSN?"
export const boostW4DataElementsFieldSSNWhyButton = "Why do you need my SSN?"
// "In order to fill out your Form W-4, your HR department requires your social security number to file the paperwork."
export const boostW4DataElementsFieldSSNWhyButtonHelpMessage = "In order to fill out your Form W-4, your HR department requires your social security number to file the paperwork."
// "Why do I need to enter my Social Security Number?"
export const boostW4DataElementsFieldSSNWhyButtonHelpTitle = "Why do I need to enter my Social Security Number?"
// "All of your information is securely stored so don’t be worried."
export const boostW4DataElementsInfo = "All of your information is securely stored so don’t be worried."
// "W-4 Creation"
export const boostW4DataElementsNavTitle = "W-4 Creation"
// "We need some personal information to create your new W-4."
export const boostW4DataElementsTitle = "We need some personal information to create your new W-4."
// "Email"
export const boostW4HRSubmitCheckboxTitleEmail = "Email"
// "Mailing Address"
export const boostW4HRSubmitCheckboxTitleSnailMail = "Mailing Address"
// "Coming Soon"
export const boostW4HRSubmitComingSoon = "Coming Soon"
// "Continue"
export const boostW4HRSubmitContinueButton = "Continue"
// "Please fill out the Payroll Administrator Name/Department field."
export const boostW4HRSubmitErrorReasonEmptyAttn = "Please fill out the Payroll Administrator Name/Department field."
// "Please add the city of your Payroll Administrator or Department."
export const boostW4HRSubmitErrorReasonEmptyCity = "Please add the city of your Payroll Administrator or Department."
// "Please add your Payroll Administrator or HR Department email address."
export const boostW4HRSubmitErrorReasonEmptyEmail = "Please add your Payroll Administrator or HR Department email address."
// "Please add the state of your Payroll Administrator or Department."
export const boostW4HRSubmitErrorReasonEmptyState = "Please add the state of your Payroll Administrator or Department."
// "Please add the street address of your Payroll Administrator or Department."
export const boostW4HRSubmitErrorReasonEmptyStreet = "Please add the street address of your Payroll Administrator or Department."
// "Please add the zip code of your Payroll Administrator or Department."
export const boostW4HRSubmitErrorReasonEmptyZip = "Please add the zip code of your Payroll Administrator or Department."
// "This doesn’t appear to be a valid email address. Please check your entry and try again."
export const boostW4HRSubmitErrorReasonInvalidEmail = "This doesn’t appear to be a valid email address. Please check your entry and try again."
// "The state you entered is not a valid state abbreviation."
export const boostW4HRSubmitErrorReasonInvalidState = "The state you entered is not a valid state abbreviation."
// "Please enter the five digit zip code of your Payroll Administrator or Department."
export const boostW4HRSubmitErrorReasonInvalidZip = "Please enter the five digit zip code of your Payroll Administrator or Department."
// "How should we get in touch with your HR Department?"
export const boostW4HRSubmitPrompt = "How should we get in touch with your HR Department?"
// "Where should we send your"
// "updated W-4?"
export const boostW4HRSubmitPromptNew = "Where should we send your\u000Aupdated W-4?"
// "Submit Manually"
export const boostW4HRSubmitSubmitManuallyButton = "Submit Manually"
// "Payroll Administrator Name/Department"
export const boostW4HRSubmitTextFieldAttn = "Payroll Administrator Name/Department"
// "City"
export const boostW4HRSubmitTextFieldCity = "City"
// "HR or Payroll Department Email"
export const boostW4HRSubmitTextFieldEmail = "HR or Payroll Department Email"
// "State"
export const boostW4HRSubmitTextFieldState = "State"
// "Street Address"
export const boostW4HRSubmitTextFieldStreet = "Street Address"
// "Apartment, suite, etc."
export const boostW4HRSubmitTextFieldStreet2 = "Apartment, suite, etc."
// "Zip Code"
export const boostW4HRSubmitTextFieldZip = "Zip Code"
// "AL"
export const boostW4HRSubmitTextfieldStatePlaceholder = "AL"
// "Your Updated W-4"
export const boostW4HRSubmitTitle = "Your Updated W-4"
// "Check with your HR department if you don’t receive a boost on your next paycheck."
export const boostW4HRSuccessAutomatedSubtitle = "Check with your HR department if you don’t receive a boost on your next paycheck."
// "You’re all set!"
export const boostW4HRSuccessAutomatedTitle = "You’re all set!"
// "Continue"
export const boostW4HRSuccessContinueButton = "Continue"
// "Enable"
export const boostWaitingEnablePushButtonText = "Enable"
// "Not now"
export const boostWaitingNotNowButtonText = "Not now"
// "If you enable push notifications, you can close the app and we’ll let you know when it’s ready for you."
export const boostWaitingText = "If you enable push notifications, you can close the app and we’ll let you know when it’s ready for you."
// "Your PayBoost is taking a few minutes…"
export const boostWaitingTitle = "Your PayBoost is taking a few minutes…"
// "Hooray!"
export const boostYGBContinueButtonText = "Hooray!"
// "{{boost_amt}} was added to your take-home pay this week! Without PayBoost, you would not have received this money until you filed your tax return."
export const boostYGBDescription = "{{boost_amt}} was added to your take-home pay this week! Without PayBoost, you would not have received this money until you filed your tax return."
// "Are you enjoying PayBoost? Let us know!"
export const boostYGBRatingCTAButtonText = "Are you enjoying PayBoost? Let us know!"
// "Tap to share"
export const boostYGBShare = "Tap to share"
// "{{boost_amt}} was added to my paycheck this week, all thanks to Grid PayBoost! Stop overpaying on your taxes, and see what else Grid has to offer at https://www.getgrid.app #getgrid #grid #gridPayBoost"
export const boostYGBShareContent = "{{boost_amt}} was added to my paycheck this week, all thanks to Grid PayBoost! Stop overpaying on your taxes, and see what else Grid has to offer at https://www.getgrid.app #getgrid #grid #gridPayBoost"
// "Grid | getgrid.app"
export const boostYGBShareContentFooter = "Grid | getgrid.app"
// "{{boost_amt}}"
export const boostYGBShareImageHeader = "{{boost_amt}}"
// "Was added to my paycheck thanks to Grid PayBoost!"
export const boostYGBShareImageText = "Was added to my paycheck thanks to Grid PayBoost!"
// "You got boosted!"
export const boostYGBTitle = "You got boosted!"
// "Almost there! You’re one step away from placing your Grid Card order."
export const boostcardTileFinishCreditLineBody = "Almost there! You’re one step away from placing your Grid Card order."
// "Continue"
export const boostcardTileFinishCreditLineCTA = "Continue"
// "Finish Setup"
export const boostcardTileFinishCreditLineTitle = "Finish Setup"
// "Update your payment method to get your card."
export const boostcardTilePaymentFailedBody = "Update your payment method to get your card."
// "Update"
export const boostcardTilePaymentFailedCta = "Update"
// "Payment Failed"
export const boostcardTilePaymentFailedTitle = "Payment Failed"
// "Back"
export const buttonBack = "Back"
// "Cancel"
export const buttonCancel = "Cancel"
// "Continue"
export const buttonContinue = "Continue"
// "Call (501) 566-4743"
export const callUsSupportMenuCTATitle = "Call (501) 566-4743"
// "If you need to reach us outside of business hours please send us a message in the app."
export const callUsSupportMenuChatCTA = "If you need to reach us outside of business hours please send us a message in the app."
// "send us a message"
export const callUsSupportMenuChatCTAHighlightQuery = "send us a message"
// "5015664743"
export const callUsSupportMenuPhoneNumber = "5015664743"
// "Call Us"
export const callUsSupportMenuTitle = "Call Us"
// "We’re available 8am - 6pm Monday-Friday, PST"
export const callUsSupportMenuTitleHeader = "We’re available 8am - 6pm Monday-Friday, PST"
// "We’re having trouble connecting to your devices camera"
export const captureAlertNoCameraMessage = "We’re having trouble connecting to your devices camera"
// "OK"
export const captureAlertNoCameraOk = "OK"
// "Failed to Open Camera"
export const captureAlertNoCameraTitle = "Failed to Open Camera"
// "Keep"
export const captureConfirmationButtonKeep = "Keep"
// "Retake"
export const captureConfirmationButtonRetake = "Retake"
// "Cancel"
export const captureNavButtonCancel = "Cancel"
// "Done"
export const captureNavButtonDone = "Done"
// "Continue"
export const cashboostAccountErrorViewCTATitleConnectNewAccount = "Continue"
// "Connect Another Card"
export const cashboostAccountErrorViewCTATitleConnectNewCard = "Connect Another Card"
// "Reconnect your account"
export const cashboostAccountErrorViewCTATitleReconnectAccount = "Reconnect your account"
// "Bank account"
export const cashboostAccountRequirementsBankAccountTitle = "Bank account"
// "Debit card"
export const cashboostAccountRequirementsDebitCardTitle = "Debit card"
// "Account Requirements"
export const cashboostAccountRequirementsListHeaderRowTitle = "Account Requirements"
// "Subscription"
export const cashboostAccountRequirementsMembershipIssueTitle = "Subscription"
// "You must meet all requirements to be eligible for Grid Advance"
export const cashboostAccountsRequirementHeaderBankAccountContent = "You must meet all requirements to be eligible for Grid Advance"
// "Bank account requirements"
export const cashboostAccountsRequirementHeaderBankAccountTitle = "Bank account requirements"
// "You must meet all requirements to work with Grid Advance."
export const cashboostAccountsRequirementHeaderDebitCardContent = "You must meet all requirements to work with Grid Advance."
// "Debit Card Requirements"
export const cashboostAccountsRequirementHeaderDebitCardTitle = "Debit Card Requirements"
// "Tap any row to continue with your advance."
export const cashboostAccountsRequirementHeaderGenericBottom = "Tap any row to continue with your advance."
// "If you’ve already transferred additional"
// "funds please reach out to us via chat"
export const cashboostAccountsRequirementHeaderMembershipIssueBottom = "If you’ve already transferred additional\u000Afunds please reach out to us via chat"
// "Please transfer additional funds to your bank account whenever you can. You will be able to continue using Grid Advance once your subscription fees are paid."
export const cashboostAccountsRequirementHeaderMembershipIssueContent = "Please transfer additional funds to your bank account whenever you can. You will be able to continue using Grid Advance once your subscription fees are paid."
// "SUBSCRIPTION REQUIREMENTS"
export const cashboostAccountsRequirementHeaderMembershipIssueTitle = "SUBSCRIPTION REQUIREMENTS"
// "{{account}} debit card"
export const cashboostAddDebitCardBankInfo = "{{account}} debit card"
// "Continue"
export const cashboostAddDebitCardCTAButton = "Continue"
// "Your advance will arrive within 1-2 business days"
export const cashboostAddDebitCardCTAPrompt = "Your advance will arrive within 1-2 business days"
// "Please provide the debit card that is connected to your bank account."
export const cashboostAddDebitCardCardFailureFailed = "Please provide the debit card that is connected to your bank account."
// "Something happened. Please try again."
export const cashboostAddDebitCardCardFailureUnknownError = "Something happened. Please try again."
// "Your debit card information is incorrect."
export const cashboostAddDebitCardIncompleteInfoError = "Your debit card information is incorrect."
// "Enter your {{account}} debit card info to receive your payout."
export const cashboostAddDebitCardSubtitle = "Enter your {{account}} debit card info to receive your payout."
// "debit card"
export const cashboostAddDebitCardSubtitleBoldQuery = "debit card"
// "Transfer Details"
export const cashboostAddDebitCardTitle = "Transfer Details"
// "Enter address manually"
export const cashboostAddressEntryListNoAddressCTA = "Enter address manually"
// "No suggestions found"
export const cashboostAddressEntryListNoAddressTitle = "No suggestions found"
// "Enter your address"
export const cashboostAddressEntryListTextfieldPlaceholder = "Enter your address"
// "Enter your address"
export const cashboostAddressEntryTextfieldPlaceholder = "Enter your address"
// "What is your address?"
export const cashboostAddressEntryTitle = "What is your address?"
// "{{amt}} auto-pay {{date}}"
export const cashboostAdvanceDeliveredTileAutopayDate = "{{amt}} auto-pay {{date}}"
// "auto-pay"
export const cashboostAdvanceDeliveredTileAutopayDateHighlightQuery = "auto-pay"
// "{{amt}} due {{date}}"
export const cashboostAdvanceDeliveredTilePaybackDate = "{{amt}} due {{date}}"
// "{{amt}} due today"
export const cashboostAdvanceDeliveredTilePaybackDateToday = "{{amt}} due today"
// "{{amt}} overdue by {{days}}"
export const cashboostAdvanceDeliveredTilePaybackOverdueDate = "{{amt}} overdue by {{days}}"
// "Your advance was delivered"
export const cashboostAdvanceDeliveredTileTitle = "Your advance was delivered"
// "You received {{amt}} on {{date}}"
export const cashboostAdvanceRepaymentHistoryAdvanceDescription = "You received {{amt}} on {{date}}"
// "We’ll check your account everyday and withdraw the payment when funds become available. We’ll never overdraft your account."
export const cashboostAdvanceRepaymentHistoryAutoPayDescription = "We’ll check your account everyday and withdraw the payment when funds become available. We’ll never overdraft your account."
// "{{amt}} outstanding"
export const cashboostAdvanceRepaymentHistoryOutstandingItem = "{{amt}} outstanding"
// "Pay All Now"
export const cashboostAdvanceRepaymentHistoryPayAllNowCta = "Pay All Now"
// "Pay Remainder Now"
export const cashboostAdvanceRepaymentHistoryPayRemainderNowCta = "Pay Remainder Now"
// "Original payback was {{date}}"
export const cashboostAdvanceRepaymentHistoryPaybackDate = "Original payback was {{date}}"
// "{{amt}} paid"
export const cashboostAdvanceRepaymentHistoryPaymentItem = "{{amt}} paid"
// "Repayment overdue"
export const cashboostAdvanceRepaymentHistoryTileCta = "Repayment overdue"
// "Repayment schedule"
export const cashboostAdvanceRepaymentHistoryTitle = "Repayment schedule"
// "No need to worry if you don’t have funds in your bank account on your repayment date! We’ll automatically charge whenever we see the funds on or after your repayment date, so you can sit back and relax."
export const cashboostAutoPayInfoCanIChangeRepaymentBody = "No need to worry if you don’t have funds in your bank account on your repayment date! We’ll automatically charge whenever we see the funds on or after your repayment date, so you can sit back and relax."
// "Can I change my repayment date?"
export const cashboostAutoPayInfoCanIChangeRepaymentTitle = "Can I change my repayment date?"
// "Before our system collects your repayment, it will first check your account balance to ensure that it is safe to withdraw. If there are sufficient funds in your account, our system will automatically collect the repayment (with partial or full payments)."
export const cashboostAutoPayInfoHowItWorksBody = "Before our system collects your repayment, it will first check your account balance to ensure that it is safe to withdraw. If there are sufficient funds in your account, our system will automatically collect the repayment (with partial or full payments)."
// "How does auto payback work?"
export const cashboostAutoPayInfoHowItWorksTitle = "How does auto payback work?"
// "Pay now"
export const cashboostCardActionsViewPayNow = "Pay now"
// "Snooze"
export const cashboostCardActionsViewSnooze = "Snooze"
// "We’re still putting the finishing touches on your Grid Advance, please try again later."
export const cashboostCheckoutAmountNotReady = "We’re still putting the finishing touches on your Grid Advance, please try again later."
// "Continue"
export const cashboostContinueCTA = "Continue"
// "Grid Advance"
export const cashboostCreditComparisonCashboost = "Grid Advance"
// "With a credit line on your Grid Card, you get more cash, benefits, and flexibility."
export const cashboostCreditComparisonContent = "With a credit line on your Grid Card, you get more cash, benefits, and flexibility."
// "Credit Line"
export const cashboostCreditComparisonCreditLine = "Credit Line"
// "Get Grid Card"
export const cashboostCreditComparisonCta = "Get Grid Card"
// "Skip the delivery fees"
export const cashboostCreditComparisonTitle = "Skip the delivery fees"
// "Skip the wait with Credit Line"
export const cashboostCreditLineTeaser = "Skip the wait with Credit Line"
// "Get Started"
export const cashboostCreditLineTileFinishSetUpCTA = "Get Started"
// "Get started by requesting your Grid Card and setting up direct deposit."
export const cashboostCreditLineTileFinishSetUpSubtitle = "Get started by requesting your Grid Card and setting up direct deposit."
// "Unlock credit line"
export const cashboostCreditLineTileFinishSetUpTitle = "Unlock credit line"
// "Your Grid Advance progress has been converted into a Credit Line."
export const cashboostCreditLineTileSubtitle = "Your Grid Advance progress has been converted into a Credit Line."
// "You’ve unlocked Credit Line"
export const cashboostCreditLineTileTitle = "You’ve unlocked Credit Line"
// "View Progress"
export const cashboostCreditLineTileViewProgressCTA = "View Progress"
// "Get a credit line when you set up direct deposit and spend with your card."
export const cashboostCreditLineTileViewProgressSubtitle = "Get a credit line when you set up direct deposit and spend with your card."
// "Unlock credit line"
export const cashboostCreditLineTileViewProgressTitle = "Unlock credit line"
// "With Credit Line, you can get your money instantly, without the fees."
export const cashboostCreditLineUpsellFastDeliverySubtitle = "With Credit Line, you can get your money instantly, without the fees."
// "Skip the fees next time"
export const cashboostCreditLineUpsellFastDeliveryTitle = "Skip the fees next time"
// "With Credit Line, you can get your money instantly, without the wait."
export const cashboostCreditLineUpsellStandardDeliverySubtitle = "With Credit Line, you can get your money instantly, without the wait."
// "Skip the wait next time"
export const cashboostCreditLineUpsellStandardDeliveryTitle = "Skip the wait next time"
// "Get Grid Card"
export const cashboostCreditLineUpsellValuePropsCTA = "Get Grid Card"
// "Get up to $200"
export const cashboostCreditLineUpsellValuePropsCashboost1 = "Get up to $200"
// "Repay in full each time"
export const cashboostCreditLineUpsellValuePropsCashboost2 = "Repay in full each time"
// "Wait between advances"
export const cashboostCreditLineUpsellValuePropsCashboost3 = "Wait between advances"
// "$2.99 Fast Delivery fee"
export const cashboostCreditLineUpsellValuePropsCashboost4 = "$2.99 Fast Delivery fee"
// "Level up to $3000"
export const cashboostCreditLineUpsellValuePropsCreditLine1 = "Level up to $3000"
// "Make minimum payments"
export const cashboostCreditLineUpsellValuePropsCreditLine2 = "Make minimum payments"
// "Your line is always available"
export const cashboostCreditLineUpsellValuePropsCreditLine3 = "Your line is always available"
// "Rebuild credit and earn rewards"
export const cashboostCreditLineUpsellValuePropsCreditLine4 = "Rebuild credit and earn rewards"
// "With a credit line on your Grid Card, you get more cash, benefits, and flexibility."
export const cashboostCreditLineUpsellValuePropsSubtitle = "With a credit line on your Grid Card, you get more cash, benefits, and flexibility."
// "Switch to Credit Line"
export const cashboostCreditLineUpsellValuePropsTitle = "Switch to Credit Line"
// "Dismiss"
export const cashboostExtendedWaitInfoViewCTA = "Dismiss"
// "We’re having troubles with the payout to your debit card. We’re working with your bank to get this fixed. Your Payout might be delayed by 1-2 business days."
export const cashboostExtendedWaitInfoViewContent = "We’re having troubles with the payout to your debit card. We’re working with your bank to get this fixed. Your Payout might be delayed by 1-2 business days."
// "This is taking longer than expected"
export const cashboostExtendedWaitInfoViewTitle = "This is taking longer than expected"
// "Arriving {{date}}"
export const cashboostHistoryArrivingDate = "Arriving {{date}}"
// "History"
export const cashboostHistoryHeaderTitle = "History"
// "This is the end of your Advance history."
export const cashboostHistoryListScreenListFooter = "This is the end of your Advance history."
// "Your completed advances will appear here."
export const cashboostHistoryListScreenListFooterNoAdvances = "Your completed advances will appear here."
// "Paid in full"
export const cashboostHistoryListScreenPaidInFull = "Paid in full"
// "{{amt}} advance"
export const cashboostHistoryListScreenRowShortTitle = "{{amt}} advance"
// "Received {{date}}"
export const cashboostHistoryListScreenRowSubtitle = "Received {{date}}"
// "{{amt}} advance on {{date}}"
export const cashboostHistoryListScreenRowTitle = "{{amt}} advance on {{date}}"
// "Advance History"
export const cashboostHistoryListScreenShortTitle = "Advance History"
// "Advance History"
export const cashboostHistoryListScreenTitle = "Advance History"
// "Account"
export const cashboostHistoryReceiptScreenAccount = "Account"
// "Details"
export const cashboostHistoryReceiptScreenAdvanceDetails = "Details"
// "Amount"
export const cashboostHistoryReceiptScreenAmount = "Amount"
// "Amount owed"
export const cashboostHistoryReceiptScreenAmountOwed = "Amount owed"
// "Delivery Date"
export const cashboostHistoryReceiptScreenDeliveryDate = "Delivery Date"
// "Delivery Fee"
export const cashboostHistoryReceiptScreenDeliveryFee = "Delivery Fee"
// "Due Date"
export const cashboostHistoryReceiptScreenDueDate = "Due Date"
// "Problem with this transaction?"
export const cashboostHistoryReceiptScreenHelpCTA = "Problem with this transaction?"
// "Payout Date"
export const cashboostHistoryReceiptScreenPayoutDate = "Payout Date"
// "Repayment Date"
export const cashboostHistoryReceiptScreenRepaymentDate = "Repayment Date"
// "Repayment Details"
export const cashboostHistoryReceiptScreenRepaymentDetails = "Repayment Details"
// "Status"
export const cashboostHistoryReceiptScreenStatus = "Status"
// "Complete"
export const cashboostHistoryReceiptScreenStatusComplete = "Complete"
// "Advance payment"
export const cashboostHistoryReceiptScreenTitle = "Advance payment"
// "Total"
export const cashboostHistoryReceiptScreenTotal = "Total"
// "Received {{date}}"
export const cashboostHistoryReceivedDate = "Received {{date}}"
// "Arrived"
export const cashboostHistoryStatusArrived = "Arrived"
// "Pending"
export const cashboostHistoryStatusPending = "Pending"
// "In Repayment"
export const cashboostHistoryStatusRepayment = "In Repayment"
// "Your advance history"
export const cashboostHistoryTileTitle = "Your advance history"
// "Retry"
export const cashboostIDVContinueRetryCTA = "Retry"
// "Next"
export const cashboostIDVPopoverModalCTA = "Next"
// "Please upload photos of the front and back of a photo ID."
export const cashboostIDVPopoverModalTitle = "Please upload photos of the front and back of a photo ID."
// "• Driver’s license"
// "• State-issued ID card"
export const cashboostIDVPopoverValidIDList = "• Driver’s license\u000A• State-issued ID card"
// "Valid forms of ID are:"
export const cashboostIDVPopoverValidIDListHeader = "Valid forms of ID are:"
// "Please upload the following documents:"
export const cashboostIDVSubTitle = "Please upload the following documents:"
// "Upload Back of ID"
export const cashboostIDVTakePhotoBackCTA = "Upload Back of ID"
// "Take Photo…"
export const cashboostIDVTakePhotoCTA = "Take Photo…"
// "Upload Front of ID"
export const cashboostIDVTakePhotoFrontCTA = "Upload Front of ID"
// "We need additional information in order to verify your identity:"
export const cashboostIDVTitle = "We need additional information in order to verify your identity:"
// "PLEASE MAKE SURE THE ENTIRE ID IS CLEARLY VISIBLE"
export const cashboostIDVUploadSubTitle = "PLEASE MAKE SURE THE ENTIRE ID IS CLEARLY VISIBLE"
// "Review ID"
export const cashboostIDVUploadTitle = "Review ID"
// "Verification Error"
export const cashboostIDVUploadTitleAlt = "Verification Error"
// "BACK"
export const cashboostIDVUploadWellTitleBack = "BACK"
// "FRONT"
export const cashboostIDVUploadWellTitleFront = "FRONT"
// "Your advance will arrive by {{date}}"
export const cashboostLandingCardArrivalTitle = "Your advance will arrive by {{date}}"
// "Your advance will arrive in {{days}} days"
export const cashboostLandingCardTitle = "Your advance will arrive in {{days}} days"
// "Delayed"
export const cashboostLandingCardTitleDelayed = "Delayed"
// "Your advance will arrive soon"
export const cashboostLandingCardTitleFallback = "Your advance will arrive soon"
// "Your advance will arrive today"
export const cashboostLandingCardTitleToday = "Your advance will arrive today"
// "Your advance will arrive between 4-11pm today"
export const cashboostLandingCardTitleTodayDetailed = "Your advance will arrive between 4-11pm today"
// "Your advance will arrive tomorrow"
export const cashboostLandingCardTitleTomorrow = "Your advance will arrive tomorrow"
// "Your advance will arrive between 4-11pm tomorrow"
export const cashboostLandingCardTitleTomorrowDetailed = "Your advance will arrive between 4-11pm tomorrow"
// "Advance Amount"
export const cashboostLandingScreenAdvanceAmount = "Advance Amount"
// "Hooray!"
export const cashboostLandingScreenCTA = "Hooray!"
// "Close"
export const cashboostLandingScreenDelayedCTA = "Close"
// "We were unable to process your Fast Delivery. You won’t be charged the $2.99 fee. We apologize for the inconvenience."
export const cashboostLandingScreenDelayedSubTitle = "We were unable to process your Fast Delivery. You won’t be charged the $2.99 fee. We apologize for the inconvenience."
// "Your advance is delayed"
export const cashboostLandingScreenDelayedTitle = "Your advance is delayed"
// "Delivery date"
export const cashboostLandingScreenLandingDate = "Delivery date"
// "Due back on {{date}}"
export const cashboostLandingScreenRepaymentDate = "Due back on {{date}}"
// "You’ll get your"
// "money in {{days}} days!"
export const cashboostLandingScreenTitle = "You’ll get your\u000Amoney in {{days}} days!"
// "You’ll get your"
// "money soon!"
export const cashboostLandingScreenTitleFallback = "You’ll get your\u000Amoney soon!"
// "days!"
export const cashboostLandingScreenTitleMultipleDaysSuffix = "days!"
// "You’ll get your"
// "money today!"
export const cashboostLandingScreenTitleToday = "You’ll get your\u000Amoney today!"
// "You’ll get your"
// "money tomorrow!"
export const cashboostLandingScreenTitleTomorrow = "You’ll get your\u000Amoney tomorrow!"
// "You’ll get your"
// "money soon!"
export const cashboostLandingScreenTitleUnknownDays = "You’ll get your\u000Amoney soon!"
// "{{amt}} advance"
export const cashboostLevelTileContent = "{{amt}} advance"
// "Current"
export const cashboostLevelTileCurrent = "Current"
// "Level {{level}}"
export const cashboostLevelTileTitle = "Level {{level}}"
// "Done"
export const cashboostLevelsLearnMoreDoneButton = "Done"
// "Every level increases the maximum advance amount you can get."
export const cashboostLevelsLearnMoreMaxAmountDescription = "Every level increases the maximum advance amount you can get."
// "MAX AMOUNT"
export const cashboostLevelsLearnMoreMaxAmountTitle = "MAX AMOUNT"
// "Level up every month by paying on time. Pay your advance and subscription on time every month and receive a Level up!"
export const cashboostLevelsLearnMoreOverview = "Level up every month by paying on time. Pay your advance and subscription on time every month and receive a Level up!"
// "Need more time before you want to payback the advance - use a snooze and push back the repayment date."
export const cashboostLevelsLearnMoreSnoozesDescription = "Need more time before you want to payback the advance - use a snooze and push back the repayment date."
// "SNOOZES"
export const cashboostLevelsLearnMoreSnoozesTitle = "SNOOZES"
// "How do levels work?"
export const cashboostLevelsLearnMoreTitle = "How do levels work?"
// "Get Another Advance"
export const cashboostLevelsNewLevelCTA = "Get Another Advance"
// "Level {{level}}"
export const cashboostLevelsNewLevelLevelTitle = "Level {{level}}"
// "Congratulations! You unlocked Grid Advance Level {{level}}!"
export const cashboostLevelsNewLevelTitle = "Congratulations! You unlocked Grid Advance Level {{level}}!"
// "After Level 10, you’ll be invited to Credit Line, where you can unlock up to $3,000."
export const cashboostLevelsScreenBottomSubtitle = "After Level 10, you’ll be invited to Credit Line, where you can unlock up to $3,000."
// "Get Upgraded"
export const cashboostLevelsScreenBottomTitle = "Get Upgraded"
// "Close"
export const cashboostLevelsScreenCTA = "Close"
// "Get more every time you pay your Grid Advance back on time."
export const cashboostLevelsScreenSubtitle = "Get more every time you pay your Grid Advance back on time."
// "Unlock more cash"
export const cashboostLevelsScreenTitle = "Unlock more cash"
// "Pay back early or on-time to gain levels."
export const cashboostLevelsTileDescription = "Pay back early or on-time to gain levels."
// "Pay back on time to level up!"
export const cashboostLevelsTileDescriptionUpdated = "Pay back on time to level up!"
// "Boost your Grid Advance"
export const cashboostLevelsTileHeaderTitle = "Boost your Grid Advance"
// "Level {{lvl}}"
export const cashboostLevelsTileLevelLabel = "Level {{lvl}}"
// "{{amt}} max"
export const cashboostLevelsTileTitle = "{{amt}} max"
// "{{amt}} max"
export const cashboostLevelsTileTitleSingular = "{{amt}} max"
// "Get advances up to $200 by paying back early or on-time."
export const cashboostLevelsViewLevelsContent = "Get advances up to $200 by paying back early or on-time."
// "Level up every month by paying on time. Pay your advance and subscription on time every month and receive a Level up!"
export const cashboostLevelsViewLevelsContentUpdated = "Level up every month by paying on time. Pay your advance and subscription on time every month and receive a Level up!"
// "LEVELS"
export const cashboostLevelsViewLevelsHeader = "LEVELS"
// "Higher levels,"
// "more boost"
export const cashboostLevelsViewTitle = "Higher levels,\u000Amore boost"
// "Hang Tight"
export const cashboostLoadingTileHeader = "Hang Tight"
// "We are calculating your Grid Advance"
export const cashboostLoadingTileSubheader = "We are calculating your Grid Advance"
// "Subscription Requirements"
export const cashboostMembershipAccountRequirementsListHeaderRowTitle = "Subscription Requirements"
// "Grid Advance"
export const cashboostNavigationCashboostTitle = "Grid Advance"
// "Grid+"
export const cashboostNavigationTitle = "Grid+"
// "Close"
export const cashboostNewAdvanceSuccessCTA = "Close"
// "With Fast Delivery, your advance should arrive within 8 hours."
export const cashboostNewAdvanceSuccessSubtitleFastDelivery = "With Fast Delivery, your advance should arrive within 8 hours."
// "With Standard Delivery, your advance should arrive within 3 business days."
export const cashboostNewAdvanceSuccessSubtitleStandardThreeDay = "With Standard Delivery, your advance should arrive within 3 business days."
// "Your Advance is on the way!"
export const cashboostNewAdvanceSuccessTitle = "Your Advance is on the way!"
// "Need help? Reach out via chat."
export const cashboostOffboardingTileDoesNotQualifyCTA = "Need help? Reach out via chat."
// "We’re working hard to open up Grid Advance to more users. We’ll check your qualifications every 2 weeks, check back anytime."
export const cashboostOffboardingTileDoesNotQualifyContent = "We’re working hard to open up Grid Advance to more users. We’ll check your qualifications every 2 weeks, check back anytime."
// "Not Available"
export const cashboostOffboardingTileDoesNotQualifySubtitle = "Not Available"
// "You don’t currently qualify for Grid Advance."
export const cashboostOffboardingTileDoesNotQualifyTitle = "You don’t currently qualify for Grid Advance."
// "Advance Amount"
export const cashboostOfferAcceptAdvanceAmount = "Advance Amount"
// "I agree to this condition"
export const cashboostOfferAcceptAgreementRadioAgreeLabel = "I agree to this condition"
// "One-time cash advance"
export const cashboostOfferAcceptAmountLabelSubtitleButton = "One-time cash advance"
// "Grid Card"
export const cashboostOfferAcceptBoostCard = "Grid Card"
// "Unlimited Grid Advances"
export const cashboostOfferAcceptBulletUnlimitedCashboosts = "Unlimited Grid Advances"
// "(coming soon)"
export const cashboostOfferAcceptComingSoon = "(coming soon)"
// "Confirm"
export const cashboostOfferAcceptContinueCTA = "Confirm"
// "Delivery Fee"
export const cashboostOfferAcceptDeliveryFee = "Delivery Fee"
// "Arriving Today"
export const cashboostOfferAcceptFastDeliveryArrival = "Arriving Today"
// "Your Grid+ subscription includes:"
export const cashboostOfferAcceptListTitle = "Your Grid+ subscription includes:"
// "{{amt}} / month"
export const cashboostOfferAcceptMembershipFeeAmount = "{{amt}} / month"
// "Your Grid+ subscription fee is billed directly to your connected bank account or charged to your debit card."
export const cashboostOfferAcceptMembershipFeeDescription = "Your Grid+ subscription fee is billed directly to your connected bank account or charged to your debit card."
// "Subscription fee:"
export const cashboostOfferAcceptMembershipFeeTitle = "Subscription fee:"
// "PayBoost"
export const cashboostOfferAcceptPayBoost = "PayBoost"
// "Repay on {{date}}"
export const cashboostOfferAcceptRepayment = "Repay on {{date}}"
// "Repayment Snooze"
export const cashboostOfferAcceptRepaymentSnooze = "Repayment Snooze"
// "Arriving by, {{date}}"
export const cashboostOfferAcceptStandardDeliveryArrival = "Arriving by, {{date}}"
// "Tax projection"
export const cashboostOfferAcceptTaxProjection = "Tax projection"
// "We cannot cancel after you confirm. By confirming, you agree to the Grid+ Terms as well as the Stripe Connect Terms and authorize Grid to withdraw {{amt}} from your next paycheck or bank account on or after your next pay day."
export const cashboostOfferAcceptTerms = "We cannot cancel after you confirm. By confirming, you agree to the Grid+ Terms as well as the Stripe Connect Terms and authorize Grid to withdraw {{amt}} from your next paycheck or bank account on or after your next pay day."
// "the advance amount"
export const cashboostOfferAcceptTermsAdvanceAmountFallback = "the advance amount"
// ""
export const cashboostOfferAcceptTermsBoldAlgoQuery = ""
// "Grid+ Terms,Stripe Connect Terms"
export const cashboostOfferAcceptTermsTermsLinkAlgoQuery = "Grid+ Terms,Stripe Connect Terms"
// "https://getgrid.app/terms,https://stripe.com/connect-account/legal"
export const cashboostOfferAcceptTermsTermsLinkAlgoQueryTermsAttribution = "https://getgrid.app/terms,https://stripe.com/connect-account/legal"
// "We cannot cancel after you confirm. By confirming, you agree to the Grid+ Terms as well as the Stripe Connect Terms and authorize Grid to withdraw {{amt}} from your bank account(s) ending in {{accounts}} on or after your next payday."
export const cashboostOfferAcceptTermsWithAccounts = "We cannot cancel after you confirm. By confirming, you agree to the Grid+ Terms as well as the Stripe Connect Terms and authorize Grid to withdraw {{amt}} from your bank account(s) ending in {{accounts}} on or after your next payday."
// "We cannot cancel after you confirm."
export const cashboostOfferAcceptTermsWithAccountsBoldKey = "We cannot cancel after you confirm."
// "We cannot cancel after you confirm. By confirming, you agree to the Grid+ Terms as well as the Stripe Connect Terms and authorize Grid to withdraw {{amt}} from your Grid account and bank account(s) ending in {{accounts}} on or after your next payday."
export const cashboostOfferAcceptTermsWithAccountsWithGrid = "We cannot cancel after you confirm. By confirming, you agree to the Grid+ Terms as well as the Stripe Connect Terms and authorize Grid to withdraw {{amt}} from your Grid account and bank account(s) ending in {{accounts}} on or after your next payday."
// "Sign up for Grid+ to unlock your Grid Advance!"
export const cashboostOfferAcceptTitle = "Sign up for Grid+ to unlock your Grid Advance!"
// "Total"
export const cashboostOfferAcceptTotal = "Total"
// "Your payment was received and is being processed. You can request another advance in 1-3 business days."
export const cashboostOfferAdvanceOfferCooldown = "Your payment was received and is being processed. You can request another advance in 1-3 business days."
// "{{amt}} Cash Advance"
export const cashboostOfferAmount = "{{amt}} Cash Advance"
// "Deliver to"
export const cashboostOfferDeliveryDestinationLabel = "Deliver to"
// "You qualify for a"
export const cashboostOfferDeliverySelectorTitle = "You qualify for a"
// "You should receive your advance within 3 business days."
export const cashboostOfferFastUnavailableInfoBody = "You should receive your advance within 3 business days."
// "$2.99"
export const cashboostOfferInstantButtonPrice = "$2.99"
// "Fast Delivery"
export const cashboostOfferInstantButtonTitle = "Fast Delivery"
// "With Fast Delivery, you can get your advance within 8 hours on any day."
export const cashboostOfferInstantInfoBody = "With Fast Delivery, you can get your advance within 8 hours on any day."
// "Need money fast?"
export const cashboostOfferInstantInfoTitle = "Need money fast?"
// "Overview"
export const cashboostOfferOverviewTitle = "Overview"
// "Repayment Date"
export const cashboostOfferRepaymentDateLabel = "Repayment Date"
// "Account"
export const cashboostOfferScreenAccountLabel = "Account"
// "{{amt}} Advance"
export const cashboostOfferScreenAdvanceAmountLabel = "{{amt}} Advance"
// "Calculating…"
export const cashboostOfferScreenAdvanceCalculating = "Calculating…"
// "Arriving on {{date}}"
export const cashboostOfferScreenArrivalDate = "Arriving on {{date}}"
// "Calculating…"
export const cashboostOfferScreenArrivalDateLabelCalculating = "Calculating…"
// "Arriving {{date}} to {{bank}}"
export const cashboostOfferScreenArrivalInfo = "Arriving {{date}} to {{bank}}"
// "Auto Payback"
export const cashboostOfferScreenAutoPayCTA = "Auto Payback"
// "Auto Payback"
export const cashboostOfferScreenAutoPaybackLabel = "Auto Payback"
// "Arriving to {{bank}}"
export const cashboostOfferScreenBankAccountLabel = "Arriving to {{bank}}"
// "Continue"
export const cashboostOfferScreenContinueCTA = "Continue"
// "Your Grid Advance offer:"
export const cashboostOfferScreenOfferTitle = "Your Grid Advance offer:"
// "Payback"
export const cashboostOfferScreenPaybackDateLabel = "Payback"
// "Confirm"
export const cashboostOfferScreenSubsequentCTALabel = "Confirm"
// "Here is your"
// "Grid Advance offer"
export const cashboostOfferScreenTitle = "Here is your\u000AGrid Advance offer"
// "FREE"
export const cashboostOfferStandardButtonPrice = "FREE"
// "Standard Delivery"
export const cashboostOfferStandardButtonTitle = "Standard Delivery"
// "With Standard Delivery, you will get your advance in up to 3 business days."
export const cashboostOfferStandardInfoBody = "With Standard Delivery, you will get your advance in up to 3 business days."
// "Wait and save."
export const cashboostOfferStandardInfoTitle = "Wait and save."
// "This is taking longer than expected!"
export const cashboostOfferTakingTooLongContent = "This is taking longer than expected!"
// "Check back in 15 minutes…"
export const cashboostOfferTakingTooLongTitle = "Check back in 15 minutes…"
// "When will it arrive?"
export const cashboostOfferWhenWillItArrive = "When will it arrive?"
// "Advance amount"
export const cashboostPayNowAdvanceAmountTitle = "Advance amount"
// "Pay back {{amt}}"
export const cashboostPayNowConfirmationCTATitle = "Pay back {{amt}}"
// "Your Grid Advance"
export const cashboostPayNowConfirmationCashboost = "Your Grid Advance"
// "+ Change payment method"
export const cashboostPayNowConfirmationChangePaymentMethod = "+ Change payment method"
// "Level up every month by paying on time!"
export const cashboostPayNowConfirmationPayEarlyPrompt = "Level up every month by paying on time!"
// "Do you want to pay your"
// "advance back now?"
export const cashboostPayNowConfirmationTitle = "Do you want to pay your\u000Aadvance back now?"
// "Transfer From"
export const cashboostPayNowConfirmationTransferFrom = "Transfer From"
// "Delivery Fee"
export const cashboostPayNowDeliveryFeeTitle = "Delivery Fee"
// "Dismiss"
export const cashboostPayNowErrorDismissCTA = "Dismiss"
// "Your payment was declined by your bank. Please reach out to us to process your payment."
export const cashboostPayNowErrorInsufficientFundsContent = "Your payment was declined by your bank. Please reach out to us to process your payment."
// "Oops!"
export const cashboostPayNowErrorInsufficientFundsTitle = "Oops!"
// "Your payment is being finalized."
export const cashboostPayNowLoadingTitle = "Your payment is being finalized."
// "Get Another Advance"
export const cashboostPayNowSuccessAnotherAdvanceCTA = "Get Another Advance"
// "Great job, you’re all paid up and can get another advance whenever you need it."
export const cashboostPayNowSuccessContent = "Great job, you’re all paid up and can get another advance whenever you need it."
// "Close"
export const cashboostPayNowSuccessNoCtaButton = "Close"
// "Your payment has been submitted and is currently processing."
export const cashboostPayNowSuccessNoCtaContent = "Your payment has been submitted and is currently processing."
// "Thank you for your payment"
export const cashboostPayNowSuccessNoCtaTitle = "Thank you for your payment"
// "Skip"
export const cashboostPayNowSuccessSkipCTA = "Skip"
// "You’re all set!"
export const cashboostPayNowSuccessTitle = "You’re all set!"
// "Total Due"
export const cashboostPayNowTotalDueTitle = "Total Due"
// "Transfer from"
export const cashboostPayNowTransferFromTitle = "Transfer from"
// "You are qualified to get a Grid Card if you connect your main bank account"
export const cashboostPlaidIntroBoostcardTitle = "You are qualified to get a Grid Card if you connect your main bank account"
// "Connect your primary bank account"
export const cashboostPlaidIntroBulletOne = "Connect your primary bank account"
// "See account requirements ›"
export const cashboostPlaidIntroBulletOneCTA = "See account requirements ›"
// "1"
export const cashboostPlaidIntroBulletOneNumber = "1"
// "Verify your identity"
export const cashboostPlaidIntroBulletThree = "Verify your identity"
// "3"
export const cashboostPlaidIntroBulletThreeNumber = "3"
// "Set up transfer details"
export const cashboostPlaidIntroBulletTwo = "Set up transfer details"
// "2"
export const cashboostPlaidIntroBulletTwoNumber = "2"
// "Continue"
export const cashboostPlaidIntroCTA = "Continue"
// "Three simple steps to get your Grid Advance"
export const cashboostPlaidIntroTitle = "Three simple steps to get your Grid Advance"
// "300 days active"
export const cashboostPlaidIntroWithCriteria60Days = "300 days active"
// "Connectivity to Grid"
export const cashboostPlaidIntroWithCriteriaConnectivityToGrid = "Connectivity to Grid"
// "Consistent deposits"
export const cashboostPlaidIntroWithCriteriaConsistentDeposits = "Consistent deposits"
// "Consistent spending"
export const cashboostPlaidIntroWithCriteriaConsistentSpending = "Consistent spending"
// "Minimum $1,000 deposits per month"
export const cashboostPlaidIntroWithCriteriaMinimum1KDeposits = "Minimum $1,000 deposits per month"
// "Minimum $1,000 spending per month"
export const cashboostPlaidIntroWithCriteriaMinimum1KSpending = "Minimum $1,000 spending per month"
// "Connect your primary bank account so we can get you your Grid Advance quickly."
export const cashboostPlaidIntroWithCriteriaTitle = "Connect your primary bank account so we can get you your Grid Advance quickly."
// "Check back in a few minutes."
export const cashboostQualificationStepProcessingContent = "Check back in a few minutes."
// "We need more time to verify your account."
export const cashboostQualificationStepProcessingTitle = "We need more time to verify your account."
// ""
export const cashboostRewardsIntroAfterLevel10 = ""
// "Close"
export const cashboostRewardsIntroCloseCTA = "Close"
// "Continue"
export const cashboostRewardsIntroContinueCTA = "Continue"
// "Get Upgraded"
export const cashboostRewardsIntroGetUpgraded = "Get Upgraded"
// "Learn more about Levels"
export const cashboostRewardsIntroLearnMoreCTA = "Learn more about Levels"
// "{{amt}} advance"
export const cashboostRewardsIntroRowAdvance = "{{amt}} advance"
// "Level up every time you repay your Advance early or on time"
export const cashboostRewardsIntroSubtitle = "Level up every time you repay your Advance early or on time"
// "Get more every time you pay your Grid Advance back on time."
export const cashboostRewardsIntroSubtitleUpdated = "Get more every time you pay your Grid Advance back on time."
// "Get up to $200!"
export const cashboostRewardsIntroTitle = "Get up to $200!"
// "Unlock more cash"
export const cashboostRewardsIntroTitleUpdated = "Unlock more cash"
// "You are currently on a FREE Subscription"
export const cashboostRiskListFreeMembershipBanner = "You are currently on a FREE Subscription"
// "If this is not your primary account (you receive deposits here), then connect your primary account instead."
// ""
// "If this account is your primary account, please check back and try again after you receive your next paycheck."
export const cashboostRiskListModal1KDepositsContent = "If this is not your primary account (you receive deposits here), then connect your primary account instead.\u000A\u000AIf this account is your primary account, please check back and try again after you receive your next paycheck."
// "You must receive a minimum of $1,000 deposits per month."
export const cashboostRiskListModal1KDepositsTitle = "You must receive a minimum of $1,000 deposits per month."
// "Connect a different account showing consistent and regular purchases."
// ""
// "If the connected account is your primary account that you use for daily spending, please check back with us in a few days."
export const cashboostRiskListModal1KWithdrawlsContent = "Connect a different account showing consistent and regular purchases.\u000A\u000AIf the connected account is your primary account that you use for daily spending, please check back with us in a few days."
// "You must spend a minimum of $1,000 per month from this account."
export const cashboostRiskListModal1KWithdrawlsTitle = "You must spend a minimum of $1,000 per month from this account."
// "If you have an account with consistent deposits and a transaction history of at least 300 days, please connect that one."
// ""
// "If you have connected a valid account and it’s not working, please try again in 48 hours."
export const cashboostRiskListModal60DaysActiveContent = "If you have an account with consistent deposits and a transaction history of at least 300 days, please connect that one.\u000A\u000AIf you have connected a valid account and it’s not working, please try again in 48 hours."
// "Your account needs to have been opened at least 300 days ago."
export const cashboostRiskListModal60DaysActiveTitle = "Your account needs to have been opened at least 300 days ago."
// "If this is not your primary account (you receive deposits here), then connect your primary account instead."
// ""
// "If this account is your primary account, please check back and try again in a few weeks."
export const cashboostRiskListModalConsistentDepositsContent = "If this is not your primary account (you receive deposits here), then connect your primary account instead.\u000A\u000AIf this account is your primary account, please check back and try again in a few weeks."
// "You must receive direct deposits into this account."
export const cashboostRiskListModalConsistentDepositsTitle = "You must receive direct deposits into this account."
// "Connect a different account showing consistent and regular deposits and purchases."
// ""
// "If the connected account is your primary account that you use for daily spending, please check back with us in a few days."
export const cashboostRiskListModalConsistentSpendingContent = "Connect a different account showing consistent and regular deposits and purchases.\u000A\u000AIf the connected account is your primary account that you use for daily spending, please check back with us in a few days."
// "Your account must show consistent spending activity."
export const cashboostRiskListModalConsistentSpendingTitle = "Your account must show consistent spending activity."
// "If you have another account that is able to connect with Grid, please connect that one."
// ""
// "If you have connected a valid account and it’s not working, please try again in 24-48 hours."
export const cashboostRiskListModalGridConnectivityContent = "If you have another account that is able to connect with Grid, please connect that one.\u000A\u000AIf you have connected a valid account and it’s not working, please try again in 24-48 hours."
// "Your bank needs to be able to connect with Grid."
export const cashboostRiskListModalGridConnectivityTitle = "Your bank needs to be able to connect with Grid."
// "What can I do?"
export const cashboostRiskListModalGridWhatCanIDoTitle = "What can I do?"
// "Has at least $1,000 in deposits per month"
export const cashboostSetupBankAccountError1KDepositsRequirement = "Has at least $1,000 in deposits per month"
// "Has at least $1,000 in spending per month"
export const cashboostSetupBankAccountError1KSpendingRequirement = "Has at least $1,000 in spending per month"
// "Active and in good standing"
export const cashboostSetupBankAccountErrorActiveAndInGoodStandingRequirement = "Active and in good standing"
// "Connect a Different Account"
export const cashboostSetupBankAccountErrorCTA = "Connect a Different Account"
// "Has consistent deposits"
export const cashboostSetupBankAccountErrorConsistentDepositsRequirement = "Has consistent deposits"
// "Has consistent spending"
export const cashboostSetupBankAccountErrorConsistentSpendingRequirement = "Has consistent spending"
// "We’re working hard to support these banks. We really hope you can join our Grid family soon and look forward to servicing you!"
export const cashboostSetupBankAccountErrorDescription = "We’re working hard to support these banks. We really hope you can join our Grid family soon and look forward to servicing you!"
// "Is active for at least 300 days"
export const cashboostSetupBankAccountErrorMinLengthRequirement = "Is active for at least 300 days"
// "There was an issue with your bank account"
export const cashboostSetupBankAccountErrorTitle = "There was an issue with your bank account"
// "Your bank allows connectivity to Grid"
export const cashboostSetupBankAccountErrorUnsupportedBankAccountRequirement = "Your bank allows connectivity to Grid"
// "Connect a Different Account"
export const cashboostSetupBankAccountExistsOnClientErrorCTA = "Connect a Different Account"
// "It looks like you’ve already connected this account. Please connect a different account or reach out to us. Thanks!"
export const cashboostSetupBankAccountExistsOnClientErrorContent = "It looks like you’ve already connected this account. Please connect a different account or reach out to us. Thanks!"
// "Bank already linked to your Grid Account."
export const cashboostSetupBankAccountExistsOnClientErrorTitle = "Bank already linked to your Grid Account."
// "Connect a bank account"
export const cashboostSetupBankAccountInstitutionUnsupportedCTA = "Connect a bank account"
// "Unfortunately we couldn‘t connect to your bank account. Please try again and if this error still exists try a different bank account."
export const cashboostSetupBankAccountInstitutionUnsupportedContent = "Unfortunately we couldn‘t connect to your bank account. Please try again and if this error still exists try a different bank account."
// "There was an error connecting your bank account"
export const cashboostSetupBankAccountInstitutionUnsupportedTitle = "There was an error connecting your bank account"
// "• Dave"
// "• Chime"
// "• Capital One (NetSpend)"
// "• Varo"
export const cashboostSetupBankAccountUnsupportedBlacklistItems = "• Dave\u000A• Chime\u000A• Capital One (NetSpend)\u000A• Varo"
// "Coming Soon: {{date}}"
export const cashboostSetupBankAccountUnsupportedBlacklistTitle = "Coming Soon: {{date}}"
// "If you have another primary account with direct deposits, please connect that account."
export const cashboostSetupBankAccountUnsupportedCtaContent = "If you have another primary account with direct deposits, please connect that account."
// "What can I do?"
export const cashboostSetupBankAccountUnsupportedCtaTitle = "What can I do?"
// "We don’t currently support your bank"
export const cashboostSetupBankAccountUnsupportedTitle = "We don’t currently support your bank"
// "Active and in good standing"
export const cashboostSetupDebitErrorActiveAccountRequirement = "Active and in good standing"
// "Connected to the bank account provided"
export const cashboostSetupDebitErrorConnectedToBankAccountRequirement = "Connected to the bank account provided"
// "Oops! Please complete setting up your Grid Advance before changing your default bank account."
export const cashboostSetupNeedsFinished = "Oops! Please complete setting up your Grid Advance before changing your default bank account."
// "Must be active (not expired)"
export const cashboostSetupSubsequentAdvanceActiveAccountRequirement = "Must be active (not expired)"
// "Must have sufficient funds to cover subscription payment"
export const cashboostSetupSubsequentSufficientFundsAccountRequirement = "Must have sufficient funds to cover subscription payment"
// "Continue"
export const cashboostSocialSecurityContinueCTA = "Continue"
// "Grid uses this information to make sure no one but you can request an advance under your name."
export const cashboostSocialSecurityInfoDescription = "Grid uses this information to make sure no one but you can request an advance under your name."
// "Why do we need your Social Security Number?"
export const cashboostSocialSecurityInfoTitle = "Why do we need your Social Security Number?"
// "Why do you need my SSN?"
export const cashboostSocialSecurityQuestionCTA = "Why do you need my SSN?"
// "What are the last 4 digits of"
// "your Social Security"
// "Number?"
export const cashboostSocialSecurityTitle = "What are the last 4 digits of\u000Ayour Social Security\u000ANumber?"
// "Your advance has been delivered"
export const cashboostStatusTileDeliveredTitle = "Your advance has been delivered"
// "It should arrive within 8 hours."
export const cashboostStatusTileFastArrivalEst = "It should arrive within 8 hours."
// "It should arrive by {{date}}."
export const cashboostStatusTileStandardArrivalEst = "It should arrive by {{date}}."
// "Continue"
export const cashboostStepCompletionContinueCTA = "Continue"
// "Success! You’re all set to get your Grid Advance!"
export const cashboostStepCompletionStepAll = "Success! You’re all set to get your Grid Advance!"
// "Success! Your bank"
// "account is connected."
export const cashboostStepCompletionStepBankAccount = "Success! Your bank\u000Aaccount is connected."
// "Connect your primary bank account"
export const cashboostStepCompletionStepOneBulletTitle = "Connect your primary bank account"
// "Verify your identity"
export const cashboostStepCompletionStepThreeBulletTitle = "Verify your identity"
// "Success! Your debit"
// "card is verified."
export const cashboostStepCompletionStepTransferDetails = "Success! Your debit\u000Acard is verified."
// "Set up transfer details"
export const cashboostStepCompletionStepTwoBulletTitle = "Set up transfer details"
// "We’re verifying your account"
export const cashboostStepProcessingBankAccountTitle = "We’re verifying your account"
// "This should take about 10 seconds…"
export const cashboostStepProcessingGenericSubtitle = "This should take about 10 seconds…"
// "We’re verifying your identity"
export const cashboostStepProcessingIdentityTitle = "We’re verifying your identity"
// "We’re verifying your debit card"
export const cashboostStepProcessingtransferDetailsTitle = "We’re verifying your debit card"
// "You do not currently qualify for an Advance. Please reach out to our support team at the top right hand corner if you have any questions."
export const cashboostSubsequentAdvanceIneligibleError = "You do not currently qualify for an Advance. Please reach out to our support team at the top right hand corner if you have any questions."
// "We just need you to confirm your identity"
export const cashboostSubsequentAdvanceMigrationIntroTitle = "We just need you to confirm your identity"
// "Change"
export const cashboostSubsequentAdvanceTileButtonPrimarySecondaryText = "Change"
// "Continue"
export const cashboostSubsequentAdvanceTileButtonPrimaryText = "Continue"
// "Would you like the same Advance again?"
export const cashboostSubsequentAdvanceTileContent = "Would you like the same Advance again?"
// "Account"
export const cashboostSubsequentOfferAcceptAccount = "Account"
// "Continue"
export const cashboostSubsequentOfferAcceptCTA = "Continue"
// "Payback"
export const cashboostSubsequentOfferAcceptPayback = "Payback"
// "By confirming your Advance, you agree to Grid’s Terms as well as the Stripe Connect Terms."
export const cashboostSubsequentOfferAcceptTerms = "By confirming your Advance, you agree to Grid’s Terms as well as the Stripe Connect Terms."
// ""
export const cashboostSubsequentOfferAcceptTermsBoldAlgoQuery = ""
// "We cannot cancel after you confirm."
export const cashboostSubsequentOfferAcceptTermsBoldKey = "We cannot cancel after you confirm."
// "Grid’s Terms,Stripe Connect Terms"
export const cashboostSubsequentOfferAcceptTermsTermsLinkAlgoQuery = "Grid’s Terms,Stripe Connect Terms"
// "https://getgrid.app/terms,https://stripe.com/connect-account/legal"
export const cashboostSubsequentOfferAcceptTermsTermsLinkAlgoQueryTermsAttribution = "https://getgrid.app/terms,https://stripe.com/connect-account/legal"
// "We cannot cancel after you confirm. By confirming, you authorize Grid to withdraw {{amt}} from your bank account(s) ending in {{accounts}} on or after your next payday."
export const cashboostSubsequentOfferAcceptTermsWithAccounts = "We cannot cancel after you confirm. By confirming, you authorize Grid to withdraw {{amt}} from your bank account(s) ending in {{accounts}} on or after your next payday."
// "We cannot cancel after you confirm. By confirming, you authorize Grid to withdraw {{amt}} from your Grid account and your bank account(s) ending in {{accounts}} on or after your next payday."
export const cashboostSubsequentOfferAcceptTermsWithAccountsAndGrid = "We cannot cancel after you confirm. By confirming, you authorize Grid to withdraw {{amt}} from your Grid account and your bank account(s) ending in {{accounts}} on or after your next payday."
// "{{amt}} Cash Advance"
export const cashboostSubsequentOfferAcceptTitle = "{{amt}} Cash Advance"
// "Please complete your address"
export const cashboostSupplementalAddressEntryTextfieldPlaceholder = "Please complete your address"
// "Account Error"
export const cashboostTileAccountError = "Account Error"
// "There’s an issue with your"
// "bank account."
export const cashboostTileAccountErrorBankAccount = "There’s an issue with your\u000Abank account."
// "View"
export const cashboostTileAccountErrorBannerCTA = "View"
// "There’s an issue with the"
// "debit card you provided."
export const cashboostTileAccountErrorDebitCard = "There’s an issue with the\u000Adebit card you provided."
// "Sorry, there was an issue with your payout."
// "We will reach out to you shortly."
export const cashboostTileAccountErrorPayoutException = "Sorry, there was an issue with your payout.\u000AWe will reach out to you shortly."
// "Your advance is processing…"
export const cashboostTileAdvanceProcessing = "Your advance is processing…"
// "Your advance is taking"
// "longer than expected."
export const cashboostTileBannerExtendedWait = "Your advance is taking\u000Alonger than expected."
// "See More"
export const cashboostTileBannerExtendedWaitCTA = "See More"
// "Pay back early and get $10 more with your next advance."
export const cashboostTileBannerPayBackEarly = "Pay back early and get $10 more with your next advance."
// "Verify"
export const cashboostTileIDVBannerCTA = "Verify"
// "We couldn’t verify your ID"
export const cashboostTileIDVBannerInitialTitle = "We couldn’t verify your ID"
// "Our team is reviewing your ID, this will take 2 business days"
export const cashboostTileIDVBannerProcessingTitle = "Our team is reviewing your ID, this will take 2 business days"
// "Your ID couldn’t be verified. Please try again."
export const cashboostTileIDVBannerSubsequentTitle = "Your ID couldn’t be verified. Please try again."
// "Your subscription payment is overdue"
export const cashboostTileMembershipError = "Your subscription payment is overdue"
// "Get your Grid Card"
export const cashboostToBoostCardCTA = "Get your Grid Card"
// "Get your Grid Card today and start building your credit line. Grid Card is free and you’re already approved."
export const cashboostToBoostCardContent = "Get your Grid Card today and start building your credit line. Grid Card is free and you’re already approved."
// "approved"
export const cashboostToBoostCardContentHighlightQueryApproved = "approved"
// "free"
export const cashboostToBoostCardContentHighlightQueryFree = "free"
// "Build up to $3,000 credit line within one year"
export const cashboostToBoostCardTitle = "Build up to $3,000 credit line within one year"
// "$3,000"
export const cashboostToBoostCardTitleHighlightQuery = "$3,000"
// "Delivered {{date}} to {{account}}"
export const cashboostToCreditLineAdvanceDeliveryInfo = "Delivered {{date}} to {{account}}"
// "Advance Repayment"
export const cashboostToCreditLineAdvanceRepayment = "Advance Repayment"
// "Continue"
export const cashboostToCreditLineCta = "Continue"
// "First payment due {{date}}"
export const cashboostToCreditLineFirstPayment = "First payment due {{date}}"
// "Grid Plus Subscription"
export const cashboostToCreditLineGridPlusSubscription = "Grid Plus Subscription"
// "Billed {{date}}"
export const cashboostToCreditLineGridPlusSubscriptionInfo = "Billed {{date}}"
// "Grid Credit"
export const cashboostToCreditLineHeader = "Grid Credit"
// "Subscription Reactivation Fee"
export const cashboostToCreditLineReactivationFee = "Subscription Reactivation Fee"
// "Remaining Credit Line"
export const cashboostToCreditLineRemainingCreditLine = "Remaining Credit Line"
// "Starting Credit Line"
export const cashboostToCreditLineStartingCreditLimit = "Starting Credit Line"
// "Your {{amt}} balance will be transferred to your credit line, giving you more time to pay it back."
export const cashboostToCreditLineSubtitle = "Your {{amt}} balance will be transferred to your credit line, giving you more time to pay it back."
// "Transfer your outstanding Grid balance"
export const cashboostToCreditLineTitle = "Transfer your outstanding Grid balance"
// "Link different account"
export const cashboostUnsupportedBankAccountCTA = "Link different account"
// "We don‘t support your bank. Apologies for the inconvenience."
export const cashboostUnsupportedBankAccountTitle = "We don‘t support your bank. Apologies for the inconvenience."
// "Continue"
export const cashboostVerifyIdentityContinueCTA = "Continue"
// "Date of Birth (MM / DD / YYYY)"
export const cashboostVerifyIdentityDOBPlaceholder = "Date of Birth (MM / DD / YYYY)"
// "Date of Birth"
export const cashboostVerifyIdentityDOBTitle = "Date of Birth"
// "Marko"
export const cashboostVerifyIdentityFirstNamePlaceholder = "Marko"
// "Legal First Name"
export const cashboostVerifyIdentityFirstNameTitle = "Legal First Name"
// "Please enter a valid date of birth"
export const cashboostVerifyIdentityInvalidDOB = "Please enter a valid date of birth"
// "Please enter the last four digits of your Social Security Number correctly."
export const cashboostVerifyIdentityInvalidSSN = "Please enter the last four digits of your Social Security Number correctly."
// "Polo"
export const cashboostVerifyIdentityLastNamePlaceholder = "Polo"
// "Legal Last Name"
export const cashboostVerifyIdentityLastNameTitle = "Legal Last Name"
// "Please verify your legal name and date of birth"
export const cashboostVerifyIdentityTitle = "Please verify your legal name and date of birth"
// "View Rewards"
export const celebration2XRewardsCTATitle = "View Rewards"
// "Your qualifying direct deposit has unlocked 2x points on all purchases made this month"
export const celebration2XRewardsSubtitle = "Your qualifying direct deposit has unlocked 2x points on all purchases made this month"
// "made this month"
export const celebration2XRewardsSubtitleBold = "made this month"
// "You’ve unlocked 2x rewards!"
export const celebration2XRewardsTitle = "You’ve unlocked 2x rewards!"
// "View Credit Line"
export const celebrationCreditLineCTATitle = "View Credit Line"
// "Your qualifying direct deposit has unlocked your credit line. Say hello to more cash, benefits, and flexibility."
export const celebrationCreditLineSubtitle = "Your qualifying direct deposit has unlocked your credit line. Say hello to more cash, benefits, and flexibility."
// "You’ve unlocked"
// "Credit Line!"
export const celebrationCreditLineTitle = "You’ve unlocked\u000ACredit Line!"
// "Close"
export const celebrationCreditLineUpgradeCTATitle = "Close"
// "NEW CREDIT LINE LIMIT"
export const celebrationCreditLineUpgradeSubtitle = "NEW CREDIT LINE LIMIT"
// "You’ve been"
// "upgraded!"
export const celebrationCreditLineUpgradeTitle = "You’ve been\u000Aupgraded!"
// "We’ve pulled 3 recent transactions from your {{account}} account. Please confirm the transaction amounts."
export const changeCashboostBankAccountIntroBody = "We’ve pulled 3 recent transactions from your {{account}} account. Please confirm the transaction amounts."
// "For your security, we need to verify account ownership."
export const changeCashboostBankAccountIntroBodyTitle = "For your security, we need to verify account ownership."
// "Get Started"
export const changeCashboostBankAccountIntroCTA = "Get Started"
// "Account Verification"
export const changeCashboostBankAccountIntroTitle = "Account Verification"
// "Attachments"
export const chatAttachmentFolderTitleDefault = "Attachments"
// "Please take a look at my documents."
export const chatAttachmentOnlyMessageBodyPlural = "Please take a look at my documents."
// "Please take a look at my document."
export const chatAttachmentOnlyMessageBodySingular = "Please take a look at my document."
// "Yes"
export const chatCancelSubscriptionAutoresponderCTAConfirm = "Yes"
// "No"
export const chatCancelSubscriptionAutoresponderCTADeny = "No"
// "It looks like you want to cancel or delete your account. Is that what you want to do?"
export const chatCancelSubscriptionAutoresponderContent = "It looks like you want to cancel or delete your account. Is that what you want to do?"
// "It looks like you want to cancel or delete your account. Is that what you want to do?"
// ""
// ""
// "Hold tight. A member of our team will respond to you within 24 hours."
export const chatCancelSubscriptionAutoresponderContentWithSLA = "It looks like you want to cancel or delete your account. Is that what you want to do?\u000A\u000A\u000AHold tight. A member of our team will respond to you within 24 hours."
// "Grid Team"
export const chatCancelSubscriptionAutoresponderSenderName = "Grid Team"
// "I would like to cancel my Grid+ subscription. I’ve repaid my outstanding advances."
export const chatCancelSubscriptionPrefilledText = "I would like to cancel my Grid+ subscription. I’ve repaid my outstanding advances."
// "Cancel"
export const chatCancelSubscriptionPrefilledTextEditAlertCancelCTA = "Cancel"
// "Tapping continue will erase the current message and let you type a custom one."
export const chatCancelSubscriptionPrefilledTextEditAlertContent = "Tapping continue will erase the current message and let you type a custom one."
// "Continue"
export const chatCancelSubscriptionPrefilledTextEditAlertContinueCTA = "Continue"
// "Edit Message?"
export const chatCancelSubscriptionPrefilledTextEditAlertTitle = "Edit Message?"
// "We‘re sorry to see you go. Your Grid subscription has many benefits like tax optimization to help you maximize your take-home pay. If you would like to keep your Grid subscription, you can simply close this chat box. However, if you want to reactivate your subscription later, you‘ll be charged a $5 reactivation fee. If you would like to cancel, please message back with “Confirm.”"
export const chatCancelSubscriptionRententionCloserAutoresponderContent = "We‘re sorry to see you go. Your Grid subscription has many benefits like tax optimization to help you maximize your take-home pay. If you would like to keep your Grid subscription, you can simply close this chat box. However, if you want to reactivate your subscription later, you‘ll be charged a $5 reactivation fee. If you would like to cancel, please message back with “Confirm.”"
// "Okay"
export const chatCannotCancelWithActiveAdvanceContinueCTA = "Okay"
// "Thanks for reaching out! You can’t cancel your account if you have an advance outstanding. Please come back once you’ve repaid your advance."
export const chatCannotCancelWithActiveAdvanceMessage = "Thanks for reaching out! You can’t cancel your account if you have an advance outstanding. Please come back once you’ve repaid your advance."
// "You have an active advance."
export const chatCannotCancelWithActiveAdvanceTitle = "You have an active advance."
// "(edited)"
export const chatCellEditedAddendum = "(edited)"
// "We’ll get back to you within 1-2 business days"
export const chatCellSLA = "We’ll get back to you within 1-2 business days"
// "Type your message"
export const chatInputPlaceholder = "Type your message"
// "Got questions? Our team will get back to you within 1-2 business days"
export const chatPlaceholderInfo = "Got questions? Our team will get back to you within 1-2 business days"
// "We’re here to help!"
export const chatPlaceholderTitle = "We’re here to help!"
// "Help Center"
export const chatTitle = "Help Center"
// "This message is empty."
export const chatWarningNoTextMessage = "This message is empty."
// "Access your tax refund early"
export const checkoutBenefits0Title = "Access your tax refund early"
// "On-demand access to our support pros"
export const checkoutBenefits1Title = "On-demand access to our support pros"
// "Increase your take-home pay today"
export const checkoutBenefits2Title = "Increase your take-home pay today"
// "Monthly paystub check-ins"
export const checkoutBenefits3Title = "Monthly paystub check-ins"
// "You are good to go with Grid! Stay in the know about your PayBoost by enabling push notifications."
export const checkoutCompletedBody = "You are good to go with Grid! Stay in the know about your PayBoost by enabling push notifications."
// "Dismiss"
export const checkoutCompletedButtonDashboard = "Dismiss"
// "Enable"
export const checkoutCompletedButtonPush = "Enable"
// "Continue"
export const checkoutCompletedEnableATTEnableButton = "Continue"
// "Grid needs to occasionally communicate with other apps, like your payroll provider or HR department using technology Apple calls “App Tracking Transparency“. Giving Grid access to this lets us improve your experience."
export const checkoutCompletedEnableATTMessage = "Grid needs to occasionally communicate with other apps, like your payroll provider or HR department using technology Apple calls “App Tracking Transparency“. Giving Grid access to this lets us improve your experience."
// "Dismiss"
export const checkoutCompletedEnableATTSkipButton = "Dismiss"
// "Let Grid communicate with other apps"
export const checkoutCompletedEnableATTTitle = "Let Grid communicate with other apps"
// "Congratulations"
export const checkoutCompletedTitle = "Congratulations"
// "Included in your Grid+ subscription"
export const checkoutDiscountMemoExistingGridPlus = "Included in your Grid+ subscription"
// "You qualify for a special offer today!"
export const checkoutDiscountMemoFreeForeverCoupon = "You qualify for a special offer today!"
// "A discount has been applied!"
export const checkoutDiscountMemoSkipCheckout = "A discount has been applied!"
// "A discount has been applied!"
export const checkoutDiscountMemoUnknownCoupon = "A discount has been applied!"
// "No $10 start fee!"
export const checkoutDiscountMemoWaivedFeeCoupon = "No $10 start fee!"
// "We waived your start fee so you can get PayBoost for less!"
export const checkoutDiscountWaivedFeeBody = "We waived your start fee so you can get PayBoost for less!"
// "No $10 start fee!"
export const checkoutDiscountWaivedFeeSubtitle = "No $10 start fee!"
// "In order to move forward, please accept the billing terms."
export const checkoutErrorBillingTermsNotAcceptedMessage = "In order to move forward, please accept the billing terms."
// "Something went wrong."
export const checkoutErrorTitle = "Something went wrong."
// "This is how much money you’re leaving on the table every month! We think we can increase your take-home pay by this much each month."
export const checkoutEstimateBoostEstimateItemDescription = "This is how much money you’re leaving on the table every month! We think we can increase your take-home pay by this much each month."
// "PayBoost estimate"
export const checkoutEstimateBoostEstimateItemTitle = "PayBoost estimate"
// "This is the tax refund it looks like you are going to get next tax season. Why wait when this money can be yours today?"
export const checkoutEstimateEndOfYearRefundEstimateDescription = "This is the tax refund it looks like you are going to get next tax season. Why wait when this money can be yours today?"
// "Annual refund estimate"
export const checkoutEstimateEndOfYearRefundEstimateTitle = "Annual refund estimate"
// "This is how much money you’re leaving on the table every year!"
export const checkoutEstimateTotalBoostEstimateItemDescription = "This is how much money you’re leaving on the table every year!"
// "PayBoost total"
export const checkoutEstimateTotalBoostEstimateItemTitle = "PayBoost total"
// "Free Plan"
export const checkoutGridFreePaymentSummaryItemLabel = "Free Plan"
// "PayBoost lets you take home an extra:"
export const checkoutIntroBody = "PayBoost lets you take home an extra:"
// "This means you could be taking home up to {{amount}} more per {{interval}}."
export const checkoutIntroBreakdownBodyLabel = "This means you could be taking home up to {{amount}} more per {{interval}}."
// "This means your paycheck goes up, and you get your refund earlier."
export const checkoutIntroBreakdownBodyLabelFalback = "This means your paycheck goes up, and you get your refund earlier."
// "Get PayBoost"
export const checkoutIntroBreakdownButton = "Get PayBoost"
// ""
export const checkoutIntroBreakdownOfferTitle = ""
// "Free"
export const checkoutIntroBreakdownPriceFree = "Free"
// "Included in your Grid+ subscription"
export const checkoutIntroBreakdownPriceIncludedGridPlus = "Included in your Grid+ subscription"
// "to start"
export const checkoutIntroBreakdownPriceOneTimeLabel = "to start"
// "per day"
export const checkoutIntroBreakdownPriceRecurringDailyLabel = "per day"
// "after that"
export const checkoutIntroBreakdownPriceRecurringLabelWithDueTodaySuffix = "after that"
// "per month"
export const checkoutIntroBreakdownPriceRecurringMonthlyLabel = "per month"
// "per week"
export const checkoutIntroBreakdownPriceRecurringWeeklyLabel = "per week"
// "per year"
export const checkoutIntroBreakdownPriceRecurringYearlyLabel = "per year"
// "What you pay"
export const checkoutIntroBreakdownPriceTitle = "What you pay"
// "What you get"
export const checkoutIntroBreakdownTitleLabel = "What you get"
// "Continue"
export const checkoutIntroButton = "Continue"
// "You qualify for PayBoost!"
export const checkoutIntroTitle = "You qualify for PayBoost!"
// "Want to make someone else’s day? Choose the amount that feels right to you!"
export const checkoutPayItForwardBody = "Want to make someone else’s day? Choose the amount that feels right to you!"
// "Continue"
export const checkoutPayItForwardCallToAction = "Continue"
// "Pay It Forward"
export const checkoutPayItForwardHeading = "Pay It Forward"
// "Select the amount you want to pay it forward"
export const checkoutPayItForwardInstructions = "Select the amount you want to pay it forward"
// "No thanks—just pay your $1."
export const checkoutPayItForwardInstructionsSelection0 = "No thanks—just pay your $1."
// "Awesome, you’ll cover {{amount}} for the next user."
export const checkoutPayItForwardInstructionsSelection1 = "Awesome, you’ll cover {{amount}} for the next user."
// "Awesome, you’ll cover {{amount}} for the next user."
export const checkoutPayItForwardInstructionsSelection2 = "Awesome, you’ll cover {{amount}} for the next user."
// "Great news! Another user, {{credited_to}}, paid it forward. You only need to pay the $1 subscription today."
export const checkoutPayItForwardTitleWithCredit = "Great news! Another user, {{credited_to}}, paid it forward. You only need to pay the $1 subscription today."
// "Congrats! Another user just paid it forward. You only need to pay the $1 subscription today."
export const checkoutPayItForwardTitleWithoutCredit = "Congrats! Another user just paid it forward. You only need to pay the $1 subscription today."
// "Today"
export const checkoutPaymentAmountDueTodayLabel = "Today"
// "Pay with Apple Pay"
export const checkoutPaymentApplePayTitle = "Pay with Apple Pay"
// "I agree to Grid’s terms and the billing terms below."
export const checkoutPaymentBillingCheckboxLabel = "I agree to Grid’s terms and the billing terms below."
// "per day subscription"
export const checkoutPaymentBillingTermsDaily = "per day subscription"
// "subscription"
export const checkoutPaymentBillingTermsFallback = "subscription"
// "per month subscription"
export const checkoutPaymentBillingTermsMonthly = "per month subscription"
// "one time fee"
export const checkoutPaymentBillingTermsOnce = "one time fee"
// "per week subscription"
export const checkoutPaymentBillingTermsWeekly = "per week subscription"
// "per year subscription"
export const checkoutPaymentBillingTermsYearly = "per year subscription"
// "Subscribe Now"
export const checkoutPaymentCallToAction = "Subscribe Now"
// "Please check your entries and try again."
export const checkoutPaymentCardFormErrorsGeneralError = "Please check your entries and try again."
// "Subscribe now"
export const checkoutPaymentCardSubmitButton = "Subscribe now"
// "Subscribe Now"
export const checkoutPaymentConfirmButton = "Subscribe Now"
// "Confirm your payment:"
export const checkoutPaymentConfirmTitle = "Confirm your payment:"
// "Your card will be immediately charged and then billed on a regular basis."
export const checkoutPaymentFinePrintApplePayFallback = "Your card will be immediately charged and then billed on a regular basis."
// "We require a card on file, but you are signing up for a free plan."
export const checkoutPaymentFinePrintApplePayForGridFree = "We require a card on file, but you are signing up for a free plan."
// "Your card will be immediately charged {{amountDueToday}} and then billed {{unitPrice}} {{interval}}."
export const checkoutPaymentFinePrintApplePayWithAmounts = "Your card will be immediately charged {{amountDueToday}} and then billed {{unitPrice}} {{interval}}."
// "Your card will be immediately charged and then billed on a regular basis."
export const checkoutPaymentFinePrintCreditCardFallback = "Your card will be immediately charged and then billed on a regular basis."
// "We require a card on file, but you are signing up for a free plan."
export const checkoutPaymentFinePrintCreditCardForGridFree = "We require a card on file, but you are signing up for a free plan."
// "Your card will be immediately charged {{amountDueToday}} and then billed {{unitPrice}} {{interval}}."
export const checkoutPaymentFinePrintCreditCardWithAmounts = "Your card will be immediately charged {{amountDueToday}} and then billed {{unitPrice}} {{interval}}."
// "each day"
export const checkoutPaymentFinePrintIntervalDay = "each day"
// "on a regular basis"
export const checkoutPaymentFinePrintIntervalFallback = "on a regular basis"
// "each month"
export const checkoutPaymentFinePrintIntervalMonth = "each month"
// "one time"
export const checkoutPaymentFinePrintIntervalOnce = "one time"
// "each week"
export const checkoutPaymentFinePrintIntervalWeek = "each week"
// "each year"
export const checkoutPaymentFinePrintIntervalYear = "each year"
// "Free Trial"
export const checkoutPaymentFreeTrialPaymentSummaryItemLabel = "Free Trial"
// "Enter payment details:"
export const checkoutPaymentHeading = "Enter payment details:"
// "{{unitPrice}} / {{interval}}"
export const checkoutPaymentPricePerInterval = "{{unitPrice}} / {{interval}}"
// "Daily Subscription"
export const checkoutPaymentPricingPlanIntervalDayPaymentSummaryItemLabel = "Daily Subscription"
// "Grid Services"
export const checkoutPaymentPricingPlanIntervalDefaultPaymentSummaryItemLabel = "Grid Services"
// "Signup Fee"
export const checkoutPaymentPricingPlanIntervalInitiationFeePaymentSummaryItemLabel = "Signup Fee"
// "Monthly Subscription"
export const checkoutPaymentPricingPlanIntervalMonthPaymentSummaryItemLabel = "Monthly Subscription"
// "Weekly Subscription"
export const checkoutPaymentPricingPlanIntervalWeekPaymentSummaryItemLabel = "Weekly Subscription"
// "Annual Subscription"
export const checkoutPaymentPricingPlanIntervalYearPaymentSummaryItemLabel = "Annual Subscription"
// "Subscription"
export const checkoutPaymentSubscriptionAmountLabel = "Subscription"
// "https://getgrid.app/terms/"
export const checkoutPaymentTermsUrl = "https://getgrid.app/terms/"
// "Payment"
export const checkoutPaymentTitle = "Payment"
// "PayBoost costs just {{unitPrice}}. We keep an eye on your income and withholdings so that you get your refund on your terms."
export const checkoutPricingBodyOneTimeWithFields = "PayBoost costs just {{unitPrice}}. We keep an eye on your income and withholdings so that you get your refund on your terms."
// "PayBoost is just {{unitPrice}} {{interval}}. We keep an eye on your income and withholdings so that you get your refund on your terms."
export const checkoutPricingBodyWithFields = "PayBoost is just {{unitPrice}} {{interval}}. We keep an eye on your income and withholdings so that you get your refund on your terms."
// "Get PayBoost"
export const checkoutPricingButtonContinue = "Get PayBoost"
// "Subscribe Now"
export const checkoutPricingCallToAction = "Subscribe Now"
// "Per"
export const checkoutPricingSparklesIntervalPrefix = "Per"
// "Per"
export const checkoutPricingSparklesIntervalPrefixThis = "Per"
// "Checkout to get your PayBoost"
export const checkoutPricingTitle = "Checkout to get your PayBoost"
// "Review Your PayBoost"
export const checkoutReviewTitle = "Review Your PayBoost"
// "Grid"
export const checkoutStripeCompanyName = "Grid"
// "This card does not support Fast Delivery, please choose a different card and try again"
export const ckoCardInstantPayoutIneligible = "This card does not support Fast Delivery, please choose a different card and try again"
// "Your card info failed, please check your card details and try again"
export const ckoCardVerificationFailed = "Your card info failed, please check your card details and try again"
// "You need to cancel your subscription before you can delete your account."
export const closeAccountActiveSubscriptionAlertContent = "You need to cancel your subscription before you can delete your account."
// "You have an active paid subscription"
export const closeAccountActiveSubscriptionAlertTitle = "You have an active paid subscription"
// "Thanks for trying Grid. Your account is permanently closed and can no longer be accessed."
export const closeAccountClosedBody = "Thanks for trying Grid. Your account is permanently closed and can no longer be accessed."
// "Done"
export const closeAccountClosedButton = "Done"
// "Account deleted"
export const closeAccountClosedTitle = "Account deleted"
// "Cancel"
export const closeAccountConfirmAlertActionCancel = "Cancel"
// "Delete Account"
export const closeAccountConfirmAlertActionCloseAccount = "Delete Account"
// "Are you sure you want to delete your Grid account?"
export const closeAccountConfirmAlertMessage = "Are you sure you want to delete your Grid account?"
// "Delete Account"
export const closeAccountConfirmAlertTitle = "Delete Account"
// "This is permanent. We suggest canceling your subscription to stop any charges. Without deleting, you can come back to your account when we release new products or when your eligibility changes!"
export const closeAccountConfirmBody = "This is permanent. We suggest canceling your subscription to stop any charges. Without deleting, you can come back to your account when we release new products or when your eligibility changes!"
// "No"
export const closeAccountConfirmButtonCancel = "No"
// "Yes"
export const closeAccountConfirmButtonConfirm = "Yes"
// "We’re deleting out your account…"
export const closeAccountConfirmLoadingModalContent = "We’re deleting out your account…"
// "Are you sure you want to delete your account?"
export const closeAccountConfirmTitle = "Are you sure you want to delete your account?"
// "You account has an outstanding balance as the result of an invalid chargeback. You can’t close your account until your balance has been fully repaid. Once you’ve fully repaid it, you’ll be able to delete your account."
export const closeAccountDisputedBalanceAlertContent = "You account has an outstanding balance as the result of an invalid chargeback. You can’t close your account until your balance has been fully repaid. Once you’ve fully repaid it, you’ll be able to delete your account."
// "Your account has an outstanding balance."
export const closeAccountDisputedBalanceAlertTitle = "Your account has an outstanding balance."
// "You can’t make changes to your account until your advance has been fully repaid. Once you’ve fully repaid it, you’ll be able to modify your account."
export const closeAccountOutstandingBalanceAlertContent = "You can’t make changes to your account until your advance has been fully repaid. Once you’ve fully repaid it, you’ll be able to modify your account."
// "Continue"
export const closeAccountOutstandingBalanceAlertContinueCTA = "Continue"
// "Pay Fees"
export const closeAccountOutstandingBalanceAlertPayFeesCTA = "Pay Fees"
// "Repay Advance"
export const closeAccountOutstandingBalanceAlertRepayAdvanceCTA = "Repay Advance"
// "Your account has an outstanding balance."
export const closeAccountOutstandingBalanceAlertTitle = "Your account has an outstanding balance."
// "You can’t make changes to your account until your subscription fees have been fully repaid. Once you’ve fully repaid them, you’ll be able to modify your account."
export const closeAccountOutstandingSubscriptionAlertContent = "You can’t make changes to your account until your subscription fees have been fully repaid. Once you’ve fully repaid them, you’ll be able to modify your account."
// "No, delete my account"
export const closeAccountWaivedFeeButtonConfirm = "No, delete my account"
// "Grid"
export const companyNameShort = "Grid"
// "Submit"
export const complianceAppealFailureButton = "Submit"
// "If you think this is a mistake, please send a message to our operations team."
export const complianceAppealFailureSubtitle = "If you think this is a mistake, please send a message to our operations team."
// "Write your message to our operations team"
export const complianceAppealFailureTextHint = "Write your message to our operations team"
// "We were not able to"
// "verify your identity"
export const complianceAppealFailureTitle = "We were not able to\u000Averify your identity"
// "Confirm"
export const complianceConfirmProfileButton = "Confirm"
// "Address"
export const complianceConfirmProfileCardAddressTitle = "Address"
// "DATE OF BIRTH"
export const complianceConfirmProfileCardBasicDataDobLabel = "DATE OF BIRTH"
// "FIRST NAME"
export const complianceConfirmProfileCardBasicDataFirstNameLabel = "FIRST NAME"
// "LAST NAME"
export const complianceConfirmProfileCardBasicDataLastNameLabel = "LAST NAME"
// "Basic Data"
export const complianceConfirmProfileCardBasicDataTitle = "Basic Data"
// "EDIT"
export const complianceConfirmProfileCardEditButton = "EDIT"
// "Social Security Number"
export const complianceConfirmProfileCardSsnTitle = "Social Security Number"
// "Please make sure that your personal data is accurate before continuing"
export const complianceConfirmProfileSubtitle = "Please make sure that your personal data is accurate before continuing"
// "We couldn’t verify you"
export const complianceConfirmProfileTitle = "We couldn’t verify you"
// "Verify Your Identity"
export const complianceDocumentUploadFootprintVerificationButton = "Verify Your Identity"
// "We are using FootPrint, a trusted partner to verify your identity"
export const complianceDocumentUploadFootprintVerificationInfoItem = "We are using FootPrint, a trusted partner to verify your identity"
// "Grid needs to collect some personal"
// " information to confirm your identity"
export const complianceDocumentUploadFootprintVerificationSubtitle = "Grid needs to collect some personal\u000A information to confirm your identity"
// "Grid wants to verify your identity"
export const complianceDocumentUploadFootprintVerificationTitle = "Grid wants to verify your identity"
// "Continue"
export const complianceDocumentUploadPrimerButton = "Continue"
// "Driver’s license"
export const complianceDocumentUploadPrimerDocuments1Item1 = "Driver’s license"
// "State-issued ID card"
export const complianceDocumentUploadPrimerDocuments1Item2 = "State-issued ID card"
// "Passport"
export const complianceDocumentUploadPrimerDocuments1Item3 = "Passport"
// "Passport card"
export const complianceDocumentUploadPrimerDocuments1Item4 = "Passport card"
// "Photo ID"
export const complianceDocumentUploadPrimerDocuments1Title = "Photo ID"
// "Please upload the following documents:"
export const complianceDocumentUploadPrimerSubtitle = "Please upload the following documents:"
// "We need additional information in order to verify your identity:"
export const complianceDocumentUploadPrimerTitle = "We need additional information in order to verify your identity:"
// "Confirm"
export const complianceEditBasicDataButton = "Confirm"
// "Date of Birth"
export const complianceEditBasicDataDobLabel = "Date of Birth"
// "First Name"
export const complianceEditBasicDataFirstNameLabel = "First Name"
// "Last Name"
export const complianceEditBasicDataLastNameLabel = "Last Name"
// "Basic Data"
export const complianceEditBasicDataTitle = "Basic Data"
// "Close"
export const complianceVerificationFailedButton = "Close"
// "You should hear back from us within a week"
export const complianceVerificationFailedSubtitle = "You should hear back from us within a week"
// "Your response was submitted"
export const complianceVerificationFailedTitle = "Your response was submitted"
// "Connected"
export const connectionDetailsBankAccountStatusConnected = "Connected"
// "Fix account connection"
export const connectionDetailsBankAccountStatusError = "Fix account connection"
// "Connection error"
export const connectionDetailsBankAccountStatusErrorReadonly = "Connection error"
// "{{name}} Payroll"
export const connectionDetailsHeader = "{{name}} Payroll"
// "Associated Job"
export const connectionDetailsJobTitle = "Associated Job"
// "Payroll Provider"
export const connectionDetailsPayrollProviderTitle = "Payroll Provider"
// "Remove account"
export const connectionDetailsRemove = "Remove account"
// "Cancel"
export const connectionDetailsRemoveConnectionAlertCancel = "Cancel"
// "Yes, delete"
export const connectionDetailsRemoveConnectionAlertDelete = "Yes, delete"
// "Are you sure you want to delete this job?"
export const connectionDetailsRemoveConnectionAlertMessage = "Are you sure you want to delete this job?"
// "In order to move forward, please make sure there is a good connection for your current job."
export const connectionDetailsRemoveConnectionAlertWarning = "In order to move forward, please make sure there is a good connection for your current job."
// "Removing account…"
export const connectionDetailsRemoveConnectionLoadingText = "Removing account…"
// "Account"
export const connectionDetailsSectionHeader = "Account"
// "Status"
export const connectionDetailsStatus = "Status"
// "Fix account connection"
export const connectionDetailsStatusBroken = "Fix account connection"
// "Answer security challenge"
export const connectionDetailsStatusChallengeDefault = "Answer security challenge"
// "CONNECTED Last synced {{lastSyncedAt}}"
export const connectionDetailsStatusConnected = "CONNECTED Last synced {{lastSyncedAt}}"
// "Update login credentials"
export const connectionDetailsStatusProvidedNotTestable = "Update login credentials"
// "SYNCING"
export const connectionDetailsStatusSyncing = "SYNCING"
// "Copied"
export const copyableCellCopied = "Copied"
// "Copy"
export const copyableCellCta = "Copy"
// "Add Funds"
export const creditBoostAddFundsCTA = "Add Funds"
// "To start building your credit score, you’ll need to first add funds to your Grid Card"
export const creditBoostAddFundsSubtitle = "To start building your credit score, you’ll need to first add funds to your Grid Card"
// "Add funds to your Grid Card"
export const creditBoostAddFundsTitle = "Add funds to your Grid Card"
// "Continue"
export const creditBoostAuthFailureCTA = "Continue"
// "We couldn’t verify your identity at this time. Please try again."
export const creditBoostAuthFailureSubtitle = "We couldn’t verify your identity at this time. Please try again."
// "Oops!"
export const creditBoostAuthFailureTitle = "Oops!"
// "This should take about 30 seconds…"
export const creditBoostFetchingScoresSubtitle = "This should take about 30 seconds…"
// "Pulling credit score"
export const creditBoostFetchingScoresTitle = "Pulling credit score"
// "Current Score"
export const creditBoostGraphCurrentScoreLabel = "Current Score"
// "Pending update"
export const creditBoostGraphEmptyTileTitle1 = "Pending update"
// "Change to date"
export const creditBoostGraphEmptyTileTitle2 = "Change to date"
// "You’ll see your first"
// "score next month"
export const creditBoostGraphEmptyTitle = "You’ll see your first\u000Ascore next month"
// "Future Score"
export const creditBoostGraphFutureScoreLabel = "Future Score"
// "*Future Score is an estimate based on general assumptions. Individual results will vary."
export const creditBoostGraphSliderFinePrint = "*Future Score is an estimate based on general assumptions. Individual results will vary."
// "Spend more with Grid Card and watch your score climb."
export const creditBoostGraphSliderLabel = "Spend more with Grid Card and watch your score climb."
// "Build your credit with every purchase you make"
export const creditBoostIntroBulletOne = "Build your credit with every purchase you make"
// "No credit check. Everyone is approved"
export const creditBoostIntroBulletThree = "No credit check. Everyone is approved"
// "Earn up to $100 in cash rewards per month"
export const creditBoostIntroBulletTwo = "Earn up to $100 in cash rewards per month"
// "Boost your Credit"
export const creditBoostIntroCTA = "Boost your Credit"
// "Finish Setup"
export const creditBoostIntroFinishSetup = "Finish Setup"
// "Bank Transfer"
export const creditBoostIntroFutureScoreBankTransfer = "Bank Transfer"
// "Start Improving Your Score"
export const creditBoostIntroFutureScoreCTA = "Start Improving Your Score"
// "Plus, get {{amt}} in cash back points 💵"
export const creditBoostIntroFutureScoreContent = "Plus, get {{amt}} in cash back points 💵"
// "*Individual results may vary,"
// "estimate based on 6 month horizon."
export const creditBoostIntroFutureScoreDisclaimer = "*Individual results may vary,\u000Aestimate based on 6 month horizon."
// "OR"
export const creditBoostIntroFutureScoreOr = "OR"
// "The more you spend on Grid Card, the faster you’ll get here"
export const creditBoostIntroFutureScoreSubtitle = "The more you spend on Grid Card, the faster you’ll get here"
// "Your Future Score 🔮"
export const creditBoostIntroFutureScoreTitle = "Your Future Score 🔮"
// "Build credit with every purchase"
export const creditBoostIntroInfo1 = "Build credit with every purchase"
// "Earn cash rewards"
export const creditBoostIntroInfo2 = "Earn cash rewards"
// "No credit check to apply"
export const creditBoostIntroInfo3 = "No credit check to apply"
// "Everything for just "
// " 10/month"
export const creditBoostIntroInfo4 = "Everything for just \u000A 10/month"
// "Meet Grid Credit"
export const creditBoostIntroMainTitle = "Meet Grid Credit"
// "Credit Builder"
export const creditBoostIntroTabHeaderTitle = "Credit Builder"
// "Build your credit with Card"
export const creditBoostIntroTitle = "Build your credit with Card"
// "VantageScore 3.0 powered by Equifax {{date}}"
export const creditBoostPoweredBy = "VantageScore 3.0 powered by Equifax {{date}}"
// "Close"
export const creditBoostPullScoresTimeoutCta = "Close"
// "We are unable to retrieve your credit score right now. Please try again later."
export const creditBoostPullScoresTimeoutSubtitle = "We are unable to retrieve your credit score right now. Please try again later."
// "Oops!"
export const creditBoostPullScoresTimeoutTitle = "Oops!"
// "VantageScore 3.0 powered by {{reporting_agency}}"
export const creditBoostReportingProvider = "VantageScore 3.0 powered by {{reporting_agency}}"
// "Close"
export const creditBoostScoreErrorButtonTitle = "Close"
// "We are unable to retrieve your credit score right now. Please try again later."
export const creditBoostScoreErrorSubtitle = "We are unable to retrieve your credit score right now. Please try again later."
// "Exceptional"
export const creditBoostScoreRangeExceptional = "Exceptional"
// "Fair"
export const creditBoostScoreRangeFair = "Fair"
// "Good"
export const creditBoostScoreRangeGood = "Good"
// "Poor"
export const creditBoostScoreRangePoor = "Poor"
// "Very Good"
export const creditBoostScoreRangeVeryGood = "Very Good"
// "This should take about 30 seconds…"
export const creditBoostScoreScreenSubtitle = "This should take about 30 seconds…"
// "Pulling credit score"
export const creditBoostScoreScreenTitle = "Pulling credit score"
// "—"
export const creditBoostStatusTileChangeToDateEmptyMessage = "—"
// "Changes to date"
export const creditBoostStatusTileChangeToDateTitle = "Changes to date"
// "Use your Grid Card and you’ll see your first score next month"
export const creditBoostStatusTileChartEmpty = "Use your Grid Card and you’ll see your first score next month"
// "Reports updated every 30 days"
export const creditBoostStatusTileCreditSourceEmpty = "Reports updated every 30 days"
// "850"
export const creditBoostStatusTileHighScore = "850"
// "Pending"
export const creditBoostStatusTileLastUpdatedEmptyMessage = "Pending"
// "Updated"
export const creditBoostStatusTileLastUpdatedTitle = "Updated"
// "300"
export const creditBoostStatusTileLowScore = "300"
// "pts"
export const creditBoostStatusTilePoints = "pts"
// "No score"
export const creditBoostStatusTileRangePlaceholder = "No score"
// "--"
export const creditBoostStatusTileScorePlaceholder = "--"
// "Tips to improve your score"
export const creditBoostStatusTileTipsTitle = "Tips to improve your score"
// "Your score"
export const creditBoostStatusTileYourScore = "Your score"
// "Tips to improve your score"
export const creditBoostTipCardTitle = "Tips to improve your score"
// "Use less than 30% of your total credit card limits"
export const creditBoostTipCardUsageContent = "Use less than 30% of your total credit card limits"
// "Credit card usage"
export const creditBoostTipCardUsageTitle = "Credit card usage"
// "Longer average age of your open accounts improves your score."
export const creditBoostTipCreditAgeContent = "Longer average age of your open accounts improves your score."
// "Credit age"
export const creditBoostTipCreditAgeTitle = "Credit age"
// "Collections, tax liens, civil judgements or bankruptcies have a negative impact on your score."
export const creditBoostTipDerogatoryMarksContent = "Collections, tax liens, civil judgements or bankruptcies have a negative impact on your score."
// "Derogatory marks"
export const creditBoostTipDerogatoryMarksTitle = "Derogatory marks"
// "Number of times you’ve applied for credit. Lenders favor fewer hard inquiries."
export const creditBoostTipHardInquiriesContent = "Number of times you’ve applied for credit. Lenders favor fewer hard inquiries."
// "Hard inquiries"
export const creditBoostTipHardInquiriesTitle = "Hard inquiries"
// "High Impact"
export const creditBoostTipImpactHigh = "High Impact"
// "Low Impact"
export const creditBoostTipImpactLow = "Low Impact"
// "Medium Impact"
export const creditBoostTipImpactMedium = "Medium Impact"
// "Lenders like to see that you use a variety of accounts responsibly."
export const creditBoostTipTotalAccountsContent = "Lenders like to see that you use a variety of accounts responsibly."
// "Total accounts"
export const creditBoostTipTotalAccountsTitle = "Total accounts"
// "Enroll in Credit Builder"
export const creditBuilderEnrollmentScreenCTA = "Enroll in Credit Builder"
// "Set up direct deposit of $250 or more each month on your Grid Card."
export const creditBuilderEnrollmentScreenDetails1 = "Set up direct deposit of $250 or more each month on your Grid Card."
// "Spend at least $500 each month on your card."
export const creditBuilderEnrollmentScreenDetails2 = "Spend at least $500 each month on your card."
// "If you don’t see your guaranteed score after 12 months in a row of deposits and spend, we will send you $3,000.*"
export const creditBuilderEnrollmentScreenDetails3 = "If you don’t see your guaranteed score after 12 months in a row of deposits and spend, we will send you $3,000.*"
// "How it works:"
export const creditBuilderEnrollmentScreenDetailsTitle = "How it works:"
// "Your Guaranteed Score"
export const creditBuilderEnrollmentScreenGuaranteedScore = "Your Guaranteed Score"
// "Credit Builder"
export const creditBuilderEnrollmentScreenNavTitle = "Credit Builder"
// "By clicking the button below, you acknowledge that you have read and agree to the"
export const creditBuilderEnrollmentScreenTerms = "By clicking the button below, you acknowledge that you have read and agree to the"
// "Credit Builder Terms."
export const creditBuilderEnrollmentScreenTermsLink = "Credit Builder Terms."
// "Credit Builder bets on you"
export const creditBuilderEnrollmentScreenTitle = "Credit Builder bets on you"
// "We can’t access your credit score at the moment. Please try again next month."
export const creditBuilderEnrollmentUnavailableNoCreditReports = "We can’t access your credit score at the moment. Please try again next month."
// "Your progress will reset if there is an overdue payment or negative impact on your credit score."
export const creditBuilderGoalsScreenDescription = "Your progress will reset if there is an overdue payment or negative impact on your credit score."
// "Complete the following goals this month to maintain your Credit Builder progress."
export const creditBuilderGoalsScreenSubtitle = "Complete the following goals this month to maintain your Credit Builder progress."
// "Stay on track"
export const creditBuilderGoalsScreenTitle = "Stay on track"
// "Got it"
export const creditBuilderHowItWorksScreenCTA = "Got it"
// "Maintain a direct deposit of $250+ and monthly spend of $500 for 12 months in a row for a guaranteed increase to your credit score. If you don’t improve your credit score after completing the program, we will send you $3,000."
export const creditBuilderHowItWorksScreenSubtitle = "Maintain a direct deposit of $250+ and monthly spend of $500 for 12 months in a row for a guaranteed increase to your credit score. If you don’t improve your credit score after completing the program, we will send you $3,000."
// "How does Credit Builder work?"
export const creditBuilderHowItWorksScreenTitle = "How does Credit Builder work?"
// "Next"
export const creditBuilderIntroScreenCTA = "Next"
// "Credit Builder"
export const creditBuilderIntroScreenNavigationTitle = "Credit Builder"
// "A higher credit score means your current lease payments can go further."
export const creditBuilderIntroScreenSubtitle = "A higher credit score means your current lease payments can go further."
// "Better score. Better life."
export const creditBuilderIntroScreenTitle = "Better score. Better life."
// "Get Started"
export const creditBuilderOnboardingScreenCTATitle = "Get Started"
// "Boost your credit"
// "Build your future"
export const creditBuilderOnboardingScreenSubtitle = "Boost your credit\u000ABuild your future"
// "Credit Builder"
export const creditBuilderOnboardingScreenTitle = "Credit Builder"
// "Continue"
export const creditBuilderOnboardingStep1ScreenCTATitle = "Continue"
// "Having a lower credit score can be limiting and lead to higher expenses"
export const creditBuilderOnboardingStep1ScreenContent1 = "Having a lower credit score can be limiting and lead to higher expenses"
// "Increasing your credit score to over 650 can save you about $5,000 per year*"
export const creditBuilderOnboardingStep1ScreenContent2 = "Increasing your credit score to over 650 can save you about $5,000 per year*"
// "Grid users have already increased their credit scores by over 1 million points"
export const creditBuilderOnboardingStep1ScreenContent3 = "Grid users have already increased their credit scores by over 1 million points"
// "* Based on average auto loan, auto insurance and unsecured loan for prime vs subprime credit users"
export const creditBuilderOnboardingStep1ScreenGraphDescription = "* Based on average auto loan, auto insurance and unsecured loan for prime vs subprime credit users"
// "Better Score. Better Life."
export const creditBuilderOnboardingStep1ScreenSubtitle = "Better Score. Better Life."
// "Building Credit Matters"
export const creditBuilderOnboardingStep1ScreenTitle = "Building Credit Matters"
// "Continue"
export const creditBuilderOnboardingStep2ScreenCTATitle = "Continue"
// "Pay just $10 every month and we’ll report your on time payments to the credit bureaus"
export const creditBuilderOnboardingStep2ScreenContent1 = "Pay just $10 every month and we’ll report your on time payments to the credit bureaus"
// "We report a utilization of less than 10% on your credit builder"
export const creditBuilderOnboardingStep2ScreenContent2 = "We report a utilization of less than 10% on your credit builder"
// "Keep your account open and improve your account age"
export const creditBuilderOnboardingStep2ScreenContent3 = "Keep your account open and improve your account age"
// "Want to know more?"
export const creditBuilderOnboardingStep2ScreenHelpCTATitle = "Want to know more?"
// "Making your monthly $10 Credit Builder payment on time helps improve your credit score and build a positive repayment history, helping boost your credit."
export const creditBuilderOnboardingStep2ScreenHelpContent1Point1 = "Making your monthly $10 Credit Builder payment on time helps improve your credit score and build a positive repayment history, helping boost your credit."
// "With Credit Builder, we set up a revolving credit line of $1,500 to cover a year’s worth of subscriptions to Grid+ ($120). There is no upfront annual fee. Initially, your credit utilization will be 8% of the credit line ($120/$1,500). Each month, your Grid+ payment will reduce your owed amount by $10. Please note that you can only use the credit line for credit-building purchases at Credit Builder Store. For better credit, keep your credit utilization under 10%."
export const creditBuilderOnboardingStep2ScreenHelpContent2Point1 = "With Credit Builder, we set up a revolving credit line of $1,500 to cover a year’s worth of subscriptions to Grid+ ($120). There is no upfront annual fee. Initially, your credit utilization will be 8% of the credit line ($120/$1,500). Each month, your Grid+ payment will reduce your owed amount by $10. Please note that you can only use the credit line for credit-building purchases at Credit Builder Store. For better credit, keep your credit utilization under 10%."
// "you can only use the credit line for credit-building purchases at Credit Builder Store"
export const creditBuilderOnboardingStep2ScreenHelpContent2Point1Bold = "you can only use the credit line for credit-building purchases at Credit Builder Store"
// "The longer you keep your Credit Builder account open, the more you increase the average age of your credit accounts, improving your score."
export const creditBuilderOnboardingStep2ScreenHelpContent3Point1 = "The longer you keep your Credit Builder account open, the more you increase the average age of your credit accounts, improving your score."
// "Increase Repayment History"
export const creditBuilderOnboardingStep2ScreenHelpTitle1 = "Increase Repayment History"
// "Decrease Utilization"
export const creditBuilderOnboardingStep2ScreenHelpTitle2 = "Decrease Utilization"
// "Improve Account Age"
export const creditBuilderOnboardingStep2ScreenHelpTitle3 = "Improve Account Age"
// "Grid improves the most important credit factors"
export const creditBuilderOnboardingStep2ScreenSubtitle = "Grid improves the most important credit factors"
// "How Does it Work?"
export const creditBuilderOnboardingStep2ScreenTitle = "How Does it Work?"
// "Track Goals"
export const creditBuilderProgressTileCTA = "Track Goals"
// "You have completed the Credit Builder program."
export const creditBuilderProgressTileComplete = "You have completed the Credit Builder program."
// "Congratulations!"
export const creditBuilderProgressTileCongrats = "Congratulations!"
// "GUARANTEED"
// "SCORE"
export const creditBuilderProgressTileGuaranteed = "GUARANTEED\u000ASCORE"
// "How it works"
export const creditBuilderProgressTileHowItWorks = "How it works"
// "You have completed the Credit Builder program."
export const creditBuilderProgressTileIncomplete = "You have completed the Credit Builder program."
// "Contact Support"
export const creditBuilderProgressTileIncompleteCTA = "Contact Support"
// "{{done}}/{{goal}} months"
export const creditBuilderProgressTileProgress = "{{done}}/{{goal}} months"
// "STARTING"
// "SCORE"
export const creditBuilderProgressTileStarting = "STARTING\u000ASCORE"
// "Complete your monthly Grid Card goals for 12 months in a row to unlock a better score."
export const creditBuilderProgressTileSubtitle = "Complete your monthly Grid Card goals for 12 months in a row to unlock a better score."
// "You’re on your way!"
export const creditBuilderProgressTileTitle = "You’re on your way!"
// "Sign Up"
export const creditBuilderSignUpTileCTA = "Sign Up"
// "Complete monthly goals for a guaranteed increase to your score with this program - FREE with your Grid+ subscription."
export const creditBuilderSignUpTileSubtitle = "Complete monthly goals for a guaranteed increase to your score with this program - FREE with your Grid+ subscription."
// "A better score, guaranteed"
export const creditBuilderSignUpTileTitle = "A better score, guaranteed"
// "Terms Apply."
export const creditBuilderTermsApply = "Terms Apply."
// "Credit age is the average age of your open accounts."
export const creditCategoryCreditAgeExplanation = "Credit age is the average age of your open accounts."
// "Fair: 5-6 years"
export const creditCategoryCreditAgeFairRange = "Fair: 5-6 years"
// "Good: 7+ years"
export const creditCategoryCreditAgeGoodRange = "Good: 7+ years"
// "Needs work: 0-4 years"
export const creditCategoryCreditAgeNeedsWorkRange = "Needs work: 0-4 years"
// "Derogatory marks take into account any debts in collections or public records you may have. Examples of public records include bankruptcies, civil judgements, and tax liens."
export const creditCategoryDerogatoryMarksExplanation = "Derogatory marks take into account any debts in collections or public records you may have. Examples of public records include bankruptcies, civil judgements, and tax liens."
// "Fair: 1"
export const creditCategoryDerogatoryMarksFairRange = "Fair: 1"
// "Good: 0"
export const creditCategoryDerogatoryMarksGoodRange = "Good: 0"
// "Needs work: 2+"
export const creditCategoryDerogatoryMarksNeedsWorkRange = "Needs work: 2+"
// "Hard inquiries is the number of inquiries on your credit report."
export const creditCategoryHardInquiriesExplanation = "Hard inquiries is the number of inquiries on your credit report."
// "Fair: 3-4"
export const creditCategoryHardInquiriesFairRange = "Fair: 3-4"
// "Good: 0-2"
export const creditCategoryHardInquiriesGoodRange = "Good: 0-2"
// "Needs work: 5+"
export const creditCategoryHardInquiriesNeedsWorkRange = "Needs work: 5+"
// "High Impact"
export const creditCategoryImpactHighImpact = "High Impact"
// "Low Impact"
export const creditCategoryImpactLowImpact = "Low Impact"
// "Medium Impact"
export const creditCategoryImpactMediumImpact = "Medium Impact"
// "Credit Age"
export const creditCategoryNameCreditAge = "Credit Age"
// "Derogatory Marks"
export const creditCategoryNameDerogatoryMarks = "Derogatory Marks"
// "Hard Inquiries"
export const creditCategoryNameHardInquiries = "Hard Inquiries"
// "Payment History"
export const creditCategoryNamePayments = "Payment History"
// "Total Accounts"
export const creditCategoryNameTotalAccounts = "Total Accounts"
// "Credit Utilization"
export const creditCategoryNameUtilization = "Credit Utilization"
// "N/A"
export const creditCategoryNotApplicable = "N/A"
// "Your on-time payment history is based off of all the possible payments you can make across all of your reported accounts."
export const creditCategoryPaymentsExplanation = "Your on-time payment history is based off of all the possible payments you can make across all of your reported accounts."
// "Fair: 98%"
export const creditCategoryPaymentsFairRange = "Fair: 98%"
// "Good: ≥99%"
export const creditCategoryPaymentsGoodRange = "Good: ≥99%"
// "Needs work: <97%"
export const creditCategoryPaymentsNeedsWorkRange = "Needs work: \u003C97%"
// "Total accounts is your total number of open and closed accounts."
export const creditCategoryTotalAccountsExplanation = "Total accounts is your total number of open and closed accounts."
// "Fair: 11-20"
export const creditCategoryTotalAccountsFairRange = "Fair: 11-20"
// "Good: 21+"
export const creditCategoryTotalAccountsGoodRange = "Good: 21+"
// "Needs work: 0-10"
export const creditCategoryTotalAccountsNeedsWorkRange = "Needs work: 0-10"
// "Your credit utilization takes into consideration individual card utilization and the average across all of your credit cards."
export const creditCategoryUtilizationExplanation = "Your credit utilization takes into consideration individual card utilization and the average across all of your credit cards."
// "Fair: 30-49%"
export const creditCategoryUtilizationFairRange = "Fair: 30-49%"
// "Good: 0-29%"
export const creditCategoryUtilizationGoodRange = "Good: 0-29%"
// "Needs work: >50%"
export const creditCategoryUtilizationNeedsWorkRange = "Needs work: \u003E50%"
// "AVAILABLE CREDIT"
export const creditLineActiveTileAvailableCreditLabel = "AVAILABLE CREDIT"
// "CURRENT BALANCE"
export const creditLineActiveTileCurrentBalanceLabel = "CURRENT BALANCE"
// "Make Payment"
export const creditLineActiveTileMakePaymentCTA = "Make Payment"
// "Remaining Minimum Payment"
export const creditLineActiveTileMinimumPaymentLabel = "Remaining Minimum Payment"
// "Minimum Payment"
export const creditLineActiveTileMinimumPaymentNoPaymentsLabel = "Minimum Payment"
// "Outstanding balance"
export const creditLineActiveTileOutstandBalanceLabel = "Outstanding balance"
// "To keep you on track, we’ll be charging $50.00 to your connected account."
export const creditLineActiveTilePaymentDueBannerSubtitleA = "To keep you on track, we’ll be charging $50.00 to your connected account."
// "You have an overdue minimum payment. Late payments will be reflected on your credit report and may impact your score."
export const creditLineActiveTilePaymentDueBannerSubtitleB = "You have an overdue minimum payment. Late payments will be reflected on your credit report and may impact your score."
// "Payment Overdue"
export const creditLineActiveTilePaymentDueBannerTitle = "Payment Overdue"
// "Payment Due"
export const creditLineActiveTilePaymentDueLabel = "Payment Due"
// "Pay down your outstanding balance to reduce interest fees on your next statement."
export const creditLineActiveTilePaymentFeesBannerSubitle = "Pay down your outstanding balance to reduce interest fees on your next statement."
// "Reduce interest fees"
export const creditLineActiveTilePaymentFeesBannerTitle = "Reduce interest fees"
// "PAID ✅"
export const creditLineActiveTilePaymentStatusPaid = "PAID ✅"
// "Pending"
export const creditLineActiveTilePaymentStatusPending = "Pending"
// "Processing…"
export const creditLineActiveTilePaymentStatusProcessing = "Processing…"
// "Remaining Statement Balance"
export const creditLineActiveTilePaymentStatusStatementBalance = "Remaining Statement Balance"
// "Statement Balance"
export const creditLineActiveTilePaymentStatusStatementBalanceNoPayments = "Statement Balance"
// "{{amt}} used"
export const creditLineActiveTilePercentageUsedLabel = "{{amt}} used"
// "{{amt}} limit"
export const creditLineActiveTileSpendingLineLabel = "{{amt}} limit"
// "Build Your Credit Line"
export const creditLineActiveTileViewBuildCreditLineCTA = "Build Your Credit Line"
// "Credit Line Transactions"
export const creditLineActiveTileViewReceiptsCTA = "Credit Line Transactions"
// "Late payment protection is enabled. We no longer allow you to toggle off autopay."
export const creditLineAutoPayEnforced = "Late payment protection is enabled. We no longer allow you to toggle off autopay."
// "Enroll in Late Payment Protection"
export const creditLineAutopayCTAEnroll = "Enroll in Late Payment Protection"
// "Save Changes"
export const creditLineAutopayCTATitle = "Save Changes"
// "Payment Amount"
export const creditLineAutopayPaymentAmountRowTitle = "Payment Amount"
// "Payment Details"
export const creditLineAutopayPaymentDetailsSectionTitle = "Payment Details"
// "Cancel"
export const creditLineAutopayPaymentOptionCTALabel = "Cancel"
// "Minimum Payment"
export const creditLineAutopayPaymentOptionMinimum = "Minimum Payment"
// "Other Amount"
export const creditLineAutopayPaymentOptionOtherAmount = "Other Amount"
// "Statement Balance"
export const creditLineAutopayPaymentOptionStatement = "Statement Balance"
// "Grid will automatically debit the selected amount from your connected bank account."
export const creditLineAutopayRowSubtitle = "Grid will automatically debit the selected amount from your connected bank account."
// "Autopay"
export const creditLineAutopayRowTitle = "Autopay"
// "Manage Autopay"
export const creditLineAutopayScreenTitle = "Manage Autopay"
// "By saving, you authorize Grid to withdraw the selected payment amount from your Grid account and your bank account(s) ending in {{accounts}} on or after the statement date - the 7th of each month."
export const creditLineAutopayTerms = "By saving, you authorize Grid to withdraw the selected payment amount from your Grid account and your bank account(s) ending in {{accounts}} on or after the statement date - the 7th of each month."
// "By saving, you authorize Grid to withdraw the selected payment amount your Grid account and from your bank account(s) on or after the statement date - the 7th of each month."
export const creditLineAutopayTermsNoAccounts = "By saving, you authorize Grid to withdraw the selected payment amount your Grid account and from your bank account(s) on or after the statement date - the 7th of each month."
// "Manage Autopay"
export const creditLineAutopayTileCTALabel = "Manage Autopay"
// "Autopay scheduled {{date}}"
export const creditLineAutopayTileLabel = "Autopay scheduled {{date}}"
// "Autopay OFF"
export const creditLineAutopayTileOff = "Autopay OFF"
// "Autopay ON"
export const creditLineAutopayTileOn = "Autopay ON"
// "Transfer from"
export const creditLineAutopayTransferFromRowTitle = "Transfer from"
// "Sorry, we’re unable to provide instant access to Credit Line at this time. Please reach out to our support team for assistance."
export const creditLineBlockedConversionLedgerDiscrepancy = "Sorry, we’re unable to provide instant access to Credit Line at this time. Please reach out to our support team for assistance."
// "Apologies, we’re unable to provide instant access to Credit Line at this time. Please reach out to our support team for assistance."
export const creditLineBlockedConversionOpenDispute = "Apologies, we’re unable to provide instant access to Credit Line at this time. Please reach out to our support team for assistance."
// "Grid will automatically debit the minimum payment from your connected account."
export const creditLineCheckoutAutoPayDescription = "Grid will automatically debit the minimum payment from your connected account."
// "Stay on track with Autopay"
export const creditLineCheckoutAutoPayTitle = "Stay on track with Autopay"
// "Accept Credit Line"
export const creditLineCheckoutCTA = "Accept Credit Line"
// "By confirming, you are accepting the Grid Credit Terms and Synapse Loan Terms."
export const creditLineCheckoutLegal = "By confirming, you are accepting the Grid Credit Terms and Synapse Loan Terms."
// "By accepting, you agree to the Grid Credit Terms as well as the Synapse Loan Terms and authorize Grid to withdraw the minimum payment from your Grid account and your bank account(s) ending in {{accounts}} on or after the statement date - the 7th of each month."
export const creditLineCheckoutLegalWithAccounts = "By accepting, you agree to the Grid Credit Terms as well as the Synapse Loan Terms and authorize Grid to withdraw the minimum payment from your Grid account and your bank account(s) ending in {{accounts}} on or after the statement date - the 7th of each month."
// "By accepting, you agree to the Grid Credit Terms as well as the Synapse Loan Terms and authorize Grid to withdraw the minimum payment from your Grid account and your bank account(s) on or after the statement date - the 7th of each month."
export const creditLineCheckoutLegalWithNoAccounts = "By accepting, you agree to the Grid Credit Terms as well as the Synapse Loan Terms and authorize Grid to withdraw the minimum payment from your Grid account and your bank account(s) on or after the statement date - the 7th of each month."
// "Grid Credit"
export const creditLineCheckoutNavTitle = "Grid Credit"
// "Grid Credit Terms,Synapse Loan Terms"
export const creditLineCheckoutTermsLinkAlgoQuery = "Grid Credit Terms,Synapse Loan Terms"
// "Easy access to funds"
export const creditLineCheckoutValuePropOne = "Easy access to funds"
// "Low {{apr}}% APR"
export const creditLineCheckoutValuePropThree = "Low {{apr}}% APR"
// "No interest when you repay the statement balance each month"
export const creditLineCheckoutValuePropTwo = "No interest when you repay the statement balance each month"
// "Looks like you are using an old app version. Please update the Grid application and try again."
export const creditLineConversionAppVersion = "Looks like you are using an old app version. Please update the Grid application and try again."
// "It looks like you have a Credit Line transfer that is processing. Please wait a few moments for it to clear to draw down any available balance."
export const creditLineDrawDownDuplicateWarningLabel = "It looks like you have a Credit Line transfer that is processing. Please wait a few moments for it to clear to draw down any available balance."
// "Close"
export const creditLineDrawDownSucccessCTA = "Close"
// "Your {{amount}} transfer should arrive soon. If you don’t see it, please contact our support team."
export const creditLineDrawDownSucccessContent = "Your {{amount}} transfer should arrive soon. If you don’t see it, please contact our support team."
// "Your transfer is on its way!"
export const creditLineDrawDownSucccessTitle = "Your transfer is on its way!"
// "Remember, Credit Line is a loan. Make sure to only draw down what you can afford to pay back."
export const creditLineDrawDownWarningLabel = "Remember, Credit Line is a loan. Make sure to only draw down what you can afford to pay back."
// "Sorry, we need to verify your identity. Please reach out to our support team."
export const creditLineEnrollmentMissingPermissions = "Sorry, we need to verify your identity. Please reach out to our support team."
// "See What’s Possible ✨"
export const creditLineInitialScoreCTA = "See What’s Possible ✨"
// "Get paid 2 days early"
export const creditLineInitialScoreExplainItemOne = "Get paid 2 days early"
// "2x rewards"
export const creditLineInitialScoreExplainItemThree = "2x rewards"
// "Unlock a credit line"
export const creditLineInitialScoreExplainItemTwo = "Unlock a credit line"
// "Build credit faster with direct deposit"
export const creditLineInitialScoreExplainTitle = "Build credit faster with direct deposit"
// "We’re working on pulling your score. In the meantime, continue setting up your card."
export const creditLineInitialScoreNoFileMessage = "We’re working on pulling your score. In the meantime, continue setting up your card."
// "Not bad! But you can hit your goals faster with better credit. Here’s how ⬇️"
export const creditLineInitialScorePresentMessage = "Not bad! But you can hit your goals faster with better credit. Here’s how ⬇️"
// "You don’t have enough credit history yet 😢. Use Grid Card to get a score faster."
export const creditLineInitialScoreThinFileMessage = "You don’t have enough credit history yet 😢. Use Grid Card to get a score faster."
// "Current"
export const creditLineInitialScoreTitle = "Current"
// "Congrats! Based on your Grid Advance history, we’re giving you access to a {{credit_limit}} credit line. Order your free Grid Card to access it."
export const creditLineInvitationBody = "Congrats! Based on your Grid Advance history, we’re giving you access to a {{credit_limit}} credit line. Order your free Grid Card to access it."
// "Get Started"
export const creditLineInvitationCTA = "Get Started"
// "Congratulations"
export const creditLineInvitationCongratsTitle = "Congratulations"
// "We’re offering you a {{credit_limit}} credit line. Get started by requesting your Grid Card."
export const creditLineInvitationTileBody = "We’re offering you a {{credit_limit}} credit line. Get started by requesting your Grid Card."
// "Get Started"
export const creditLineInvitationTileCTA = "Get Started"
// "Unlock Credit Line"
export const creditLineInvitationTileTitle = "Unlock Credit Line"
// "Get Your {{credit_limit}} Credit Line"
export const creditLineInvitationTitle = "Get Your {{credit_limit}} Credit Line"
// "Get {{amt}}"
export const creditLineInvitedGoalTitle = "Get {{amt}}"
// "Complete the following goals this month to unlock a {{amt}} credit line on your card."
export const creditLineInvitedGoalsubtitle = "Complete the following goals this month to unlock a {{amt}} credit line on your card."
// "Minimum Payment"
export const creditLineLatePaymentScreenMinimumPayment = "Minimum Payment"
// "Payment Amount"
export const creditLineLatePaymentScreenPaymentAmount = "Payment Amount"
// "Statement Balance"
export const creditLineLatePaymentScreenStatementBalance = "Statement Balance"
// "Avoid interest fees by paying off your statement balance on time. Remaining balance will accrue interest at {{amt}}% APR."
export const creditLineLatePaymentScreenStatementBalanceDescription = "Avoid interest fees by paying off your statement balance on time. Remaining balance will accrue interest at {{amt}}% APR."
// "Recommended"
export const creditLineLatePaymentScreenStatementBalanceRecommended = "Recommended"
// "Before your due date, Autopay will check your connected accounts and make a payment if there’s sufficient balance."
export const creditLineLatePaymentScreenSubtitle = "Before your due date, Autopay will check your connected accounts and make a payment if there’s sufficient balance."
// "Late payment protection"
export const creditLineLatePaymentScreenTitle = "Late payment protection"
// "Grid protects your credit score from late payments with Autopay."
export const creditLineLatePaymentSubtitle = "Grid protects your credit score from late payments with Autopay."
// "Late Payment Protection"
export const creditLineLatePaymentTitle = "Late Payment Protection"
// "Amount Remaining"
export const creditLineLevelsAmountRemaining = "Amount Remaining"
// "{{amount}}"
export const creditLineLevelsAmountToSpend = "{{amount}}"
// "Spend at least {{amount}} on your Grid Card this month to complete this goal."
export const creditLineLevelsAmountToSpendGoal = "Spend at least {{amount}} on your Grid Card this month to complete this goal."
// "Complete the following goals this month to unlock a higher credit line."
export const creditLineLevelsCompleteGoals = "Complete the following goals this month to unlock a higher credit line."
// "Get {{amount}} More"
export const creditLineLevelsGetMoreMoney = "Get {{amount}} More"
// "Goal"
export const creditLineLevelsGoal = "Goal"
// "Hide Details"
export const creditLineLevelsHideDetails = "Hide Details"
// "Late payments may impact your credit score and result in more interest fees."
export const creditLineLevelsLatePaymentImpact = "Late payments may impact your credit score and result in more interest fees."
// "Please make a payment in order to continue making progress on getting {{amount}} more in credit line."
export const creditLineLevelsMakeAPayment = "Please make a payment in order to continue making progress on getting {{amount}} more in credit line."
// "Make at least one direct deposit of $250 or more this month."
export const creditLineLevelsMakeAtLeastOneDirectDeposit = "Make at least one direct deposit of $250 or more this month."
// "Make a Direct Deposit"
export const creditLineLevelsMakeDirectDeposit = "Make a Direct Deposit"
// "{{amount}} days left"
export const creditLineLevelsNumberOfDaysLeft = "{{amount}} days left"
// "You have an overdue payment"
export const creditLineLevelsOverduePayment = "You have an overdue payment"
// "Remember to pay your statement on time! You won’t be upgraded to a higher credit line if you have an overdue payment."
export const creditLineLevelsRememberToPayText = "Remember to pay your statement on time! You won’t be upgraded to a higher credit line if you have an overdue payment."
// "{{amt}} line"
export const creditLineLevelsScreenLevelSubtitle = "{{amt}} line"
// "Level {{level}}"
export const creditLineLevelsScreenLevelTitle = "Level {{level}}"
// "10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000"
export const creditLineLevelsScreenLevels = "10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000"
// "Level up by making 2 direct deposits of $250 every month!"
export const creditLineLevelsScreenSubtitle = "Level up by making 2 direct deposits of $250 every month!"
// "Build Your Credit Line"
export const creditLineLevelsScreenTitle = "Build Your Credit Line"
// "Show Details"
export const creditLineLevelsShowDetails = "Show Details"
// "Spend {{amount}}"
export const creditLineLevelsSpendAmount = "Spend {{amount}}"
// "Total Spend"
export const creditLineLevelsTotalSpend = "Total Spend"
// "If you haven’t made a minimum payment by {{date}}, we’ll withdraw {{amount}} from your connected accounts to keep you on track."
export const creditLineMakePaymentMinimumPaymentDescription = "If you haven’t made a minimum payment by {{date}}, we’ll withdraw {{amount}} from your connected accounts to keep you on track."
// "Stay on track with minimum payments"
export const creditLineMakePaymentMinimumPaymentTitle = "Stay on track with minimum payments"
// "Choose Amount"
export const creditLineMakePaymentNavTitle = "Choose Amount"
// "Enter Amount"
export const creditLineMakePaymentOptionEnterAmountTitle = "Enter Amount"
// "This is less than your minimum payment. This may impact your credit score and result in more interest fees."
export const creditLineMakePaymentOptionEnteredLessThanMin = "This is less than your minimum payment. This may impact your credit score and result in more interest fees."
// "Owe {{amount}} in interest by {{date}}"
export const creditLineMakePaymentOptionMinimumPaymentDescription = "Owe {{amount}} in interest by {{date}}"
// "REMAINING MINIMUM PAYMENT"
export const creditLineMakePaymentOptionMinimumPaymentTitle = "REMAINING MINIMUM PAYMENT"
// "No interest fee"
export const creditLineMakePaymentOptionStatementBalanceDescription = "No interest fee"
// "REMAINING STATEMENT BALANCE"
export const creditLineMakePaymentOptionStatementBalanceTitle = "REMAINING STATEMENT BALANCE"
// "How much do you want to pay?"
export const creditLineMakePaymentTitle = "How much do you want to pay?"
// "Your credit line is a loan, and late payments may be reported to credit bureaus."
export const creditLineMakepaymentWarningSubtitle = "Your credit line is a loan, and late payments may be reported to credit bureaus."
// "You have an overdue payment"
export const creditLineMakepaymentWarningTitle = "You have an overdue payment"
// "Please pay the minimum payment amount to access your Credit Line."
export const creditLineMinimumPaymentMustBePaid = "Please pay the minimum payment amount to access your Credit Line."
// "Looks like you have a past due subscription payment. Please make your latest subscription payment to access your Credit Line."
export const creditLineOutstandingSubscriptionFee = "Looks like you have a past due subscription payment. Please make your latest subscription payment to access your Credit Line."
// "Make a minimum payment in order to avoid negative impact to your credit score."
export const creditLinePastDueBannerAvoidNegativeSubtitle = "Make a minimum payment in order to avoid negative impact to your credit score."
// "Avoid negative credit impact"
export const creditLinePastDueBannerAvoidNegativeTitle = "Avoid negative credit impact"
// "Pay down your outstanding balance to reduce interest fees on your next statement."
export const creditLinePastDueBannerReduceInterestSubtitle = "Pay down your outstanding balance to reduce interest fees on your next statement."
// "Reduce interest fees"
export const creditLinePastDueBannerReduceInterestTitle = "Reduce interest fees"
// "Please reach out to us to discuss how we can support you."
export const creditLinePastDueDefaultedSubtitle = "Please reach out to us to discuss how we can support you."
// "Your account is defaulted"
export const creditLinePastDueDefaultedTitle = "Your account is defaulted"
// "Please make a payment in order to avoid further negative impact to your credit score."
export const creditLinePastDueIsDelinquentSubtitle = "Please make a payment in order to avoid further negative impact to your credit score."
// "Your account is delinquent"
export const creditLinePastDueIsDelinquentTitle = "Your account is delinquent"
// "Make a payment to avoid negative impact to your credit score."
export const creditLinePastDueSubtitle = "Make a payment to avoid negative impact to your credit score."
// "Your account is past due"
export const creditLinePastDueTitle = "Your account is past due"
// "https://getgrid.app/terms,https://storage.googleapis.com/grid-creditline-docs/Pier%20ACH%20Authorization.pdf"
export const creditLinePayNowAcceptTermsLinkAlgoQueryTermsAttribution = "https://getgrid.app/terms,https://storage.googleapis.com/grid-creditline-docs/Pier%20ACH%20Authorization.pdf"
// "By confirming your payment, you agree to Grid’s Terms as well as the ACH Authorization Terms."
export const creditLinePayNowTerms = "By confirming your payment, you agree to Grid’s Terms as well as the ACH Authorization Terms."
// "Grid’s Terms,ACH Authorization Terms"
export const creditLinePayNowTermsTermsLinkAlgoQuery = "Grid’s Terms,ACH Authorization Terms"
// "Your Grid Card balance is too low to make this payment. Please try again with a different amount."
export const creditLinePaymentInsufficientBoostCardBalance = "Your Grid Card balance is too low to make this payment. Please try again with a different amount."
// "Close"
export const creditLinePaymentSucccessCTA = "Close"
// "Paying on time helps increase your credit score and build towards a higher credit line."
export const creditLinePaymentSucccessContent = "Paying on time helps increase your credit score and build towards a higher credit line."
// "It may take up to 6 business days to process your payment. Once fully processed, your credit line balance will be updated."
export const creditLinePaymentSucccessContentBankingTransfer = "It may take up to 6 business days to process your payment. Once fully processed, your credit line balance will be updated."
// "Thank you for your payment"
export const creditLinePaymentSucccessTitle = "Thank you for your payment"
// "Your payment has been submitted"
export const creditLinePaymentSucccessTitleBankingTransfer = "Your payment has been submitted"
// "Unlock a $100 credit line when you set up direct deposit and spend with your card"
export const creditLineProgressTileBody = "Unlock a $100 credit line when you set up direct deposit and spend with your card"
// "View Progress"
export const creditLineProgressTileCTA = "View Progress"
// "Unlock credit line"
export const creditLineProgressTileTitle = "Unlock credit line"
// "Date"
export const creditLineReceiptsDetailsScreenDate = "Date"
// "Problem with this transaction?"
export const creditLineReceiptsDetailsScreenHelpButton = "Problem with this transaction?"
// "Status"
export const creditLineReceiptsDetailsScreenStatus = "Status"
// "Type"
export const creditLineReceiptsDetailsScreenType = "Type"
// "Vendor"
export const creditLineReceiptsDetailsScreenVendor = "Vendor"
// "This is the end of your credit line receipts."
export const creditLineReceiptsScreenEndOfReceipts = "This is the end of your credit line receipts."
// "You have no receipts at this time."
// ""
// "When you draw down your credit line or make payments towards your balance, the receipts will be available here."
export const creditLineReceiptsScreenNoReceipts = "You have no receipts at this time.\u000A\u000AWhen you draw down your credit line or make payments towards your balance, the receipts will be available here."
// "Credit Line Transactions"
export const creditLineReceiptsScreenTitle = "Credit Line Transactions"
// "Credit Line Repayment"
export const creditLineReceiptsScreenTypeRepayment = "Credit Line Repayment"
// "Transfer to Grid Card"
export const creditLineReceiptsScreenTypeTransferToBoostcard = "Transfer to Grid Card"
// "Sorry, we’re still processing your Credit Line repayment. You can access your Credit Line once the transaction clears. This can take up to 6 business days."
export const creditLineRepaymentAwaitingClearing = "Sorry, we’re still processing your Credit Line repayment. You can access your Credit Line once the transaction clears. This can take up to 6 business days."
// "This is more than your current credit line balance. Please try again with a lower amount."
export const creditLineRepaymentExceedsOutstandingAmount = "This is more than your current credit line balance. Please try again with a lower amount."
// "Payment Amount"
export const creditLineReviewPaymentAmount = "Payment Amount"
// "Pay {{amount}}"
export const creditLineReviewPaymentConfirmCTA = "Pay {{amount}}"
// "Interest Fee"
export const creditLineReviewPaymentInterestFee = "Interest Fee"
// "Review Payment"
export const creditLineReviewPaymentNavTitle = "Review Payment"
// "Total"
export const creditLineReviewPaymentTotalAmount = "Total"
// "Transfer From"
export const creditLineReviewPaymentTransferFrom = "Transfer From"
// "You have nothing due currently. Your new statement balance, minimum payment, and payment due date will be available on {{date}}."
export const creditLineTileBannerAllPaidDescription = "You have nothing due currently. Your new statement balance, minimum payment, and payment due date will be available on {{date}}."
// "Nothing to pay"
export const creditLineTileBannerAllPaidTitle = "Nothing to pay"
// "You have nothing due currently. Your statement balance, minimum payment, and payment due date will be available on {{date}}."
export const creditLineTileBannerDrawdownBlockedDescription = "You have nothing due currently. Your statement balance, minimum payment, and payment due date will be available on {{date}}."
// "Nothing to pay"
export const creditLineTileBannerDrawdownBlockedTitle = "Nothing to pay"
// "You have nothing due currently. Check back on {{date}} for updated information."
export const creditLineTileBannerFirstMonthDescription = "You have nothing due currently. Check back on {{date}} for updated information."
// "Nothing to pay"
export const creditLineTileBannerFirstMonthTitle = "Nothing to pay"
// "Continue"
export const creditLineTileHelpScreenCTA1Title = "Continue"
// "Got it!"
export const creditLineTileHelpScreenCTA2Title = "Got it!"
// "If you pay off this amount, you won’t be charged any interest."
export const creditLineTileHelpScreenSection1Item1Description = "If you pay off this amount, you won’t be charged any interest."
// "Statement Balance:"
export const creditLineTileHelpScreenSection1Item1Title = "Statement Balance:"
// "The least amount you can pay without being overdue."
export const creditLineTileHelpScreenSection1Item2Description = "The least amount you can pay without being overdue."
// "Minimum Payment:"
export const creditLineTileHelpScreenSection1Item2Title = "Minimum Payment:"
// "Pay before this date."
export const creditLineTileHelpScreenSection1Item3Description = "Pay before this date."
// "Payment Due Date:"
export const creditLineTileHelpScreenSection1Item3Title = "Payment Due Date:"
// "What do I owe?"
export const creditLineTileHelpScreenSection1Title = "What do I owe?"
// "Maximum amount you can borrow."
export const creditLineTileHelpScreenSection2Item1Description = "Maximum amount you can borrow."
// "Credit Line Limit:"
export const creditLineTileHelpScreenSection2Item1Title = "Credit Line Limit:"
// "Total amount you are currently borrowing."
export const creditLineTileHelpScreenSection2Item2Description = "Total amount you are currently borrowing."
// "Outstanding Balance:"
export const creditLineTileHelpScreenSection2Item2Title = "Outstanding Balance:"
// "We’ll automatically make a payment on your behalf."
export const creditLineTileHelpScreenSection2Item3Description = "We’ll automatically make a payment on your behalf."
// "Autopay:"
export const creditLineTileHelpScreenSection2Item3Title = "Autopay:"
// "If you have a remaining statement balance by the due date, you will be charged interest."
export const creditLineTileHelpScreenSection2Item4Description = "If you have a remaining statement balance by the due date, you will be charged interest."
// "Interest:"
export const creditLineTileHelpScreenSection2Item4Title = "Interest:"
// "What else should I know?"
export const creditLineTileHelpScreenSection2Title = "What else should I know?"
// "Remember, Credit Line is a loan. Make sure to only use what you can afford to pay back."
export const creditLineTileHelpScreenSection3Item1Description = "Remember, Credit Line is a loan. Make sure to only use what you can afford to pay back."
// "Pay your remaining statement balance each month to avoid interest charges."
export const creditLineTileHelpScreenSection3Item2Description = "Pay your remaining statement balance each month to avoid interest charges."
// "Pay as early as possible to ensure your payments are on-time."
export const creditLineTileHelpScreenSection3Item3Description = "Pay as early as possible to ensure your payments are on-time."
// "What are some helpful tips?"
export const creditLineTileHelpScreenSection3Title = "What are some helpful tips?"
// "Understanding the Credit Line Program"
export const creditLineTileHelpScreenSubtitle = "Understanding the Credit Line Program"
// "Credit Line Program"
export const creditLineTileHelpScreenTitle = "Credit Line Program"
// "Generic Credits"
export const creditLineTransactionTypeGenericCredit = "Generic Credits"
// "Generic Debits"
export const creditLineTransactionTypeGenericDebit = "Generic Debits"
// "Interest payment"
export const creditLineTransactionTypeInterestPayment = "Interest payment"
// "-"
export const creditLineTransactionTypeInvalid = "-"
// "Credit Line Payment"
export const creditLineTransactionTypePayment = "Credit Line Payment"
// "Withdrawal to {{vendor}}"
export const creditLineTransactionTypeTransferToVendor = "Withdrawal to {{vendor}}"
// "-"
export const creditLineTransactionTypeUnclassified = "-"
// "Transfer to Card"
export const creditLineTransactionTypeWithdrawalToBoostCard = "Transfer to Card"
// "No thanks, I only want {{amt}}"
export const creditLineUnlockDecline = "No thanks, I only want {{amt}}"
// "STARTING CREDIT LINE"
export const creditLineUnlockSubTitle = "STARTING CREDIT LINE"
// "You’ve unlocked Credit Line!"
export const creditLineUnlockTitle = "You’ve unlocked Credit Line!"
// "Your Current Score"
export const creditLineUpdatedInitialScoreTitle = "Your Current Score"
// "Credit Factors"
export const creditReportDetailsHeader = "Credit Factors"
// "Hide Details"
export const creditReportDetailsHideDetails = "Hide Details"
// "Show Details"
export const creditReportDetailsShowMore = "Show Details"
// "Score Details"
export const creditReportDetailsTitle = "Score Details"
// "View Score Details"
export const creditReportViewDetailsCta = "View Score Details"
// "Continue"
export const dashboardCellCheckinContinueButton = "Continue"
// "Account paused"
export const dashboardCellCheckinInBlockedLabel = "Account paused"
// "Continue your check-in"
export const dashboardCellCheckinInProgressLabel = "Continue your check-in"
// "Begin"
export const dashboardCellCheckinReadyButton = "Begin"
// "Your newest check‑in is ready to start!"
export const dashboardCellCheckinReadyLabel = "Your newest check‑in is ready to start!"
// "{{month}} – Not Completed"
export const dashboardCellCheckinSkippedLabel = "{{month}} – Not Completed"
// "Completed {{completed_date}}"
export const dashboardCellCheckinStatusCompleted = "Completed {{completed_date}}"
// "Ready to begin"
export const dashboardCellCheckinStatusReady = "Ready to begin"
// "Ready in {{days}} days"
export const dashboardCellCheckinStatusScheduledDays = "Ready in {{days}} days"
// "Soon"
export const dashboardCellCheckinStatusScheduledSoon = "Soon"
// "Tomorrow"
export const dashboardCellCheckinStatusScheduledTomorrow = "Tomorrow"
// "Expand to see breakdown"
export const dashboardHeaderBoostActivityBody = "Expand to see breakdown"
// "{{streak_number}} month streak! Bonus next month!"
export const dashboardHeaderBoostActivityBodyBonusNext = "{{streak_number}} month streak! Bonus next month!"
// "Your PayBoost is pending"
export const dashboardHeaderBoostActivityBodyEmpty = "Your PayBoost is pending"
// "{{streak_number}} month streak! Keep up the good work"
export const dashboardHeaderBoostActivityBodyStreak = "{{streak_number}} month streak! Keep up the good work"
// "Your monthly activity"
export const dashboardHeaderBoostActivityTitle = "Your monthly activity"
// "month"
export const dashboardHeaderBoostProgressDenominator = "month"
// "/"
export const dashboardHeaderBoostProgressDenominatorSymbol = "/"
// "PayBoost received this year"
export const dashboardHeaderBoostProgressDescription = "PayBoost received this year"
// "Your pending PayBoost"
export const dashboardHeaderBoostProgressDescriptionNone = "Your pending PayBoost"
// "of"
export const dashboardHeaderBoostProgressTotalPrefix = "of"
// "Check it out"
export const dashboardHeaderBoostReadyLabelButton = "Check it out"
// "Your PayBoost is ready for review."
export const dashboardHeaderBoostReadyLabelInfo = "Your PayBoost is ready for review."
// "Ready Now!"
export const dashboardHeaderBoostReadyLabelTitle = "Ready Now!"
// "Your PayBoost projection will be ready within:"
export const dashboardHeaderBoostWaitingLabelInfo = "Your PayBoost projection will be ready within:"
// "Your PayBoost projection will be ready soon."
export const dashboardHeaderBoostWaitingLabelInfoSoon = "Your PayBoost projection will be ready soon."
// "{{hours}}"
export const dashboardHeaderBoostWaitingLabelTimeCount = "{{hours}}"
// "hours"
export const dashboardHeaderBoostWaitingLabelTimeUnit = "hours"
// "Monthly Check-ins"
export const dashboardHeaderCheckinTitle = "Monthly Check-ins"
// "Loading…"
export const dashboardNoTileLoadingView = "Loading…"
// "Banking"
export const dashboardTabControllerBankingTabBarItemTitle = "Banking"
// "Banking"
export const dashboardTabControllerBoostCardBankTabBarItemTitle = "Banking"
// "Card"
export const dashboardTabControllerBoostCardCardTabBarItemTitle = "Card"
// "Card"
export const dashboardTabControllerCardTabBarItemTitle = "Card"
// "Advance"
export const dashboardTabControllerCashboostTabBarItemTitle = "Advance"
// "Credit"
export const dashboardTabControllerCreditTabBarItemTitle = "Credit"
// "Pay"
export const dashboardTabControllerPayboostTabBarItemTitle = "Pay"
// "Need help? Reach out via chat."
export const dashboardTileUnknownErrorCTA = "Need help? Reach out via chat."
// "An unexpected error has occurred. Please let us know if this continues to happen."
export const dashboardTileUnknownErrorContent = "An unexpected error has occurred. Please let us know if this continues to happen."
// "Unknown Error"
export const dashboardTileUnknownErrorTitle = "Unknown Error"
// "Unable to process your payment. Please wait a minute."
export const deprecateClientRpcRepayCashboostAdvance = "Unable to process your payment. Please wait a minute."
// "Set up direct deposit to start building towards a $3,000 credit line."
export const directDepositPromoVarOneBody = "Set up direct deposit to start building towards a $3,000 credit line."
// "Set up Direct Deposit"
export const directDepositPromoVarOneCta = "Set up Direct Deposit"
// "Unlock Credit Line"
export const directDepositPromoVarOneTitle = "Unlock Credit Line"
// "Set up direct deposits and start earning 2x rewards on every purchase."
export const directDepositPromoVarThreeBody = "Set up direct deposits and start earning 2x rewards on every purchase."
// "Set up Direct Deposit"
export const directDepositPromoVarThreeCta = "Set up Direct Deposit"
// "Earn 2x Rewards"
export const directDepositPromoVarThreeTitle = "Earn 2x Rewards"
// "Set up direct deposit to get paid up to 2 days earlier and unlock other benefits!"
export const directDepositPromoVarTwoBody = "Set up direct deposit to get paid up to 2 days earlier and unlock other benefits!"
// "Set up Direct Deposit"
export const directDepositPromoVarTwoCta = "Set up Direct Deposit"
// "Get Paid Earlier"
export const directDepositPromoVarTwoTitle = "Get Paid Earlier"
// "Account"
export const directDepositSetupAccount = "Account"
// "Close"
export const directDepositSetupClose = "Close"
// "Set up Direct Deposit"
export const directDepositSetupHeader = "Set up Direct Deposit"
// "Set up Direct Deposit"
export const directDepositSetupIntroCta = "Set up Direct Deposit"
// "What you could earn as you spend with Grid Card:"
export const directDepositSetupIntroEarn = "What you could earn as you spend with Grid Card:"
// "+200"
export const directDepositSetupIntroEarnItemOneAmt = "+200"
// "to your credit score* 📈"
export const directDepositSetupIntroEarnItemOneLabel = "to your credit score* 📈"
// "$30"
export const directDepositSetupIntroEarnItemTwoAmt = "$30"
// "in cash back points 💵"
export const directDepositSetupIntroEarnItemTwoLabel = "in cash back points 💵"
// "1500"
export const directDepositSetupIntroExampleSpendingAmt = "1500"
// "/month"
export const directDepositSetupIntroExampleSpendingDuration = "/month"
// "*Individual results may vary. Estimate based on initial credit score of <600."
export const directDepositSetupIntroFinePrint = "*Individual results may vary. Estimate based on initial credit score of \u003C600."
// "See how far your money could go with Grid Card:"
export const directDepositSetupIntroTitle = "See how far your money could go with Grid Card:"
// "Routing"
export const directDepositSetupRouting = "Routing"
// "Just forward it to HR or update your direct deposit details with your Grid account info:"
export const directDepositSetupSubtitle = "Just forward it to HR or update your direct deposit details with your Grid account info:"
// "We’ve emailed you an updated direct deposit form"
export const directDepositSetupTitle = "We’ve emailed you an updated direct deposit form"
// "Untitled"
export const documentsCellFolderDefaultFileName = "Untitled"
// "Cancel"
export const documentsFolderAlertCancelActionCancel = "Cancel"
// "Yes, I’m sure"
export const documentsFolderAlertCancelActionDismiss = "Yes, I’m sure"
// "Are you sure you want to cancel uploading these documents?"
export const documentsFolderAlertCancelTitle = "Are you sure you want to cancel uploading these documents?"
// "Cancel"
export const documentsFolderAlertDeleteCancel = "Cancel"
// "Delete"
export const documentsFolderAlertDeleteTitle = "Delete"
// "Upload Documents"
export const documentsFolderEmptyStateButton = "Upload Documents"
// "Feel free to send us a document through chat or during next month’s check-in."
export const documentsFolderEmptyStateReadonlySubtitle = "Feel free to send us a document through chat or during next month’s check-in."
// "This folder is empty."
export const documentsFolderEmptyStateReadonlyTitle = "This folder is empty."
// "Submit your most recent statements."
export const documentsFolderEmptyStateSubtitle = "Submit your most recent statements."
// "Upload your first document"
export const documentsFolderEmptyStateTitle = "Upload your first document"
// "Folder"
export const documentsFolderNameDefault = "Folder"
// "Cancel"
export const documentsFolderNavCancel = "Cancel"
// "Done"
export const documentsFolderNavDone = "Done"
// "iPhone Capture {{timestamp}}.jpg"
export const documentsFolderPhotoName = "iPhone Capture {{timestamp}}.jpg"
// "Untitled"
export const documentsFolderTitleDefault = "Untitled"
// "Search"
export const documentsSearchFieldPlaceholder = "Search"
// "Upload files"
export const documentsSourcePickerButtonFiles = "Upload files"
// "Upload photos"
export const documentsSourcePickerButtonPhotos = "Upload photos"
// "Scan documents"
export const documentsSourcePickerButtonScan = "Scan documents"
// "Upload documents"
export const documentsSourcePickerHeader = "Upload documents"
// "Recent"
export const documentsTabRecent = "Recent"
// "Tax Returns"
export const documentsTabTaxReturns = "Tax Returns"
// "Uploaded"
export const documentsTabUploaded = "Uploaded"
// "W-4s"
export const documentsTabW4S = "W-4s"
// "Documents"
export const documentsTitle = "Documents"
// "Email Address"
export const emailUpdatePrefill = "Email Address"
// "What’s your email?"
export const emailUpdatePrompt = "What’s your email?"
// ""
export const emptyFallback = ""
// "Sorry! Our banking partner is having issues. Please try again later."
export const errSynapseIsBroken = "Sorry! Our banking partner is having issues. Please try again later."
// "Please finish signing up before turning on two-factor authentication."
export const error2FABeforeEmailSet = "Please finish signing up before turning on two-factor authentication."
// "The phone number on file isn’t working. Please update your phone number and try again."
export const error2FAInvalidPhoneNumber = "The phone number on file isn’t working. Please update your phone number and try again."
// "2FA is already setup."
export const error2FAOtpAlreadySetup = "2FA is already setup."
// "The code entered is incorrect."
export const error2FAOtpCodeIncorrect = "The code entered is incorrect."
// "Something went wrong, please email us at hi@getgrid.app and we’ll get back to you."
export const errorClientsAmbiguousLoginEmail = "Something went wrong, please email us at hi@getgrid.app and we’ll get back to you."
// "There’s no Grid account associated with this email address."
export const errorClientsBadEmail = "There’s no Grid account associated with this email address."
// "The account you logged in to is not currently set up for the Grid app. Please continue to use the Visor app."
export const errorClientsCrossAppLoginUnsupported = "The account you logged in to is not currently set up for the Grid app. Please continue to use the Visor app."
// "Your email is already verified."
export const errorClientsEmailAlreadyVerified = "Your email is already verified."
// "We already have an account using this email address. Please log into that account, or contact us if you need help."
export const errorClientsEmailNotAvailable = "We already have an account using this email address. Please log into that account, or contact us if you need help."
// "Your email is not yet verified. Please verify your email."
export const errorClientsEmailNotVerified = "Your email is not yet verified. Please verify your email."
// "This confirmation link is expired."
export const errorClientsExpiredConfirmEmail = "This confirmation link is expired."
// "This confirm email link is invalid."
export const errorClientsInvalidConfirmEmail = "This confirm email link is invalid."
// "Please enter a valid email address."
export const errorClientsInvalidEmail = "Please enter a valid email address."
// "This email address ends in .con. That’s often a typo."
export const errorClientsInvalidEmailDotCon = "This email address ends in .con. That’s often a typo."
// "This email address ends in a period. That’s often a typo."
export const errorClientsInvalidEmailEndsInDot = "This email address ends in a period. That’s often a typo."
// "This email address does not appear to be valid."
export const errorClientsInvalidEmailFormat = "This email address does not appear to be valid."
// "This password reset link is invalid."
export const errorClientsInvalidPasswordReset = "This password reset link is invalid."
// "Please enter a valid phone number."
export const errorClientsInvalidPhoneNumber = "Please enter a valid phone number."
// "This confirmation link has been used already."
export const errorClientsUsedConfirmEmailLink = "This confirmation link has been used already."
// "If you’d like to close your account or cancel your subscription, please send us a chat message."
export const errorCloseAccountNotAllowed = "If you’d like to close your account or cancel your subscription, please send us a chat message."
// "Please check your internet connection and try again."
export const errorConnectionMessage = "Please check your internet connection and try again."
// "Unable to connect"
export const errorConnectionTitle = "Unable to connect"
// "Looks like something went wrong! This shouldn’t be happening, so please email our support team at support@getgrid.app"
export const errorContactSupportPreOnboardingMessage = "Looks like something went wrong! This shouldn’t be happening, so please email our support team at support@getgrid.app"
// "Oops!"
export const errorContactSupportPreOnboardingTitle = "Oops!"
// "We ran into a small hiccup. Please try again in a bit. If there are still issues, please reach out to Support for help."
export const errorDefaultMessage = "We ran into a small hiccup. Please try again in a bit. If there are still issues, please reach out to Support for help."
// "Oops!"
export const errorDefaultTitle = "Oops!"
// "Please add another payment method first in order to delete this one."
export const errorDeletingOnlyPaymentMethodNotPermitted = "Please add another payment method first in order to delete this one."
// "Your payment was declined, but our special feature can get you through today."
export const errorDiscountAppliedAfterInsufficientFunds = "Your payment was declined, but our special feature can get you through today."
// "Continue"
export const errorDiscountAppliedAfterInsufficientFundsContinue = "Continue"
// "It’s all good"
export const errorDiscountAppliedAfterInsufficientFundsTitle = "It’s all good"
// "Please double check your entries and try again."
export const errorInvalidArgumentFallbackMessage = "Please double check your entries and try again."
// "Uh oh"
export const errorInvalidArgumentTitle = "Uh oh"
// "For your security, all passwords must contain at least 1 lower case, 1 upper case, 1 number and 1 special character."
export const errorPasswordComplexityNotComplex = "For your security, all passwords must contain at least 1 lower case, 1 upper case, 1 number and 1 special character."
// "For your security, passwords must not contain the same character three times in a row."
export const errorPasswordComplexityRepeatedCharacters = "For your security, passwords must not contain the same character three times in a row."
// "For your security, passwords must be shorter than 129 characters."
export const errorPasswordComplexityTooLong = "For your security, passwords must be shorter than 129 characters."
// "Login is not possible at this time."
export const errorPasswordNoPasswordSet = "Login is not possible at this time."
// "Password is incorrect."
export const errorPasswordPasswordIncorrect = "Password is incorrect."
// "For your security, commonly chosen passwords are not allowed. Please try a different password."
export const errorPasswordTooCommon = "For your security, commonly chosen passwords are not allowed. Please try a different password."
// "If you’d like to pause your subscription, please send us a chat message."
export const errorPauseAccountNotAllowed = "If you’d like to pause your subscription, please send us a chat message."
// "Please enter a valid company ID."
export const errorPayrollCompanyIdentifierRequired = "Please enter a valid company ID."
// "We’re unable to accept your answer right now."
export const errorPayrollConnectionMFAChallengeInvalidState = "We’re unable to accept your answer right now."
// "We’re having trouble connecting to your account. Our payroll specialists would love to help you with this process."
export const errorPayrollCredentialChangesRateLimited = "We’re having trouble connecting to your account. Our payroll specialists would love to help you with this process."
// "Payroll login is not available at the moment. Please try again soon."
export const errorPayrollCredentialResourceExhaustedFallback = "Payroll login is not available at the moment. Please try again soon."
// "This company login URL does not appear to be valid."
export const errorPayrollLoginUrlInvalid = "This company login URL does not appear to be valid."
// "Please enter your company login URL."
export const errorPayrollLoginUrlRequired = "Please enter your company login URL."
// "Please enter the password for your payroll account."
export const errorPayrollPasswordRequired = "Please enter the password for your payroll account."
// "Please enter a valid sandbox username."
export const errorPayrollUnsupportedSandboxUsername = "Please enter a valid sandbox username."
// "Please enter the username for your payroll account."
export const errorPayrollUsernameRequired = "Please enter the username for your payroll account."
// "Sorry, the referral"
// "link isn’t valid."
export const errorReferralVoucherCodeInvalid = "Sorry, the referral\u000Alink isn’t valid."
// "You currently have a pending transaction. Please try again in 1-2 days."
export const errorRepayAdvancePaymentPending = "You currently have a pending transaction. Please try again in 1-2 days."
// "Error retrieving Advance History Receipts."
export const errorRetrievingAdvanceHistoryReceipts = "Error retrieving Advance History Receipts."
// "Subscription is already paused."
export const errorSubscriptionsSubscriptionPaused = "Subscription is already paused."
// "Sorry! Name changes are not allowed in app. Please write in for help."
export const errorUpdateNameNotSupported = "Sorry! Name changes are not allowed in app. Please write in for help."
// "We could not verify your bank account. Please try again."
export const errorVerifyBankAccountFailed = "We could not verify your bank account. Please try again."
// "The amount is too large."
export const errorWorkflowAmountTooLarge = "The amount is too large."
// "The amount is too small."
export const errorWorkflowAmountTooSmall = "The amount is too small."
// "We have some exciting new features for you to see, but in order to continue we need you to update your app!"
export const errorWorkflowAppVersionNotNewEnough = "We have some exciting new features for you to see, but in order to continue we need you to update your app!"
// "You need at least one job in order to use Grid."
export const errorWorkflowAtLeastOneJobsIsRequired = "You need at least one job in order to use Grid."
// "Please fix your payroll connection."
export const errorWorkflowBrokenConnection = "Please fix your payroll connection."
// "The date entered is too early."
export const errorWorkflowDateTooEarly = "The date entered is too early."
// "The date entered is too late."
export const errorWorkflowDateTooLate = "The date entered is too late."
// "Please upload the required document."
export const errorWorkflowDocumentRequestRequired = "Please upload the required document."
// "Your email address is not confirmed, please check your email and verify it by clicking the link."
export const errorWorkflowEmailIsNotConfirmed = "Your email address is not confirmed, please check your email and verify it by clicking the link."
// "The email you provided is already associated with a Visor account. Please continue to use the Visor app."
export const errorWorkflowEmailReservedForVisorClient = "The email you provided is already associated with a Visor account. Please continue to use the Visor app."
// "This is a validation message."
export const errorWorkflowExampleValidationMessage = "This is a validation message."
// "Hmm, that entry doesn’t seem to be correct. Please try again or contact support."
export const errorWorkflowLastFourSsnIsNotConfirmed = "Hmm, that entry doesn’t seem to be correct. Please try again or contact support."
// "It looks like you’ve swapped your pay period start and end dates."
export const errorWorkflowPayPeriodDatesSwapped = "It looks like you’ve swapped your pay period start and end dates."
// "The pay period you’ve entered is in the future, please check your entries and try again."
export const errorWorkflowPayPeriodIsInFuture = "The pay period you’ve entered is in the future, please check your entries and try again."
// "This pay statement covers too many days compared to how often you said you’re paid. If you’re sure this is correct, please contact us for help."
export const errorWorkflowPayPeriodIsTooLong = "This pay statement covers too many days compared to how often you said you’re paid. If you’re sure this is correct, please contact us for help."
// "This pay statement is too old. Please input a newer pay statement."
export const errorWorkflowPayPeriodIsTooOld = "This pay statement is too old. Please input a newer pay statement."
// "It looks like your Year-To-Date Federal Tax amount is smaller than last month’s entry of ${{min}}."
export const errorWorkflowPayStatementFedTaxYTDValuesBelowPrevious = "It looks like your Year-To-Date Federal Tax amount is smaller than last month’s entry of ${{min}}."
// "It looks like you’ve entered $0 Federal Tax Paid. This number is the most important for us to maximize your PayBoost. If you’re certain this is correct, please enter $1 here to proceed."
export const errorWorkflowPayStatementFederalIncomeTaxIsZero = "It looks like you’ve entered $0 Federal Tax Paid. This number is the most important for us to maximize your PayBoost. If you’re certain this is correct, please enter $1 here to proceed."
// "Your federal income tax seems low. Similar customers entered a federal income tax between ${{min}} and ${{max}}. Please check your entries and try again."
export const errorWorkflowPayStatementFederalIncomeTaxOutOfExpectedRangeLow = "Your federal income tax seems low. Similar customers entered a federal income tax between ${{min}} and ${{max}}. Please check your entries and try again."
// "The federal income tax seems high. Similar customers entered a federal income tax between ${{min}} and ${{max}}. Please check your entry and resubmit."
export const errorWorkflowPayStatementFederalIncomeTaxOutofExpectedRangeHigh = "The federal income tax seems high. Similar customers entered a federal income tax between ${{min}} and ${{max}}. Please check your entry and resubmit."
// "The federal income tax you entered is high. Please check your entry and resubmit."
export const errorWorkflowPayStatementFederalIncomeTaxTooHigh = "The federal income tax you entered is high. Please check your entry and resubmit."
// "Gross Income/Pay is required."
export const errorWorkflowPayStatementGrossIncomeIsRequired = "Gross Income/Pay is required."
// "The gross income you entered is high. Please check your entry and resubmit."
export const errorWorkflowPayStatementGrossIncomeTooHigh = "The gross income you entered is high. Please check your entry and resubmit."
// "It looks like your Year-To-Date Gross Income amount is smaller than last month’s entry of ${{min}}."
export const errorWorkflowPayStatementGrossIncomeYTDValuesBelowPrevious = "It looks like your Year-To-Date Gross Income amount is smaller than last month’s entry of ${{min}}."
// "Medicare Tax seems to be out of the expected range. Please check your entries and try again. If you’re sure they’re correct, please send a chat message to support."
export const errorWorkflowPayStatementMedicareTaxIsOutOfExpectedRange = "Medicare Tax seems to be out of the expected range. Please check your entries and try again. If you’re sure they’re correct, please send a chat message to support."
// "It looks like your Year-To-Date Net Income amount is smaller than last month’s entry of ${{min}}."
export const errorWorkflowPayStatementNetIncomeYTDValuesBelowPrevious = "It looks like your Year-To-Date Net Income amount is smaller than last month’s entry of ${{min}}."
// "Social Security Tax seems to be out of the expected range. Please check your entries and try again. If you’re sure they’re correct, please send a chat message to support."
export const errorWorkflowPayStatementSocialSecurityTaxIsOutOfExpectedRange = "Social Security Tax seems to be out of the expected range. Please check your entries and try again. If you’re sure they’re correct, please send a chat message to support."
// "It looks like your Year-To-Date State Tax amount is smaller than last month’s entry of ${{min}}."
export const errorWorkflowPayStatementStateTaxYTDValuesBelowPrevious = "It looks like your Year-To-Date State Tax amount is smaller than last month’s entry of ${{min}}."
// "It looks like some of your Year-To-Date dollar amounts are smaller than the dollar amounts you’ve entered for just this pay statement."
export const errorWorkflowPayStatementValuesSwapped = "It looks like some of your Year-To-Date dollar amounts are smaller than the dollar amounts you’ve entered for just this pay statement."
// "Your phone number is not confirmed, please check your text messages and verify the code."
export const errorWorkflowPhoneIsNotConfirmed = "Your phone number is not confirmed, please check your text messages and verify the code."
// "We couldn’t verify your identity at this time. Please try again."
export const footprintSDKErrorBody = "We couldn’t verify your identity at this time. Please try again."
// "Something Went Wrong"
export const footprintSDKErrorTitle = "Something Went Wrong"
// "Daily"
export const frequencyDaily = "Daily"
// "Every payday"
export const frequencyEveryPayday = "Every payday"
// "On the 1st and 15th"
export const frequencyFirstAndFifteenth = "On the 1st and 15th"
// "Monthly"
export const frequencyMonthly = "Monthly"
// "One time"
export const frequencyOnce = "One time"
// "Weekly"
export const frequencyWeekly = "Weekly"
// "{{price}}/{{totalPrice}}"
export const generalPriceComparisson = "{{price}}/{{totalPrice}}"
// "Check back in a few minutes."
export const genericCheckBack = "Check back in a few minutes."
// "Error retrieving information."
export const genericErrorRetrieving = "Error retrieving information."
// "Go Back"
export const genericGoBack = "Go Back"
// "We canceled your subscription so you won’t get charged"
export const getCashboostDeniedBottomInfoTitle = "We canceled your subscription so you won’t get charged"
// "Advance"
export const getCashboostDeniedLeadingInfoTitle = "Advance"
// "Disqualified"
export const getCashboostDeniedTitle = "Disqualified"
// "Canceled"
export const getCashboostDeniedTrailingInfoContent = "Canceled"
// "Status"
export const getCashboostDeniedTrailingInfoTitle = "Status"
// "Unfortunately you don’t yet qualify based on your account details."
// "Please try again next month."
export const getCashboostDeniedntent = "Unfortunately you don’t yet qualify based on your account details.\u000APlease try again next month."
// "We’re working on expanding Grid Advance to everyone. Check back soon!"
export const getCashboostOffboardingTileDoesNotQualifyContent = "We’re working on expanding Grid Advance to everyone. Check back soon!"
// "Coming Soon"
export const getCashboostOffboardingTileDoesNotQualifySubtitle = "Coming Soon"
// "Grid Advance is coming soon"
export const getCashboostOffboardingTileDoesNotQualifyTitle = "Grid Advance is coming soon"
// "We have paused your Grid+ subscription and will let you know when you can get your next Grid Advance."
export const getCashboostOffboardingTileNoLongerQualifiesContent = "We have paused your Grid+ subscription and will let you know when you can get your next Grid Advance."
// "Not Available"
export const getCashboostOffboardingTileNoLongerQualifiesSubtitle = "Not Available"
// "You no longer qualify for Grid Advance."
export const getCashboostOffboardingTileNoLongerQualifiesTitle = "You no longer qualify for Grid Advance."
// "We’re working on expanding Grid Advance to everyone. Check back soon!"
export const getCashboostOffboardingTileWaitlistContent = "We’re working on expanding Grid Advance to everyone. Check back soon!"
// "Coming Soon"
export const getCashboostOffboardingTileWaitlistSubtitle = "Coming Soon"
// "Grid Advance is"
// "coming soon."
export const getCashboostOffboardingTileWaitlistTitle = "Grid Advance is\u000Acoming soon."
// "Get another Grid Advance"
export const getCashboostPaymentSettlingTileButton = "Get another Grid Advance"
// "Your payment was received and is being processed. You can request another advance in 1-3 business days."
export const getCashboostPaymentSettlingTileContent = "Your payment was received and is being processed. You can request another advance in 1-3 business days."
// "Hang Tight"
export const getCashboostPaymentSettlingTileTitle = "Hang Tight"
// "We’re here to help you with your repayment. Please reach out to us."
export const getCashboostStatusTileExceptionContent = "We’re here to help you with your repayment. Please reach out to us."
// "Advance Amount"
export const getCashboostStatusTileExceptionLeadingInfoTitle = "Advance Amount"
// "Don’t worry!"
export const getCashboostStatusTileExceptionTitle = "Don’t worry!"
// "Delayed"
export const getCashboostStatusTileExceptionTrailingInfoContent = "Delayed"
// "Repayment Status"
export const getCashboostStatusTileExceptionTrailingInfoTitle = "Repayment Status"
// "Your last advance was repaid in full. Let us know when you want a new advance."
export const getCashboostStatusTileFinanceCompleteContent = "Your last advance was repaid in full. Let us know when you want a new advance."
// "Advance Amount"
export const getCashboostStatusTileFinanceCompleteLeadingInfoTitle = "Advance Amount"
// "Nice job!"
export const getCashboostStatusTileFinanceCompleteTitle = "Nice job!"
// "Repaid"
export const getCashboostStatusTileFinanceCompleteTrailingInfoContent = "Repaid"
// "Repayment Status"
export const getCashboostStatusTileFinanceCompleteTrailingInfoTitle = "Repayment Status"
// "Your advance is on its way."
export const getCashboostStatusTileFundingContent = "Your advance is on its way."
// "Advance Amount"
export const getCashboostStatusTileFundingLeadingInfoTitle = "Advance Amount"
// "We’re processing your advance."
export const getCashboostStatusTileFundingRequestedContent = "We’re processing your advance."
// "Advance Amount"
export const getCashboostStatusTileFundingRequestedLeadingInfoTitle = "Advance Amount"
// "Hang tight!"
export const getCashboostStatusTileFundingRequestedTitle = "Hang tight!"
// "Pending"
export const getCashboostStatusTileFundingRequestedTrailingInfoContent = "Pending"
// "Status"
export const getCashboostStatusTileFundingRequestedTrailingInfoTitle = "Status"
// "Hang tight!"
export const getCashboostStatusTileFundingTitle = "Hang tight!"
// "Arrival"
export const getCashboostStatusTileFundingTrailingInfoTitle = "Arrival"
// "Enjoy your advance. We will process your repayment on the date below."
export const getCashboostStatusTileRepaymentContent = "Enjoy your advance. We will process your repayment on the date below."
// "Advance Amount"
export const getCashboostStatusTileRepaymentLeadingInfoTitle = "Advance Amount"
// "Enjoy!"
export const getCashboostStatusTileRepaymentTitle = "Enjoy!"
// "Repayment"
export const getCashboostStatusTileRepaymentTrailingInfoTitle = "Repayment"
// "Get an advance"
export const getCashboostTileCTA = "Get an advance"
// "Get an unlimited number of advances and other amazing features with Grid+"
export const getCashboostTileContent = "Get an unlimited number of advances and other amazing features with Grid+"
// "Get an advance"
export const getCashboostTileTitle = "Get an advance"
// "Get another Grid Advance"
export const getCashboostTileTitleSubsequentAdvanceTileCTA = "Get another Grid Advance"
// "With Grid+, you can get an advance whenever you need it."
export const getCashboostTileTitleSubsequentAdvanceTileContent = "With Grid+, you can get an advance whenever you need it."
// "Ready for your next advance?"
export const getCashboostTileTitleSubsequentAdvanceTileTitle = "Ready for your next advance?"
// "We’re working on calculating your Grid Advance. We’ll have our results for you soon!"
export const getCashboostWaitingForCashboostTileContent = "We’re working on calculating your Grid Advance. We’ll have our results for you soon!"
// "Just a little longer…"
export const getCashboostWaitingForCashboostTileTitle = "Just a little longer…"
// "Get PayBoost"
export const getPayboostContinueSetupTileCTA = "Get PayBoost"
// "Enter your latest info, so we make the perfect PayBoost for you."
export const getPayboostContinueSetupTileContent = "Enter your latest info, so we make the perfect PayBoost for you."
// "Get PayBoost"
// "today!"
export const getPayboostContinueSetupTileTitle = "Get PayBoost\u000Atoday!"
// "PayBoost received this year"
export const getPayboostCurrentBoostTileTitle = "PayBoost received this year"
// "Days"
export const getPayboostCurrentPayboostTileNextCheckinDaysAmountSuffixPlural = "Days"
// "Day"
export const getPayboostCurrentPayboostTileNextCheckinDaysAmountSuffixSingular = "Day"
// "Soon…"
export const getPayboostCurrentPayboostTileNextCheckinDaysAmountUnknown = "Soon…"
// "Your Next Check-in"
export const getPayboostCurrentPayboostTileNextCheckinTitle = "Your Next Check-in"
// "of"
export const getPayboostCurrentPayboostTileTotalAmountPrefix = "of"
// "Continue"
export const getPayboostExtraInfoTileCTA = "Continue"
// "We ran the numbers, and we hit a snag. Please enter some more info, so we can prepare a PayBoost for you."
export const getPayboostExtraInfoTileContent = "We ran the numbers, and we hit a snag. Please enter some more info, so we can prepare a PayBoost for you."
// "One more thing!"
export const getPayboostExtraInfoTileTitle = "One more thing!"
// "See Your PayBoost"
export const getPayboostMonthlyCheckinContinueSetupTileCTA = "See Your PayBoost"
// "We ran all your numbers and are ready to go with your PayBoost."
export const getPayboostMonthlyCheckinContinueSetupTileContent = "We ran all your numbers and are ready to go with your PayBoost."
// "Your PayBoost is ready!"
export const getPayboostMonthlyCheckinContinueSetupTileTitle = "Your PayBoost is ready!"
// "Please contact us if this error appears after trying again."
export const getPayboostNoCheckoutError = "Please contact us if this error appears after trying again."
// "Learn More"
export const getPayboostOffboardingTileDoesNotQualifyCTA = "Learn More"
// "We’re sorry we can’t serve you yet. Check back soon to see if you qualify."
export const getPayboostOffboardingTileDoesNotQualifyContent = "We’re sorry we can’t serve you yet. Check back soon to see if you qualify."
// "Not Available"
export const getPayboostOffboardingTileDoesNotQualifySubtitle = "Not Available"
// "You don’t currently qualify for PayBoost."
export const getPayboostOffboardingTileDoesNotQualifyTitle = "You don’t currently qualify for PayBoost."
// "Get Started"
export const getPayboostOnboardingIntroCTA = "Get Started"
// "Get up to $250 more per paycheck"
export const getPayboostOnboardingIntroSubtitle = "Get up to $250 more per paycheck"
// "Increase your pay"
export const getPayboostOnboardingIntroTitle = "Increase your pay"
// "Access your tax refund early"
export const getPayboostOnboardingIntroValuePropOne = "Access your tax refund early"
// "Guess what? PayBoost is FREE!"
export const getPayboostOnboardingIntroValuePropThree = "Guess what? PayBoost is FREE!"
// "Get it in as little as 2-3 weeks"
export const getPayboostOnboardingIntroValuePropTwo = "Get it in as little as 2-3 weeks"
// "Get started today!"
export const getPayboostOnboardingMeetSubtitle = "Get started today!"
// "Meet PayBoost"
export const getPayboostOnboardingMeetTitle = "Meet PayBoost"
// "Get PayBoost"
export const getPayboostOnboardingTileCTA = "Get PayBoost"
// "Get Started for Free"
export const getPayboostOnboardingTileCTA2 = "Get Started for Free"
// "Enter your latest info to see how much more you can get."
export const getPayboostOnboardingTileContent = "Enter your latest info to see how much more you can get."
// "Enter your latest info so we can make the perfect PayBoost for you"
export const getPayboostOnboardingTileContent2 = "Enter your latest info so we can make the perfect PayBoost for you"
// "You’re eligible to apply for a boost!"
export const getPayboostOnboardingTileTitle = "You’re eligible to apply for a boost!"
// "Apply for a BOOST"
export const getPayboostOnboardingTileTitle2 = "Apply for a BOOST"
// "See Your PayBoost"
export const getPayboostReadyTileCTA = "See Your PayBoost"
// "We ran all your numbers and are ready to go with your PayBoost."
export const getPayboostReadyTileText = "We ran all your numbers and are ready to go with your PayBoost."
// "Your PayBoost is ready!"
export const getPayboostReadyTileTitle = "Your PayBoost is ready!"
// "See Your PayBoost"
export const getPayboostWaitingForBoostTileCTA = "See Your PayBoost"
// "5 minutes"
export const getPayboostWaitingForBoostTileContent = "5 minutes"
// "PayBoost projection ready in about:"
export const getPayboostWaitingForBoostTileTitle = "PayBoost projection ready in about:"
// "/month"
export const getPayboostWaitingForPayboostCadenceLabel = "/month"
// "We’re working on calculating your PayBoost. We’ll have our results for you soon!"
export const getPayboostWaitingForPayboostTileContent = "We’re working on calculating your PayBoost. We’ll have our results for you soon!"
// "Just a little longer…"
export const getPayboostWaitingForPayboostTileTitle = "Just a little longer…"
// "Why wait for tax time to get your refund?  Access your refund all year with PayBoost! Let’s get started."
export const getStartedPage1Body = "Why wait for tax time to get your refund?  Access your refund all year with PayBoost! Let’s get started."
// "She makes $4,000 a month but was paying $400 a month in taxes."
export const getStartedPage1Body2 = "She makes $4,000 a month but was paying $400 a month in taxes."
// "Your tax refund can be your personal piggy bank."
export const getStartedPage1Title = "Your tax refund can be your personal piggy bank."
// "Carol is a mother of two and works full-time. She knew she could get more from her paycheck."
export const getStartedPage1Title2 = "Carol is a mother of two and works full-time. She knew she could get more from her paycheck."
// "Start by answering a few questions about your tax situation."
export const getStartedPage2Body = "Start by answering a few questions about your tax situation."
// "In joining Grid, she safely lowered her monthly tax payments. She now keeps more of her paycheck in her pocket."
export const getStartedPage2Body2 = "In joining Grid, she safely lowered her monthly tax payments. She now keeps more of her paycheck in her pocket."
// "Answer some questions"
export const getStartedPage2Title = "Answer some questions"
// "After 2 weeks with Grid, Carol’s next paycheck was boosted $75."
export const getStartedPage2Title2 = "After 2 weeks with Grid, Carol’s next paycheck was boosted $75."
// "See how much PayBoost you can get and decide how much you want."
export const getStartedPage3Body = "See how much PayBoost you can get and decide how much you want."
// "She uses the extra cash to pay bills, to save, and for the occasional treat."
export const getStartedPage3Body2 = "She uses the extra cash to pay bills, to save, and for the occasional treat."
// "Decide how much you want"
export const getStartedPage3Title = "Decide how much you want"
// "PayBoost gives Carol part of her yearly tax refund on every paycheck throughout the year."
export const getStartedPage3Title2 = "PayBoost gives Carol part of her yearly tax refund on every paycheck throughout the year."
// "We take it from there! Our team of tax pros work with your payroll, boosting each paycheck."
export const getStartedPage4Body = "We take it from there! Our team of tax pros work with your payroll, boosting each paycheck."
// "Grid also gives her year-round support, so Carol can have more year-round peace of mind."
export const getStartedPage4Body2 = "Grid also gives her year-round support, so Carol can have more year-round peace of mind."
// "Sit back and relax"
export const getStartedPage4Title = "Sit back and relax"
// "Carol checks in with us once a month and every few months her PayBoost gets a little bigger."
export const getStartedPage4Title2 = "Carol checks in with us once a month and every few months her PayBoost gets a little bigger."
// "Build credit with every swipe"
export const gridCardCheckoutScreenBenefitsBulletA = "Build credit with every swipe"
// "No credit check"
export const gridCardCheckoutScreenBenefitsBulletB = "No credit check"
// "No APR"
export const gridCardCheckoutScreenBenefitsBulletC = "No APR"
// "BENEFITS OF GRID CARD"
export const gridCardCheckoutScreenBenefitsTitle = "BENEFITS OF GRID CARD"
// "Credit Builder Card"
export const gridCardCheckoutScreenSubscriptionUpgradeContentTitle = "Credit Builder Card"
// "YOUR CARD"
export const gridCardCheckoutScreenSubscriptionUpgradeTitle = "YOUR CARD"
// "No APR"
export const gridCardDashboardBulletA = "No APR"
// "No credit check"
export const gridCardDashboardBulletB = "No credit check"
// "Build credit with every swipe"
export const gridCardDashboardBulletC = "Build credit with every swipe"
// "Get started!"
export const gridCardDashboardCTATitle = "Get started!"
// "How does this work?"
export const gridCardDashboardHowCTATitle = "How does this work?"
// "The safe way to"
// "build credit"
export const gridCardDashboardSybtitle = "The safe way to\u000Abuild credit"
// "A Card that builds credit"
export const gridCardDashboardTitle = "A Card that builds credit"
// "Deposit"
// "money"
export const gridCardDepositMoneyTileTitle = "Deposit\u000Amoney"
// "The amount you transfer will be the credit limit on your Grid Card"
export const gridCardFundAccountSubtitle = "The amount you transfer will be the credit limit on your Grid Card"
// "Let’s get started"
export const gridCardFundAccountTitle = "Let’s get started"
// "Continue"
export const gridCardFundedAccountSuccededCTATitle = "Continue"
// "Grid Card"
export const gridCardFundedAccountSuccededScreenTitle = "Grid Card"
// "You should expect your  card within 5-10 business days"
export const gridCardFundedAccountSuccededSubtitle = "You should expect your  card within 5-10 business days"
// "Congratulations, your account will be funded, your Grid Card is on it’s way"
export const gridCardFundedAccountSuccededTitle = "Congratulations, your account will be funded, your Grid Card is on it’s way"
// "Fund your account. Whatever you add is the credit limit for your card."
export const gridCardHowItWorksSubitleA = "Fund your account. Whatever you add is the credit limit for your card."
// "Spend, spend, spend. Use your Grid Card for everyday purchases."
export const gridCardHowItWorksSubitleB = "Spend, spend, spend. Use your Grid Card for everyday purchases."
// "The money you added automatically pays off your balance."
export const gridCardHowItWorksSubitleC = "The money you added automatically pays off your balance."
// "Grid reports your on-time payments to the Credit Bureaus."
export const gridCardHowItWorksSubitleD = "Grid reports your on-time payments to the Credit Bureaus."
// "Add"
// "money"
export const gridCardHowItWorksTitleA = "Add\u000Amoney"
// "Use your"
// "card"
export const gridCardHowItWorksTitleB = "Use your\u000Acard"
// "Pay off"
// "balance"
export const gridCardHowItWorksTitleC = "Pay off\u000Abalance"
// "Build"
// "Credit"
export const gridCardHowItWorksTitleD = "Build\u000ACredit"
// "Build credit faster with direct deposit"
export const gridCardKBADetailsBulletA = "Build credit faster with direct deposit"
// "Get paid 2 days early"
export const gridCardKBADetailsBulletB = "Get paid 2 days early"
// "Unlock a credit line"
export const gridCardKBADetailsBulletC = "Unlock a credit line"
// "2x rewards"
export const gridCardKBADetailsBulletD = "2x rewards"
// "See What’s Possible"
export const gridCardKBADetailsCTATitle = "See What’s Possible"
// "Not bad! Let’s boost your"
// "score in 4 simple steps"
export const gridCardKBADetailsTitle = "Not bad! Let’s boost your\u000Ascore in 4 simple steps"
// "boost"
export const gridCardKBADetailsTitleLight = "boost"
// "You don’t have enough credit"
// "history yet! Here’s how to boost"
export const gridCardKBAOnboardingNotEnoughCredit = "You don’t have enough credit\u000Ahistory yet! Here’s how to boost"
// "We’re working on pulling your"
// "score while you set up your Card!"
export const gridCardKBAOnboardingWaitingOnCreditScore = "We’re working on pulling your\u000Ascore while you set up your Card!"
// "Build credit with every swipe."
export const gridCardLockedScreenBullet1 = "Build credit with every swipe."
// "Earn up to 2% cash back on all purchases"
export const gridCardLockedScreenBullet2 = "Earn up to 2% cash back on all purchases"
// "No hidden fees, late fees, setup fees, maintenance fees, rewards fees"
export const gridCardLockedScreenBullet3 = "No hidden fees, late fees, setup fees, maintenance fees, rewards fees"
// "Unlock"
export const gridCardLockedScreenCTATitle = "Unlock"
// "Benefits of Grid Card"
export const gridCardLockedScreenTitle = "Benefits of Grid Card"
// "No credit check"
export const gridCardOnboardingScreenBBulletA = "No credit check"
// "Build credit with every swipe"
export const gridCardOnboardingScreenBBulletB = "Build credit with every swipe"
// "No minimums, cancel anytime"
export const gridCardOnboardingScreenBBulletC = "No minimums, cancel anytime"
// "A credit builder that protects you"
export const gridCardOnboardingScreenBTitle = "A credit builder that protects you"
// "protects"
export const gridCardOnboardingScreenBTitleLight = "protects"
// "Credit builder card"
export const gridCardOnboardingScreenCBulletA = "Credit builder card"
// "No credit check"
export const gridCardOnboardingScreenCBulletB = "No credit check"
// "No APR"
export const gridCardOnboardingScreenCBulletC = "No APR"
// "Get the credit score you deserve"
export const gridCardOnboardingScreenCTitle = "Get the credit score you deserve"
// "deserve"
export const gridCardOnboardingScreenCTitleLight = "deserve"
// "Credit builder card"
export const gridCardOnboardingScreenDBulletA = "Credit builder card"
// "No credit checks"
export const gridCardOnboardingScreenDBulletB = "No credit checks"
// "Approval in 3 minutes"
export const gridCardOnboardingScreenDBulletC = "Approval in 3 minutes"
// "Do more with your card."
// "Build credit."
export const gridCardOnboardingScreenDTitle = "Do more with your card.\u000ABuild credit."
// "Build credit."
export const gridCardOnboardingScreenDTitleLight = "Build credit."
// "Get a credit builder card, NO credit check, and NO APR!"
export const gridCardOnboardingTileBanner = "Get a credit builder card, NO credit check, and NO APR!"
// "Get Started"
export const gridCardOnboardingTileButton = "Get Started"
// "The credit score you deserve"
export const gridCardOnboardingTileTitle = "The credit score you deserve"
// "Contact Us"
export const gridCardStatusIsDefaultedCTA = "Contact Us"
// "Please reach out to us to discuss how we can support you."
export const gridCardStatusIsDefaultedContent = "Please reach out to us to discuss how we can support you."
// "Your account is defaulted"
export const gridCardStatusIsDefaultedTitle = "Your account is defaulted"
// "Add Funds"
export const gridCardStatusIsDelinquentCTA = "Add Funds"
// "Please make a payment as soon as possible to avoid further negative impact to your credit score."
export const gridCardStatusIsDelinquentContent = "Please make a payment as soon as possible to avoid further negative impact to your credit score."
// "Your account is delinquent"
export const gridCardStatusIsDelinquentTitle = "Your account is delinquent"
// "Add Funds"
export const gridCardStatusNegativeBalanceCTA = "Add Funds"
// "Please make a payment by {{date}} to avoid negative impact to your credit score."
export const gridCardStatusNegativeBalanceContent = "Please make a payment by {{date}} to avoid negative impact to your credit score."
// "You have a negative balance"
export const gridCardStatusNegativeBalanceTitle = "You have a negative balance"
// "Ensure that you have no unpaid charges from Grid"
export const gridCardToUnlockScreenBullet1 = "Ensure that you have no unpaid charges from Grid"
// "Make 3 monthly payments on-time to your Credit Builder"
export const gridCardToUnlockScreenBullet2 = "Make 3 monthly payments on-time to your Credit Builder"
// "Month"
export const gridCardToUnlockScreenMonth = "Month"
// "To unlock Grid Card:"
export const gridCardToUnlockScreenSubtitle = "To unlock Grid Card:"
// "Unleash your credit, unlock your spending, with Grid Card"
export const gridCardToUnlockScreenTitle = "Unleash your credit, unlock your spending, with Grid Card"
// "ATM withdrawal"
export const gridCardTransactionTypeAtmWithdrawal = "ATM withdrawal"
// "Card purchase"
export const gridCardTransactionTypeCardPurchase = "Card purchase"
// "Credit from {{vendor}}"
export const gridCardTransactionTypeCreditFromVendor = "Credit from {{vendor}}"
// "Transfer from Credit Line"
export const gridCardTransactionTypeCreditTransferFromCreditLine = "Transfer from Credit Line"
// "Generic Credit"
export const gridCardTransactionTypeGenericCredit = "Generic Credit"
// "Generic Debit"
export const gridCardTransactionTypeGenericDebit = "Generic Debit"
// "Grid Plus Subscription"
export const gridCardTransactionTypeGridPlusSubscription = "Grid Plus Subscription"
// "-"
export const gridCardTransactionTypeInvalid = "-"
// "Provisional Credit"
export const gridCardTransactionTypeProvisionalCreditForDisputedTransaction = "Provisional Credit"
// "Rewards Redemption"
export const gridCardTransactionTypeRewardsRedemption = "Rewards Redemption"
// "Transfer To Credit Line"
export const gridCardTransactionTypeTransferToCreditLine = "Transfer To Credit Line"
// "-"
export const gridCardTransactionTypeUnclassified = "-"
// "Withdrawal to {{vendor}}"
export const gridCardTransactionTypeWithdrawalToVendor = "Withdrawal to {{vendor}}"
// "When you use your Grid Card, the transactions will be available here."
export const gridCardTransactionsListEmpty = "When you use your Grid Card, the transactions will be available here."
// "This is the end of your Grid Card transactions"
export const gridCardTransactionsListEndOfTransactions = "This is the end of your Grid Card transactions"
// "Withdraw"
// "money"
export const gridCardWithdrawMoneyTileTitle = "Withdraw\u000Amoney"
// "Legal Agreements"
export const gridLegalAgreements = "Legal Agreements"
// "Grid+ Subscription Required."
// ""
// "If you’d like to reactivate your subscription, please send us a chat message."
export const gridPlusSubscriptionMissingCTA = "Grid+ Subscription Required.\u000A\u000AIf you’d like to reactivate your subscription, please send us a chat message."
// "Make a payment"
export const gridPlusSubscriptionOverdueCTA = "Make a payment"
// "We were unable to process your subscription payment. Please make a payment in order to access premium features."
export const gridPlusSubscriptionOverdueContent = "We were unable to process your subscription payment. Please make a payment in order to access premium features."
// "Your subscription payment is overdue"
export const gridPlusSubscriptionOverdueTitle = "Your subscription payment is overdue"
// "Done"
export const helpContentButtonClose = "Done"
// "Active"
export const incomeProtectionActiveTileActiveState = "Active"
// "Coverage Amount:"
export const incomeProtectionActiveTileCoverageAmntPlain = "Coverage Amount:"
// "{{amount}}"
export const incomeProtectionActiveTileCoverageAmntValue = "{{amount}}"
// "Coverage Activates On:"
export const incomeProtectionActiveTileEligibleDate = "Coverage Activates On:"
// "MM/DD/YYYY"
export const incomeProtectionActiveTileEligibleDateDefaultValue = "MM/DD/YYYY"
// "{{date}}"
export const incomeProtectionActiveTileEligibleDateValue = "{{date}}"
// "DUE"
export const incomeProtectionActiveTileOverdueState = "DUE"
// "Next Payment Due:"
export const incomeProtectionActiveTilePaymentDue = "Next Payment Due:"
// "PAID"
export const incomeProtectionActiveTilePaymentDuePaidValue = "PAID"
// "{{paid}}"
export const incomeProtectionActiveTilePaymentDueValue = "{{paid}}"
// "Income Protection"
export const incomeProtectionActiveTileTitle = "Income Protection"
// "Link New Account"
export const incomeProtectionAddBankAccountCTATitle = "Link New Account"
// "Save Card"
export const incomeProtectionAddDebitCardCTAButton = "Save Card"
// "Enter Payment Method"
export const incomeProtectionAddPaymentMethodTitle = "Enter Payment Method"
// "Almost there!"
export const incomeProtectionApplyingTileAlmost = "Almost there!"
// "Apply for Free"
export const incomeProtectionApplyingTileApplyCTATitle = "Apply for Free"
// "Continue with your Income Protection Application"
export const incomeProtectionApplyingTileMessage = "Continue with your Income Protection Application"
// "Income Protection"
export const incomeProtectionApplyingTileTitle = "Income Protection"
// "Benefit Payout"
export const incomeProtectionBenefitPayoutEventTitle = "Benefit Payout"
// "Receive $2,000/month for 2 months"
export const incomeProtectionBulletItem1 = "Receive $2,000/month for 2 months"
// "Extra coverage on top of state unemployment benefits"
export const incomeProtectionBulletItem2 = "Extra coverage on top of state unemployment benefits"
// "No hidden fees, just $15 per month"
export const incomeProtectionBulletItem3 = "No hidden fees, just $15 per month"
// "Add Card"
export const incomeProtectionCheckoutAddCard = "Add Card"
// "Add payment method"
export const incomeProtectionCheckoutAddPayment = "Add payment method"
// "Grid’s Income Protection Agreement."
export const incomeProtectionCheckoutAgreementLabel = "Grid’s Income Protection Agreement."
// "Income Protection Agreement."
export const incomeProtectionCheckoutAgreementLinkedText = "Income Protection Agreement."
// "https://getgrid.app/terms/income-protection"
export const incomeProtectionCheckoutAgreementURL = "https://getgrid.app/terms/income-protection"
// "1 month free"
export const incomeProtectionCheckoutBubbleLine1 = "1 month free"
// "$15/month"
export const incomeProtectionCheckoutBubbleLine2 = "$15/month"
// "$4,000 INCOME PROTECTION PLAN"
export const incomeProtectionCheckoutBubbleTitle = "$4,000 INCOME PROTECTION PLAN"
// "Submit"
export const incomeProtectionCheckoutCTA = "Submit"
// "Your card info failed, please check your card details and try again"
export const incomeProtectionCheckoutCardError = "Your card info failed, please check your card details and try again"
// "You will not be charged at checkout. $15 pre-payment for your second month will be processed next week."
export const incomeProtectionCheckoutCollectionsExplainer = "You will not be charged at checkout. $15 pre-payment for your second month will be processed next week."
// "{{amount}} / {{time}}"
export const incomeProtectionCheckoutCost = "{{amount}} / {{time}}"
// "{{amount}} due"
export const incomeProtectionCheckoutCostDue = "{{amount}} due"
// "${{amount}} due"
export const incomeProtectionCheckoutCostDuePlus = "${{amount}} due"
// "{{amount}} / month"
export const incomeProtectionCheckoutCostOnly = "{{amount}} / month"
// "{{amount}} coverage"
export const incomeProtectionCheckoutCoverage = "{{amount}} coverage"
// "coverage"
export const incomeProtectionCheckoutCoveragePlain = "coverage"
// "Existing Member"
export const incomeProtectionCheckoutExistingMember = "Existing Member"
// "1 month free, then $15/month"
export const incomeProtectionCheckoutFeeExplaner = "1 month free, then $15/month"
// "Income Protection is backed by "
// "A.M. Best A-rated insurance."
export const incomeProtectionCheckoutHeaderBannerTitle = "Income Protection is backed by \u000AA.M. Best A-rated insurance."
// "For the same price, it would take you 22 years to save $4,000!"
export const incomeProtectionCheckoutHeaderTitle = "For the same price, it would take you 22 years to save $4,000!"
// "New Member"
export const incomeProtectionCheckoutNewMember = "New Member"
// "Your card info failed, please check your card details and try again"
export const incomeProtectionCheckoutPaymentFailed = "Your card info failed, please check your card details and try again"
// "Have questions? Grid Income Protection FAQ."
export const incomeProtectionCheckoutQuestionsLabel = "Have questions? Grid Income Protection FAQ."
// "Income Protection FAQ."
export const incomeProtectionCheckoutQuestionsLinkedText = "Income Protection FAQ."
// "https://getgrid.app/help"
export const incomeProtectionCheckoutQuestionsURL = "https://getgrid.app/help"
// "Your Policy"
export const incomeProtectionCheckoutRowsTitle = "Your Policy"
// "Submit"
export const incomeProtectionCheckoutScreenCTATitle = "Submit"
// "INCOME PROTECTION"
export const incomeProtectionCheckoutScreenFirstRow = "INCOME PROTECTION"
// "New Member"
export const incomeProtectionCheckoutScreenNewMember = "New Member"
// "SUBSCRIPTION"
export const incomeProtectionCheckoutScreenSecondRow = "SUBSCRIPTION"
// "Your Protection"
export const incomeProtectionCheckoutScreenTitle = "Your Protection"
// "At $15/month, it would take you 22 years to save $4,000!"
export const incomeProtectionCheckoutSubTitle = "At $15/month, it would take you 22 years to save $4,000!"
// "This could take up to a minute."
export const incomeProtectionCheckoutSubmitSubtitle = "This could take up to a minute."
// "Processing your information…"
export const incomeProtectionCheckoutSubmitTitle = "Processing your information…"
// "Income Protection"
export const incomeProtectionCheckoutTitle = "Income Protection"
// "Your Order"
export const incomeProtectionCheckoutTitleOrder = "Your Order"
// "Your Protection"
export const incomeProtectionCheckoutTitleSubscription = "Your Protection"
// "Pay stubs from the last 3 months you were employed"
export const incomeProtectionClaimApplicationDocDescriptonPaystub = "Pay stubs from the last 3 months you were employed"
// "Termination letter (recommended)"
export const incomeProtectionClaimApplicationDocDescriptonTerminationLetter = "Termination letter (recommended)"
// "Proof you are receiving state unemployment benefits"
export const incomeProtectionClaimApplicationDocDescriptonUnemployment = "Proof you are receiving state unemployment benefits"
// "Your claim is being processed. We will reach out when there is an update."
export const incomeProtectionClaimInProgressMessage = "Your claim is being processed. We will reach out when there is an update."
// "Sorry, you are ineligible for a benefit claim."
export const incomeProtectionClaimRejectedPopupTitle = "Sorry, you are ineligible for a benefit claim."
// "You don’t have any Income Protection claims."
export const incomeProtectionClaimStatusScreenNoClaims = "You don’t have any Income Protection claims."
// "Claim Status"
export const incomeProtectionClaimStatusScreenTitle = "Claim Status"
// "Fast Delivery"
// "Coming Soon"
export const incomeProtectionClaimStepFastDelivery = "Fast Delivery\u000AComing Soon"
// "Standard Delivery"
// "FREE"
export const incomeProtectionClaimStepStandardDelivery = "Standard Delivery\u000AFREE"
// "Once your claim is approved, your funds will arrive within 6 business days."
export const incomeProtectionClaimStepTransferBoxStandardDeliverySubtitle = "Once your claim is approved, your funds will arrive within 6 business days."
// "STANDARD DELIVERY"
export const incomeProtectionClaimStepTransferBoxStandardDeliveryTitle = "STANDARD DELIVERY"
// "Claim Submitted"
export const incomeProtectionClaimSubmittedEventTitle = "Claim Submitted"
// "Take photos"
export const incomeProtectionClaimUploadDocsOption1 = "Take photos"
// "Upload photos"
export const incomeProtectionClaimUploadDocsOption2 = "Upload photos"
// "Upload files"
export const incomeProtectionClaimUploadDocsOption3 = "Upload files"
// "If accepted, {{amount}} will be deposited into the account you specified."
export const incomeProtectionClaimWorkflowClaimNextStepsStepBottomComment = "If accepted, {{amount}} will be deposited into the account you specified."
// "Done"
export const incomeProtectionClaimWorkflowClaimNextStepsStepCTATitle = "Done"
// "Grid will process your responses and update you on whether your claim has been accepted or rejected."
export const incomeProtectionClaimWorkflowClaimNextStepsStepSubtitle = "Grid will process your responses and update you on whether your claim has been accepted or rejected."
// "Next Steps"
export const incomeProtectionClaimWorkflowClaimNextStepsStepTitle = "Next Steps"
// "What’s next?"
export const incomeProtectionClaimWorkflowClaimReceivedStepCTATitle = "What’s next?"
// "Claim submission received"
export const incomeProtectionClaimWorkflowClaimReceivedStepTitle = "Claim submission received"
// "Continue"
export const incomeProtectionClaimWorkflowCompleteClaimStepCTATitle = "Continue"
// "1. Pay stubs from the last 3 months you were employed"
export const incomeProtectionClaimWorkflowCompleteClaimStepContent1 = "1. Pay stubs from the last 3 months you were employed"
// "2. Proof you are receiving state unemployment benefits"
export const incomeProtectionClaimWorkflowCompleteClaimStepContent2 = "2. Proof you are receiving state unemployment benefits"
// "3. Termination letter (recommended)"
export const incomeProtectionClaimWorkflowCompleteClaimStepContent3 = "3. Termination letter (recommended)"
// "Please upload the following documents:"
export const incomeProtectionClaimWorkflowCompleteClaimStepSubtitle = "Please upload the following documents:"
// "Complete your"
// "claim submission"
export const incomeProtectionClaimWorkflowCompleteClaimStepTitle = "Complete your\u000Aclaim submission"
// "After you have received unemployment benefits from your state, come here to file a claim with Grid."
export const incomeProtectionClaimWorkflowHowItWorksStepContent1 = "After you have received unemployment benefits from your state, come here to file a claim with Grid."
// "Grid will verify your information and either approve or deny your claim."
export const incomeProtectionClaimWorkflowHowItWorksStepContent2A = "Grid will verify your information and either approve or deny your claim."
// "If approved, Grid will pay you"
// "$2,000 for your first claim."
export const incomeProtectionClaimWorkflowHowItWorksStepContent2B = "If approved, Grid will pay you\u000A$2,000 for your first claim."
// "Upload all the correct documents so that your claim can be processed quickly."
export const incomeProtectionClaimWorkflowHowItWorksStepContent3 = "Upload all the correct documents so that your claim can be processed quickly."
// "File for benefits"
export const incomeProtectionClaimWorkflowHowItWorksStepTitle1 = "File for benefits"
// "Get approved"
export const incomeProtectionClaimWorkflowHowItWorksStepTitle2 = "Get approved"
// "Submit a claim"
export const incomeProtectionClaimWorkflowHowItWorksStepTitle3 = "Submit a claim"
// "Get Started!"
export const incomeProtectionClaimWorkflowIntroStepCTATitle = "Get Started!"
// "To receive your benefits, submit a claim."
export const incomeProtectionClaimWorkflowIntroStepSubtitle = "To receive your benefits, submit a claim."
// "Lost your job?"
export const incomeProtectionClaimWorkflowIntroStepTitle = "Lost your job?"
// "Sorry, you are ineligible for a benefit claim."
export const incomeProtectionClaimWorkflowNonEligibleToSubmitAClaim = "Sorry, you are ineligible for a benefit claim."
// "Back"
export const incomeProtectionClaimWorkflowStepBackButtonTitle = "Back"
// "Next"
export const incomeProtectionClaimWorkflowStepNextButtonTitle = "Next"
// "Claim Submission"
export const incomeProtectionClaimWorkflowStepTitle = "Claim Submission"
// "Get your funds faster with Grid Card"
export const incomeProtectionClaimWorkflowTransferToStepBotomComment = "Get your funds faster with Grid Card"
// "Transfer to"
export const incomeProtectionClaimWorkflowTransferToStepLeftTitle = "Transfer to"
// "+ Link New Account"
export const incomeProtectionClaimWorkflowTransferToStepLinkNewAccount = "+ Link New Account"
// "Grid Card"
export const incomeProtectionClaimWorkflowTransferToStepRightTitle = "Grid Card"
// "If your claim is accepted, where should your funds be sent?"
export const incomeProtectionClaimWorkflowTransferToStepTitle = "If your claim is accepted, where should your funds be sent?"
// "Renew Coverage"
export const incomeProtectionClaimedStateCTATitle = "Renew Coverage"
// "Policy Ended"
export const incomeProtectionClaimedStatePolicyEnded = "Policy Ended"
// "Update your employment information to renew your coverage"
export const incomeProtectionClaimedStateSubtitle = "Update your employment information to renew your coverage"
// "You’ve reached your maximum benefit"
export const incomeProtectionClaimedStateTitle = "You’ve reached your maximum benefit"
// "{{companyName}} Claim"
export const incomeProtectionClaimsStatusItemTitle = "{{companyName}} Claim"
// "Paystub"
export const incomeProtectionClaimsUploadDocStepDocumentTypePayStub = "Paystub"
// "Termination Letter"
export const incomeProtectionClaimsUploadDocStepDocumentTypeTerminationLetter = "Termination Letter"
// "Unemployment Notice"
export const incomeProtectionClaimsUploadDocStepDocumentTypeUnemploymentInsurance = "Unemployment Notice"
// "PLEASE MAKE SURE THE ENTIRE DOCUMENT IS CLEARLY VISIBLE"
export const incomeProtectionClaimsUploadDocStepFolderWarnign = "PLEASE MAKE SURE THE ENTIRE DOCUMENT IS CLEARLY VISIBLE"
// "Where do I find my paystub?"
export const incomeProtectionClaimsUploadHelpCTATitle = "Where do I find my paystub?"
// "A paystub is a document that shows how much money you earned, how much tax was taken out, and any deductions from your paycheck. Your employer gives it to you along with your paycheck."
export const incomeProtectionClaimsUploadHelpContentContentA = "A paystub is a document that shows how much money you earned, how much tax was taken out, and any deductions from your paycheck. Your employer gives it to you along with your paycheck."
// "Some employers give you a physical paystub with your paycheck. Others may provide electronic paystubs that you can access through a company portal or HR system. If you’re not sure how to access your pay stubs online, ask your employer for instructions."
export const incomeProtectionClaimsUploadHelpContentContentB = "Some employers give you a physical paystub with your paycheck. Others may provide electronic paystubs that you can access through a company portal or HR system. If you’re not sure how to access your pay stubs online, ask your employer for instructions."
// "What is a paystub?"
export const incomeProtectionClaimsUploadHelpContentTitleA = "What is a paystub?"
// "Where can I find it?"
export const incomeProtectionClaimsUploadHelpContentTitleB = "Where can I find it?"
// "Continue"
export const incomeProtectionConnectYourBankAccountCTATitle = "Continue"
// "Please connect the bank account where your state unemployment is deposited."
export const incomeProtectionConnectYourBankAccountDepositDestinationContent = "Please connect the bank account where your state unemployment is deposited."
// "DEPOSIT DESTINATION"
export const incomeProtectionConnectYourBankAccountDepositDestinationTitle = "DEPOSIT DESTINATION"
// "Where do you receive your state unemployment deposits?"
export const incomeProtectionConnectYourBankAccountDepositSubtitle = "Where do you receive your state unemployment deposits?"
// "Please connect the bank account where your paychecks were deposited."
export const incomeProtectionConnectYourBankAccountPaycheckDestinationContent = "Please connect the bank account where your paychecks were deposited."
// "PAYCHECK DESTINATION"
export const incomeProtectionConnectYourBankAccountPaycheckDestinationTitle = "PAYCHECK DESTINATION"
// "Where did you receive your paychecks?"
export const incomeProtectionConnectYourBankAccountPaycheckSubtitle = "Where did you receive your paychecks?"
// "Connect your bank"
export const incomeProtectionConnectYourBankAccountTitle = "Connect your bank"
// "Continue"
export const incomeProtectionConnectYourCardStepCTATitle = "Continue"
// "What are the first 8 digits of your government provided pre-paid debit card?"
export const incomeProtectionConnectYourCardStepContent = "What are the first 8 digits of your government provided pre-paid debit card?"
// "Where do you receive your state unemployment deposits?"
export const incomeProtectionConnectYourCardStepTitle = "Where do you receive your state unemployment deposits?"
// "Sorry, you don’t currently qualify for Income Protection"
export const incomeProtectionDontQualifyErrorMessage = "Sorry, you don’t currently qualify for Income Protection"
// "You don’t qualify for Income Protection."
export const incomeProtectionDontQualifyPermanentReasonTileTitle = "You don’t qualify for Income Protection."
// "Your premium payment has been refunded"
export const incomeProtectionDontQualifyTileRefunded = "Your premium payment has been refunded"
// "You will be eligible to reapply for Grid Income Protection on {{date}}."
export const incomeProtectionDontQualifyTileSubtitle = "You will be eligible to reapply for Grid Income Protection on {{date}}."
// "You don’t currently qualify for Income Protection."
export const incomeProtectionDontQualifyTileTitle = "You don’t currently qualify for Income Protection."
// "Failure to make a payment by {{date}} will result in loss of coverage."
export const incomeProtectionDueWarningLabelContent = "Failure to make a payment by {{date}} will result in loss of coverage."
// "Your account is past due"
export const incomeProtectionDueWarningLabelTitle = "Your account is past due"
// "Coverage Activated"
export const incomeProtectionEligibleForClaimEventTitle = "Coverage Activated"
// "Still unsure? Ask your employer"
export const incomeProtectionEmployeeTypesHelpBottomLine = "Still unsure? Ask your employer"
// "I’m not sure"
export const incomeProtectionEmployeeTypesHelpCTATitle = "I’m not sure"
// "You receive an IRS Form W-2 from your employer and you file a Form W-4 with the IRS"
export const incomeProtectionEmployeeTypesHelpContent1Point1 = "You receive an IRS Form W-2 from your employer and you file a Form W-4 with the IRS"
// "You receive a regular paycheck"
export const incomeProtectionEmployeeTypesHelpContent1Point2 = "You receive a regular paycheck"
// "Examples: office workers, retail workers, restaurant workers"
export const incomeProtectionEmployeeTypesHelpContent1Point3 = "Examples: office workers, retail workers, restaurant workers"
// "You file a Form 1099-MISC with the IRS"
export const incomeProtectionEmployeeTypesHelpContent2Point1 = "You file a Form 1099-MISC with the IRS"
// "You work for a company or organization on a specific project or assignment"
export const incomeProtectionEmployeeTypesHelpContent2Point2 = "You work for a company or organization on a specific project or assignment"
// "Examples: delivery driver, house cleaner, staffing agency, contractors"
export const incomeProtectionEmployeeTypesHelpContent2Point3 = "Examples: delivery driver, house cleaner, staffing agency, contractors"
// "You work for yourself and do not have an employer who pays you a salary or wage"
export const incomeProtectionEmployeeTypesHelpContent3Point1 = "You work for yourself and do not have an employer who pays you a salary or wage"
// "Examples: entrepreneurs and small business owners"
export const incomeProtectionEmployeeTypesHelpContent3Point2 = "Examples: entrepreneurs and small business owners"
// "You are hired to work only during certain times of the year"
export const incomeProtectionEmployeeTypesHelpContent4Point1 = "You are hired to work only during certain times of the year"
// "Examples: holiday retail workers, teachers"
export const incomeProtectionEmployeeTypesHelpContent4Point2 = "Examples: holiday retail workers, teachers"
// "Year-round salaried or hourly worker"
export const incomeProtectionEmployeeTypesHelpTitle1 = "Year-round salaried or hourly worker"
// "Contract or gig worker"
export const incomeProtectionEmployeeTypesHelpTitle2 = "Contract or gig worker"
// "Self-employed"
export const incomeProtectionEmployeeTypesHelpTitle3 = "Self-employed"
// "Seasonal or temp worker"
export const incomeProtectionEmployeeTypesHelpTitle4 = "Seasonal or temp worker"
// "Employment"
// "changed?"
export const incomeProtectionEmploymentChangedRowTitle = "Employment\u000Achanged?"
// "Enrolled in Income Protection"
export const incomeProtectionEnrolledEventTitle = "Enrolled in Income Protection"
// "If accepted, $2,000 will be deposited into the account you specified."
export const incomeProtectionFirstClaimSubmittedFinePrint = "If accepted, $2,000 will be deposited into the account you specified."
// "Start Your Free Month"
export const incomeProtectionFreeCheckoutCTA = "Start Your Free Month"
// "Your benefit payout has failed because there was a problem with the bank account you selected."
// ""
// "Please contact support to rectify the problem."
export const incomeProtectionHistoryBenefitPayoutFailedEvent = "Your benefit payout has failed because there was a problem with the bank account you selected.\u000A\u000APlease contact support to rectify the problem."
// "Your claim for coverage has been denied."
// ""
// "If you have any questions, please contact our support team."
export const incomeProtectionHistoryClaimRejectedEvent = "Your claim for coverage has been denied.\u000A\u000AIf you have any questions, please contact our support team."
// "We received your request to cover a job loss event under your policy. Your claim will be reviewed, and you will be notified of the outcome"
export const incomeProtectionHistoryClaimSubmittedEvent = "We received your request to cover a job loss event under your policy. Your claim will be reviewed, and you will be notified of the outcome"
// "Enrollment Period"
export const incomeProtectionHistoryDetailEnrollmentPeriod = "Enrollment Period"
// "Account"
export const incomeProtectionHistoryDetailItemAccount = "Account"
// "Benefit Amount"
export const incomeProtectionHistoryDetailItemBenefitAmount = "Benefit Amount"
// "Your benefit payout has failed because there was a problem with the bank account you selected."
// ""
// "Please contact support to rectify the problem."
export const incomeProtectionHistoryDetailItemBenefitPayoutInfo = "Your benefit payout has failed because there was a problem with the bank account you selected.\u000A\u000APlease contact support to rectify the problem."
// "Claim Amount"
export const incomeProtectionHistoryDetailItemClaimAmount = "Claim Amount"
// "Your claim for coverage has been denied."
// ""
// "If you have any questions, please contact our support team."
export const incomeProtectionHistoryDetailItemClaimDeniedInfo = "Your claim for coverage has been denied.\u000A\u000AIf you have any questions, please contact our support team."
// "You have submitted a claim for coverage of a covered policy event. Your claim will be reviewed, and you will be notified of the outcome."
export const incomeProtectionHistoryDetailItemClaimSubmittedInfo = "You have submitted a claim for coverage of a covered policy event. Your claim will be reviewed, and you will be notified of the outcome."
// "Date"
export const incomeProtectionHistoryDetailItemDate = "Date"
// "Date Approved"
export const incomeProtectionHistoryDetailItemDateApproved = "Date Approved"
// "Date Submitted"
export const incomeProtectionHistoryDetailItemDateSubmitted = "Date Submitted"
// "As per your Income Protection policy, there is a 90 day waiting period before your coverage activates any layoff events."
// ""
// "You will be able to submit a claim for benefits on {{date}}."
export const incomeProtectionHistoryDetailItemEligibilityBeginsInfo = "As per your Income Protection policy, there is a 90 day waiting period before your coverage activates any layoff events.\u000A\u000AYou will be able to submit a claim for benefits on {{date}}."
// "You are now able to submit a claim for benefits as long your policy remains active."
export const incomeProtectionHistoryDetailItemEligibilityToSubmitInfo = "You are now able to submit a claim for benefits as long your policy remains active."
// "Month {{date}}"
export const incomeProtectionHistoryDetailItemMonty = "Month {{date}}"
// "Payment Amount"
export const incomeProtectionHistoryDetailItemPaymentAmount = "Payment Amount"
// "Your Income Protection policy has been canceled for one of the following reasons:"
// ""
// "• Missed premium payment"
// "• According to Grid’s Income Protection Guarantee"
// "• At your request"
// ""
// "You may be eligible to re-apply for a new policy."
export const incomeProtectionHistoryDetailItemPolicyCanceledInfo = "Your Income Protection policy has been canceled for one of the following reasons:\u000A\u000A• Missed premium payment\u000A• According to Grid’s Income Protection Guarantee\u000A• At your request\u000A\u000AYou may be eligible to re-apply for a new policy."
// "You disputed this premium payment so your Income Protection policy has been canceled."
// ""
// "If the dispute was a mistake, please reach out to our support team."
export const incomeProtectionHistoryDetailItemPremiumPaymentInfo = "You disputed this premium payment so your Income Protection policy has been canceled.\u000A\u000AIf the dispute was a mistake, please reach out to our support team."
// "Status"
export const incomeProtectionHistoryDetailItemStatus = "Status"
// "Complete"
export const incomeProtectionHistoryDetailItemStatusComplete = "Complete"
// "Disputed"
export const incomeProtectionHistoryDetailItemStatusDisputed = "Disputed"
// "Failed"
export const incomeProtectionHistoryDetailItemStatusFailed = "Failed"
// "FREE MONTH"
export const incomeProtectionHistoryDetailItemStatusFreeMonth = "FREE MONTH"
// "Pending"
export const incomeProtectionHistoryDetailItemStatusPending = "Pending"
// "Refunded"
export const incomeProtectionHistoryDetailItemStatusRefunded = "Refunded"
// "Details"
export const incomeProtectionHistoryDetailScreenTitle = "Details"
// "As per your Income Protection policy, a claim for benefits cannot be made within 90 days of enrolling."
// ""
// "You will be able to submit a claim for benefits on {{eligibleAt}}."
export const incomeProtectionHistoryEligibleForClaimAtFutureDateEvent = "As per your Income Protection policy, a claim for benefits cannot be made within 90 days of enrolling.\u000A\u000AYou will be able to submit a claim for benefits on {{eligibleAt}}."
// "You are now able to submit a claim for benefits as long your policy remains active."
export const incomeProtectionHistoryEligibleForClaimEvent = "You are now able to submit a claim for benefits as long your policy remains active."
// "{{companyName}} Policy: {{startDate}} - {{closedDate}}"
export const incomeProtectionHistoryPolicyTitle = "{{companyName}} Policy: {{startDate}} - {{closedDate}}"
// "From: {{startDate}}"
// "To: {{endDate}}"
export const incomeProtectionHistoryPremiumEnrollmentPeriod = "From: {{startDate}}\u000ATo: {{endDate}}"
// "Policy {{section}}"
export const incomeProtectionHistorySectionTitle = "Policy {{section}}"
// "Claims only"
export const incomeProtectionHistorySwitchTitle = "Claims only"
// "Back"
export const incomeProtectionHowItWorksBack = "Back"
// "Grid will process your claim, and when approved, pay you up to $4,000 in cash."
export const incomeProtectionHowItWorksContentFive = "Grid will process your claim, and when approved, pay you up to $4,000 in cash."
// "If you get laid off, file for state unemployment and then submit a claim with Grid."
export const incomeProtectionHowItWorksContentFour = "If you get laid off, file for state unemployment and then submit a claim with Grid."
// "To qualify, you must currently be a full-time W-2 employee."
export const incomeProtectionHowItWorksContentOne = "To qualify, you must currently be a full-time W-2 employee."
// "During your 90 day waiting period you won’t be covered for a job loss."
// "After that, you’re covered!"
export const incomeProtectionHowItWorksContentThree = "During your 90 day waiting period you won’t be covered for a job loss.\u000AAfter that, you’re covered!"
// "Only $15/month. Pay on time to keep your coverage."
export const incomeProtectionHowItWorksContentTwo = "Only $15/month. Pay on time to keep your coverage."
// "Next"
export const incomeProtectionHowItWorksContinue = "Next"
// "Part-time workers, gig workers, and independent contractors will not be eligible."
export const incomeProtectionHowItWorksFinePrintOne = "Part-time workers, gig workers, and independent contractors will not be eligible."
// "This is a separate subscription from the"
// "Grid+ subscription."
export const incomeProtectionHowItWorksFinePrintTwo = "This is a separate subscription from the\u000AGrid+ subscription."
// "To qualify, you must currently be a full-time W-2 employee."
export const incomeProtectionHowItWorksSubitle1 = "To qualify, you must currently be a full-time W-2 employee."
// "Only $15/month. Pay on time to keep your coverage."
export const incomeProtectionHowItWorksSubtitle2 = "Only $15/month. Pay on time to keep your coverage."
// "During your 90 day waiting period you won’t be eligible to submit a claim."
export const incomeProtectionHowItWorksSubtitle3 = "During your 90 day waiting period you won’t be eligible to submit a claim."
// "If you get laid off, file for state unemployment and then submit a claim with Grid."
export const incomeProtectionHowItWorksSubtitle4 = "If you get laid off, file for state unemployment and then submit a claim with Grid."
// "Enroll"
export const incomeProtectionHowItWorksTitle1 = "Enroll"
// "Pay your premium"
export const incomeProtectionHowItWorksTitle2 = "Pay your premium"
// "Waiting period"
export const incomeProtectionHowItWorksTitle3 = "Waiting period"
// "Laid off? Submit a claim"
export const incomeProtectionHowItWorksTitle4 = "Laid off? Submit a claim"
// "Peace of mind"
export const incomeProtectionHowItWorksTitleFive = "Peace of mind"
// "Laid off?"
// "Submit a claim"
export const incomeProtectionHowItWorksTitleFour = "Laid off?\u000ASubmit a claim"
// "Enroll"
export const incomeProtectionHowItWorksTitleOne = "Enroll"
// "Waiting period"
export const incomeProtectionHowItWorksTitleThree = "Waiting period"
// "Pay your premium"
export const incomeProtectionHowItWorksTitleTwo = "Pay your premium"
// "Your premium payment has been refunded. You will be eligible to reapply for Grid Income Protection on {{date}}."
export const incomeProtectionIneligibleTileRefunded = "Your premium payment has been refunded. You will be eligible to reapply for Grid Income Protection on {{date}}."
// "Get Started!"
export const incomeProtectionIntroCTA = "Get Started!"
// "Get $4,000 if you lose your job"
export const incomeProtectionIntroScreenBoxA = "Get $4,000 if you lose your job"
// "Backed by A.M. Best A-rated insurance"
export const incomeProtectionIntroScreenBoxB = "Backed by A.M. Best A-rated insurance"
// "Coverage on top of state benefits"
export const incomeProtectionIntroScreenBoxC = "Coverage on top of state benefits"
// "No hidden fees, cancel anytime"
export const incomeProtectionIntroScreenBoxD = "No hidden fees, cancel anytime"
// "How Does It Work?"
export const incomeProtectionIntroScreenCTATitle = "How Does It Work?"
// "Get started for FREE"
export const incomeProtectionIntroScreenSubtitle = "Get started for FREE"
// "Meet Income Protection"
export const incomeProtectionIntroScreenTitle = "Meet Income Protection"
// "Get $4,000 if you lose your job."
export const incomeProtectionIntroSubtitle = "Get $4,000 if you lose your job."
// "Meet Income Protection"
export const incomeProtectionIntroTitle = "Meet Income Protection"
// "Pay {{amount}}"
export const incomeProtectionMakeAPaymentCTATitle = "Pay {{amount}}"
// "Your payment is being finalized"
export const incomeProtectionMakeAPaymentLoaderTitle = "Your payment is being finalized"
// "Your next premium payment isn’t due until {{date}}."
export const incomeProtectionMakeAPaymentLockedMessage = "Your next premium payment isn’t due until {{date}}."
// "Premium Payment"
export const incomeProtectionMakeAPaymentPremiumRow = "Premium Payment"
// "Close"
export const incomeProtectionMakeAPaymentSuccessClose = "Close"
// "Your {{amount}} payment has been submitted and is currently processing."
export const incomeProtectionMakeAPaymentSuccessContent = "Your {{amount}} payment has been submitted and is currently processing."
// "Thank you for your payment"
export const incomeProtectionMakeAPaymentSuccessTitle = "Thank you for your payment"
// "Make Payment"
export const incomeProtectionMakeAPaymentTitle = "Make Payment"
// "Add payment method"
export const incomeProtectionMakeAPaymentTransferFromAddPaymentMethod = "Add payment method"
// "Transfer From"
export const incomeProtectionMakeAPaymentTransferFromRow = "Transfer From"
// "Employer: {{employer}}"
// "Enrolled: {{from_date}} - {{to_date}}"
export const incomeProtectionMultiplePoliciesScreenItemTitle = "Employer: {{employer}}\u000AEnrolled: {{from_date}} - {{to_date}}"
// "PRESENT"
export const incomeProtectionMultiplePoliciesScreenItemTitlePresent = "PRESENT"
// "Next"
export const incomeProtectionMultiplePoliciesScreenNextCTA = "Next"
// "Please confirm the policy you would like to use to submit a claim."
export const incomeProtectionMultiplePoliciesScreenQuestion = "Please confirm the policy you would like to use to submit a claim."
// "Claim Submission"
export const incomeProtectionMultiplePoliciesScreenTitle = "Claim Submission"
// "NEW: Grid Income Protection"
export const incomeProtectionNewFeatureBanner = "NEW: Grid Income Protection"
// "You will be eligible to re-apply for Income Protection on {{date}}."
export const incomeProtectionNonEligibleForReapplyAlarm = "You will be eligible to re-apply for Income Protection on {{date}}."
// "Not Available"
export const incomeProtectionNotAvailableMessage = "Not Available"
// "Get $4,000 if you lose your job"
export const incomeProtectionOnboardingBubbleContent = "Get $4,000 if you lose your job"
// "PEACE OF MIND"
export const incomeProtectionOnboardingBubbleTitle = "PEACE OF MIND"
// "Get Started for Free"
export const incomeProtectionOnboardingCTA = "Get Started for Free"
// "1 month free, then $15/month"
export const incomeProtectionOnboardingFinePrint = "1 month free, then $15/month"
// "First month FREE"
export const incomeProtectionOnboardingFirstMonthFree = "First month FREE"
// "Limited time only"
export const incomeProtectionOnboardingLimitedTimeOnly = "Limited time only"
// "Protect your income"
export const incomeProtectionOnboardingTitle = "Protect your income"
// "Payment Due:"
export const incomeProtectionOverdueTilePaymentDue = "Payment Due:"
// "https://getgrid.app/terms"
export const incomeProtectionPayAcceptTermsLinkAlgoQueryTermsAttribution = "https://getgrid.app/terms"
// "By confirming your payment, you agree to Grid’s Terms."
export const incomeProtectionPayTerms = "By confirming your payment, you agree to Grid’s Terms."
// "Grid’s Terms"
export const incomeProtectionPayTermsTermsLinkAlgoQuery = "Grid’s Terms"
// "+ Add Debit Card"
export const incomeProtectionPayoutStepAddDebitCardCTA = "+ Add Debit Card"
// "Fast Delivery"
// "$9.99"
export const incomeProtectionPayoutStepFastDelivery = "Fast Delivery\u000A$9.99"
// "Once your claim is approved, your funds will arrive within 24 hours."
export const incomeProtectionPayoutStepFastDeliveryDescription = "Once your claim is approved, your funds will arrive within 24 hours."
// "Fast Delivery"
// "$9.99 FREE"
export const incomeProtectionPayoutStepFastDeliveryFree = "Fast Delivery\u000A$9.99 FREE"
// "$9.99"
export const incomeProtectionPayoutStepFastDeliveryFreeStrike = "$9.99"
// "FAST DELIVERY"
export const incomeProtectionPayoutStepFastDeliveryTitle = "FAST DELIVERY"
// "Skip the fee with Grid Card"
export const incomeProtectionPayoutStepSkipWithGrid = "Skip the fee with Grid Card"
// "Once your claim is approved, your funds will arrive within 6 days 24 hours."
export const incomeProtectionPayoutStepStandardDelivery24 = "Once your claim is approved, your funds will arrive within 6 days 24 hours."
// "6 days"
export const incomeProtectionPayoutStepStandardDelivery24Strike = "6 days"
// "You will be eligible to reapply for Grid Income Protection on {{date}}."
export const incomeProtectionPolicyCanceledContent = "You will be eligible to reapply for Grid Income Protection on {{date}}."
// "Policy Canceled"
export const incomeProtectionPolicyCanceledEventTitle = "Policy Canceled"
// "Your Income Protection Policy has been canceled"
export const incomeProtectionPolicyCanceledTile = "Your Income Protection Policy has been canceled"
// "Claim Denied"
export const incomeProtectionPolicyClaimRejectedEventTitle = "Claim Denied"
// "Coverage Activates On"
export const incomeProtectionPolicyEligibleForClaimAtFutureDateEventTitle = "Coverage Activates On"
// "View Details"
export const incomeProtectionPolicyHistoryCTATitle = "View Details"
// "This is the end of your policy history."
export const incomeProtectionPolicyHistoryScreenEndOfRows = "This is the end of your policy history."
// "You have no policy history at this time."
export const incomeProtectionPolicyHistoryScreenNoRows = "You have no policy history at this time."
// "Policy History"
export const incomeProtectionPolicyHistoryScreenTitle = "Policy History"
// "Intuit Inc. Policy 2"
export const incomeProtectionPolicyHistoryTestFixtureFirstPolicyTitle = "Intuit Inc. Policy 2"
// "Intuit Inc. Policy 1"
export const incomeProtectionPolicyHistoryTestFixtureSecondPolicyTitle = "Intuit Inc. Policy 1"
// "View payments and updates made to your policy"
export const incomeProtectionPolicyHistoryTileSubtitle = "View payments and updates made to your policy"
// "Policy History"
export const incomeProtectionPolicyHistoryTileTitle = "Policy History"
// "Premium Payment"
export const incomeProtectionPolicyPremiumPaymentEventTitle = "Premium Payment"
// "Congrats! You qualify for Income Protection!"
export const incomeProtectionQualifyTitle = "Congrats! You qualify for Income Protection!"
// "Re-Apply"
export const incomeProtectionReApplyTileCTATitle = "Re-Apply"
// "Your policy has been reactivated."
export const incomeProtectionReactivatedPopupTitle = "Your policy has been reactivated."
// "If accepted, $2,000 will be deposited into the account you specified. Your Income Protection policy will then be closed."
export const incomeProtectionSecondClaimSubmittedFinePrint = "If accepted, $2,000 will be deposited into the account you specified. Your Income Protection policy will then be closed."
// "Submit another claim to request your second $2,000 benefit."
export const incomeProtectionSecondClaimWorkflowIntroStepSubtitle = "Submit another claim to request your second $2,000 benefit."
// "Grid is here to help!"
export const incomeProtectionSecondClaimWorkflowIntroStepTitle = "Grid is here to help!"
// "Continue"
export const incomeProtectionSignatureClaimStepCTATitle = "Continue"
// "Clear Signature"
export const incomeProtectionSignatureClaimStepClearCTATitle = "Clear Signature"
// "I declare under penalty of perjury under the laws of the United States of America that the information contained in my Application is true, complete, and correct to the best of my knowledge and belief, and this Application is made in good faith. I certify that all personal and employment information contained herein is accurate and that my Application is submitted in accordance with Grid’s terms and conditions."
export const incomeProtectionSignatureClaimStepContent = "I declare under penalty of perjury under the laws of the United States of America that the information contained in my Application is true, complete, and correct to the best of my knowledge and belief, and this Application is made in good faith. I certify that all personal and employment information contained herein is accurate and that my Application is submitted in accordance with Grid’s terms and conditions."
// "Please Sign Below"
export const incomeProtectionSignatureClaimStepSecondHeader = "Please Sign Below"
// "View the status of your claim"
export const incomeProtectionStatusTileClaimStatusRowTitle = "View the status of your claim"
// "Blocked from making a claim until {{date}}."
export const incomeProtectionSubmitAClaimBlockedUntilMessage = "Blocked from making a claim until {{date}}."
// "Your coverage does not activate until {{date}}."
export const incomeProtectionSubmitAClaimLockedMessage = "Your coverage does not activate until {{date}}."
// "Close"
export const incomeProtectionSuccessScreenCTATitle = "Close"
// "Congrats, you’re covered!"
export const incomeProtectionSuccessScreenMessage = "Congrats, you’re covered!"
// "Finish Enrolling"
export const incomeProtectionTabDashboardOnboardingApplyingCTATitle = "Finish Enrolling"
// "PEACE OF MIND"
// "Get $4,000 if you lose your job"
export const incomeProtectionTabDashboardOnboardingBanner = "PEACE OF MIND\u000AGet $4,000 if you lose your job"
// "Get Started for Free"
export const incomeProtectionTabDashboardOnboardingCTATitle = "Get Started for Free"
// "Limited time only"
export const incomeProtectionTabDashboardOnboardingLimitedOffer = "Limited time only"
// "1 month free, then $15/month"
export const incomeProtectionTabDashboardOnboardingPricing = "1 month free, then $15/month"
// "First month FREE"
export const incomeProtectionTabDashboardOnboardingSubtitle = "First month FREE"
// "Protect your"
// "income"
export const incomeProtectionTabDashboardOnboardingTitle = "Protect your\u000Aincome"
// "Protect"
export const incomeProtectionTabDashboardTitle = "Protect"
// "Protect"
export const incomeProtectionTabTitle = "Protect"
// "By enrolling in the Income Protection program with Grid, you agree to Grid’s Income Protection Agreement."
export const incomeProtectionTermsOfService = "By enrolling in the Income Protection program with Grid, you agree to Grid’s Income Protection Agreement."
// "By enrolling in the Income Protection program on Grid, you agree to Grid’s Income Protection Agreement and authorize Grid to withdraw subsequent premium payment amounts from your Grid account and your card(s) ending in {{cards}}."
export const incomeProtectionTermsOfService2 = "By enrolling in the Income Protection program on Grid, you agree to Grid’s Income Protection Agreement and authorize Grid to withdraw subsequent premium payment amounts from your Grid account and your card(s) ending in {{cards}}."
// "I Agree"
export const incomeProtectionTermsOfServiceCTA = "I Agree"
// "Income Protection Agreement"
export const incomeProtectionTermsOfServiceLinkedText = "Income Protection Agreement"
// "Terms of Service"
export const incomeProtectionTermsOfServiceTitle = "Terms of Service"
// "https://getgrid.app/terms/income-protection"
export const incomeProtectionTermsOfServiceURL = "https://getgrid.app/terms/income-protection"
// "Get Income Protection"
export const incomeProtectionTileCTA = "Get Income Protection"
// "Make Payment"
export const incomeProtectionTileMakeAPaymentTitle = "Make Payment"
// "Submit a Claim"
export const incomeProtectionTileSubmitAClaimTitle = "Submit a Claim"
// "See if you qualify for $4,000 in income protection!"
export const incomeProtectionTileTitle = "See if you qualify for $4,000 in income protection!"
// "Apply for $4,000 in income protection"
export const incomeProtectionTileTitleAlt1 = "Apply for $4,000 in income protection"
// "Get stated with income protection"
export const incomeProtectionTileTitleAlt2 = "Get stated with income protection"
// "Confirm"
export const incomeProtectionUploadDocCTATitle = "Confirm"
// "An approved notice of state unemployment benefits is a document you receive from your state that confirms your eligibility to receive benefits.  It should include:"
export const incomeProtectionUploadDocsApprovalLetterHelpContent1Intro = "An approved notice of state unemployment benefits is a document you receive from your state that confirms your eligibility to receive benefits.  It should include:"
// "Start and end dates of benefits"
export const incomeProtectionUploadDocsApprovalLetterHelpContent1Point1 = "Start and end dates of benefits"
// "Your benefit amount"
export const incomeProtectionUploadDocsApprovalLetterHelpContent1Point2 = "Your benefit amount"
// "Employer Information"
export const incomeProtectionUploadDocsApprovalLetterHelpContent1Point3 = "Employer Information"
// "After applying for state unemployment benefits and getting approved, your state’s unemployment agency will notify you of your eligibility either through your online portal or by mail. For more information go to https://www.usa.gov/unemployment-benefits."
export const incomeProtectionUploadDocsApprovalLetterHelpContent2Intro = "After applying for state unemployment benefits and getting approved, your state’s unemployment agency will notify you of your eligibility either through your online portal or by mail. For more information go to https://www.usa.gov/unemployment-benefits."
// "https://www.usa.gov/unemployment-benefits"
export const incomeProtectionUploadDocsApprovalLetterHelpContent2IntroLink = "https://www.usa.gov/unemployment-benefits"
// "Monetary determination letters do not count as an eligible notice for unemployment on their own."
export const incomeProtectionUploadDocsApprovalLetterHelpContent3Point1 = "Monetary determination letters do not count as an eligible notice for unemployment on their own."
// "The start date of benefits must be on or after your termination date"
export const incomeProtectionUploadDocsApprovalLetterHelpContent3Point2 = "The start date of benefits must be on or after your termination date"
// "Employer information must match your pay stubs"
export const incomeProtectionUploadDocsApprovalLetterHelpContent3Point3 = "Employer information must match your pay stubs"
// "What is an approved notice of state unemployment benefits?"
export const incomeProtectionUploadDocsApprovalLetterHelpTitle1 = "What is an approved notice of state unemployment benefits?"
// "Where can I get one?"
export const incomeProtectionUploadDocsApprovalLetterHelpTitle2 = "Where can I get one?"
// "What is an approved notice of state unemployment benefits?"
export const incomeProtectionUploadDocsApprovalLetterHelpTitle3 = "What is an approved notice of state unemployment benefits?"
// "A paystub is a document your employer gives to you along with your paycheck that shows:"
export const incomeProtectionUploadDocsHelpContent1Intro = "A paystub is a document your employer gives to you along with your paycheck that shows:"
// "Employer name"
export const incomeProtectionUploadDocsHelpContent1Point1 = "Employer name"
// "Employee name"
export const incomeProtectionUploadDocsHelpContent1Point2 = "Employee name"
// "Pay period start and pay period end dates (these are not the same as the paycheck date)"
export const incomeProtectionUploadDocsHelpContent1Point3 = "Pay period start and pay period end dates (these are not the same as the paycheck date)"
// "How much money you earned"
export const incomeProtectionUploadDocsHelpContent1Point4 = "How much money you earned"
// "How many hours you worked"
export const incomeProtectionUploadDocsHelpContent1Point5 = "How many hours you worked"
// "How much tax was taken out"
export const incomeProtectionUploadDocsHelpContent1Point6 = "How much tax was taken out"
// "Some employers give you a physical paystub with your paycheck. Others may provide electronic pay stubs that you can access through a company portal or HR system. If you’re not sure how to access your pay stubs online, ask your employer for instructions."
export const incomeProtectionUploadDocsHelpContent2Point1 = "Some employers give you a physical paystub with your paycheck. Others may provide electronic pay stubs that you can access through a company portal or HR system. If you’re not sure how to access your pay stubs online, ask your employer for instructions."
// "What is a paystub?"
export const incomeProtectionUploadDocsHelpTitle1 = "What is a paystub?"
// "Where can I find it?"
export const incomeProtectionUploadDocsHelpTitle2 = "Where can I find it?"
// "A termination letter is a document that details important information about your termination, including:"
export const incomeProtectionUploadDocsTerminationLetterHelpContent1Intro = "A termination letter is a document that details important information about your termination, including:"
// "Employer Name"
export const incomeProtectionUploadDocsTerminationLetterHelpContent1Point1 = "Employer Name"
// "Employee Name"
export const incomeProtectionUploadDocsTerminationLetterHelpContent1Point2 = "Employee Name"
// "The reason(s) for termination"
export const incomeProtectionUploadDocsTerminationLetterHelpContent1Point3 = "The reason(s) for termination"
// "The termination date"
export const incomeProtectionUploadDocsTerminationLetterHelpContent1Point4 = "The termination date"
// "To get a termination letter from your employer, ask them directly. Some states require that your employer provide you with a termination letter."
export const incomeProtectionUploadDocsTerminationLetterHelpContent2Intro = "To get a termination letter from your employer, ask them directly. Some states require that your employer provide you with a termination letter."
// "What is a termination letter?"
export const incomeProtectionUploadDocsTerminationLetterHelpTitle1 = "What is a termination letter?"
// "Where can I get one?"
export const incomeProtectionUploadDocsTerminationLetterHelpTitle2 = "Where can I get one?"
// "How it works:"
export const ineligibleCreditLineUpgradeBulletListHeader = "How it works:"
// "• Set up a direct deposit of $250+"
export const ineligibleCreditLineUpgradeBulletListItem1 = "• Set up a direct deposit of $250+"
// "• Spend $500 on your Grid Card"
export const ineligibleCreditLineUpgradeBulletListItem2 = "• Spend $500 on your Grid Card"
// "• Once complete, your credit line limit will increase by $50 the next month"
export const ineligibleCreditLineUpgradeBulletListItem3 = "• Once complete, your credit line limit will increase by $50 the next month"
// "Check back next month to start the program and track your progress."
export const ineligibleCreditLineUpgradeFooter = "Check back next month to start the program and track your progress."
// "Get $50 more when you complete monthly goals"
export const ineligibleCreditLineUpgradeSubtitle = "Get $50 more when you complete monthly goals"
// "Level up your Credit Line"
export const ineligibleCreditLineUpgradeTitle = "Level up your Credit Line"
// "Request a Quote"
export const insuranceTeasingScreenCTATitle = "Request a Quote"
// "A better credit score means better rates, compare them today! Plus when you pay with your Grid Card you get up to 10x rewards on your premium."
// ""
// "Get a quote from our partner Harmonic in minutes."
export const insuranceTeasingScreenContent = "A better credit score means better rates, compare them today! Plus when you pay with your Grid Card you get up to 10x rewards on your premium.\u000A\u000AGet a quote from our partner Harmonic in minutes."
// "Save on your auto insurance and get rewarded!"
export const insuranceTeasingScreenTitle = "Save on your auto insurance and get rewarded!"
// "Request a Quote"
export const insuranceTileCTATitle = "Request a Quote"
// "Auto Insurance"
export const insuranceTileSectionTitle = "Auto Insurance"
// "Save on your premium, plus earn up to 10x rewards when you pay with Grid Card."
export const insuranceTileSubtitle = "Save on your premium, plus earn up to 10x rewards when you pay with Grid Card."
// "Higher score, lower rates!"
export const insuranceTileTitle = "Higher score, lower rates!"
// "day"
export const intervalDay = "day"
// "days"
export const intervalDays = "days"
// "month"
export const intervalMonth = "month"
// "once"
export const intervalOnce = "once"
// "week"
export const intervalWeek = "week"
// "year"
export const intervalYear = "year"
// "Logout"
export const launchLogoutButton = "Logout"
// "Try Again"
export const launchRetryButton = "Try Again"
// "Cardholder agreement"
export const legalSupportMenuRowTitleSynapseCardholderAgreement = "Cardholder agreement"
// "Card Loan agreement"
export const legalSupportMenuRowTitleSynapseConsumerLoanAgreement = "Card Loan agreement"
// "Credit Line Loan Agreement"
export const legalSupportMenuRowTitleSynapseCreditLoanAgreement = "Credit Line Loan Agreement"
// "Deposit agreement"
export const legalSupportMenuRowTitleSynapseDepositAgreement = "Deposit agreement"
// "Terms of Service"
export const legalSupportMenuRowTitleTermsOfService = "Terms of Service"
// "Agreements"
export const legalSupportMenuTitle = "Agreements"
// "Change Bank"
export const linkedAccountsAdvanceTileChangeBankCTA = "Change Bank"
// "Relink Bank"
export const linkedAccountsAdvanceTileRelinkBankCTA = "Relink Bank"
// "Relink Card"
export const linkedAccountsAdvanceTileRelinkCardCTA = "Relink Card"
// "Grid Advance Account"
export const linkedAccountsAdvanceTileTitle = "Grid Advance Account"
// "Card ••••"
export const linkedAccountsCardNumber = "Card ••••"
// "Change"
export const linkedAccountsChangeAccountCTA = "Change"
// "DEFAULT"
export const linkedAccountsDefaultRow = "DEFAULT"
// "Fix Required"
export const linkedAccountsFixRequired = "Fix Required"
// "Close"
export const linkedAccountsOutstandingBalanceCTA = "Close"
// "To change your linked account, please first pay back your current advance or any overdue subscription invoices."
export const linkedAccountsOutstandingBalanceSubtitle = "To change your linked account, please first pay back your current advance or any overdue subscription invoices."
// "You have an outstanding balance on your account"
export const linkedAccountsOutstandingBalanceTitle = "You have an outstanding balance on your account"
// "Payment Cards"
export const linkedAccountsPaymentCardsTitle = "Payment Cards"
// "RELINK"
export const linkedAccountsRelinkCTA = "RELINK"
// "Enable {{biometry_type}}"
export const localauthButtonEnable = "Enable {{biometry_type}}"
// "Not now"
export const localauthButtonNotnow = "Not now"
// "Do you want to turn on {{biometry_type}} for sign in?"
export const localauthInstructions = "Do you want to turn on {{biometry_type}} for sign in?"
// "Log in"
export const loginEmailButton = "Log in"
// "Please enter your email"
export const loginEmailInstructions = "Please enter your email"
// "Log in"
export const loginPasswordButton = "Log in"
// "Forgot your password?"
export const loginPasswordForgotButton = "Forgot your password?"
// "Enter your email to reset password"
export const loginPasswordInstructions = "Enter your email to reset password"
// "Password"
export const loginPasswordPrefill = "Password"
// "Reset Password"
export const loginResetPasswordButton = "Reset Password"
// "Please check your email to reset your password"
export const loginResetPasswordMessage = "Please check your email to reset your password"
// "Reset password sent!"
export const loginResetPasswordTitle = "Reset password sent!"
// "Hey there! 👋"
export const loginResetPasswordWelcomeTitle = "Hey there! 👋"
// "Welcome back!"
export const loginScreenTitle = "Welcome back!"
// "Get more cash with our Levels program"
export const meetCashboostBulletsGetMoreCash = "Get more cash with our Levels program"
// "Get up to $200 with our levels program"
export const meetCashboostBulletsLevels = "Get up to $200 with our levels program"
// "No credit check"
export const meetCashboostBulletsNoCreditCheck = "No credit check"
// "No hidden fees or “tips”"
export const meetCashboostBulletsNoHiddenFees = "No hidden fees or “tips”"
// "Unlimited cash advances"
export const meetCashboostBulletsUnlimitedAdvancesTitle = "Unlimited cash advances"
// "Get Started"
export const meetCashboostCTATitle = "Get Started"
// "Grid Advance requires Grid+ subscription"
export const meetCashboostGridPlus = "Grid Advance requires Grid+ subscription"
// "Learn more about Levels"
export const meetCashboostLevelsCTA = "Learn more about Levels"
// "Cash advances with no strings"
export const meetCashboostSubtitle = "Cash advances with no strings"
// "Meet Grid Advance"
export const meetCashboostTitle = "Meet Grid Advance"
// "Please enter a valid CVV to continue."
export const multidigitPhoneNumberErrorInvalidCVV = "Please enter a valid CVV to continue."
// "Please enter a valid four numbers to continue."
export const multidigitPhoneNumberErrorInvalidLastFour = "Please enter a valid four numbers to continue."
// "Please enter a valid PIN to continue."
export const multidigitPhoneNumberErrorInvalidPIN = "Please enter a valid PIN to continue."
// "Please enter a valid phone number to continue."
export const multidigitPhoneNumberErrorInvalidPhoneNumber = "Please enter a valid phone number to continue."
// "Both entries must match to continue."
export const multidigitPhoneNumberErrorMismatchedPin = "Both entries must match to continue."
// "Please enter a valid social security number to continue."
export const multidigitSSNErrorInvalidSSN = "Please enter a valid social security number to continue."
// "We need some additional info to calculate your PayBoost. Please reach out to our support team (upper right of your screen)"
export const offboardingActionNotSupportedMessage = "We need some additional info to calculate your PayBoost. Please reach out to our support team (upper right of your screen)"
// "Unsupported"
export const offboardingActionNotSupportedTitle = "Unsupported"
// "Leave a Review"
export const offboardingLeaveAReviewButton = "Leave a Review"
// "Contact Support"
export const offboardingNeedMoreHelpButton = "Contact Support"
// "See your PayBoost"
export const onboardingHomeBoostCheckoutButton = "See your PayBoost"
// "See details"
export const onboardingHomeBoostCheckoutButtonSeeDetails = "See details"
// "You qualify!"
export const onboardingHomeBoostCheckoutMessage = "You qualify!"
// "PayBoost:"
export const onboardingHomeBoostCheckoutTitle = "PayBoost:"
// "Getting Started"
export const onboardingHomeBoostHeading = "Getting Started"
// "Contact Support"
export const onboardingHomeFooterUnsupportedContactSupportButtonText = "Contact Support"
// "Unknown Error"
export const onboardingHomeFooterUnsupportedFallbackHeader = "Unknown Error"
// "Get Grid+"
export const onboardingHomeFooterUnsupportedGetGridPlusActionButtonText = "Get Grid+"
// "Based on your info you qualify for Grid+ and can get access to unlimited cash advances, flexible repayment and much more."
export const onboardingHomeFooterUnsupportedGetGridPlusDescription = "Based on your info you qualify for Grid+ and can get access to unlimited cash advances, flexible repayment and much more."
// "Welcome to Grid+"
export const onboardingHomeFooterUnsupportedGetGridPlusHeader = "Welcome to Grid+"
// "Please reach out to our support team if you have any questions."
export const onboardingHomeFooterUnsupportedGetGridPlusInstructions = "Please reach out to our support team if you have any questions."
// "Unsupported"
export const onboardingHomeFooterUnsupportedHeader = "Unsupported"
// "Please reach out to our support team if you have any questions."
export const onboardingHomeFooterUnsupportedInstructions = "Please reach out to our support team if you have any questions."
// "What can we do better?"
export const onboardingHomeFooterUnsupportedLeaveAReviewButtonText = "What can we do better?"
// "Uh-oh, something went wrong. Please reach out to customer support using the chat feature so we can identify the issue."
export const onboardingHomeFooterUnsupportedTitle = "Uh-oh, something went wrong. Please reach out to customer support using the chat feature so we can identify the issue."
// "Notify me"
export const onboardingHomeFooterWaitingOnBoostButtonEnablePush = "Notify me"
// "PayBoost:"
export const onboardingHomeFooterWaitingOnBoostHeader = "PayBoost:"
// "PayBoost with no start fee:"
export const onboardingHomeFooterWaitingOnBoostHeaderWaivedFee = "PayBoost with no start fee:"
// "Hold tight, we’re working on your PayBoost plan…"
export const onboardingHomeFooterWaitingOnBoostTitle = "Hold tight, we’re working on your PayBoost plan…"
// "Continue"
export const onboardingHomeFooterWorkflowsButtonInProgress = "Continue"
// "Begin"
export const onboardingHomeFooterWorkflowsButtonReady = "Begin"
// "Continue"
export const onboardingHomeFooterWorkflowsButtonReadyWithProgress = "Continue"
// "Next"
export const onboardingHomeFooterWorkflowsHeader = "Next"
// ""
export const onboardingHomeProgressHouseholdBody = ""
// "Almost done – two down, one to go!"
export const onboardingHomeProgressHouseholdHeadline = "Almost done – two down, one to go!"
// ""
export const onboardingHomeProgressPersonalBody = ""
// "One down, two to go!"
export const onboardingHomeProgressPersonalHeadline = "One down, two to go!"
// "{{numerator}} of {{denominator}}"
// "Categories"
export const onboardingHomeProgressRingProgress = "{{numerator}} of {{denominator}}\u000ACategories"
// "Hold tight while we create a PayBoost plan for you…"
export const onboardingHomeWaitingOnBoostLoading = "Hold tight while we create a PayBoost plan for you…"
// "We’re hard at work building the new and improved PayBoost for you!"
export const payBoostUnderConstructionBannerSubtitle = "We’re hard at work building the new and improved PayBoost for you!"
// "PayBoost is under construction"
export const payBoostUnderConstructionBannerTitle = "PayBoost is under construction"
// "Under Construction"
export const payBoostUnderConstructionTitle = "Under Construction"
// "Add payment method"
export const payEverythingBottomSheetAddPaymentMethod = "Add payment method"
// "Advance Amount"
export const payEverythingBottomSheetAdvanceAmount = "Advance Amount"
// "Now"
export const payEverythingBottomSheetAmountFallback = "Now"
// "Card"
export const payEverythingBottomSheetCardTitle = "Card"
// "+ Change payment method"
export const payEverythingBottomSheetChangePaymentMethodCTA = "+ Change payment method"
// "Credit Line Balance"
export const payEverythingBottomSheetCreditLineBalance = "Credit Line Balance"
// "Due Date"
export const payEverythingBottomSheetDateTitle = "Due Date"
// "Default Card"
export const payEverythingBottomSheetDefaultPaymentMethod = "Default Card"
// "Delivery Fee"
export const payEverythingBottomSheetDeliveryFee = "Delivery Fee"
// "Discount 🎉"
export const payEverythingBottomSheetDiscount = "Discount 🎉"
// "Make Payment"
export const payEverythingBottomSheetMakePayment = "Make Payment"
// "Negative Grid Card Balance"
export const payEverythingBottomSheetNegativeBalance = "Negative Grid Card Balance"
// "Pay Now"
export const payEverythingBottomSheetPayButtonTitle = "Pay Now"
// "Payment Amount"
export const payEverythingBottomSheetPaymentAmount = "Payment Amount"
// "Your payment is being finalized"
export const payEverythingBottomSheetProcessing = "Your payment is being finalized"
// "Remaining Balance"
export const payEverythingBottomSheetRemainingBalance = "Remaining Balance"
// "Subscription Fee"
export const payEverythingBottomSheetSubscriptionFee = "Subscription Fee"
// "Subscription Fees"
export const payEverythingBottomSheetSubscriptionFeePlural = "Subscription Fees"
// "Summary"
export const payEverythingBottomSheetSummary = "Summary"
// "Total Due"
export const payEverythingBottomSheetTotalDue = "Total Due"
// "Transfer From"
export const payEverythingBottomSheetTransferFrom = "Transfer From"
// "Pay Now"
export const payEverythingCashboostTileCTATitle = "Pay Now"
// "Repayment due"
export const payEverythingCashboostTileTitle = "Repayment due"
// "Transfer Amount"
export const payEverythingChooseAmountChooseAmount = "Transfer Amount"
// "Continue"
export const payEverythingChooseAmountContinue = "Continue"
// "This is more than the amount due."
export const payEverythingChooseAmountMaximumPaymenteError = "This is more than the amount due."
// "The minimum payment amount is {{amt}}"
export const payEverythingChooseAmountMinimumPaymenteError = "The minimum payment amount is {{amt}}"
// "Total Due: {{amt}}"
export const payEverythingChooseAmountTotalDue = "Total Due: {{amt}}"
// "Looks like something went wrong! Please try again later or use a different payment method."
export const payEverythingInvoiceFailedMessage = "Looks like something went wrong! Please try again later or use a different payment method."
// "Oops!"
export const payEverythingInvoiceFailedTitle = "Oops!"
// "Please retry your payment in a bit. If there are still issues, please reach out to Support for help."
export const payEverythingInvoiceMismatchMessage = "Please retry your payment in a bit. If there are still issues, please reach out to Support for help."
// "We ran into a small hiccup."
export const payEverythingInvoiceMismatchTitle = "We ran into a small hiccup."
// "Add Funds"
export const payEverythingNegativeBalanceAlertACTA = "Add Funds"
// "Transfer funds to your card in order to get another Grid Advance."
export const payEverythingNegativeBalanceAlertASubtitle = "Transfer funds to your card in order to get another Grid Advance."
// "You have a negative balance on your Grid Card"
export const payEverythingNegativeBalanceAlertATitle = "You have a negative balance on your Grid Card"
// "Make a payment"
export const payEverythingNegativeBalanceAlertBCTA = "Make a payment"
// "Please repay the outstanding balance on your account before requesting another advance."
export const payEverythingNegativeBalanceAlertBSubtitle = "Please repay the outstanding balance on your account before requesting another advance."
// "You have an outstanding balance"
export const payEverythingNegativeBalanceAlertBTitle = "You have an outstanding balance"
// "Make a payment"
export const payEverythingNegativeBalanceAlertCCTA = "Make a payment"
// "We were unable to process your subscription payment. Please make a payment in order to access premium features."
export const payEverythingNegativeBalanceAlertCSubtitle = "We were unable to process your subscription payment. Please make a payment in order to access premium features."
// "Your subscription payment is overdue"
export const payEverythingNegativeBalanceAlertCTitle = "Your subscription payment is overdue"
// "Looks like there is a problem with this card. Please enter a different payment method."
export const payEverythingPaymentFailedBadCard = "Looks like there is a problem with this card. Please enter a different payment method."
// "It looks like this account is closed. Please reopen the account and try again or use a different payment method."
export const payEverythingPaymentFailedDeclineAccountClosed = "It looks like this account is closed. Please reopen the account and try again or use a different payment method."
// "It looks like this account doesn’t have sufficient funds to cover this transaction. Please add more funds to your card and try again."
export const payEverythingPaymentFailedDeclineInsufficientFunds = "It looks like this account doesn’t have sufficient funds to cover this transaction. Please add more funds to your card and try again."
// "There was a problem initiating this transaction. Please check to see if the numbers you have entered are correct."
export const payEverythingPaymentFailedDeclineInvalidEntries = "There was a problem initiating this transaction. Please check to see if the numbers you have entered are correct."
// "Looks like something went wrong! Please try again later or use a different payment method."
export const payEverythingPaymentFailedGenericDecline = "Looks like something went wrong! Please try again later or use a different payment method."
// "Looks like something went wrong! Please try again or use a different payment method."
export const payEverythingPaymentFailedGenericSystemError = "Looks like something went wrong! Please try again or use a different payment method."
// "It looks like this card has not been setup. Please finish setting up your card and try again."
export const payEverythingPaymentFailedNotSetup = "It looks like this card has not been setup. Please finish setting up your card and try again."
// "Looks like there is a problem with this card. Please enter a different payment method."
export const payEverythingPaymentKYCIssue = "Looks like there is a problem with this card. Please enter a different payment method."
// "Advance Amount"
export const payEverythingPaymentsAdvanceAmount = "Advance Amount"
// "Delivery Fee"
export const payEverythingPaymentsAdvanceDeliveryFee = "Delivery Fee"
// "Credit Line Balance"
export const payEverythingPaymentsCreditLineBalance = "Credit Line Balance"
// "Grid Card Fee"
export const payEverythingPaymentsGridCardFee = "Grid Card Fee"
// "Account"
export const payEverythingPaymentsHistoryDetailsScreenAccount = "Account"
// "Date"
export const payEverythingPaymentsHistoryDetailsScreenDate = "Date"
// "Disputed"
export const payEverythingPaymentsHistoryDetailsScreenDisputed = "Disputed"
// "Payment"
export const payEverythingPaymentsHistoryDetailsScreenPayment = "Payment"
// "Advance Amount"
export const payEverythingPaymentsHistoryDetailsScreenPaymentDetailsAdvanceAmount = "Advance Amount"
// "Delivery Fee"
export const payEverythingPaymentsHistoryDetailsScreenPaymentDetailsDeliveryFee = "Delivery Fee"
// "Subscription Fee"
export const payEverythingPaymentsHistoryDetailsScreenPaymentDetailsSubscriptionFee = "Subscription Fee"
// "Payment Details"
export const payEverythingPaymentsHistoryDetailsScreenPaymentDetailsTitle = "Payment Details"
// "Total"
export const payEverythingPaymentsHistoryDetailsScreenPaymentDetailsTotal = "Total"
// "Problem with this transaction?"
export const payEverythingPaymentsHistoryDetailsScreenPaymentProblemCTA = "Problem with this transaction?"
// "Payment Refund"
export const payEverythingPaymentsHistoryDetailsScreenPaymentRefund = "Payment Refund"
// "Refund"
export const payEverythingPaymentsHistoryDetailsScreenRefund = "Refund"
// "Status"
export const payEverythingPaymentsHistoryDetailsScreenStatus = "Status"
// "Pending"
export const payEverythingPaymentsHistoryDetailsScreenStatusPending = "Pending"
// "Posted"
export const payEverythingPaymentsHistoryDetailsScreenStatusPosted = "Posted"
// "Type"
export const payEverythingPaymentsHistoryDetailsScreenType = "Type"
// "Payment History"
export const payEverythingPaymentsHistoryRowTitle = "Payment History"
// "This is the end of this month’s payment history."
export const payEverythingPaymentsHistoryScreenEndOfList = "This is the end of this month’s payment history."
// "You haven’t made any payments this month."
export const payEverythingPaymentsHistoryScreenNoPayments = "You haven’t made any payments this month."
// "Pending"
export const payEverythingPaymentsHistoryScreenPendingSection = "Pending"
// "Posted"
export const payEverythingPaymentsHistoryScreenPostedSection = "Posted"
// "Payment History"
export const payEverythingPaymentsHistoryScreenTitle = "Payment History"
// "Negative Grid Card Balance"
export const payEverythingPaymentsNegativeBalance = "Negative Grid Card Balance"
// "Subscription Fee"
export const payEverythingPaymentsSubscriptionFee = "Subscription Fee"
// "Subscription Fees"
export const payEverythingPaymentsSubscriptionFeePlural = "Subscription Fees"
// "Manage Subscription"
export const payEverythingSettingsManageSubscription = "Manage Subscription"
// "Active"
export const payEverythingSettingsManageSubscriptionActive = "Active"
// "Next payment on {{date}}"
export const payEverythingSettingsManageSubscriptionNextPayment = "Next payment on {{date}}"
// "Payment History"
export const payEverythingSettingsPaymentHistory = "Payment History"
// "This is the end of this month’s payment history."
export const payEverythingSettingsPaymentHistoryEndOfList = "This is the end of this month’s payment history."
// "Grid Balance Payment"
export const payEverythingSettingsPaymentHistoryGridBalancePayment = "Grid Balance Payment"
// "You haven’t made any payments this month."
export const payEverythingSettingsPaymentHistoryNoPayments = "You haven’t made any payments this month."
// "Pending"
export const payEverythingSettingsPaymentHistoryPending = "Pending"
// "Posted"
export const payEverythingSettingsPaymentHistoryPosted = "Posted"
// "Payment History"
export const payEverythingSettingsPaymentHistoryTitle = "Payment History"
// "Continue"
export const payEverythingSuccessScreenClose = "Continue"
// "Your {{amt}} payment has been submitted and is currently processing."
export const payEverythingSuccessScreenSubtitle = "Your {{amt}} payment has been submitted and is currently processing."
// "Thank you for your payment"
export const payEverythingSuccessScreenTitle = "Thank you for your payment"
// "Subscription Overdue"
export const payEverythingYourSubscriptionOverdue = "Subscription Overdue"
// "You have no documents under {{type}}"
export const payboostAllDocumentsUploadedEmptyState = "You have no documents under {{type}}"
// "You can add or remove any documents"
export const payboostAllDocumentsUploadedSubtitle = "You can add or remove any documents"
// "All Documents Uploaded"
export const payboostAllDocumentsUploadedTitle = "All Documents Uploaded"
// "CURRENT"
export const payboostBoostedCurrentTitle = "CURRENT"
// "Sit back and relax"
export const payboostBoostedLabel = "Sit back and relax"
// "Last Received: {{date}}"
export const payboostBoostedLastReceivedDate = "Last Received: {{date}}"
// "on {{date}}"
export const payboostBoostedPendingCardDate = "on {{date}}"
// "Your Pending PayBoost"
export const payboostBoostedPendingCardTitle = "Your Pending PayBoost"
// "Enable push notifications so we can let you know when your payroll provider has updated your withholdings"
export const payboostBoostedPushNotificationSubtitle = "Enable push notifications so we can let you know when your payroll provider has updated your withholdings"
// "Congratulations! You’re one month closer to your PayBoost!"
export const payboostBoostedPushNotificationTitle = "Congratulations! You’re one month closer to your PayBoost!"
// "Your PayBoost is on its way"
export const payboostBoostedSubtitle = "Your PayBoost is on its way"
// "To-do"
export const payboostCardTodoTileTitle = "To-do"
// "Your PayBoost amount will be added to your Grid Card."
export const payboostCheckoutIntroHasCardBody = "Your PayBoost amount will be added to your Grid Card."
// "Continue"
export const payboostCheckoutIntroHasCardCTA = "Continue"
// "You’re all set!"
export const payboostCheckoutIntroHasCardTitle = "You’re all set!"
// "Your PayBoost amount will be added to your Grid Card. We need some basic info to deliver your card to you"
export const payboostCheckoutIntroNeedsCardBody = "Your PayBoost amount will be added to your Grid Card. We need some basic info to deliver your card to you"
// "Continue"
export const payboostCheckoutIntroNeedsCardCTA = "Continue"
// "There’s just a few more things…"
export const payboostCheckoutIntroNeedsCardTitle = "There’s just a few more things…"
// "Sorry, you don’t currently qualify for PayBoost"
export const payboostDisqualifiedErrorMessage = "Sorry, you don’t currently qualify for PayBoost"
// "You don’t currently qualify for PayBoost."
export const payboostDisqualifiedGeneralReason = "You don’t currently qualify for PayBoost."
// "Employment Blocked"
export const payboostDisqualifiedReasonEmploymentBlocked = "Employment Blocked"
// "Not available"
export const payboostDisqualifiedTitle = "Not available"
// "Enter your latest info so we can make the perfect PayBoost for you"
export const payboostOnboardingTileMessage = "Enter your latest info so we can make the perfect PayBoost for you"
// "Resume"
export const payboostOnboardingTileResumeCtaTitle = "Resume"
// "Get Started for Free"
export const payboostOnboardingTileSetupCtaTitle = "Get Started for Free"
// "Apply for a BOOST"
export const payboostOnboardingTileTitle = "Apply for a BOOST"
// "Continue"
export const payboostPayrollConnectIntroButtonTitle = "Continue"
// "1."
export const payboostPayrollConnectIntroFirstMarker = "1."
// "Add the job where you make most of your money."
export const payboostPayrollConnectIntroFirstValueProp = "Add the job where you make most of your money."
// "2."
export const payboostPayrollConnectIntroSecondMarker = "2."
// "Login with your payroll account"
// "credentials"
export const payboostPayrollConnectIntroSecondValueProp = "Login with your payroll account\u000Acredentials"
// "3."
export const payboostPayrollConnectIntroThirdMarker = "3."
// "If you have any other jobs, let us know during sign-up."
export const payboostPayrollConnectIntroThirdValueProp = "If you have any other jobs, let us know during sign-up."
// "Grid links to your payroll provider to automate your PayBoost."
export const payboostPayrollConnectIntroTitle = "Grid links to your payroll provider to automate your PayBoost."
// "Payroll Connect"
export const payboostPayroollConnectIntroNavBarTitle = "Payroll Connect"
// "PayBoost optimizes your paycheck, helping you keep more money in your pocket each pay period"
export const payboostReviewResultsBenefitExplanationBody = "PayBoost optimizes your paycheck, helping you keep more money in your pocket each pay period"
// "Continue"
export const payboostReviewResultsButton = "Continue"
// "This means you could be taking home up to {{amount}} more per month this year."
export const payboostReviewResultsPayoutExplanationBody = "This means you could be taking home up to {{amount}} more per month this year."
// "What does this mean?"
export const payboostReviewResultsPayoutExplanationTitle = "What does this mean?"
// "You qualify for PayBoost!"
export const payboostReviewResultsTitle = "You qualify for PayBoost!"
// "We ran all your numbers and are ready to go with your PayBoost."
export const payboostTileBoostReadyBody = "We ran all your numbers and are ready to go with your PayBoost."
// "See Your PayBoost"
export const payboostTileBoostReadyButton = "See Your PayBoost"
// "Your PayBoost is ready!"
export const payboostTileBoostReadyTitle = "Your PayBoost is ready!"
// "Continue W-4"
export const payboostTileContinueW4Cta = "Continue W-4"
// "See Your PayBoost"
export const payboostTileWaitingForBoostButton = "See Your PayBoost"
// "5 minutes"
export const payboostTileWaitingForBoostEstimate = "5 minutes"
// "Your PayBoost projection will be ready in about"
export const payboostTileWaitingForBoostTitle = "Your PayBoost projection will be ready in about"
// "Continue"
export const payboostUploadDocsContinueButton = "Continue"
// "Sending documents failed, please try again"
export const payboostUploadDocsDefaultErrorMessage = "Sending documents failed, please try again"
// "Confirm"
export const payboostUploadDocsDeleteConfirmButton = "Confirm"
// "Delete the following?"
export const payboostUploadDocsDeleteTitle = "Delete the following?"
// "Your file is too large, please upload a document that’s less than {{number}}MB"
export const payboostUploadDocsFileSizeLimitTitle = "Your file is too large, please upload a document that’s less than {{number}}MB"
// "Please select any of the following you would like to upload documents for"
export const payboostUploadDocsOptionsSubtitle = "Please select any of the following you would like to upload documents for"
// "Let us give you the best PayBoost!"
export const payboostUploadDocsOptionsTitle = "Let us give you the best PayBoost!"
// "No"
export const payboostUploadDocsRetryNoButton = "No"
// "Would you like to retry uploading?"
export const payboostUploadDocsRetryTitle = "Would you like to retry uploading?"
// "Yes"
export const payboostUploadDocsRetryYesButton = "Yes"
// "Skip"
export const payboostUploadDocsSkipButton = "Skip"
// "No, go back"
export const payboostUploadDocsSkipNoButton = "No, go back"
// "By skipping you agree that you do not need to upload any documents"
export const payboostUploadDocsSkipWarningTitle = "By skipping you agree that you do not need to upload any documents"
// "Yes, continue"
export const payboostUploadDocsSkipYesButton = "Yes, continue"
// "Start Uploading"
export const payboostUploadDocsStartUploadingTitle = "Start Uploading"
// "Take a Picture"
export const payboostUploadDocsTakePictureButton = "Take a Picture"
// "Upload from Files"
export const payboostUploadDocsUploadFilesButton = "Upload from Files"
// "Upload from Photos"
export const payboostUploadDocsUploadPhotosButton = "Upload from Photos"
// "View All Uploaded Documents"
export const payboostUploadDocsViewAllDocsButton = "View All Uploaded Documents"
// "Continue"
export const payboostW4DataCaptureCTA = "Continue"
// "Legal First Name"
export const payboostW4DataCaptureFirstName = "Legal First Name"
// "We’re generating your updated W-4"
export const payboostW4DataCaptureGeneratingW4 = "We’re generating your updated W-4"
// "Legal Last Name"
export const payboostW4DataCaptureLastName = "Legal Last Name"
// "All of your information is securely stored so no need to worry."
export const payboostW4DataCaptureReassurance = "All of your information is securely stored so no need to worry."
// "Social Security Number"
export const payboostW4DataCaptureSSN = "Social Security Number"
// "Grid uses this information to make sure no one but you can request an advance under your name."
export const payboostW4DataCaptureSSNBottomSheetDescription = "Grid uses this information to make sure no one but you can request an advance under your name."
// "For your privacy:"
export const payboostW4DataCaptureSSNBottomSheetSubtitle = "For your privacy:"
// "Why do we need your Social Security Number?"
export const payboostW4DataCaptureSSNBottomSheetTitle = "Why do we need your Social Security Number?"
// "Why do you need my SSN?"
export const payboostW4DataCaptureSSNQuestion = "Why do you need my SSN?"
// "City"
export const payboostW4DataCaptureStreetAddressCity = "City"
// "Street Address"
export const payboostW4DataCaptureStreetAddressOne = "Street Address"
// "State"
export const payboostW4DataCaptureStreetAddressState = "State"
// "Apartment, suite, etc."
export const payboostW4DataCaptureStreetAddressTwo = "Apartment, suite, etc."
// "Zip Code"
export const payboostW4DataCaptureStreetAddressZip = "Zip Code"
// "We need some personal information to create your W-4"
export const payboostW4DataCaptureTitle = "We need some personal information to create your W-4"
// "Sign Document"
export const payboostW4ReviewButton = "Sign Document"
// "What is this?"
export const payboostW4ReviewMoreInfoCta = "What is this?"
// "Your Updated W-4"
export const payboostW4ReviewTitle = "Your Updated W-4"
// "Clear Signature"
export const payboostW4SignatureClear = "Clear Signature"
// "Your document is being finalized"
export const payboostW4SignatureDocumentCreationInProgress = "Your document is being finalized"
// "Draw your Signature"
export const payboostW4SignatureHeader = "Draw your Signature"
// "Sign Document"
export const payboostW4SignatureSignButton = "Sign Document"
// "Please Sign Below"
export const payboostW4SignatureTitle = "Please Sign Below"
// "Grid Plus Subscription"
export const paymentHistoryRowGridPlusTitle = "Grid Plus Subscription"
// "Overdue"
export const paymentHistoryRowOverdue = "Overdue"
// "Pay Now"
export const paymentHistoryRowPayNowCTA = "Pay Now"
// "Due {{date}}"
export const paymentHistoryRowStateOpen = "Due {{date}}"
// "Due {{due_date}} (Paid {{payment_date}})"
export const paymentHistoryRowStatePaid = "Due {{due_date}} (Paid {{payment_date}})"
// "Due {{date}} (Overdue)"
export const paymentHistoryRowStateUnpaid = "Due {{date}} (Overdue)"
// "Grid Card"
export const paymentMethodGridCardName = "Grid Card"
// "123"
export const paymentsCardFormFieldCvcPlaceholder = "123"
// "CVV"
export const paymentsCardFormFieldCvcTitle = "CVV"
// "MM/YY"
export const paymentsCardFormFieldExpirationPlaceholder = "MM/YY"
// "Expiration"
export const paymentsCardFormFieldExpirationTitle = "Expiration"
// "XXXX XXXX XXXX XXXX"
export const paymentsCardFormFieldNumberPlaceholder = "XXXX XXXX XXXX XXXX"
// "Card Number"
export const paymentsCardFormFieldNumberTitle = "Card Number"
// "12345"
export const paymentsCardFormFieldZipPlaceholder = "12345"
// "ZIP"
export const paymentsCardFormFieldZipTitle = "ZIP"
// "Save"
export const paymentsMethodsAddButtonSave = "Save"
// "Add Card"
export const paymentsMethodsAddTitle = "Add Card"
// "Cancel"
export const paymentsMethodsDeleteMethodAlertActionCancel = "Cancel"
// "Delete"
export const paymentsMethodsDeleteMethodAlertActionDelete = "Delete"
// "Deleting payment method…"
export const paymentsMethodsDeleteMethodAlertLoadingText = "Deleting payment method…"
// "Delete payment method?"
export const paymentsMethodsDeleteMethodAlertMessage = "Delete payment method?"
// "Payment Method"
export const paymentsMethodsDeleteMethodAlertTitle = "Payment Method"
// "Add card"
export const paymentsMethodsListScreenAddCard = "Add card"
// "Add Debit Card"
export const paymentsMethodsListScreenAddDebitCard = "Add Debit Card"
// "Saved Cards"
export const paymentsMethodsListScreenSavedCards = "Saved Cards"
// "Payment Method"
export const paymentsMethodsListScreenTitle = "Payment Method"
// "amex"
export const paymentsMethodsRowAmex = "amex"
// "Card Number"
export const paymentsMethodsRowCardNumber = "Card Number"
// "discover"
export const paymentsMethodsRowDiscover = "discover"
// "Expiration Date"
export const paymentsMethodsRowExpirationDate = "Expiration Date"
// "This card is set as your default"
export const paymentsMethodsRowIsDefault = "This card is set as your default"
// "mastercard"
export const paymentsMethodsRowMastercard = "mastercard"
// "Payment Method"
export const paymentsMethodsRowPaymentMethod = "Payment Method"
// "Remove account"
export const paymentsMethodsRowRemove = "Remove account"
// "Set this as your default payment method"
export const paymentsMethodsRowSetDefault = "Set this as your default payment method"
// "visa"
export const paymentsMethodsRowVisa = "visa"
// "Apple Pay"
export const paymentsMethodsTitleApplePay = "Apple Pay"
// "You’ll be billed automatically when you start your subscription."
export const paymentsSettingsPaymentHistoryEmptyStateMessage = "You’ll be billed automatically when you start your subscription."
// "This is the end of your payment history."
export const paymentsSettingsPaymentHistoryFooterMessage = "This is the end of your payment history."
// "Payment History"
export const paymentsSettingsPaymentHistorytitle = "Payment History"
// "Add Payment Method"
export const paymentsSettingsRowAddpaymentmethod = "Add Payment Method"
// "Payment Methods"
export const paymentsSettingsRowPaymentmethod = "Payment Methods"
// "Payment"
export const paymentsSettingsTitle = "Payment"
// "Pending"
export const paymentsTagDraft = "Pending"
// "Unpaid"
export const paymentsTagOpen = "Unpaid"
// "Paid"
export const paymentsTagPaid = "Paid"
// "Unpaid"
export const paymentsTagUncollectible = "Unpaid"
// "Great! We’ve successfully imported your W-4 from {{provider_name}}."
export const payrollConnectAcknowledgeFirstSuccessQuestion = "Great! We’ve successfully imported your W-4 from {{provider_name}}."
// "Great! We’ve successfully imported your W-4."
export const payrollConnectAcknowledgeFirstSuccessQuestionFallback = "Great! We’ve successfully imported your W-4."
// "No, I have no other jobs"
export const payrollConnectComponentAskForASecondButtonNo = "No, I have no other jobs"
// "Yes, add another job"
export const payrollConnectComponentAskForASecondButtonYes = "Yes, add another job"
// "It’s important for us to have a full picture of your employment to get you the best PayBoost. Do you have any other jobs?"
export const payrollConnectComponentAskForASecondQuestion = "It’s important for us to have a full picture of your employment to get you the best PayBoost. Do you have any other jobs?"
// "No, I have no other jobs"
export const payrollConnectComponentAskForAnotherButtonNo = "No, I have no other jobs"
// "Yes, add another job"
export const payrollConnectComponentAskForAnotherButtonYes = "Yes, add another job"
// "Do you have any other jobs?"
export const payrollConnectComponentAskForAnotherQuestion = "Do you have any other jobs?"
// "Skip for now"
export const payrollConnectComponentAskForCredentialsButtonNo = "Skip for now"
// "Connect payroll account"
export const payrollConnectComponentAskForCredentialsButtonYes = "Connect payroll account"
// "We need more details for the jobs you told us about. You can add that info now or we’ll follow up in your monthly check-in."
export const payrollConnectComponentAskForCredentialsQuestion = "We need more details for the jobs you told us about. You can add that info now or we’ll follow up in your monthly check-in."
// "Continue"
export const payrollConnectComponentDataEntryRequiredButton = "Continue"
// "You’re all done getting your account set up. By enabling notification permissions you will never miss important updates on your account."
export const payrollConnectComponentDataEntryRequiredConclusionBody = "You’re all done getting your account set up. By enabling notification permissions you will never miss important updates on your account."
// "Not now"
export const payrollConnectComponentDataEntryRequiredConclusionSkipText = "Not now"
// "Congratulations!"
export const payrollConnectComponentDataEntryRequiredConclusionTitle = "Congratulations!"
// "Continue"
export const payrollConnectComponentDataEntryRequiredIntroButtonContinue = "Continue"
// "Find your latest paystub"
export const payrollConnectComponentDataEntryRequiredIntroTextBullet1 = "Find your latest paystub"
// "Take a picture or upload it"
export const payrollConnectComponentDataEntryRequiredIntroTextBullet2 = "Take a picture or upload it"
// "Verify your paystub information"
export const payrollConnectComponentDataEntryRequiredIntroTextBullet3 = "Verify your paystub information"
// "Grid makes it easy to get more out of your paycheck."
export const payrollConnectComponentDataEntryRequiredIntroTextMain = "Grid makes it easy to get more out of your paycheck."
// "Grid"
export const payrollConnectComponentDataEntryRequiredIntroTitle = "Grid"
// "Seems like we can’t connect with your {{provider_name}} account. Please select ’Continue’ to upload your paystub manually."
export const payrollConnectComponentDataEntryRequiredQuestion = "Seems like we can’t connect with your {{provider_name}} account. Please select ’Continue’ to upload your paystub manually."
// "To get your PayBoost set up, we need to know the numbers on your last paystub. In the next step, we’ll show you where to get them in Square Team app."
export const payrollConnectComponentDataEntryRequiredSkipCredentialsQuestion = "To get your PayBoost set up, we need to know the numbers on your last paystub. In the next step, we’ll show you where to get them in Square Team app."
// "Continue"
export const payrollConnectComponentLoadingButton = "Continue"
// "Grid keeps an eye on your income and updates your tax withholding each month by connecting with your payroll provider. Tap continue to link your payroll account."
export const payrollConnectComponentLoadingQuestion = "Grid keeps an eye on your income and updates your tax withholding each month by connecting with your payroll provider. Tap continue to link your payroll account."
// "Continue"
export const payrollConnectComponentReadyToAdvanceButton = "Continue"
// "Great! If you have no other jobs, we’ll continue to other sources of income."
export const payrollConnectComponentReadyToAdvanceQuestion = "Great! If you have no other jobs, we’ll continue to other sources of income."
// "Continue"
export const payrollConnectComponentWaitingOnFirstConnectionButton = "Continue"
// "Grid keeps an eye on your income and updates your tax withholding each month by connecting with your payroll provider. Tap continue to link your payroll account."
export const payrollConnectComponentWaitingOnFirstConnectionQuestion = "Grid keeps an eye on your income and updates your tax withholding each month by connecting with your payroll provider. Tap continue to link your payroll account."
// "Add another job"
export const payrollConnectComponentWaitingOnFirstGoodConnectionButton = "Add another job"
// "Grid keeps an eye on your income and updates your tax withholding each month by connecting with your payroll provider. Tap continue to link your payroll account."
export const payrollConnectComponentWaitingOnFirstGoodConnectionQuestion = "Grid keeps an eye on your income and updates your tax withholding each month by connecting with your payroll provider. Tap continue to link your payroll account."
// "Continue"
export const payrollConnectIntroButtonContinue = "Continue"
// "Don’t know who your provider is?"
export const payrollConnectIntroButtonDontKnow = "Don’t know who your provider is?"
// "A payroll provider is a company (like ADP, Paychex, Workday, etc) that your job uses to manage and track your paycheck."
// ""
// "If you don’t know the name of your payroll provider, please contact your manager or HR department."
// ""
// "Grid needs this information so that we can maximize your PayBoost."
export const payrollConnectIntroHelpMessage = "A payroll provider is a company (like ADP, Paychex, Workday, etc) that your job uses to manage and track your paycheck.\u000A\u000AIf you don’t know the name of your payroll provider, please contact your manager or HR department.\u000A\u000AGrid needs this information so that we can maximize your PayBoost."
// "Who Is My Payroll Provider?"
export const payrollConnectIntroHelpTitle = "Who Is My Payroll Provider?"
// "Add the job where you make most of your money first."
export const payrollConnectIntroTextBullet1 = "Add the job where you make most of your money first."
// "Login with your payroll account credentials"
export const payrollConnectIntroTextBullet2 = "Login with your payroll account credentials"
// "We’ll send you a new W-4 with your updated tax withholding info"
export const payrollConnectIntroTextBullet3 = "We’ll send you a new W-4 with your updated tax withholding info"
// "Grid links to your payroll provider to automate your PayBoost."
export const payrollConnectIntroTextMain = "Grid links to your payroll provider to automate your PayBoost."
// "Payroll Connect"
export const payrollConnectIntroTitle = "Payroll Connect"
// "Company ID"
export const payrollConnectPaylocityCompanyIdentifierFieldPlaceholder = "Company ID"
// "Company ID"
export const payrollConnectPaylocityCompanyIdentifierFieldTitle = "Company ID"
// "Company ID"
export const payrollConnectSandboxCompanyIdentifierFieldPlaceholder = "Company ID"
// "Company ID"
export const payrollConnectSandboxCompanyIdentifierFieldTitle = "Company ID"
// "Login URL"
export const payrollConnectSandboxCompanyLoginURLFieldPlaceholder = "Login URL"
// "Login URL"
export const payrollConnectSandboxCompanyLoginURLFieldTitle = "Login URL"
// "your-url"
export const payrollConnectUltiproCompanyLoginUrlFieldBase = "your-url"
// "Company login URL"
export const payrollConnectUltiproCompanyLoginUrlFieldPlaceholder = "Company login URL"
// ".ultipro.com"
export const payrollConnectUltiproCompanyLoginUrlFieldPostfix = ".ultipro.com"
// "Company Login URL"
export const payrollConnectUltiproCompanyLoginUrlFieldTitle = "Company Login URL"
// "It looks like you don’t have any payroll connections with working credentials. At least one is required."
export const payrollConnectionAtLeastOneValidConnectionIsRequired = "It looks like you don’t have any payroll connections with working credentials. At least one is required."
// "Your payroll account is not available right now. Please log in online to resolve the issue. If you are able to log in, please come back and try again."
export const payrollConnectionStatusAccountBlockedErrorText = "Your payroll account is not available right now. Please log in online to resolve the issue. If you are able to log in, please come back and try again."
// "Your username and password will expire soon. Please log in to your payroll portal to update your password."
export const payrollConnectionStatusCredentialExpirationPendingErrorText = "Your username and password will expire soon. Please log in to your payroll portal to update your password."
// "Your username and password have expired. Please log in to your payroll portal to update your password."
export const payrollConnectionStatusCredentialsExpiredErrorText = "Your username and password have expired. Please log in to your payroll portal to update your password."
// "Your username and password are not valid. Please login to your payroll portal online to confirm your credentials and try again."
export const payrollConnectionStatusCredentialsInvalidErrorText = "Your username and password are not valid. Please login to your payroll portal online to confirm your credentials and try again."
// "Credentials were not provided"
export const payrollConnectionStatusCredentialsNotProvidedErrorText = "Credentials were not provided"
// "Credentials are not testable"
export const payrollConnectionStatusCredentialsNotTestableErrorText = "Credentials are not testable"
// "The provided username, password, or UltiPro URL is not valid. Please log into your payroll portal to confirm your credentials and try again."
// "Note that the URL must match your company’s UltiPro URL and be properly formatted as ‘company.ultipro.com’."
export const payrollConnectionStatusUltiproCredentialsInvalidErrorText = "The provided username, password, or UltiPro URL is not valid. Please log into your payroll portal to confirm your credentials and try again.\u000ANote that the URL must match your company’s UltiPro URL and be properly formatted as ‘company.ultipro.com’."
// "Feel free to close the app and come back later. All your info will be saved."
export const payrollCredentialsAlertWaitingBody = "Feel free to close the app and come back later. All your info will be saved."
// "This will take a few minutes…"
export const payrollCredentialsAlertWaitingTitle = "This will take a few minutes…"
// "Continue…"
export const payrollCredentialsButtonPasswordConfirmationButton = "Continue…"
// "No problem. Retrieve your user ID or reset your password with {{institution_name}}, and then come back here when you’re done!"
export const payrollCredentialsButtonPasswordConfirmationMessage = "No problem. Retrieve your user ID or reset your password with {{institution_name}}, and then come back here when you’re done!"
// "your payroll provider"
export const payrollCredentialsButtonPasswordConfirmationMessageInstitutionNameFallback = "your payroll provider"
// "Forgot your user ID or password?"
export const payrollCredentialsButtonPasswordConfirmationTitle = "Forgot your user ID or password?"
// "Forgot User ID/Password"
export const payrollCredentialsButtonPasswordReset = "Forgot User ID/Password"
// "Submit"
export const payrollCredentialsButtonSubmit = "Submit"
// "Continue"
export const payrollCredentialsChallengeQButton = "Continue"
// "Select one"
export const payrollCredentialsChallengeQChooseOnePlaceholder = "Select one"
// "Security challenge was unsuccessful."
export const payrollCredentialsChallengeQError = "Security challenge was unsuccessful."
// "Answer"
export const payrollCredentialsChallengeQPlaceholder = "Answer"
// "Challenge Question"
export const payrollCredentialsChallengeQTitle = "Challenge Question"
// "Chat with a Payroll Specialist Now"
export const payrollCredentialsErrorButtonChat = "Chat with a Payroll Specialist Now"
// "Continue"
export const payrollCredentialsErrorButtonContinue = "Continue"
// "Try Again"
export const payrollCredentialsErrorButtonRetry = "Try Again"
// "We couldn’t establish a successful link. Please try entering your login credentials one more time…"
export const payrollCredentialsErrorLabelDefaultFailure = "We couldn’t establish a successful link. Please try entering your login credentials one more time…"
// "We couldn’t establish a successful link."
export const payrollCredentialsErrorLabelDefaultRetry = "We couldn’t establish a successful link."
// "Password"
export const payrollCredentialsFieldPasswordPlaceholder = "Password"
// "Username"
export const payrollCredentialsFieldUsernamePlaceholder = "Username"
// "A Grid Account Professional will use these credentials to securely connect to your payroll provider."
export const payrollCredentialsFinePrintManualProvider = "A Grid Account Professional will use these credentials to securely connect to your payroll provider."
// "Continue"
export const payrollCredentialsSuccessButtonContinue = "Continue"
// "Your account was successfully linked to Grid."
export const payrollCredentialsSuccessLabelInfo = "Your account was successfully linked to Grid."
// "Answer security challenge"
export const payrollCredentialsTitleChallenge = "Answer security challenge"
// "Failure"
export const payrollCredentialsTitleChallengeError = "Failure"
// "Failure"
export const payrollCredentialsTitleFailure = "Failure"
// "Loading…"
export const payrollCredentialsTitleLoading = "Loading…"
// "Enter your credentials"
export const payrollCredentialsTitleReady = "Enter your credentials"
// "Success"
export const payrollCredentialsTitleSuccess = "Success"
// "Continue"
export const payrollJobNameButtonContinue = "Continue"
// "What do you want to call your job?"
export const payrollJobNameQuestionDefault = "What do you want to call your job?"
// "What do you want to call your fifth job?"
export const payrollJobNameQuestionFifth = "What do you want to call your fifth job?"
// "What do you want to call your first job?"
export const payrollJobNameQuestionFirst = "What do you want to call your first job?"
// "If you have more than one job, you’ll be able to add more later"
export const payrollJobNameQuestionFirstSubtext = "If you have more than one job, you’ll be able to add more later"
// "What do you want to call your fourth job?"
export const payrollJobNameQuestionFourth = "What do you want to call your fourth job?"
// "What do you want to call your next job?"
export const payrollJobNameQuestionNext = "What do you want to call your next job?"
// "What do you want to call your second job?"
export const payrollJobNameQuestionSecond = "What do you want to call your second job?"
// "What do you want to call your third job?"
export const payrollJobNameQuestionThird = "What do you want to call your third job?"
// "Acme Co."
export const payrollJobNameTextFieldPlaceholder = "Acme Co."
// "Payroll Connect"
export const payrollJobNameTitle = "Payroll Connect"
// "Continue"
export const payrollPaystubUploadButtonLabelContinue = "Continue"
// "You’ll need your latest paystub to fill out some numbers"
export const payrollPaystubUploadDescription = "You’ll need your latest paystub to fill out some numbers"
// "Go to paystub"
export const payrollPaystubUploadLatestPaystubButtonLabel = "Go to paystub"
// "Step 1"
export const payrollPaystubUploadStepOne = "Step 1"
// "Go to paystub"
export const payrollPaystubUploadStepOneButtonLabel = "Go to paystub"
// "View paystub"
export const payrollPaystubUploadStepOneButtonLabelDone = "View paystub"
// "Download your last paystub in Square Team"
export const payrollPaystubUploadStepOneDescripton = "Download your last paystub in Square Team"
// "Step 3"
export const payrollPaystubUploadStepThree = "Step 3"
// "Fill out your income information"
export const payrollPaystubUploadStepThreeDescripton = "Fill out your income information"
// "Step 2"
export const payrollPaystubUploadStepTwo = "Step 2"
// "Upload your paystub in Grid"
export const payrollPaystubUploadStepTwoDescripton = "Upload your paystub in Grid"
// "Upload your paystub"
export const payrollPaystubUploadTitle = "Upload your paystub"
// "Other provider"
export const payrollProviderButtonOtherProvider = "Other provider"
// "Need help?"
export const payrollProviderHelp = "Need help?"
// "No"
export const payrollProviderIntroButtonsNo = "No"
// "Yes"
export const payrollProviderIntroButtonsYes = "Yes"
// "Do you know your payroll provider?"
export const payrollProviderIntroQuestion = "Do you know your payroll provider?"
// "These are the most common providers:"
export const payrollProviderIntroSubtext = "These are the most common providers:"
// "Payroll Connect"
export const payrollProviderIntroTitle = "Payroll Connect"
// "Connect us with your payroll provider and we’ll update your account each month to maximize your PayBoost."
export const payrollProviderLabelSubtitle = "Connect us with your payroll provider and we’ll update your account each month to maximize your PayBoost."
// "Connect your {{job_name}} payroll provider"
export const payrollProviderLabelTitle = "Connect your {{job_name}} payroll provider"
// "Continue"
export const payrollProviderNameButtonContinue = "Continue"
// "Payroll provider"
export const payrollProviderNameTextFieldPlaceholder = "Payroll provider"
// "What is your provider called?"
export const payrollProviderNameTextQuestion = "What is your provider called?"
// "Search through hundreds of payroll providers"
export const payrollProviderSearchBlankTextInfo = "Search through hundreds of payroll providers"
// "Search for your payroll provider"
export const payrollProviderSearchInstructionsTextPrimary = "Search for your payroll provider"
// "Link your payroll provider and we’ll update your account monthly to maximize your PayBoost."
export const payrollProviderSearchInstructionsTextSecondary = "Link your payroll provider and we’ll update your account monthly to maximize your PayBoost."
// "Add provider manually"
export const payrollProviderSearchNoResultsButtonAddProvider = "Add provider manually"
// "No results."
export const payrollProviderSearchNoResultsText = "No results."
// "Payroll Connect"
export const payrollProviderTitle = "Payroll Connect"
// "Authenticate to change your app lock settings."
export const profileAppLockReason = "Authenticate to change your app lock settings."
// "Grid Card"
export const profileBoostcardSettingsTitle = "Grid Card"
// "Available after signup."
export const profileCell2FADisabledSubtitle = "Available after signup."
// "2-Step Verification"
export const profileCell2FATitle = "2-Step Verification"
// "{{biometry_type}}"
export const profileCellBiometryTitle = "{{biometry_type}}"
// "Notifications"
export const profileCellNotificationsTitle = "Notifications"
// "Sign Out"
export const profileCellSignOut = "Sign Out"
// "Update {{setting}}"
export const profileEditButtonSave = "Update {{setting}}"
// "Email"
export const profileEditEmailTitle = "Email"
// "Legal first Name"
export const profileEditFirstNameTitle = "Legal first Name"
// "Legal last Name"
export const profileEditLastNameTitle = "Legal last Name"
// "Please go back and continue onboarding. You’ll be able to update your {{field}} here later on."
export const profileEditOnboardalertMessage = "Please go back and continue onboarding. You’ll be able to update your {{field}} here later on."
// "Some things are worth waiting for"
export const profileEditOnboardalertTitle = "Some things are worth waiting for"
// "New Password"
export const profileEditPasswordTitle = "New Password"
// "Phone"
export const profileEditPhoneTitle = "Phone"
// "Add new payroll connection"
export const profileLinkedAccountsAddNewConnection = "Add new payroll connection"
// "RENEW LINK"
export const profileLinkedAccountsRenewLink = "RENEW LINK"
// "How long do you want to pause for?"
export const profilePauseAlertMessage = "How long do you want to pause for?"
// "1 month"
export const profilePauseAlertOption1Month = "1 month"
// "3 months"
export const profilePauseAlertOption3Month = "3 months"
// "Cancel"
export const profilePauseAlertOptionCancel = "Cancel"
// "Indefinitely"
export const profilePauseAlertOptionIndefinitely = "Indefinitely"
// ""
export const profilePauseAlertTitle = ""
// "Day"
export const profilePaymentplanIntervalDay = "Day"
// "Invoice"
export const profilePaymentplanIntervalFallback = "Invoice"
// "Month"
export const profilePaymentplanIntervalMonth = "Month"
// "Once"
export const profilePaymentplanIntervalOnce = "Once"
// "Week"
export const profilePaymentplanIntervalWeek = "Week"
// "Year"
export const profilePaymentplanIntervalYear = "Year"
// "Close account"
export const profileRowAccountClose = "Close account"
// "Grid Card Banking"
export const profileRowAccountsBoostCard = "Grid Card Banking"
// "Active"
export const profileRowAccountsBoostCardStateActive = "Active"
// "Blocked"
export const profileRowAccountsBoostCardStateBlocked = "Blocked"
// "Closed"
export const profileRowAccountsBoostCardStateClosed = "Closed"
// "Setup"
export const profileRowAccountsBoostCardStateSetup = "Setup"
// "Accounts"
export const profileRowAccountsHeader = "Accounts"
// "Email"
export const profileRowEmail = "Email"
// "verified"
export const profileRowEmailVerified = "verified"
// "Legal first name"
export const profileRowFirstName = "Legal first name"
// "Legal last name"
export const profileRowLastName = "Legal last name"
// "Subscription"
export const profileRowMembershipHeader = "Subscription"
// "Grid"
export const profileRowMembershipLevelGridBasic = "Grid"
// "Grid"
export const profileRowMembershipLevelGridNone = "Grid"
// "Grid Plus"
export const profileRowMembershipLevelGridPlus = "Grid Plus"
// "Password"
export const profileRowPassword = "Password"
// "Phone"
export const profileRowPhone = "Phone"
// "verified"
export const profileRowPhoneVerified = "verified"
// "Your plan"
export const profileRowSubscription = "Your plan"
// "Subscription"
export const profileRowSubscriptionHeader = "Subscription"
// "Daily"
export const profileRowSubscriptionIntervalDay = "Daily"
// "Monthly"
export const profileRowSubscriptionIntervalMonth = "Monthly"
// "Once"
export const profileRowSubscriptionIntervalOnce = "Once"
// "Weekly"
export const profileRowSubscriptionIntervalWeek = "Weekly"
// "Annually"
export const profileRowSubscriptionIntervalYear = "Annually"
// "Pause subscription"
export const profileRowSubscriptionPause = "Pause subscription"
// "Turning this on will put your Grid account on hold. Don’t worry, we’ll keep all your information handy for when you come back!"
export const profileRowSubscriptionPauseDescription = "Turning this on will put your Grid account on hold. Don’t worry, we’ll keep all your information handy for when you come back!"
// "Your Grid subscription is paused for another {{days}} days."
export const profileRowSubscriptionPauseDescriptionFinite = "Your Grid subscription is paused for another {{days}} days."
// "Your Grid subscription is currently paused."
export const profileRowSubscriptionPauseDescriptionIndefinite = "Your Grid subscription is currently paused."
// "Re-activate subscription"
export const profileRowSubscriptionResume = "Re-activate subscription"
// "Linked Accounts"
export const profileSectionHeaderLinkedAccounts = "Linked Accounts"
// "Privacy"
export const profileSectionHeaderPrivacy = "Privacy"
// "Security"
export const profileSectionHeaderSecurity = "Security"
// "Your {{ year }} Stats"
export const profileStatsHeader = "Your {{ year }} Stats"
// "Your Stats"
export const profileStatsHeaderDefault = "Your Stats"
// "Billing History"
export const profileSubscriptionBillingHistoryTitle = "Billing History"
// "Cancel Subscription"
export const profileSubscriptionDescriptionCancelSubscriptionCTA = "Cancel Subscription"
// "Cancel Subscription"
export const profileSubscriptionDescriptionCloseAccountCTA = "Cancel Subscription"
// "Delete Account"
export const profileSubscriptionDescriptionDeleteAccountCTA = "Delete Account"
// "{{amt}} / month"
export const profileSubscriptionDescriptionPrice = "{{amt}} / month"
// "$10/month"
export const profileSubscriptionGridPlusPrice = "$10/month"
// "Your Grid+ subscription ($10 / month) will start when your advance gets delivered to your account."
export const profileSubscriptionGridPlusPriceExplanation = "Your Grid+ subscription ($10 / month) will start when your advance gets delivered to your account."
// "FREE Subscription"
export const profileSubscriptionGridPrice = "FREE Subscription"
// "FREE"
export const profileSubscriptionInvoiceFreeAmount = "FREE"
// "Grid Plus Subscription"
export const profileSubscriptionInvoiceTitle = "Grid Plus Subscription"
// "Overdue"
export const profileSubscriptionOverdue = "Overdue"
// "Overdue Payments"
export const profileSubscriptionOverduePaymentsTitle = "Overdue Payments"
// "Next payment on"
export const profileSubscriptionRenewsAt = "Next payment on"
// "Subscription"
export const profileSubscrpitionDescriptionMembershipRowTitle = "Subscription"
// "Your Subscription"
export const profileSubscrpitionDescriptionTitle = "Your Subscription"
// "Settings"
export const profileTitle = "Settings"
// "PayBoost"
export const purchaseHistoryOneTimePayboost = "PayBoost"
// "PayBoost"
export const purchaseHistoryOneTimePayboost60Pct = "PayBoost"
// "PayBoost"
export const purchaseHistoryOneTimePayboost80Pct = "PayBoost"
// "PayBoost Subscription"
export const purchaseHistorySubscriptionTitle = "PayBoost Subscription"
// "Grid Services"
export const purchaseHistoryUnknownProduct = "Grid Services"
// "Enable"
export const pushNotificationsInvitationScreenCTATitle = "Enable"
// "Not Now"
export const pushNotificationsInvitationScreenLinkCTATitle = "Not Now"
// "Turn on push notifications to get important updates on your Grid account."
export const pushNotificationsInvitationScreenSubtitle = "Turn on push notifications to get important updates on your Grid account."
// "Stay in the know"
export const pushNotificationsInvitationScreenTitle = "Stay in the know"
// "VERIFICATION REQUIREMENTS"
export const qualificationCriteriaIdv = "VERIFICATION REQUIREMENTS"
// "All corners of ID visible"
export const qualificationCriteriaIdvAllCornersVisible = "All corners of ID visible"
// "To verify your identiy please upload a valid ID."
export const qualificationCriteriaIdvContentSubtitle = "To verify your identiy please upload a valid ID."
// "VERIFICATION REQUIREMENTS"
export const qualificationCriteriaIdvContentTitle = "VERIFICATION REQUIREMENTS"
// "Upload ID"
export const qualificationCriteriaIdvCta = "Upload ID"
// "Face and name visible"
export const qualificationCriteriaIdvFaceAndNameVisible = "Face and name visible"
// "High resolution and color"
export const qualificationCriteriaIdvHighResolutionAndColor = "High resolution and color"
// "ID Requirements"
export const qualificationCriteriaIdvTableHeader = "ID Requirements"
// "Verification"
export const qualificationCriteriaIdvTitle = "Verification"
// "Valid non-expired document"
export const qualificationCriteriaIdvValidNonExpiredDocument = "Valid non-expired document"
// "Grid helps you access your tax refund early."
// "Access up to $250 / month with PayBoost!"
export const referralProgramWebPickupDescription = "Grid helps you access your tax refund early.\u000AAccess up to $250 / month with PayBoost!"
// "Email"
export const referralProgramWebPickupEmailInputLabel = "Email"
// "Email"
export const referralProgramWebPickupEmailInputPlaceholder = "Email"
// "Download and Claim"
export const referralProgramWebPickupSubmitEmailButton = "Download and Claim"
// "You successfully"
// "claimed your free"
// "month!"
export const referralProgramWebPickupSuccess = "You successfully\u000Aclaimed your free\u000Amonth!"
// "Register your account with the same email you provided us to make sure your referral is applied."
export const referralProgramWebPickupSuccessHelp = "Register your account with the same email you provided us to make sure your referral is applied."
// "Download the app to get started."
export const referralProgramWebPickupSuccessSubtitle = "Download the app to get started."
// "{{firstName}} just hooked"
// "you up with 1 free"
// "month with Grid."
export const referralProgramWebPickupTitle = "{{firstName}} just hooked\u000Ayou up with 1 free\u000Amonth with Grid."
// "You signed up with a referral code, giving you credit that will be used when you checkout!"
export const rewardsCheckoutReferred = "You signed up with a referral code, giving you credit that will be used when you checkout!"
// "Invite your friends and receive $5 for every successful referral."
export const rewardsReferralsBody = "Invite your friends and receive $5 for every successful referral."
// "How it Works"
export const rewardsReferralsButonHelp = "How it Works"
// "Share with friends"
export const rewardsReferralsButtonShare = "Share with friends"
// "When a friend subscribes to Grid, you’ll earn $5 towards your monthly fees."
// ""
// "To get started, tap the share button. You can share your personalized link however you want. Make sure your friend uses that link before signing up."
// ""
// "You’ll receive your referral bonus once your friend signs up for a paid subscription."
export const rewardsReferralsHelpMessage = "When a friend subscribes to Grid, you’ll earn $5 towards your monthly fees.\u000A\u000ATo get started, tap the share button. You can share your personalized link however you want. Make sure your friend uses that link before signing up.\u000A\u000AYou’ll receive your referral bonus once your friend signs up for a paid subscription."
// "How do referrals work?"
export const rewardsReferralsHelpTitle = "How do referrals work?"
// "Give $5, Get $5"
export const rewardsReferralsTitle = "Give $5, Get $5"
// "Your rewards will be applied to your subscription every month."
export const rewardsRewardsBody = "Your rewards will be applied to your subscription every month."
// "Your rewards"
export const rewardsRewardsCaption = "Your rewards"
// "Refer a friend"
export const rewardsTabReferrals = "Refer a friend"
// "Your rewards"
export const rewardsTabRewards = "Your rewards"
// "Invite Friends"
export const rewardsTitle = "Invite Friends"
// "We’ll be right back…"
export const sadfaceMessage = "We’ll be right back…"
// "Chat with us"
export const sadfaceVCChatCTA = "Chat with us"
// "Get support at hi@getgrid.app"
export const sadfaceVCChatUnavailableCTA = "Get support at hi@getgrid.app"
// "Something went wrong."
// "Please chat with us so we can fix it!"
export const sadfaceVCTitle = "Something went wrong.\u000APlease chat with us so we can fix it!"
// "Unlock your Grid app"
export const securityAuthReason = "Unlock your Grid app"
// "Face ID"
export const securityBiometryFaceid = "Face ID"
// "Passcode"
export const securityBiometryPasscode = "Passcode"
// "Touch ID"
export const securityBiometryTouchid = "Touch ID"
// "Unavailable"
export const securityBiometryUnknown = "Unavailable"
// "Grid Card helps rebuild your credit. For your protection, we need to verify your credit profile before we submit your card order."
export const setupCreditLineIntroBody = "Grid Card helps rebuild your credit. For your protection, we need to verify your credit profile before we submit your card order."
// "Grid Card helps rebuild your credit. For your protection, we need to verify your credit profile in order to continue."
export const setupCreditLineIntroBody2 = "Grid Card helps rebuild your credit. For your protection, we need to verify your credit profile in order to continue."
// "Continue"
export const setupCreditLineIntroCTA = "Continue"
// "This will not affect your credit score."
export const setupCreditLineIntroFineText = "This will not affect your credit score."
// "Set Up Credit Profile"
export const setupCreditLineIntroHeader = "Set Up Credit Profile"
// "Almost there!"
export const setupCreditLineIntroTitle = "Almost there!"
// "you@example.com"
export const sideMenuAnonymousUserEmail = "you@example.com"
// "AU"
export const sideMenuAnonymousUserInitials = "AU"
// "Anonymous User"
export const sideMenuAnonymousUserName = "Anonymous User"
// "Documents"
export const sideMenuDocuments = "Documents"
// "Terms of service"
export const sideMenuLegal = "Terms of service"
// "https://getgrid.app/terms/"
export const sideMenuLegalLink = "https://getgrid.app/terms/"
// "Linked Accounts"
export const sideMenuLinkedAccounts = "Linked Accounts"
// "Logout"
export const sideMenuLogout = "Logout"
// "Payment"
export const sideMenuPayment = "Payment"
// "Profile"
export const sideMenuProfile = "Profile"
// "Write a review"
export const sideMenuRating = "Write a review"
// "Invite friends"
export const sideMenuRewards = "Invite friends"
// "Settings"
export const sideMenuSettings = "Settings"
// "Support"
export const sideMenuSupport = "Support"
// "Bank Accounts"
export const sidebarConnectedAccountsBankAccountsHeader = "Bank Accounts"
// "Debit Cards"
export const sidebarConnectedAccountsDebitCardsHeader = "Debit Cards"
// "Link New Account"
export const sidebarConnectedAccountsLinkNewAccountButtonTitle = "Link New Account"
// "Link New Debit Card"
export const sidebarConnectedAccountsLinkNewDebitCardButtonTitle = "Link New Debit Card"
// "Payroll Accounts"
export const sidebarConnectedAccountsPayrollHeader = "Payroll Accounts"
// "Profile"
export const sidebarProfileMenuTtile = "Profile"
// "There has already a redemption request in processing. Didn’t request one this month? Let us know!"
export const statementCreditAlreadyProcessing = "There has already a redemption request in processing. Didn’t request one this month? Let us know!"
// "Sorry, we’re running into an issue redeeming your rewards. Please reach out to our support team for assistance."
export const statementCreditRedemptionNotPossible = "Sorry, we’re running into an issue redeeming your rewards. Please reach out to our support team for assistance."
// "https://static.getgrid.app/bank_institutions/institution_manifest.pb"
export const staticAssetBankInstitutionManifestUrl = "https://static.getgrid.app/bank_institutions/institution_manifest.pb"
// "check-in {{checkins_required}} more times"
export const streaksLevelCheckinsRequired = "check-in {{checkins_required}} more times"
// "check-in 1 more time"
export const streaksLevelCheckinsRequiredSingular = "check-in 1 more time"
// "estimated per year"
export const streaksLevelIntervalSubtext = "estimated per year"
// "Level 1"
export const streaksLevelOneTitle = "Level 1"
// "Level 3"
export const streaksLevelThreeTitle = "Level 3"
// "Level 2"
export const streaksLevelTwoTitle = "Level 2"
// "Your card does not support this type of purchase."
export const stripeCardErrorDeclineCodeCardNotSupported = "Your card does not support this type of purchase."
// "Your card has expired."
export const stripeCardErrorDeclineCodeExpiredCard = "Your card has expired."
// "Your card’s security code is incorrect."
export const stripeCardErrorDeclineCodeIncorrectCVC = "Your card’s security code is incorrect."
// "Your card number is incorrect."
export const stripeCardErrorDeclineCodeIncorrectNumber = "Your card number is incorrect."
// "The zip code you supplied failed validation."
export const stripeCardErrorDeclineCodeIncorrectZip = "The zip code you supplied failed validation."
// "Your card has insufficient funds."
export const stripeCardErrorDeclineCodeInsufficientFunds = "Your card has insufficient funds."
// "An error occurred while processing your card. Try again in a little bit."
export const stripeCardErrorDeclineCodeProcessingError = "An error occurred while processing your card. Try again in a little bit."
// "Your card was declined. Please try another card."
export const stripeCardErrorDefault = "Your card was declined. Please try another card."
// "Something went wrong."
export const stripeErrorDefault = "Something went wrong."
// "Your payment is currently clearing with our payments processor. This can take up to 5-7 business days."
export const stripeInvoicePendingError = "Your payment is currently clearing with our payments processor. This can take up to 5-7 business days."
// "https://getgrid.app/terms,https://stripe.com/connect-account/legal"
export const subscriptionPayNowAcceptTermsLinkAlgoQueryTermsAttribution = "https://getgrid.app/terms,https://stripe.com/connect-account/legal"
// "Select a Payment Method"
export const subscriptionPayNowAddPaymentMethodTitle = "Select a Payment Method"
// "By confirming your payment, you agree to Grid’s Terms as well as the Stripe Connect Terms."
export const subscriptionPayNowTerms = "By confirming your payment, you agree to Grid’s Terms as well as the Stripe Connect Terms."
// "Grid’s Terms,Stripe Connect Terms"
export const subscriptionPayNowTermsTermsLinkAlgoQuery = "Grid’s Terms,Stripe Connect Terms"
// "Now"
export const subscriptionsPayNowAmountFallback = "Now"
// "Card"
export const subscriptionsPayNowCardTitle = "Card"
// "+ Change payment method"
export const subscriptionsPayNowChangePaymentMethodCTA = "+ Change payment method"
// "Confirm"
export const subscriptionsPayNowContinueCTA = "Confirm"
// "Due Date"
export const subscriptionsPayNowDateTitle = "Due Date"
// "Default Card"
export const subscriptionsPayNowDefaultPaymentMethod = "Default Card"
// "Your card cannot be validated at this time. Please try another card."
export const subscriptionsPayNowErrorDescription = "Your card cannot be validated at this time. Please try another card."
// "Dismiss"
export const subscriptionsPayNowErrorDismissCTA = "Dismiss"
// "We can’t process your card"
export const subscriptionsPayNowErrorTitle = "We can’t process your card"
// "Make Payment"
export const subscriptionsPayNowMakePayment = "Make Payment"
// "Processing your payment…"
export const subscriptionsPayNowProcessing = "Processing your payment…"
// "Subscription Fee"
export const subscriptionsPayNowSubscriptionFee = "Subscription Fee"
// "Pay {{amt}}"
export const subscriptionsPayNowTitle = "Pay {{amt}}"
// "Total Due"
export const subscriptionsPayNowTotalDue = "Total Due"
// "Transfer from"
export const subscriptionsPayNowTransferFrom = "Transfer from"
// "Call us"
export const supportMenuCustomerServiceCall = "Call us"
// "1"
export const supportMenuCustomerSupportCloseHour = "1"
// "Monday - Friday"
export const supportMenuCustomerSupportDays = "Monday - Friday"
// "9am - 5pm PST"
export const supportMenuCustomerSupportHours = "9am - 5pm PST"
// "{{open}} - {{close}}"
export const supportMenuCustomerSupportHoursDynamic = "{{open}} - {{close}}"
// "16"
export const supportMenuCustomerSupportOpenHour = "16"
// "Customer Support Business Hours"
export const supportMenuCustomerSupportTitle = "Customer Support Business Hours"
// "Chat with Us"
export const supportMenuRowChatWithUs = "Chat with Us"
// "Customer Service"
export const supportMenuRowHeaderCustomerService = "Customer Service"
// "FAQ"
export const supportMenuRowHeaderFAQ = "FAQ"
// "Feedback"
export const supportMenuRowHeaderFeedback = "Feedback"
// "Get Help"
export const supportMenuRowHeaderGetHelp = "Get Help"
// "Legal"
export const supportMenuRowHeaderLegal = "Legal"
// "Grid Card"
export const supportMenuRowHeaderMonthlyStatements = "Grid Card"
// "Help Center"
export const supportMenuRowHelpCenter = "Help Center"
// "Agreements"
export const supportMenuRowTitleAgreements = "Agreements"
// "Close Grid Card Account"
export const supportMenuRowTitleBoostCardCloseAccount = "Close Grid Card Account"
// "Frequently Asked Questions"
export const supportMenuRowTitleCustomerFAQ = "Frequently Asked Questions"
// "Send us a message"
export const supportMenuRowTitleCustomerService = "Send us a message"
// "Write a review"
export const supportMenuRowTitleFeedback = "Write a review"
// "Terms of Service"
export const supportMenuRowTitleLegal = "Terms of Service"
// "Account Statements"
export const supportMenuRowTitleMonthlyStatements = "Account Statements"
// "https://getgrid.app/help"
export const supportMenuRowURLFAQ = "https://getgrid.app/help"
// "https://getgrid.zendesk.com/hc/en-us"
export const supportMenuRowURLHelpCenter = "https://getgrid.zendesk.com/hc/en-us"
// "https://getgrid.app/terms"
export const supportMenuRowURLLegal = "https://getgrid.app/terms"
// "Support"
export const supportMenuTitle = "Support"
// "You don’t have any statements at this time"
export const supportMonthlyStatementsNoStatements = "You don’t have any statements at this time"
// "Account Statements"
export const supportMonthlyStatementsTitle = "Account Statements"
// "This bank account is still connecting to Grid. Please try again in a couple minutes."
export const synapseBankAccountNotReadyForTransfers = "This bank account is still connecting to Grid. Please try again in a couple minutes."
// "Sorry, but Grid Card isn’t yet available in your state."
export const synapseBoostCardUnsupportedStateError = "Sorry, but Grid Card isn’t yet available in your state."
// "Our service provider was unable to verify your address. Please try again."
export const synapseGoogleUndeliverableAddressError = "Our service provider was unable to verify your address. Please try again."
// "Our service provider was unable to verify your address. Please double check your second address field and try again."
export const synapseIncorrectUnitAddressError = "Our service provider was unable to verify your address. Please double check your second address field and try again."
// "It looks like you don’t have that much money to withdraw. Please double check the amount and try again."
export const synapseInsufficientFundsTransferError = "It looks like you don’t have that much money to withdraw. Please double check the amount and try again."
// "The PIN you entered does not match our records."
export const synapseMismatchedCreditCardPinError = "The PIN you entered does not match our records."
// "Add it to your Wallet to pay in stores, anywhere Apple Pay is accepted."
export const temporaryCardAddToWalletScreenAppleSubtitle = "Add it to your Wallet to pay in stores, anywhere Apple Pay is accepted."
// "Add your temporary"
// "card to Apple Wallet"
export const temporaryCardAddToWalletScreenAppleTitle = "Add your temporary\u000Acard to Apple Wallet"
// "CVV 888"
export const temporaryCardAddToWalletScreenGoogleCardCvv = "CVV 888"
// "EXP 08/28"
export const temporaryCardAddToWalletScreenGoogleCardExpiracy = "EXP 08/28"
// "Rodolfo Smarts"
export const temporaryCardAddToWalletScreenGoogleCardName = "Rodolfo Smarts"
// "1234  1234  1234  1234"
export const temporaryCardAddToWalletScreenGoogleCardNumber = "1234  1234  1234  1234"
// "Add it to your Wallet to pay in stores, anywhere Google Pay is accepted."
export const temporaryCardAddToWalletScreenGoogleSubtitle = "Add it to your Wallet to pay in stores, anywhere Google Pay is accepted."
// "Add your temporary card to Google Wallet"
export const temporaryCardAddToWalletScreenGoogleTitle = "Add your temporary card to Google Wallet"
// "Skip for now"
export const temporaryCardAddToWalletScreenSkipCTA = "Skip for now"
// "Once you activate your physical card, your temporary card will be disabled and can no longer be used."
export const temporaryCardAddToWalletScreenWarning = "Once you activate your physical card, your temporary card will be disabled and can no longer be used."
// "Your temporary card"
export const temporaryCardBoostCardScreenTitle = "Your temporary card"
// "Once you activate your physical card, your temporary card will be disabled and can no longer be used."
export const temporaryCardBoostCardScreenWarning = "Once you activate your physical card, your temporary card will be disabled and can no longer be used."
// "Close"
export const temporaryCardDeactivatedHeadsUpScreenCTA = "Close"
// "Remember to update any payment info that uses the old card number."
export const temporaryCardDeactivatedHeadsUpScreenSubtitle = "Remember to update any payment info that uses the old card number."
// "Heads up! Your temporary card has been disabled."
export const temporaryCardDeactivatedHeadsUpScreenTitle = "Heads up! Your temporary card has been disabled."
// "Get Temporary Card"
export const temporaryCardGetTemporaryCardCTA = "Get Temporary Card"
// "Continue"
export const temporaryCardIntroScreenCTA = "Continue"
// "Close"
export const temporaryCardIntroScreenCTAClose = "Close"
// "This is a digital card"
export const temporaryCardIntroScreenExplainItem1 = "This is a digital card"
// "Use it online or add it to Apple Wallet"
export const temporaryCardIntroScreenExplainItem2 = "Use it online or add it to Apple Wallet"
// "Use it online or add it to Google Pay"
export const temporaryCardIntroScreenExplainItem2Google = "Use it online or add it to Google Pay"
// "It will not work at an ATM"
export const temporaryCardIntroScreenExplainItem3 = "It will not work at an ATM"
// "How the temporary card works:"
export const temporaryCardIntroScreenExplanationTitle = "How the temporary card works:"
// "Your physical card will be mailed out soon, and should arrive in 1-2 weeks."
export const temporaryCardIntroScreenSubtitle = "Your physical card will be mailed out soon, and should arrive in 1-2 weeks."
// "Use your temporary card to start spending now! Here’s how it works:"
export const temporaryCardIntroScreenTitle = "Use your temporary card to start spending now! Here’s how it works:"
// "Try Again"
export const temporaryCardWrong4DigitsScreenCTA = "Try Again"
// "Please make sure the card you’re looking at the physical card you received in the mail."
export const temporaryCardWrong4DigitsScreenSubitle = "Please make sure the card you’re looking at the physical card you received in the mail."
// "It looks like you entered the last 4 digits of your temporary card"
export const temporaryCardWrong4DigitsScreenTitle = "It looks like you entered the last 4 digits of your temporary card"
// "Confirming transfer…"
export const transactionConfirmationSpinnerBoxTitle = "Confirming transfer…"
// "Deposit"
export const transactionInfoTypeACHTransferDeposit = "Deposit"
// "Withdrawal"
export const transactionInfoTypeACHTransferWithdrawal = "Withdrawal"
// "Cash Back"
export const transactionInfoTypeCashBack = "Cash Back"
// "Grid Advance"
export const transactionInfoTypeCashBoostAdvance = "Grid Advance"
// "Grid Advance Repayment"
export const transactionInfoTypeCashBoostRepayment = "Grid Advance Repayment"
// "Cash Deposit"
export const transactionInfoTypeCashDeposit = "Cash Deposit"
// "Cash Withdrawal"
export const transactionInfoTypeCashWithdrawal = "Cash Withdrawal"
// "Grid Card Cash Advance"
export const transactionInfoTypeCreditCardCashAdvance = "Grid Card Cash Advance"
// "Grid Card Purchase"
export const transactionInfoTypeCreditCardCharge = "Grid Card Purchase"
// "Grid Card Refund"
export const transactionInfoTypeCreditCardRefund = "Grid Card Refund"
// "Grid Card Purchase"
export const transactionInfoTypeDebitCardCharge = "Grid Card Purchase"
// "Deposit"
export const transactionInfoTypeDebitCardPushToDebit = "Deposit"
// "Grid Card Refund"
export const transactionInfoTypeDebitCardRefund = "Grid Card Refund"
// "Fee"
export const transactionInfoTypeFee = "Fee"
// "Problem with this transaction?"
export const transactionInfoViewIssueCTA = "Problem with this transaction?"
// "Category"
export const transactionInfoViewRowCategory = "Category"
// "Date"
export const transactionInfoViewRowDate = "Date"
// "From"
export const transactionInfoViewRowFrom = "From"
// "Status"
export const transactionInfoViewRowStatus = "Status"
// "Canceled"
export const transactionInfoViewRowStatusCanceled = "Canceled"
// "Dispute Lost"
export const transactionInfoViewRowStatusDisputeLost = "Dispute Lost"
// "Dispute Pending"
export const transactionInfoViewRowStatusDisputePending = "Dispute Pending"
// "Dispute Won"
export const transactionInfoViewRowStatusDisputeWon = "Dispute Won"
// "Failed"
export const transactionInfoViewRowStatusFailed = "Failed"
// "In Dispute"
export const transactionInfoViewRowStatusInDispute = "In Dispute"
// "Pending"
export const transactionInfoViewRowStatusPending = "Pending"
// "Complete"
export const transactionInfoViewRowStatusPosted = "Complete"
// "-"
export const transactionInfoViewRowStatusUnknown = "-"
// "To"
export const transactionInfoViewRowTo = "To"
// "Type"
export const transactionInfoViewRowType = "Type"
// "Vendor"
export const transactionInfoViewRowVendor = "Vendor"
// "Done"
export const transactionInitiatedModalCTA = "Done"
// "Your {{amt}} transfer should arrive in up to 6 business days. If you don’t see it, please contact our support team."
export const transactionInitiatedModalContent = "Your {{amt}} transfer should arrive in up to 6 business days. If you don’t see it, please contact our support team."
// "Your transfer is on its way!"
export const transactionInitiatedModalTitle = "Your transfer is on its way!"
// "Please reach out to Support if you need to dispute this transaction."
export const transactionNotEligibleForDispute = "Please reach out to Support if you need to dispute this transaction."
// "Change Phone Number"
export const twoFAChangePhoneNumber = "Change Phone Number"
// "Change Email Address"
export const twoFAEmailVerificationChangeEmail = "Change Email Address"
// "What’s your email?"
export const twoFAEmailVerificationChangeEmailTitle = "What’s your email?"
// "Resend Email"
export const twoFAEmailVerificationResend = "Resend Email"
// "Please check your email for a verification link"
export const twoFAEmailVerificationTitle = "Please check your email for a verification link"
// "Enter 6-Digit Verification Code"
export const twoFAInstructions = "Enter 6-Digit Verification Code"
// "A verification code will be sent to (•••) •••-{{last4}}."
export const twoFAMoreInfo = "A verification code will be sent to (•••) •••-{{last4}}."
// "What’s your phone number?"
export const twoFAPhoneVerificationInstructions = "What’s your phone number?"
// "A verification code will be sent to this number"
export const twoFAPhoneVerificationMoreInfo = "A verification code will be sent to this number"
// "Verify Phone Number"
export const twoFAPhoneVerificationVerifyPhone = "Verify Phone Number"
// "Resend Code"
export const twoFAResend = "Resend Code"
// "Verifying…"
export const twoFAVerifying = "Verifying…"
// "Your version Grid is no longer supported. Please update it via the App Store."
export const updateAppMessage = "Your version Grid is no longer supported. Please update it via the App Store."
// "Please Update Grid"
export const updateAppTitle = "Please Update Grid"
// "It looks like your federal income tax is less than it was on a previous check-in. Unless you’ve changed jobs, this is likely incorrect. Please double check your entries and try again, or reach out to our team if you need assistance."
export const validationFederalIncomeTaxLessThanPrevious = "It looks like your federal income tax is less than it was on a previous check-in. Unless you’ve changed jobs, this is likely incorrect. Please double check your entries and try again, or reach out to our team if you need assistance."
// "Your federal income tax is rather high for your income level. Please make sure your entries are correct."
export const validationFederalIncomeTaxTooHigh = "Your federal income tax is rather high for your income level. Please make sure your entries are correct."
// "The federal income tax you’ve entered is still rather high. Please check your entries to ensure you’ve entered only federal income tax, not total tax."
export const validationFederalIncomeTaxTooHighSecondAttempt = "The federal income tax you’ve entered is still rather high. Please check your entries to ensure you’ve entered only federal income tax, not total tax."
// "Please make sure you’ve only entered the amount withheld up to today’s date."
// "Please make sure you’ve entered only federal income tax, and not total tax."
export const validationFederalIncomeTaxYTDTooHigh = "Please make sure you’ve only entered the amount withheld up to today’s date.\u000APlease make sure you’ve entered only federal income tax, and not total tax."
// "Please make sure you’ve only entered the amount withheld up to today’s date."
// "The federal income tax you’ve entered is still rather high. Please check your entries to ensure you’ve entered only federal income tax, not total tax."
export const validationFederalIncomeTaxYTDTooHighSecondAttempt = "Please make sure you’ve only entered the amount withheld up to today’s date.\u000AThe federal income tax you’ve entered is still rather high. Please check your entries to ensure you’ve entered only federal income tax, not total tax."
// "It looks like your gross income is less than it was on a previous check-in. Unless you’ve changed jobs, this is likely incorrect. Please double check your entries and try again, or reach out to our team if you need assistance."
export const validationGrossIncomeLessThanPrevious = "It looks like your gross income is less than it was on a previous check-in. Unless you’ve changed jobs, this is likely incorrect. Please double check your entries and try again, or reach out to our team if you need assistance."
// "The amount you’ve entered for your gross income is rather high. Please make sure your entries are correct."
export const validationGrossIncomeTooHigh = "The amount you’ve entered for your gross income is rather high. Please make sure your entries are correct."
// "The amount you’ve entered for your gross income is still rather high. Please make sure your entries are correct."
export const validationGrossIncomeTooHighSecondAttempt = "The amount you’ve entered for your gross income is still rather high. Please make sure your entries are correct."
// "Please make sure you’ve only entered the amount you’ve earned up to today’s date."
// "The amount you’ve entered for your gross income is rather high. Please make sure your entries are correct."
export const validationGrossIncomeYTDTooHigh = "Please make sure you’ve only entered the amount you’ve earned up to today’s date.\u000AThe amount you’ve entered for your gross income is rather high. Please make sure your entries are correct."
// "Please make sure you’ve only entered the amount you’ve earned up to today’s date."
// "The amount you’ve entered for your gross income is still rather high. Please make sure your entries are correct."
export const validationGrossIncomeYTDTooHighSecondAttempt = "Please make sure you’ve only entered the amount you’ve earned up to today’s date.\u000AThe amount you’ve entered for your gross income is still rather high. Please make sure your entries are correct."
// "The amount you’ve entered for your net pay is higher than your gross pay. Please make sure your entries are correct."
export const validationNetIncomeHigherThanGross = "The amount you’ve entered for your net pay is higher than your gross pay. Please make sure your entries are correct."
// "The amount you’ve entered for your net income is rather high. Please make sure your entries are correct."
export const validationNetIncomeTooHigh = "The amount you’ve entered for your net income is rather high. Please make sure your entries are correct."
// "The amount you’ve entered for your net income is still rather high. Please make sure your entries are correct."
export const validationNetIncomeTooHighSecondAttempt = "The amount you’ve entered for your net income is still rather high. Please make sure your entries are correct."
// "The amount you’ve entered for your net pay is higher than your gross pay. Please make sure your entries are correct."
export const validationStateTaxHigherThanGross = "The amount you’ve entered for your net pay is higher than your gross pay. Please make sure your entries are correct."
// "It looks like your state income tax is less than it was on a previous check-in. Unless you’ve changed jobs, this is likely incorrect. Please double check your entries and try again, or reach out to our team if you need assistance."
export const validationStateTaxLessThanPrevious = "It looks like your state income tax is less than it was on a previous check-in. Unless you’ve changed jobs, this is likely incorrect. Please double check your entries and try again, or reach out to our team if you need assistance."
// "Contact Support"
export const verifyBankAccountScreenFailedCTA = "Contact Support"
// "Please contact Support to complete your change request."
export const verifyBankAccountScreenFailedSubtitle = "Please contact Support to complete your change request."
// "We were unable to verify your account ownership"
export const verifyBankAccountScreenFailedTitle = "We were unable to verify your account ownership"
// "Continue"
export const verifyBankAccountStepContinueButton = "Continue"
// "Account Verification"
export const verifyBankAccountStepHeaderTitle = "Account Verification"
// "Contact Support"
export const verifyBankAccountStepHelpButton = "Contact Support"
// "Need help?"
export const verifyBankAccountStepHelpCTA = "Need help?"
// "If you’re having trouble accessing your bank account or transaction history, please reach out to Support for help."
export const verifyBankAccountStepHelpMessage = "If you’re having trouble accessing your bank account or transaction history, please reach out to Support for help."
// "Pick 2 of the following transactions and provide the amounts."
export const verifyBankAccountStepTitle = "Pick 2 of the following transactions and provide the amounts."
// "Your account ending in {{lastFour}} is now connected."
export const verifyBankAccountWorkflowCompletionBody = "Your account ending in {{lastFour}} is now connected."
// "Close"
export const verifyBankAccountWorkflowCompletionCTA = "Close"
// "We are reviewing your information. Please check back in a few minutes."
export const waitingForVerificationLongSubtitle = "We are reviewing your information. Please check back in a few minutes."
// "We need more time to verify your account."
export const waitingForVerificationLongTitle = "We need more time to verify your account."
// "This should take about 1 minute."
export const waitingForVerificationSubtitle = "This should take about 1 minute."
// "We’re verifying your account"
export const waitingForVerificationTitle = "We’re verifying your account"
// "Email"
export const welcomeEmailFieldPlaceholder = "Email"
// "Please check your email to reset your password"
export const welcomeForgotPasswordAlertMessage = "Please check your email to reset your password"
// "Reset password sent!"
export const welcomeForgotPasswordAlertTitle = "Reset password sent!"
// "I already have an account"
export const welcomeLoginButton = "I already have an account"
// "Get started"
export const welcomeOnboardingButton = "Get started"
// "Password"
export const welcomePasswordFieldPlaceholder = "Password"
// "Continue"
export const welcomePreonboardingButton = "Continue"
// "Next"
export const welcomePreonboardingButtonNextPage = "Next"
// "Get your tax refund today!"
export const welcomeTagline = "Get your tax refund today!"
// "Your money."
// "Your choice."
export const welcomeTagline2 = "Your money.\u000AYour choice."
// "One app,"
// "all things money"
export const welcomeTagline3 = "One app,\u000Aall things money"
// "Continue"
export const welcomeWorkflowTextViewDefaultPlaceholder = "Continue"
// "Based on your info you qualify for Grid+ and can get access to unlimited cash advances, flexible repayment and much more."
export const workflowBoostResultOffboardingReasonGridPlus = "Based on your info you qualify for Grid+ and can get access to unlimited cash advances, flexible repayment and much more."
// "Done"
export const workflowGenericHelpModalDone = "Done"
// "You’re all done with your monthly check-in. We’ll let you know if we have any follow-up questions, otherwise you’re all set until next month."
export const workflowMonthlyConclusionDoneBody = "You’re all done with your monthly check-in. We’ll let you know if we have any follow-up questions, otherwise you’re all set until next month."
// "Go to dashboard"
export const workflowMonthlyConclusionDoneContinueButton = "Go to dashboard"
// "Congratulations!"
export const workflowMonthlyConclusionDoneTitle = "Congratulations!"
// "Give us 10 minutes and we’ll give you your new PayBoost!"
export const workflowMonthlyConclusionWaitingBody = "Give us 10 minutes and we’ll give you your new PayBoost!"
// "You can close the app and we’ll let you know when it’s ready."
export const workflowMonthlyConclusionWaitingFinePrint = "You can close the app and we’ll let you know when it’s ready."
// "Looks like your tax return estimate has changed."
export const workflowMonthlyConclusionWaitingTitleDecrease = "Looks like your tax return estimate has changed."
// "Looks like your tax return is growing!"
export const workflowMonthlyConclusionWaitingTitleIncrease = "Looks like your tax return is growing!"
// "Get started!"
export const workflowMonthlyIntroButtonGo = "Get started!"
// "Skip"
export const workflowMonthlyIntroButtonSkip = "Skip"
// "Continue"
export const workflowStepContinueButtonTitle = "Continue"
// "Next"
export const workflowStepNextButtonTitle = "Next"
// "What is your address?"
export const workflowsAddressStepTitle = "What is your address?"
// ""
export const workflowsBoostCardActivationGroupTitle = ""
// "Confirm your PIN"
export const workflowsBoostCardActivationGroupsFourDigitPinStepConfirmationTitle = "Confirm your PIN"
// "This is the pin that will be used at the ATM."
export const workflowsBoostCardActivationGroupsFourDigitPinStepHelpText = "This is the pin that will be used at the ATM."
// ""
export const workflowsBoostCardActivationGroupsFourDigitPinStepHelpTitle = ""
// "Set a 4-digit PIN for your card"
export const workflowsBoostCardActivationGroupsFourDigitPinStepTitle = "Set a 4-digit PIN for your card"
// ""
export const workflowsBoostCardActivationGroupsLastFourCardNumberStepHelpText = ""
// ""
export const workflowsBoostCardActivationGroupsLastFourCardNumberStepHelpTitle = ""
// "What are the last 4 digits of the card you got in the mail?"
export const workflowsBoostCardActivationGroupsLastFourCardNumberStepTitle = "What are the last 4 digits of the card you got in the mail?"
// "This is the 3-digit number on the back of your physical card."
export const workflowsBoostCardActivationGroupsThreeDigitCVVStepHelpText = "This is the 3-digit number on the back of your physical card."
// ""
export const workflowsBoostCardActivationGroupsThreeDigitCVVStepHelpTitle = ""
// "What is the 3-digit CVV?"
export const workflowsBoostCardActivationGroupsThreeDigitCVVStepTitle = "What is the 3-digit CVV?"
// "Add Money"
export const workflowsBoostCardActivationSuccessCta = "Add Money"
// "Start using your card and racking up rewards right away."
export const workflowsBoostCardActivationSuccessMessage = "Start using your card and racking up rewards right away."
// "Your card is ready to be used!"
export const workflowsBoostCardActivationSuccessTitle = "Your card is ready to be used!"
// "Grid Card Activation"
export const workflowsBoostCardActivationTitle = "Grid Card Activation"
// ""
export const workflowsBoostCardGroupsAddressStepHelpText = ""
// "This is the address where we’ll mail your card."
export const workflowsBoostCardGroupsAddressStepHelpTitle = "This is the address where we’ll mail your card."
// "What’s your address?"
export const workflowsBoostCardGroupsAddressStepTitle = "What’s your address?"
// "This is the 3-digit number on the back of your card."
export const workflowsBoostCardGroupsBoostCardActivationCardCVVStepHelpText = "This is the 3-digit number on the back of your card."
// ""
export const workflowsBoostCardGroupsBoostCardActivationCardCVVStepHelpTitle = ""
// "What is the 3-digit CCV?"
export const workflowsBoostCardGroupsBoostCardActivationCardCVVStepTitle = "What is the 3-digit CCV?"
// "This is the PIN you’ll use for ATM withdrawals"
export const workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepHelpText = "This is the PIN you’ll use for ATM withdrawals"
// ""
export const workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepHelpTitle = ""
// "Set a 4-digit PIN for your card:"
export const workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepTitle = "Set a 4-digit PIN for your card:"
// ""
export const workflowsBoostCardGroupsBoostCardActivationCardLastFourStepHelpText = ""
// ""
export const workflowsBoostCardGroupsBoostCardActivationCardLastFourStepHelpTitle = ""
// "What are the last 4 digits of your card?"
export const workflowsBoostCardGroupsBoostCardActivationCardLastFourStepTitle = "What are the last 4 digits of your card?"
// ""
export const workflowsBoostCardGroupsBoostCardCheckoutStepHelpText = ""
// ""
export const workflowsBoostCardGroupsBoostCardCheckoutStepHelpTitle = ""
// ""
export const workflowsBoostCardGroupsBoostCardCheckoutStepTitle = ""
// ""
export const workflowsBoostCardGroupsBoostCardInformationStepHelpText = ""
// ""
export const workflowsBoostCardGroupsBoostCardInformationStepHelpTitle = ""
// "Sign up for Grid+ and open your bank account today"
export const workflowsBoostCardGroupsBoostCardInformationStepTitle = "Sign up for Grid+ and open your bank account today"
// "This could take up to a minute…"
export const workflowsBoostCardGroupsBoostCardQualificationStepHelpText = "This could take up to a minute…"
// ""
export const workflowsBoostCardGroupsBoostCardQualificationStepHelpTitle = ""
// "We’re verifying your account"
export const workflowsBoostCardGroupsBoostCardQualificationStepTitle = "We’re verifying your account"
// ""
export const workflowsBoostCardGroupsConfirmEmailStepHelpText = ""
// ""
export const workflowsBoostCardGroupsConfirmEmailStepHelpTitle = ""
// "Confirm Email"
export const workflowsBoostCardGroupsConfirmEmailStepTitle = "Confirm Email"
// "Grid uses this information to confirm your identity before opening a Grid Card account."
export const workflowsBoostCardGroupsConfirmLastFourSsnStepHelpText = "Grid uses this information to confirm your identity before opening a Grid Card account."
// "Why do you need to confirm my SSN?"
export const workflowsBoostCardGroupsConfirmLastFourSsnStepHelpTitle = "Why do you need to confirm my SSN?"
// "Please confirm the last 4 digits of your Social Security Number"
export const workflowsBoostCardGroupsConfirmLastFourSsnStepTitle = "Please confirm the last 4 digits of your Social Security Number"
// ""
export const workflowsBoostCardGroupsConfirmPhoneNumberStepHelpText = ""
// ""
export const workflowsBoostCardGroupsConfirmPhoneNumberStepHelpTitle = ""
// "Confirm Phone Number"
export const workflowsBoostCardGroupsConfirmPhoneNumberStepTitle = "Confirm Phone Number"
// "Grid uses this information to make sure no one but you can request an advance under your name."
export const workflowsBoostCardGroupsFullSSNStepHelpText = "Grid uses this information to make sure no one but you can request an advance under your name."
// "Why do you need my SSN?"
export const workflowsBoostCardGroupsFullSSNStepHelpTitle = "Why do you need my SSN?"
// "What is your Social Security Number?"
export const workflowsBoostCardGroupsFullSSNStepTitle = "What is your Social Security Number?"
// ""
export const workflowsBoostCardGroupsMFAPinStepHelpText = ""
// ""
export const workflowsBoostCardGroupsMFAPinStepHelpTitle = ""
// "Validation PIN"
export const workflowsBoostCardGroupsMFAPinStepTitle = "Validation PIN"
// ""
export const workflowsBoostCardGroupsPhoneNumberStepHelpText = ""
// ""
export const workflowsBoostCardGroupsPhoneNumberStepHelpTitle = ""
// "Phone Number"
export const workflowsBoostCardGroupsPhoneNumberStepTitle = "Phone Number"
// ""
export const workflowsBoostCardGroupsSSNStepHelpText = ""
// "Why do we need your Social Security Number?"
export const workflowsBoostCardGroupsSSNStepHelpTitle = "Why do we need your Social Security Number?"
// "SSN"
export const workflowsBoostCardGroupsSSNStepTitle = "SSN"
// ""
export const workflowsBoostCardGroupsTermsOfServiceStepHelpText = ""
// ""
export const workflowsBoostCardGroupsTermsOfServiceStepHelpTitle = ""
// ""
export const workflowsBoostCardGroupsTermsOfServiceStepTitle = ""
// ""
export const workflowsBoostCardSetupGroupTitle = ""
// "Grid Card"
export const workflowsBoostCardSetupTitle = "Grid Card"
// "We don’t support high income earners yet. We’re working on new services that could help folks in your situation. Stay tuned!"
export const workflowsBoostResultOffboardingReasonIncomeTooHigh = "We don’t support high income earners yet. We’re working on new services that could help folks in your situation. Stay tuned!"
// "It looks like you’re paying the right amount or not enough taxes. We’re working on new services that could help folks in your situation. Stay tuned!"
export const workflowsBoostResultOffboardingReasonNoEligibleBoost = "It looks like you’re paying the right amount or not enough taxes. We’re working on new services that could help folks in your situation. Stay tuned!"
// "Our systems can’t verify your income. Don’t worry though - we’re working on new services that will help people in your situation. Stay tuned!"
export const workflowsBoostResultOffboardingReasonNoKnownIncome = "Our systems can’t verify your income. Don’t worry though - we’re working on new services that will help people in your situation. Stay tuned!"
// "It looks like you’re paying the right amount or not enough taxes. We’re working on new services that could help folks in your situation. Stay tuned!"
export const workflowsBoostResultOffboardingReasonNoRefundWithholdings = "It looks like you’re paying the right amount or not enough taxes. We’re working on new services that could help folks in your situation. Stay tuned!"
// "It looks like you’re paying the right amount or not enough taxes. We’re working on new services that could help folks in your situation. Stay tuned!"
export const workflowsBoostResultOffboardingReasonOwesMoney = "It looks like you’re paying the right amount or not enough taxes. We’re working on new services that could help folks in your situation. Stay tuned!"
// "Our systems can’t run your payroll account numbers. Don’t worry though - we’re working on new services that will help people in your situation. Stay tuned!"
export const workflowsBoostResultOffboardingReasonUnknownPayrollIssue = "Our systems can’t run your payroll account numbers. Don’t worry though - we’re working on new services that will help people in your situation. Stay tuned!"
// "Unsupported"
export const workflowsBoostResultOffboardingTitle = "Unsupported"
// ""
export const workflowsCashBoostGroupsCashBoostAcceptStepHelpText = ""
// ""
export const workflowsCashBoostGroupsCashBoostAcceptStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsCashBoostAcceptStepTitle = ""
// ""
export const workflowsCashBoostGroupsCashBoostAddressStepHelpText = ""
// ""
export const workflowsCashBoostGroupsCashBoostAddressStepHelpTitle = ""
// "Please enter your address"
export const workflowsCashBoostGroupsCashBoostAddressStepTitle = "Please enter your address"
// ""
export const workflowsCashBoostGroupsCashBoostCheckoutStepHelpText = ""
// ""
export const workflowsCashBoostGroupsCashBoostCheckoutStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsCashBoostCheckoutStepTitle = ""
// ""
export const workflowsCashBoostGroupsCashBoostCollectFootprintStepHelpText = ""
// ""
export const workflowsCashBoostGroupsCashBoostCollectFootprintStepHelpTitle = ""
// "Identity Verification"
export const workflowsCashBoostGroupsCashBoostCollectFootprintStepTitle = "Identity Verification"
// ""
export const workflowsCashBoostGroupsCashBoostIntroStepHelpText = ""
// ""
export const workflowsCashBoostGroupsCashBoostIntroStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsCashBoostIntroStepTitle = ""
// "Grid uses this information to make sure that no one but you can request a Grid Advance under your name."
export const workflowsCashBoostGroupsCashBoostLastFourSsnStepHelpText = "Grid uses this information to make sure that no one but you can request a Grid Advance under your name."
// "Why do you need my SSN?"
export const workflowsCashBoostGroupsCashBoostLastFourSsnStepHelpTitle = "Why do you need my SSN?"
// "What are the last 4 digits of your Social Security Number?"
export const workflowsCashBoostGroupsCashBoostLastFourSsnStepTitle = "What are the last 4 digits of your Social Security Number?"
// ""
export const workflowsCashBoostGroupsCashBoostPhoneNumberStepHelpText = ""
// ""
export const workflowsCashBoostGroupsCashBoostPhoneNumberStepHelpTitle = ""
// "Phone number"
export const workflowsCashBoostGroupsCashBoostPhoneNumberStepTextFieldTitle = "Phone number"
// "What is your"
// "phone number?"
export const workflowsCashBoostGroupsCashBoostPhoneNumberStepTitle = "What is your\u000Aphone number?"
// ""
export const workflowsCashBoostGroupsCashBoostQualificationStepHelpText = ""
// ""
export const workflowsCashBoostGroupsCashBoostQualificationStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsCashBoostQualificationStepTitle = ""
// ""
export const workflowsCashBoostGroupsConnectBankAccountStepHelpText = ""
// ""
export const workflowsCashBoostGroupsConnectBankAccountStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsConnectBankAccountStepTitle = ""
// ""
export const workflowsCashBoostGroupsConnectDebitCardStepHelpText = ""
// ""
export const workflowsCashBoostGroupsConnectDebitCardStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsConnectDebitCardStepTitle = ""
// ""
export const workflowsCashBoostGroupsLegalNameAndDateOfBirthStepHelpText = ""
// ""
export const workflowsCashBoostGroupsLegalNameAndDateOfBirthStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsLegalNameAndDateOfBirthStepTitle = ""
// ""
export const workflowsCashBoostGroupsPreSelectBankAccountStepHelpText = ""
// ""
export const workflowsCashBoostGroupsPreSelectBankAccountStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsPreSelectBankAccountStepTitle = ""
// ""
export const workflowsCashBoostGroupsSetupStripeStepHelpText = ""
// ""
export const workflowsCashBoostGroupsSetupStripeStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsSetupStripeStepTitle = ""
// ""
export const workflowsCashBoostGroupsVerifyBankAccountStepHelpText = ""
// ""
export const workflowsCashBoostGroupsVerifyBankAccountStepHelpTitle = ""
// ""
export const workflowsCashBoostGroupsVerifyBankAccountStepTitle = ""
// "Grid Advance Setup"
export const workflowsCashBoostSetupGroupsTitle = "Grid Advance Setup"
// "Grid Advance"
export const workflowsCashBoostSetupTitle = "Grid Advance"
// "Claim Submission"
export const workflowsClaimsApplicationGroupsCompanyRoleConfirmationTitle = "Claim Submission"
// ""
export const workflowsClaimsApplicationGroupsCompanyRoleHelpText = ""
// ""
export const workflowsClaimsApplicationGroupsCompanyRoleHelpTitle = ""
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadHelpText = ""
// "What qualifies as a paystub?"
export const workflowsClaimsApplicationGroupsDocumentsUploadHelpTitle = "What qualifies as a paystub?"
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadIntroHelpText = ""
// "State unemployment insurance proof"
export const workflowsClaimsApplicationGroupsDocumentsUploadIntroHelpTitle = "State unemployment insurance proof"
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadIntroStepHelpTitle = ""
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadIntroTitle = ""
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadLastPayStubHelpText = ""
// "What qualifies as a paystub?"
export const workflowsClaimsApplicationGroupsDocumentsUploadLastPayStubHelpTitle = "What qualifies as a paystub?"
// "Your last paystub with a pay period that extends through {{date}}"
export const workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubDocumentPrompt = "Your last paystub with a pay period that extends through {{date}}"
// "Your last paystub"
export const workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubDocumentType = "Your last paystub"
// "Document Upload"
export const workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubTitle = "Document Upload"
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPayStubHelpText = ""
// "What qualifies as a paystub?"
export const workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPayStubHelpTitle = "What qualifies as a paystub?"
// "A paystub from {{month}}"
export const workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubDocumentPrompt = "A paystub from {{month}}"
// "A paystub from the 2nd to last month you were employed"
export const workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubDocumentType = "A paystub from the 2nd to last month you were employed"
// "Document Upload"
export const workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubTitle = "Document Upload"
// "Your approval letter for state unemployment benefits for this job loss"
export const workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterDocumentType = "Your approval letter for state unemployment benefits for this job loss"
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterHelpText = ""
// "What qualifies as an approval letter?"
export const workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterHelpTitle = "What qualifies as an approval letter?"
// "Document Upload"
export const workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterTitle = "Document Upload"
// "Your termination letter (recommended)"
export const workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterDocumentType = "Your termination letter (recommended)"
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterHelpText = ""
// "What qualifies as a termination letter?"
export const workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterHelpTitle = "What qualifies as a termination letter?"
// "Document Upload"
export const workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterTitle = "Document Upload"
// ""
export const workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPayStubHelpText = ""
// "What qualifies as a paystub?"
export const workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPayStubHelpTitle = "What qualifies as a paystub?"
// "A paystub from {{month}}"
export const workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubDocumentPrompt = "A paystub from {{month}}"
// "A paystub from the 3rd to last month you were employed"
export const workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubDocumentType = "A paystub from the 3rd to last month you were employed"
// "Document Upload"
export const workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubTitle = "Document Upload"
// "Document Upload"
export const workflowsClaimsApplicationGroupsDocumentsUploadTitle = "Document Upload"
// "Claim Submission"
export const workflowsClaimsApplicationGroupsEmployeeTypeConfirmationTitle = "Claim Submission"
// ""
export const workflowsClaimsApplicationGroupsEmployeeTypeHelpText = ""
// ""
export const workflowsClaimsApplicationGroupsEmployeeTypeHelpTitle = ""
// "When did you stop working for {{employer}}?"
export const workflowsClaimsApplicationGroupsEmploymentTerminationDateHelpText = "When did you stop working for {{employer}}?"
// ""
export const workflowsClaimsApplicationGroupsEmploymentTerminationDateHelpTitle = ""
// "When did you stop working for your previous employer?"
export const workflowsClaimsApplicationGroupsEmploymentTerminationDateQuestion = "When did you stop working for your previous employer?"
// "Claim Submission"
export const workflowsClaimsApplicationGroupsEmploymentTerminationDateTitle = "Claim Submission"
// ""
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonHelpContentText = ""
// ""
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonHelpContentTitle = ""
// "What was the reason for your termination?"
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonQuestion = "What was the reason for your termination?"
// "Claims Submission"
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonTitle = "Claims Submission"
// "Involuntary termination for cause"
// "(fired)"
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesFired = "Involuntary termination for cause\u000A(fired)"
// "Involuntary termination without cause"
// "(layoff)"
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesLayoff = "Involuntary termination without cause\u000A(layoff)"
// "Voluntary termination"
// "(quit or resigned)"
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesVoluntary = "Voluntary termination\u000A(quit or resigned)"
// ""
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeHelpContentTitle = ""
// ""
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeHelpContextText = ""
// "What was the reason for your termination?"
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeQuestion = "What was the reason for your termination?"
// "Claim Submission"
export const workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeTitle = "Claim Submission"
// ""
export const workflowsClaimsApplicationGroupsHowItWorksHelpText = ""
// ""
export const workflowsClaimsApplicationGroupsHowItWorksHelpTitle = ""
// ""
export const workflowsClaimsApplicationGroupsHowItWorksTitle = ""
// ""
export const workflowsClaimsApplicationGroupsIntroHelpText = ""
// ""
export const workflowsClaimsApplicationGroupsIntroHelpTitle = ""
// ""
export const workflowsClaimsApplicationGroupsIntroTitle = ""
// "Did you lose your job at {{employer}}?"
export const workflowsClaimsApplicationGroupsJobHistoryConfirmationHelpContentText = "Did you lose your job at {{employer}}?"
// ""
export const workflowsClaimsApplicationGroupsJobHistoryConfirmationHelpContentTitle = ""
// "Did you lose the job you used to enroll for your Income Protection policy?"
export const workflowsClaimsApplicationGroupsJobHistoryConfirmationQuestion = "Did you lose the job you used to enroll for your Income Protection policy?"
// "Claim Submission"
export const workflowsClaimsApplicationGroupsJobHistoryConfirmationTitle = "Claim Submission"
// ""
export const workflowsClaimsApplicationGroupsPayoutInfoHelpContentText = ""
// "If your claim is accepted, where would you like to receive your funds?"
export const workflowsClaimsApplicationGroupsPayoutInfoHelpContentTitle = "If your claim is accepted, where would you like to receive your funds?"
// ""
export const workflowsClaimsApplicationGroupsPayoutInfoTitle = ""
// "Were you a full-time year-round employee before you were terminated?"
export const workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationHelpContentText = "Were you a full-time year-round employee before you were terminated?"
// "Were you an executive or director at {{employer}} before you were terminated?"
export const workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationHelpContentTitle = "Were you an executive or director at {{employer}} before you were terminated?"
// "Were you an executive or director at your company before you were terminated?"
export const workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationQuestion = "Were you an executive or director at your company before you were terminated?"
// "Were you a full-time year-round employee at {{employer}} before you were terminated?"
export const workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationTitle = "Were you a full-time year-round employee at {{employer}} before you were terminated?"
// ""
export const workflowsClaimsApplicationGroupsPostSubmissionInfoHelpText = ""
// ""
export const workflowsClaimsApplicationGroupsPostSubmissionInfoHelpTitle = ""
// ""
export const workflowsClaimsApplicationGroupsPostSubmissionInfoTitle = ""
// ""
export const workflowsClaimsApplicationGroupsSignatureHelpContentText = ""
// ""
export const workflowsClaimsApplicationGroupsSignatureHelpContentTitle = ""
// "Draw your signature"
export const workflowsClaimsApplicationGroupsSignatureTitle = "Draw your signature"
// ""
export const workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationHelpContentText = ""
// ""
export const workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationHelpContentTitle = ""
// "Have you received state unemployment benefits for this job loss?"
export const workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationQuestion = "Have you received state unemployment benefits for this job loss?"
// "Claim Submission"
export const workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationTitle = "Claim Submission"
// ""
export const workflowsClaimsApplicationGroupsTitle = ""
// "Direct deposit to bank account"
export const workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesBankAccount = "Direct deposit to bank account"
// "Pre-paid debit card"
export const workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesDebitCard = "Pre-paid debit card"
// "Physical checks"
export const workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesPhysicalCheck = "Physical checks"
// ""
export const workflowsClaimsApplicationGroupsUnemploymentCheckLocationHelpContentTitle = ""
// ""
export const workflowsClaimsApplicationGroupsUnemploymentCheckLocationHelpContextText = ""
// "How do you receive your unemployment checks?"
export const workflowsClaimsApplicationGroupsUnemploymentCheckLocationQuestion = "How do you receive your unemployment checks?"
// "Claim Submission"
export const workflowsClaimsApplicationGroupsUnemploymentCheckLocationTitle = "Claim Submission"
// ""
export const workflowsClaimsApplicationTitle = ""
// "Confirm"
export const workflowsComponentsAmountButtonConfirm = "Confirm"
// "Amount"
export const workflowsComponentsAmountPlaceholder = "Amount"
// "Federal taxes"
export const workflowsComponentsCatchupButtonFederalTaxes = "Federal taxes"
// "Gross income"
export const workflowsComponentsCatchupButtonGrossIncome = "Gross income"
// "Skip"
export const workflowsComponentsCatchupButtonSkip = "Skip"
// "State taxes"
export const workflowsComponentsCatchupButtonStateTaxes = "State taxes"
// "Confirm"
export const workflowsComponentsCatchupButtonSubmit = "Confirm"
// "Financial Data"
export const workflowsComponentsCatchupTitle = "Financial Data"
// "None"
export const workflowsComponentsChoiceAnswerNone = "None"
// "Confirm"
export const workflowsComponentsChoiceButtonConfirm = "Confirm"
// "Skip"
export const workflowsComponentsChoiceButtonSkip = "Skip"
// "Confirm"
export const workflowsComponentsCreateJobButtonConfirm = "Confirm"
// "Amount"
export const workflowsComponentsCurrencyPlaceholder = "Amount"
// "Confirm"
export const workflowsComponentsDataEntryButtonSubmit = "Confirm"
// "Continue"
export const workflowsComponentsDateButtonConfirm = "Continue"
// "This does not appear to be a valid date. Please check your entry and try again."
export const workflowsComponentsDateErrorInvalidInput = "This does not appear to be a valid date. Please check your entry and try again."
// "The date needs to be before {{max}}. Please check your entry and try again."
export const workflowsComponentsDateErrorTooHigh = "The date needs to be before {{max}}. Please check your entry and try again."
// "The date needs to be after {{min}}. Please check your entry and try again."
export const workflowsComponentsDateErrorTooLow = "The date needs to be after {{min}}. Please check your entry and try again."
// "MM / DD / YYYY"
export const workflowsComponentsDatePlaceholder = "MM / DD / YYYY"
// "Upload files"
export const workflowsComponentsDocumentButtonDocumentRequestsFiles = "Upload files"
// "Upload photos"
export const workflowsComponentsDocumentButtonDocumentRequestsPhotos = "Upload photos"
// "Scan documents"
export const workflowsComponentsDocumentButtonDocumentRequestsScan = "Scan documents"
// "Changed my mind, skip this"
export const workflowsComponentsDocumentButtonDocumentRequestsSkip = "Changed my mind, skip this"
// "Continue"
export const workflowsComponentsDocumentButtonDoneContinue = "Continue"
// "Continue"
export const workflowsComponentsDocumentButtonIntroContinue = "Continue"
// "No, skip this"
export const workflowsComponentsDocumentButtonIntroSkip = "No, skip this"
// "Confirm"
export const workflowsComponentsDocumentDeliveryButtonConfirm = "Confirm"
// "No files"
export const workflowsComponentsDocumentDeliveryLabelNoFiles = "No files"
// "documents"
export const workflowsComponentsDocumentPromptDefaultDocumentRequestTitle = "documents"
// "If you have more documents to upload, tap one of the folders above. When you’re done, press {{continue_text}}."
export const workflowsComponentsDocumentPromptDone = "If you have more documents to upload, tap one of the folders above. When you’re done, press {{continue_text}}."
// "Let’s start with your {{doc_request_title}}. How do you want to upload them?"
export const workflowsComponentsDocumentPromptFirst = "Let’s start with your {{doc_request_title}}. How do you want to upload them?"
// "Show us what you got! These documents give us the fullest picture of your finances and will help us give you the best PayBoost."
export const workflowsComponentsDocumentPromptIntro = "Show us what you got! These documents give us the fullest picture of your finances and will help us give you the best PayBoost."
// "Almost done! How do you want to upload {{doc_request_title}}?"
export const workflowsComponentsDocumentPromptLast = "Almost done! How do you want to upload {{doc_request_title}}?"
// "Up next, {{doc_request_title}}. How do you want to upload them?"
export const workflowsComponentsDocumentPromptNext = "Up next, {{doc_request_title}}. How do you want to upload them?"
// "Please upload an image of your {{doc_request_title}}."
export const workflowsComponentsDocumentPromptOnly = "Please upload an image of your {{doc_request_title}}."
// "MONTH {{month_number}}"
export const workflowsComponentsInterstitialCaption = "MONTH {{month_number}}"
// "The longer you stay,"
// "the more you get"
export const workflowsComponentsInterstitialMessage = "The longer you stay,\u000Athe more you get"
// "Add new job"
export const workflowsComponentsJobsAddRowTitle = "Add new job"
// "RENEW LINK"
export const workflowsComponentsJobsRowAction = "RENEW LINK"
// "Job {{index}}"
export const workflowsComponentsJobsRowLabelIndex = "Job {{index}}"
// "Untitled"
export const workflowsComponentsJobsRowLabelTitleDefault = "Untitled"
// "Payroll pending…"
export const workflowsComponentsJobsRowProvidedNotTestable = "Payroll pending…"
// "Continue"
export const workflowsComponentsMonthlyBreakdownButtonContinue = "Continue"
// "Take-home pay"
export const workflowsComponentsMonthlyBreakdownHeaderTakeHomePay = "Take-home pay"
// "Begin"
export const workflowsComponentsPayrollButtonBegin = "Begin"
// "Continue"
export const workflowsComponentsPayrollButtonContinue = "Continue"
// "Your payroll connection’s username and password don’t seem to be working."
export const workflowsComponentsPayrollConnectionCredentialsInvalidErrorText = "Your payroll connection’s username and password don’t seem to be working."
// "Please finish inputting your payroll data to continue."
export const workflowsComponentsPayrollConnectionInputIncompleteErrorText = "Please finish inputting your payroll data to continue."
// "We’re still syncing your account. Thanks for your patience."
export const workflowsComponentsPayrollConnectionStateInvalidErrorText = "We’re still syncing your account. Thanks for your patience."
// "Thanks for connecting your jobs to Grid. Please make sure to add all of your payroll accounts that you have access to. Tap continue when you’re done."
export const workflowsComponentsPayrollQuestionDone = "Thanks for connecting your jobs to Grid. Please make sure to add all of your payroll accounts that you have access to. Tap continue when you’re done."
// "We’re working on getting your most recent paystub from {{job_name}}. It’ll take us a few minutes. We’ll let you know when it’s ready."
export const workflowsComponentsPayrollQuestionError = "We’re working on getting your most recent paystub from {{job_name}}. It’ll take us a few minutes. We’ll let you know when it’s ready."
// "your job"
export const workflowsComponentsPayrollQuestionErrorDefaultJobName = "your job"
// "Grid keeps an eye on your income and updates your tax withholding each month by connecting with your payroll provider. Tap continue to link your payroll account."
export const workflowsComponentsPayrollQuestionFirstJob = "Grid keeps an eye on your income and updates your tax withholding each month by connecting with your payroll provider. Tap continue to link your payroll account."
// "Tap continue to link your next payroll account."
export const workflowsComponentsPayrollQuestionNextJob = "Tap continue to link your next payroll account."
// "Great! We’ve successfully imported your W-4 from {{prev_job_name}}. Now for your second job…"
export const workflowsComponentsPayrollQuestionSecondJob = "Great! We’ve successfully imported your W-4 from {{prev_job_name}}. Now for your second job…"
// "Enable"
export const workflowsComponentsPermissionsNotificationsButtonAction = "Enable"
// "Not now"
export const workflowsComponentsPermissionsNotificationsButtonSkip = "Not now"
// "Don’t miss an important deadline"
export const workflowsComponentsPermissionsNotificationsTitle = "Don’t miss an important deadline"
// "PayBoost increased your take-home pay by {{amount}} in {{month}}."
export const workflowsComponentsReviewPayboostImpactMessage = "PayBoost increased your take-home pay by {{amount}} in {{month}}."
// "{{amount}} in {{month}}."
export const workflowsComponentsReviewPayboostImpactMessageBoldedSubtext = "{{amount}} in {{month}}."
// "Take-home pay"
export const workflowsComponentsReviewPayboostImpactTakeHomePayTitle = "Take-home pay"
// "Taxes"
export const workflowsComponentsReviewPayboostImpactTaxesTitle = "Taxes"
// "{{month}} {{year}}"
export const workflowsComponentsReviewPayboostImpactTitle = "{{month}} {{year}}"
// "Without Grid"
export const workflowsComponentsReviewPayboostImpactWithoutGridSubtitle = "Without Grid"
// "Confirm"
export const workflowsComponentsSignupButtonConfirmTerms = "Confirm"
// "Yes, I agree"
export const workflowsComponentsSignupChoiceAgreeToTerms = "Yes, I agree"
// "Continue"
export const workflowsComponentsTextButtonContinue = "Continue"
// "Continue"
export const workflowsComponentsTextButtonMultiline = "Continue"
// "Continue"
export const workflowsComponentsTextButtonSingleline = "Continue"
// "No"
export const workflowsComponentsYesnoNo = "No"
// "Yes"
export const workflowsComponentsYesnoYes = "Yes"
// "Return here once you’ve followed the instructions in the email we’ve sent to the address you’ve provided."
export const workflowsConfirmEmailInstruction = "Return here once you’ve followed the instructions in the email we’ve sent to the address you’ve provided."
// "Connect Bank Account"
export const workflowsConnectBankAccountGroupsTitle = "Connect Bank Account"
// "Connect"
export const workflowsConnectBankAccountTitle = "Connect"
// "Connect Debit Card"
export const workflowsConnectDebitCardGroupsTitle = "Connect Debit Card"
// "Connect"
export const workflowsConnectDebitCardTitle = "Connect"
// ""
export const workflowsCreditBuilderGroupsCreditBuilderCheckoutStepHelpText = ""
// ""
export const workflowsCreditBuilderGroupsCreditBuilderCheckoutStepHelpTitle = ""
// ""
export const workflowsCreditBuilderGroupsCreditBuilderCheckoutStepTitle = ""
// ""
export const workflowsCreditBuilderGroupsCreditBuilderIntroStepHelpText = ""
// ""
export const workflowsCreditBuilderGroupsCreditBuilderIntroStepHelpTitle = ""
// ""
export const workflowsCreditBuilderGroupsCreditBuilderIntroStepTitle = ""
// ""
export const workflowsCreditBuilderGroupsKBAStepHelpText = ""
// ""
export const workflowsCreditBuilderGroupsKBAStepHelpTitle = ""
// ""
export const workflowsCreditBuilderGroupsKBAStepTitle = ""
// ""
export const workflowsCreditBuilderSetupGroupTitle = ""
// ""
export const workflowsCreditBuilderSetupTitle = ""
// "If you need assistance here, please send us a message."
export const workflowsDefaultsHelpText = "If you need assistance here, please send us a message."
// "Need help?"
export const workflowsDefaultsHelpTitle = "Need help?"
// "Loading…"
export const workflowsDefaultsTitle = "Loading…"
// "$1,243"
export const workflowsExtraInfoGroupsFinancialCatchupFederalTaxesPlaceholder = "$1,243"
// "Federal taxes"
export const workflowsExtraInfoGroupsFinancialCatchupFederalTaxesTitle = "Federal taxes"
// "$32,516"
export const workflowsExtraInfoGroupsFinancialCatchupGrossIncomePlaceholder = "$32,516"
// "Gross income"
export const workflowsExtraInfoGroupsFinancialCatchupGrossIncomeTitle = "Gross income"
// "Please enter estimated amounts for each field"
export const workflowsExtraInfoGroupsFinancialCatchupHelpContentText = "Please enter estimated amounts for each field"
// "Financial Data"
export const workflowsExtraInfoGroupsFinancialCatchupHelpContentTitle = "Financial Data"
// "$346"
export const workflowsExtraInfoGroupsFinancialCatchupStateTaxesPlaceholder = "$346"
// "State taxes"
export const workflowsExtraInfoGroupsFinancialCatchupStateTaxesTitle = "State taxes"
// "It looks like we’re missing some financial data for you. Please enter the amounts you’ve earned or paid so far this year. It can be approximate because we only use this for estimation purposes."
export const workflowsExtraInfoGroupsFinancialCatchupText = "It looks like we’re missing some financial data for you. Please enter the amounts you’ve earned or paid so far this year. It can be approximate because we only use this for estimation purposes."
// "Financial Data"
export const workflowsExtraInfoGroupsFinancialCatchupTitle = "Financial Data"
// "Financial Data"
export const workflowsExtraInfoGroupsFinancialGroupTitle = "Financial Data"
// "We couldn’t quite figure out everything from your payroll connections, so just a few quick follow up questions."
export const workflowsExtraInfoIntroText = "We couldn’t quite figure out everything from your payroll connections, so just a few quick follow up questions."
// "Extra Info"
export const workflowsExtraInfoTitle = "Extra Info"
// "tax documents"
export const workflowsFallbacksDocumentType = "tax documents"
// "I’ve been furloughed"
export const workflowsFinancialGroupsCovid19ChoiceFurloughed = "I’ve been furloughed"
// "My hours have been reduced"
export const workflowsFinancialGroupsCovid19ChoiceHoursReduced = "My hours have been reduced"
// "I’ve been laid off"
export const workflowsFinancialGroupsCovid19ChoiceLaidOff = "I’ve been laid off"
// "Other"
export const workflowsFinancialGroupsCovid19ChoiceOther = "Other"
// "I’ve received unemployment benefits"
export const workflowsFinancialGroupsCovid19ChoiceRecievingUnemployment = "I’ve received unemployment benefits"
// "My tips have been reduced"
export const workflowsFinancialGroupsCovid19ChoiceTipsReduced = "My tips have been reduced"
// "Not affected"
export const workflowsFinancialGroupsCovid19CustomSkipText = "Not affected"
// "Please let us know if your income was affected by COVID-19 (aka Coronavirus)."
export const workflowsFinancialGroupsCovid19HelpText = "Please let us know if your income was affected by COVID-19 (aka Coronavirus)."
// "Income Impact From COVID-19 (Coronavirus)"
export const workflowsFinancialGroupsCovid19HelpTitle = "Income Impact From COVID-19 (Coronavirus)"
// "Has the COVID-19 pandemic affected your income?"
export const workflowsFinancialGroupsCovid19QuestionText = "Has the COVID-19 pandemic affected your income?"
// "COVID-19"
export const workflowsFinancialGroupsCovid19Title = "COVID-19"
// "investment account statements"
export const workflowsFinancialGroupsDocumentsStepsInvestmentStatementsDocumentType = "investment account statements"
// "If you have any stocks or bonds that you’ve sold this year, we’d like to get a picture of your capital gains. This includes accounts like e*trade and Robinhood. If you provide an official statement with cost basis and transaction dates, that will help us estimate your tax impact most accurately."
export const workflowsFinancialGroupsDocumentsStepsInvestmentStatementsHelpText = "If you have any stocks or bonds that you’ve sold this year, we’d like to get a picture of your capital gains. This includes accounts like e*trade and Robinhood. If you provide an official statement with cost basis and transaction dates, that will help us estimate your tax impact most accurately."
// "Investment and Brokerage Statements"
export const workflowsFinancialGroupsDocumentsStepsInvestmentStatementsHelpTitle = "Investment and Brokerage Statements"
// "Investment statements"
export const workflowsFinancialGroupsDocumentsStepsInvestmentStatementsTitle = "Investment statements"
// "last year’s tax return"
export const workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnDocumentType = "last year’s tax return"
// "You can get your prior year tax return from TurboTax or H&R Block. The most important thing to provide is the first page of the IRS Form 1040. Most people have this form, as it is the standard cover page when you file your individual tax return each year."
export const workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnHelpText = "You can get your prior year tax return from TurboTax or H\u0026R Block. The most important thing to provide is the first page of the IRS Form 1040. Most people have this form, as it is the standard cover page when you file your individual tax return each year."
// "Last Year’s Tax Return"
export const workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnHelpTitle = "Last Year’s Tax Return"
// "Last year’s tax return"
export const workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnTitle = "Last year’s tax return"
// "Certain situations might mean we need you to upload some documents."
export const workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsHelpText = "Certain situations might mean we need you to upload some documents."
// "Account and Tax Documents"
export const workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsHelpTitle = "Account and Tax Documents"
// "Account and Tax Documents"
export const workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsTitle = "Account and Tax Documents"
// "retirement account statements"
export const workflowsFinancialGroupsDocumentsStepsRetirementStatementsDocumentType = "retirement account statements"
// "Do you contribute to a retirement savings account, such as a 401(k), Roth or IRA? If so, please upload your latest statement, so that we can see your YTD contributions. If you have any special situations, such as 401(k) loans or penalties, please provide information about those as well."
export const workflowsFinancialGroupsDocumentsStepsRetirementStatementsHelpText = "Do you contribute to a retirement savings account, such as a 401(k), Roth or IRA? If so, please upload your latest statement, so that we can see your YTD contributions. If you have any special situations, such as 401(k) loans or penalties, please provide information about those as well."
// "Retirement Account Statements"
export const workflowsFinancialGroupsDocumentsStepsRetirementStatementsHelpTitle = "Retirement Account Statements"
// "Retirement statements"
export const workflowsFinancialGroupsDocumentsStepsRetirementStatementsTitle = "Retirement statements"
// "Documents"
export const workflowsFinancialGroupsDocumentsTitle = "Documents"
// "Enter the date that your most recent paycheck hit your bank account."
export const workflowsFinancialGroupsPaydayStepsLastPayDateHelpText = "Enter the date that your most recent paycheck hit your bank account."
// "Last Pay Date"
export const workflowsFinancialGroupsPaydayStepsLastPayDateHelpTitle = "Last Pay Date"
// "What date did you last get paid?"
export const workflowsFinancialGroupsPaydayStepsLastPayDateQuestion = "What date did you last get paid?"
// "Last Pay Date"
export const workflowsFinancialGroupsPaydayStepsLastPayDateTitle = "Last Pay Date"
// ""
export const workflowsFinancialGroupsPayrollStepsCalculatingBoostHelpText = ""
// ""
export const workflowsFinancialGroupsPayrollStepsCalculatingBoostHelpTitle = ""
// "Calculating PayBoost"
export const workflowsFinancialGroupsPayrollStepsCalculatingBoostPrompt = "Calculating PayBoost"
// ""
export const workflowsFinancialGroupsPayrollStepsCalculatingBoostTitle = ""
// "Connecting your payroll account is necessary. We will walk you through this once you click continue."
export const workflowsFinancialGroupsPayrollStepsConnectPayrollAccountHelpText = "Connecting your payroll account is necessary. We will walk you through this once you click continue."
// "Connect payroll account"
export const workflowsFinancialGroupsPayrollStepsConnectPayrollAccountHelpTitle = "Connect payroll account"
// "Job {{job_number}}"
export const workflowsFinancialGroupsPayrollStepsConnectPayrollAccountTitle = "Job {{job_number}}"
// "Connecting your payroll account is necessary. We will walk you through this once you click continue."
export const workflowsFinancialGroupsPayrollStepsConnectPayrollHelpText = "Connecting your payroll account is necessary. We will walk you through this once you click continue."
// "Connect payroll account"
export const workflowsFinancialGroupsPayrollStepsConnectPayrollHelpTitle = "Connect payroll account"
// "Please tap continue to link your payroll account for {{job_name}}."
export const workflowsFinancialGroupsPayrollStepsConnectPayrollPrompt = "Please tap continue to link your payroll account for {{job_name}}."
// "Job {{job_number}}"
export const workflowsFinancialGroupsPayrollStepsConnectPayrollTitle = "Job {{job_number}}"
// ""
export const workflowsFinancialGroupsPayrollStepsDraftW4ReviewHelpText = ""
// ""
export const workflowsFinancialGroupsPayrollStepsDraftW4ReviewHelpTitle = ""
// "Draft W-4 Review"
export const workflowsFinancialGroupsPayrollStepsDraftW4ReviewPrompt = "Draft W-4 Review"
// ""
export const workflowsFinancialGroupsPayrollStepsDraftW4ReviewTitle = ""
// "In order to maximize your PayBoost, we’ll need to access your W-4 for every job you have. Wages from multiple jobs can make a big difference to the amount of tax you need to pay, so whether you have one job or eight, we need them all!"
export const workflowsFinancialGroupsPayrollStepsNumJobsHelpText = "In order to maximize your PayBoost, we’ll need to access your W-4 for every job you have. Wages from multiple jobs can make a big difference to the amount of tax you need to pay, so whether you have one job or eight, we need them all!"
// "Jobs"
export const workflowsFinancialGroupsPayrollStepsNumJobsHelpTitle = "Jobs"
// "# of jobs"
export const workflowsFinancialGroupsPayrollStepsNumJobsPlaceholder = "# of jobs"
// "How many jobs do you have?"
export const workflowsFinancialGroupsPayrollStepsNumJobsQuestion = "How many jobs do you have?"
// "Number of jobs"
export const workflowsFinancialGroupsPayrollStepsNumJobsTitle = "Number of jobs"
// ""
export const workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsHelpText = ""
// ""
export const workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsHelpTitle = ""
// "Uploading Onboarding Docs"
export const workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsPrompt = "Uploading Onboarding Docs"
// ""
export const workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsTitle = ""
// ""
export const workflowsFinancialGroupsPayrollStepsProjectionResultHelpText = ""
// ""
export const workflowsFinancialGroupsPayrollStepsProjectionResultHelpTitle = ""
// "Projection Result"
export const workflowsFinancialGroupsPayrollStepsProjectionResultPrompt = "Projection Result"
// ""
export const workflowsFinancialGroupsPayrollStepsProjectionResultTitle = ""
// "401(k)"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsChoices401K = "401(k)"
// "IRA"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesIra = "IRA"
// "Pension"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesPension = "Pension"
// "Roth 401(k)"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesRoth401K = "Roth 401(k)"
// "Roth IRA"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesRothIra = "Roth IRA"
// "Retirement plan contributions impact your tax refund can result in additional PayBoost"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsHelpText = "Retirement plan contributions impact your tax refund can result in additional PayBoost"
// "Retirement Accounts"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsHelpTitle = "Retirement Accounts"
// "Do you currently contribute to any of the following retirement accounts?"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsQuestion = "Do you currently contribute to any of the following retirement accounts?"
// "None"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsSkip = "None"
// "Retirement accounts"
export const workflowsFinancialGroupsPayrollStepsRetirementAccountsTitle = "Retirement accounts"
// "W-4 Form Creation"
export const workflowsFinancialGroupsPayrollStepsW4FormCreationHelpText = "W-4 Form Creation"
// "W-4 Form Creation"
export const workflowsFinancialGroupsPayrollStepsW4FormCreationHelpTitle = "W-4 Form Creation"
// "W-4 Form Creation"
export const workflowsFinancialGroupsPayrollStepsW4FormCreationTitle = "W-4 Form Creation"
// "W-4 Form Signature"
export const workflowsFinancialGroupsPayrollStepsW4FormSignatureHelpText = "W-4 Form Signature"
// "W-4 Form Signature"
export const workflowsFinancialGroupsPayrollStepsW4FormSignatureHelpTitle = "W-4 Form Signature"
// "Signature"
export const workflowsFinancialGroupsPayrollStepsW4FormSignaturePlaceholder = "Signature"
// "Draw your signature"
export const workflowsFinancialGroupsPayrollStepsW4FormSignaturePrompt = "Draw your signature"
// "W-4 Form Signature"
export const workflowsFinancialGroupsPayrollStepsW4FormSignatureTitle = "W-4 Form Signature"
// "Financial"
export const workflowsFinancialGroupsPayrollTitle = "Financial"
// "Once a month"
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceAMonth = "Once a month"
// "Once a week"
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceAWeek = "Once a week"
// "Once every two weeks"
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceEveryTwoWeeks = "Once every two weeks"
// "Other"
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOther = "Other"
// "Twice a month"
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesTwiceAMonth = "Twice a month"
// "How often you do you receive a paycheck or direct deposit from your primary employer? This is commonly every 2 weeks on Fridays, but sometimes it is once a week, or 1-2 times per month."
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyHelpText = "How often you do you receive a paycheck or direct deposit from your primary employer? This is commonly every 2 weeks on Fridays, but sometimes it is once a week, or 1-2 times per month."
// "Pay Frequency"
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyHelpTitle = "Pay Frequency"
// "How often do you get paid?"
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyQuestion = "How often do you get paid?"
// "Pay frequency"
export const workflowsFinancialGroupsPeriodFrequencyPayFrequencyTitle = "Pay frequency"
// "Your household info is saved. Now, let’s talk numbers."
export const workflowsFinancialIntroText = "Your household info is saved. Now, let’s talk numbers."
// "We need last year’s tax return in order to accurately calculate your PayBoost amount."
export const workflowsFinancialOffboardingNoLastYearReturnDescription = "We need last year’s tax return in order to accurately calculate your PayBoost amount."
// "Last Year’s Tax Return"
export const workflowsFinancialOffboardingNoLastYearReturnTitle = "Last Year’s Tax Return"
// "There seems to be an issue with one of your payroll accounts. Please reach out to support."
export const workflowsFinancialOffboardingUnsupportedConnectionDescription = "There seems to be an issue with one of your payroll accounts. Please reach out to support."
// "Payroll Data Error"
export const workflowsFinancialOffboardingUnsupportedConnectionTitle = "Payroll Data Error"
// "Unfortunately, your job’s payroll connection isn’t set up for Grid yet. We will let you know when we’re able to service you!"
export const workflowsFinancialOffboardingUnsupportedNoGridEligibleJobsDescription = "Unfortunately, your job’s payroll connection isn’t set up for Grid yet. We will let you know when we’re able to service you!"
// "No Grid Eligible Jobs"
export const workflowsFinancialOffboardingUnsupportedNoGridEligibleJobsTitle = "No Grid Eligible Jobs"
// "Sorry, there seems to be an issue with one of your payroll accounts. Please reach out to support."
export const workflowsFinancialOffboardingUnsupportedNoJobsDescription = "Sorry, there seems to be an issue with one of your payroll accounts. Please reach out to support."
// "Payroll data error"
export const workflowsFinancialOffboardingUnsupportedNoJobsTitle = "Payroll data error"
// "Unfortunately we don’t support this many jobs yet. We will let you know when we do!"
export const workflowsFinancialOffboardingUnsupportedTooManyJobsDescription = "Unfortunately we don’t support this many jobs yet. We will let you know when we do!"
// "Too Many Jobs"
export const workflowsFinancialOffboardingUnsupportedTooManyJobsTitle = "Too Many Jobs"
// "Financials"
export const workflowsFinancialTitle = "Financials"
// ""
export const workflowsGridCardGroupsPayBoostCheckoutStepHelpText = ""
// ""
export const workflowsGridCardGroupsPayBoostCheckoutStepHelpTitle = ""
// ""
export const workflowsGridCardGroupsPayBoostCheckoutStepTitle = ""
// ""
export const workflowsGridCardGroupsTemporaryGridCardHowItWorksStepHelpText = ""
// ""
export const workflowsGridCardGroupsTemporaryGridCardHowItWorksStepHelpTitle = ""
// ""
export const workflowsGridCardGroupsTemporaryGridCardHowItWorksStepTitle = ""
// ""
export const workflowsGridCardGroupsTemporaryGridCardMobileWalletStepHelpText = ""
// ""
export const workflowsGridCardGroupsTemporaryGridCardMobileWalletStepHelpTitle = ""
// ""
export const workflowsGridCardGroupsTemporaryGridCardMobileWalletStepTitle = ""
// "Claiming your adult children or other qualifying dependents that you financially support on your taxes can make you eligible for tax credits that can increase your refund."
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenHelpText = "Claiming your adult children or other qualifying dependents that you financially support on your taxes can make you eligible for tax credits that can increase your refund."
// "Adult Children"
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenHelpTitle = "Adult Children"
// "# adult children"
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenPlaceholder = "# adult children"
// "How many children do you have between the ages of 17-24 that live with you?"
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenQuestion = "How many children do you have between the ages of 17-24 that live with you?"
// "Knowing how many of your children are students may increase your tax refund. You can potentially deduct or receive a reimbursement for your child’s educational expenses."
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsHelpText = "Knowing how many of your children are students may increase your tax refund. You can potentially deduct or receive a reimbursement for your child’s educational expenses."
// "Adult Children Students"
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsHelpTitle = "Adult Children Students"
// "# adult children students"
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsPlaceholder = "# adult children students"
// "How many of your children between the ages of 17-24 are either part-time or full-time students?"
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsQuestion = "How many of your children between the ages of 17-24 are either part-time or full-time students?"
// "Adult children students"
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsTitle = "Adult children students"
// "Adult children"
export const workflowsHouseholdGroupsHouseholdStepsAdultChildrenTitle = "Adult children"
// "Bought a house"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesBoughtHouseText = "Bought a house"
// "Had a baby"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesChildBornText = "Had a baby"
// "Got married"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesMarriedText = "Got married"
// "Moved"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesMovedText = "Moved"
// "Other"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesOtherText = "Other"
// "Switched jobs"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesSwitchedJobsText = "Switched jobs"
// "Getting married, having children, or buying a home can all make a big difference to your taxes. The more info we get from you the better we can PayBoost your paycheck."
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearHelpText = "Getting married, having children, or buying a home can all make a big difference to your taxes. The more info we get from you the better we can PayBoost your paycheck."
// "Significant Life Events"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearHelpTitle = "Significant Life Events"
// "Any big changes this year?"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearQuestion = "Any big changes this year?"
// "None"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearSkip = "None"
// "Life changes"
export const workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearTitle = "Life changes"
// "Let us know how much you spent on child expenses. Activities, such as daycare, summer/winter camp, or babysitters qualify. We’ll do our best to get as much of these expenses reimbursed!"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareHelpText = "Let us know how much you spent on child expenses. Activities, such as daycare, summer/winter camp, or babysitters qualify. We’ll do our best to get as much of these expenses reimbursed!"
// "Children Care"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareHelpTitle = "Children Care"
// "$ per year"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCarePlaceholder = "$ per year"
// "How much have you spent on child care expenses, such as daycare or baby sitters this year?"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareQuestion = "How much have you spent on child care expenses, such as daycare or baby sitters this year?"
// "Child Care"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareTitle = "Child Care"
// "Let us know how much you spent on child expenses. Activities, such as daycare, summer/winter camp, or babysitters qualify. We’ll do our best to get as much of these expenses reimbursed!"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareV2HelpText = "Let us know how much you spent on child expenses. Activities, such as daycare, summer/winter camp, or babysitters qualify. We’ll do our best to get as much of these expenses reimbursed!"
// "Children Care"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareV2HelpTitle = "Children Care"
// "$ per year"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Placeholder = "$ per year"
// "How much have you spent on child care expenses, such as daycare or baby sitters this year?"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Question = "How much have you spent on child care expenses, such as daycare or baby sitters this year?"
// "Child Care"
export const workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Title = "Child Care"
// "Claiming your children that you financially support on your taxes can make you eligible for tax credits that can increase your refund."
export const workflowsHouseholdGroupsHouseholdStepsChildrenHelpText = "Claiming your children that you financially support on your taxes can make you eligible for tax credits that can increase your refund."
// "Children"
export const workflowsHouseholdGroupsHouseholdStepsChildrenHelpTitle = "Children"
// "# of children"
export const workflowsHouseholdGroupsHouseholdStepsChildrenPlaceholder = "# of children"
// "How many children do you have under the age of 17?"
export const workflowsHouseholdGroupsHouseholdStepsChildrenQuestion = "How many children do you have under the age of 17?"
// "Children"
export const workflowsHouseholdGroupsHouseholdStepsChildrenTitle = "Children"
// "Claiming your children that you financially support on your taxes can make you eligible for tax credits that can increase your refund."
export const workflowsHouseholdGroupsHouseholdStepsChildrenV2HelpText = "Claiming your children that you financially support on your taxes can make you eligible for tax credits that can increase your refund."
// "Children"
export const workflowsHouseholdGroupsHouseholdStepsChildrenV2HelpTitle = "Children"
// "# of children"
export const workflowsHouseholdGroupsHouseholdStepsChildrenV2Placeholder = "# of children"
// "How many children do you have under the age of 17?"
export const workflowsHouseholdGroupsHouseholdStepsChildrenV2Question = "How many children do you have under the age of 17?"
// "Children"
export const workflowsHouseholdGroupsHouseholdStepsChildrenV2Title = "Children"
// "Your citizenship and green card status help us qualify you for certain tax deductions and credits."
export const workflowsHouseholdGroupsHouseholdStepsCitizenshipHelpText = "Your citizenship and green card status help us qualify you for certain tax deductions and credits."
// "Immigration Status"
export const workflowsHouseholdGroupsHouseholdStepsCitizenshipHelpTitle = "Immigration Status"
// "Are you a US citizen or green card holder?"
export const workflowsHouseholdGroupsHouseholdStepsCitizenshipQuestion = "Are you a US citizen or green card holder?"
// "Citizenship / green card"
export const workflowsHouseholdGroupsHouseholdStepsCitizenshipTitle = "Citizenship / green card"
// "Let us know how much you spent on dependent expenses. Activities, such as daycare, summer/winter camp, or babysitters qualify. We’ll do our best to get as much of these expenses reimbursed!"
export const workflowsHouseholdGroupsHouseholdStepsDependentCareHelpText = "Let us know how much you spent on dependent expenses. Activities, such as daycare, summer/winter camp, or babysitters qualify. We’ll do our best to get as much of these expenses reimbursed!"
// "Dependent Care"
export const workflowsHouseholdGroupsHouseholdStepsDependentCareHelpTitle = "Dependent Care"
// "$ dependent care expenses"
export const workflowsHouseholdGroupsHouseholdStepsDependentCarePlaceholder = "$ dependent care expenses"
// "How much have you spent on dependent care expenses, such as daycare or baby sitters per year?"
export const workflowsHouseholdGroupsHouseholdStepsDependentCareQuestion = "How much have you spent on dependent care expenses, such as daycare or baby sitters per year?"
// "Dependent care"
export const workflowsHouseholdGroupsHouseholdStepsDependentCareTitle = "Dependent care"
// "Dependents are those in your household that you fully support, such as a niece, nephew, cousin, or other family member. Friends or other types of relatives qualify as well, so long as they live with you."
export const workflowsHouseholdGroupsHouseholdStepsDependentsHelpText = "Dependents are those in your household that you fully support, such as a niece, nephew, cousin, or other family member. Friends or other types of relatives qualify as well, so long as they live with you."
// "Juvenile Dependents"
export const workflowsHouseholdGroupsHouseholdStepsDependentsHelpTitle = "Juvenile Dependents"
// "# dependents"
export const workflowsHouseholdGroupsHouseholdStepsDependentsPlaceholder = "# dependents"
// "How many dependents do you have that are under the age of 17?"
export const workflowsHouseholdGroupsHouseholdStepsDependentsQuestion = "How many dependents do you have that are under the age of 17?"
// "Dependents"
export const workflowsHouseholdGroupsHouseholdStepsDependentsTitle = "Dependents"
// "Insurance through your employer, plans purchased through a state exchange, private insurance, and government funded plans are all qualifying healthcare coverage. If you purchased your coverage through a state exchange it may impact your bottom line at tax time."
export const workflowsHouseholdGroupsHouseholdStepsHealthInsuranceHelpText = "Insurance through your employer, plans purchased through a state exchange, private insurance, and government funded plans are all qualifying healthcare coverage. If you purchased your coverage through a state exchange it may impact your bottom line at tax time."
// "Health insurance"
export const workflowsHouseholdGroupsHouseholdStepsHealthInsuranceHelpTitle = "Health insurance"
// "Do you plan to have health insurance for all of 2020?"
export const workflowsHouseholdGroupsHouseholdStepsHealthInsuranceQuestion = "Do you plan to have health insurance for all of 2020?"
// "Health insurance"
export const workflowsHouseholdGroupsHouseholdStepsHealthInsuranceTitle = "Health insurance"
// "Juvenile dependents are those in your household that you fully support, such as a niece, nephew, cousin, or other family member. To qualify, they must be under the age of 17 at the time of answering this question."
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsHelpText = "Juvenile dependents are those in your household that you fully support, such as a niece, nephew, cousin, or other family member. To qualify, they must be under the age of 17 at the time of answering this question."
// "Juvenile Dependents"
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsHelpTitle = "Juvenile Dependents"
// "# of Dependents"
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsPlaceholder = "# of Dependents"
// "How many dependents, excluding your own children, do you have that are under the age of 17?"
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsQuestion = "How many dependents, excluding your own children, do you have that are under the age of 17?"
// "Dependents"
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsTitle = "Dependents"
// "Dependents are those in your household that you fully support, such as a niece, nephew, cousin, or other family member. Friends or other types of relatives qualify as well, so long as they live with you."
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoHelpText = "Dependents are those in your household that you fully support, such as a niece, nephew, cousin, or other family member. Friends or other types of relatives qualify as well, so long as they live with you."
// "Dependents"
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoHelpTitle = "Dependents"
// "Do you have any dependents that are not your children, such as a niece or nephew?"
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoQuestion = "Do you have any dependents that are not your children, such as a niece or nephew?"
// "Dependents"
export const workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoTitle = "Dependents"
// "Household"
export const workflowsHouseholdGroupsHouseholdTitle = "Household"
// "household"
export const workflowsHouseholdIntroIllustration = "household"
// "Let’s get to know each other! Insurance, citizenship, big life events, we want it all!"
export const workflowsHouseholdIntroText = "Let’s get to know each other! Insurance, citizenship, big life events, we want it all!"
// "Sorry, but Grid is currently only available for US citizens and green card holders."
export const workflowsHouseholdOffboardingCitizenshipDescription = "Sorry, but Grid is currently only available for US citizens and green card holders."
// "Citizenship required"
export const workflowsHouseholdOffboardingCitizenshipTitle = "Citizenship required"
// "Household"
export const workflowsHouseholdTitle = "Household"
// "Confirm"
export const workflowsInputTextButtonSubmit = "Confirm"
// "Get started"
export const workflowsIntroButtonGetStarted = "Get started"
// "{{index}} of {{count}}"
export const workflowsIntroProgress = "{{index}} of {{count}}"
// ""
export const workflowsKycFixGroupTitle = ""
// ""
export const workflowsKycFixGroupsGovernmentIDUploadStepHelpText = ""
// ""
export const workflowsKycFixGroupsGovernmentIDUploadStepHelpTitle = ""
// "We need you to upload photos of the front and back of a photo ID."
export const workflowsKycFixGroupsGovernmentIDUploadStepTitle = "We need you to upload photos of the front and back of a photo ID."
// "Please input the US State that appears on your Government-issued ID."
export const workflowsKycFixGroupsIssuingStateStepsStateHelpText = "Please input the US State that appears on your Government-issued ID."
// "State of Residency"
export const workflowsKycFixGroupsIssuingStateStepsStateHelpTitle = "State of Residency"
// "What US state appears on your ID?"
export const workflowsKycFixGroupsIssuingStateStepsStateQuestion = "What US state appears on your ID?"
// "Issuing State"
export const workflowsKycFixGroupsIssuingStateStepsStateTitle = "Issuing State"
// "Verify Your Identity"
export const workflowsKycFixTitle = "Verify Your Identity"
// "Investment and retirement account statements can help us more accurately project your taxed. Please upload them here."
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsHelpContentText = "Investment and retirement account statements can help us more accurately project your taxed. Please upload them here."
// "Additional Statements"
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsHelpContentTitle = "Additional Statements"
// "investment account statements"
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsDocumentType = "investment account statements"
// "If you have any stocks or bonds that you’ve sold this year, we’d like to get a picture of your capital gains. This includes accounts like e*trade and Robinhood. If you provide an official statement with cost basis and transaction dates, that will help us estimate your tax impact the most accurately."
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsHelpText = "If you have any stocks or bonds that you’ve sold this year, we’d like to get a picture of your capital gains. This includes accounts like e*trade and Robinhood. If you provide an official statement with cost basis and transaction dates, that will help us estimate your tax impact the most accurately."
// "Investment and Brokerage Statements"
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsHelpTitle = "Investment and Brokerage Statements"
// "Investment Statements"
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsTitle = "Investment Statements"
// "retirement account statements"
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsDocumentType = "retirement account statements"
// "Do you contribute to a retirement savings account, such as a 401(k), Roth or IRA? If so, please upload your latest statement, so that we can see your YTD contributions. If you have any special situations, such as 401(k) loans or penalties, please provide information about those as well."
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsHelpText = "Do you contribute to a retirement savings account, such as a 401(k), Roth or IRA? If so, please upload your latest statement, so that we can see your YTD contributions. If you have any special situations, such as 401(k) loans or penalties, please provide information about those as well."
// "Retirement Account Statements"
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsHelpTitle = "Retirement Account Statements"
// "Retirement Statements"
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsTitle = "Retirement Statements"
// "Additional documents"
export const workflowsMonthlyCheckinGroupsCheckinAdditionalDocsTitle = "Additional documents"
// "Choose yes here if you would like to change your PayBoost amount."
export const workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewHelpContentText = "Choose yes here if you would like to change your PayBoost amount."
// "PayBoost Plan"
export const workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewHelpContentTitle = "PayBoost Plan"
// "Your PayBoost plan is getting you an extra ${{boost_dollars_per_month}}/month. Do you want to change this PayBoost amount?"
export const workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewText = "Your PayBoost plan is getting you an extra ${{boost_dollars_per_month}}/month. Do you want to change this PayBoost amount?"
// "PayBoost plan review"
export const workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewTitle = "PayBoost plan review"
// "Please let us know what other changes happened this month."
export const workflowsMonthlyCheckinGroupsCheckinChangesThisMonthHelpContentText = "Please let us know what other changes happened this month."
// "Other Life Changes"
export const workflowsMonthlyCheckinGroupsCheckinChangesThisMonthHelpContentTitle = "Other Life Changes"
// "List any other life changes that might affect your taxes here…"
export const workflowsMonthlyCheckinGroupsCheckinChangesThisMonthPlaceholder = "List any other life changes that might affect your taxes here…"
// "What changed this month?"
export const workflowsMonthlyCheckinGroupsCheckinChangesThisMonthText = "What changed this month?"
// "Changes this month"
export const workflowsMonthlyCheckinGroupsCheckinChangesThisMonthTitle = "Changes this month"
// "Please let us know if you’ve left or joined any new positions."
// ""
// "It’s important that we have an accurate picture into your payroll account. That way, we can make sure your PayBoost is right for you!"
export const workflowsMonthlyCheckinGroupsCheckinEmploymentChangeHelpContentText = "Please let us know if you’ve left or joined any new positions.\u000A\u000AIt’s important that we have an accurate picture into your payroll account. That way, we can make sure your PayBoost is right for you!"
// "Changes In Employment"
export const workflowsMonthlyCheckinGroupsCheckinEmploymentChangeHelpContentTitle = "Changes In Employment"
// "You can delete or add jobs to reflect your recent employment changes. We’ll update your profile settings:"
export const workflowsMonthlyCheckinGroupsCheckinEmploymentChangeTitle = "You can delete or add jobs to reflect your recent employment changes. We’ll update your profile settings:"
// "This is the last pay statement we have on file."
export const workflowsMonthlyCheckinGroupsCheckinLastPayStatementHelpContentText = "This is the last pay statement we have on file."
// "Paystub"
export const workflowsMonthlyCheckinGroupsCheckinLastPayStatementHelpContentTitle = "Paystub"
// "We received your most recent paystub from {{job_name}}. You can take a look or continue with your check-in."
export const workflowsMonthlyCheckinGroupsCheckinLastPayStatementText = "We received your most recent paystub from {{job_name}}. You can take a look or continue with your check-in."
// "Paystub review"
export const workflowsMonthlyCheckinGroupsCheckinLastPayStatementTitle = "Paystub review"
// "Change in employment"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthChangeInEmployment = "Change in employment"
// "Other"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthChangeOther = "Other"
// "Having or adopting a child"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHavingAChild = "Having or adopting a child"
// "Please let us know if any of these things happened this month."
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHelpContentText = "Please let us know if any of these things happened this month."
// "Life Events"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHelpContentTitle = "Life Events"
// "Change in marital status"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthMaritalStatusChange = "Change in marital status"
// "Moved to a different state"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthMovedYourResidence = "Moved to a different state"
// "None"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthNone = "None"
// "Life happens! Any big changes in the last month?"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthText = "Life happens! Any big changes in the last month?"
// "Life events"
export const workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthTitle = "Life events"
// "This screen shows what you earned, paid in taxes, and took home for this month."
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownHelpContentText = "This screen shows what you earned, paid in taxes, and took home for this month."
// "Month In Review"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownHelpContentTitle = "Month In Review"
// "Gross Pay"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionGrossPayTitle = "Gross Pay"
// "Base pay"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemBasepay = "Base pay"
// "PayBoost"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemBoost = "PayBoost"
// "Net base pay"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemNetBasePay = "Net base pay"
// "Take-home pay"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeTitle = "Take-home pay"
// "Federal tax"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemFederal = "Federal tax"
// "Post-tax"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemPosttax = "Post-tax"
// "Pre-tax"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemPretax = "Pre-tax"
// "State tax"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemState = "State tax"
// "Taxes"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesTitle = "Taxes"
// "Take-home pay"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownText = "Take-home pay"
// "Pay summary"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownTitle = "Pay summary"
// "No changes"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesCustomSkipText = "No changes"
// "Large changes to monthly expenses can affect your tax projections."
export const workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesHelpContentText = "Large changes to monthly expenses can affect your tax projections."
// "Monthly Expenses"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesHelpContentTitle = "Monthly Expenses"
// "If yes, please explain the changes here…"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesPlaceholder = "If yes, please explain the changes here…"
// "Any changes in your monthly expenses?"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesText = "Any changes in your monthly expenses?"
// "Expenses"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesTitle = "Expenses"
// "We need a few minutes to calculate your PayBoost."
export const workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationContentText = "We need a few minutes to calculate your PayBoost."
// "Running Numbers…"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationContentTitle = "Running Numbers…"
// "Congratulations!"
export const workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationTitle = "Congratulations!"
// ""
export const workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceHelpContentText = ""
// ""
export const workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceHelpContentTitle = ""
// "PayBoost changes"
export const workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceTitle = "PayBoost changes"
// ""
export const workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusHelpContentText = ""
// ""
export const workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusHelpContentTitle = ""
// "PayBoost level"
export const workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusTitle = "PayBoost level"
// "Please enter the numbers from your latest paystub and let us know if you’ve left or joined any new positions."
// ""
// "It’s important that we have an accurate picture into your payroll account. That way, we can make sure your PayBoost is right for you!"
export const workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationHelpText = "Please enter the numbers from your latest paystub and let us know if you’ve left or joined any new positions.\u000A\u000AIt’s important that we have an accurate picture into your payroll account. That way, we can make sure your PayBoost is right for you!"
// "Updates In Employment"
export const workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationHelpTitle = "Updates In Employment"
// "Please update us with your latest payroll information. You can add or remove jobs as well as enter your pay statement information directly."
export const workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationTitle = "Please update us with your latest payroll information. You can add or remove jobs as well as enter your pay statement information directly."
// "April check-in"
export const workflowsMonthlyCheckinHeaderTitleApr = "April check-in"
// "August check-in"
export const workflowsMonthlyCheckinHeaderTitleAug = "August check-in"
// "December check-in"
export const workflowsMonthlyCheckinHeaderTitleDec = "December check-in"
// "February check-in"
export const workflowsMonthlyCheckinHeaderTitleFeb = "February check-in"
// "January check-in"
export const workflowsMonthlyCheckinHeaderTitleJan = "January check-in"
// "July check-in"
export const workflowsMonthlyCheckinHeaderTitleJul = "July check-in"
// "June check-in"
export const workflowsMonthlyCheckinHeaderTitleJun = "June check-in"
// "March check-in"
export const workflowsMonthlyCheckinHeaderTitleMar = "March check-in"
// "May check-in"
export const workflowsMonthlyCheckinHeaderTitleMay = "May check-in"
// "November check-in"
export const workflowsMonthlyCheckinHeaderTitleNov = "November check-in"
// "October check-in"
export const workflowsMonthlyCheckinHeaderTitleOct = "October check-in"
// "September check-in"
export const workflowsMonthlyCheckinHeaderTitleSep = "September check-in"
// "April"
export const workflowsMonthlyCheckinStubTitleApr = "April"
// "August"
export const workflowsMonthlyCheckinStubTitleAug = "August"
// "December"
export const workflowsMonthlyCheckinStubTitleDec = "December"
// "February"
export const workflowsMonthlyCheckinStubTitleFeb = "February"
// "January"
export const workflowsMonthlyCheckinStubTitleJan = "January"
// "July"
export const workflowsMonthlyCheckinStubTitleJul = "July"
// "June"
export const workflowsMonthlyCheckinStubTitleJun = "June"
// "March"
export const workflowsMonthlyCheckinStubTitleMar = "March"
// "May"
export const workflowsMonthlyCheckinStubTitleMay = "May"
// "November"
export const workflowsMonthlyCheckinStubTitleNov = "November"
// "October"
export const workflowsMonthlyCheckinStubTitleOct = "October"
// "September"
export const workflowsMonthlyCheckinStubTitleSep = "September"
// "Get Started"
export const workflowsMonthlyCheckinTileCTAButton = "Get Started"
// "Complete your {{month}} check-in to keep your tax team up-to-date."
export const workflowsMonthlyCheckinTileLabel = "Complete your {{month}} check-in to keep your tax team up-to-date."
// "Skip"
export const workflowsMonthlyCheckinTileSkipButton = "Skip"
// "Time for check-in"
export const workflowsMonthlyCheckinTileTitle = "Time for check-in"
// "Complete your April check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextApr = "Complete your April check-in to keep our tax team up-to-date."
// "Complete your August check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextAug = "Complete your August check-in to keep our tax team up-to-date."
// "Complete your December check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextDec = "Complete your December check-in to keep our tax team up-to-date."
// "Complete your February check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextFeb = "Complete your February check-in to keep our tax team up-to-date."
// "Complete your January check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextJan = "Complete your January check-in to keep our tax team up-to-date."
// "Complete your July check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextJul = "Complete your July check-in to keep our tax team up-to-date."
// "Complete your June check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextJun = "Complete your June check-in to keep our tax team up-to-date."
// "Complete your March check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextMar = "Complete your March check-in to keep our tax team up-to-date."
// "Complete your May check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextMay = "Complete your May check-in to keep our tax team up-to-date."
// "Complete your November check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextNov = "Complete your November check-in to keep our tax team up-to-date."
// "Complete your October check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextOct = "Complete your October check-in to keep our tax team up-to-date."
// "Complete your September check-in to keep our tax team up-to-date."
export const workflowsMonthlyCheckinWorkflowTextSep = "Complete your September check-in to keep our tax team up-to-date."
// "Unfortunately you’re not eligible for Grid right now."
export const workflowsOffboardingHelpContentMessage = "Unfortunately you’re not eligible for Grid right now."
// "Grid Ineligibility"
export const workflowsOffboardingHelpContentTitle = "Grid Ineligibility"
// "Unsupported"
export const workflowsOffboardingPill = "Unsupported"
// "Goodbye"
export const workflowsOffboardingTitle = "Goodbye"
// "Grid Card"
export const workflowsPayboostSetupGroupsGridCardTitle = "Grid Card"
// "Job Information"
export const workflowsPayboostSetupGroupsPayrollTitle = "Job Information"
// "PayBoost"
export const workflowsPayboostSetupTitle = "PayBoost"
// "Once a month"
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceAMonth = "Once a month"
// "Once a week"
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceAWeek = "Once a week"
// "Once every two weeks"
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceEveryTwoWeeks = "Once every two weeks"
// "Other"
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOther = "Other"
// "Twice a month"
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesTwiceAMonth = "Twice a month"
// "How often you do you receive a paycheck or direct deposit from your primary employer? This is commonly every 2 weeks on Fridays, but sometimes it is once a week, or 1-2 times per month."
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyHelpText = "How often you do you receive a paycheck or direct deposit from your primary employer? This is commonly every 2 weeks on Fridays, but sometimes it is once a week, or 1-2 times per month."
// "Pay Frequency"
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyHelpTitle = "Pay Frequency"
// "How often do you get paid?"
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyQuestion = "How often do you get paid?"
// "Pay frequency"
export const workflowsPayrollDataEntryPayPeriodGroupPayFrequencyTitle = "Pay frequency"
// "Pay Period Beginning"
export const workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesBegininningTitle = "Pay Period Beginning"
// "Pay Period Ending"
export const workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesEndingTitle = "Pay Period Ending"
// "These are the actual days that you are receiving pay for. They are usually the 1-2 weeks prior to the day you actually received the money."
export const workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesHelpText = "These are the actual days that you are receiving pay for. They are usually the 1-2 weeks prior to the day you actually received the money."
// "Pay Period Dates"
export const workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesHelpTitle = "Pay Period Dates"
// "What are the pay period dates on your most recent paystub?"
export const workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesQuestion = "What are the pay period dates on your most recent paystub?"
// "Current Pay Period"
export const workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesTitle = "Current Pay Period"
// "Current pay period"
export const workflowsPayrollDataEntryPayPeriodGroupTitle = "Current pay period"
// "Federal Income Tax"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeFederal = "Federal Income Tax"
// "Gross Income/Pay"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeGross = "Gross Income/Pay"
// "This is the income you’ve received and taxes you’ve paid from the current pay period"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeHelpText = "This is the income you’ve received and taxes you’ve paid from the current pay period"
// "Pay Period Income"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeHelpTitle = "Pay Period Income"
// "Medicare Tax"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeMedicare = "Medicare Tax"
// "Net Pay"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeNet = "Net Pay"
// "Great! Now we need some income and tax amounts for the same pay period:"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeQuestion = "Great! Now we need some income and tax amounts for the same pay period:"
// "Social Security Tax"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeSocialSecurity = "Social Security Tax"
// "State Income Tax"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeState = "State Income Tax"
// "Current Pay Period"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeTitle = "Current Pay Period"
// "Pay period income"
export const workflowsPayrollDataEntryPayPeriodIncomeGroupTitle = "Pay period income"
// "Please upload your most recent paystub. If you can, you should download the complete paystub document and provide that here."
export const workflowsPayrollDataEntryPaystubDocRequestPrompt = "Please upload your most recent paystub. If you can, you should download the complete paystub document and provide that here."
// "paystubs"
export const workflowsPayrollDataEntryPaystubDocsDocumentType = "paystubs"
// "Latest paystub"
export const workflowsPayrollDataEntryPaystubDocsGroupTitle = "Latest paystub"
// "Providing an image or PDF of your paystub allows us to verify your income and tax information. We want to get you the perfect PayBoost!"
export const workflowsPayrollDataEntryPaystubDocsHelpContentText = "Providing an image or PDF of your paystub allows us to verify your income and tax information. We want to get you the perfect PayBoost!"
// "Paystub Upload"
export const workflowsPayrollDataEntryPaystubDocsHelpContentTitle = "Paystub Upload"
// "Paystub"
export const workflowsPayrollDataEntryPaystubDocsTitle = "Paystub"
// "{{jobName}} Income"
export const workflowsPayrollDataEntryTitle = "{{jobName}} Income"
// "If your original numbers are correct, you can continue without changing them."
export const workflowsPayrollDataEntryValidationWarningMessage = "If your original numbers are correct, you can continue without changing them."
// "Year-to-date income"
export const workflowsPayrollDataEntryYTDIncomeGroupTitle = "Year-to-date income"
// "Federal Income Tax"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeFederal = "Federal Income Tax"
// "Gross Income/Pay"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeGross = "Gross Income/Pay"
// "This is the income you’ve received and taxes you’ve paid since January 1st"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeHelpText = "This is the income you’ve received and taxes you’ve paid since January 1st"
// "Year-To-Date Income"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeHelpTitle = "Year-To-Date Income"
// "Medicare Tax"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeMedicare = "Medicare Tax"
// "Net Pay"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeNet = "Net Pay"
// "Great! Now we need the same info from the year-to-date income section of your paystub:"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeQuestion = "Great! Now we need the same info from the year-to-date income section of your paystub:"
// "Social Security Tax"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeSocialSecurity = "Social Security Tax"
// "State Income Tax"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeState = "State Income Tax"
// "Year-To-Date Income"
export const workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeTitle = "Year-To-Date Income"
// "If you enable push notifications, Grid will remind you about important deadlines."
export const workflowsPersonalGroupsAccountInformationStepsPushPermissionsDescription = "If you enable push notifications, Grid will remind you about important deadlines."
// "Don’t miss an important deadline!"
export const workflowsPersonalGroupsAccountInformationStepsPushPermissionsText = "Don’t miss an important deadline!"
// "Push Notifications"
export const workflowsPersonalGroupsAccountInformationStepsPushPermissionsTitle = "Push Notifications"
// "Please enter your email address to set up your Grid account. Only one account per email is allowed."
export const workflowsPersonalGroupsAccountInformationStepsSignupEmailHelpMessage = "Please enter your email address to set up your Grid account. Only one account per email is allowed."
// "Email"
export const workflowsPersonalGroupsAccountInformationStepsSignupEmailHelpTitle = "Email"
// "Email address"
export const workflowsPersonalGroupsAccountInformationStepsSignupEmailPlaceholder = "Email address"
// "What is your email?"
export const workflowsPersonalGroupsAccountInformationStepsSignupEmailPrompt = "What is your email?"
// "Email"
export const workflowsPersonalGroupsAccountInformationStepsSignupEmailTitle = "Email"
// "Please choose a new password. Please avoid reusing your password from another service, and make sure your password meets the following complexity requirements:"
// "• Between 6 and 128 characters."
// "• At least 3 of the following:"
// "  • 1 uppercase character,"
// "  • 1 lowercase character,"
// "  • 1 digit character, and"
// "  • 1 special character,"
// "  • Or a passphrase that is longer than 20 characters."
// "• Not more than 2 identical characters in a row."
export const workflowsPersonalGroupsAccountInformationStepsSignupPasswordHelpMessage = "Please choose a new password. Please avoid reusing your password from another service, and make sure your password meets the following complexity requirements:\u000A• Between 6 and 128 characters.\u000A• At least 3 of the following:\u000A  • 1 uppercase character,\u000A  • 1 lowercase character,\u000A  • 1 digit character, and\u000A  • 1 special character,\u000A  • Or a passphrase that is longer than 20 characters.\u000A• Not more than 2 identical characters in a row."
// "Password"
export const workflowsPersonalGroupsAccountInformationStepsSignupPasswordHelpTitle = "Password"
// "Password"
export const workflowsPersonalGroupsAccountInformationStepsSignupPasswordPlaceholder = "Password"
// "Please choose a password."
// "• 8 characters"
// "• 1 uppercase"
// "• 1 lowercase"
// "• 1 number or special character"
export const workflowsPersonalGroupsAccountInformationStepsSignupPasswordPrompt = "Please choose a password.\u000A• 8 characters\u000A• 1 uppercase\u000A• 1 lowercase\u000A• 1 number or special character"
// "Create a password."
export const workflowsPersonalGroupsAccountInformationStepsSignupPasswordQuestionOnly = "Create a password."
// "• 8 characters"
// "• 1 uppercase"
// "• 1 lowercase"
// "• 1 number or special character"
export const workflowsPersonalGroupsAccountInformationStepsSignupPasswordRequirements = "• 8 characters\u000A• 1 uppercase\u000A• 1 lowercase\u000A• 1 number or special character"
// "Password"
export const workflowsPersonalGroupsAccountInformationStepsSignupPasswordTitle = "Password"
// "I have read and agree to all {{toc}} and the {{privacy}}."
export const workflowsPersonalGroupsAccountInformationStepsSignupTerms = "I have read and agree to all {{toc}} and the {{privacy}}."
// "Please accept the terms and conditions to use this application. If you have questions about this, please send us a message."
export const workflowsPersonalGroupsAccountInformationStepsSignupTermsHelpMessage = "Please accept the terms and conditions to use this application. If you have questions about this, please send us a message."
// "Terms and Conditions"
export const workflowsPersonalGroupsAccountInformationStepsSignupTermsHelpTitle = "Terms and Conditions"
// "privacy policy"
export const workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkPrivacy = "privacy policy"
// "https://getgrid.app/privacy/"
export const workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkPrivacyUrl = "https://getgrid.app/privacy/"
// "terms and conditions"
export const workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkToc = "terms and conditions"
// "https://getgrid.app/terms/"
export const workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkTocUrl = "https://getgrid.app/terms/"
// "Terms and Conditions"
export const workflowsPersonalGroupsAccountInformationStepsSignupTermsPrompt = "Terms and Conditions"
// "Terms and Conditions"
export const workflowsPersonalGroupsAccountInformationStepsSignupTermsTitle = "Terms and Conditions"
// "Signup"
export const workflowsPersonalGroupsAccountInformationStepsSignupTitle = "Signup"
// "Account Information"
export const workflowsPersonalGroupsAccountInformationTitle = "Account Information"
// "Consultant (1099 MISC)"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesConsultant = "Consultant (1099 MISC)"
// "Interest or dividend income"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesInterestDividend = "Interest or dividend income"
// "Investment or stock sale income"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesInvestmentStock = "Investment or stock sale income"
// "On demand (1099 MISC)"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesOnDemand = "On demand (1099 MISC)"
// "Rental Property Income"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesRentalProperty = "Rental Property Income"
// "Retired (1099G and/or Social Security)"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesRetired = "Retired (1099G and/or Social Security)"
// "Salary or Hourly Pay (W2)"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesSalaryHourly = "Salary or Hourly Pay (W2)"
// "Social security income (SSI)"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesSocialSecurity = "Social security income (SSI)"
// "Got a side hustle? Rideshare income, selling online… whatever your gig, extra income could increase or decrease your refund. We’ll need some info to determine eligibility and calculate your PayBoost."
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeHelpText = "Got a side hustle? Rideshare income, selling online… whatever your gig, extra income could increase or decrease your refund. We’ll need some info to determine eligibility and calculate your PayBoost."
// "Secondary Income"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeHelpTitle = "Secondary Income"
// "Any other money coming in?"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeQuestion = "Any other money coming in?"
// "None"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeSkip = "None"
// "Secondary income"
export const workflowsPersonalGroupsPersonalStepsAdditionalIncomeTitle = "Secondary income"
// "Chime"
export const workflowsPersonalGroupsPersonalStepsAttributionChoicesChime = "Chime"
// "A friend told me about Grid"
export const workflowsPersonalGroupsPersonalStepsAttributionChoicesFriend = "A friend told me about Grid"
// "Search (App store, Google…)"
export const workflowsPersonalGroupsPersonalStepsAttributionChoicesSearch = "Search (App store, Google…)"
// "Social media (Facebook, Instagram…)"
export const workflowsPersonalGroupsPersonalStepsAttributionChoicesSocialMedia = "Social media (Facebook, Instagram…)"
// "Square"
export const workflowsPersonalGroupsPersonalStepsAttributionChoicesSquare = "Square"
// "Please let us know how you first found out about Grid. This information will allow us to serve you better."
export const workflowsPersonalGroupsPersonalStepsAttributionHelpText = "Please let us know how you first found out about Grid. This information will allow us to serve you better."
// "How you found us"
export const workflowsPersonalGroupsPersonalStepsAttributionHelpTitle = "How you found us"
// "How did you hear about us?"
export const workflowsPersonalGroupsPersonalStepsAttributionQuestion = "How did you hear about us?"
// "Other"
export const workflowsPersonalGroupsPersonalStepsAttributionSkip = "Other"
// "How you found us"
export const workflowsPersonalGroupsPersonalStepsAttributionTitle = "How you found us"
// "Enter the date of your birth shown on your birth certificate or ID."
export const workflowsPersonalGroupsPersonalStepsDobHelpText = "Enter the date of your birth shown on your birth certificate or ID."
// "Date of Birth"
export const workflowsPersonalGroupsPersonalStepsDobHelpTitle = "Date of Birth"
// "When were you born?"
export const workflowsPersonalGroupsPersonalStepsDobQuestion = "When were you born?"
// "Birth date"
export const workflowsPersonalGroupsPersonalStepsDobTitle = "Birth date"
// "Employment status affects your PayBoost. We need to know if you’re currently employed to minimize your federal tax withholdings and maximize your PayBoost."
export const workflowsPersonalGroupsPersonalStepsEmploymentVerificationHelpText = "Employment status affects your PayBoost. We need to know if you’re currently employed to minimize your federal tax withholdings and maximize your PayBoost."
// "Current employment status"
export const workflowsPersonalGroupsPersonalStepsEmploymentVerificationHelpTitle = "Current employment status"
// "Are you currently employed?"
export const workflowsPersonalGroupsPersonalStepsEmploymentVerificationQuestion = "Are you currently employed?"
// "Employment"
export const workflowsPersonalGroupsPersonalStepsEmploymentVerificationTitle = "Employment"
// "Married, filing jointly"
export const workflowsPersonalGroupsPersonalStepsFilingStatusChoicesMarriedJointly = "Married, filing jointly"
// "Married, filing separately"
export const workflowsPersonalGroupsPersonalStepsFilingStatusChoicesMarriedSeparately = "Married, filing separately"
// "Single"
export const workflowsPersonalGroupsPersonalStepsFilingStatusChoicesSingle = "Single"
// "Your marital status can have a big impact on your tax refund."
// "Tell us if you’re single or married and whether you’re filing together or individually, so that we can build your best PayBoost."
export const workflowsPersonalGroupsPersonalStepsFilingStatusHelpText = "Your marital status can have a big impact on your tax refund.\u000ATell us if you’re single or married and whether you’re filing together or individually, so that we can build your best PayBoost."
// "Martial and Filing Status"
export const workflowsPersonalGroupsPersonalStepsFilingStatusHelpTitle = "Martial and Filing Status"
// "How do you plan to file?"
export const workflowsPersonalGroupsPersonalStepsFilingStatusQuestion = "How do you plan to file?"
// "Marital status"
export const workflowsPersonalGroupsPersonalStepsFilingStatusTitle = "Marital status"
// "Please enter your full legal first name as it appears on your ID."
export const workflowsPersonalGroupsPersonalStepsFirstNameHelpText = "Please enter your full legal first name as it appears on your ID."
// "Legal First Name"
export const workflowsPersonalGroupsPersonalStepsFirstNameHelpTitle = "Legal First Name"
// "Alex"
export const workflowsPersonalGroupsPersonalStepsFirstNamePlaceholder = "Alex"
// "What’s your legal first name?"
export const workflowsPersonalGroupsPersonalStepsFirstNameQuestion = "What’s your legal first name?"
// "Legal first name"
export const workflowsPersonalGroupsPersonalStepsFirstNameTitle = "Legal first name"
// "We’re checking your eligibility for Grid…"
export const workflowsPersonalGroupsPersonalStepsInterstitial1Description = "We’re checking your eligibility for Grid…"
// "THANKS!"
export const workflowsPersonalGroupsPersonalStepsInterstitial1Text = "THANKS!"
// "W-4 Optimization"
export const workflowsPersonalGroupsPersonalStepsInterstitial2Description = "W-4 Optimization"
// "GRID INCLUDES:"
export const workflowsPersonalGroupsPersonalStepsInterstitial2Text = "GRID INCLUDES:"
// "Tax Planning"
export const workflowsPersonalGroupsPersonalStepsInterstitial3Description = "Tax Planning"
// "GRID INCLUDES:"
export const workflowsPersonalGroupsPersonalStepsInterstitial3Text = "GRID INCLUDES:"
// "Financial Projections"
export const workflowsPersonalGroupsPersonalStepsInterstitial4Description = "Financial Projections"
// "GRID INCLUDES:"
export const workflowsPersonalGroupsPersonalStepsInterstitial4Text = "GRID INCLUDES:"
// "Great! You’re on your way to Grid!"
export const workflowsPersonalGroupsPersonalStepsInterstitialCompletionText = "Great! You’re on your way to Grid!"
// "Confirming eligibility"
export const workflowsPersonalGroupsPersonalStepsInterstitialTitle = "Confirming eligibility"
// "Please enter your full legal last name as it appears on your ID."
export const workflowsPersonalGroupsPersonalStepsLastNameHelpText = "Please enter your full legal last name as it appears on your ID."
// "Legal Last Name"
export const workflowsPersonalGroupsPersonalStepsLastNameHelpTitle = "Legal Last Name"
// "Williams"
export const workflowsPersonalGroupsPersonalStepsLastNamePlaceholder = "Williams"
// "What’s your legal last name?"
export const workflowsPersonalGroupsPersonalStepsLastNameQuestion = "What’s your legal last name?"
// "Legal last name"
export const workflowsPersonalGroupsPersonalStepsLastNameTitle = "Legal last name"
// "Consultant (1099 MISC)"
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesConsultant = "Consultant (1099 MISC)"
// "On demand (1099 MISC)"
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesOnDemand = "On demand (1099 MISC)"
// "Other"
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesOther = "Other"
// "Retired (1099G and/or Social Security)"
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesRetired = "Retired (1099G and/or Social Security)"
// "Salary or Hourly Pay (W2)"
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesSalaryHourly = "Salary or Hourly Pay (W2)"
// "Do you make most of your money working for someone who takes your taxes out for you and gives you a paycheck every week or so? You’ve probably got W-2 wages or salary. If you work for yourself, or gig for rideshare or delivery apps, you’re probably looking at 1099 or self-employment income."
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeHelpText = "Do you make most of your money working for someone who takes your taxes out for you and gives you a paycheck every week or so? You’ve probably got W-2 wages or salary. If you work for yourself, or gig for rideshare or delivery apps, you’re probably looking at 1099 or self-employment income."
// "Primary Income"
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeHelpTitle = "Primary Income"
// "How do you make most of your money?"
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeQuestion = "How do you make most of your money?"
// "Primary income"
export const workflowsPersonalGroupsPersonalStepsPrimaryIncomeTitle = "Primary income"
// "We love to keep our Grid followers up to date on new app features. Our followers also sometimes get special offers. Drop your username if you follow us so we can provide you with the best service! If you’re coming from Facebook, just paste your profile’s URL."
export const workflowsPersonalGroupsPersonalStepsSocialHandleHelpText = "We love to keep our Grid followers up to date on new app features. Our followers also sometimes get special offers. Drop your username if you follow us so we can provide you with the best service! If you’re coming from Facebook, just paste your profile’s URL."
// "Social media"
export const workflowsPersonalGroupsPersonalStepsSocialHandleHelpTitle = "Social media"
// "@username"
export const workflowsPersonalGroupsPersonalStepsSocialHandlePlaceholderText = "@username"
// "If you follow us, what’s your username?"
export const workflowsPersonalGroupsPersonalStepsSocialHandleQuestion = "If you follow us, what’s your username?"
// "Skip"
export const workflowsPersonalGroupsPersonalStepsSocialHandleSkipText = "Skip"
// "Social media"
export const workflowsPersonalGroupsPersonalStepsSocialHandleTitle = "Social media"
// "Alabama"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateAlabama = "Alabama"
// "Alaska"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateAlaska = "Alaska"
// "Arizona"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateArizona = "Arizona"
// "Arkansas"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateArkansas = "Arkansas"
// "California"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateCalifornia = "California"
// "Colorado"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateColorado = "Colorado"
// "Connecticut"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateConnecticut = "Connecticut"
// "Delaware"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateDelaware = "Delaware"
// "District of Columbia"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateDistrictOfColumbia = "District of Columbia"
// "Florida"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateFlorida = "Florida"
// "Georgia"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateGeorgia = "Georgia"
// "Hawaii"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateHawaii = "Hawaii"
// "Idaho"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateIdaho = "Idaho"
// "Illinois"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateIllinois = "Illinois"
// "Indiana"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateIndiana = "Indiana"
// "Iowa"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateIowa = "Iowa"
// "Kansas"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateKansas = "Kansas"
// "Kentucky"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateKentucky = "Kentucky"
// "Louisiana"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateLouisiana = "Louisiana"
// "Maine"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateMaine = "Maine"
// "Maryland"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateMaryland = "Maryland"
// "Massachusetts"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateMassachusetts = "Massachusetts"
// "Michigan"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateMichigan = "Michigan"
// "Minnesota"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateMinnesota = "Minnesota"
// "Mississippi"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateMississippi = "Mississippi"
// "Missouri"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateMissouri = "Missouri"
// "Montana"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateMontana = "Montana"
// "Nebraska"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateNebraska = "Nebraska"
// "Nevada"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateNevada = "Nevada"
// "New Hampshire"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewHampshire = "New Hampshire"
// "New Jersey"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewJersey = "New Jersey"
// "New Mexico"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewMexico = "New Mexico"
// "New York"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewYork = "New York"
// "North Carolina"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateNorthCarolina = "North Carolina"
// "North Dakota"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateNorthDakota = "North Dakota"
// "Ohio"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateOhio = "Ohio"
// "Oklahoma"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateOklahoma = "Oklahoma"
// "Oregon"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateOregon = "Oregon"
// "Pennsylvania"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStatePennsylvania = "Pennsylvania"
// "Rhode Island"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateRhodeIsland = "Rhode Island"
// "South Carolina"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateSouthCarolina = "South Carolina"
// "South Dakota"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateSouthDakota = "South Dakota"
// "Tennessee"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateTennessee = "Tennessee"
// "Texas"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateTexas = "Texas"
// "Utah"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateUtah = "Utah"
// "Vermont"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateVermont = "Vermont"
// "Virginia"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateVirginia = "Virginia"
// "Washington"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateWashington = "Washington"
// "West Virginia"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateWestVirginia = "West Virginia"
// "Wisconsin"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateWisconsin = "Wisconsin"
// "Wyoming"
export const workflowsPersonalGroupsPersonalStepsStateChoices00USStateWyoming = "Wyoming"
// "American Samoa"
export const workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryAmericanSamoa = "American Samoa"
// "Guam"
export const workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryGuam = "Guam"
// "Northern Mariana Islands"
export const workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryNorthernMarianaIslands = "Northern Mariana Islands"
// "Puerto Rico"
export const workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryPuertoRico = "Puerto Rico"
// "Virgin Islands"
export const workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryVirginIslands = "Virgin Islands"
// "Other"
export const workflowsPersonalGroupsPersonalStepsStateChoices20Other = "Other"
// "Many states have their own income tax that you pay ON TOP of your federal tax. If you live in one of those states, it could affect your PayBoost."
export const workflowsPersonalGroupsPersonalStepsStateHelpText = "Many states have their own income tax that you pay ON TOP of your federal tax. If you live in one of those states, it could affect your PayBoost."
// "Residency"
export const workflowsPersonalGroupsPersonalStepsStateHelpTitle = "Residency"
// "What state do you live in?"
export const workflowsPersonalGroupsPersonalStepsStateQuestion = "What state do you live in?"
// "State of residency"
export const workflowsPersonalGroupsPersonalStepsStateTitle = "State of residency"
// "Personal"
export const workflowsPersonalGroupsPersonalTitle = "Personal"
// "Sorry, but you must be 18 years of age or older to use Grid."
export const workflowsPersonalOffboardingMinorDescription = "Sorry, but you must be 18 years of age or older to use Grid."
// "Grid is for grownups!"
export const workflowsPersonalOffboardingMinorTitle = "Grid is for grownups!"
// "We don’t support unemployment income or those without income yet. Stay tuned!"
export const workflowsPersonalOffboardingUnemployedDescription = "We don’t support unemployment income or those without income yet. Stay tuned!"
// "Unemployment"
export const workflowsPersonalOffboardingUnemployedTitle = "Unemployment"
// "We don’t support joint filing yet. Stay tuned!"
export const workflowsPersonalOffboardingUnsupportedFilingTypeDescription = "We don’t support joint filing yet. Stay tuned!"
// "Filing type"
export const workflowsPersonalOffboardingUnsupportedFilingTypeTitle = "Filing type"
// "We don’t support non W-2 income yet. Stay tuned!"
export const workflowsPersonalOffboardingUnsupportedPrimaryIncomeDescription = "We don’t support non W-2 income yet. Stay tuned!"
// "Primary income"
export const workflowsPersonalOffboardingUnsupportedPrimaryIncomeTitle = "Primary income"
// "Sorry, but Grid is not yet available in your region."
export const workflowsPersonalOffboardingUnsupportedStateDescription = "Sorry, but Grid is not yet available in your region."
// "Not available in your area yet!"
export const workflowsPersonalOffboardingUnsupportedStateTitle = "Not available in your area yet!"
// "Personal"
export const workflowsPersonalTitle = "Personal"
// "What is your phone number?"
export const workflowsPhoneNumberStepTitle = "What is your phone number?"
// "Select Bank Account"
export const workflowsPreSelectBankAccountGroupsTitle = "Select Bank Account"
// "Oops! It looks like we can’t identify your bank."
export const workflowsPreSelectBankAccountNoBankFound = "Oops! It looks like we can’t identify your bank."
// "Continue"
export const workflowsPreSelectBankAccountNoBankFoundContinueCTA = "Continue"
// "Search"
export const workflowsPreSelectBankAccountSearchPlaceholder = "Search"
// "Select your bank"
export const workflowsPreSelectBankAccountTitle = "Select your bank"
// "Subsequent Advance"
export const workflowsSubsequentAdvanceGroupsTitle = "Subsequent Advance"
// "Subsequent Advance"
export const workflowsSubsequentAdvanceTitle = "Subsequent Advance"
// ""
export const workflowsUnemploymentApplicationGroupsAnnualPayHelpContentTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsAnnualPayHelpContextText = ""
// ""
export const workflowsUnemploymentApplicationGroupsAnnualPayPlaceholder = ""
// "What is your annual income before tax?"
export const workflowsUnemploymentApplicationGroupsAnnualPayQuestion = "What is your annual income before tax?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsAnnualPayTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsAreYouADirectorHelpContentTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsAreYouADirectorHelpContextText = ""
// "Are you part of the executive staff (i.e., CEO, COO, CFO) or a Director (i.e., Board of Directors) at your company?"
export const workflowsUnemploymentApplicationGroupsAreYouADirectorQuestion = "Are you part of the executive staff (i.e., CEO, COO, CFO) or a Director (i.e., Board of Directors) at your company?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsAreYouADirectorTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusHelpContentText = ""
// ""
export const workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusHelpContentTitle = ""
// "Do you currently have a job?"
export const workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusQuestion = "Do you currently have a job?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsDobHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsDobHelpTitle = ""
// "When were you born?"
export const workflowsUnemploymentApplicationGroupsDobQuestion = "When were you born?"
// ""
export const workflowsUnemploymentApplicationGroupsEmployedFor3MonthsHelpContentText = ""
// ""
export const workflowsUnemploymentApplicationGroupsEmployedFor3MonthsHelpContentTitle = ""
// "Have you been employed for at least 6 months at your current employer?"
export const workflowsUnemploymentApplicationGroupsEmployedFor3MonthsQuestion = "Have you been employed for at least 6 months at your current employer?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsEmployedFor3MonthsTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsEmployerCityHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsEmployerCityHelpTitle = ""
// "Employer city"
export const workflowsUnemploymentApplicationGroupsEmployerCityPlaceholder = "Employer city"
// "What city is your employer based in?"
export const workflowsUnemploymentApplicationGroupsEmployerCityQuestion = "What city is your employer based in?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsEmployerCityTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsEmployerIsNonProfitHelpContentText = ""
// ""
export const workflowsUnemploymentApplicationGroupsEmployerIsNonProfitHelpContentTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsEmployerIsNonProfitQuestion = ""
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsEmployerIsNonProfitTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsEmployerNameHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsEmployerNameHelpTitle = ""
// "Employer name"
export const workflowsUnemploymentApplicationGroupsEmployerNamePlaceholder = "Employer name"
// "Who is listed as your employer on your pay stubs?"
export const workflowsUnemploymentApplicationGroupsEmployerNameQuestion = "Who is listed as your employer on your pay stubs?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsEmployerNameTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsEmployerStateHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsEmployerStateHelpTitle = ""
// "What state is your employer based in?"
export const workflowsUnemploymentApplicationGroupsEmployerStateQuestion = "What state is your employer based in?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsEmployerStateTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsExpectingTerminationHelpContentTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsExpectingTerminationHelpContextText = ""
// ""
export const workflowsUnemploymentApplicationGroupsExpectingTerminationQuestion = ""
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsExpectingTerminationTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsFirstNameHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsFirstNameHelpTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsFirstNamePlaceholder = ""
// "What is your legal first name?"
export const workflowsUnemploymentApplicationGroupsFirstNameQuestion = "What is your legal first name?"
// "Personal"
export const workflowsUnemploymentApplicationGroupsFirstNameTitle = "Personal"
// ""
export const workflowsUnemploymentApplicationGroupsFulltimeHelpContentTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsFulltimeHelpContextText = ""
// "Do you work at least 30 hours a week?"
export const workflowsUnemploymentApplicationGroupsFulltimeQuestion = "Do you work at least 30 hours a week?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsFulltimeTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsHowItWorksIntroHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsHowItWorksIntroHelpTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsHowItWorksIntroTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsImpendingLayoffHelpContentTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsImpendingLayoffHelpContextText = ""
// "Do you know of any future layoffs at your company or have you received notice of termination?"
export const workflowsUnemploymentApplicationGroupsImpendingLayoffQuestion = "Do you know of any future layoffs at your company or have you received notice of termination?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsImpendingLayoffTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsIntroHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsIntroHelpTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsIntroTitle = ""
// "Job title"
export const workflowsUnemploymentApplicationGroupsJobTitlePlaceholder = "Job title"
// "What is your occupation?"
export const workflowsUnemploymentApplicationGroupsJobTitleQuestion = "What is your occupation?"
// ""
export const workflowsUnemploymentApplicationGroupsJobTitleStepHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsJobTitleStepHelpTitle = ""
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsJobTitleStepTitle = "Employer Verification"
// "Contract or gig worker"
export const workflowsUnemploymentApplicationGroupsJobTypeChoicesIndependentContractor = "Contract or gig worker"
// "Year-round salaried or hourly worker"
export const workflowsUnemploymentApplicationGroupsJobTypeChoicesSalaryHourly = "Year-round salaried or hourly worker"
// "Seasonal or temp worker"
export const workflowsUnemploymentApplicationGroupsJobTypeChoicesSeasonalWorker = "Seasonal or temp worker"
// "Self-employed"
export const workflowsUnemploymentApplicationGroupsJobTypeChoicesSelfEmployed = "Self-employed"
// "I’m not sure"
export const workflowsUnemploymentApplicationGroupsJobTypeHelpContentTitle = "I’m not sure"
// ""
export const workflowsUnemploymentApplicationGroupsJobTypeHelpContextText = ""
// "What employee type are you?"
export const workflowsUnemploymentApplicationGroupsJobTypeQuestion = "What employee type are you?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsJobTypeTitle = "Employer Verification"
// ""
export const workflowsUnemploymentApplicationGroupsLastNameHelpText = ""
// ""
export const workflowsUnemploymentApplicationGroupsLastNameHelpTitle = ""
// ""
export const workflowsUnemploymentApplicationGroupsLastNamePlaceholder = ""
// "What is your legal last name?"
export const workflowsUnemploymentApplicationGroupsLastNameQuestion = "What is your legal last name?"
// "Personal"
export const workflowsUnemploymentApplicationGroupsLastNameTitle = "Personal"
// ""
export const workflowsUnemploymentApplicationGroupsOperatingFor3YearsHelpContentText = ""
// ""
export const workflowsUnemploymentApplicationGroupsOperatingFor3YearsHelpContentTitle = ""
// "Has your employer been in continuous operation for the last 3 years?"
export const workflowsUnemploymentApplicationGroupsOperatingFor3YearsQuestion = "Has your employer been in continuous operation for the last 3 years?"
// "Employer Verification"
export const workflowsUnemploymentApplicationGroupsOperatingFor3YearsTitle = "Employer Verification"
// "Contract or gig worker"
export const workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesOnDemand = "Contract or gig worker"
// "Year-round salaried or hourly worker"
export const workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSalaryHourly = "Year-round salaried or hourly worker"
// "Seasonal or temp worker"
export const workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSeasonalWorker = "Seasonal or temp worker"
// "Self-employed"
export const workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSelfEmployed = "Self-employed"
// "Personal"
export const workflowsUnemploymentApplicationGroupsStepsDobTitle = "Personal"
// ""
export const workflowsUnemploymentApplicationGroupsTitle = ""
// "Employer Verification"
export const workflowsUnemploymentApplicationTitle = "Employer Verification"
// "Something went wrong. Please try again later or email support@getgrid.app for assistance."
export const zendeskGenericFailureMessage = "Something went wrong. Please try again later or email support@getgrid.app for assistance."

// Map that enables lookups of server-side keys.
export function string(localizedString: visor.common.strings.ILocalizedString): (string|null) {
    if (!localizedString) { return null; }
    switch (localizedString.key) {
    case `addPaymentMethodContinueButton`: return addPaymentMethodContinueButton;
    case `addPaymentMethodHeader`: return addPaymentMethodHeader;
    case `addPaymentMethodSaveCard`: return addPaymentMethodSaveCard;
    case `addPaymentMethodTitle`: return addPaymentMethodTitle;
    case `addressFormErrorReasonEmptyCity`: return addressFormErrorReasonEmptyCity;
    case `addressFormErrorReasonEmptyState`: return addressFormErrorReasonEmptyState;
    case `addressFormErrorReasonEmptyStreet`: return addressFormErrorReasonEmptyStreet;
    case `addressFormErrorReasonEmptyZip`: return addressFormErrorReasonEmptyZip;
    case `addressFormErrorReasonInvalidState`: return addressFormErrorReasonInvalidState;
    case `addressFormErrorReasonInvalidZip`: return addressFormErrorReasonInvalidZip;
    case `addressInputFormLegalAddressCheckBoxTitle`: return addressInputFormLegalAddressCheckBoxTitle;
    case `addressInputFormLegalAddressSubtitle`: return addressInputFormLegalAddressSubtitle;
    case `addressInputFormLegalAddressTitle`: return addressInputFormLegalAddressTitle;
    case `addressInputFormMailingAddressSubtitle`: return addressInputFormMailingAddressSubtitle;
    case `addressInputFormMailingAddressTitle`: return addressInputFormMailingAddressTitle;
    case `addressInputFormMailingAddressUpdateCTA`: return addressInputFormMailingAddressUpdateCTA;
    case `advanceCTATitleOverdueDeliveredTile`: return advanceCTATitleOverdueDeliveredTile;
    case `advanceLevelsExplanationFooter`: return advanceLevelsExplanationFooter;
    case `advanceLevelsExplanationSubtitle`: return advanceLevelsExplanationSubtitle;
    case `advanceMeetAdvanceSubtitle`: return advanceMeetAdvanceSubtitle;
    case `advanceMeetAdvanceTitle`: return advanceMeetAdvanceTitle;
    case `advanceMeetTileCashAdvanceEmoji`: return advanceMeetTileCashAdvanceEmoji;
    case `advanceMeetTileCashAdvanceTitle`: return advanceMeetTileCashAdvanceTitle;
    case `advanceMeetTileHiddenFeesEmoji`: return advanceMeetTileHiddenFeesEmoji;
    case `advanceMeetTileHiddenFeesTitle`: return advanceMeetTileHiddenFeesTitle;
    case `advanceMeetTileLevelsProgramButtonText`: return advanceMeetTileLevelsProgramButtonText;
    case `advanceMeetTileLevelsProgramEmoji`: return advanceMeetTileLevelsProgramEmoji;
    case `advanceMeetTileLevelsProgramTitle`: return advanceMeetTileLevelsProgramTitle;
    case `advanceMeetTitleNoCreditCheckEmoji`: return advanceMeetTitleNoCreditCheckEmoji;
    case `advanceMeetTitleNoCreditCheckTitle`: return advanceMeetTitleNoCreditCheckTitle;
    case `advanceNewLevelSubtitle`: return advanceNewLevelSubtitle;
    case `advanceNewLevelTitle`: return advanceNewLevelTitle;
    case `advanceOnboardingDashboardBanner`: return advanceOnboardingDashboardBanner;
    case `advanceOnboardingDashboardCTATitle`: return advanceOnboardingDashboardCTATitle;
    case `advanceOnboardingDashboardTitle`: return advanceOnboardingDashboardTitle;
    case `advanceOnboardingFastAndDeliveryScreenCTATitle`: return advanceOnboardingFastAndDeliveryScreenCTATitle;
    case `advanceOnboardingFastAndDeliveryScreenFastBannerSubtitle`: return advanceOnboardingFastAndDeliveryScreenFastBannerSubtitle;
    case `advanceOnboardingFastAndDeliveryScreenFastBannerTitle`: return advanceOnboardingFastAndDeliveryScreenFastBannerTitle;
    case `advanceOnboardingFastAndDeliveryScreenFastBox`: return advanceOnboardingFastAndDeliveryScreenFastBox;
    case `advanceOnboardingFastAndDeliveryScreenStandardBannerSubtitle`: return advanceOnboardingFastAndDeliveryScreenStandardBannerSubtitle;
    case `advanceOnboardingFastAndDeliveryScreenStandardBannerTitle`: return advanceOnboardingFastAndDeliveryScreenStandardBannerTitle;
    case `advanceOnboardingFastAndDeliveryScreenStandardBox`: return advanceOnboardingFastAndDeliveryScreenStandardBox;
    case `advanceOnboardingFastAndDeliveryScreenTitle`: return advanceOnboardingFastAndDeliveryScreenTitle;
    case `advanceOnboardingFastAndDeliveryScreenYourOfferBannerContentA`: return advanceOnboardingFastAndDeliveryScreenYourOfferBannerContentA;
    case `advanceOnboardingFastAndDeliveryScreenYourOfferBannerContentB`: return advanceOnboardingFastAndDeliveryScreenYourOfferBannerContentB;
    case `advanceOnboardingFastAndDeliveryScreenYourOfferBannerTitle`: return advanceOnboardingFastAndDeliveryScreenYourOfferBannerTitle;
    case `advanceOnboardingSummaryScreenIncludesBox1`: return advanceOnboardingSummaryScreenIncludesBox1;
    case `advanceOnboardingSummaryScreenIncludesBox2`: return advanceOnboardingSummaryScreenIncludesBox2;
    case `advanceOnboardingSummaryScreenIncludesBox3`: return advanceOnboardingSummaryScreenIncludesBox3;
    case `advanceOnboardingSummaryScreenIncludesBox4A`: return advanceOnboardingSummaryScreenIncludesBox4A;
    case `advanceOnboardingSummaryScreenIncludesBox4B`: return advanceOnboardingSummaryScreenIncludesBox4B;
    case `advanceOnboardingSummaryScreenIncludesBox5`: return advanceOnboardingSummaryScreenIncludesBox5;
    case `advanceOnboardingSummaryScreenIncludesBoxTitle`: return advanceOnboardingSummaryScreenIncludesBoxTitle;
    case `advanceOnboardingSummaryScreenLabelA`: return advanceOnboardingSummaryScreenLabelA;
    case `advanceOnboardingSummaryScreenLabelB`: return advanceOnboardingSummaryScreenLabelB;
    case `advanceOnboardingSummaryScreenOverviewTitle`: return advanceOnboardingSummaryScreenOverviewTitle;
    case `advanceOnboardingSummaryScreenTermsAndConsA`: return advanceOnboardingSummaryScreenTermsAndConsA;
    case `advanceOnboardingSummaryScreenTermsAndConsB`: return advanceOnboardingSummaryScreenTermsAndConsB;
    case `advanceOnboardingSummaryScreenTermsAndConsBBold`: return advanceOnboardingSummaryScreenTermsAndConsBBold;
    case `advanceOnboardingSummaryScreenTermsAndConsC`: return advanceOnboardingSummaryScreenTermsAndConsC;
    case `advanceOnboardingSummaryScreenTermsAndConsCTATitle`: return advanceOnboardingSummaryScreenTermsAndConsCTATitle;
    case `advanceOnboardingSummaryScreenTermsAndConsLinkA`: return advanceOnboardingSummaryScreenTermsAndConsLinkA;
    case `advanceOnboardingSummaryScreenTermsAndConsLinkAText`: return advanceOnboardingSummaryScreenTermsAndConsLinkAText;
    case `advanceOnboardingSummaryScreenTermsAndConsLinkB`: return advanceOnboardingSummaryScreenTermsAndConsLinkB;
    case `advanceOnboardingSummaryScreenTermsAndConsLinkBText`: return advanceOnboardingSummaryScreenTermsAndConsLinkBText;
    case `advanceOnboardingWorkflowBankAccountAdded`: return advanceOnboardingWorkflowBankAccountAdded;
    case `advanceOnboardingWorkflowDebitCardAdded`: return advanceOnboardingWorkflowDebitCardAdded;
    case `advanceOnboardingWorkflowEnterDebitCardCTATitle`: return advanceOnboardingWorkflowEnterDebitCardCTATitle;
    case `advanceOnboardingWorkflowEnterDebitCardTitle`: return advanceOnboardingWorkflowEnterDebitCardTitle;
    case `advanceOnboardingWorkflowHIWBoxA`: return advanceOnboardingWorkflowHIWBoxA;
    case `advanceOnboardingWorkflowHIWBoxB`: return advanceOnboardingWorkflowHIWBoxB;
    case `advanceOnboardingWorkflowHIWBoxC`: return advanceOnboardingWorkflowHIWBoxC;
    case `advanceOnboardingWorkflowHIWBoxD`: return advanceOnboardingWorkflowHIWBoxD;
    case `advanceOnboardingWorkflowHIWCTATitle`: return advanceOnboardingWorkflowHIWCTATitle;
    case `advanceOnboardingWorkflowHIWSubitle`: return advanceOnboardingWorkflowHIWSubitle;
    case `advanceOnboardingWorkflowHIWTitle`: return advanceOnboardingWorkflowHIWTitle;
    case `advanceOnboardingWorkflowStepsForYourAdvanceCTATitle`: return advanceOnboardingWorkflowStepsForYourAdvanceCTATitle;
    case `advanceOnboardingWorkflowStepsForYourAdvanceLabelA`: return advanceOnboardingWorkflowStepsForYourAdvanceLabelA;
    case `advanceOnboardingWorkflowStepsForYourAdvanceLabelB`: return advanceOnboardingWorkflowStepsForYourAdvanceLabelB;
    case `advanceOnboardingWorkflowStepsForYourAdvanceLabelC`: return advanceOnboardingWorkflowStepsForYourAdvanceLabelC;
    case `advanceOnboardingWorkflowStepsForYourAdvanceTitle`: return advanceOnboardingWorkflowStepsForYourAdvanceTitle;
    case `advanceOnboardingWorkflowVerifyingBankAccountSubtitle`: return advanceOnboardingWorkflowVerifyingBankAccountSubtitle;
    case `advanceOnboardingWorkflowVerifyingBankAccountTitle`: return advanceOnboardingWorkflowVerifyingBankAccountTitle;
    case `advanceOnboardingWorkflowVerifyingDebitCardSubtitle`: return advanceOnboardingWorkflowVerifyingDebitCardSubtitle;
    case `advanceOnboardingWorkflowVerifyingDebitCardTitle`: return advanceOnboardingWorkflowVerifyingDebitCardTitle;
    case `advanceOnboardingYourOfferScreenBoxASubtitle`: return advanceOnboardingYourOfferScreenBoxASubtitle;
    case `advanceOnboardingYourOfferScreenBoxATitle`: return advanceOnboardingYourOfferScreenBoxATitle;
    case `advanceOnboardingYourOfferScreenBoxBSubitle`: return advanceOnboardingYourOfferScreenBoxBSubitle;
    case `advanceOnboardingYourOfferScreenBoxBTitle`: return advanceOnboardingYourOfferScreenBoxBTitle;
    case `advanceOnboardingYourOfferScreenCTATitle`: return advanceOnboardingYourOfferScreenCTATitle;
    case `advanceOnboardingYourOfferScreenTitle`: return advanceOnboardingYourOfferScreenTitle;
    case `advanceOverdueBannerSubtitle`: return advanceOverdueBannerSubtitle;
    case `advanceOverdueBannerTitle`: return advanceOverdueBannerTitle;
    case `advancePlaidContinueButton`: return advancePlaidContinueButton;
    case `advancePlaidTitle`: return advancePlaidTitle;
    case `advanceSomethingWentWrongTitle`: return advanceSomethingWentWrongTitle;
    case `advanceSubsequentBottomSheetCTAText`: return advanceSubsequentBottomSheetCTAText;
    case `advanceTileAccountErrorBankAccountMsg`: return advanceTileAccountErrorBankAccountMsg;
    case `advanceTileAccountErrorBankAccountTitle`: return advanceTileAccountErrorBankAccountTitle;
    case `advanceTileAccountErrorDebitCardMsg`: return advanceTileAccountErrorDebitCardMsg;
    case `advanceTileAccountErrorDebitCardTitle`: return advanceTileAccountErrorDebitCardTitle;
    case `advanceTileAccountErrorPayoutExceptionMsg`: return advanceTileAccountErrorPayoutExceptionMsg;
    case `advanceTileAccountErrorPayoutExceptionTitle`: return advanceTileAccountErrorPayoutExceptionTitle;
    case `advanceTileAccountErrorVerifyIdTitle`: return advanceTileAccountErrorVerifyIdTitle;
    case `advanceTileCooldownCaption`: return advanceTileCooldownCaption;
    case `advanceTileCooldownTitle`: return advanceTileCooldownTitle;
    case `advanceTileDisqualifiedButton`: return advanceTileDisqualifiedButton;
    case `advanceTileDisqualifiedCaption`: return advanceTileDisqualifiedCaption;
    case `advanceTileDisqualifiedTitle`: return advanceTileDisqualifiedTitle;
    case `advanceTileErrorButton`: return advanceTileErrorButton;
    case `advanceTileErrorCaption`: return advanceTileErrorCaption;
    case `advanceTileErrorTitle`: return advanceTileErrorTitle;
    case `advanceTileLevelsText`: return advanceTileLevelsText;
    case `advanceTilePaymentArrivingButton`: return advanceTilePaymentArrivingButton;
    case `advanceTilePaymentArrivingDeliveryCaption`: return advanceTilePaymentArrivingDeliveryCaption;
    case `advanceTilePaymentArrivingFastArrivalEst`: return advanceTilePaymentArrivingFastArrivalEst;
    case `advanceTilePaymentArrivingTitle`: return advanceTilePaymentArrivingTitle;
    case `advanceTilePaymentAutopayDate`: return advanceTilePaymentAutopayDate;
    case `advanceTilePaymentDeliveredButton`: return advanceTilePaymentDeliveredButton;
    case `advanceTilePaymentDeliveredTitle`: return advanceTilePaymentDeliveredTitle;
    case `advanceTilePaymentHistoryText`: return advanceTilePaymentHistoryText;
    case `advanceTileProcessingDelayBanner`: return advanceTileProcessingDelayBanner;
    case `advanceTileProcessingTitle`: return advanceTileProcessingTitle;
    case `advanceTileSubsequentButton`: return advanceTileSubsequentButton;
    case `advanceTileSubsequentCaption`: return advanceTileSubsequentCaption;
    case `advanceTileSubsequentTitle`: return advanceTileSubsequentTitle;
    case `advanceUnlockCreditLineSubtitle`: return advanceUnlockCreditLineSubtitle;
    case `advanceUnlockCreditLineTitle`: return advanceUnlockCreditLineTitle;
    case `appLockAuthReason`: return appLockAuthReason;
    case `appLockLabelButtonLogout`: return appLockLabelButtonLogout;
    case `appLockLabelButtonUnlock`: return appLockLabelButtonUnlock;
    case `appLockLabelStatusError`: return appLockLabelStatusError;
    case `appLockLabelStatusInitializing`: return appLockLabelStatusInitializing;
    case `appLockLabelStatusLocked`: return appLockLabelStatusLocked;
    case `appLockLabelStatusNew`: return appLockLabelStatusNew;
    case `appLockLabelStatusUnlocked`: return appLockLabelStatusUnlocked;
    case `appLockLabelStatusUnlocking`: return appLockLabelStatusUnlocking;
    case `appRatingRatingInstructions`: return appRatingRatingInstructions;
    case `appRatingReviewInstructions`: return appRatingReviewInstructions;
    case `appRatingSendButton`: return appRatingSendButton;
    case `appRatingSkipButton`: return appRatingSkipButton;
    case `appRatingThankYouPopupTitle`: return appRatingThankYouPopupTitle;
    case `appRatingTitle`: return appRatingTitle;
    case `appUnavailableMessage`: return appUnavailableMessage;
    case `appUnavailableTitle`: return appUnavailableTitle;
    case `bankAccountRowAccountNumber`: return bankAccountRowAccountNumber;
    case `bankAccountRowInstitutionName`: return bankAccountRowInstitutionName;
    case `bankAccountRowIsDefault`: return bankAccountRowIsDefault;
    case `bannersDefaultLoadingText`: return bannersDefaultLoadingText;
    case `bannersEmailVerificationBannerAction`: return bannersEmailVerificationBannerAction;
    case `bannersEmailVerificationBannerLoadingText`: return bannersEmailVerificationBannerLoadingText;
    case `bannersEmailVerificationBannerMessage`: return bannersEmailVerificationBannerMessage;
    case `bannersEmailVerificationBannerTitle`: return bannersEmailVerificationBannerTitle;
    case `bannersGridPlusSubscriptionOverdueAction`: return bannersGridPlusSubscriptionOverdueAction;
    case `bannersGridPlusSubscriptionOverdueMessage`: return bannersGridPlusSubscriptionOverdueMessage;
    case `bannersGridPlusSubscriptionOverdueTitle`: return bannersGridPlusSubscriptionOverdueTitle;
    case `bannersPayrollIssueAction`: return bannersPayrollIssueAction;
    case `bannersPayrollIssueMessage`: return bannersPayrollIssueMessage;
    case `bannersPayrollIssueTitle`: return bannersPayrollIssueTitle;
    case `bannersTwoFAAction`: return bannersTwoFAAction;
    case `bannersTwoFAMessage`: return bannersTwoFAMessage;
    case `bannersTwoFATitle`: return bannersTwoFATitle;
    case `bannersW4AdjustmentAdpW4AppliedForClientMessage`: return bannersW4AdjustmentAdpW4AppliedForClientMessage;
    case `bannersW4AdjustmentAdpW4Message`: return bannersW4AdjustmentAdpW4Message;
    case `bannersW4AdjustmentDismiss`: return bannersW4AdjustmentDismiss;
    case `bannersW4AdjustmentTitle`: return bannersW4AdjustmentTitle;
    case `bannersWorkflowStepWaitForBoostInProgressMessage`: return bannersWorkflowStepWaitForBoostInProgressMessage;
    case `bannersWorkflowStepWaitForBoostInProgressTitle`: return bannersWorkflowStepWaitForBoostInProgressTitle;
    case `bannersWorkflowStepWaitForBoostReadyAction`: return bannersWorkflowStepWaitForBoostReadyAction;
    case `bannersWorkflowStepWaitForBoostReadyMessage`: return bannersWorkflowStepWaitForBoostReadyMessage;
    case `bannersWorkflowStepWaitForBoostReadyTitle`: return bannersWorkflowStepWaitForBoostReadyTitle;
    case `bogusKey`: return bogusKey;
    case `boostAutoDeliveryEnrollCTA`: return boostAutoDeliveryEnrollCTA;
    case `boostAutoDeliveryEnrollmentMessage`: return boostAutoDeliveryEnrollmentMessage;
    case `boostAutoDeliveryEnrollmentTitle`: return boostAutoDeliveryEnrollmentTitle;
    case `boostAutoDeliveryInfoBenefits1`: return boostAutoDeliveryInfoBenefits1;
    case `boostAutoDeliveryInfoBenefits2`: return boostAutoDeliveryInfoBenefits2;
    case `boostAutoDeliveryInfoBenefits3`: return boostAutoDeliveryInfoBenefits3;
    case `boostAutoDeliveryInfoBenefits4`: return boostAutoDeliveryInfoBenefits4;
    case `boostAutoDeliveryInfoBenefitsTitle`: return boostAutoDeliveryInfoBenefitsTitle;
    case `boostAutoDeliveryInfoCardCTA`: return boostAutoDeliveryInfoCardCTA;
    case `boostAutoDeliveryInfoEnrollCTA`: return boostAutoDeliveryInfoEnrollCTA;
    case `boostAutoDeliveryInfoSubtitle`: return boostAutoDeliveryInfoSubtitle;
    case `boostAutoDeliveryInfoTitle`: return boostAutoDeliveryInfoTitle;
    case `boostAutoDeliveryProcessingSubtitle`: return boostAutoDeliveryProcessingSubtitle;
    case `boostAutoDeliveryProcessingTitle`: return boostAutoDeliveryProcessingTitle;
    case `boostCardAccountClosedError`: return boostCardAccountClosedError;
    case `boostCardAccountRequirementsBody`: return boostCardAccountRequirementsBody;
    case `boostCardAccountRequirementsCriteria60Days`: return boostCardAccountRequirementsCriteria60Days;
    case `boostCardAccountRequirementsCriteriaActiveAccount`: return boostCardAccountRequirementsCriteriaActiveAccount;
    case `boostCardAccountRequirementsCriteriaMinimum1KDeposits`: return boostCardAccountRequirementsCriteriaMinimum1KDeposits;
    case `boostCardAccountRequirementsCriteriaMinimumSpending`: return boostCardAccountRequirementsCriteriaMinimumSpending;
    case `boostCardAccountRequirementsCta`: return boostCardAccountRequirementsCta;
    case `boostCardAccountRequirementsHeader`: return boostCardAccountRequirementsHeader;
    case `boostCardAccountRequirementsTitle`: return boostCardAccountRequirementsTitle;
    case `boostCardActivateCheckYourMailTileCTA`: return boostCardActivateCheckYourMailTileCTA;
    case `boostCardActivateCheckYourMailTileSubtitle`: return boostCardActivateCheckYourMailTileSubtitle;
    case `boostCardActivateCheckYourMailTileTitle`: return boostCardActivateCheckYourMailTileTitle;
    case `boostCardActivateDeliveryIssueTileCTA`: return boostCardActivateDeliveryIssueTileCTA;
    case `boostCardActivateDeliveryIssueTileSubtitle`: return boostCardActivateDeliveryIssueTileSubtitle;
    case `boostCardActivateDeliveryIssueTileTitle`: return boostCardActivateDeliveryIssueTileTitle;
    case `boostCardActivateOrderCardTileCTA`: return boostCardActivateOrderCardTileCTA;
    case `boostCardActivateOrderCardTileSubtitle`: return boostCardActivateOrderCardTileSubtitle;
    case `boostCardActivateOrderCardTileTitle`: return boostCardActivateOrderCardTileTitle;
    case `boostCardActivatedAppleScreenSubtitle`: return boostCardActivatedAppleScreenSubtitle;
    case `boostCardActivatedAppleScreenTitle`: return boostCardActivatedAppleScreenTitle;
    case `boostCardActivatedGoogleScreenSubtitle`: return boostCardActivatedGoogleScreenSubtitle;
    case `boostCardActivatedGoogleScreenTitle`: return boostCardActivatedGoogleScreenTitle;
    case `boostCardActivation4DigitsScreenSubtitle`: return boostCardActivation4DigitsScreenSubtitle;
    case `boostCardActivationCVVScreenSubtitle`: return boostCardActivationCVVScreenSubtitle;
    case `boostCardActivationCVVScreenTitle`: return boostCardActivationCVVScreenTitle;
    case `boostCardActivationConfirmPINScreenTitle`: return boostCardActivationConfirmPINScreenTitle;
    case `boostCardActivationScreenTitle`: return boostCardActivationScreenTitle;
    case `boostCardActivationSetPINScreenSubtitle`: return boostCardActivationSetPINScreenSubtitle;
    case `boostCardActivationSetPINScreenTitle`: return boostCardActivationSetPINScreenTitle;
    case `boostCardBankAccountDataNotAvailable`: return boostCardBankAccountDataNotAvailable;
    case `boostCardBankingAccountButtonTitle`: return boostCardBankingAccountButtonTitle;
    case `boostCardBankingAccountDetailsAccountNumber`: return boostCardBankingAccountDetailsAccountNumber;
    case `boostCardBankingAccountDetailsDirectDepositContent`: return boostCardBankingAccountDetailsDirectDepositContent;
    case `boostCardBankingAccountDetailsRoutingNumber`: return boostCardBankingAccountDetailsRoutingNumber;
    case `boostCardBankingAccountDetailsTitle`: return boostCardBankingAccountDetailsTitle;
    case `boostCardBankingAccountNumbers`: return boostCardBankingAccountNumbers;
    case `boostCardBankingActionsMoveMoney`: return boostCardBankingActionsMoveMoney;
    case `boostCardBankingActionsTitle`: return boostCardBankingActionsTitle;
    case `boostCardBankingAddressSubtitle`: return boostCardBankingAddressSubtitle;
    case `boostCardBankingAddressTextFieldPrompt`: return boostCardBankingAddressTextFieldPrompt;
    case `boostCardBankingAmountViewAddMoneyCTA`: return boostCardBankingAmountViewAddMoneyCTA;
    case `boostCardBankingAmountViewTitle`: return boostCardBankingAmountViewTitle;
    case `boostCardBankingCheckoutAddPaymentMethodCTA`: return boostCardBankingCheckoutAddPaymentMethodCTA;
    case `boostCardBankingCheckoutCTA`: return boostCardBankingCheckoutCTA;
    case `boostCardBankingCheckoutCardTypeHeader`: return boostCardBankingCheckoutCardTypeHeader;
    case `boostCardBankingCheckoutCardTypeIvory`: return boostCardBankingCheckoutCardTypeIvory;
    case `boostCardBankingCheckoutChangePaymentMethodCTA`: return boostCardBankingCheckoutChangePaymentMethodCTA;
    case `boostCardBankingCheckoutDeliveryAddressHeader`: return boostCardBankingCheckoutDeliveryAddressHeader;
    case `boostCardBankingCheckoutErrorNoPaymentMethodContent`: return boostCardBankingCheckoutErrorNoPaymentMethodContent;
    case `boostCardBankingCheckoutErrorNoPaymentMethodTitle`: return boostCardBankingCheckoutErrorNoPaymentMethodTitle;
    case `boostCardBankingCheckoutErrorOutstandingAdvance`: return boostCardBankingCheckoutErrorOutstandingAdvance;
    case `boostCardBankingCheckoutHeader`: return boostCardBankingCheckoutHeader;
    case `boostCardBankingCheckoutLoadingContent`: return boostCardBankingCheckoutLoadingContent;
    case `boostCardBankingCheckoutLoadingTitle`: return boostCardBankingCheckoutLoadingTitle;
    case `boostCardBankingCheckoutOutstandingAdvanceAmount`: return boostCardBankingCheckoutOutstandingAdvanceAmount;
    case `boostCardBankingCheckoutOutstandingAdvanceContent`: return boostCardBankingCheckoutOutstandingAdvanceContent;
    case `boostCardBankingCheckoutOutstandingAdvanceHeader`: return boostCardBankingCheckoutOutstandingAdvanceHeader;
    case `boostCardBankingCheckoutRetryButtonTitle`: return boostCardBankingCheckoutRetryButtonTitle;
    case `boostCardBankingCheckoutSubscriptionExisting`: return boostCardBankingCheckoutSubscriptionExisting;
    case `boostCardBankingCheckoutSubscriptionExistingMember`: return boostCardBankingCheckoutSubscriptionExistingMember;
    case `boostCardBankingCheckoutSubscriptionGridFree`: return boostCardBankingCheckoutSubscriptionGridFree;
    case `boostCardBankingCheckoutSubscriptionGridPlus`: return boostCardBankingCheckoutSubscriptionGridPlus;
    case `boostCardBankingCheckoutSubscriptionHeader`: return boostCardBankingCheckoutSubscriptionHeader;
    case `boostCardBankingCheckoutSubscriptionNewMember`: return boostCardBankingCheckoutSubscriptionNewMember;
    case `boostCardBankingCheckoutSubscriptionOverdue`: return boostCardBankingCheckoutSubscriptionOverdue;
    case `boostCardBankingCheckoutTermsCardholderAgreementSection`: return boostCardBankingCheckoutTermsCardholderAgreementSection;
    case `boostCardBankingCheckoutTermsCardholderAgreementSectionBoldQuery`: return boostCardBankingCheckoutTermsCardholderAgreementSectionBoldQuery;
    case `boostCardBankingCheckoutTermsConsumerLoanAgreementSection`: return boostCardBankingCheckoutTermsConsumerLoanAgreementSection;
    case `boostCardBankingCheckoutTermsConsumerLoanAgreementSectionBoldQuery`: return boostCardBankingCheckoutTermsConsumerLoanAgreementSectionBoldQuery;
    case `boostCardBankingCheckoutTermsContinueCTA`: return boostCardBankingCheckoutTermsContinueCTA;
    case `boostCardBankingCheckoutTermsDepositAgreementSection`: return boostCardBankingCheckoutTermsDepositAgreementSection;
    case `boostCardBankingCheckoutTermsDepositAgreementSectionBoldQuery`: return boostCardBankingCheckoutTermsDepositAgreementSectionBoldQuery;
    case `boostCardBankingCheckoutTermsHeaderSection`: return boostCardBankingCheckoutTermsHeaderSection;
    case `boostCardBankingCheckoutTermsTitle`: return boostCardBankingCheckoutTermsTitle;
    case `boostCardBankingDepositMoney`: return boostCardBankingDepositMoney;
    case `boostCardBankingDisputesCancelingRecurringChargeContactPerson`: return boostCardBankingDisputesCancelingRecurringChargeContactPerson;
    case `boostCardBankingDisputesCancelingRecurringChargeDateCancelled`: return boostCardBankingDisputesCancelingRecurringChargeDateCancelled;
    case `boostCardBankingDisputesCancelingRecurringChargeTitle`: return boostCardBankingDisputesCancelingRecurringChargeTitle;
    case `boostCardBankingDisputesCancelingRecurringChargeVendorContactMethod`: return boostCardBankingDisputesCancelingRecurringChargeVendorContactMethod;
    case `boostCardBankingDisputesCancelingRecurringChargeVendorResponse`: return boostCardBankingDisputesCancelingRecurringChargeVendorResponse;
    case `boostCardBankingDisputesCardLostOrStolenDateLostOrStolen`: return boostCardBankingDisputesCardLostOrStolenDateLostOrStolen;
    case `boostCardBankingDisputesCardLostOrStolenReason`: return boostCardBankingDisputesCardLostOrStolenReason;
    case `boostCardBankingDisputesCardLostOrStolenTitle`: return boostCardBankingDisputesCardLostOrStolenTitle;
    case `boostCardBankingDisputesConfirmationScreenTitle`: return boostCardBankingDisputesConfirmationScreenTitle;
    case `boostCardBankingDisputesCreditNotProcessedDuplicateAttachDisputeTransactionFiles`: return boostCardBankingDisputesCreditNotProcessedDuplicateAttachDisputeTransactionFiles;
    case `boostCardBankingDisputesCreditNotProcessedDuplicateBothTransactionsSameCard`: return boostCardBankingDisputesCreditNotProcessedDuplicateBothTransactionsSameCard;
    case `boostCardBankingDisputesCreditNotProcessedDuplicateMerchantGivenChanceToResolve`: return boostCardBankingDisputesCreditNotProcessedDuplicateMerchantGivenChanceToResolve;
    case `boostCardBankingDisputesCreditNotProcessedDuplicateTitle`: return boostCardBankingDisputesCreditNotProcessedDuplicateTitle;
    case `boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansAttachDisputeTransactionFiles`: return boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansAttachDisputeTransactionFiles;
    case `boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansBothTransactionsSameCard`: return boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansBothTransactionsSameCard;
    case `boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansMerchantGivenChanceToResolve`: return boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansMerchantGivenChanceToResolve;
    case `boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansTitle`: return boostCardBankingDisputesCreditNotProcessedPaidByOtherMeansTitle;
    case `boostCardBankingDisputesDamagedMerchandise`: return boostCardBankingDisputesDamagedMerchandise;
    case `boostCardBankingDisputesDamagedMerchandiseContactMethod`: return boostCardBankingDisputesDamagedMerchandiseContactMethod;
    case `boostCardBankingDisputesDamagedMerchandiseDescription`: return boostCardBankingDisputesDamagedMerchandiseDescription;
    case `boostCardBankingDisputesDamagedMerchandiseMerchantName`: return boostCardBankingDisputesDamagedMerchandiseMerchantName;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseContactDate`: return boostCardBankingDisputesDidNotReceiveMerchandiseContactDate;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseContactMethod`: return boostCardBankingDisputesDidNotReceiveMerchandiseContactMethod;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseContactName`: return boostCardBankingDisputesDidNotReceiveMerchandiseContactName;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseItemDelivered`: return boostCardBankingDisputesDidNotReceiveMerchandiseItemDelivered;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseMerchantResponse`: return boostCardBankingDisputesDidNotReceiveMerchandiseMerchantResponse;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseOrderCancelled`: return boostCardBankingDisputesDidNotReceiveMerchandiseOrderCancelled;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseOrderExpectedArrival`: return boostCardBankingDisputesDidNotReceiveMerchandiseOrderExpectedArrival;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseOrderExpectedArrivalDate`: return boostCardBankingDisputesDidNotReceiveMerchandiseOrderExpectedArrivalDate;
    case `boostCardBankingDisputesDidNotReceiveMerchandisePrepaidItem`: return boostCardBankingDisputesDidNotReceiveMerchandisePrepaidItem;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseTitle`: return boostCardBankingDisputesDidNotReceiveMerchandiseTitle;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseWasItemDelivered`: return boostCardBankingDisputesDidNotReceiveMerchandiseWasItemDelivered;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseWasItemPrepaid`: return boostCardBankingDisputesDidNotReceiveMerchandiseWasItemPrepaid;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseWasOrderCanceled`: return boostCardBankingDisputesDidNotReceiveMerchandiseWasOrderCanceled;
    case `boostCardBankingDisputesDidNotReceiveMerchandiseWhenWasOrderCanceled`: return boostCardBankingDisputesDidNotReceiveMerchandiseWhenWasOrderCanceled;
    case `boostCardBankingDisputesGenericDisputesDescription`: return boostCardBankingDisputesGenericDisputesDescription;
    case `boostCardBankingDisputesGenericDisputesTransactionChargedTwice`: return boostCardBankingDisputesGenericDisputesTransactionChargedTwice;
    case `boostCardBankingDisputesGenericDisputesUnauthorizedCharge`: return boostCardBankingDisputesGenericDisputesUnauthorizedCharge;
    case `boostCardBankingDisputesHeaderTitle`: return boostCardBankingDisputesHeaderTitle;
    case `boostCardBankingDisputesIncorrectAmountAuthorizedAmount`: return boostCardBankingDisputesIncorrectAmountAuthorizedAmount;
    case `boostCardBankingDisputesIncorrectAmountTitle`: return boostCardBankingDisputesIncorrectAmountTitle;
    case `boostCardBankingDisputesInitialTitle`: return boostCardBankingDisputesInitialTitle;
    case `boostCardBankingDisputesMerchantContactDate`: return boostCardBankingDisputesMerchantContactDate;
    case `boostCardBankingDisputesMerchantContactName`: return boostCardBankingDisputesMerchantContactName;
    case `boostCardBankingDisputesMerchantMethodUsedToContactMerchant`: return boostCardBankingDisputesMerchantMethodUsedToContactMerchant;
    case `boostCardBankingDisputesMerchantResponse`: return boostCardBankingDisputesMerchantResponse;
    case `boostCardBankingDisputesQualityOfServicesOrMerchandise`: return boostCardBankingDisputesQualityOfServicesOrMerchandise;
    case `boostCardBankingDisputesQualityOfServicesOrMerchandiseContactMethod`: return boostCardBankingDisputesQualityOfServicesOrMerchandiseContactMethod;
    case `boostCardBankingDisputesQualityOfServicesOrMerchandiseDescription`: return boostCardBankingDisputesQualityOfServicesOrMerchandiseDescription;
    case `boostCardBankingDisputesQualityOfServicesOrMerchandiseMerchantName`: return boostCardBankingDisputesQualityOfServicesOrMerchandiseMerchantName;
    case `boostCardBankingDisputesReturnedMerchandiseContactMethod`: return boostCardBankingDisputesReturnedMerchandiseContactMethod;
    case `boostCardBankingDisputesReturnedMerchandiseDescription`: return boostCardBankingDisputesReturnedMerchandiseDescription;
    case `boostCardBankingDisputesReturnedMerchandiseItemDescription`: return boostCardBankingDisputesReturnedMerchandiseItemDescription;
    case `boostCardBankingDisputesReturnedMerchandiseMerchantName`: return boostCardBankingDisputesReturnedMerchandiseMerchantName;
    case `boostCardBankingDisputesReturnedMerchandiseReasonForReturnOrCancellation`: return boostCardBankingDisputesReturnedMerchandiseReasonForReturnOrCancellation;
    case `boostCardBankingDisputesReturnedMerchandiseTitle`: return boostCardBankingDisputesReturnedMerchandiseTitle;
    case `boostCardBankingDisputesReturnedMerchandiseWasACancellationPolicyProvided`: return boostCardBankingDisputesReturnedMerchandiseWasACancellationPolicyProvided;
    case `boostCardBankingDisputesReturnedMerchandiseWasMerchantGivenChanceToResolve`: return boostCardBankingDisputesReturnedMerchandiseWasMerchantGivenChanceToResolve;
    case `boostCardBankingDisputesReturnedMerchandiseWasOrderCanceled`: return boostCardBankingDisputesReturnedMerchandiseWasOrderCanceled;
    case `boostCardBankingDisputesReturnedMerchandiseWasRecurringPayment`: return boostCardBankingDisputesReturnedMerchandiseWasRecurringPayment;
    case `boostCardBankingDisputesReturnedMerchandiseWasReturned`: return boostCardBankingDisputesReturnedMerchandiseWasReturned;
    case `boostCardBankingDisputesReturnedMerchandiseWhenWasOrderCanceled`: return boostCardBankingDisputesReturnedMerchandiseWhenWasOrderCanceled;
    case `boostCardBankingDisputesReturnedWhenDidYouContactToCancelTheReturnPayment`: return boostCardBankingDisputesReturnedWhenDidYouContactToCancelTheReturnPayment;
    case `boostCardBankingDisputesReturnedWhenWasTheRecurringPaymentCanceled`: return boostCardBankingDisputesReturnedWhenWasTheRecurringPaymentCanceled;
    case `boostCardBankingDisputesSubformCTA`: return boostCardBankingDisputesSubformCTA;
    case `boostCardBankingDisputesSubmitCTATitle`: return boostCardBankingDisputesSubmitCTATitle;
    case `boostCardBankingDisputesSuccessCTATitle`: return boostCardBankingDisputesSuccessCTATitle;
    case `boostCardBankingDisputesSuccessContent`: return boostCardBankingDisputesSuccessContent;
    case `boostCardBankingDisputesSuccessTitle`: return boostCardBankingDisputesSuccessTitle;
    case `boostCardBankingDisputesTopLevelFormCTA`: return boostCardBankingDisputesTopLevelFormCTA;
    case `boostCardBankingDisputesTransactionChargedTwiceBothTransactionsSameCard`: return boostCardBankingDisputesTransactionChargedTwiceBothTransactionsSameCard;
    case `boostCardBankingDisputesTransactionChargedTwiceMerchantGivenChanceToResolve`: return boostCardBankingDisputesTransactionChargedTwiceMerchantGivenChanceToResolve;
    case `boostCardBankingDisputesTransactionChargedTwiceTitle`: return boostCardBankingDisputesTransactionChargedTwiceTitle;
    case `boostCardBankingDisputesTransactionChargedTwiceTitleNoFieldsDescription`: return boostCardBankingDisputesTransactionChargedTwiceTitleNoFieldsDescription;
    case `boostCardBankingDisputesTransactionChargedTwiceTitleNoFieldsTitle`: return boostCardBankingDisputesTransactionChargedTwiceTitleNoFieldsTitle;
    case `boostCardBankingDisputesTransactionChargedTwiceUploadImage`: return boostCardBankingDisputesTransactionChargedTwiceUploadImage;
    case `boostCardBankingDisputesUnauthorizedCharge`: return boostCardBankingDisputesUnauthorizedCharge;
    case `boostCardBankingDisputesUnauthorizedChargeNoFieldsDescription`: return boostCardBankingDisputesUnauthorizedChargeNoFieldsDescription;
    case `boostCardBankingDisputesUnauthorizedChargeNoFieldsTitle`: return boostCardBankingDisputesUnauthorizedChargeNoFieldsTitle;
    case `boostCardBankingDisputesUploadQuestionCTA`: return boostCardBankingDisputesUploadQuestionCTA;
    case `boostCardBankingDisputesUploadQuestionTitle`: return boostCardBankingDisputesUploadQuestionTitle;
    case `boostCardBankingDisputesYesNoQuestionNo`: return boostCardBankingDisputesYesNoQuestionNo;
    case `boostCardBankingDisputesYesNoQuestionYes`: return boostCardBankingDisputesYesNoQuestionYes;
    case `boostCardBankingInterstitialCTA`: return boostCardBankingInterstitialCTA;
    case `boostCardBankingInterstitialCarouselTileBoostCardContent`: return boostCardBankingInterstitialCarouselTileBoostCardContent;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepOne`: return boostCardBankingInterstitialCarouselTileBoostCardStepOne;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepOneContent`: return boostCardBankingInterstitialCarouselTileBoostCardStepOneContent;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepOneTitle`: return boostCardBankingInterstitialCarouselTileBoostCardStepOneTitle;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepThree`: return boostCardBankingInterstitialCarouselTileBoostCardStepThree;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepThreeContent`: return boostCardBankingInterstitialCarouselTileBoostCardStepThreeContent;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepThreeTitle`: return boostCardBankingInterstitialCarouselTileBoostCardStepThreeTitle;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepTwo`: return boostCardBankingInterstitialCarouselTileBoostCardStepTwo;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepTwoContent`: return boostCardBankingInterstitialCarouselTileBoostCardStepTwoContent;
    case `boostCardBankingInterstitialCarouselTileBoostCardStepTwoTitle`: return boostCardBankingInterstitialCarouselTileBoostCardStepTwoTitle;
    case `boostCardBankingInterstitialCarouselTileBoostCardTitle`: return boostCardBankingInterstitialCarouselTileBoostCardTitle;
    case `boostCardBankingInterstitialCarouselTileCashboostContent`: return boostCardBankingInterstitialCarouselTileCashboostContent;
    case `boostCardBankingInterstitialCarouselTileCashboostTitle`: return boostCardBankingInterstitialCarouselTileCashboostTitle;
    case `boostCardBankingInterstitialCarouselTilePayboostContent`: return boostCardBankingInterstitialCarouselTilePayboostContent;
    case `boostCardBankingInterstitialCarouselTilePayboostTitle`: return boostCardBankingInterstitialCarouselTilePayboostTitle;
    case `boostCardBankingInterstitialCarouselTileTaxFilingContent`: return boostCardBankingInterstitialCarouselTileTaxFilingContent;
    case `boostCardBankingInterstitialCarouselTileTaxFilingTitle`: return boostCardBankingInterstitialCarouselTileTaxFilingTitle;
    case `boostCardBankingInterstitialHowTitle`: return boostCardBankingInterstitialHowTitle;
    case `boostCardBankingInterstitialSubtitle`: return boostCardBankingInterstitialSubtitle;
    case `boostCardBankingInterstitialTitle`: return boostCardBankingInterstitialTitle;
    case `boostCardBankingLinkedAccounts`: return boostCardBankingLinkedAccounts;
    case `boostCardBankingQualificationApprovedCTA`: return boostCardBankingQualificationApprovedCTA;
    case `boostCardBankingQualificationApprovedContent`: return boostCardBankingQualificationApprovedContent;
    case `boostCardBankingQualificationApprovedTitle`: return boostCardBankingQualificationApprovedTitle;
    case `boostCardBankingQualificationApprovedTitleBottom`: return boostCardBankingQualificationApprovedTitleBottom;
    case `boostCardBankingQualificationApprovedTitleTop`: return boostCardBankingQualificationApprovedTitleTop;
    case `boostCardBankingQualificationBlockedContent`: return boostCardBankingQualificationBlockedContent;
    case `boostCardBankingQualificationBlockedTitle`: return boostCardBankingQualificationBlockedTitle;
    case `boostCardBankingQualificationProductBlockedContent`: return boostCardBankingQualificationProductBlockedContent;
    case `boostCardBankingQualificationProductBlockedTitle`: return boostCardBankingQualificationProductBlockedTitle;
    case `boostCardBankingSetupCompleteCTA`: return boostCardBankingSetupCompleteCTA;
    case `boostCardBankingSetupCompleteTitle`: return boostCardBankingSetupCompleteTitle;
    case `boostCardBankingTransactionDisputeCta`: return boostCardBankingTransactionDisputeCta;
    case `boostCardBankingTransactionOtherCta`: return boostCardBankingTransactionOtherCta;
    case `boostCardBankingTransactionProblemContentTitle`: return boostCardBankingTransactionProblemContentTitle;
    case `boostCardBankingTransactionProblemTitle`: return boostCardBankingTransactionProblemTitle;
    case `boostCardBankingTransactionUnknownTransactionCta`: return boostCardBankingTransactionUnknownTransactionCta;
    case `boostCardBankingTransactionsListPendingSectionTitle`: return boostCardBankingTransactionsListPendingSectionTitle;
    case `boostCardBankingTransactionsListTitle`: return boostCardBankingTransactionsListTitle;
    case `boostCardBankingTransactionsTileHeader`: return boostCardBankingTransactionsTileHeader;
    case `boostCardBankingTransactionsTileHeaderToday`: return boostCardBankingTransactionsTileHeaderToday;
    case `boostCardBankingTransactionsTileHeaderYesterday`: return boostCardBankingTransactionsTileHeaderYesterday;
    case `boostCardBankingTransactionsTileNoHistory`: return boostCardBankingTransactionsTileNoHistory;
    case `boostCardBankingTransactionsTileSeeAllCTA`: return boostCardBankingTransactionsTileSeeAllCTA;
    case `boostCardBankingTransactionsTileUnhandledTransactions`: return boostCardBankingTransactionsTileUnhandledTransactions;
    case `boostCardBankingTransferAmountTitle`: return boostCardBankingTransferAmountTitle;
    case `boostCardBankingTransferAmountTitlePrompt`: return boostCardBankingTransferAmountTitlePrompt;
    case `boostCardBankingTransferBalanceAvailable`: return boostCardBankingTransferBalanceAvailable;
    case `boostCardBankingTransferBoostCardTitle`: return boostCardBankingTransferBoostCardTitle;
    case `boostCardBankingTransferCTA`: return boostCardBankingTransferCTA;
    case `boostCardBankingTransferChooseAccountTitle`: return boostCardBankingTransferChooseAccountTitle;
    case `boostCardBankingTransferConfirmAvailabilityContent`: return boostCardBankingTransferConfirmAvailabilityContent;
    case `boostCardBankingTransferConfirmAvailabilityTitle`: return boostCardBankingTransferConfirmAvailabilityTitle;
    case `boostCardBankingTransferConfirmCTA`: return boostCardBankingTransferConfirmCTA;
    case `boostCardBankingTransferConfirmTitle`: return boostCardBankingTransferConfirmTitle;
    case `boostCardBankingTransferConnectBankAccountTitle`: return boostCardBankingTransferConnectBankAccountTitle;
    case `boostCardBankingTransferCreditLineTitle`: return boostCardBankingTransferCreditLineTitle;
    case `boostCardBankingTransferErrorMessageGenericFailBody`: return boostCardBankingTransferErrorMessageGenericFailBody;
    case `boostCardBankingTransferErrorMessageGenericFailTitle`: return boostCardBankingTransferErrorMessageGenericFailTitle;
    case `boostCardBankingTransferErrorMessageNoAmount`: return boostCardBankingTransferErrorMessageNoAmount;
    case `boostCardBankingTransferErrorMessageNoFrequency`: return boostCardBankingTransferErrorMessageNoFrequency;
    case `boostCardBankingTransferErrorMessageNoReceivingAccount`: return boostCardBankingTransferErrorMessageNoReceivingAccount;
    case `boostCardBankingTransferErrorMessageNoSendingAccount`: return boostCardBankingTransferErrorMessageNoSendingAccount;
    case `boostCardBankingTransferFixAccountTitle`: return boostCardBankingTransferFixAccountTitle;
    case `boostCardBankingTransferFrequencyTitle`: return boostCardBankingTransferFrequencyTitle;
    case `boostCardBankingTransferFromTitle`: return boostCardBankingTransferFromTitle;
    case `boostCardBankingTransferIntroDepositTitle`: return boostCardBankingTransferIntroDepositTitle;
    case `boostCardBankingTransferIntroMethodBankTransfer`: return boostCardBankingTransferIntroMethodBankTransfer;
    case `boostCardBankingTransferIntroMethodBankTransferSubtitle`: return boostCardBankingTransferIntroMethodBankTransferSubtitle;
    case `boostCardBankingTransferIntroMethodCreditLine`: return boostCardBankingTransferIntroMethodCreditLine;
    case `boostCardBankingTransferIntroMethodCreditLineSubtitle`: return boostCardBankingTransferIntroMethodCreditLineSubtitle;
    case `boostCardBankingTransferIntroMethodDirectDeposit`: return boostCardBankingTransferIntroMethodDirectDeposit;
    case `boostCardBankingTransferIntroMethodDirectDepositSubtitle`: return boostCardBankingTransferIntroMethodDirectDepositSubtitle;
    case `boostCardBankingTransferIntroMethodTransferMoneyOut`: return boostCardBankingTransferIntroMethodTransferMoneyOut;
    case `boostCardBankingTransferIntroWithdrawalTitle`: return boostCardBankingTransferIntroWithdrawalTitle;
    case `boostCardBankingTransferOnceTerms`: return boostCardBankingTransferOnceTerms;
    case `boostCardBankingTransferRecurringTerms`: return boostCardBankingTransferRecurringTerms;
    case `boostCardBankingTransferTitle`: return boostCardBankingTransferTitle;
    case `boostCardBankingTransferToTitle`: return boostCardBankingTransferToTitle;
    case `boostCardBankingWithdrawMoney`: return boostCardBankingWithdrawMoney;
    case `boostCardCVV`: return boostCardCVV;
    case `boostCardCVVHidden`: return boostCardCVVHidden;
    case `boostCardCardActivationActivateTileCTA`: return boostCardCardActivationActivateTileCTA;
    case `boostCardCardActivationActivateTileContent`: return boostCardCardActivationActivateTileContent;
    case `boostCardCardActivationActivateTileTitle`: return boostCardCardActivationActivateTileTitle;
    case `boostCardCardActivationCanceledTileCTA`: return boostCardCardActivationCanceledTileCTA;
    case `boostCardCardActivationCanceledTileContent`: return boostCardCardActivationCanceledTileContent;
    case `boostCardCardActivationCanceledTileTitle`: return boostCardCardActivationCanceledTileTitle;
    case `boostCardCardActivationProcessingTileContent`: return boostCardCardActivationProcessingTileContent;
    case `boostCardCardActivationProcessingTileTitle`: return boostCardCardActivationProcessingTileTitle;
    case `boostCardCardOnboardingCTA`: return boostCardCardOnboardingCTA;
    case `boostCardCardTodoTileTitle`: return boostCardCardTodoTileTitle;
    case `boostCardChangePinConfirmNewPinContinueCTA`: return boostCardChangePinConfirmNewPinContinueCTA;
    case `boostCardChangePinConfirmNewPinTitle`: return boostCardChangePinConfirmNewPinTitle;
    case `boostCardChangePinCreateNewPinContinueCTA`: return boostCardChangePinCreateNewPinContinueCTA;
    case `boostCardChangePinCreateNewPinTitle`: return boostCardChangePinCreateNewPinTitle;
    case `boostCardChangePinIncorrectLast4SSN`: return boostCardChangePinIncorrectLast4SSN;
    case `boostCardChangePinIncorrectLast4SSNLimitReached`: return boostCardChangePinIncorrectLast4SSNLimitReached;
    case `boostCardChangePinNewPinConfirmedContinueCTA`: return boostCardChangePinNewPinConfirmedContinueCTA;
    case `boostCardChangePinNewPinConfirmedTitle`: return boostCardChangePinNewPinConfirmedTitle;
    case `boostCardChangePinNewPinsMismatch`: return boostCardChangePinNewPinsMismatch;
    case `boostCardChangePinVerifyPinContinueCTA`: return boostCardChangePinVerifyPinContinueCTA;
    case `boostCardChangePinVerifyPinTitle`: return boostCardChangePinVerifyPinTitle;
    case `boostCardChangePinVerifySSNContinueCTA`: return boostCardChangePinVerifySSNContinueCTA;
    case `boostCardChangePinVerifySSNTitle`: return boostCardChangePinVerifySSNTitle;
    case `boostCardCheckoutPaymentFailed`: return boostCardCheckoutPaymentFailed;
    case `boostCardCloseAccountBalanceRemainingContent`: return boostCardCloseAccountBalanceRemainingContent;
    case `boostCardCloseAccountBalanceRemainingCta`: return boostCardCloseAccountBalanceRemainingCta;
    case `boostCardCloseAccountBalanceRemainingTitle`: return boostCardCloseAccountBalanceRemainingTitle;
    case `boostCardCloseAccountContent`: return boostCardCloseAccountContent;
    case `boostCardCloseAccountCta`: return boostCardCloseAccountCta;
    case `boostCardCloseAccountTitle`: return boostCardCloseAccountTitle;
    case `boostCardCongratsScreenDoneButton`: return boostCardCongratsScreenDoneButton;
    case `boostCardCongratsScreenPoints`: return boostCardCongratsScreenPoints;
    case `boostCardCongratsScreenPointsWorth`: return boostCardCongratsScreenPointsWorth;
    case `boostCardCongratsScreenTitle`: return boostCardCongratsScreenTitle;
    case `boostCardCongratsScreenTitlePointsSubstring`: return boostCardCongratsScreenTitlePointsSubstring;
    case `boostCardCvvIncorrect`: return boostCardCvvIncorrect;
    case `boostCardDeactivatedStateTitle`: return boostCardDeactivatedStateTitle;
    case `boostCardDisputeDateInvalid`: return boostCardDisputeDateInvalid;
    case `boostCardDisputeRequestMissingFields`: return boostCardDisputeRequestMissingFields;
    case `boostCardDisputesWarningBannerSubtitle`: return boostCardDisputesWarningBannerSubtitle;
    case `boostCardDisputesWarningBannerTitle`: return boostCardDisputesWarningBannerTitle;
    case `boostCardExpirationDate`: return boostCardExpirationDate;
    case `boostCardFundingWelcomePromoCents`: return boostCardFundingWelcomePromoCents;
    case `boostCardFundingWelcomePromoDollars`: return boostCardFundingWelcomePromoDollars;
    case `boostCardFundingWelcomePromoPill`: return boostCardFundingWelcomePromoPill;
    case `boostCardFundingWelcomePromoTileBody`: return boostCardFundingWelcomePromoTileBody;
    case `boostCardFundingWelcomePromoTileCTA`: return boostCardFundingWelcomePromoTileCTA;
    case `boostCardFundingWelcomePromoTileTitle`: return boostCardFundingWelcomePromoTileTitle;
    case `boostCardGridLockedErrorContent`: return boostCardGridLockedErrorContent;
    case `boostCardGridLockedErrorTitle`: return boostCardGridLockedErrorTitle;
    case `boostCardIntroInterstitialBulletOne`: return boostCardIntroInterstitialBulletOne;
    case `boostCardIntroInterstitialBulletThree`: return boostCardIntroInterstitialBulletThree;
    case `boostCardIntroInterstitialBulletTwo`: return boostCardIntroInterstitialBulletTwo;
    case `boostCardIntroInterstitialContinueCTA`: return boostCardIntroInterstitialContinueCTA;
    case `boostCardIntroInterstitialTitle`: return boostCardIntroInterstitialTitle;
    case `boostCardKYCBannerCTA`: return boostCardKYCBannerCTA;
    case `boostCardKYCContinueCTA`: return boostCardKYCContinueCTA;
    case `boostCardKYCUploadBackImage`: return boostCardKYCUploadBackImage;
    case `boostCardKYCUploadFailedContent`: return boostCardKYCUploadFailedContent;
    case `boostCardKYCUploadFailedTitle`: return boostCardKYCUploadFailedTitle;
    case `boostCardKYCUploadFrontImage`: return boostCardKYCUploadFrontImage;
    case `boostCardKYCValidFormsListDriversLicense`: return boostCardKYCValidFormsListDriversLicense;
    case `boostCardKYCValidFormsListMilitaryID`: return boostCardKYCValidFormsListMilitaryID;
    case `boostCardKYCValidFormsListPassport`: return boostCardKYCValidFormsListPassport;
    case `boostCardKYCValidFormsListStateIDCard`: return boostCardKYCValidFormsListStateIDCard;
    case `boostCardKYCValidFormsListTitle`: return boostCardKYCValidFormsListTitle;
    case `boostCardLast4DigitsIncorrect`: return boostCardLast4DigitsIncorrect;
    case `boostCardLinkNewAccountOption`: return boostCardLinkNewAccountOption;
    case `boostCardMoneyInputInsufficientFunds`: return boostCardMoneyInputInsufficientFunds;
    case `boostCardMoneyInputMaxTransferContent`: return boostCardMoneyInputMaxTransferContent;
    case `boostCardMoneyInputMaxTransferExceeded`: return boostCardMoneyInputMaxTransferExceeded;
    case `boostCardMoneyInputSaveCTA`: return boostCardMoneyInputSaveCTA;
    case `boostCardMoneyInputTitle`: return boostCardMoneyInputTitle;
    case `boostCardMoneyInputTransferAvailableCredit`: return boostCardMoneyInputTransferAvailableCredit;
    case `boostCardMoneyInputTransferCreditLineExceeded`: return boostCardMoneyInputTransferCreditLineExceeded;
    case `boostCardMoneyInputTransferCreditLinePaymentBalance`: return boostCardMoneyInputTransferCreditLinePaymentBalance;
    case `boostCardMoneyInputTransferCreditLinePaymentBalanceExceeded`: return boostCardMoneyInputTransferCreditLinePaymentBalanceExceeded;
    case `boostCardMoneyInputTransferExternalAccountBalanceExceeded`: return boostCardMoneyInputTransferExternalAccountBalanceExceeded;
    case `boostCardMoneyInputTransferExternalAccountBalanceExceededHelpText`: return boostCardMoneyInputTransferExternalAccountBalanceExceededHelpText;
    case `boostCardMoneyInputTransferWithdrawalAvailableBalance`: return boostCardMoneyInputTransferWithdrawalAvailableBalance;
    case `boostCardMoneyInputTransferWithdrawalAvailableBalanceExceeded`: return boostCardMoneyInputTransferWithdrawalAvailableBalanceExceeded;
    case `boostCardNegativeBalanceTileCTA`: return boostCardNegativeBalanceTileCTA;
    case `boostCardNegativeBalanceTileSbutitle`: return boostCardNegativeBalanceTileSbutitle;
    case `boostCardNegativeBalanceTileTitle`: return boostCardNegativeBalanceTileTitle;
    case `boostCardNotFundedForCreditBoostError`: return boostCardNotFundedForCreditBoostError;
    case `boostCardOptionsAccountNumbers`: return boostCardOptionsAccountNumbers;
    case `boostCardOptionsChangePin`: return boostCardOptionsChangePin;
    case `boostCardOptionsCloseAccount`: return boostCardOptionsCloseAccount;
    case `boostCardOptionsCopyCardNumber`: return boostCardOptionsCopyCardNumber;
    case `boostCardOptionsCopyCardNumberNumberCopied`: return boostCardOptionsCopyCardNumberNumberCopied;
    case `boostCardOptionsFindATM`: return boostCardOptionsFindATM;
    case `boostCardOptionsHideNumbers`: return boostCardOptionsHideNumbers;
    case `boostCardOptionsLockCard`: return boostCardOptionsLockCard;
    case `boostCardOptionsReportProblem`: return boostCardOptionsReportProblem;
    case `boostCardOptionsShowNumbers`: return boostCardOptionsShowNumbers;
    case `boostCardOptionsUnlockCard`: return boostCardOptionsUnlockCard;
    case `boostCardOrderScreenBadgeTitle`: return boostCardOrderScreenBadgeTitle;
    case `boostCardPhysicalActivatedScreenCTAClose`: return boostCardPhysicalActivatedScreenCTAClose;
    case `boostCardPhysicalActivatedScreenCTAContinue`: return boostCardPhysicalActivatedScreenCTAContinue;
    case `boostCardReportProblemCTAContinue`: return boostCardReportProblemCTAContinue;
    case `boostCardReportProblemCTADamaged`: return boostCardReportProblemCTADamaged;
    case `boostCardReportProblemCTALostOrStolen`: return boostCardReportProblemCTALostOrStolen;
    case `boostCardReportProblemContent`: return boostCardReportProblemContent;
    case `boostCardReportProblemDamagedCalloutCTA`: return boostCardReportProblemDamagedCalloutCTA;
    case `boostCardReportProblemDamagedCalloutContent`: return boostCardReportProblemDamagedCalloutContent;
    case `boostCardReportProblemDamagedContent`: return boostCardReportProblemDamagedContent;
    case `boostCardReportProblemDamagedTitle`: return boostCardReportProblemDamagedTitle;
    case `boostCardReportProblemLostOrStolenCalloutCTA`: return boostCardReportProblemLostOrStolenCalloutCTA;
    case `boostCardReportProblemLostOrStolenCalloutContent`: return boostCardReportProblemLostOrStolenCalloutContent;
    case `boostCardReportProblemLostOrStolenContent`: return boostCardReportProblemLostOrStolenContent;
    case `boostCardReportProblemLostOrStolenTitle`: return boostCardReportProblemLostOrStolenTitle;
    case `boostCardReportProblemNotArrivedDescription`: return boostCardReportProblemNotArrivedDescription;
    case `boostCardReportProblemNotArrivedSubtitle`: return boostCardReportProblemNotArrivedSubtitle;
    case `boostCardReportProblemNotArrivedTitle`: return boostCardReportProblemNotArrivedTitle;
    case `boostCardReportProblemOrderConfirmedCTATitle`: return boostCardReportProblemOrderConfirmedCTATitle;
    case `boostCardReportProblemOrderConfirmedInfo`: return boostCardReportProblemOrderConfirmedInfo;
    case `boostCardReportProblemOrderConfirmedSubtitle`: return boostCardReportProblemOrderConfirmedSubtitle;
    case `boostCardReportProblemOrderConfirmedTitle`: return boostCardReportProblemOrderConfirmedTitle;
    case `boostCardReportProblemOrderNewCard`: return boostCardReportProblemOrderNewCard;
    case `boostCardReportProblemOrderPhysicalConfirmedCTATitle`: return boostCardReportProblemOrderPhysicalConfirmedCTATitle;
    case `boostCardReportProblemSuccessCTA`: return boostCardReportProblemSuccessCTA;
    case `boostCardReportProblemSuccessCardTerminatedTitle`: return boostCardReportProblemSuccessCardTerminatedTitle;
    case `boostCardReportProblemSuccessTitle`: return boostCardReportProblemSuccessTitle;
    case `boostCardReportProblemTitle`: return boostCardReportProblemTitle;
    case `boostCardRewards1XTileBadge`: return boostCardRewards1XTileBadge;
    case `boostCardRewards2XTileBadge`: return boostCardRewards2XTileBadge;
    case `boostCardRewardsRedemption2XCongrats`: return boostCardRewardsRedemption2XCongrats;
    case `boostCardRewardsRedemption2XTeaser`: return boostCardRewardsRedemption2XTeaser;
    case `boostCardRewardsRedemptionAvailable`: return boostCardRewardsRedemptionAvailable;
    case `boostCardRewardsRedemptionEarning1XBadge`: return boostCardRewardsRedemptionEarning1XBadge;
    case `boostCardRewardsRedemptionEarning2XBadge`: return boostCardRewardsRedemptionEarning2XBadge;
    case `boostCardRewardsRedemptionHelp1XContent`: return boostCardRewardsRedemptionHelp1XContent;
    case `boostCardRewardsRedemptionHelp1XTitle`: return boostCardRewardsRedemptionHelp1XTitle;
    case `boostCardRewardsRedemptionHelp2XContent`: return boostCardRewardsRedemptionHelp2XContent;
    case `boostCardRewardsRedemptionHelp2XTitle`: return boostCardRewardsRedemptionHelp2XTitle;
    case `boostCardRewardsRedemptionHelpBottomBannerCTA`: return boostCardRewardsRedemptionHelpBottomBannerCTA;
    case `boostCardRewardsRedemptionHelpBottomBannerTitle`: return boostCardRewardsRedemptionHelpBottomBannerTitle;
    case `boostCardRewardsRedemptionHelpCTA`: return boostCardRewardsRedemptionHelpCTA;
    case `boostCardRewardsRedemptionHelpItem1`: return boostCardRewardsRedemptionHelpItem1;
    case `boostCardRewardsRedemptionHelpItem2`: return boostCardRewardsRedemptionHelpItem2;
    case `boostCardRewardsRedemptionHelpItem3`: return boostCardRewardsRedemptionHelpItem3;
    case `boostCardRewardsRedemptionHelpSubtitle`: return boostCardRewardsRedemptionHelpSubtitle;
    case `boostCardRewardsRedemptionHelpTitle`: return boostCardRewardsRedemptionHelpTitle;
    case `boostCardRewardsRedemptionInProgress`: return boostCardRewardsRedemptionInProgress;
    case `boostCardRewardsRedemptionOpenHelpCta`: return boostCardRewardsRedemptionOpenHelpCta;
    case `boostCardRewardsRedemptionPending`: return boostCardRewardsRedemptionPending;
    case `boostCardRewardsRedemptionPendingExplanation`: return boostCardRewardsRedemptionPendingExplanation;
    case `boostCardRewardsRedemptionPointsWorth`: return boostCardRewardsRedemptionPointsWorth;
    case `boostCardRewardsRedemptionRedeemCta`: return boostCardRewardsRedemptionRedeemCta;
    case `boostCardRewardsRedemptionSuccess`: return boostCardRewardsRedemptionSuccess;
    case `boostCardRewardsRedemptionTitle`: return boostCardRewardsRedemptionTitle;
    case `boostCardRewardsSectionHeader`: return boostCardRewardsSectionHeader;
    case `boostCardRewardsTilePointsHeader`: return boostCardRewardsTilePointsHeader;
    case `boostCardRewardsTileTurboBoostTitle`: return boostCardRewardsTileTurboBoostTitle;
    case `boostCardRewardsValuePropFundAccountCTA`: return boostCardRewardsValuePropFundAccountCTA;
    case `boostCardRewardsValuePropFundAccountContent`: return boostCardRewardsValuePropFundAccountContent;
    case `boostCardRewardsValuePropFundAccountTitle`: return boostCardRewardsValuePropFundAccountTitle;
    case `boostCardSetupConnectBankAccountRow1KDeposits`: return boostCardSetupConnectBankAccountRow1KDeposits;
    case `boostCardSetupConnectBankAccountRow60DaysActive`: return boostCardSetupConnectBankAccountRow60DaysActive;
    case `boostCardSetupConnectBankAccountRowConnectivityToGrid`: return boostCardSetupConnectBankAccountRowConnectivityToGrid;
    case `boostCardSetupConnectBankAccountRowSpending`: return boostCardSetupConnectBankAccountRowSpending;
    case `boostCardSetupConnectBankAccountTitle`: return boostCardSetupConnectBankAccountTitle;
    case `boostCardStatementsNodeCreditAccount`: return boostCardStatementsNodeCreditAccount;
    case `boostCardStatementsNodeCreditLine`: return boostCardStatementsNodeCreditLine;
    case `boostCardStatementsNodeDeposit`: return boostCardStatementsNodeDeposit;
    case `boostCardStatementsNodeReserve`: return boostCardStatementsNodeReserve;
    case `boostCardTileCreditBoostSetupBody`: return boostCardTileCreditBoostSetupBody;
    case `boostCardTileCreditBoostSetupStart`: return boostCardTileCreditBoostSetupStart;
    case `boostCardTileCreditBoostSetupTitle`: return boostCardTileCreditBoostSetupTitle;
    case `boostCardTileCreditLineSetupBody`: return boostCardTileCreditLineSetupBody;
    case `boostCardTileCreditLineSetupHeader`: return boostCardTileCreditLineSetupHeader;
    case `boostCardTileCreditLineSetupModalApplicationSubmitted`: return boostCardTileCreditLineSetupModalApplicationSubmitted;
    case `boostCardTileCreditLineSetupModalClose`: return boostCardTileCreditLineSetupModalClose;
    case `boostCardTileCreditLineSetupModalCompleted`: return boostCardTileCreditLineSetupModalCompleted;
    case `boostCardTileCreditLineSetupModalCta`: return boostCardTileCreditLineSetupModalCta;
    case `boostCardTileCreditLineSetupModalItemOne`: return boostCardTileCreditLineSetupModalItemOne;
    case `boostCardTileCreditLineSetupModalItemThree`: return boostCardTileCreditLineSetupModalItemThree;
    case `boostCardTileCreditLineSetupModalItemTwo`: return boostCardTileCreditLineSetupModalItemTwo;
    case `boostCardTileCreditLineSetupModalProgress`: return boostCardTileCreditLineSetupModalProgress;
    case `boostCardTileCreditLineSetupModalSubtitle`: return boostCardTileCreditLineSetupModalSubtitle;
    case `boostCardTileCreditLineSetupModalTitle`: return boostCardTileCreditLineSetupModalTitle;
    case `boostCardTileCreditLineSetupProgress`: return boostCardTileCreditLineSetupProgress;
    case `boostCardTileCreditLineSetupStart`: return boostCardTileCreditLineSetupStart;
    case `boostCardTileCreditLineSetupTitle`: return boostCardTileCreditLineSetupTitle;
    case `boostCardTileCreditLineSetupUnlockedCTA`: return boostCardTileCreditLineSetupUnlockedCTA;
    case `boostCardTileCreditLineSetupUnlockedDisabledReasonActiveAdvance`: return boostCardTileCreditLineSetupUnlockedDisabledReasonActiveAdvance;
    case `boostCardTileCreditLineSetupUnlockedDisabledReasonCardInactive`: return boostCardTileCreditLineSetupUnlockedDisabledReasonCardInactive;
    case `boostCardTileCreditLineSetupUnlockedStartingOfferDescription`: return boostCardTileCreditLineSetupUnlockedStartingOfferDescription;
    case `boostCardTileCreditLineSetupUnlockedTitle`: return boostCardTileCreditLineSetupUnlockedTitle;
    case `boostCardTileCreditLineUnlockedBody`: return boostCardTileCreditLineUnlockedBody;
    case `boostCardTileCreditLineUnlockedCTA`: return boostCardTileCreditLineUnlockedCTA;
    case `boostCardTileCreditLineUnlockedTitle`: return boostCardTileCreditLineUnlockedTitle;
    case `boostCardTodoListFundAccountCTA`: return boostCardTodoListFundAccountCTA;
    case `boostCardTodoListFundAccountContent`: return boostCardTodoListFundAccountContent;
    case `boostCardTodoListFundAccountTitle`: return boostCardTodoListFundAccountTitle;
    case `boostCardTodoOrderNewCardCTA`: return boostCardTodoOrderNewCardCTA;
    case `boostCardTodoOrderNewCardSubtitle`: return boostCardTodoOrderNewCardSubtitle;
    case `boostCardTodoOrderNewCardTitle`: return boostCardTodoOrderNewCardTitle;
    case `boostCardTransactionAlreadyDisputed`: return boostCardTransactionAlreadyDisputed;
    case `boostCardTransactionNotSettledToDispute`: return boostCardTransactionNotSettledToDispute;
    case `boostCardTransferLimitError`: return boostCardTransferLimitError;
    case `boostCardTransferMinimumNotMetError`: return boostCardTransferMinimumNotMetError;
    case `boostCardVerifyPinTitle`: return boostCardVerifyPinTitle;
    case `boostCardYourOrderChangeLabel`: return boostCardYourOrderChangeLabel;
    case `boostCardYourOrderShipCardTo`: return boostCardYourOrderShipCardTo;
    case `boostConclusionButtonEnablePush`: return boostConclusionButtonEnablePush;
    case `boostConclusionButtonNotNow`: return boostConclusionButtonNotNow;
    case `boostConclusionLabelBody`: return boostConclusionLabelBody;
    case `boostConclusionLabelBodyV2`: return boostConclusionLabelBodyV2;
    case `boostConclusionLabelTitle`: return boostConclusionLabelTitle;
    case `boostCongratulationsBody`: return boostCongratulationsBody;
    case `boostCongratulationsButton`: return boostCongratulationsButton;
    case `boostCongratulationsGuidedBody`: return boostCongratulationsGuidedBody;
    case `boostCongratulationsGuidedButton`: return boostCongratulationsGuidedButton;
    case `boostCongratulationsGuidedTitle`: return boostCongratulationsGuidedTitle;
    case `boostCongratulationsTitle`: return boostCongratulationsTitle;
    case `boostIntroButtonContinue`: return boostIntroButtonContinue;
    case `boostIntroButtonNext`: return boostIntroButtonNext;
    case `boostIntroPage1Body`: return boostIntroPage1Body;
    case `boostIntroPage1Title`: return boostIntroPage1Title;
    case `boostIntroPage2Body`: return boostIntroPage2Body;
    case `boostIntroPage2Title`: return boostIntroPage2Title;
    case `boostIntroPage3Body`: return boostIntroPage3Body;
    case `boostIntroPage3Title`: return boostIntroPage3Title;
    case `boostIntroPagePayboostBody`: return boostIntroPagePayboostBody;
    case `boostIntroPagePayboostTitle`: return boostIntroPagePayboostTitle;
    case `boostIntroTitle`: return boostIntroTitle;
    case `boostLevelUnlockedLevelButtonContinue`: return boostLevelUnlockedLevelButtonContinue;
    case `boostLevelUnlockedLevelSubtext`: return boostLevelUnlockedLevelSubtext;
    case `boostLevelUnlockedMessage`: return boostLevelUnlockedMessage;
    case `boostLoadingWaitingForSignedW4`: return boostLoadingWaitingForSignedW4;
    case `boostLoadingWaitingForUnsignedW4`: return boostLoadingWaitingForUnsignedW4;
    case `boostProcessingTitle`: return boostProcessingTitle;
    case `boostReasonDecreaseBody`: return boostReasonDecreaseBody;
    case `boostReasonDecreaseTitle`: return boostReasonDecreaseTitle;
    case `boostReasonIncreaseBody`: return boostReasonIncreaseBody;
    case `boostReasonIncreaseTitle`: return boostReasonIncreaseTitle;
    case `boostReasonNewYearBody`: return boostReasonNewYearBody;
    case `boostReasonNewYearTitle`: return boostReasonNewYearTitle;
    case `boostSelectionButtonContinue`: return boostSelectionButtonContinue;
    case `boostSelectionButtonRefundResults`: return boostSelectionButtonRefundResults;
    case `boostSelectionLabelIWant`: return boostSelectionLabelIWant;
    case `boostSelectionLabelMonthlyPayboost`: return boostSelectionLabelMonthlyPayboost;
    case `boostSelectionLabelPerMonth`: return boostSelectionLabelPerMonth;
    case `boostSelectionLabelRefund`: return boostSelectionLabelRefund;
    case `boostSelectionMoreInfoButtonClose`: return boostSelectionMoreInfoButtonClose;
    case `boostSelectionMoreInfoLabelTitle`: return boostSelectionMoreInfoLabelTitle;
    case `boostSelectionMoreInfoMoreInfoCurrentMonthlyTakeHome`: return boostSelectionMoreInfoMoreInfoCurrentMonthlyTakeHome;
    case `boostSelectionMoreInfoMoreInfoIncreaseInMonthlyTakeHome`: return boostSelectionMoreInfoMoreInfoIncreaseInMonthlyTakeHome;
    case `boostSelectionMoreInfoMoreInfoIncreaseInMonthlyTakeHomeApproximate`: return boostSelectionMoreInfoMoreInfoIncreaseInMonthlyTakeHomeApproximate;
    case `boostSelectionMoreInfoMoreInfoRefundRemaining`: return boostSelectionMoreInfoMoreInfoRefundRemaining;
    case `boostSignatureButtonClear`: return boostSignatureButtonClear;
    case `boostSignatureButtonSave`: return boostSignatureButtonSave;
    case `boostSignatureTitle`: return boostSignatureTitle;
    case `boostSignedW4NoActivityCompletedMessage`: return boostSignedW4NoActivityCompletedMessage;
    case `boostSignedW4NoActivityCompletedTitle`: return boostSignedW4NoActivityCompletedTitle;
    case `boostSignedW4SLabelText`: return boostSignedW4SLabelText;
    case `boostSignedW4SLabelTextExpectedPaycheckAmountFallback`: return boostSignedW4SLabelTextExpectedPaycheckAmountFallback;
    case `boostSignedW4ShareSubject`: return boostSignedW4ShareSubject;
    case `boostSignedW4Title`: return boostSignedW4Title;
    case `boostSignedW4SAutomaticButton`: return boostSignedW4SAutomaticButton;
    case `boostSignedW4SAutomaticInfo`: return boostSignedW4SAutomaticInfo;
    case `boostSignedW4SAutomaticPill`: return boostSignedW4SAutomaticPill;
    case `boostSignedW4SButtonNotNow`: return boostSignedW4SButtonNotNow;
    case `boostSignedW4SButtonPreview`: return boostSignedW4SButtonPreview;
    case `boostSignedW4SLabelSubtext`: return boostSignedW4SLabelSubtext;
    case `boostSignedW4STitle`: return boostSignedW4STitle;
    case `boostStreaksAlreadyCompletedMessage`: return boostStreaksAlreadyCompletedMessage;
    case `boostStreaksButton`: return boostStreaksButton;
    case `boostStreaksDefaultTitle`: return boostStreaksDefaultTitle;
    case `boostStreaksMaxLevelMessage`: return boostStreaksMaxLevelMessage;
    case `boostStreaksNewLevelFirstMessage`: return boostStreaksNewLevelFirstMessage;
    case `boostStreaksNewLevelMessage`: return boostStreaksNewLevelMessage;
    case `boostStreaksNewLevelTitle`: return boostStreaksNewLevelTitle;
    case `boostStreaksNextLevelDefaultMessage`: return boostStreaksNextLevelDefaultMessage;
    case `boostStreaksNextLevelFirstMessage`: return boostStreaksNextLevelFirstMessage;
    case `boostStreaksNextLevelOneAwayMessage`: return boostStreaksNextLevelOneAwayMessage;
    case `boostTransactionEstimatedPostDate`: return boostTransactionEstimatedPostDate;
    case `boostTransactionTypeBalanceCorrectionClawBackAchReturn`: return boostTransactionTypeBalanceCorrectionClawBackAchReturn;
    case `boostTransactionTypeBalanceCorrectionClawBackNegativeSweep`: return boostTransactionTypeBalanceCorrectionClawBackNegativeSweep;
    case `boostTransactionTypeBalanceCorrectionRefundAchReturn`: return boostTransactionTypeBalanceCorrectionRefundAchReturn;
    case `boostTransactionTypeBalanceCorrectionRefundNegativeSweep`: return boostTransactionTypeBalanceCorrectionRefundNegativeSweep;
    case `boostTransactionTypeBoostCardCredit`: return boostTransactionTypeBoostCardCredit;
    case `boostTransactionTypeClawBackRefund`: return boostTransactionTypeClawBackRefund;
    case `boostTransactionTypeClawBackReversal`: return boostTransactionTypeClawBackReversal;
    case `boostTransactionTypeCreditLineACHPayment`: return boostTransactionTypeCreditLineACHPayment;
    case `boostTransactionTypeCreditLineConversionGridPlusSubscription`: return boostTransactionTypeCreditLineConversionGridPlusSubscription;
    case `boostTransactionTypeCreditLineConversionSubscriptionReactivation`: return boostTransactionTypeCreditLineConversionSubscriptionReactivation;
    case `boostTransactionTypeCreditLineDisbursement`: return boostTransactionTypeCreditLineDisbursement;
    case `boostTransactionTypeCreditLineInterestCharge`: return boostTransactionTypeCreditLineInterestCharge;
    case `boostTransactionTypeCreditLinePayment`: return boostTransactionTypeCreditLinePayment;
    case `boostTransactionTypeCreditLinePaymentToCashboost`: return boostTransactionTypeCreditLinePaymentToCashboost;
    case `boostTransactionTypeGeneric`: return boostTransactionTypeGeneric;
    case `boostTransactionTypePaymentFromThirdParty`: return boostTransactionTypePaymentFromThirdParty;
    case `boostTransactionTypeProvisionalCredit`: return boostTransactionTypeProvisionalCredit;
    case `boostTransactionTypeStatementCredit`: return boostTransactionTypeStatementCredit;
    case `boostTransactionTypeSubscriptionPayment`: return boostTransactionTypeSubscriptionPayment;
    case `boostUnsignedW4ButtonContinue`: return boostUnsignedW4ButtonContinue;
    case `boostUnsignedW4ButtonHelp`: return boostUnsignedW4ButtonHelp;
    case `boostUnsignedW4HelpContent`: return boostUnsignedW4HelpContent;
    case `boostUnsignedW4HelpTitle`: return boostUnsignedW4HelpTitle;
    case `boostUnsignedW4Title`: return boostUnsignedW4Title;
    case `boostW4DataElementsButtonContinue`: return boostW4DataElementsButtonContinue;
    case `boostW4DataElementsErrorMessageInvalidAddress`: return boostW4DataElementsErrorMessageInvalidAddress;
    case `boostW4DataElementsErrorMessageInvalidCityStateZip`: return boostW4DataElementsErrorMessageInvalidCityStateZip;
    case `boostW4DataElementsErrorMessageInvalidFirstName`: return boostW4DataElementsErrorMessageInvalidFirstName;
    case `boostW4DataElementsErrorMessageInvalidLastName`: return boostW4DataElementsErrorMessageInvalidLastName;
    case `boostW4DataElementsErrorMessageInvalidSSN`: return boostW4DataElementsErrorMessageInvalidSSN;
    case `boostW4DataElementsErrorTitle`: return boostW4DataElementsErrorTitle;
    case `boostW4DataElementsFieldAddressPlaceholder`: return boostW4DataElementsFieldAddressPlaceholder;
    case `boostW4DataElementsFieldAddressTitle`: return boostW4DataElementsFieldAddressTitle;
    case `boostW4DataElementsFieldCityStateZipPlaceholder`: return boostW4DataElementsFieldCityStateZipPlaceholder;
    case `boostW4DataElementsFieldCityStateZipTitle`: return boostW4DataElementsFieldCityStateZipTitle;
    case `boostW4DataElementsFieldFirstNamePlaceholder`: return boostW4DataElementsFieldFirstNamePlaceholder;
    case `boostW4DataElementsFieldFirstNameTitle`: return boostW4DataElementsFieldFirstNameTitle;
    case `boostW4DataElementsFieldLastNamePlaceholder`: return boostW4DataElementsFieldLastNamePlaceholder;
    case `boostW4DataElementsFieldLastNameTitle`: return boostW4DataElementsFieldLastNameTitle;
    case `boostW4DataElementsFieldSSNPlaceholder`: return boostW4DataElementsFieldSSNPlaceholder;
    case `boostW4DataElementsFieldSSNTitle`: return boostW4DataElementsFieldSSNTitle;
    case `boostW4DataElementsFieldSSNWhyButton`: return boostW4DataElementsFieldSSNWhyButton;
    case `boostW4DataElementsFieldSSNWhyButtonHelpMessage`: return boostW4DataElementsFieldSSNWhyButtonHelpMessage;
    case `boostW4DataElementsFieldSSNWhyButtonHelpTitle`: return boostW4DataElementsFieldSSNWhyButtonHelpTitle;
    case `boostW4DataElementsInfo`: return boostW4DataElementsInfo;
    case `boostW4DataElementsNavTitle`: return boostW4DataElementsNavTitle;
    case `boostW4DataElementsTitle`: return boostW4DataElementsTitle;
    case `boostW4HRSubmitCheckboxTitleEmail`: return boostW4HRSubmitCheckboxTitleEmail;
    case `boostW4HRSubmitCheckboxTitleSnailMail`: return boostW4HRSubmitCheckboxTitleSnailMail;
    case `boostW4HRSubmitComingSoon`: return boostW4HRSubmitComingSoon;
    case `boostW4HRSubmitContinueButton`: return boostW4HRSubmitContinueButton;
    case `boostW4HRSubmitErrorReasonEmptyAttn`: return boostW4HRSubmitErrorReasonEmptyAttn;
    case `boostW4HRSubmitErrorReasonEmptyCity`: return boostW4HRSubmitErrorReasonEmptyCity;
    case `boostW4HRSubmitErrorReasonEmptyEmail`: return boostW4HRSubmitErrorReasonEmptyEmail;
    case `boostW4HRSubmitErrorReasonEmptyState`: return boostW4HRSubmitErrorReasonEmptyState;
    case `boostW4HRSubmitErrorReasonEmptyStreet`: return boostW4HRSubmitErrorReasonEmptyStreet;
    case `boostW4HRSubmitErrorReasonEmptyZip`: return boostW4HRSubmitErrorReasonEmptyZip;
    case `boostW4HRSubmitErrorReasonInvalidEmail`: return boostW4HRSubmitErrorReasonInvalidEmail;
    case `boostW4HRSubmitErrorReasonInvalidState`: return boostW4HRSubmitErrorReasonInvalidState;
    case `boostW4HRSubmitErrorReasonInvalidZip`: return boostW4HRSubmitErrorReasonInvalidZip;
    case `boostW4HRSubmitPrompt`: return boostW4HRSubmitPrompt;
    case `boostW4HRSubmitPromptNew`: return boostW4HRSubmitPromptNew;
    case `boostW4HRSubmitSubmitManuallyButton`: return boostW4HRSubmitSubmitManuallyButton;
    case `boostW4HRSubmitTextFieldAttn`: return boostW4HRSubmitTextFieldAttn;
    case `boostW4HRSubmitTextFieldCity`: return boostW4HRSubmitTextFieldCity;
    case `boostW4HRSubmitTextFieldEmail`: return boostW4HRSubmitTextFieldEmail;
    case `boostW4HRSubmitTextFieldState`: return boostW4HRSubmitTextFieldState;
    case `boostW4HRSubmitTextFieldStreet`: return boostW4HRSubmitTextFieldStreet;
    case `boostW4HRSubmitTextFieldStreet2`: return boostW4HRSubmitTextFieldStreet2;
    case `boostW4HRSubmitTextFieldZip`: return boostW4HRSubmitTextFieldZip;
    case `boostW4HRSubmitTextfieldStatePlaceholder`: return boostW4HRSubmitTextfieldStatePlaceholder;
    case `boostW4HRSubmitTitle`: return boostW4HRSubmitTitle;
    case `boostW4HRSuccessAutomatedSubtitle`: return boostW4HRSuccessAutomatedSubtitle;
    case `boostW4HRSuccessAutomatedTitle`: return boostW4HRSuccessAutomatedTitle;
    case `boostW4HRSuccessContinueButton`: return boostW4HRSuccessContinueButton;
    case `boostWaitingEnablePushButtonText`: return boostWaitingEnablePushButtonText;
    case `boostWaitingNotNowButtonText`: return boostWaitingNotNowButtonText;
    case `boostWaitingText`: return boostWaitingText;
    case `boostWaitingTitle`: return boostWaitingTitle;
    case `boostYGBContinueButtonText`: return boostYGBContinueButtonText;
    case `boostYGBDescription`: return boostYGBDescription;
    case `boostYGBRatingCTAButtonText`: return boostYGBRatingCTAButtonText;
    case `boostYGBShare`: return boostYGBShare;
    case `boostYGBShareContent`: return boostYGBShareContent;
    case `boostYGBShareContentFooter`: return boostYGBShareContentFooter;
    case `boostYGBShareImageHeader`: return boostYGBShareImageHeader;
    case `boostYGBShareImageText`: return boostYGBShareImageText;
    case `boostYGBTitle`: return boostYGBTitle;
    case `boostcardTileFinishCreditLineBody`: return boostcardTileFinishCreditLineBody;
    case `boostcardTileFinishCreditLineCTA`: return boostcardTileFinishCreditLineCTA;
    case `boostcardTileFinishCreditLineTitle`: return boostcardTileFinishCreditLineTitle;
    case `boostcardTilePaymentFailedBody`: return boostcardTilePaymentFailedBody;
    case `boostcardTilePaymentFailedCta`: return boostcardTilePaymentFailedCta;
    case `boostcardTilePaymentFailedTitle`: return boostcardTilePaymentFailedTitle;
    case `buttonBack`: return buttonBack;
    case `buttonCancel`: return buttonCancel;
    case `buttonContinue`: return buttonContinue;
    case `callUsSupportMenuCTATitle`: return callUsSupportMenuCTATitle;
    case `callUsSupportMenuChatCTA`: return callUsSupportMenuChatCTA;
    case `callUsSupportMenuChatCTAHighlightQuery`: return callUsSupportMenuChatCTAHighlightQuery;
    case `callUsSupportMenuPhoneNumber`: return callUsSupportMenuPhoneNumber;
    case `callUsSupportMenuTitle`: return callUsSupportMenuTitle;
    case `callUsSupportMenuTitleHeader`: return callUsSupportMenuTitleHeader;
    case `captureAlertNoCameraMessage`: return captureAlertNoCameraMessage;
    case `captureAlertNoCameraOk`: return captureAlertNoCameraOk;
    case `captureAlertNoCameraTitle`: return captureAlertNoCameraTitle;
    case `captureConfirmationButtonKeep`: return captureConfirmationButtonKeep;
    case `captureConfirmationButtonRetake`: return captureConfirmationButtonRetake;
    case `captureNavButtonCancel`: return captureNavButtonCancel;
    case `captureNavButtonDone`: return captureNavButtonDone;
    case `cashboostAccountErrorViewCTATitleConnectNewAccount`: return cashboostAccountErrorViewCTATitleConnectNewAccount;
    case `cashboostAccountErrorViewCTATitleConnectNewCard`: return cashboostAccountErrorViewCTATitleConnectNewCard;
    case `cashboostAccountErrorViewCTATitleReconnectAccount`: return cashboostAccountErrorViewCTATitleReconnectAccount;
    case `cashboostAccountRequirementsBankAccountTitle`: return cashboostAccountRequirementsBankAccountTitle;
    case `cashboostAccountRequirementsDebitCardTitle`: return cashboostAccountRequirementsDebitCardTitle;
    case `cashboostAccountRequirementsListHeaderRowTitle`: return cashboostAccountRequirementsListHeaderRowTitle;
    case `cashboostAccountRequirementsMembershipIssueTitle`: return cashboostAccountRequirementsMembershipIssueTitle;
    case `cashboostAccountsRequirementHeaderBankAccountContent`: return cashboostAccountsRequirementHeaderBankAccountContent;
    case `cashboostAccountsRequirementHeaderBankAccountTitle`: return cashboostAccountsRequirementHeaderBankAccountTitle;
    case `cashboostAccountsRequirementHeaderDebitCardContent`: return cashboostAccountsRequirementHeaderDebitCardContent;
    case `cashboostAccountsRequirementHeaderDebitCardTitle`: return cashboostAccountsRequirementHeaderDebitCardTitle;
    case `cashboostAccountsRequirementHeaderGenericBottom`: return cashboostAccountsRequirementHeaderGenericBottom;
    case `cashboostAccountsRequirementHeaderMembershipIssueBottom`: return cashboostAccountsRequirementHeaderMembershipIssueBottom;
    case `cashboostAccountsRequirementHeaderMembershipIssueContent`: return cashboostAccountsRequirementHeaderMembershipIssueContent;
    case `cashboostAccountsRequirementHeaderMembershipIssueTitle`: return cashboostAccountsRequirementHeaderMembershipIssueTitle;
    case `cashboostAddDebitCardBankInfo`: return cashboostAddDebitCardBankInfo;
    case `cashboostAddDebitCardCTAButton`: return cashboostAddDebitCardCTAButton;
    case `cashboostAddDebitCardCTAPrompt`: return cashboostAddDebitCardCTAPrompt;
    case `cashboostAddDebitCardCardFailureFailed`: return cashboostAddDebitCardCardFailureFailed;
    case `cashboostAddDebitCardCardFailureUnknownError`: return cashboostAddDebitCardCardFailureUnknownError;
    case `cashboostAddDebitCardIncompleteInfoError`: return cashboostAddDebitCardIncompleteInfoError;
    case `cashboostAddDebitCardSubtitle`: return cashboostAddDebitCardSubtitle;
    case `cashboostAddDebitCardSubtitleBoldQuery`: return cashboostAddDebitCardSubtitleBoldQuery;
    case `cashboostAddDebitCardTitle`: return cashboostAddDebitCardTitle;
    case `cashboostAddressEntryListNoAddressCTA`: return cashboostAddressEntryListNoAddressCTA;
    case `cashboostAddressEntryListNoAddressTitle`: return cashboostAddressEntryListNoAddressTitle;
    case `cashboostAddressEntryListTextfieldPlaceholder`: return cashboostAddressEntryListTextfieldPlaceholder;
    case `cashboostAddressEntryTextfieldPlaceholder`: return cashboostAddressEntryTextfieldPlaceholder;
    case `cashboostAddressEntryTitle`: return cashboostAddressEntryTitle;
    case `cashboostAdvanceDeliveredTileAutopayDate`: return cashboostAdvanceDeliveredTileAutopayDate;
    case `cashboostAdvanceDeliveredTileAutopayDateHighlightQuery`: return cashboostAdvanceDeliveredTileAutopayDateHighlightQuery;
    case `cashboostAdvanceDeliveredTilePaybackDate`: return cashboostAdvanceDeliveredTilePaybackDate;
    case `cashboostAdvanceDeliveredTilePaybackDateToday`: return cashboostAdvanceDeliveredTilePaybackDateToday;
    case `cashboostAdvanceDeliveredTilePaybackOverdueDate`: return cashboostAdvanceDeliveredTilePaybackOverdueDate;
    case `cashboostAdvanceDeliveredTileTitle`: return cashboostAdvanceDeliveredTileTitle;
    case `cashboostAdvanceRepaymentHistoryAdvanceDescription`: return cashboostAdvanceRepaymentHistoryAdvanceDescription;
    case `cashboostAdvanceRepaymentHistoryAutoPayDescription`: return cashboostAdvanceRepaymentHistoryAutoPayDescription;
    case `cashboostAdvanceRepaymentHistoryOutstandingItem`: return cashboostAdvanceRepaymentHistoryOutstandingItem;
    case `cashboostAdvanceRepaymentHistoryPayAllNowCta`: return cashboostAdvanceRepaymentHistoryPayAllNowCta;
    case `cashboostAdvanceRepaymentHistoryPayRemainderNowCta`: return cashboostAdvanceRepaymentHistoryPayRemainderNowCta;
    case `cashboostAdvanceRepaymentHistoryPaybackDate`: return cashboostAdvanceRepaymentHistoryPaybackDate;
    case `cashboostAdvanceRepaymentHistoryPaymentItem`: return cashboostAdvanceRepaymentHistoryPaymentItem;
    case `cashboostAdvanceRepaymentHistoryTileCta`: return cashboostAdvanceRepaymentHistoryTileCta;
    case `cashboostAdvanceRepaymentHistoryTitle`: return cashboostAdvanceRepaymentHistoryTitle;
    case `cashboostAutoPayInfoCanIChangeRepaymentBody`: return cashboostAutoPayInfoCanIChangeRepaymentBody;
    case `cashboostAutoPayInfoCanIChangeRepaymentTitle`: return cashboostAutoPayInfoCanIChangeRepaymentTitle;
    case `cashboostAutoPayInfoHowItWorksBody`: return cashboostAutoPayInfoHowItWorksBody;
    case `cashboostAutoPayInfoHowItWorksTitle`: return cashboostAutoPayInfoHowItWorksTitle;
    case `cashboostCardActionsViewPayNow`: return cashboostCardActionsViewPayNow;
    case `cashboostCardActionsViewSnooze`: return cashboostCardActionsViewSnooze;
    case `cashboostCheckoutAmountNotReady`: return cashboostCheckoutAmountNotReady;
    case `cashboostContinueCTA`: return cashboostContinueCTA;
    case `cashboostCreditComparisonCashboost`: return cashboostCreditComparisonCashboost;
    case `cashboostCreditComparisonContent`: return cashboostCreditComparisonContent;
    case `cashboostCreditComparisonCreditLine`: return cashboostCreditComparisonCreditLine;
    case `cashboostCreditComparisonCta`: return cashboostCreditComparisonCta;
    case `cashboostCreditComparisonTitle`: return cashboostCreditComparisonTitle;
    case `cashboostCreditLineTeaser`: return cashboostCreditLineTeaser;
    case `cashboostCreditLineTileFinishSetUpCTA`: return cashboostCreditLineTileFinishSetUpCTA;
    case `cashboostCreditLineTileFinishSetUpSubtitle`: return cashboostCreditLineTileFinishSetUpSubtitle;
    case `cashboostCreditLineTileFinishSetUpTitle`: return cashboostCreditLineTileFinishSetUpTitle;
    case `cashboostCreditLineTileSubtitle`: return cashboostCreditLineTileSubtitle;
    case `cashboostCreditLineTileTitle`: return cashboostCreditLineTileTitle;
    case `cashboostCreditLineTileViewProgressCTA`: return cashboostCreditLineTileViewProgressCTA;
    case `cashboostCreditLineTileViewProgressSubtitle`: return cashboostCreditLineTileViewProgressSubtitle;
    case `cashboostCreditLineTileViewProgressTitle`: return cashboostCreditLineTileViewProgressTitle;
    case `cashboostCreditLineUpsellFastDeliverySubtitle`: return cashboostCreditLineUpsellFastDeliverySubtitle;
    case `cashboostCreditLineUpsellFastDeliveryTitle`: return cashboostCreditLineUpsellFastDeliveryTitle;
    case `cashboostCreditLineUpsellStandardDeliverySubtitle`: return cashboostCreditLineUpsellStandardDeliverySubtitle;
    case `cashboostCreditLineUpsellStandardDeliveryTitle`: return cashboostCreditLineUpsellStandardDeliveryTitle;
    case `cashboostCreditLineUpsellValuePropsCTA`: return cashboostCreditLineUpsellValuePropsCTA;
    case `cashboostCreditLineUpsellValuePropsCashboost1`: return cashboostCreditLineUpsellValuePropsCashboost1;
    case `cashboostCreditLineUpsellValuePropsCashboost2`: return cashboostCreditLineUpsellValuePropsCashboost2;
    case `cashboostCreditLineUpsellValuePropsCashboost3`: return cashboostCreditLineUpsellValuePropsCashboost3;
    case `cashboostCreditLineUpsellValuePropsCashboost4`: return cashboostCreditLineUpsellValuePropsCashboost4;
    case `cashboostCreditLineUpsellValuePropsCreditLine1`: return cashboostCreditLineUpsellValuePropsCreditLine1;
    case `cashboostCreditLineUpsellValuePropsCreditLine2`: return cashboostCreditLineUpsellValuePropsCreditLine2;
    case `cashboostCreditLineUpsellValuePropsCreditLine3`: return cashboostCreditLineUpsellValuePropsCreditLine3;
    case `cashboostCreditLineUpsellValuePropsCreditLine4`: return cashboostCreditLineUpsellValuePropsCreditLine4;
    case `cashboostCreditLineUpsellValuePropsSubtitle`: return cashboostCreditLineUpsellValuePropsSubtitle;
    case `cashboostCreditLineUpsellValuePropsTitle`: return cashboostCreditLineUpsellValuePropsTitle;
    case `cashboostExtendedWaitInfoViewCTA`: return cashboostExtendedWaitInfoViewCTA;
    case `cashboostExtendedWaitInfoViewContent`: return cashboostExtendedWaitInfoViewContent;
    case `cashboostExtendedWaitInfoViewTitle`: return cashboostExtendedWaitInfoViewTitle;
    case `cashboostHistoryArrivingDate`: return cashboostHistoryArrivingDate;
    case `cashboostHistoryHeaderTitle`: return cashboostHistoryHeaderTitle;
    case `cashboostHistoryListScreenListFooter`: return cashboostHistoryListScreenListFooter;
    case `cashboostHistoryListScreenListFooterNoAdvances`: return cashboostHistoryListScreenListFooterNoAdvances;
    case `cashboostHistoryListScreenPaidInFull`: return cashboostHistoryListScreenPaidInFull;
    case `cashboostHistoryListScreenRowShortTitle`: return cashboostHistoryListScreenRowShortTitle;
    case `cashboostHistoryListScreenRowSubtitle`: return cashboostHistoryListScreenRowSubtitle;
    case `cashboostHistoryListScreenRowTitle`: return cashboostHistoryListScreenRowTitle;
    case `cashboostHistoryListScreenShortTitle`: return cashboostHistoryListScreenShortTitle;
    case `cashboostHistoryListScreenTitle`: return cashboostHistoryListScreenTitle;
    case `cashboostHistoryReceiptScreenAccount`: return cashboostHistoryReceiptScreenAccount;
    case `cashboostHistoryReceiptScreenAdvanceDetails`: return cashboostHistoryReceiptScreenAdvanceDetails;
    case `cashboostHistoryReceiptScreenAmount`: return cashboostHistoryReceiptScreenAmount;
    case `cashboostHistoryReceiptScreenAmountOwed`: return cashboostHistoryReceiptScreenAmountOwed;
    case `cashboostHistoryReceiptScreenDeliveryDate`: return cashboostHistoryReceiptScreenDeliveryDate;
    case `cashboostHistoryReceiptScreenDeliveryFee`: return cashboostHistoryReceiptScreenDeliveryFee;
    case `cashboostHistoryReceiptScreenDueDate`: return cashboostHistoryReceiptScreenDueDate;
    case `cashboostHistoryReceiptScreenHelpCTA`: return cashboostHistoryReceiptScreenHelpCTA;
    case `cashboostHistoryReceiptScreenPayoutDate`: return cashboostHistoryReceiptScreenPayoutDate;
    case `cashboostHistoryReceiptScreenRepaymentDate`: return cashboostHistoryReceiptScreenRepaymentDate;
    case `cashboostHistoryReceiptScreenRepaymentDetails`: return cashboostHistoryReceiptScreenRepaymentDetails;
    case `cashboostHistoryReceiptScreenStatus`: return cashboostHistoryReceiptScreenStatus;
    case `cashboostHistoryReceiptScreenStatusComplete`: return cashboostHistoryReceiptScreenStatusComplete;
    case `cashboostHistoryReceiptScreenTitle`: return cashboostHistoryReceiptScreenTitle;
    case `cashboostHistoryReceiptScreenTotal`: return cashboostHistoryReceiptScreenTotal;
    case `cashboostHistoryReceivedDate`: return cashboostHistoryReceivedDate;
    case `cashboostHistoryStatusArrived`: return cashboostHistoryStatusArrived;
    case `cashboostHistoryStatusPending`: return cashboostHistoryStatusPending;
    case `cashboostHistoryStatusRepayment`: return cashboostHistoryStatusRepayment;
    case `cashboostHistoryTileTitle`: return cashboostHistoryTileTitle;
    case `cashboostIDVContinueRetryCTA`: return cashboostIDVContinueRetryCTA;
    case `cashboostIDVPopoverModalCTA`: return cashboostIDVPopoverModalCTA;
    case `cashboostIDVPopoverModalTitle`: return cashboostIDVPopoverModalTitle;
    case `cashboostIDVPopoverValidIDList`: return cashboostIDVPopoverValidIDList;
    case `cashboostIDVPopoverValidIDListHeader`: return cashboostIDVPopoverValidIDListHeader;
    case `cashboostIDVSubTitle`: return cashboostIDVSubTitle;
    case `cashboostIDVTakePhotoBackCTA`: return cashboostIDVTakePhotoBackCTA;
    case `cashboostIDVTakePhotoCTA`: return cashboostIDVTakePhotoCTA;
    case `cashboostIDVTakePhotoFrontCTA`: return cashboostIDVTakePhotoFrontCTA;
    case `cashboostIDVTitle`: return cashboostIDVTitle;
    case `cashboostIDVUploadSubTitle`: return cashboostIDVUploadSubTitle;
    case `cashboostIDVUploadTitle`: return cashboostIDVUploadTitle;
    case `cashboostIDVUploadTitleAlt`: return cashboostIDVUploadTitleAlt;
    case `cashboostIDVUploadWellTitleBack`: return cashboostIDVUploadWellTitleBack;
    case `cashboostIDVUploadWellTitleFront`: return cashboostIDVUploadWellTitleFront;
    case `cashboostLandingCardArrivalTitle`: return cashboostLandingCardArrivalTitle;
    case `cashboostLandingCardTitle`: return cashboostLandingCardTitle;
    case `cashboostLandingCardTitleDelayed`: return cashboostLandingCardTitleDelayed;
    case `cashboostLandingCardTitleFallback`: return cashboostLandingCardTitleFallback;
    case `cashboostLandingCardTitleToday`: return cashboostLandingCardTitleToday;
    case `cashboostLandingCardTitleTodayDetailed`: return cashboostLandingCardTitleTodayDetailed;
    case `cashboostLandingCardTitleTomorrow`: return cashboostLandingCardTitleTomorrow;
    case `cashboostLandingCardTitleTomorrowDetailed`: return cashboostLandingCardTitleTomorrowDetailed;
    case `cashboostLandingScreenAdvanceAmount`: return cashboostLandingScreenAdvanceAmount;
    case `cashboostLandingScreenCTA`: return cashboostLandingScreenCTA;
    case `cashboostLandingScreenDelayedCTA`: return cashboostLandingScreenDelayedCTA;
    case `cashboostLandingScreenDelayedSubTitle`: return cashboostLandingScreenDelayedSubTitle;
    case `cashboostLandingScreenDelayedTitle`: return cashboostLandingScreenDelayedTitle;
    case `cashboostLandingScreenLandingDate`: return cashboostLandingScreenLandingDate;
    case `cashboostLandingScreenRepaymentDate`: return cashboostLandingScreenRepaymentDate;
    case `cashboostLandingScreenTitle`: return cashboostLandingScreenTitle;
    case `cashboostLandingScreenTitleFallback`: return cashboostLandingScreenTitleFallback;
    case `cashboostLandingScreenTitleMultipleDaysSuffix`: return cashboostLandingScreenTitleMultipleDaysSuffix;
    case `cashboostLandingScreenTitleToday`: return cashboostLandingScreenTitleToday;
    case `cashboostLandingScreenTitleTomorrow`: return cashboostLandingScreenTitleTomorrow;
    case `cashboostLandingScreenTitleUnknownDays`: return cashboostLandingScreenTitleUnknownDays;
    case `cashboostLevelTileContent`: return cashboostLevelTileContent;
    case `cashboostLevelTileCurrent`: return cashboostLevelTileCurrent;
    case `cashboostLevelTileTitle`: return cashboostLevelTileTitle;
    case `cashboostLevelsLearnMoreDoneButton`: return cashboostLevelsLearnMoreDoneButton;
    case `cashboostLevelsLearnMoreMaxAmountDescription`: return cashboostLevelsLearnMoreMaxAmountDescription;
    case `cashboostLevelsLearnMoreMaxAmountTitle`: return cashboostLevelsLearnMoreMaxAmountTitle;
    case `cashboostLevelsLearnMoreOverview`: return cashboostLevelsLearnMoreOverview;
    case `cashboostLevelsLearnMoreSnoozesDescription`: return cashboostLevelsLearnMoreSnoozesDescription;
    case `cashboostLevelsLearnMoreSnoozesTitle`: return cashboostLevelsLearnMoreSnoozesTitle;
    case `cashboostLevelsLearnMoreTitle`: return cashboostLevelsLearnMoreTitle;
    case `cashboostLevelsNewLevelCTA`: return cashboostLevelsNewLevelCTA;
    case `cashboostLevelsNewLevelLevelTitle`: return cashboostLevelsNewLevelLevelTitle;
    case `cashboostLevelsNewLevelTitle`: return cashboostLevelsNewLevelTitle;
    case `cashboostLevelsScreenBottomSubtitle`: return cashboostLevelsScreenBottomSubtitle;
    case `cashboostLevelsScreenBottomTitle`: return cashboostLevelsScreenBottomTitle;
    case `cashboostLevelsScreenCTA`: return cashboostLevelsScreenCTA;
    case `cashboostLevelsScreenSubtitle`: return cashboostLevelsScreenSubtitle;
    case `cashboostLevelsScreenTitle`: return cashboostLevelsScreenTitle;
    case `cashboostLevelsTileDescription`: return cashboostLevelsTileDescription;
    case `cashboostLevelsTileDescriptionUpdated`: return cashboostLevelsTileDescriptionUpdated;
    case `cashboostLevelsTileHeaderTitle`: return cashboostLevelsTileHeaderTitle;
    case `cashboostLevelsTileLevelLabel`: return cashboostLevelsTileLevelLabel;
    case `cashboostLevelsTileTitle`: return cashboostLevelsTileTitle;
    case `cashboostLevelsTileTitleSingular`: return cashboostLevelsTileTitleSingular;
    case `cashboostLevelsViewLevelsContent`: return cashboostLevelsViewLevelsContent;
    case `cashboostLevelsViewLevelsContentUpdated`: return cashboostLevelsViewLevelsContentUpdated;
    case `cashboostLevelsViewLevelsHeader`: return cashboostLevelsViewLevelsHeader;
    case `cashboostLevelsViewTitle`: return cashboostLevelsViewTitle;
    case `cashboostLoadingTileHeader`: return cashboostLoadingTileHeader;
    case `cashboostLoadingTileSubheader`: return cashboostLoadingTileSubheader;
    case `cashboostMembershipAccountRequirementsListHeaderRowTitle`: return cashboostMembershipAccountRequirementsListHeaderRowTitle;
    case `cashboostNavigationCashboostTitle`: return cashboostNavigationCashboostTitle;
    case `cashboostNavigationTitle`: return cashboostNavigationTitle;
    case `cashboostNewAdvanceSuccessCTA`: return cashboostNewAdvanceSuccessCTA;
    case `cashboostNewAdvanceSuccessSubtitleFastDelivery`: return cashboostNewAdvanceSuccessSubtitleFastDelivery;
    case `cashboostNewAdvanceSuccessSubtitleStandardThreeDay`: return cashboostNewAdvanceSuccessSubtitleStandardThreeDay;
    case `cashboostNewAdvanceSuccessTitle`: return cashboostNewAdvanceSuccessTitle;
    case `cashboostOffboardingTileDoesNotQualifyCTA`: return cashboostOffboardingTileDoesNotQualifyCTA;
    case `cashboostOffboardingTileDoesNotQualifyContent`: return cashboostOffboardingTileDoesNotQualifyContent;
    case `cashboostOffboardingTileDoesNotQualifySubtitle`: return cashboostOffboardingTileDoesNotQualifySubtitle;
    case `cashboostOffboardingTileDoesNotQualifyTitle`: return cashboostOffboardingTileDoesNotQualifyTitle;
    case `cashboostOfferAcceptAdvanceAmount`: return cashboostOfferAcceptAdvanceAmount;
    case `cashboostOfferAcceptAgreementRadioAgreeLabel`: return cashboostOfferAcceptAgreementRadioAgreeLabel;
    case `cashboostOfferAcceptAmountLabelSubtitleButton`: return cashboostOfferAcceptAmountLabelSubtitleButton;
    case `cashboostOfferAcceptBoostCard`: return cashboostOfferAcceptBoostCard;
    case `cashboostOfferAcceptBulletUnlimitedCashboosts`: return cashboostOfferAcceptBulletUnlimitedCashboosts;
    case `cashboostOfferAcceptComingSoon`: return cashboostOfferAcceptComingSoon;
    case `cashboostOfferAcceptContinueCTA`: return cashboostOfferAcceptContinueCTA;
    case `cashboostOfferAcceptDeliveryFee`: return cashboostOfferAcceptDeliveryFee;
    case `cashboostOfferAcceptFastDeliveryArrival`: return cashboostOfferAcceptFastDeliveryArrival;
    case `cashboostOfferAcceptListTitle`: return cashboostOfferAcceptListTitle;
    case `cashboostOfferAcceptMembershipFeeAmount`: return cashboostOfferAcceptMembershipFeeAmount;
    case `cashboostOfferAcceptMembershipFeeDescription`: return cashboostOfferAcceptMembershipFeeDescription;
    case `cashboostOfferAcceptMembershipFeeTitle`: return cashboostOfferAcceptMembershipFeeTitle;
    case `cashboostOfferAcceptPayBoost`: return cashboostOfferAcceptPayBoost;
    case `cashboostOfferAcceptRepayment`: return cashboostOfferAcceptRepayment;
    case `cashboostOfferAcceptRepaymentSnooze`: return cashboostOfferAcceptRepaymentSnooze;
    case `cashboostOfferAcceptStandardDeliveryArrival`: return cashboostOfferAcceptStandardDeliveryArrival;
    case `cashboostOfferAcceptTaxProjection`: return cashboostOfferAcceptTaxProjection;
    case `cashboostOfferAcceptTerms`: return cashboostOfferAcceptTerms;
    case `cashboostOfferAcceptTermsAdvanceAmountFallback`: return cashboostOfferAcceptTermsAdvanceAmountFallback;
    case `cashboostOfferAcceptTermsBoldAlgoQuery`: return cashboostOfferAcceptTermsBoldAlgoQuery;
    case `cashboostOfferAcceptTermsTermsLinkAlgoQuery`: return cashboostOfferAcceptTermsTermsLinkAlgoQuery;
    case `cashboostOfferAcceptTermsTermsLinkAlgoQueryTermsAttribution`: return cashboostOfferAcceptTermsTermsLinkAlgoQueryTermsAttribution;
    case `cashboostOfferAcceptTermsWithAccounts`: return cashboostOfferAcceptTermsWithAccounts;
    case `cashboostOfferAcceptTermsWithAccountsBoldKey`: return cashboostOfferAcceptTermsWithAccountsBoldKey;
    case `cashboostOfferAcceptTermsWithAccountsWithGrid`: return cashboostOfferAcceptTermsWithAccountsWithGrid;
    case `cashboostOfferAcceptTitle`: return cashboostOfferAcceptTitle;
    case `cashboostOfferAcceptTotal`: return cashboostOfferAcceptTotal;
    case `cashboostOfferAdvanceOfferCooldown`: return cashboostOfferAdvanceOfferCooldown;
    case `cashboostOfferAmount`: return cashboostOfferAmount;
    case `cashboostOfferDeliveryDestinationLabel`: return cashboostOfferDeliveryDestinationLabel;
    case `cashboostOfferDeliverySelectorTitle`: return cashboostOfferDeliverySelectorTitle;
    case `cashboostOfferFastUnavailableInfoBody`: return cashboostOfferFastUnavailableInfoBody;
    case `cashboostOfferInstantButtonPrice`: return cashboostOfferInstantButtonPrice;
    case `cashboostOfferInstantButtonTitle`: return cashboostOfferInstantButtonTitle;
    case `cashboostOfferInstantInfoBody`: return cashboostOfferInstantInfoBody;
    case `cashboostOfferInstantInfoTitle`: return cashboostOfferInstantInfoTitle;
    case `cashboostOfferOverviewTitle`: return cashboostOfferOverviewTitle;
    case `cashboostOfferRepaymentDateLabel`: return cashboostOfferRepaymentDateLabel;
    case `cashboostOfferScreenAccountLabel`: return cashboostOfferScreenAccountLabel;
    case `cashboostOfferScreenAdvanceAmountLabel`: return cashboostOfferScreenAdvanceAmountLabel;
    case `cashboostOfferScreenAdvanceCalculating`: return cashboostOfferScreenAdvanceCalculating;
    case `cashboostOfferScreenArrivalDate`: return cashboostOfferScreenArrivalDate;
    case `cashboostOfferScreenArrivalDateLabelCalculating`: return cashboostOfferScreenArrivalDateLabelCalculating;
    case `cashboostOfferScreenArrivalInfo`: return cashboostOfferScreenArrivalInfo;
    case `cashboostOfferScreenAutoPayCTA`: return cashboostOfferScreenAutoPayCTA;
    case `cashboostOfferScreenAutoPaybackLabel`: return cashboostOfferScreenAutoPaybackLabel;
    case `cashboostOfferScreenBankAccountLabel`: return cashboostOfferScreenBankAccountLabel;
    case `cashboostOfferScreenContinueCTA`: return cashboostOfferScreenContinueCTA;
    case `cashboostOfferScreenOfferTitle`: return cashboostOfferScreenOfferTitle;
    case `cashboostOfferScreenPaybackDateLabel`: return cashboostOfferScreenPaybackDateLabel;
    case `cashboostOfferScreenSubsequentCTALabel`: return cashboostOfferScreenSubsequentCTALabel;
    case `cashboostOfferScreenTitle`: return cashboostOfferScreenTitle;
    case `cashboostOfferStandardButtonPrice`: return cashboostOfferStandardButtonPrice;
    case `cashboostOfferStandardButtonTitle`: return cashboostOfferStandardButtonTitle;
    case `cashboostOfferStandardInfoBody`: return cashboostOfferStandardInfoBody;
    case `cashboostOfferStandardInfoTitle`: return cashboostOfferStandardInfoTitle;
    case `cashboostOfferTakingTooLongContent`: return cashboostOfferTakingTooLongContent;
    case `cashboostOfferTakingTooLongTitle`: return cashboostOfferTakingTooLongTitle;
    case `cashboostOfferWhenWillItArrive`: return cashboostOfferWhenWillItArrive;
    case `cashboostPayNowAdvanceAmountTitle`: return cashboostPayNowAdvanceAmountTitle;
    case `cashboostPayNowConfirmationCTATitle`: return cashboostPayNowConfirmationCTATitle;
    case `cashboostPayNowConfirmationCashboost`: return cashboostPayNowConfirmationCashboost;
    case `cashboostPayNowConfirmationChangePaymentMethod`: return cashboostPayNowConfirmationChangePaymentMethod;
    case `cashboostPayNowConfirmationPayEarlyPrompt`: return cashboostPayNowConfirmationPayEarlyPrompt;
    case `cashboostPayNowConfirmationTitle`: return cashboostPayNowConfirmationTitle;
    case `cashboostPayNowConfirmationTransferFrom`: return cashboostPayNowConfirmationTransferFrom;
    case `cashboostPayNowDeliveryFeeTitle`: return cashboostPayNowDeliveryFeeTitle;
    case `cashboostPayNowErrorDismissCTA`: return cashboostPayNowErrorDismissCTA;
    case `cashboostPayNowErrorInsufficientFundsContent`: return cashboostPayNowErrorInsufficientFundsContent;
    case `cashboostPayNowErrorInsufficientFundsTitle`: return cashboostPayNowErrorInsufficientFundsTitle;
    case `cashboostPayNowLoadingTitle`: return cashboostPayNowLoadingTitle;
    case `cashboostPayNowSuccessAnotherAdvanceCTA`: return cashboostPayNowSuccessAnotherAdvanceCTA;
    case `cashboostPayNowSuccessContent`: return cashboostPayNowSuccessContent;
    case `cashboostPayNowSuccessNoCtaButton`: return cashboostPayNowSuccessNoCtaButton;
    case `cashboostPayNowSuccessNoCtaContent`: return cashboostPayNowSuccessNoCtaContent;
    case `cashboostPayNowSuccessNoCtaTitle`: return cashboostPayNowSuccessNoCtaTitle;
    case `cashboostPayNowSuccessSkipCTA`: return cashboostPayNowSuccessSkipCTA;
    case `cashboostPayNowSuccessTitle`: return cashboostPayNowSuccessTitle;
    case `cashboostPayNowTotalDueTitle`: return cashboostPayNowTotalDueTitle;
    case `cashboostPayNowTransferFromTitle`: return cashboostPayNowTransferFromTitle;
    case `cashboostPlaidIntroBoostcardTitle`: return cashboostPlaidIntroBoostcardTitle;
    case `cashboostPlaidIntroBulletOne`: return cashboostPlaidIntroBulletOne;
    case `cashboostPlaidIntroBulletOneCTA`: return cashboostPlaidIntroBulletOneCTA;
    case `cashboostPlaidIntroBulletOneNumber`: return cashboostPlaidIntroBulletOneNumber;
    case `cashboostPlaidIntroBulletThree`: return cashboostPlaidIntroBulletThree;
    case `cashboostPlaidIntroBulletThreeNumber`: return cashboostPlaidIntroBulletThreeNumber;
    case `cashboostPlaidIntroBulletTwo`: return cashboostPlaidIntroBulletTwo;
    case `cashboostPlaidIntroBulletTwoNumber`: return cashboostPlaidIntroBulletTwoNumber;
    case `cashboostPlaidIntroCTA`: return cashboostPlaidIntroCTA;
    case `cashboostPlaidIntroTitle`: return cashboostPlaidIntroTitle;
    case `cashboostPlaidIntroWithCriteria60Days`: return cashboostPlaidIntroWithCriteria60Days;
    case `cashboostPlaidIntroWithCriteriaConnectivityToGrid`: return cashboostPlaidIntroWithCriteriaConnectivityToGrid;
    case `cashboostPlaidIntroWithCriteriaConsistentDeposits`: return cashboostPlaidIntroWithCriteriaConsistentDeposits;
    case `cashboostPlaidIntroWithCriteriaConsistentSpending`: return cashboostPlaidIntroWithCriteriaConsistentSpending;
    case `cashboostPlaidIntroWithCriteriaMinimum1KDeposits`: return cashboostPlaidIntroWithCriteriaMinimum1KDeposits;
    case `cashboostPlaidIntroWithCriteriaMinimum1KSpending`: return cashboostPlaidIntroWithCriteriaMinimum1KSpending;
    case `cashboostPlaidIntroWithCriteriaTitle`: return cashboostPlaidIntroWithCriteriaTitle;
    case `cashboostQualificationStepProcessingContent`: return cashboostQualificationStepProcessingContent;
    case `cashboostQualificationStepProcessingTitle`: return cashboostQualificationStepProcessingTitle;
    case `cashboostRewardsIntroAfterLevel10`: return cashboostRewardsIntroAfterLevel10;
    case `cashboostRewardsIntroCloseCTA`: return cashboostRewardsIntroCloseCTA;
    case `cashboostRewardsIntroContinueCTA`: return cashboostRewardsIntroContinueCTA;
    case `cashboostRewardsIntroGetUpgraded`: return cashboostRewardsIntroGetUpgraded;
    case `cashboostRewardsIntroLearnMoreCTA`: return cashboostRewardsIntroLearnMoreCTA;
    case `cashboostRewardsIntroRowAdvance`: return cashboostRewardsIntroRowAdvance;
    case `cashboostRewardsIntroSubtitle`: return cashboostRewardsIntroSubtitle;
    case `cashboostRewardsIntroSubtitleUpdated`: return cashboostRewardsIntroSubtitleUpdated;
    case `cashboostRewardsIntroTitle`: return cashboostRewardsIntroTitle;
    case `cashboostRewardsIntroTitleUpdated`: return cashboostRewardsIntroTitleUpdated;
    case `cashboostRiskListFreeMembershipBanner`: return cashboostRiskListFreeMembershipBanner;
    case `cashboostRiskListModal1KDepositsContent`: return cashboostRiskListModal1KDepositsContent;
    case `cashboostRiskListModal1KDepositsTitle`: return cashboostRiskListModal1KDepositsTitle;
    case `cashboostRiskListModal1KWithdrawlsContent`: return cashboostRiskListModal1KWithdrawlsContent;
    case `cashboostRiskListModal1KWithdrawlsTitle`: return cashboostRiskListModal1KWithdrawlsTitle;
    case `cashboostRiskListModal60DaysActiveContent`: return cashboostRiskListModal60DaysActiveContent;
    case `cashboostRiskListModal60DaysActiveTitle`: return cashboostRiskListModal60DaysActiveTitle;
    case `cashboostRiskListModalConsistentDepositsContent`: return cashboostRiskListModalConsistentDepositsContent;
    case `cashboostRiskListModalConsistentDepositsTitle`: return cashboostRiskListModalConsistentDepositsTitle;
    case `cashboostRiskListModalConsistentSpendingContent`: return cashboostRiskListModalConsistentSpendingContent;
    case `cashboostRiskListModalConsistentSpendingTitle`: return cashboostRiskListModalConsistentSpendingTitle;
    case `cashboostRiskListModalGridConnectivityContent`: return cashboostRiskListModalGridConnectivityContent;
    case `cashboostRiskListModalGridConnectivityTitle`: return cashboostRiskListModalGridConnectivityTitle;
    case `cashboostRiskListModalGridWhatCanIDoTitle`: return cashboostRiskListModalGridWhatCanIDoTitle;
    case `cashboostSetupBankAccountError1KDepositsRequirement`: return cashboostSetupBankAccountError1KDepositsRequirement;
    case `cashboostSetupBankAccountError1KSpendingRequirement`: return cashboostSetupBankAccountError1KSpendingRequirement;
    case `cashboostSetupBankAccountErrorActiveAndInGoodStandingRequirement`: return cashboostSetupBankAccountErrorActiveAndInGoodStandingRequirement;
    case `cashboostSetupBankAccountErrorCTA`: return cashboostSetupBankAccountErrorCTA;
    case `cashboostSetupBankAccountErrorConsistentDepositsRequirement`: return cashboostSetupBankAccountErrorConsistentDepositsRequirement;
    case `cashboostSetupBankAccountErrorConsistentSpendingRequirement`: return cashboostSetupBankAccountErrorConsistentSpendingRequirement;
    case `cashboostSetupBankAccountErrorDescription`: return cashboostSetupBankAccountErrorDescription;
    case `cashboostSetupBankAccountErrorMinLengthRequirement`: return cashboostSetupBankAccountErrorMinLengthRequirement;
    case `cashboostSetupBankAccountErrorTitle`: return cashboostSetupBankAccountErrorTitle;
    case `cashboostSetupBankAccountErrorUnsupportedBankAccountRequirement`: return cashboostSetupBankAccountErrorUnsupportedBankAccountRequirement;
    case `cashboostSetupBankAccountExistsOnClientErrorCTA`: return cashboostSetupBankAccountExistsOnClientErrorCTA;
    case `cashboostSetupBankAccountExistsOnClientErrorContent`: return cashboostSetupBankAccountExistsOnClientErrorContent;
    case `cashboostSetupBankAccountExistsOnClientErrorTitle`: return cashboostSetupBankAccountExistsOnClientErrorTitle;
    case `cashboostSetupBankAccountInstitutionUnsupportedCTA`: return cashboostSetupBankAccountInstitutionUnsupportedCTA;
    case `cashboostSetupBankAccountInstitutionUnsupportedContent`: return cashboostSetupBankAccountInstitutionUnsupportedContent;
    case `cashboostSetupBankAccountInstitutionUnsupportedTitle`: return cashboostSetupBankAccountInstitutionUnsupportedTitle;
    case `cashboostSetupBankAccountUnsupportedBlacklistItems`: return cashboostSetupBankAccountUnsupportedBlacklistItems;
    case `cashboostSetupBankAccountUnsupportedBlacklistTitle`: return cashboostSetupBankAccountUnsupportedBlacklistTitle;
    case `cashboostSetupBankAccountUnsupportedCtaContent`: return cashboostSetupBankAccountUnsupportedCtaContent;
    case `cashboostSetupBankAccountUnsupportedCtaTitle`: return cashboostSetupBankAccountUnsupportedCtaTitle;
    case `cashboostSetupBankAccountUnsupportedTitle`: return cashboostSetupBankAccountUnsupportedTitle;
    case `cashboostSetupDebitErrorActiveAccountRequirement`: return cashboostSetupDebitErrorActiveAccountRequirement;
    case `cashboostSetupDebitErrorConnectedToBankAccountRequirement`: return cashboostSetupDebitErrorConnectedToBankAccountRequirement;
    case `cashboostSetupNeedsFinished`: return cashboostSetupNeedsFinished;
    case `cashboostSetupSubsequentAdvanceActiveAccountRequirement`: return cashboostSetupSubsequentAdvanceActiveAccountRequirement;
    case `cashboostSetupSubsequentSufficientFundsAccountRequirement`: return cashboostSetupSubsequentSufficientFundsAccountRequirement;
    case `cashboostSocialSecurityContinueCTA`: return cashboostSocialSecurityContinueCTA;
    case `cashboostSocialSecurityInfoDescription`: return cashboostSocialSecurityInfoDescription;
    case `cashboostSocialSecurityInfoTitle`: return cashboostSocialSecurityInfoTitle;
    case `cashboostSocialSecurityQuestionCTA`: return cashboostSocialSecurityQuestionCTA;
    case `cashboostSocialSecurityTitle`: return cashboostSocialSecurityTitle;
    case `cashboostStatusTileDeliveredTitle`: return cashboostStatusTileDeliveredTitle;
    case `cashboostStatusTileFastArrivalEst`: return cashboostStatusTileFastArrivalEst;
    case `cashboostStatusTileStandardArrivalEst`: return cashboostStatusTileStandardArrivalEst;
    case `cashboostStepCompletionContinueCTA`: return cashboostStepCompletionContinueCTA;
    case `cashboostStepCompletionStepAll`: return cashboostStepCompletionStepAll;
    case `cashboostStepCompletionStepBankAccount`: return cashboostStepCompletionStepBankAccount;
    case `cashboostStepCompletionStepOneBulletTitle`: return cashboostStepCompletionStepOneBulletTitle;
    case `cashboostStepCompletionStepThreeBulletTitle`: return cashboostStepCompletionStepThreeBulletTitle;
    case `cashboostStepCompletionStepTransferDetails`: return cashboostStepCompletionStepTransferDetails;
    case `cashboostStepCompletionStepTwoBulletTitle`: return cashboostStepCompletionStepTwoBulletTitle;
    case `cashboostStepProcessingBankAccountTitle`: return cashboostStepProcessingBankAccountTitle;
    case `cashboostStepProcessingGenericSubtitle`: return cashboostStepProcessingGenericSubtitle;
    case `cashboostStepProcessingIdentityTitle`: return cashboostStepProcessingIdentityTitle;
    case `cashboostStepProcessingtransferDetailsTitle`: return cashboostStepProcessingtransferDetailsTitle;
    case `cashboostSubsequentAdvanceIneligibleError`: return cashboostSubsequentAdvanceIneligibleError;
    case `cashboostSubsequentAdvanceMigrationIntroTitle`: return cashboostSubsequentAdvanceMigrationIntroTitle;
    case `cashboostSubsequentAdvanceTileButtonPrimarySecondaryText`: return cashboostSubsequentAdvanceTileButtonPrimarySecondaryText;
    case `cashboostSubsequentAdvanceTileButtonPrimaryText`: return cashboostSubsequentAdvanceTileButtonPrimaryText;
    case `cashboostSubsequentAdvanceTileContent`: return cashboostSubsequentAdvanceTileContent;
    case `cashboostSubsequentOfferAcceptAccount`: return cashboostSubsequentOfferAcceptAccount;
    case `cashboostSubsequentOfferAcceptCTA`: return cashboostSubsequentOfferAcceptCTA;
    case `cashboostSubsequentOfferAcceptPayback`: return cashboostSubsequentOfferAcceptPayback;
    case `cashboostSubsequentOfferAcceptTerms`: return cashboostSubsequentOfferAcceptTerms;
    case `cashboostSubsequentOfferAcceptTermsBoldAlgoQuery`: return cashboostSubsequentOfferAcceptTermsBoldAlgoQuery;
    case `cashboostSubsequentOfferAcceptTermsBoldKey`: return cashboostSubsequentOfferAcceptTermsBoldKey;
    case `cashboostSubsequentOfferAcceptTermsTermsLinkAlgoQuery`: return cashboostSubsequentOfferAcceptTermsTermsLinkAlgoQuery;
    case `cashboostSubsequentOfferAcceptTermsTermsLinkAlgoQueryTermsAttribution`: return cashboostSubsequentOfferAcceptTermsTermsLinkAlgoQueryTermsAttribution;
    case `cashboostSubsequentOfferAcceptTermsWithAccounts`: return cashboostSubsequentOfferAcceptTermsWithAccounts;
    case `cashboostSubsequentOfferAcceptTermsWithAccountsAndGrid`: return cashboostSubsequentOfferAcceptTermsWithAccountsAndGrid;
    case `cashboostSubsequentOfferAcceptTitle`: return cashboostSubsequentOfferAcceptTitle;
    case `cashboostSupplementalAddressEntryTextfieldPlaceholder`: return cashboostSupplementalAddressEntryTextfieldPlaceholder;
    case `cashboostTileAccountError`: return cashboostTileAccountError;
    case `cashboostTileAccountErrorBankAccount`: return cashboostTileAccountErrorBankAccount;
    case `cashboostTileAccountErrorBannerCTA`: return cashboostTileAccountErrorBannerCTA;
    case `cashboostTileAccountErrorDebitCard`: return cashboostTileAccountErrorDebitCard;
    case `cashboostTileAccountErrorPayoutException`: return cashboostTileAccountErrorPayoutException;
    case `cashboostTileAdvanceProcessing`: return cashboostTileAdvanceProcessing;
    case `cashboostTileBannerExtendedWait`: return cashboostTileBannerExtendedWait;
    case `cashboostTileBannerExtendedWaitCTA`: return cashboostTileBannerExtendedWaitCTA;
    case `cashboostTileBannerPayBackEarly`: return cashboostTileBannerPayBackEarly;
    case `cashboostTileIDVBannerCTA`: return cashboostTileIDVBannerCTA;
    case `cashboostTileIDVBannerInitialTitle`: return cashboostTileIDVBannerInitialTitle;
    case `cashboostTileIDVBannerProcessingTitle`: return cashboostTileIDVBannerProcessingTitle;
    case `cashboostTileIDVBannerSubsequentTitle`: return cashboostTileIDVBannerSubsequentTitle;
    case `cashboostTileMembershipError`: return cashboostTileMembershipError;
    case `cashboostToBoostCardCTA`: return cashboostToBoostCardCTA;
    case `cashboostToBoostCardContent`: return cashboostToBoostCardContent;
    case `cashboostToBoostCardContentHighlightQueryApproved`: return cashboostToBoostCardContentHighlightQueryApproved;
    case `cashboostToBoostCardContentHighlightQueryFree`: return cashboostToBoostCardContentHighlightQueryFree;
    case `cashboostToBoostCardTitle`: return cashboostToBoostCardTitle;
    case `cashboostToBoostCardTitleHighlightQuery`: return cashboostToBoostCardTitleHighlightQuery;
    case `cashboostToCreditLineAdvanceDeliveryInfo`: return cashboostToCreditLineAdvanceDeliveryInfo;
    case `cashboostToCreditLineAdvanceRepayment`: return cashboostToCreditLineAdvanceRepayment;
    case `cashboostToCreditLineCta`: return cashboostToCreditLineCta;
    case `cashboostToCreditLineFirstPayment`: return cashboostToCreditLineFirstPayment;
    case `cashboostToCreditLineGridPlusSubscription`: return cashboostToCreditLineGridPlusSubscription;
    case `cashboostToCreditLineGridPlusSubscriptionInfo`: return cashboostToCreditLineGridPlusSubscriptionInfo;
    case `cashboostToCreditLineHeader`: return cashboostToCreditLineHeader;
    case `cashboostToCreditLineReactivationFee`: return cashboostToCreditLineReactivationFee;
    case `cashboostToCreditLineRemainingCreditLine`: return cashboostToCreditLineRemainingCreditLine;
    case `cashboostToCreditLineStartingCreditLimit`: return cashboostToCreditLineStartingCreditLimit;
    case `cashboostToCreditLineSubtitle`: return cashboostToCreditLineSubtitle;
    case `cashboostToCreditLineTitle`: return cashboostToCreditLineTitle;
    case `cashboostUnsupportedBankAccountCTA`: return cashboostUnsupportedBankAccountCTA;
    case `cashboostUnsupportedBankAccountTitle`: return cashboostUnsupportedBankAccountTitle;
    case `cashboostVerifyIdentityContinueCTA`: return cashboostVerifyIdentityContinueCTA;
    case `cashboostVerifyIdentityDOBPlaceholder`: return cashboostVerifyIdentityDOBPlaceholder;
    case `cashboostVerifyIdentityDOBTitle`: return cashboostVerifyIdentityDOBTitle;
    case `cashboostVerifyIdentityFirstNamePlaceholder`: return cashboostVerifyIdentityFirstNamePlaceholder;
    case `cashboostVerifyIdentityFirstNameTitle`: return cashboostVerifyIdentityFirstNameTitle;
    case `cashboostVerifyIdentityInvalidDOB`: return cashboostVerifyIdentityInvalidDOB;
    case `cashboostVerifyIdentityInvalidSSN`: return cashboostVerifyIdentityInvalidSSN;
    case `cashboostVerifyIdentityLastNamePlaceholder`: return cashboostVerifyIdentityLastNamePlaceholder;
    case `cashboostVerifyIdentityLastNameTitle`: return cashboostVerifyIdentityLastNameTitle;
    case `cashboostVerifyIdentityTitle`: return cashboostVerifyIdentityTitle;
    case `celebration2XRewardsCTATitle`: return celebration2XRewardsCTATitle;
    case `celebration2XRewardsSubtitle`: return celebration2XRewardsSubtitle;
    case `celebration2XRewardsSubtitleBold`: return celebration2XRewardsSubtitleBold;
    case `celebration2XRewardsTitle`: return celebration2XRewardsTitle;
    case `celebrationCreditLineCTATitle`: return celebrationCreditLineCTATitle;
    case `celebrationCreditLineSubtitle`: return celebrationCreditLineSubtitle;
    case `celebrationCreditLineTitle`: return celebrationCreditLineTitle;
    case `celebrationCreditLineUpgradeCTATitle`: return celebrationCreditLineUpgradeCTATitle;
    case `celebrationCreditLineUpgradeSubtitle`: return celebrationCreditLineUpgradeSubtitle;
    case `celebrationCreditLineUpgradeTitle`: return celebrationCreditLineUpgradeTitle;
    case `changeCashboostBankAccountIntroBody`: return changeCashboostBankAccountIntroBody;
    case `changeCashboostBankAccountIntroBodyTitle`: return changeCashboostBankAccountIntroBodyTitle;
    case `changeCashboostBankAccountIntroCTA`: return changeCashboostBankAccountIntroCTA;
    case `changeCashboostBankAccountIntroTitle`: return changeCashboostBankAccountIntroTitle;
    case `chatAttachmentFolderTitleDefault`: return chatAttachmentFolderTitleDefault;
    case `chatAttachmentOnlyMessageBodyPlural`: return chatAttachmentOnlyMessageBodyPlural;
    case `chatAttachmentOnlyMessageBodySingular`: return chatAttachmentOnlyMessageBodySingular;
    case `chatCancelSubscriptionAutoresponderCTAConfirm`: return chatCancelSubscriptionAutoresponderCTAConfirm;
    case `chatCancelSubscriptionAutoresponderCTADeny`: return chatCancelSubscriptionAutoresponderCTADeny;
    case `chatCancelSubscriptionAutoresponderContent`: return chatCancelSubscriptionAutoresponderContent;
    case `chatCancelSubscriptionAutoresponderContentWithSLA`: return chatCancelSubscriptionAutoresponderContentWithSLA;
    case `chatCancelSubscriptionAutoresponderSenderName`: return chatCancelSubscriptionAutoresponderSenderName;
    case `chatCancelSubscriptionPrefilledText`: return chatCancelSubscriptionPrefilledText;
    case `chatCancelSubscriptionPrefilledTextEditAlertCancelCTA`: return chatCancelSubscriptionPrefilledTextEditAlertCancelCTA;
    case `chatCancelSubscriptionPrefilledTextEditAlertContent`: return chatCancelSubscriptionPrefilledTextEditAlertContent;
    case `chatCancelSubscriptionPrefilledTextEditAlertContinueCTA`: return chatCancelSubscriptionPrefilledTextEditAlertContinueCTA;
    case `chatCancelSubscriptionPrefilledTextEditAlertTitle`: return chatCancelSubscriptionPrefilledTextEditAlertTitle;
    case `chatCancelSubscriptionRententionCloserAutoresponderContent`: return chatCancelSubscriptionRententionCloserAutoresponderContent;
    case `chatCannotCancelWithActiveAdvanceContinueCTA`: return chatCannotCancelWithActiveAdvanceContinueCTA;
    case `chatCannotCancelWithActiveAdvanceMessage`: return chatCannotCancelWithActiveAdvanceMessage;
    case `chatCannotCancelWithActiveAdvanceTitle`: return chatCannotCancelWithActiveAdvanceTitle;
    case `chatCellEditedAddendum`: return chatCellEditedAddendum;
    case `chatCellSLA`: return chatCellSLA;
    case `chatInputPlaceholder`: return chatInputPlaceholder;
    case `chatPlaceholderInfo`: return chatPlaceholderInfo;
    case `chatPlaceholderTitle`: return chatPlaceholderTitle;
    case `chatTitle`: return chatTitle;
    case `chatWarningNoTextMessage`: return chatWarningNoTextMessage;
    case `checkoutBenefits0Title`: return checkoutBenefits0Title;
    case `checkoutBenefits1Title`: return checkoutBenefits1Title;
    case `checkoutBenefits2Title`: return checkoutBenefits2Title;
    case `checkoutBenefits3Title`: return checkoutBenefits3Title;
    case `checkoutCompletedBody`: return checkoutCompletedBody;
    case `checkoutCompletedButtonDashboard`: return checkoutCompletedButtonDashboard;
    case `checkoutCompletedButtonPush`: return checkoutCompletedButtonPush;
    case `checkoutCompletedEnableATTEnableButton`: return checkoutCompletedEnableATTEnableButton;
    case `checkoutCompletedEnableATTMessage`: return checkoutCompletedEnableATTMessage;
    case `checkoutCompletedEnableATTSkipButton`: return checkoutCompletedEnableATTSkipButton;
    case `checkoutCompletedEnableATTTitle`: return checkoutCompletedEnableATTTitle;
    case `checkoutCompletedTitle`: return checkoutCompletedTitle;
    case `checkoutDiscountMemoExistingGridPlus`: return checkoutDiscountMemoExistingGridPlus;
    case `checkoutDiscountMemoFreeForeverCoupon`: return checkoutDiscountMemoFreeForeverCoupon;
    case `checkoutDiscountMemoSkipCheckout`: return checkoutDiscountMemoSkipCheckout;
    case `checkoutDiscountMemoUnknownCoupon`: return checkoutDiscountMemoUnknownCoupon;
    case `checkoutDiscountMemoWaivedFeeCoupon`: return checkoutDiscountMemoWaivedFeeCoupon;
    case `checkoutDiscountWaivedFeeBody`: return checkoutDiscountWaivedFeeBody;
    case `checkoutDiscountWaivedFeeSubtitle`: return checkoutDiscountWaivedFeeSubtitle;
    case `checkoutErrorBillingTermsNotAcceptedMessage`: return checkoutErrorBillingTermsNotAcceptedMessage;
    case `checkoutErrorTitle`: return checkoutErrorTitle;
    case `checkoutEstimateBoostEstimateItemDescription`: return checkoutEstimateBoostEstimateItemDescription;
    case `checkoutEstimateBoostEstimateItemTitle`: return checkoutEstimateBoostEstimateItemTitle;
    case `checkoutEstimateEndOfYearRefundEstimateDescription`: return checkoutEstimateEndOfYearRefundEstimateDescription;
    case `checkoutEstimateEndOfYearRefundEstimateTitle`: return checkoutEstimateEndOfYearRefundEstimateTitle;
    case `checkoutEstimateTotalBoostEstimateItemDescription`: return checkoutEstimateTotalBoostEstimateItemDescription;
    case `checkoutEstimateTotalBoostEstimateItemTitle`: return checkoutEstimateTotalBoostEstimateItemTitle;
    case `checkoutGridFreePaymentSummaryItemLabel`: return checkoutGridFreePaymentSummaryItemLabel;
    case `checkoutIntroBody`: return checkoutIntroBody;
    case `checkoutIntroBreakdownBodyLabel`: return checkoutIntroBreakdownBodyLabel;
    case `checkoutIntroBreakdownBodyLabelFalback`: return checkoutIntroBreakdownBodyLabelFalback;
    case `checkoutIntroBreakdownButton`: return checkoutIntroBreakdownButton;
    case `checkoutIntroBreakdownOfferTitle`: return checkoutIntroBreakdownOfferTitle;
    case `checkoutIntroBreakdownPriceFree`: return checkoutIntroBreakdownPriceFree;
    case `checkoutIntroBreakdownPriceIncludedGridPlus`: return checkoutIntroBreakdownPriceIncludedGridPlus;
    case `checkoutIntroBreakdownPriceOneTimeLabel`: return checkoutIntroBreakdownPriceOneTimeLabel;
    case `checkoutIntroBreakdownPriceRecurringDailyLabel`: return checkoutIntroBreakdownPriceRecurringDailyLabel;
    case `checkoutIntroBreakdownPriceRecurringLabelWithDueTodaySuffix`: return checkoutIntroBreakdownPriceRecurringLabelWithDueTodaySuffix;
    case `checkoutIntroBreakdownPriceRecurringMonthlyLabel`: return checkoutIntroBreakdownPriceRecurringMonthlyLabel;
    case `checkoutIntroBreakdownPriceRecurringWeeklyLabel`: return checkoutIntroBreakdownPriceRecurringWeeklyLabel;
    case `checkoutIntroBreakdownPriceRecurringYearlyLabel`: return checkoutIntroBreakdownPriceRecurringYearlyLabel;
    case `checkoutIntroBreakdownPriceTitle`: return checkoutIntroBreakdownPriceTitle;
    case `checkoutIntroBreakdownTitleLabel`: return checkoutIntroBreakdownTitleLabel;
    case `checkoutIntroButton`: return checkoutIntroButton;
    case `checkoutIntroTitle`: return checkoutIntroTitle;
    case `checkoutPayItForwardBody`: return checkoutPayItForwardBody;
    case `checkoutPayItForwardCallToAction`: return checkoutPayItForwardCallToAction;
    case `checkoutPayItForwardHeading`: return checkoutPayItForwardHeading;
    case `checkoutPayItForwardInstructions`: return checkoutPayItForwardInstructions;
    case `checkoutPayItForwardInstructionsSelection0`: return checkoutPayItForwardInstructionsSelection0;
    case `checkoutPayItForwardInstructionsSelection1`: return checkoutPayItForwardInstructionsSelection1;
    case `checkoutPayItForwardInstructionsSelection2`: return checkoutPayItForwardInstructionsSelection2;
    case `checkoutPayItForwardTitleWithCredit`: return checkoutPayItForwardTitleWithCredit;
    case `checkoutPayItForwardTitleWithoutCredit`: return checkoutPayItForwardTitleWithoutCredit;
    case `checkoutPaymentAmountDueTodayLabel`: return checkoutPaymentAmountDueTodayLabel;
    case `checkoutPaymentApplePayTitle`: return checkoutPaymentApplePayTitle;
    case `checkoutPaymentBillingCheckboxLabel`: return checkoutPaymentBillingCheckboxLabel;
    case `checkoutPaymentBillingTermsDaily`: return checkoutPaymentBillingTermsDaily;
    case `checkoutPaymentBillingTermsFallback`: return checkoutPaymentBillingTermsFallback;
    case `checkoutPaymentBillingTermsMonthly`: return checkoutPaymentBillingTermsMonthly;
    case `checkoutPaymentBillingTermsOnce`: return checkoutPaymentBillingTermsOnce;
    case `checkoutPaymentBillingTermsWeekly`: return checkoutPaymentBillingTermsWeekly;
    case `checkoutPaymentBillingTermsYearly`: return checkoutPaymentBillingTermsYearly;
    case `checkoutPaymentCallToAction`: return checkoutPaymentCallToAction;
    case `checkoutPaymentCardFormErrorsGeneralError`: return checkoutPaymentCardFormErrorsGeneralError;
    case `checkoutPaymentCardSubmitButton`: return checkoutPaymentCardSubmitButton;
    case `checkoutPaymentConfirmButton`: return checkoutPaymentConfirmButton;
    case `checkoutPaymentConfirmTitle`: return checkoutPaymentConfirmTitle;
    case `checkoutPaymentFinePrintApplePayFallback`: return checkoutPaymentFinePrintApplePayFallback;
    case `checkoutPaymentFinePrintApplePayForGridFree`: return checkoutPaymentFinePrintApplePayForGridFree;
    case `checkoutPaymentFinePrintApplePayWithAmounts`: return checkoutPaymentFinePrintApplePayWithAmounts;
    case `checkoutPaymentFinePrintCreditCardFallback`: return checkoutPaymentFinePrintCreditCardFallback;
    case `checkoutPaymentFinePrintCreditCardForGridFree`: return checkoutPaymentFinePrintCreditCardForGridFree;
    case `checkoutPaymentFinePrintCreditCardWithAmounts`: return checkoutPaymentFinePrintCreditCardWithAmounts;
    case `checkoutPaymentFinePrintIntervalDay`: return checkoutPaymentFinePrintIntervalDay;
    case `checkoutPaymentFinePrintIntervalFallback`: return checkoutPaymentFinePrintIntervalFallback;
    case `checkoutPaymentFinePrintIntervalMonth`: return checkoutPaymentFinePrintIntervalMonth;
    case `checkoutPaymentFinePrintIntervalOnce`: return checkoutPaymentFinePrintIntervalOnce;
    case `checkoutPaymentFinePrintIntervalWeek`: return checkoutPaymentFinePrintIntervalWeek;
    case `checkoutPaymentFinePrintIntervalYear`: return checkoutPaymentFinePrintIntervalYear;
    case `checkoutPaymentFreeTrialPaymentSummaryItemLabel`: return checkoutPaymentFreeTrialPaymentSummaryItemLabel;
    case `checkoutPaymentHeading`: return checkoutPaymentHeading;
    case `checkoutPaymentPricePerInterval`: return checkoutPaymentPricePerInterval;
    case `checkoutPaymentPricingPlanIntervalDayPaymentSummaryItemLabel`: return checkoutPaymentPricingPlanIntervalDayPaymentSummaryItemLabel;
    case `checkoutPaymentPricingPlanIntervalDefaultPaymentSummaryItemLabel`: return checkoutPaymentPricingPlanIntervalDefaultPaymentSummaryItemLabel;
    case `checkoutPaymentPricingPlanIntervalInitiationFeePaymentSummaryItemLabel`: return checkoutPaymentPricingPlanIntervalInitiationFeePaymentSummaryItemLabel;
    case `checkoutPaymentPricingPlanIntervalMonthPaymentSummaryItemLabel`: return checkoutPaymentPricingPlanIntervalMonthPaymentSummaryItemLabel;
    case `checkoutPaymentPricingPlanIntervalWeekPaymentSummaryItemLabel`: return checkoutPaymentPricingPlanIntervalWeekPaymentSummaryItemLabel;
    case `checkoutPaymentPricingPlanIntervalYearPaymentSummaryItemLabel`: return checkoutPaymentPricingPlanIntervalYearPaymentSummaryItemLabel;
    case `checkoutPaymentSubscriptionAmountLabel`: return checkoutPaymentSubscriptionAmountLabel;
    case `checkoutPaymentTermsUrl`: return checkoutPaymentTermsUrl;
    case `checkoutPaymentTitle`: return checkoutPaymentTitle;
    case `checkoutPricingBodyOneTimeWithFields`: return checkoutPricingBodyOneTimeWithFields;
    case `checkoutPricingBodyWithFields`: return checkoutPricingBodyWithFields;
    case `checkoutPricingButtonContinue`: return checkoutPricingButtonContinue;
    case `checkoutPricingCallToAction`: return checkoutPricingCallToAction;
    case `checkoutPricingSparklesIntervalPrefix`: return checkoutPricingSparklesIntervalPrefix;
    case `checkoutPricingSparklesIntervalPrefixThis`: return checkoutPricingSparklesIntervalPrefixThis;
    case `checkoutPricingTitle`: return checkoutPricingTitle;
    case `checkoutReviewTitle`: return checkoutReviewTitle;
    case `checkoutStripeCompanyName`: return checkoutStripeCompanyName;
    case `ckoCardInstantPayoutIneligible`: return ckoCardInstantPayoutIneligible;
    case `ckoCardVerificationFailed`: return ckoCardVerificationFailed;
    case `closeAccountActiveSubscriptionAlertContent`: return closeAccountActiveSubscriptionAlertContent;
    case `closeAccountActiveSubscriptionAlertTitle`: return closeAccountActiveSubscriptionAlertTitle;
    case `closeAccountClosedBody`: return closeAccountClosedBody;
    case `closeAccountClosedButton`: return closeAccountClosedButton;
    case `closeAccountClosedTitle`: return closeAccountClosedTitle;
    case `closeAccountConfirmAlertActionCancel`: return closeAccountConfirmAlertActionCancel;
    case `closeAccountConfirmAlertActionCloseAccount`: return closeAccountConfirmAlertActionCloseAccount;
    case `closeAccountConfirmAlertMessage`: return closeAccountConfirmAlertMessage;
    case `closeAccountConfirmAlertTitle`: return closeAccountConfirmAlertTitle;
    case `closeAccountConfirmBody`: return closeAccountConfirmBody;
    case `closeAccountConfirmButtonCancel`: return closeAccountConfirmButtonCancel;
    case `closeAccountConfirmButtonConfirm`: return closeAccountConfirmButtonConfirm;
    case `closeAccountConfirmLoadingModalContent`: return closeAccountConfirmLoadingModalContent;
    case `closeAccountConfirmTitle`: return closeAccountConfirmTitle;
    case `closeAccountDisputedBalanceAlertContent`: return closeAccountDisputedBalanceAlertContent;
    case `closeAccountDisputedBalanceAlertTitle`: return closeAccountDisputedBalanceAlertTitle;
    case `closeAccountOutstandingBalanceAlertContent`: return closeAccountOutstandingBalanceAlertContent;
    case `closeAccountOutstandingBalanceAlertContinueCTA`: return closeAccountOutstandingBalanceAlertContinueCTA;
    case `closeAccountOutstandingBalanceAlertPayFeesCTA`: return closeAccountOutstandingBalanceAlertPayFeesCTA;
    case `closeAccountOutstandingBalanceAlertRepayAdvanceCTA`: return closeAccountOutstandingBalanceAlertRepayAdvanceCTA;
    case `closeAccountOutstandingBalanceAlertTitle`: return closeAccountOutstandingBalanceAlertTitle;
    case `closeAccountOutstandingSubscriptionAlertContent`: return closeAccountOutstandingSubscriptionAlertContent;
    case `closeAccountWaivedFeeButtonConfirm`: return closeAccountWaivedFeeButtonConfirm;
    case `companyNameShort`: return companyNameShort;
    case `complianceAppealFailureButton`: return complianceAppealFailureButton;
    case `complianceAppealFailureSubtitle`: return complianceAppealFailureSubtitle;
    case `complianceAppealFailureTextHint`: return complianceAppealFailureTextHint;
    case `complianceAppealFailureTitle`: return complianceAppealFailureTitle;
    case `complianceConfirmProfileButton`: return complianceConfirmProfileButton;
    case `complianceConfirmProfileCardAddressTitle`: return complianceConfirmProfileCardAddressTitle;
    case `complianceConfirmProfileCardBasicDataDobLabel`: return complianceConfirmProfileCardBasicDataDobLabel;
    case `complianceConfirmProfileCardBasicDataFirstNameLabel`: return complianceConfirmProfileCardBasicDataFirstNameLabel;
    case `complianceConfirmProfileCardBasicDataLastNameLabel`: return complianceConfirmProfileCardBasicDataLastNameLabel;
    case `complianceConfirmProfileCardBasicDataTitle`: return complianceConfirmProfileCardBasicDataTitle;
    case `complianceConfirmProfileCardEditButton`: return complianceConfirmProfileCardEditButton;
    case `complianceConfirmProfileCardSsnTitle`: return complianceConfirmProfileCardSsnTitle;
    case `complianceConfirmProfileSubtitle`: return complianceConfirmProfileSubtitle;
    case `complianceConfirmProfileTitle`: return complianceConfirmProfileTitle;
    case `complianceDocumentUploadFootprintVerificationButton`: return complianceDocumentUploadFootprintVerificationButton;
    case `complianceDocumentUploadFootprintVerificationInfoItem`: return complianceDocumentUploadFootprintVerificationInfoItem;
    case `complianceDocumentUploadFootprintVerificationSubtitle`: return complianceDocumentUploadFootprintVerificationSubtitle;
    case `complianceDocumentUploadFootprintVerificationTitle`: return complianceDocumentUploadFootprintVerificationTitle;
    case `complianceDocumentUploadPrimerButton`: return complianceDocumentUploadPrimerButton;
    case `complianceDocumentUploadPrimerDocuments1Item1`: return complianceDocumentUploadPrimerDocuments1Item1;
    case `complianceDocumentUploadPrimerDocuments1Item2`: return complianceDocumentUploadPrimerDocuments1Item2;
    case `complianceDocumentUploadPrimerDocuments1Item3`: return complianceDocumentUploadPrimerDocuments1Item3;
    case `complianceDocumentUploadPrimerDocuments1Item4`: return complianceDocumentUploadPrimerDocuments1Item4;
    case `complianceDocumentUploadPrimerDocuments1Title`: return complianceDocumentUploadPrimerDocuments1Title;
    case `complianceDocumentUploadPrimerSubtitle`: return complianceDocumentUploadPrimerSubtitle;
    case `complianceDocumentUploadPrimerTitle`: return complianceDocumentUploadPrimerTitle;
    case `complianceEditBasicDataButton`: return complianceEditBasicDataButton;
    case `complianceEditBasicDataDobLabel`: return complianceEditBasicDataDobLabel;
    case `complianceEditBasicDataFirstNameLabel`: return complianceEditBasicDataFirstNameLabel;
    case `complianceEditBasicDataLastNameLabel`: return complianceEditBasicDataLastNameLabel;
    case `complianceEditBasicDataTitle`: return complianceEditBasicDataTitle;
    case `complianceVerificationFailedButton`: return complianceVerificationFailedButton;
    case `complianceVerificationFailedSubtitle`: return complianceVerificationFailedSubtitle;
    case `complianceVerificationFailedTitle`: return complianceVerificationFailedTitle;
    case `connectionDetailsBankAccountStatusConnected`: return connectionDetailsBankAccountStatusConnected;
    case `connectionDetailsBankAccountStatusError`: return connectionDetailsBankAccountStatusError;
    case `connectionDetailsBankAccountStatusErrorReadonly`: return connectionDetailsBankAccountStatusErrorReadonly;
    case `connectionDetailsHeader`: return connectionDetailsHeader;
    case `connectionDetailsJobTitle`: return connectionDetailsJobTitle;
    case `connectionDetailsPayrollProviderTitle`: return connectionDetailsPayrollProviderTitle;
    case `connectionDetailsRemove`: return connectionDetailsRemove;
    case `connectionDetailsRemoveConnectionAlertCancel`: return connectionDetailsRemoveConnectionAlertCancel;
    case `connectionDetailsRemoveConnectionAlertDelete`: return connectionDetailsRemoveConnectionAlertDelete;
    case `connectionDetailsRemoveConnectionAlertMessage`: return connectionDetailsRemoveConnectionAlertMessage;
    case `connectionDetailsRemoveConnectionAlertWarning`: return connectionDetailsRemoveConnectionAlertWarning;
    case `connectionDetailsRemoveConnectionLoadingText`: return connectionDetailsRemoveConnectionLoadingText;
    case `connectionDetailsSectionHeader`: return connectionDetailsSectionHeader;
    case `connectionDetailsStatus`: return connectionDetailsStatus;
    case `connectionDetailsStatusBroken`: return connectionDetailsStatusBroken;
    case `connectionDetailsStatusChallengeDefault`: return connectionDetailsStatusChallengeDefault;
    case `connectionDetailsStatusConnected`: return connectionDetailsStatusConnected;
    case `connectionDetailsStatusProvidedNotTestable`: return connectionDetailsStatusProvidedNotTestable;
    case `connectionDetailsStatusSyncing`: return connectionDetailsStatusSyncing;
    case `copyableCellCopied`: return copyableCellCopied;
    case `copyableCellCta`: return copyableCellCta;
    case `creditBoostAddFundsCTA`: return creditBoostAddFundsCTA;
    case `creditBoostAddFundsSubtitle`: return creditBoostAddFundsSubtitle;
    case `creditBoostAddFundsTitle`: return creditBoostAddFundsTitle;
    case `creditBoostAuthFailureCTA`: return creditBoostAuthFailureCTA;
    case `creditBoostAuthFailureSubtitle`: return creditBoostAuthFailureSubtitle;
    case `creditBoostAuthFailureTitle`: return creditBoostAuthFailureTitle;
    case `creditBoostFetchingScoresSubtitle`: return creditBoostFetchingScoresSubtitle;
    case `creditBoostFetchingScoresTitle`: return creditBoostFetchingScoresTitle;
    case `creditBoostGraphCurrentScoreLabel`: return creditBoostGraphCurrentScoreLabel;
    case `creditBoostGraphEmptyTileTitle1`: return creditBoostGraphEmptyTileTitle1;
    case `creditBoostGraphEmptyTileTitle2`: return creditBoostGraphEmptyTileTitle2;
    case `creditBoostGraphEmptyTitle`: return creditBoostGraphEmptyTitle;
    case `creditBoostGraphFutureScoreLabel`: return creditBoostGraphFutureScoreLabel;
    case `creditBoostGraphSliderFinePrint`: return creditBoostGraphSliderFinePrint;
    case `creditBoostGraphSliderLabel`: return creditBoostGraphSliderLabel;
    case `creditBoostIntroBulletOne`: return creditBoostIntroBulletOne;
    case `creditBoostIntroBulletThree`: return creditBoostIntroBulletThree;
    case `creditBoostIntroBulletTwo`: return creditBoostIntroBulletTwo;
    case `creditBoostIntroCTA`: return creditBoostIntroCTA;
    case `creditBoostIntroFinishSetup`: return creditBoostIntroFinishSetup;
    case `creditBoostIntroFutureScoreBankTransfer`: return creditBoostIntroFutureScoreBankTransfer;
    case `creditBoostIntroFutureScoreCTA`: return creditBoostIntroFutureScoreCTA;
    case `creditBoostIntroFutureScoreContent`: return creditBoostIntroFutureScoreContent;
    case `creditBoostIntroFutureScoreDisclaimer`: return creditBoostIntroFutureScoreDisclaimer;
    case `creditBoostIntroFutureScoreOr`: return creditBoostIntroFutureScoreOr;
    case `creditBoostIntroFutureScoreSubtitle`: return creditBoostIntroFutureScoreSubtitle;
    case `creditBoostIntroFutureScoreTitle`: return creditBoostIntroFutureScoreTitle;
    case `creditBoostIntroInfo1`: return creditBoostIntroInfo1;
    case `creditBoostIntroInfo2`: return creditBoostIntroInfo2;
    case `creditBoostIntroInfo3`: return creditBoostIntroInfo3;
    case `creditBoostIntroInfo4`: return creditBoostIntroInfo4;
    case `creditBoostIntroMainTitle`: return creditBoostIntroMainTitle;
    case `creditBoostIntroTabHeaderTitle`: return creditBoostIntroTabHeaderTitle;
    case `creditBoostIntroTitle`: return creditBoostIntroTitle;
    case `creditBoostPoweredBy`: return creditBoostPoweredBy;
    case `creditBoostPullScoresTimeoutCta`: return creditBoostPullScoresTimeoutCta;
    case `creditBoostPullScoresTimeoutSubtitle`: return creditBoostPullScoresTimeoutSubtitle;
    case `creditBoostPullScoresTimeoutTitle`: return creditBoostPullScoresTimeoutTitle;
    case `creditBoostReportingProvider`: return creditBoostReportingProvider;
    case `creditBoostScoreErrorButtonTitle`: return creditBoostScoreErrorButtonTitle;
    case `creditBoostScoreErrorSubtitle`: return creditBoostScoreErrorSubtitle;
    case `creditBoostScoreRangeExceptional`: return creditBoostScoreRangeExceptional;
    case `creditBoostScoreRangeFair`: return creditBoostScoreRangeFair;
    case `creditBoostScoreRangeGood`: return creditBoostScoreRangeGood;
    case `creditBoostScoreRangePoor`: return creditBoostScoreRangePoor;
    case `creditBoostScoreRangeVeryGood`: return creditBoostScoreRangeVeryGood;
    case `creditBoostScoreScreenSubtitle`: return creditBoostScoreScreenSubtitle;
    case `creditBoostScoreScreenTitle`: return creditBoostScoreScreenTitle;
    case `creditBoostStatusTileChangeToDateEmptyMessage`: return creditBoostStatusTileChangeToDateEmptyMessage;
    case `creditBoostStatusTileChangeToDateTitle`: return creditBoostStatusTileChangeToDateTitle;
    case `creditBoostStatusTileChartEmpty`: return creditBoostStatusTileChartEmpty;
    case `creditBoostStatusTileCreditSourceEmpty`: return creditBoostStatusTileCreditSourceEmpty;
    case `creditBoostStatusTileHighScore`: return creditBoostStatusTileHighScore;
    case `creditBoostStatusTileLastUpdatedEmptyMessage`: return creditBoostStatusTileLastUpdatedEmptyMessage;
    case `creditBoostStatusTileLastUpdatedTitle`: return creditBoostStatusTileLastUpdatedTitle;
    case `creditBoostStatusTileLowScore`: return creditBoostStatusTileLowScore;
    case `creditBoostStatusTilePoints`: return creditBoostStatusTilePoints;
    case `creditBoostStatusTileRangePlaceholder`: return creditBoostStatusTileRangePlaceholder;
    case `creditBoostStatusTileScorePlaceholder`: return creditBoostStatusTileScorePlaceholder;
    case `creditBoostStatusTileTipsTitle`: return creditBoostStatusTileTipsTitle;
    case `creditBoostStatusTileYourScore`: return creditBoostStatusTileYourScore;
    case `creditBoostTipCardTitle`: return creditBoostTipCardTitle;
    case `creditBoostTipCardUsageContent`: return creditBoostTipCardUsageContent;
    case `creditBoostTipCardUsageTitle`: return creditBoostTipCardUsageTitle;
    case `creditBoostTipCreditAgeContent`: return creditBoostTipCreditAgeContent;
    case `creditBoostTipCreditAgeTitle`: return creditBoostTipCreditAgeTitle;
    case `creditBoostTipDerogatoryMarksContent`: return creditBoostTipDerogatoryMarksContent;
    case `creditBoostTipDerogatoryMarksTitle`: return creditBoostTipDerogatoryMarksTitle;
    case `creditBoostTipHardInquiriesContent`: return creditBoostTipHardInquiriesContent;
    case `creditBoostTipHardInquiriesTitle`: return creditBoostTipHardInquiriesTitle;
    case `creditBoostTipImpactHigh`: return creditBoostTipImpactHigh;
    case `creditBoostTipImpactLow`: return creditBoostTipImpactLow;
    case `creditBoostTipImpactMedium`: return creditBoostTipImpactMedium;
    case `creditBoostTipTotalAccountsContent`: return creditBoostTipTotalAccountsContent;
    case `creditBoostTipTotalAccountsTitle`: return creditBoostTipTotalAccountsTitle;
    case `creditBuilderEnrollmentScreenCTA`: return creditBuilderEnrollmentScreenCTA;
    case `creditBuilderEnrollmentScreenDetails1`: return creditBuilderEnrollmentScreenDetails1;
    case `creditBuilderEnrollmentScreenDetails2`: return creditBuilderEnrollmentScreenDetails2;
    case `creditBuilderEnrollmentScreenDetails3`: return creditBuilderEnrollmentScreenDetails3;
    case `creditBuilderEnrollmentScreenDetailsTitle`: return creditBuilderEnrollmentScreenDetailsTitle;
    case `creditBuilderEnrollmentScreenGuaranteedScore`: return creditBuilderEnrollmentScreenGuaranteedScore;
    case `creditBuilderEnrollmentScreenNavTitle`: return creditBuilderEnrollmentScreenNavTitle;
    case `creditBuilderEnrollmentScreenTerms`: return creditBuilderEnrollmentScreenTerms;
    case `creditBuilderEnrollmentScreenTermsLink`: return creditBuilderEnrollmentScreenTermsLink;
    case `creditBuilderEnrollmentScreenTitle`: return creditBuilderEnrollmentScreenTitle;
    case `creditBuilderEnrollmentUnavailableNoCreditReports`: return creditBuilderEnrollmentUnavailableNoCreditReports;
    case `creditBuilderGoalsScreenDescription`: return creditBuilderGoalsScreenDescription;
    case `creditBuilderGoalsScreenSubtitle`: return creditBuilderGoalsScreenSubtitle;
    case `creditBuilderGoalsScreenTitle`: return creditBuilderGoalsScreenTitle;
    case `creditBuilderHowItWorksScreenCTA`: return creditBuilderHowItWorksScreenCTA;
    case `creditBuilderHowItWorksScreenSubtitle`: return creditBuilderHowItWorksScreenSubtitle;
    case `creditBuilderHowItWorksScreenTitle`: return creditBuilderHowItWorksScreenTitle;
    case `creditBuilderIntroScreenCTA`: return creditBuilderIntroScreenCTA;
    case `creditBuilderIntroScreenNavigationTitle`: return creditBuilderIntroScreenNavigationTitle;
    case `creditBuilderIntroScreenSubtitle`: return creditBuilderIntroScreenSubtitle;
    case `creditBuilderIntroScreenTitle`: return creditBuilderIntroScreenTitle;
    case `creditBuilderOnboardingScreenCTATitle`: return creditBuilderOnboardingScreenCTATitle;
    case `creditBuilderOnboardingScreenSubtitle`: return creditBuilderOnboardingScreenSubtitle;
    case `creditBuilderOnboardingScreenTitle`: return creditBuilderOnboardingScreenTitle;
    case `creditBuilderOnboardingStep1ScreenCTATitle`: return creditBuilderOnboardingStep1ScreenCTATitle;
    case `creditBuilderOnboardingStep1ScreenContent1`: return creditBuilderOnboardingStep1ScreenContent1;
    case `creditBuilderOnboardingStep1ScreenContent2`: return creditBuilderOnboardingStep1ScreenContent2;
    case `creditBuilderOnboardingStep1ScreenContent3`: return creditBuilderOnboardingStep1ScreenContent3;
    case `creditBuilderOnboardingStep1ScreenGraphDescription`: return creditBuilderOnboardingStep1ScreenGraphDescription;
    case `creditBuilderOnboardingStep1ScreenSubtitle`: return creditBuilderOnboardingStep1ScreenSubtitle;
    case `creditBuilderOnboardingStep1ScreenTitle`: return creditBuilderOnboardingStep1ScreenTitle;
    case `creditBuilderOnboardingStep2ScreenCTATitle`: return creditBuilderOnboardingStep2ScreenCTATitle;
    case `creditBuilderOnboardingStep2ScreenContent1`: return creditBuilderOnboardingStep2ScreenContent1;
    case `creditBuilderOnboardingStep2ScreenContent2`: return creditBuilderOnboardingStep2ScreenContent2;
    case `creditBuilderOnboardingStep2ScreenContent3`: return creditBuilderOnboardingStep2ScreenContent3;
    case `creditBuilderOnboardingStep2ScreenHelpCTATitle`: return creditBuilderOnboardingStep2ScreenHelpCTATitle;
    case `creditBuilderOnboardingStep2ScreenHelpContent1Point1`: return creditBuilderOnboardingStep2ScreenHelpContent1Point1;
    case `creditBuilderOnboardingStep2ScreenHelpContent2Point1`: return creditBuilderOnboardingStep2ScreenHelpContent2Point1;
    case `creditBuilderOnboardingStep2ScreenHelpContent2Point1Bold`: return creditBuilderOnboardingStep2ScreenHelpContent2Point1Bold;
    case `creditBuilderOnboardingStep2ScreenHelpContent3Point1`: return creditBuilderOnboardingStep2ScreenHelpContent3Point1;
    case `creditBuilderOnboardingStep2ScreenHelpTitle1`: return creditBuilderOnboardingStep2ScreenHelpTitle1;
    case `creditBuilderOnboardingStep2ScreenHelpTitle2`: return creditBuilderOnboardingStep2ScreenHelpTitle2;
    case `creditBuilderOnboardingStep2ScreenHelpTitle3`: return creditBuilderOnboardingStep2ScreenHelpTitle3;
    case `creditBuilderOnboardingStep2ScreenSubtitle`: return creditBuilderOnboardingStep2ScreenSubtitle;
    case `creditBuilderOnboardingStep2ScreenTitle`: return creditBuilderOnboardingStep2ScreenTitle;
    case `creditBuilderProgressTileCTA`: return creditBuilderProgressTileCTA;
    case `creditBuilderProgressTileComplete`: return creditBuilderProgressTileComplete;
    case `creditBuilderProgressTileCongrats`: return creditBuilderProgressTileCongrats;
    case `creditBuilderProgressTileGuaranteed`: return creditBuilderProgressTileGuaranteed;
    case `creditBuilderProgressTileHowItWorks`: return creditBuilderProgressTileHowItWorks;
    case `creditBuilderProgressTileIncomplete`: return creditBuilderProgressTileIncomplete;
    case `creditBuilderProgressTileIncompleteCTA`: return creditBuilderProgressTileIncompleteCTA;
    case `creditBuilderProgressTileProgress`: return creditBuilderProgressTileProgress;
    case `creditBuilderProgressTileStarting`: return creditBuilderProgressTileStarting;
    case `creditBuilderProgressTileSubtitle`: return creditBuilderProgressTileSubtitle;
    case `creditBuilderProgressTileTitle`: return creditBuilderProgressTileTitle;
    case `creditBuilderSignUpTileCTA`: return creditBuilderSignUpTileCTA;
    case `creditBuilderSignUpTileSubtitle`: return creditBuilderSignUpTileSubtitle;
    case `creditBuilderSignUpTileTitle`: return creditBuilderSignUpTileTitle;
    case `creditBuilderTermsApply`: return creditBuilderTermsApply;
    case `creditCategoryCreditAgeExplanation`: return creditCategoryCreditAgeExplanation;
    case `creditCategoryCreditAgeFairRange`: return creditCategoryCreditAgeFairRange;
    case `creditCategoryCreditAgeGoodRange`: return creditCategoryCreditAgeGoodRange;
    case `creditCategoryCreditAgeNeedsWorkRange`: return creditCategoryCreditAgeNeedsWorkRange;
    case `creditCategoryDerogatoryMarksExplanation`: return creditCategoryDerogatoryMarksExplanation;
    case `creditCategoryDerogatoryMarksFairRange`: return creditCategoryDerogatoryMarksFairRange;
    case `creditCategoryDerogatoryMarksGoodRange`: return creditCategoryDerogatoryMarksGoodRange;
    case `creditCategoryDerogatoryMarksNeedsWorkRange`: return creditCategoryDerogatoryMarksNeedsWorkRange;
    case `creditCategoryHardInquiriesExplanation`: return creditCategoryHardInquiriesExplanation;
    case `creditCategoryHardInquiriesFairRange`: return creditCategoryHardInquiriesFairRange;
    case `creditCategoryHardInquiriesGoodRange`: return creditCategoryHardInquiriesGoodRange;
    case `creditCategoryHardInquiriesNeedsWorkRange`: return creditCategoryHardInquiriesNeedsWorkRange;
    case `creditCategoryImpactHighImpact`: return creditCategoryImpactHighImpact;
    case `creditCategoryImpactLowImpact`: return creditCategoryImpactLowImpact;
    case `creditCategoryImpactMediumImpact`: return creditCategoryImpactMediumImpact;
    case `creditCategoryNameCreditAge`: return creditCategoryNameCreditAge;
    case `creditCategoryNameDerogatoryMarks`: return creditCategoryNameDerogatoryMarks;
    case `creditCategoryNameHardInquiries`: return creditCategoryNameHardInquiries;
    case `creditCategoryNamePayments`: return creditCategoryNamePayments;
    case `creditCategoryNameTotalAccounts`: return creditCategoryNameTotalAccounts;
    case `creditCategoryNameUtilization`: return creditCategoryNameUtilization;
    case `creditCategoryNotApplicable`: return creditCategoryNotApplicable;
    case `creditCategoryPaymentsExplanation`: return creditCategoryPaymentsExplanation;
    case `creditCategoryPaymentsFairRange`: return creditCategoryPaymentsFairRange;
    case `creditCategoryPaymentsGoodRange`: return creditCategoryPaymentsGoodRange;
    case `creditCategoryPaymentsNeedsWorkRange`: return creditCategoryPaymentsNeedsWorkRange;
    case `creditCategoryTotalAccountsExplanation`: return creditCategoryTotalAccountsExplanation;
    case `creditCategoryTotalAccountsFairRange`: return creditCategoryTotalAccountsFairRange;
    case `creditCategoryTotalAccountsGoodRange`: return creditCategoryTotalAccountsGoodRange;
    case `creditCategoryTotalAccountsNeedsWorkRange`: return creditCategoryTotalAccountsNeedsWorkRange;
    case `creditCategoryUtilizationExplanation`: return creditCategoryUtilizationExplanation;
    case `creditCategoryUtilizationFairRange`: return creditCategoryUtilizationFairRange;
    case `creditCategoryUtilizationGoodRange`: return creditCategoryUtilizationGoodRange;
    case `creditCategoryUtilizationNeedsWorkRange`: return creditCategoryUtilizationNeedsWorkRange;
    case `creditLineActiveTileAvailableCreditLabel`: return creditLineActiveTileAvailableCreditLabel;
    case `creditLineActiveTileCurrentBalanceLabel`: return creditLineActiveTileCurrentBalanceLabel;
    case `creditLineActiveTileMakePaymentCTA`: return creditLineActiveTileMakePaymentCTA;
    case `creditLineActiveTileMinimumPaymentLabel`: return creditLineActiveTileMinimumPaymentLabel;
    case `creditLineActiveTileMinimumPaymentNoPaymentsLabel`: return creditLineActiveTileMinimumPaymentNoPaymentsLabel;
    case `creditLineActiveTileOutstandBalanceLabel`: return creditLineActiveTileOutstandBalanceLabel;
    case `creditLineActiveTilePaymentDueBannerSubtitleA`: return creditLineActiveTilePaymentDueBannerSubtitleA;
    case `creditLineActiveTilePaymentDueBannerSubtitleB`: return creditLineActiveTilePaymentDueBannerSubtitleB;
    case `creditLineActiveTilePaymentDueBannerTitle`: return creditLineActiveTilePaymentDueBannerTitle;
    case `creditLineActiveTilePaymentDueLabel`: return creditLineActiveTilePaymentDueLabel;
    case `creditLineActiveTilePaymentFeesBannerSubitle`: return creditLineActiveTilePaymentFeesBannerSubitle;
    case `creditLineActiveTilePaymentFeesBannerTitle`: return creditLineActiveTilePaymentFeesBannerTitle;
    case `creditLineActiveTilePaymentStatusPaid`: return creditLineActiveTilePaymentStatusPaid;
    case `creditLineActiveTilePaymentStatusPending`: return creditLineActiveTilePaymentStatusPending;
    case `creditLineActiveTilePaymentStatusProcessing`: return creditLineActiveTilePaymentStatusProcessing;
    case `creditLineActiveTilePaymentStatusStatementBalance`: return creditLineActiveTilePaymentStatusStatementBalance;
    case `creditLineActiveTilePaymentStatusStatementBalanceNoPayments`: return creditLineActiveTilePaymentStatusStatementBalanceNoPayments;
    case `creditLineActiveTilePercentageUsedLabel`: return creditLineActiveTilePercentageUsedLabel;
    case `creditLineActiveTileSpendingLineLabel`: return creditLineActiveTileSpendingLineLabel;
    case `creditLineActiveTileViewBuildCreditLineCTA`: return creditLineActiveTileViewBuildCreditLineCTA;
    case `creditLineActiveTileViewReceiptsCTA`: return creditLineActiveTileViewReceiptsCTA;
    case `creditLineAutoPayEnforced`: return creditLineAutoPayEnforced;
    case `creditLineAutopayCTAEnroll`: return creditLineAutopayCTAEnroll;
    case `creditLineAutopayCTATitle`: return creditLineAutopayCTATitle;
    case `creditLineAutopayPaymentAmountRowTitle`: return creditLineAutopayPaymentAmountRowTitle;
    case `creditLineAutopayPaymentDetailsSectionTitle`: return creditLineAutopayPaymentDetailsSectionTitle;
    case `creditLineAutopayPaymentOptionCTALabel`: return creditLineAutopayPaymentOptionCTALabel;
    case `creditLineAutopayPaymentOptionMinimum`: return creditLineAutopayPaymentOptionMinimum;
    case `creditLineAutopayPaymentOptionOtherAmount`: return creditLineAutopayPaymentOptionOtherAmount;
    case `creditLineAutopayPaymentOptionStatement`: return creditLineAutopayPaymentOptionStatement;
    case `creditLineAutopayRowSubtitle`: return creditLineAutopayRowSubtitle;
    case `creditLineAutopayRowTitle`: return creditLineAutopayRowTitle;
    case `creditLineAutopayScreenTitle`: return creditLineAutopayScreenTitle;
    case `creditLineAutopayTerms`: return creditLineAutopayTerms;
    case `creditLineAutopayTermsNoAccounts`: return creditLineAutopayTermsNoAccounts;
    case `creditLineAutopayTileCTALabel`: return creditLineAutopayTileCTALabel;
    case `creditLineAutopayTileLabel`: return creditLineAutopayTileLabel;
    case `creditLineAutopayTileOff`: return creditLineAutopayTileOff;
    case `creditLineAutopayTileOn`: return creditLineAutopayTileOn;
    case `creditLineAutopayTransferFromRowTitle`: return creditLineAutopayTransferFromRowTitle;
    case `creditLineBlockedConversionLedgerDiscrepancy`: return creditLineBlockedConversionLedgerDiscrepancy;
    case `creditLineBlockedConversionOpenDispute`: return creditLineBlockedConversionOpenDispute;
    case `creditLineCheckoutAutoPayDescription`: return creditLineCheckoutAutoPayDescription;
    case `creditLineCheckoutAutoPayTitle`: return creditLineCheckoutAutoPayTitle;
    case `creditLineCheckoutCTA`: return creditLineCheckoutCTA;
    case `creditLineCheckoutLegal`: return creditLineCheckoutLegal;
    case `creditLineCheckoutLegalWithAccounts`: return creditLineCheckoutLegalWithAccounts;
    case `creditLineCheckoutLegalWithNoAccounts`: return creditLineCheckoutLegalWithNoAccounts;
    case `creditLineCheckoutNavTitle`: return creditLineCheckoutNavTitle;
    case `creditLineCheckoutTermsLinkAlgoQuery`: return creditLineCheckoutTermsLinkAlgoQuery;
    case `creditLineCheckoutValuePropOne`: return creditLineCheckoutValuePropOne;
    case `creditLineCheckoutValuePropThree`: return creditLineCheckoutValuePropThree;
    case `creditLineCheckoutValuePropTwo`: return creditLineCheckoutValuePropTwo;
    case `creditLineConversionAppVersion`: return creditLineConversionAppVersion;
    case `creditLineDrawDownDuplicateWarningLabel`: return creditLineDrawDownDuplicateWarningLabel;
    case `creditLineDrawDownSucccessCTA`: return creditLineDrawDownSucccessCTA;
    case `creditLineDrawDownSucccessContent`: return creditLineDrawDownSucccessContent;
    case `creditLineDrawDownSucccessTitle`: return creditLineDrawDownSucccessTitle;
    case `creditLineDrawDownWarningLabel`: return creditLineDrawDownWarningLabel;
    case `creditLineEnrollmentMissingPermissions`: return creditLineEnrollmentMissingPermissions;
    case `creditLineInitialScoreCTA`: return creditLineInitialScoreCTA;
    case `creditLineInitialScoreExplainItemOne`: return creditLineInitialScoreExplainItemOne;
    case `creditLineInitialScoreExplainItemThree`: return creditLineInitialScoreExplainItemThree;
    case `creditLineInitialScoreExplainItemTwo`: return creditLineInitialScoreExplainItemTwo;
    case `creditLineInitialScoreExplainTitle`: return creditLineInitialScoreExplainTitle;
    case `creditLineInitialScoreNoFileMessage`: return creditLineInitialScoreNoFileMessage;
    case `creditLineInitialScorePresentMessage`: return creditLineInitialScorePresentMessage;
    case `creditLineInitialScoreThinFileMessage`: return creditLineInitialScoreThinFileMessage;
    case `creditLineInitialScoreTitle`: return creditLineInitialScoreTitle;
    case `creditLineInvitationBody`: return creditLineInvitationBody;
    case `creditLineInvitationCTA`: return creditLineInvitationCTA;
    case `creditLineInvitationCongratsTitle`: return creditLineInvitationCongratsTitle;
    case `creditLineInvitationTileBody`: return creditLineInvitationTileBody;
    case `creditLineInvitationTileCTA`: return creditLineInvitationTileCTA;
    case `creditLineInvitationTileTitle`: return creditLineInvitationTileTitle;
    case `creditLineInvitationTitle`: return creditLineInvitationTitle;
    case `creditLineInvitedGoalTitle`: return creditLineInvitedGoalTitle;
    case `creditLineInvitedGoalsubtitle`: return creditLineInvitedGoalsubtitle;
    case `creditLineLatePaymentScreenMinimumPayment`: return creditLineLatePaymentScreenMinimumPayment;
    case `creditLineLatePaymentScreenPaymentAmount`: return creditLineLatePaymentScreenPaymentAmount;
    case `creditLineLatePaymentScreenStatementBalance`: return creditLineLatePaymentScreenStatementBalance;
    case `creditLineLatePaymentScreenStatementBalanceDescription`: return creditLineLatePaymentScreenStatementBalanceDescription;
    case `creditLineLatePaymentScreenStatementBalanceRecommended`: return creditLineLatePaymentScreenStatementBalanceRecommended;
    case `creditLineLatePaymentScreenSubtitle`: return creditLineLatePaymentScreenSubtitle;
    case `creditLineLatePaymentScreenTitle`: return creditLineLatePaymentScreenTitle;
    case `creditLineLatePaymentSubtitle`: return creditLineLatePaymentSubtitle;
    case `creditLineLatePaymentTitle`: return creditLineLatePaymentTitle;
    case `creditLineLevelsAmountRemaining`: return creditLineLevelsAmountRemaining;
    case `creditLineLevelsAmountToSpend`: return creditLineLevelsAmountToSpend;
    case `creditLineLevelsAmountToSpendGoal`: return creditLineLevelsAmountToSpendGoal;
    case `creditLineLevelsCompleteGoals`: return creditLineLevelsCompleteGoals;
    case `creditLineLevelsGetMoreMoney`: return creditLineLevelsGetMoreMoney;
    case `creditLineLevelsGoal`: return creditLineLevelsGoal;
    case `creditLineLevelsHideDetails`: return creditLineLevelsHideDetails;
    case `creditLineLevelsLatePaymentImpact`: return creditLineLevelsLatePaymentImpact;
    case `creditLineLevelsMakeAPayment`: return creditLineLevelsMakeAPayment;
    case `creditLineLevelsMakeAtLeastOneDirectDeposit`: return creditLineLevelsMakeAtLeastOneDirectDeposit;
    case `creditLineLevelsMakeDirectDeposit`: return creditLineLevelsMakeDirectDeposit;
    case `creditLineLevelsNumberOfDaysLeft`: return creditLineLevelsNumberOfDaysLeft;
    case `creditLineLevelsOverduePayment`: return creditLineLevelsOverduePayment;
    case `creditLineLevelsRememberToPayText`: return creditLineLevelsRememberToPayText;
    case `creditLineLevelsScreenLevelSubtitle`: return creditLineLevelsScreenLevelSubtitle;
    case `creditLineLevelsScreenLevelTitle`: return creditLineLevelsScreenLevelTitle;
    case `creditLineLevelsScreenLevels`: return creditLineLevelsScreenLevels;
    case `creditLineLevelsScreenSubtitle`: return creditLineLevelsScreenSubtitle;
    case `creditLineLevelsScreenTitle`: return creditLineLevelsScreenTitle;
    case `creditLineLevelsShowDetails`: return creditLineLevelsShowDetails;
    case `creditLineLevelsSpendAmount`: return creditLineLevelsSpendAmount;
    case `creditLineLevelsTotalSpend`: return creditLineLevelsTotalSpend;
    case `creditLineMakePaymentMinimumPaymentDescription`: return creditLineMakePaymentMinimumPaymentDescription;
    case `creditLineMakePaymentMinimumPaymentTitle`: return creditLineMakePaymentMinimumPaymentTitle;
    case `creditLineMakePaymentNavTitle`: return creditLineMakePaymentNavTitle;
    case `creditLineMakePaymentOptionEnterAmountTitle`: return creditLineMakePaymentOptionEnterAmountTitle;
    case `creditLineMakePaymentOptionEnteredLessThanMin`: return creditLineMakePaymentOptionEnteredLessThanMin;
    case `creditLineMakePaymentOptionMinimumPaymentDescription`: return creditLineMakePaymentOptionMinimumPaymentDescription;
    case `creditLineMakePaymentOptionMinimumPaymentTitle`: return creditLineMakePaymentOptionMinimumPaymentTitle;
    case `creditLineMakePaymentOptionStatementBalanceDescription`: return creditLineMakePaymentOptionStatementBalanceDescription;
    case `creditLineMakePaymentOptionStatementBalanceTitle`: return creditLineMakePaymentOptionStatementBalanceTitle;
    case `creditLineMakePaymentTitle`: return creditLineMakePaymentTitle;
    case `creditLineMakepaymentWarningSubtitle`: return creditLineMakepaymentWarningSubtitle;
    case `creditLineMakepaymentWarningTitle`: return creditLineMakepaymentWarningTitle;
    case `creditLineMinimumPaymentMustBePaid`: return creditLineMinimumPaymentMustBePaid;
    case `creditLineOutstandingSubscriptionFee`: return creditLineOutstandingSubscriptionFee;
    case `creditLinePastDueBannerAvoidNegativeSubtitle`: return creditLinePastDueBannerAvoidNegativeSubtitle;
    case `creditLinePastDueBannerAvoidNegativeTitle`: return creditLinePastDueBannerAvoidNegativeTitle;
    case `creditLinePastDueBannerReduceInterestSubtitle`: return creditLinePastDueBannerReduceInterestSubtitle;
    case `creditLinePastDueBannerReduceInterestTitle`: return creditLinePastDueBannerReduceInterestTitle;
    case `creditLinePastDueDefaultedSubtitle`: return creditLinePastDueDefaultedSubtitle;
    case `creditLinePastDueDefaultedTitle`: return creditLinePastDueDefaultedTitle;
    case `creditLinePastDueIsDelinquentSubtitle`: return creditLinePastDueIsDelinquentSubtitle;
    case `creditLinePastDueIsDelinquentTitle`: return creditLinePastDueIsDelinquentTitle;
    case `creditLinePastDueSubtitle`: return creditLinePastDueSubtitle;
    case `creditLinePastDueTitle`: return creditLinePastDueTitle;
    case `creditLinePayNowAcceptTermsLinkAlgoQueryTermsAttribution`: return creditLinePayNowAcceptTermsLinkAlgoQueryTermsAttribution;
    case `creditLinePayNowTerms`: return creditLinePayNowTerms;
    case `creditLinePayNowTermsTermsLinkAlgoQuery`: return creditLinePayNowTermsTermsLinkAlgoQuery;
    case `creditLinePaymentInsufficientBoostCardBalance`: return creditLinePaymentInsufficientBoostCardBalance;
    case `creditLinePaymentSucccessCTA`: return creditLinePaymentSucccessCTA;
    case `creditLinePaymentSucccessContent`: return creditLinePaymentSucccessContent;
    case `creditLinePaymentSucccessContentBankingTransfer`: return creditLinePaymentSucccessContentBankingTransfer;
    case `creditLinePaymentSucccessTitle`: return creditLinePaymentSucccessTitle;
    case `creditLinePaymentSucccessTitleBankingTransfer`: return creditLinePaymentSucccessTitleBankingTransfer;
    case `creditLineProgressTileBody`: return creditLineProgressTileBody;
    case `creditLineProgressTileCTA`: return creditLineProgressTileCTA;
    case `creditLineProgressTileTitle`: return creditLineProgressTileTitle;
    case `creditLineReceiptsDetailsScreenDate`: return creditLineReceiptsDetailsScreenDate;
    case `creditLineReceiptsDetailsScreenHelpButton`: return creditLineReceiptsDetailsScreenHelpButton;
    case `creditLineReceiptsDetailsScreenStatus`: return creditLineReceiptsDetailsScreenStatus;
    case `creditLineReceiptsDetailsScreenType`: return creditLineReceiptsDetailsScreenType;
    case `creditLineReceiptsDetailsScreenVendor`: return creditLineReceiptsDetailsScreenVendor;
    case `creditLineReceiptsScreenEndOfReceipts`: return creditLineReceiptsScreenEndOfReceipts;
    case `creditLineReceiptsScreenNoReceipts`: return creditLineReceiptsScreenNoReceipts;
    case `creditLineReceiptsScreenTitle`: return creditLineReceiptsScreenTitle;
    case `creditLineReceiptsScreenTypeRepayment`: return creditLineReceiptsScreenTypeRepayment;
    case `creditLineReceiptsScreenTypeTransferToBoostcard`: return creditLineReceiptsScreenTypeTransferToBoostcard;
    case `creditLineRepaymentAwaitingClearing`: return creditLineRepaymentAwaitingClearing;
    case `creditLineRepaymentExceedsOutstandingAmount`: return creditLineRepaymentExceedsOutstandingAmount;
    case `creditLineReviewPaymentAmount`: return creditLineReviewPaymentAmount;
    case `creditLineReviewPaymentConfirmCTA`: return creditLineReviewPaymentConfirmCTA;
    case `creditLineReviewPaymentInterestFee`: return creditLineReviewPaymentInterestFee;
    case `creditLineReviewPaymentNavTitle`: return creditLineReviewPaymentNavTitle;
    case `creditLineReviewPaymentTotalAmount`: return creditLineReviewPaymentTotalAmount;
    case `creditLineReviewPaymentTransferFrom`: return creditLineReviewPaymentTransferFrom;
    case `creditLineTileBannerAllPaidDescription`: return creditLineTileBannerAllPaidDescription;
    case `creditLineTileBannerAllPaidTitle`: return creditLineTileBannerAllPaidTitle;
    case `creditLineTileBannerDrawdownBlockedDescription`: return creditLineTileBannerDrawdownBlockedDescription;
    case `creditLineTileBannerDrawdownBlockedTitle`: return creditLineTileBannerDrawdownBlockedTitle;
    case `creditLineTileBannerFirstMonthDescription`: return creditLineTileBannerFirstMonthDescription;
    case `creditLineTileBannerFirstMonthTitle`: return creditLineTileBannerFirstMonthTitle;
    case `creditLineTileHelpScreenCTA1Title`: return creditLineTileHelpScreenCTA1Title;
    case `creditLineTileHelpScreenCTA2Title`: return creditLineTileHelpScreenCTA2Title;
    case `creditLineTileHelpScreenSection1Item1Description`: return creditLineTileHelpScreenSection1Item1Description;
    case `creditLineTileHelpScreenSection1Item1Title`: return creditLineTileHelpScreenSection1Item1Title;
    case `creditLineTileHelpScreenSection1Item2Description`: return creditLineTileHelpScreenSection1Item2Description;
    case `creditLineTileHelpScreenSection1Item2Title`: return creditLineTileHelpScreenSection1Item2Title;
    case `creditLineTileHelpScreenSection1Item3Description`: return creditLineTileHelpScreenSection1Item3Description;
    case `creditLineTileHelpScreenSection1Item3Title`: return creditLineTileHelpScreenSection1Item3Title;
    case `creditLineTileHelpScreenSection1Title`: return creditLineTileHelpScreenSection1Title;
    case `creditLineTileHelpScreenSection2Item1Description`: return creditLineTileHelpScreenSection2Item1Description;
    case `creditLineTileHelpScreenSection2Item1Title`: return creditLineTileHelpScreenSection2Item1Title;
    case `creditLineTileHelpScreenSection2Item2Description`: return creditLineTileHelpScreenSection2Item2Description;
    case `creditLineTileHelpScreenSection2Item2Title`: return creditLineTileHelpScreenSection2Item2Title;
    case `creditLineTileHelpScreenSection2Item3Description`: return creditLineTileHelpScreenSection2Item3Description;
    case `creditLineTileHelpScreenSection2Item3Title`: return creditLineTileHelpScreenSection2Item3Title;
    case `creditLineTileHelpScreenSection2Item4Description`: return creditLineTileHelpScreenSection2Item4Description;
    case `creditLineTileHelpScreenSection2Item4Title`: return creditLineTileHelpScreenSection2Item4Title;
    case `creditLineTileHelpScreenSection2Title`: return creditLineTileHelpScreenSection2Title;
    case `creditLineTileHelpScreenSection3Item1Description`: return creditLineTileHelpScreenSection3Item1Description;
    case `creditLineTileHelpScreenSection3Item2Description`: return creditLineTileHelpScreenSection3Item2Description;
    case `creditLineTileHelpScreenSection3Item3Description`: return creditLineTileHelpScreenSection3Item3Description;
    case `creditLineTileHelpScreenSection3Title`: return creditLineTileHelpScreenSection3Title;
    case `creditLineTileHelpScreenSubtitle`: return creditLineTileHelpScreenSubtitle;
    case `creditLineTileHelpScreenTitle`: return creditLineTileHelpScreenTitle;
    case `creditLineTransactionTypeGenericCredit`: return creditLineTransactionTypeGenericCredit;
    case `creditLineTransactionTypeGenericDebit`: return creditLineTransactionTypeGenericDebit;
    case `creditLineTransactionTypeInterestPayment`: return creditLineTransactionTypeInterestPayment;
    case `creditLineTransactionTypeInvalid`: return creditLineTransactionTypeInvalid;
    case `creditLineTransactionTypePayment`: return creditLineTransactionTypePayment;
    case `creditLineTransactionTypeTransferToVendor`: return creditLineTransactionTypeTransferToVendor;
    case `creditLineTransactionTypeUnclassified`: return creditLineTransactionTypeUnclassified;
    case `creditLineTransactionTypeWithdrawalToBoostCard`: return creditLineTransactionTypeWithdrawalToBoostCard;
    case `creditLineUnlockDecline`: return creditLineUnlockDecline;
    case `creditLineUnlockSubTitle`: return creditLineUnlockSubTitle;
    case `creditLineUnlockTitle`: return creditLineUnlockTitle;
    case `creditLineUpdatedInitialScoreTitle`: return creditLineUpdatedInitialScoreTitle;
    case `creditReportDetailsHeader`: return creditReportDetailsHeader;
    case `creditReportDetailsHideDetails`: return creditReportDetailsHideDetails;
    case `creditReportDetailsShowMore`: return creditReportDetailsShowMore;
    case `creditReportDetailsTitle`: return creditReportDetailsTitle;
    case `creditReportViewDetailsCta`: return creditReportViewDetailsCta;
    case `dashboardCellCheckinContinueButton`: return dashboardCellCheckinContinueButton;
    case `dashboardCellCheckinInBlockedLabel`: return dashboardCellCheckinInBlockedLabel;
    case `dashboardCellCheckinInProgressLabel`: return dashboardCellCheckinInProgressLabel;
    case `dashboardCellCheckinReadyButton`: return dashboardCellCheckinReadyButton;
    case `dashboardCellCheckinReadyLabel`: return dashboardCellCheckinReadyLabel;
    case `dashboardCellCheckinSkippedLabel`: return dashboardCellCheckinSkippedLabel;
    case `dashboardCellCheckinStatusCompleted`: return dashboardCellCheckinStatusCompleted;
    case `dashboardCellCheckinStatusReady`: return dashboardCellCheckinStatusReady;
    case `dashboardCellCheckinStatusScheduledDays`: return dashboardCellCheckinStatusScheduledDays;
    case `dashboardCellCheckinStatusScheduledSoon`: return dashboardCellCheckinStatusScheduledSoon;
    case `dashboardCellCheckinStatusScheduledTomorrow`: return dashboardCellCheckinStatusScheduledTomorrow;
    case `dashboardHeaderBoostActivityBody`: return dashboardHeaderBoostActivityBody;
    case `dashboardHeaderBoostActivityBodyBonusNext`: return dashboardHeaderBoostActivityBodyBonusNext;
    case `dashboardHeaderBoostActivityBodyEmpty`: return dashboardHeaderBoostActivityBodyEmpty;
    case `dashboardHeaderBoostActivityBodyStreak`: return dashboardHeaderBoostActivityBodyStreak;
    case `dashboardHeaderBoostActivityTitle`: return dashboardHeaderBoostActivityTitle;
    case `dashboardHeaderBoostProgressDenominator`: return dashboardHeaderBoostProgressDenominator;
    case `dashboardHeaderBoostProgressDenominatorSymbol`: return dashboardHeaderBoostProgressDenominatorSymbol;
    case `dashboardHeaderBoostProgressDescription`: return dashboardHeaderBoostProgressDescription;
    case `dashboardHeaderBoostProgressDescriptionNone`: return dashboardHeaderBoostProgressDescriptionNone;
    case `dashboardHeaderBoostProgressTotalPrefix`: return dashboardHeaderBoostProgressTotalPrefix;
    case `dashboardHeaderBoostReadyLabelButton`: return dashboardHeaderBoostReadyLabelButton;
    case `dashboardHeaderBoostReadyLabelInfo`: return dashboardHeaderBoostReadyLabelInfo;
    case `dashboardHeaderBoostReadyLabelTitle`: return dashboardHeaderBoostReadyLabelTitle;
    case `dashboardHeaderBoostWaitingLabelInfo`: return dashboardHeaderBoostWaitingLabelInfo;
    case `dashboardHeaderBoostWaitingLabelInfoSoon`: return dashboardHeaderBoostWaitingLabelInfoSoon;
    case `dashboardHeaderBoostWaitingLabelTimeCount`: return dashboardHeaderBoostWaitingLabelTimeCount;
    case `dashboardHeaderBoostWaitingLabelTimeUnit`: return dashboardHeaderBoostWaitingLabelTimeUnit;
    case `dashboardHeaderCheckinTitle`: return dashboardHeaderCheckinTitle;
    case `dashboardNoTileLoadingView`: return dashboardNoTileLoadingView;
    case `dashboardTabControllerBankingTabBarItemTitle`: return dashboardTabControllerBankingTabBarItemTitle;
    case `dashboardTabControllerBoostCardBankTabBarItemTitle`: return dashboardTabControllerBoostCardBankTabBarItemTitle;
    case `dashboardTabControllerBoostCardCardTabBarItemTitle`: return dashboardTabControllerBoostCardCardTabBarItemTitle;
    case `dashboardTabControllerCardTabBarItemTitle`: return dashboardTabControllerCardTabBarItemTitle;
    case `dashboardTabControllerCashboostTabBarItemTitle`: return dashboardTabControllerCashboostTabBarItemTitle;
    case `dashboardTabControllerCreditTabBarItemTitle`: return dashboardTabControllerCreditTabBarItemTitle;
    case `dashboardTabControllerPayboostTabBarItemTitle`: return dashboardTabControllerPayboostTabBarItemTitle;
    case `dashboardTileUnknownErrorCTA`: return dashboardTileUnknownErrorCTA;
    case `dashboardTileUnknownErrorContent`: return dashboardTileUnknownErrorContent;
    case `dashboardTileUnknownErrorTitle`: return dashboardTileUnknownErrorTitle;
    case `deprecateClientRpcRepayCashboostAdvance`: return deprecateClientRpcRepayCashboostAdvance;
    case `directDepositPromoVarOneBody`: return directDepositPromoVarOneBody;
    case `directDepositPromoVarOneCta`: return directDepositPromoVarOneCta;
    case `directDepositPromoVarOneTitle`: return directDepositPromoVarOneTitle;
    case `directDepositPromoVarThreeBody`: return directDepositPromoVarThreeBody;
    case `directDepositPromoVarThreeCta`: return directDepositPromoVarThreeCta;
    case `directDepositPromoVarThreeTitle`: return directDepositPromoVarThreeTitle;
    case `directDepositPromoVarTwoBody`: return directDepositPromoVarTwoBody;
    case `directDepositPromoVarTwoCta`: return directDepositPromoVarTwoCta;
    case `directDepositPromoVarTwoTitle`: return directDepositPromoVarTwoTitle;
    case `directDepositSetupAccount`: return directDepositSetupAccount;
    case `directDepositSetupClose`: return directDepositSetupClose;
    case `directDepositSetupHeader`: return directDepositSetupHeader;
    case `directDepositSetupIntroCta`: return directDepositSetupIntroCta;
    case `directDepositSetupIntroEarn`: return directDepositSetupIntroEarn;
    case `directDepositSetupIntroEarnItemOneAmt`: return directDepositSetupIntroEarnItemOneAmt;
    case `directDepositSetupIntroEarnItemOneLabel`: return directDepositSetupIntroEarnItemOneLabel;
    case `directDepositSetupIntroEarnItemTwoAmt`: return directDepositSetupIntroEarnItemTwoAmt;
    case `directDepositSetupIntroEarnItemTwoLabel`: return directDepositSetupIntroEarnItemTwoLabel;
    case `directDepositSetupIntroExampleSpendingAmt`: return directDepositSetupIntroExampleSpendingAmt;
    case `directDepositSetupIntroExampleSpendingDuration`: return directDepositSetupIntroExampleSpendingDuration;
    case `directDepositSetupIntroFinePrint`: return directDepositSetupIntroFinePrint;
    case `directDepositSetupIntroTitle`: return directDepositSetupIntroTitle;
    case `directDepositSetupRouting`: return directDepositSetupRouting;
    case `directDepositSetupSubtitle`: return directDepositSetupSubtitle;
    case `directDepositSetupTitle`: return directDepositSetupTitle;
    case `documentsCellFolderDefaultFileName`: return documentsCellFolderDefaultFileName;
    case `documentsFolderAlertCancelActionCancel`: return documentsFolderAlertCancelActionCancel;
    case `documentsFolderAlertCancelActionDismiss`: return documentsFolderAlertCancelActionDismiss;
    case `documentsFolderAlertCancelTitle`: return documentsFolderAlertCancelTitle;
    case `documentsFolderAlertDeleteCancel`: return documentsFolderAlertDeleteCancel;
    case `documentsFolderAlertDeleteTitle`: return documentsFolderAlertDeleteTitle;
    case `documentsFolderEmptyStateButton`: return documentsFolderEmptyStateButton;
    case `documentsFolderEmptyStateReadonlySubtitle`: return documentsFolderEmptyStateReadonlySubtitle;
    case `documentsFolderEmptyStateReadonlyTitle`: return documentsFolderEmptyStateReadonlyTitle;
    case `documentsFolderEmptyStateSubtitle`: return documentsFolderEmptyStateSubtitle;
    case `documentsFolderEmptyStateTitle`: return documentsFolderEmptyStateTitle;
    case `documentsFolderNameDefault`: return documentsFolderNameDefault;
    case `documentsFolderNavCancel`: return documentsFolderNavCancel;
    case `documentsFolderNavDone`: return documentsFolderNavDone;
    case `documentsFolderPhotoName`: return documentsFolderPhotoName;
    case `documentsFolderTitleDefault`: return documentsFolderTitleDefault;
    case `documentsSearchFieldPlaceholder`: return documentsSearchFieldPlaceholder;
    case `documentsSourcePickerButtonFiles`: return documentsSourcePickerButtonFiles;
    case `documentsSourcePickerButtonPhotos`: return documentsSourcePickerButtonPhotos;
    case `documentsSourcePickerButtonScan`: return documentsSourcePickerButtonScan;
    case `documentsSourcePickerHeader`: return documentsSourcePickerHeader;
    case `documentsTabRecent`: return documentsTabRecent;
    case `documentsTabTaxReturns`: return documentsTabTaxReturns;
    case `documentsTabUploaded`: return documentsTabUploaded;
    case `documentsTabW4S`: return documentsTabW4S;
    case `documentsTitle`: return documentsTitle;
    case `emailUpdatePrefill`: return emailUpdatePrefill;
    case `emailUpdatePrompt`: return emailUpdatePrompt;
    case `emptyFallback`: return emptyFallback;
    case `errSynapseIsBroken`: return errSynapseIsBroken;
    case `error2FABeforeEmailSet`: return error2FABeforeEmailSet;
    case `error2FAInvalidPhoneNumber`: return error2FAInvalidPhoneNumber;
    case `error2FAOtpAlreadySetup`: return error2FAOtpAlreadySetup;
    case `error2FAOtpCodeIncorrect`: return error2FAOtpCodeIncorrect;
    case `errorClientsAmbiguousLoginEmail`: return errorClientsAmbiguousLoginEmail;
    case `errorClientsBadEmail`: return errorClientsBadEmail;
    case `errorClientsCrossAppLoginUnsupported`: return errorClientsCrossAppLoginUnsupported;
    case `errorClientsEmailAlreadyVerified`: return errorClientsEmailAlreadyVerified;
    case `errorClientsEmailNotAvailable`: return errorClientsEmailNotAvailable;
    case `errorClientsEmailNotVerified`: return errorClientsEmailNotVerified;
    case `errorClientsExpiredConfirmEmail`: return errorClientsExpiredConfirmEmail;
    case `errorClientsInvalidConfirmEmail`: return errorClientsInvalidConfirmEmail;
    case `errorClientsInvalidEmail`: return errorClientsInvalidEmail;
    case `errorClientsInvalidEmailDotCon`: return errorClientsInvalidEmailDotCon;
    case `errorClientsInvalidEmailEndsInDot`: return errorClientsInvalidEmailEndsInDot;
    case `errorClientsInvalidEmailFormat`: return errorClientsInvalidEmailFormat;
    case `errorClientsInvalidPasswordReset`: return errorClientsInvalidPasswordReset;
    case `errorClientsInvalidPhoneNumber`: return errorClientsInvalidPhoneNumber;
    case `errorClientsUsedConfirmEmailLink`: return errorClientsUsedConfirmEmailLink;
    case `errorCloseAccountNotAllowed`: return errorCloseAccountNotAllowed;
    case `errorConnectionMessage`: return errorConnectionMessage;
    case `errorConnectionTitle`: return errorConnectionTitle;
    case `errorContactSupportPreOnboardingMessage`: return errorContactSupportPreOnboardingMessage;
    case `errorContactSupportPreOnboardingTitle`: return errorContactSupportPreOnboardingTitle;
    case `errorDefaultMessage`: return errorDefaultMessage;
    case `errorDefaultTitle`: return errorDefaultTitle;
    case `errorDeletingOnlyPaymentMethodNotPermitted`: return errorDeletingOnlyPaymentMethodNotPermitted;
    case `errorDiscountAppliedAfterInsufficientFunds`: return errorDiscountAppliedAfterInsufficientFunds;
    case `errorDiscountAppliedAfterInsufficientFundsContinue`: return errorDiscountAppliedAfterInsufficientFundsContinue;
    case `errorDiscountAppliedAfterInsufficientFundsTitle`: return errorDiscountAppliedAfterInsufficientFundsTitle;
    case `errorInvalidArgumentFallbackMessage`: return errorInvalidArgumentFallbackMessage;
    case `errorInvalidArgumentTitle`: return errorInvalidArgumentTitle;
    case `errorPasswordComplexityNotComplex`: return errorPasswordComplexityNotComplex;
    case `errorPasswordComplexityRepeatedCharacters`: return errorPasswordComplexityRepeatedCharacters;
    case `errorPasswordComplexityTooLong`: return errorPasswordComplexityTooLong;
    case `errorPasswordNoPasswordSet`: return errorPasswordNoPasswordSet;
    case `errorPasswordPasswordIncorrect`: return errorPasswordPasswordIncorrect;
    case `errorPasswordTooCommon`: return errorPasswordTooCommon;
    case `errorPauseAccountNotAllowed`: return errorPauseAccountNotAllowed;
    case `errorPayrollCompanyIdentifierRequired`: return errorPayrollCompanyIdentifierRequired;
    case `errorPayrollConnectionMFAChallengeInvalidState`: return errorPayrollConnectionMFAChallengeInvalidState;
    case `errorPayrollCredentialChangesRateLimited`: return errorPayrollCredentialChangesRateLimited;
    case `errorPayrollCredentialResourceExhaustedFallback`: return errorPayrollCredentialResourceExhaustedFallback;
    case `errorPayrollLoginUrlInvalid`: return errorPayrollLoginUrlInvalid;
    case `errorPayrollLoginUrlRequired`: return errorPayrollLoginUrlRequired;
    case `errorPayrollPasswordRequired`: return errorPayrollPasswordRequired;
    case `errorPayrollUnsupportedSandboxUsername`: return errorPayrollUnsupportedSandboxUsername;
    case `errorPayrollUsernameRequired`: return errorPayrollUsernameRequired;
    case `errorReferralVoucherCodeInvalid`: return errorReferralVoucherCodeInvalid;
    case `errorRepayAdvancePaymentPending`: return errorRepayAdvancePaymentPending;
    case `errorRetrievingAdvanceHistoryReceipts`: return errorRetrievingAdvanceHistoryReceipts;
    case `errorSubscriptionsSubscriptionPaused`: return errorSubscriptionsSubscriptionPaused;
    case `errorUpdateNameNotSupported`: return errorUpdateNameNotSupported;
    case `errorVerifyBankAccountFailed`: return errorVerifyBankAccountFailed;
    case `errorWorkflowAmountTooLarge`: return errorWorkflowAmountTooLarge;
    case `errorWorkflowAmountTooSmall`: return errorWorkflowAmountTooSmall;
    case `errorWorkflowAppVersionNotNewEnough`: return errorWorkflowAppVersionNotNewEnough;
    case `errorWorkflowAtLeastOneJobsIsRequired`: return errorWorkflowAtLeastOneJobsIsRequired;
    case `errorWorkflowBrokenConnection`: return errorWorkflowBrokenConnection;
    case `errorWorkflowDateTooEarly`: return errorWorkflowDateTooEarly;
    case `errorWorkflowDateTooLate`: return errorWorkflowDateTooLate;
    case `errorWorkflowDocumentRequestRequired`: return errorWorkflowDocumentRequestRequired;
    case `errorWorkflowEmailIsNotConfirmed`: return errorWorkflowEmailIsNotConfirmed;
    case `errorWorkflowEmailReservedForVisorClient`: return errorWorkflowEmailReservedForVisorClient;
    case `errorWorkflowExampleValidationMessage`: return errorWorkflowExampleValidationMessage;
    case `errorWorkflowLastFourSsnIsNotConfirmed`: return errorWorkflowLastFourSsnIsNotConfirmed;
    case `errorWorkflowPayPeriodDatesSwapped`: return errorWorkflowPayPeriodDatesSwapped;
    case `errorWorkflowPayPeriodIsInFuture`: return errorWorkflowPayPeriodIsInFuture;
    case `errorWorkflowPayPeriodIsTooLong`: return errorWorkflowPayPeriodIsTooLong;
    case `errorWorkflowPayPeriodIsTooOld`: return errorWorkflowPayPeriodIsTooOld;
    case `errorWorkflowPayStatementFedTaxYTDValuesBelowPrevious`: return errorWorkflowPayStatementFedTaxYTDValuesBelowPrevious;
    case `errorWorkflowPayStatementFederalIncomeTaxIsZero`: return errorWorkflowPayStatementFederalIncomeTaxIsZero;
    case `errorWorkflowPayStatementFederalIncomeTaxOutOfExpectedRangeLow`: return errorWorkflowPayStatementFederalIncomeTaxOutOfExpectedRangeLow;
    case `errorWorkflowPayStatementFederalIncomeTaxOutofExpectedRangeHigh`: return errorWorkflowPayStatementFederalIncomeTaxOutofExpectedRangeHigh;
    case `errorWorkflowPayStatementFederalIncomeTaxTooHigh`: return errorWorkflowPayStatementFederalIncomeTaxTooHigh;
    case `errorWorkflowPayStatementGrossIncomeIsRequired`: return errorWorkflowPayStatementGrossIncomeIsRequired;
    case `errorWorkflowPayStatementGrossIncomeTooHigh`: return errorWorkflowPayStatementGrossIncomeTooHigh;
    case `errorWorkflowPayStatementGrossIncomeYTDValuesBelowPrevious`: return errorWorkflowPayStatementGrossIncomeYTDValuesBelowPrevious;
    case `errorWorkflowPayStatementMedicareTaxIsOutOfExpectedRange`: return errorWorkflowPayStatementMedicareTaxIsOutOfExpectedRange;
    case `errorWorkflowPayStatementNetIncomeYTDValuesBelowPrevious`: return errorWorkflowPayStatementNetIncomeYTDValuesBelowPrevious;
    case `errorWorkflowPayStatementSocialSecurityTaxIsOutOfExpectedRange`: return errorWorkflowPayStatementSocialSecurityTaxIsOutOfExpectedRange;
    case `errorWorkflowPayStatementStateTaxYTDValuesBelowPrevious`: return errorWorkflowPayStatementStateTaxYTDValuesBelowPrevious;
    case `errorWorkflowPayStatementValuesSwapped`: return errorWorkflowPayStatementValuesSwapped;
    case `errorWorkflowPhoneIsNotConfirmed`: return errorWorkflowPhoneIsNotConfirmed;
    case `footprintSDKErrorBody`: return footprintSDKErrorBody;
    case `footprintSDKErrorTitle`: return footprintSDKErrorTitle;
    case `frequencyDaily`: return frequencyDaily;
    case `frequencyEveryPayday`: return frequencyEveryPayday;
    case `frequencyFirstAndFifteenth`: return frequencyFirstAndFifteenth;
    case `frequencyMonthly`: return frequencyMonthly;
    case `frequencyOnce`: return frequencyOnce;
    case `frequencyWeekly`: return frequencyWeekly;
    case `generalPriceComparisson`: return generalPriceComparisson;
    case `genericCheckBack`: return genericCheckBack;
    case `genericErrorRetrieving`: return genericErrorRetrieving;
    case `genericGoBack`: return genericGoBack;
    case `getCashboostDeniedBottomInfoTitle`: return getCashboostDeniedBottomInfoTitle;
    case `getCashboostDeniedLeadingInfoTitle`: return getCashboostDeniedLeadingInfoTitle;
    case `getCashboostDeniedTitle`: return getCashboostDeniedTitle;
    case `getCashboostDeniedTrailingInfoContent`: return getCashboostDeniedTrailingInfoContent;
    case `getCashboostDeniedTrailingInfoTitle`: return getCashboostDeniedTrailingInfoTitle;
    case `getCashboostDeniedntent`: return getCashboostDeniedntent;
    case `getCashboostOffboardingTileDoesNotQualifyContent`: return getCashboostOffboardingTileDoesNotQualifyContent;
    case `getCashboostOffboardingTileDoesNotQualifySubtitle`: return getCashboostOffboardingTileDoesNotQualifySubtitle;
    case `getCashboostOffboardingTileDoesNotQualifyTitle`: return getCashboostOffboardingTileDoesNotQualifyTitle;
    case `getCashboostOffboardingTileNoLongerQualifiesContent`: return getCashboostOffboardingTileNoLongerQualifiesContent;
    case `getCashboostOffboardingTileNoLongerQualifiesSubtitle`: return getCashboostOffboardingTileNoLongerQualifiesSubtitle;
    case `getCashboostOffboardingTileNoLongerQualifiesTitle`: return getCashboostOffboardingTileNoLongerQualifiesTitle;
    case `getCashboostOffboardingTileWaitlistContent`: return getCashboostOffboardingTileWaitlistContent;
    case `getCashboostOffboardingTileWaitlistSubtitle`: return getCashboostOffboardingTileWaitlistSubtitle;
    case `getCashboostOffboardingTileWaitlistTitle`: return getCashboostOffboardingTileWaitlistTitle;
    case `getCashboostPaymentSettlingTileButton`: return getCashboostPaymentSettlingTileButton;
    case `getCashboostPaymentSettlingTileContent`: return getCashboostPaymentSettlingTileContent;
    case `getCashboostPaymentSettlingTileTitle`: return getCashboostPaymentSettlingTileTitle;
    case `getCashboostStatusTileExceptionContent`: return getCashboostStatusTileExceptionContent;
    case `getCashboostStatusTileExceptionLeadingInfoTitle`: return getCashboostStatusTileExceptionLeadingInfoTitle;
    case `getCashboostStatusTileExceptionTitle`: return getCashboostStatusTileExceptionTitle;
    case `getCashboostStatusTileExceptionTrailingInfoContent`: return getCashboostStatusTileExceptionTrailingInfoContent;
    case `getCashboostStatusTileExceptionTrailingInfoTitle`: return getCashboostStatusTileExceptionTrailingInfoTitle;
    case `getCashboostStatusTileFinanceCompleteContent`: return getCashboostStatusTileFinanceCompleteContent;
    case `getCashboostStatusTileFinanceCompleteLeadingInfoTitle`: return getCashboostStatusTileFinanceCompleteLeadingInfoTitle;
    case `getCashboostStatusTileFinanceCompleteTitle`: return getCashboostStatusTileFinanceCompleteTitle;
    case `getCashboostStatusTileFinanceCompleteTrailingInfoContent`: return getCashboostStatusTileFinanceCompleteTrailingInfoContent;
    case `getCashboostStatusTileFinanceCompleteTrailingInfoTitle`: return getCashboostStatusTileFinanceCompleteTrailingInfoTitle;
    case `getCashboostStatusTileFundingContent`: return getCashboostStatusTileFundingContent;
    case `getCashboostStatusTileFundingLeadingInfoTitle`: return getCashboostStatusTileFundingLeadingInfoTitle;
    case `getCashboostStatusTileFundingRequestedContent`: return getCashboostStatusTileFundingRequestedContent;
    case `getCashboostStatusTileFundingRequestedLeadingInfoTitle`: return getCashboostStatusTileFundingRequestedLeadingInfoTitle;
    case `getCashboostStatusTileFundingRequestedTitle`: return getCashboostStatusTileFundingRequestedTitle;
    case `getCashboostStatusTileFundingRequestedTrailingInfoContent`: return getCashboostStatusTileFundingRequestedTrailingInfoContent;
    case `getCashboostStatusTileFundingRequestedTrailingInfoTitle`: return getCashboostStatusTileFundingRequestedTrailingInfoTitle;
    case `getCashboostStatusTileFundingTitle`: return getCashboostStatusTileFundingTitle;
    case `getCashboostStatusTileFundingTrailingInfoTitle`: return getCashboostStatusTileFundingTrailingInfoTitle;
    case `getCashboostStatusTileRepaymentContent`: return getCashboostStatusTileRepaymentContent;
    case `getCashboostStatusTileRepaymentLeadingInfoTitle`: return getCashboostStatusTileRepaymentLeadingInfoTitle;
    case `getCashboostStatusTileRepaymentTitle`: return getCashboostStatusTileRepaymentTitle;
    case `getCashboostStatusTileRepaymentTrailingInfoTitle`: return getCashboostStatusTileRepaymentTrailingInfoTitle;
    case `getCashboostTileCTA`: return getCashboostTileCTA;
    case `getCashboostTileContent`: return getCashboostTileContent;
    case `getCashboostTileTitle`: return getCashboostTileTitle;
    case `getCashboostTileTitleSubsequentAdvanceTileCTA`: return getCashboostTileTitleSubsequentAdvanceTileCTA;
    case `getCashboostTileTitleSubsequentAdvanceTileContent`: return getCashboostTileTitleSubsequentAdvanceTileContent;
    case `getCashboostTileTitleSubsequentAdvanceTileTitle`: return getCashboostTileTitleSubsequentAdvanceTileTitle;
    case `getCashboostWaitingForCashboostTileContent`: return getCashboostWaitingForCashboostTileContent;
    case `getCashboostWaitingForCashboostTileTitle`: return getCashboostWaitingForCashboostTileTitle;
    case `getPayboostContinueSetupTileCTA`: return getPayboostContinueSetupTileCTA;
    case `getPayboostContinueSetupTileContent`: return getPayboostContinueSetupTileContent;
    case `getPayboostContinueSetupTileTitle`: return getPayboostContinueSetupTileTitle;
    case `getPayboostCurrentBoostTileTitle`: return getPayboostCurrentBoostTileTitle;
    case `getPayboostCurrentPayboostTileNextCheckinDaysAmountSuffixPlural`: return getPayboostCurrentPayboostTileNextCheckinDaysAmountSuffixPlural;
    case `getPayboostCurrentPayboostTileNextCheckinDaysAmountSuffixSingular`: return getPayboostCurrentPayboostTileNextCheckinDaysAmountSuffixSingular;
    case `getPayboostCurrentPayboostTileNextCheckinDaysAmountUnknown`: return getPayboostCurrentPayboostTileNextCheckinDaysAmountUnknown;
    case `getPayboostCurrentPayboostTileNextCheckinTitle`: return getPayboostCurrentPayboostTileNextCheckinTitle;
    case `getPayboostCurrentPayboostTileTotalAmountPrefix`: return getPayboostCurrentPayboostTileTotalAmountPrefix;
    case `getPayboostExtraInfoTileCTA`: return getPayboostExtraInfoTileCTA;
    case `getPayboostExtraInfoTileContent`: return getPayboostExtraInfoTileContent;
    case `getPayboostExtraInfoTileTitle`: return getPayboostExtraInfoTileTitle;
    case `getPayboostMonthlyCheckinContinueSetupTileCTA`: return getPayboostMonthlyCheckinContinueSetupTileCTA;
    case `getPayboostMonthlyCheckinContinueSetupTileContent`: return getPayboostMonthlyCheckinContinueSetupTileContent;
    case `getPayboostMonthlyCheckinContinueSetupTileTitle`: return getPayboostMonthlyCheckinContinueSetupTileTitle;
    case `getPayboostNoCheckoutError`: return getPayboostNoCheckoutError;
    case `getPayboostOffboardingTileDoesNotQualifyCTA`: return getPayboostOffboardingTileDoesNotQualifyCTA;
    case `getPayboostOffboardingTileDoesNotQualifyContent`: return getPayboostOffboardingTileDoesNotQualifyContent;
    case `getPayboostOffboardingTileDoesNotQualifySubtitle`: return getPayboostOffboardingTileDoesNotQualifySubtitle;
    case `getPayboostOffboardingTileDoesNotQualifyTitle`: return getPayboostOffboardingTileDoesNotQualifyTitle;
    case `getPayboostOnboardingIntroCTA`: return getPayboostOnboardingIntroCTA;
    case `getPayboostOnboardingIntroSubtitle`: return getPayboostOnboardingIntroSubtitle;
    case `getPayboostOnboardingIntroTitle`: return getPayboostOnboardingIntroTitle;
    case `getPayboostOnboardingIntroValuePropOne`: return getPayboostOnboardingIntroValuePropOne;
    case `getPayboostOnboardingIntroValuePropThree`: return getPayboostOnboardingIntroValuePropThree;
    case `getPayboostOnboardingIntroValuePropTwo`: return getPayboostOnboardingIntroValuePropTwo;
    case `getPayboostOnboardingMeetSubtitle`: return getPayboostOnboardingMeetSubtitle;
    case `getPayboostOnboardingMeetTitle`: return getPayboostOnboardingMeetTitle;
    case `getPayboostOnboardingTileCTA`: return getPayboostOnboardingTileCTA;
    case `getPayboostOnboardingTileCTA2`: return getPayboostOnboardingTileCTA2;
    case `getPayboostOnboardingTileContent`: return getPayboostOnboardingTileContent;
    case `getPayboostOnboardingTileContent2`: return getPayboostOnboardingTileContent2;
    case `getPayboostOnboardingTileTitle`: return getPayboostOnboardingTileTitle;
    case `getPayboostOnboardingTileTitle2`: return getPayboostOnboardingTileTitle2;
    case `getPayboostReadyTileCTA`: return getPayboostReadyTileCTA;
    case `getPayboostReadyTileText`: return getPayboostReadyTileText;
    case `getPayboostReadyTileTitle`: return getPayboostReadyTileTitle;
    case `getPayboostWaitingForBoostTileCTA`: return getPayboostWaitingForBoostTileCTA;
    case `getPayboostWaitingForBoostTileContent`: return getPayboostWaitingForBoostTileContent;
    case `getPayboostWaitingForBoostTileTitle`: return getPayboostWaitingForBoostTileTitle;
    case `getPayboostWaitingForPayboostCadenceLabel`: return getPayboostWaitingForPayboostCadenceLabel;
    case `getPayboostWaitingForPayboostTileContent`: return getPayboostWaitingForPayboostTileContent;
    case `getPayboostWaitingForPayboostTileTitle`: return getPayboostWaitingForPayboostTileTitle;
    case `getStartedPage1Body`: return getStartedPage1Body;
    case `getStartedPage1Body2`: return getStartedPage1Body2;
    case `getStartedPage1Title`: return getStartedPage1Title;
    case `getStartedPage1Title2`: return getStartedPage1Title2;
    case `getStartedPage2Body`: return getStartedPage2Body;
    case `getStartedPage2Body2`: return getStartedPage2Body2;
    case `getStartedPage2Title`: return getStartedPage2Title;
    case `getStartedPage2Title2`: return getStartedPage2Title2;
    case `getStartedPage3Body`: return getStartedPage3Body;
    case `getStartedPage3Body2`: return getStartedPage3Body2;
    case `getStartedPage3Title`: return getStartedPage3Title;
    case `getStartedPage3Title2`: return getStartedPage3Title2;
    case `getStartedPage4Body`: return getStartedPage4Body;
    case `getStartedPage4Body2`: return getStartedPage4Body2;
    case `getStartedPage4Title`: return getStartedPage4Title;
    case `getStartedPage4Title2`: return getStartedPage4Title2;
    case `gridCardCheckoutScreenBenefitsBulletA`: return gridCardCheckoutScreenBenefitsBulletA;
    case `gridCardCheckoutScreenBenefitsBulletB`: return gridCardCheckoutScreenBenefitsBulletB;
    case `gridCardCheckoutScreenBenefitsBulletC`: return gridCardCheckoutScreenBenefitsBulletC;
    case `gridCardCheckoutScreenBenefitsTitle`: return gridCardCheckoutScreenBenefitsTitle;
    case `gridCardCheckoutScreenSubscriptionUpgradeContentTitle`: return gridCardCheckoutScreenSubscriptionUpgradeContentTitle;
    case `gridCardCheckoutScreenSubscriptionUpgradeTitle`: return gridCardCheckoutScreenSubscriptionUpgradeTitle;
    case `gridCardDashboardBulletA`: return gridCardDashboardBulletA;
    case `gridCardDashboardBulletB`: return gridCardDashboardBulletB;
    case `gridCardDashboardBulletC`: return gridCardDashboardBulletC;
    case `gridCardDashboardCTATitle`: return gridCardDashboardCTATitle;
    case `gridCardDashboardHowCTATitle`: return gridCardDashboardHowCTATitle;
    case `gridCardDashboardSybtitle`: return gridCardDashboardSybtitle;
    case `gridCardDashboardTitle`: return gridCardDashboardTitle;
    case `gridCardDepositMoneyTileTitle`: return gridCardDepositMoneyTileTitle;
    case `gridCardFundAccountSubtitle`: return gridCardFundAccountSubtitle;
    case `gridCardFundAccountTitle`: return gridCardFundAccountTitle;
    case `gridCardFundedAccountSuccededCTATitle`: return gridCardFundedAccountSuccededCTATitle;
    case `gridCardFundedAccountSuccededScreenTitle`: return gridCardFundedAccountSuccededScreenTitle;
    case `gridCardFundedAccountSuccededSubtitle`: return gridCardFundedAccountSuccededSubtitle;
    case `gridCardFundedAccountSuccededTitle`: return gridCardFundedAccountSuccededTitle;
    case `gridCardHowItWorksSubitleA`: return gridCardHowItWorksSubitleA;
    case `gridCardHowItWorksSubitleB`: return gridCardHowItWorksSubitleB;
    case `gridCardHowItWorksSubitleC`: return gridCardHowItWorksSubitleC;
    case `gridCardHowItWorksSubitleD`: return gridCardHowItWorksSubitleD;
    case `gridCardHowItWorksTitleA`: return gridCardHowItWorksTitleA;
    case `gridCardHowItWorksTitleB`: return gridCardHowItWorksTitleB;
    case `gridCardHowItWorksTitleC`: return gridCardHowItWorksTitleC;
    case `gridCardHowItWorksTitleD`: return gridCardHowItWorksTitleD;
    case `gridCardKBADetailsBulletA`: return gridCardKBADetailsBulletA;
    case `gridCardKBADetailsBulletB`: return gridCardKBADetailsBulletB;
    case `gridCardKBADetailsBulletC`: return gridCardKBADetailsBulletC;
    case `gridCardKBADetailsBulletD`: return gridCardKBADetailsBulletD;
    case `gridCardKBADetailsCTATitle`: return gridCardKBADetailsCTATitle;
    case `gridCardKBADetailsTitle`: return gridCardKBADetailsTitle;
    case `gridCardKBADetailsTitleLight`: return gridCardKBADetailsTitleLight;
    case `gridCardKBAOnboardingNotEnoughCredit`: return gridCardKBAOnboardingNotEnoughCredit;
    case `gridCardKBAOnboardingWaitingOnCreditScore`: return gridCardKBAOnboardingWaitingOnCreditScore;
    case `gridCardLockedScreenBullet1`: return gridCardLockedScreenBullet1;
    case `gridCardLockedScreenBullet2`: return gridCardLockedScreenBullet2;
    case `gridCardLockedScreenBullet3`: return gridCardLockedScreenBullet3;
    case `gridCardLockedScreenCTATitle`: return gridCardLockedScreenCTATitle;
    case `gridCardLockedScreenTitle`: return gridCardLockedScreenTitle;
    case `gridCardOnboardingScreenBBulletA`: return gridCardOnboardingScreenBBulletA;
    case `gridCardOnboardingScreenBBulletB`: return gridCardOnboardingScreenBBulletB;
    case `gridCardOnboardingScreenBBulletC`: return gridCardOnboardingScreenBBulletC;
    case `gridCardOnboardingScreenBTitle`: return gridCardOnboardingScreenBTitle;
    case `gridCardOnboardingScreenBTitleLight`: return gridCardOnboardingScreenBTitleLight;
    case `gridCardOnboardingScreenCBulletA`: return gridCardOnboardingScreenCBulletA;
    case `gridCardOnboardingScreenCBulletB`: return gridCardOnboardingScreenCBulletB;
    case `gridCardOnboardingScreenCBulletC`: return gridCardOnboardingScreenCBulletC;
    case `gridCardOnboardingScreenCTitle`: return gridCardOnboardingScreenCTitle;
    case `gridCardOnboardingScreenCTitleLight`: return gridCardOnboardingScreenCTitleLight;
    case `gridCardOnboardingScreenDBulletA`: return gridCardOnboardingScreenDBulletA;
    case `gridCardOnboardingScreenDBulletB`: return gridCardOnboardingScreenDBulletB;
    case `gridCardOnboardingScreenDBulletC`: return gridCardOnboardingScreenDBulletC;
    case `gridCardOnboardingScreenDTitle`: return gridCardOnboardingScreenDTitle;
    case `gridCardOnboardingScreenDTitleLight`: return gridCardOnboardingScreenDTitleLight;
    case `gridCardOnboardingTileBanner`: return gridCardOnboardingTileBanner;
    case `gridCardOnboardingTileButton`: return gridCardOnboardingTileButton;
    case `gridCardOnboardingTileTitle`: return gridCardOnboardingTileTitle;
    case `gridCardStatusIsDefaultedCTA`: return gridCardStatusIsDefaultedCTA;
    case `gridCardStatusIsDefaultedContent`: return gridCardStatusIsDefaultedContent;
    case `gridCardStatusIsDefaultedTitle`: return gridCardStatusIsDefaultedTitle;
    case `gridCardStatusIsDelinquentCTA`: return gridCardStatusIsDelinquentCTA;
    case `gridCardStatusIsDelinquentContent`: return gridCardStatusIsDelinquentContent;
    case `gridCardStatusIsDelinquentTitle`: return gridCardStatusIsDelinquentTitle;
    case `gridCardStatusNegativeBalanceCTA`: return gridCardStatusNegativeBalanceCTA;
    case `gridCardStatusNegativeBalanceContent`: return gridCardStatusNegativeBalanceContent;
    case `gridCardStatusNegativeBalanceTitle`: return gridCardStatusNegativeBalanceTitle;
    case `gridCardToUnlockScreenBullet1`: return gridCardToUnlockScreenBullet1;
    case `gridCardToUnlockScreenBullet2`: return gridCardToUnlockScreenBullet2;
    case `gridCardToUnlockScreenMonth`: return gridCardToUnlockScreenMonth;
    case `gridCardToUnlockScreenSubtitle`: return gridCardToUnlockScreenSubtitle;
    case `gridCardToUnlockScreenTitle`: return gridCardToUnlockScreenTitle;
    case `gridCardTransactionTypeAtmWithdrawal`: return gridCardTransactionTypeAtmWithdrawal;
    case `gridCardTransactionTypeCardPurchase`: return gridCardTransactionTypeCardPurchase;
    case `gridCardTransactionTypeCreditFromVendor`: return gridCardTransactionTypeCreditFromVendor;
    case `gridCardTransactionTypeCreditTransferFromCreditLine`: return gridCardTransactionTypeCreditTransferFromCreditLine;
    case `gridCardTransactionTypeGenericCredit`: return gridCardTransactionTypeGenericCredit;
    case `gridCardTransactionTypeGenericDebit`: return gridCardTransactionTypeGenericDebit;
    case `gridCardTransactionTypeGridPlusSubscription`: return gridCardTransactionTypeGridPlusSubscription;
    case `gridCardTransactionTypeInvalid`: return gridCardTransactionTypeInvalid;
    case `gridCardTransactionTypeProvisionalCreditForDisputedTransaction`: return gridCardTransactionTypeProvisionalCreditForDisputedTransaction;
    case `gridCardTransactionTypeRewardsRedemption`: return gridCardTransactionTypeRewardsRedemption;
    case `gridCardTransactionTypeTransferToCreditLine`: return gridCardTransactionTypeTransferToCreditLine;
    case `gridCardTransactionTypeUnclassified`: return gridCardTransactionTypeUnclassified;
    case `gridCardTransactionTypeWithdrawalToVendor`: return gridCardTransactionTypeWithdrawalToVendor;
    case `gridCardTransactionsListEmpty`: return gridCardTransactionsListEmpty;
    case `gridCardTransactionsListEndOfTransactions`: return gridCardTransactionsListEndOfTransactions;
    case `gridCardWithdrawMoneyTileTitle`: return gridCardWithdrawMoneyTileTitle;
    case `gridLegalAgreements`: return gridLegalAgreements;
    case `gridPlusSubscriptionMissingCTA`: return gridPlusSubscriptionMissingCTA;
    case `gridPlusSubscriptionOverdueCTA`: return gridPlusSubscriptionOverdueCTA;
    case `gridPlusSubscriptionOverdueContent`: return gridPlusSubscriptionOverdueContent;
    case `gridPlusSubscriptionOverdueTitle`: return gridPlusSubscriptionOverdueTitle;
    case `helpContentButtonClose`: return helpContentButtonClose;
    case `incomeProtectionActiveTileActiveState`: return incomeProtectionActiveTileActiveState;
    case `incomeProtectionActiveTileCoverageAmntPlain`: return incomeProtectionActiveTileCoverageAmntPlain;
    case `incomeProtectionActiveTileCoverageAmntValue`: return incomeProtectionActiveTileCoverageAmntValue;
    case `incomeProtectionActiveTileEligibleDate`: return incomeProtectionActiveTileEligibleDate;
    case `incomeProtectionActiveTileEligibleDateDefaultValue`: return incomeProtectionActiveTileEligibleDateDefaultValue;
    case `incomeProtectionActiveTileEligibleDateValue`: return incomeProtectionActiveTileEligibleDateValue;
    case `incomeProtectionActiveTileOverdueState`: return incomeProtectionActiveTileOverdueState;
    case `incomeProtectionActiveTilePaymentDue`: return incomeProtectionActiveTilePaymentDue;
    case `incomeProtectionActiveTilePaymentDuePaidValue`: return incomeProtectionActiveTilePaymentDuePaidValue;
    case `incomeProtectionActiveTilePaymentDueValue`: return incomeProtectionActiveTilePaymentDueValue;
    case `incomeProtectionActiveTileTitle`: return incomeProtectionActiveTileTitle;
    case `incomeProtectionAddBankAccountCTATitle`: return incomeProtectionAddBankAccountCTATitle;
    case `incomeProtectionAddDebitCardCTAButton`: return incomeProtectionAddDebitCardCTAButton;
    case `incomeProtectionAddPaymentMethodTitle`: return incomeProtectionAddPaymentMethodTitle;
    case `incomeProtectionApplyingTileAlmost`: return incomeProtectionApplyingTileAlmost;
    case `incomeProtectionApplyingTileApplyCTATitle`: return incomeProtectionApplyingTileApplyCTATitle;
    case `incomeProtectionApplyingTileMessage`: return incomeProtectionApplyingTileMessage;
    case `incomeProtectionApplyingTileTitle`: return incomeProtectionApplyingTileTitle;
    case `incomeProtectionBenefitPayoutEventTitle`: return incomeProtectionBenefitPayoutEventTitle;
    case `incomeProtectionBulletItem1`: return incomeProtectionBulletItem1;
    case `incomeProtectionBulletItem2`: return incomeProtectionBulletItem2;
    case `incomeProtectionBulletItem3`: return incomeProtectionBulletItem3;
    case `incomeProtectionCheckoutAddCard`: return incomeProtectionCheckoutAddCard;
    case `incomeProtectionCheckoutAddPayment`: return incomeProtectionCheckoutAddPayment;
    case `incomeProtectionCheckoutAgreementLabel`: return incomeProtectionCheckoutAgreementLabel;
    case `incomeProtectionCheckoutAgreementLinkedText`: return incomeProtectionCheckoutAgreementLinkedText;
    case `incomeProtectionCheckoutAgreementURL`: return incomeProtectionCheckoutAgreementURL;
    case `incomeProtectionCheckoutBubbleLine1`: return incomeProtectionCheckoutBubbleLine1;
    case `incomeProtectionCheckoutBubbleLine2`: return incomeProtectionCheckoutBubbleLine2;
    case `incomeProtectionCheckoutBubbleTitle`: return incomeProtectionCheckoutBubbleTitle;
    case `incomeProtectionCheckoutCTA`: return incomeProtectionCheckoutCTA;
    case `incomeProtectionCheckoutCardError`: return incomeProtectionCheckoutCardError;
    case `incomeProtectionCheckoutCollectionsExplainer`: return incomeProtectionCheckoutCollectionsExplainer;
    case `incomeProtectionCheckoutCost`: return incomeProtectionCheckoutCost;
    case `incomeProtectionCheckoutCostDue`: return incomeProtectionCheckoutCostDue;
    case `incomeProtectionCheckoutCostDuePlus`: return incomeProtectionCheckoutCostDuePlus;
    case `incomeProtectionCheckoutCostOnly`: return incomeProtectionCheckoutCostOnly;
    case `incomeProtectionCheckoutCoverage`: return incomeProtectionCheckoutCoverage;
    case `incomeProtectionCheckoutCoveragePlain`: return incomeProtectionCheckoutCoveragePlain;
    case `incomeProtectionCheckoutExistingMember`: return incomeProtectionCheckoutExistingMember;
    case `incomeProtectionCheckoutFeeExplaner`: return incomeProtectionCheckoutFeeExplaner;
    case `incomeProtectionCheckoutHeaderBannerTitle`: return incomeProtectionCheckoutHeaderBannerTitle;
    case `incomeProtectionCheckoutHeaderTitle`: return incomeProtectionCheckoutHeaderTitle;
    case `incomeProtectionCheckoutNewMember`: return incomeProtectionCheckoutNewMember;
    case `incomeProtectionCheckoutPaymentFailed`: return incomeProtectionCheckoutPaymentFailed;
    case `incomeProtectionCheckoutQuestionsLabel`: return incomeProtectionCheckoutQuestionsLabel;
    case `incomeProtectionCheckoutQuestionsLinkedText`: return incomeProtectionCheckoutQuestionsLinkedText;
    case `incomeProtectionCheckoutQuestionsURL`: return incomeProtectionCheckoutQuestionsURL;
    case `incomeProtectionCheckoutRowsTitle`: return incomeProtectionCheckoutRowsTitle;
    case `incomeProtectionCheckoutScreenCTATitle`: return incomeProtectionCheckoutScreenCTATitle;
    case `incomeProtectionCheckoutScreenFirstRow`: return incomeProtectionCheckoutScreenFirstRow;
    case `incomeProtectionCheckoutScreenNewMember`: return incomeProtectionCheckoutScreenNewMember;
    case `incomeProtectionCheckoutScreenSecondRow`: return incomeProtectionCheckoutScreenSecondRow;
    case `incomeProtectionCheckoutScreenTitle`: return incomeProtectionCheckoutScreenTitle;
    case `incomeProtectionCheckoutSubTitle`: return incomeProtectionCheckoutSubTitle;
    case `incomeProtectionCheckoutSubmitSubtitle`: return incomeProtectionCheckoutSubmitSubtitle;
    case `incomeProtectionCheckoutSubmitTitle`: return incomeProtectionCheckoutSubmitTitle;
    case `incomeProtectionCheckoutTitle`: return incomeProtectionCheckoutTitle;
    case `incomeProtectionCheckoutTitleOrder`: return incomeProtectionCheckoutTitleOrder;
    case `incomeProtectionCheckoutTitleSubscription`: return incomeProtectionCheckoutTitleSubscription;
    case `incomeProtectionClaimApplicationDocDescriptonPaystub`: return incomeProtectionClaimApplicationDocDescriptonPaystub;
    case `incomeProtectionClaimApplicationDocDescriptonTerminationLetter`: return incomeProtectionClaimApplicationDocDescriptonTerminationLetter;
    case `incomeProtectionClaimApplicationDocDescriptonUnemployment`: return incomeProtectionClaimApplicationDocDescriptonUnemployment;
    case `incomeProtectionClaimInProgressMessage`: return incomeProtectionClaimInProgressMessage;
    case `incomeProtectionClaimRejectedPopupTitle`: return incomeProtectionClaimRejectedPopupTitle;
    case `incomeProtectionClaimStatusScreenNoClaims`: return incomeProtectionClaimStatusScreenNoClaims;
    case `incomeProtectionClaimStatusScreenTitle`: return incomeProtectionClaimStatusScreenTitle;
    case `incomeProtectionClaimStepFastDelivery`: return incomeProtectionClaimStepFastDelivery;
    case `incomeProtectionClaimStepStandardDelivery`: return incomeProtectionClaimStepStandardDelivery;
    case `incomeProtectionClaimStepTransferBoxStandardDeliverySubtitle`: return incomeProtectionClaimStepTransferBoxStandardDeliverySubtitle;
    case `incomeProtectionClaimStepTransferBoxStandardDeliveryTitle`: return incomeProtectionClaimStepTransferBoxStandardDeliveryTitle;
    case `incomeProtectionClaimSubmittedEventTitle`: return incomeProtectionClaimSubmittedEventTitle;
    case `incomeProtectionClaimUploadDocsOption1`: return incomeProtectionClaimUploadDocsOption1;
    case `incomeProtectionClaimUploadDocsOption2`: return incomeProtectionClaimUploadDocsOption2;
    case `incomeProtectionClaimUploadDocsOption3`: return incomeProtectionClaimUploadDocsOption3;
    case `incomeProtectionClaimWorkflowClaimNextStepsStepBottomComment`: return incomeProtectionClaimWorkflowClaimNextStepsStepBottomComment;
    case `incomeProtectionClaimWorkflowClaimNextStepsStepCTATitle`: return incomeProtectionClaimWorkflowClaimNextStepsStepCTATitle;
    case `incomeProtectionClaimWorkflowClaimNextStepsStepSubtitle`: return incomeProtectionClaimWorkflowClaimNextStepsStepSubtitle;
    case `incomeProtectionClaimWorkflowClaimNextStepsStepTitle`: return incomeProtectionClaimWorkflowClaimNextStepsStepTitle;
    case `incomeProtectionClaimWorkflowClaimReceivedStepCTATitle`: return incomeProtectionClaimWorkflowClaimReceivedStepCTATitle;
    case `incomeProtectionClaimWorkflowClaimReceivedStepTitle`: return incomeProtectionClaimWorkflowClaimReceivedStepTitle;
    case `incomeProtectionClaimWorkflowCompleteClaimStepCTATitle`: return incomeProtectionClaimWorkflowCompleteClaimStepCTATitle;
    case `incomeProtectionClaimWorkflowCompleteClaimStepContent1`: return incomeProtectionClaimWorkflowCompleteClaimStepContent1;
    case `incomeProtectionClaimWorkflowCompleteClaimStepContent2`: return incomeProtectionClaimWorkflowCompleteClaimStepContent2;
    case `incomeProtectionClaimWorkflowCompleteClaimStepContent3`: return incomeProtectionClaimWorkflowCompleteClaimStepContent3;
    case `incomeProtectionClaimWorkflowCompleteClaimStepSubtitle`: return incomeProtectionClaimWorkflowCompleteClaimStepSubtitle;
    case `incomeProtectionClaimWorkflowCompleteClaimStepTitle`: return incomeProtectionClaimWorkflowCompleteClaimStepTitle;
    case `incomeProtectionClaimWorkflowHowItWorksStepContent1`: return incomeProtectionClaimWorkflowHowItWorksStepContent1;
    case `incomeProtectionClaimWorkflowHowItWorksStepContent2A`: return incomeProtectionClaimWorkflowHowItWorksStepContent2A;
    case `incomeProtectionClaimWorkflowHowItWorksStepContent2B`: return incomeProtectionClaimWorkflowHowItWorksStepContent2B;
    case `incomeProtectionClaimWorkflowHowItWorksStepContent3`: return incomeProtectionClaimWorkflowHowItWorksStepContent3;
    case `incomeProtectionClaimWorkflowHowItWorksStepTitle1`: return incomeProtectionClaimWorkflowHowItWorksStepTitle1;
    case `incomeProtectionClaimWorkflowHowItWorksStepTitle2`: return incomeProtectionClaimWorkflowHowItWorksStepTitle2;
    case `incomeProtectionClaimWorkflowHowItWorksStepTitle3`: return incomeProtectionClaimWorkflowHowItWorksStepTitle3;
    case `incomeProtectionClaimWorkflowIntroStepCTATitle`: return incomeProtectionClaimWorkflowIntroStepCTATitle;
    case `incomeProtectionClaimWorkflowIntroStepSubtitle`: return incomeProtectionClaimWorkflowIntroStepSubtitle;
    case `incomeProtectionClaimWorkflowIntroStepTitle`: return incomeProtectionClaimWorkflowIntroStepTitle;
    case `incomeProtectionClaimWorkflowNonEligibleToSubmitAClaim`: return incomeProtectionClaimWorkflowNonEligibleToSubmitAClaim;
    case `incomeProtectionClaimWorkflowStepBackButtonTitle`: return incomeProtectionClaimWorkflowStepBackButtonTitle;
    case `incomeProtectionClaimWorkflowStepNextButtonTitle`: return incomeProtectionClaimWorkflowStepNextButtonTitle;
    case `incomeProtectionClaimWorkflowStepTitle`: return incomeProtectionClaimWorkflowStepTitle;
    case `incomeProtectionClaimWorkflowTransferToStepBotomComment`: return incomeProtectionClaimWorkflowTransferToStepBotomComment;
    case `incomeProtectionClaimWorkflowTransferToStepLeftTitle`: return incomeProtectionClaimWorkflowTransferToStepLeftTitle;
    case `incomeProtectionClaimWorkflowTransferToStepLinkNewAccount`: return incomeProtectionClaimWorkflowTransferToStepLinkNewAccount;
    case `incomeProtectionClaimWorkflowTransferToStepRightTitle`: return incomeProtectionClaimWorkflowTransferToStepRightTitle;
    case `incomeProtectionClaimWorkflowTransferToStepTitle`: return incomeProtectionClaimWorkflowTransferToStepTitle;
    case `incomeProtectionClaimedStateCTATitle`: return incomeProtectionClaimedStateCTATitle;
    case `incomeProtectionClaimedStatePolicyEnded`: return incomeProtectionClaimedStatePolicyEnded;
    case `incomeProtectionClaimedStateSubtitle`: return incomeProtectionClaimedStateSubtitle;
    case `incomeProtectionClaimedStateTitle`: return incomeProtectionClaimedStateTitle;
    case `incomeProtectionClaimsStatusItemTitle`: return incomeProtectionClaimsStatusItemTitle;
    case `incomeProtectionClaimsUploadDocStepDocumentTypePayStub`: return incomeProtectionClaimsUploadDocStepDocumentTypePayStub;
    case `incomeProtectionClaimsUploadDocStepDocumentTypeTerminationLetter`: return incomeProtectionClaimsUploadDocStepDocumentTypeTerminationLetter;
    case `incomeProtectionClaimsUploadDocStepDocumentTypeUnemploymentInsurance`: return incomeProtectionClaimsUploadDocStepDocumentTypeUnemploymentInsurance;
    case `incomeProtectionClaimsUploadDocStepFolderWarnign`: return incomeProtectionClaimsUploadDocStepFolderWarnign;
    case `incomeProtectionClaimsUploadHelpCTATitle`: return incomeProtectionClaimsUploadHelpCTATitle;
    case `incomeProtectionClaimsUploadHelpContentContentA`: return incomeProtectionClaimsUploadHelpContentContentA;
    case `incomeProtectionClaimsUploadHelpContentContentB`: return incomeProtectionClaimsUploadHelpContentContentB;
    case `incomeProtectionClaimsUploadHelpContentTitleA`: return incomeProtectionClaimsUploadHelpContentTitleA;
    case `incomeProtectionClaimsUploadHelpContentTitleB`: return incomeProtectionClaimsUploadHelpContentTitleB;
    case `incomeProtectionConnectYourBankAccountCTATitle`: return incomeProtectionConnectYourBankAccountCTATitle;
    case `incomeProtectionConnectYourBankAccountDepositDestinationContent`: return incomeProtectionConnectYourBankAccountDepositDestinationContent;
    case `incomeProtectionConnectYourBankAccountDepositDestinationTitle`: return incomeProtectionConnectYourBankAccountDepositDestinationTitle;
    case `incomeProtectionConnectYourBankAccountDepositSubtitle`: return incomeProtectionConnectYourBankAccountDepositSubtitle;
    case `incomeProtectionConnectYourBankAccountPaycheckDestinationContent`: return incomeProtectionConnectYourBankAccountPaycheckDestinationContent;
    case `incomeProtectionConnectYourBankAccountPaycheckDestinationTitle`: return incomeProtectionConnectYourBankAccountPaycheckDestinationTitle;
    case `incomeProtectionConnectYourBankAccountPaycheckSubtitle`: return incomeProtectionConnectYourBankAccountPaycheckSubtitle;
    case `incomeProtectionConnectYourBankAccountTitle`: return incomeProtectionConnectYourBankAccountTitle;
    case `incomeProtectionConnectYourCardStepCTATitle`: return incomeProtectionConnectYourCardStepCTATitle;
    case `incomeProtectionConnectYourCardStepContent`: return incomeProtectionConnectYourCardStepContent;
    case `incomeProtectionConnectYourCardStepTitle`: return incomeProtectionConnectYourCardStepTitle;
    case `incomeProtectionDontQualifyErrorMessage`: return incomeProtectionDontQualifyErrorMessage;
    case `incomeProtectionDontQualifyPermanentReasonTileTitle`: return incomeProtectionDontQualifyPermanentReasonTileTitle;
    case `incomeProtectionDontQualifyTileRefunded`: return incomeProtectionDontQualifyTileRefunded;
    case `incomeProtectionDontQualifyTileSubtitle`: return incomeProtectionDontQualifyTileSubtitle;
    case `incomeProtectionDontQualifyTileTitle`: return incomeProtectionDontQualifyTileTitle;
    case `incomeProtectionDueWarningLabelContent`: return incomeProtectionDueWarningLabelContent;
    case `incomeProtectionDueWarningLabelTitle`: return incomeProtectionDueWarningLabelTitle;
    case `incomeProtectionEligibleForClaimEventTitle`: return incomeProtectionEligibleForClaimEventTitle;
    case `incomeProtectionEmployeeTypesHelpBottomLine`: return incomeProtectionEmployeeTypesHelpBottomLine;
    case `incomeProtectionEmployeeTypesHelpCTATitle`: return incomeProtectionEmployeeTypesHelpCTATitle;
    case `incomeProtectionEmployeeTypesHelpContent1Point1`: return incomeProtectionEmployeeTypesHelpContent1Point1;
    case `incomeProtectionEmployeeTypesHelpContent1Point2`: return incomeProtectionEmployeeTypesHelpContent1Point2;
    case `incomeProtectionEmployeeTypesHelpContent1Point3`: return incomeProtectionEmployeeTypesHelpContent1Point3;
    case `incomeProtectionEmployeeTypesHelpContent2Point1`: return incomeProtectionEmployeeTypesHelpContent2Point1;
    case `incomeProtectionEmployeeTypesHelpContent2Point2`: return incomeProtectionEmployeeTypesHelpContent2Point2;
    case `incomeProtectionEmployeeTypesHelpContent2Point3`: return incomeProtectionEmployeeTypesHelpContent2Point3;
    case `incomeProtectionEmployeeTypesHelpContent3Point1`: return incomeProtectionEmployeeTypesHelpContent3Point1;
    case `incomeProtectionEmployeeTypesHelpContent3Point2`: return incomeProtectionEmployeeTypesHelpContent3Point2;
    case `incomeProtectionEmployeeTypesHelpContent4Point1`: return incomeProtectionEmployeeTypesHelpContent4Point1;
    case `incomeProtectionEmployeeTypesHelpContent4Point2`: return incomeProtectionEmployeeTypesHelpContent4Point2;
    case `incomeProtectionEmployeeTypesHelpTitle1`: return incomeProtectionEmployeeTypesHelpTitle1;
    case `incomeProtectionEmployeeTypesHelpTitle2`: return incomeProtectionEmployeeTypesHelpTitle2;
    case `incomeProtectionEmployeeTypesHelpTitle3`: return incomeProtectionEmployeeTypesHelpTitle3;
    case `incomeProtectionEmployeeTypesHelpTitle4`: return incomeProtectionEmployeeTypesHelpTitle4;
    case `incomeProtectionEmploymentChangedRowTitle`: return incomeProtectionEmploymentChangedRowTitle;
    case `incomeProtectionEnrolledEventTitle`: return incomeProtectionEnrolledEventTitle;
    case `incomeProtectionFirstClaimSubmittedFinePrint`: return incomeProtectionFirstClaimSubmittedFinePrint;
    case `incomeProtectionFreeCheckoutCTA`: return incomeProtectionFreeCheckoutCTA;
    case `incomeProtectionHistoryBenefitPayoutFailedEvent`: return incomeProtectionHistoryBenefitPayoutFailedEvent;
    case `incomeProtectionHistoryClaimRejectedEvent`: return incomeProtectionHistoryClaimRejectedEvent;
    case `incomeProtectionHistoryClaimSubmittedEvent`: return incomeProtectionHistoryClaimSubmittedEvent;
    case `incomeProtectionHistoryDetailEnrollmentPeriod`: return incomeProtectionHistoryDetailEnrollmentPeriod;
    case `incomeProtectionHistoryDetailItemAccount`: return incomeProtectionHistoryDetailItemAccount;
    case `incomeProtectionHistoryDetailItemBenefitAmount`: return incomeProtectionHistoryDetailItemBenefitAmount;
    case `incomeProtectionHistoryDetailItemBenefitPayoutInfo`: return incomeProtectionHistoryDetailItemBenefitPayoutInfo;
    case `incomeProtectionHistoryDetailItemClaimAmount`: return incomeProtectionHistoryDetailItemClaimAmount;
    case `incomeProtectionHistoryDetailItemClaimDeniedInfo`: return incomeProtectionHistoryDetailItemClaimDeniedInfo;
    case `incomeProtectionHistoryDetailItemClaimSubmittedInfo`: return incomeProtectionHistoryDetailItemClaimSubmittedInfo;
    case `incomeProtectionHistoryDetailItemDate`: return incomeProtectionHistoryDetailItemDate;
    case `incomeProtectionHistoryDetailItemDateApproved`: return incomeProtectionHistoryDetailItemDateApproved;
    case `incomeProtectionHistoryDetailItemDateSubmitted`: return incomeProtectionHistoryDetailItemDateSubmitted;
    case `incomeProtectionHistoryDetailItemEligibilityBeginsInfo`: return incomeProtectionHistoryDetailItemEligibilityBeginsInfo;
    case `incomeProtectionHistoryDetailItemEligibilityToSubmitInfo`: return incomeProtectionHistoryDetailItemEligibilityToSubmitInfo;
    case `incomeProtectionHistoryDetailItemMonty`: return incomeProtectionHistoryDetailItemMonty;
    case `incomeProtectionHistoryDetailItemPaymentAmount`: return incomeProtectionHistoryDetailItemPaymentAmount;
    case `incomeProtectionHistoryDetailItemPolicyCanceledInfo`: return incomeProtectionHistoryDetailItemPolicyCanceledInfo;
    case `incomeProtectionHistoryDetailItemPremiumPaymentInfo`: return incomeProtectionHistoryDetailItemPremiumPaymentInfo;
    case `incomeProtectionHistoryDetailItemStatus`: return incomeProtectionHistoryDetailItemStatus;
    case `incomeProtectionHistoryDetailItemStatusComplete`: return incomeProtectionHistoryDetailItemStatusComplete;
    case `incomeProtectionHistoryDetailItemStatusDisputed`: return incomeProtectionHistoryDetailItemStatusDisputed;
    case `incomeProtectionHistoryDetailItemStatusFailed`: return incomeProtectionHistoryDetailItemStatusFailed;
    case `incomeProtectionHistoryDetailItemStatusFreeMonth`: return incomeProtectionHistoryDetailItemStatusFreeMonth;
    case `incomeProtectionHistoryDetailItemStatusPending`: return incomeProtectionHistoryDetailItemStatusPending;
    case `incomeProtectionHistoryDetailItemStatusRefunded`: return incomeProtectionHistoryDetailItemStatusRefunded;
    case `incomeProtectionHistoryDetailScreenTitle`: return incomeProtectionHistoryDetailScreenTitle;
    case `incomeProtectionHistoryEligibleForClaimAtFutureDateEvent`: return incomeProtectionHistoryEligibleForClaimAtFutureDateEvent;
    case `incomeProtectionHistoryEligibleForClaimEvent`: return incomeProtectionHistoryEligibleForClaimEvent;
    case `incomeProtectionHistoryPolicyTitle`: return incomeProtectionHistoryPolicyTitle;
    case `incomeProtectionHistoryPremiumEnrollmentPeriod`: return incomeProtectionHistoryPremiumEnrollmentPeriod;
    case `incomeProtectionHistorySectionTitle`: return incomeProtectionHistorySectionTitle;
    case `incomeProtectionHistorySwitchTitle`: return incomeProtectionHistorySwitchTitle;
    case `incomeProtectionHowItWorksBack`: return incomeProtectionHowItWorksBack;
    case `incomeProtectionHowItWorksContentFive`: return incomeProtectionHowItWorksContentFive;
    case `incomeProtectionHowItWorksContentFour`: return incomeProtectionHowItWorksContentFour;
    case `incomeProtectionHowItWorksContentOne`: return incomeProtectionHowItWorksContentOne;
    case `incomeProtectionHowItWorksContentThree`: return incomeProtectionHowItWorksContentThree;
    case `incomeProtectionHowItWorksContentTwo`: return incomeProtectionHowItWorksContentTwo;
    case `incomeProtectionHowItWorksContinue`: return incomeProtectionHowItWorksContinue;
    case `incomeProtectionHowItWorksFinePrintOne`: return incomeProtectionHowItWorksFinePrintOne;
    case `incomeProtectionHowItWorksFinePrintTwo`: return incomeProtectionHowItWorksFinePrintTwo;
    case `incomeProtectionHowItWorksSubitle1`: return incomeProtectionHowItWorksSubitle1;
    case `incomeProtectionHowItWorksSubtitle2`: return incomeProtectionHowItWorksSubtitle2;
    case `incomeProtectionHowItWorksSubtitle3`: return incomeProtectionHowItWorksSubtitle3;
    case `incomeProtectionHowItWorksSubtitle4`: return incomeProtectionHowItWorksSubtitle4;
    case `incomeProtectionHowItWorksTitle1`: return incomeProtectionHowItWorksTitle1;
    case `incomeProtectionHowItWorksTitle2`: return incomeProtectionHowItWorksTitle2;
    case `incomeProtectionHowItWorksTitle3`: return incomeProtectionHowItWorksTitle3;
    case `incomeProtectionHowItWorksTitle4`: return incomeProtectionHowItWorksTitle4;
    case `incomeProtectionHowItWorksTitleFive`: return incomeProtectionHowItWorksTitleFive;
    case `incomeProtectionHowItWorksTitleFour`: return incomeProtectionHowItWorksTitleFour;
    case `incomeProtectionHowItWorksTitleOne`: return incomeProtectionHowItWorksTitleOne;
    case `incomeProtectionHowItWorksTitleThree`: return incomeProtectionHowItWorksTitleThree;
    case `incomeProtectionHowItWorksTitleTwo`: return incomeProtectionHowItWorksTitleTwo;
    case `incomeProtectionIneligibleTileRefunded`: return incomeProtectionIneligibleTileRefunded;
    case `incomeProtectionIntroCTA`: return incomeProtectionIntroCTA;
    case `incomeProtectionIntroScreenBoxA`: return incomeProtectionIntroScreenBoxA;
    case `incomeProtectionIntroScreenBoxB`: return incomeProtectionIntroScreenBoxB;
    case `incomeProtectionIntroScreenBoxC`: return incomeProtectionIntroScreenBoxC;
    case `incomeProtectionIntroScreenBoxD`: return incomeProtectionIntroScreenBoxD;
    case `incomeProtectionIntroScreenCTATitle`: return incomeProtectionIntroScreenCTATitle;
    case `incomeProtectionIntroScreenSubtitle`: return incomeProtectionIntroScreenSubtitle;
    case `incomeProtectionIntroScreenTitle`: return incomeProtectionIntroScreenTitle;
    case `incomeProtectionIntroSubtitle`: return incomeProtectionIntroSubtitle;
    case `incomeProtectionIntroTitle`: return incomeProtectionIntroTitle;
    case `incomeProtectionMakeAPaymentCTATitle`: return incomeProtectionMakeAPaymentCTATitle;
    case `incomeProtectionMakeAPaymentLoaderTitle`: return incomeProtectionMakeAPaymentLoaderTitle;
    case `incomeProtectionMakeAPaymentLockedMessage`: return incomeProtectionMakeAPaymentLockedMessage;
    case `incomeProtectionMakeAPaymentPremiumRow`: return incomeProtectionMakeAPaymentPremiumRow;
    case `incomeProtectionMakeAPaymentSuccessClose`: return incomeProtectionMakeAPaymentSuccessClose;
    case `incomeProtectionMakeAPaymentSuccessContent`: return incomeProtectionMakeAPaymentSuccessContent;
    case `incomeProtectionMakeAPaymentSuccessTitle`: return incomeProtectionMakeAPaymentSuccessTitle;
    case `incomeProtectionMakeAPaymentTitle`: return incomeProtectionMakeAPaymentTitle;
    case `incomeProtectionMakeAPaymentTransferFromAddPaymentMethod`: return incomeProtectionMakeAPaymentTransferFromAddPaymentMethod;
    case `incomeProtectionMakeAPaymentTransferFromRow`: return incomeProtectionMakeAPaymentTransferFromRow;
    case `incomeProtectionMultiplePoliciesScreenItemTitle`: return incomeProtectionMultiplePoliciesScreenItemTitle;
    case `incomeProtectionMultiplePoliciesScreenItemTitlePresent`: return incomeProtectionMultiplePoliciesScreenItemTitlePresent;
    case `incomeProtectionMultiplePoliciesScreenNextCTA`: return incomeProtectionMultiplePoliciesScreenNextCTA;
    case `incomeProtectionMultiplePoliciesScreenQuestion`: return incomeProtectionMultiplePoliciesScreenQuestion;
    case `incomeProtectionMultiplePoliciesScreenTitle`: return incomeProtectionMultiplePoliciesScreenTitle;
    case `incomeProtectionNewFeatureBanner`: return incomeProtectionNewFeatureBanner;
    case `incomeProtectionNonEligibleForReapplyAlarm`: return incomeProtectionNonEligibleForReapplyAlarm;
    case `incomeProtectionNotAvailableMessage`: return incomeProtectionNotAvailableMessage;
    case `incomeProtectionOnboardingBubbleContent`: return incomeProtectionOnboardingBubbleContent;
    case `incomeProtectionOnboardingBubbleTitle`: return incomeProtectionOnboardingBubbleTitle;
    case `incomeProtectionOnboardingCTA`: return incomeProtectionOnboardingCTA;
    case `incomeProtectionOnboardingFinePrint`: return incomeProtectionOnboardingFinePrint;
    case `incomeProtectionOnboardingFirstMonthFree`: return incomeProtectionOnboardingFirstMonthFree;
    case `incomeProtectionOnboardingLimitedTimeOnly`: return incomeProtectionOnboardingLimitedTimeOnly;
    case `incomeProtectionOnboardingTitle`: return incomeProtectionOnboardingTitle;
    case `incomeProtectionOverdueTilePaymentDue`: return incomeProtectionOverdueTilePaymentDue;
    case `incomeProtectionPayAcceptTermsLinkAlgoQueryTermsAttribution`: return incomeProtectionPayAcceptTermsLinkAlgoQueryTermsAttribution;
    case `incomeProtectionPayTerms`: return incomeProtectionPayTerms;
    case `incomeProtectionPayTermsTermsLinkAlgoQuery`: return incomeProtectionPayTermsTermsLinkAlgoQuery;
    case `incomeProtectionPayoutStepAddDebitCardCTA`: return incomeProtectionPayoutStepAddDebitCardCTA;
    case `incomeProtectionPayoutStepFastDelivery`: return incomeProtectionPayoutStepFastDelivery;
    case `incomeProtectionPayoutStepFastDeliveryDescription`: return incomeProtectionPayoutStepFastDeliveryDescription;
    case `incomeProtectionPayoutStepFastDeliveryFree`: return incomeProtectionPayoutStepFastDeliveryFree;
    case `incomeProtectionPayoutStepFastDeliveryFreeStrike`: return incomeProtectionPayoutStepFastDeliveryFreeStrike;
    case `incomeProtectionPayoutStepFastDeliveryTitle`: return incomeProtectionPayoutStepFastDeliveryTitle;
    case `incomeProtectionPayoutStepSkipWithGrid`: return incomeProtectionPayoutStepSkipWithGrid;
    case `incomeProtectionPayoutStepStandardDelivery24`: return incomeProtectionPayoutStepStandardDelivery24;
    case `incomeProtectionPayoutStepStandardDelivery24Strike`: return incomeProtectionPayoutStepStandardDelivery24Strike;
    case `incomeProtectionPolicyCanceledContent`: return incomeProtectionPolicyCanceledContent;
    case `incomeProtectionPolicyCanceledEventTitle`: return incomeProtectionPolicyCanceledEventTitle;
    case `incomeProtectionPolicyCanceledTile`: return incomeProtectionPolicyCanceledTile;
    case `incomeProtectionPolicyClaimRejectedEventTitle`: return incomeProtectionPolicyClaimRejectedEventTitle;
    case `incomeProtectionPolicyEligibleForClaimAtFutureDateEventTitle`: return incomeProtectionPolicyEligibleForClaimAtFutureDateEventTitle;
    case `incomeProtectionPolicyHistoryCTATitle`: return incomeProtectionPolicyHistoryCTATitle;
    case `incomeProtectionPolicyHistoryScreenEndOfRows`: return incomeProtectionPolicyHistoryScreenEndOfRows;
    case `incomeProtectionPolicyHistoryScreenNoRows`: return incomeProtectionPolicyHistoryScreenNoRows;
    case `incomeProtectionPolicyHistoryScreenTitle`: return incomeProtectionPolicyHistoryScreenTitle;
    case `incomeProtectionPolicyHistoryTestFixtureFirstPolicyTitle`: return incomeProtectionPolicyHistoryTestFixtureFirstPolicyTitle;
    case `incomeProtectionPolicyHistoryTestFixtureSecondPolicyTitle`: return incomeProtectionPolicyHistoryTestFixtureSecondPolicyTitle;
    case `incomeProtectionPolicyHistoryTileSubtitle`: return incomeProtectionPolicyHistoryTileSubtitle;
    case `incomeProtectionPolicyHistoryTileTitle`: return incomeProtectionPolicyHistoryTileTitle;
    case `incomeProtectionPolicyPremiumPaymentEventTitle`: return incomeProtectionPolicyPremiumPaymentEventTitle;
    case `incomeProtectionQualifyTitle`: return incomeProtectionQualifyTitle;
    case `incomeProtectionReApplyTileCTATitle`: return incomeProtectionReApplyTileCTATitle;
    case `incomeProtectionReactivatedPopupTitle`: return incomeProtectionReactivatedPopupTitle;
    case `incomeProtectionSecondClaimSubmittedFinePrint`: return incomeProtectionSecondClaimSubmittedFinePrint;
    case `incomeProtectionSecondClaimWorkflowIntroStepSubtitle`: return incomeProtectionSecondClaimWorkflowIntroStepSubtitle;
    case `incomeProtectionSecondClaimWorkflowIntroStepTitle`: return incomeProtectionSecondClaimWorkflowIntroStepTitle;
    case `incomeProtectionSignatureClaimStepCTATitle`: return incomeProtectionSignatureClaimStepCTATitle;
    case `incomeProtectionSignatureClaimStepClearCTATitle`: return incomeProtectionSignatureClaimStepClearCTATitle;
    case `incomeProtectionSignatureClaimStepContent`: return incomeProtectionSignatureClaimStepContent;
    case `incomeProtectionSignatureClaimStepSecondHeader`: return incomeProtectionSignatureClaimStepSecondHeader;
    case `incomeProtectionStatusTileClaimStatusRowTitle`: return incomeProtectionStatusTileClaimStatusRowTitle;
    case `incomeProtectionSubmitAClaimBlockedUntilMessage`: return incomeProtectionSubmitAClaimBlockedUntilMessage;
    case `incomeProtectionSubmitAClaimLockedMessage`: return incomeProtectionSubmitAClaimLockedMessage;
    case `incomeProtectionSuccessScreenCTATitle`: return incomeProtectionSuccessScreenCTATitle;
    case `incomeProtectionSuccessScreenMessage`: return incomeProtectionSuccessScreenMessage;
    case `incomeProtectionTabDashboardOnboardingApplyingCTATitle`: return incomeProtectionTabDashboardOnboardingApplyingCTATitle;
    case `incomeProtectionTabDashboardOnboardingBanner`: return incomeProtectionTabDashboardOnboardingBanner;
    case `incomeProtectionTabDashboardOnboardingCTATitle`: return incomeProtectionTabDashboardOnboardingCTATitle;
    case `incomeProtectionTabDashboardOnboardingLimitedOffer`: return incomeProtectionTabDashboardOnboardingLimitedOffer;
    case `incomeProtectionTabDashboardOnboardingPricing`: return incomeProtectionTabDashboardOnboardingPricing;
    case `incomeProtectionTabDashboardOnboardingSubtitle`: return incomeProtectionTabDashboardOnboardingSubtitle;
    case `incomeProtectionTabDashboardOnboardingTitle`: return incomeProtectionTabDashboardOnboardingTitle;
    case `incomeProtectionTabDashboardTitle`: return incomeProtectionTabDashboardTitle;
    case `incomeProtectionTabTitle`: return incomeProtectionTabTitle;
    case `incomeProtectionTermsOfService`: return incomeProtectionTermsOfService;
    case `incomeProtectionTermsOfService2`: return incomeProtectionTermsOfService2;
    case `incomeProtectionTermsOfServiceCTA`: return incomeProtectionTermsOfServiceCTA;
    case `incomeProtectionTermsOfServiceLinkedText`: return incomeProtectionTermsOfServiceLinkedText;
    case `incomeProtectionTermsOfServiceTitle`: return incomeProtectionTermsOfServiceTitle;
    case `incomeProtectionTermsOfServiceURL`: return incomeProtectionTermsOfServiceURL;
    case `incomeProtectionTileCTA`: return incomeProtectionTileCTA;
    case `incomeProtectionTileMakeAPaymentTitle`: return incomeProtectionTileMakeAPaymentTitle;
    case `incomeProtectionTileSubmitAClaimTitle`: return incomeProtectionTileSubmitAClaimTitle;
    case `incomeProtectionTileTitle`: return incomeProtectionTileTitle;
    case `incomeProtectionTileTitleAlt1`: return incomeProtectionTileTitleAlt1;
    case `incomeProtectionTileTitleAlt2`: return incomeProtectionTileTitleAlt2;
    case `incomeProtectionUploadDocCTATitle`: return incomeProtectionUploadDocCTATitle;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent1Intro`: return incomeProtectionUploadDocsApprovalLetterHelpContent1Intro;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent1Point1`: return incomeProtectionUploadDocsApprovalLetterHelpContent1Point1;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent1Point2`: return incomeProtectionUploadDocsApprovalLetterHelpContent1Point2;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent1Point3`: return incomeProtectionUploadDocsApprovalLetterHelpContent1Point3;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent2Intro`: return incomeProtectionUploadDocsApprovalLetterHelpContent2Intro;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent2IntroLink`: return incomeProtectionUploadDocsApprovalLetterHelpContent2IntroLink;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent3Point1`: return incomeProtectionUploadDocsApprovalLetterHelpContent3Point1;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent3Point2`: return incomeProtectionUploadDocsApprovalLetterHelpContent3Point2;
    case `incomeProtectionUploadDocsApprovalLetterHelpContent3Point3`: return incomeProtectionUploadDocsApprovalLetterHelpContent3Point3;
    case `incomeProtectionUploadDocsApprovalLetterHelpTitle1`: return incomeProtectionUploadDocsApprovalLetterHelpTitle1;
    case `incomeProtectionUploadDocsApprovalLetterHelpTitle2`: return incomeProtectionUploadDocsApprovalLetterHelpTitle2;
    case `incomeProtectionUploadDocsApprovalLetterHelpTitle3`: return incomeProtectionUploadDocsApprovalLetterHelpTitle3;
    case `incomeProtectionUploadDocsHelpContent1Intro`: return incomeProtectionUploadDocsHelpContent1Intro;
    case `incomeProtectionUploadDocsHelpContent1Point1`: return incomeProtectionUploadDocsHelpContent1Point1;
    case `incomeProtectionUploadDocsHelpContent1Point2`: return incomeProtectionUploadDocsHelpContent1Point2;
    case `incomeProtectionUploadDocsHelpContent1Point3`: return incomeProtectionUploadDocsHelpContent1Point3;
    case `incomeProtectionUploadDocsHelpContent1Point4`: return incomeProtectionUploadDocsHelpContent1Point4;
    case `incomeProtectionUploadDocsHelpContent1Point5`: return incomeProtectionUploadDocsHelpContent1Point5;
    case `incomeProtectionUploadDocsHelpContent1Point6`: return incomeProtectionUploadDocsHelpContent1Point6;
    case `incomeProtectionUploadDocsHelpContent2Point1`: return incomeProtectionUploadDocsHelpContent2Point1;
    case `incomeProtectionUploadDocsHelpTitle1`: return incomeProtectionUploadDocsHelpTitle1;
    case `incomeProtectionUploadDocsHelpTitle2`: return incomeProtectionUploadDocsHelpTitle2;
    case `incomeProtectionUploadDocsTerminationLetterHelpContent1Intro`: return incomeProtectionUploadDocsTerminationLetterHelpContent1Intro;
    case `incomeProtectionUploadDocsTerminationLetterHelpContent1Point1`: return incomeProtectionUploadDocsTerminationLetterHelpContent1Point1;
    case `incomeProtectionUploadDocsTerminationLetterHelpContent1Point2`: return incomeProtectionUploadDocsTerminationLetterHelpContent1Point2;
    case `incomeProtectionUploadDocsTerminationLetterHelpContent1Point3`: return incomeProtectionUploadDocsTerminationLetterHelpContent1Point3;
    case `incomeProtectionUploadDocsTerminationLetterHelpContent1Point4`: return incomeProtectionUploadDocsTerminationLetterHelpContent1Point4;
    case `incomeProtectionUploadDocsTerminationLetterHelpContent2Intro`: return incomeProtectionUploadDocsTerminationLetterHelpContent2Intro;
    case `incomeProtectionUploadDocsTerminationLetterHelpTitle1`: return incomeProtectionUploadDocsTerminationLetterHelpTitle1;
    case `incomeProtectionUploadDocsTerminationLetterHelpTitle2`: return incomeProtectionUploadDocsTerminationLetterHelpTitle2;
    case `ineligibleCreditLineUpgradeBulletListHeader`: return ineligibleCreditLineUpgradeBulletListHeader;
    case `ineligibleCreditLineUpgradeBulletListItem1`: return ineligibleCreditLineUpgradeBulletListItem1;
    case `ineligibleCreditLineUpgradeBulletListItem2`: return ineligibleCreditLineUpgradeBulletListItem2;
    case `ineligibleCreditLineUpgradeBulletListItem3`: return ineligibleCreditLineUpgradeBulletListItem3;
    case `ineligibleCreditLineUpgradeFooter`: return ineligibleCreditLineUpgradeFooter;
    case `ineligibleCreditLineUpgradeSubtitle`: return ineligibleCreditLineUpgradeSubtitle;
    case `ineligibleCreditLineUpgradeTitle`: return ineligibleCreditLineUpgradeTitle;
    case `insuranceTeasingScreenCTATitle`: return insuranceTeasingScreenCTATitle;
    case `insuranceTeasingScreenContent`: return insuranceTeasingScreenContent;
    case `insuranceTeasingScreenTitle`: return insuranceTeasingScreenTitle;
    case `insuranceTileCTATitle`: return insuranceTileCTATitle;
    case `insuranceTileSectionTitle`: return insuranceTileSectionTitle;
    case `insuranceTileSubtitle`: return insuranceTileSubtitle;
    case `insuranceTileTitle`: return insuranceTileTitle;
    case `intervalDay`: return intervalDay;
    case `intervalDays`: return intervalDays;
    case `intervalMonth`: return intervalMonth;
    case `intervalOnce`: return intervalOnce;
    case `intervalWeek`: return intervalWeek;
    case `intervalYear`: return intervalYear;
    case `launchLogoutButton`: return launchLogoutButton;
    case `launchRetryButton`: return launchRetryButton;
    case `legalSupportMenuRowTitleSynapseCardholderAgreement`: return legalSupportMenuRowTitleSynapseCardholderAgreement;
    case `legalSupportMenuRowTitleSynapseConsumerLoanAgreement`: return legalSupportMenuRowTitleSynapseConsumerLoanAgreement;
    case `legalSupportMenuRowTitleSynapseCreditLoanAgreement`: return legalSupportMenuRowTitleSynapseCreditLoanAgreement;
    case `legalSupportMenuRowTitleSynapseDepositAgreement`: return legalSupportMenuRowTitleSynapseDepositAgreement;
    case `legalSupportMenuRowTitleTermsOfService`: return legalSupportMenuRowTitleTermsOfService;
    case `legalSupportMenuTitle`: return legalSupportMenuTitle;
    case `linkedAccountsAdvanceTileChangeBankCTA`: return linkedAccountsAdvanceTileChangeBankCTA;
    case `linkedAccountsAdvanceTileRelinkBankCTA`: return linkedAccountsAdvanceTileRelinkBankCTA;
    case `linkedAccountsAdvanceTileRelinkCardCTA`: return linkedAccountsAdvanceTileRelinkCardCTA;
    case `linkedAccountsAdvanceTileTitle`: return linkedAccountsAdvanceTileTitle;
    case `linkedAccountsCardNumber`: return linkedAccountsCardNumber;
    case `linkedAccountsChangeAccountCTA`: return linkedAccountsChangeAccountCTA;
    case `linkedAccountsDefaultRow`: return linkedAccountsDefaultRow;
    case `linkedAccountsFixRequired`: return linkedAccountsFixRequired;
    case `linkedAccountsOutstandingBalanceCTA`: return linkedAccountsOutstandingBalanceCTA;
    case `linkedAccountsOutstandingBalanceSubtitle`: return linkedAccountsOutstandingBalanceSubtitle;
    case `linkedAccountsOutstandingBalanceTitle`: return linkedAccountsOutstandingBalanceTitle;
    case `linkedAccountsPaymentCardsTitle`: return linkedAccountsPaymentCardsTitle;
    case `linkedAccountsRelinkCTA`: return linkedAccountsRelinkCTA;
    case `localauthButtonEnable`: return localauthButtonEnable;
    case `localauthButtonNotnow`: return localauthButtonNotnow;
    case `localauthInstructions`: return localauthInstructions;
    case `loginEmailButton`: return loginEmailButton;
    case `loginEmailInstructions`: return loginEmailInstructions;
    case `loginPasswordButton`: return loginPasswordButton;
    case `loginPasswordForgotButton`: return loginPasswordForgotButton;
    case `loginPasswordInstructions`: return loginPasswordInstructions;
    case `loginPasswordPrefill`: return loginPasswordPrefill;
    case `loginResetPasswordButton`: return loginResetPasswordButton;
    case `loginResetPasswordMessage`: return loginResetPasswordMessage;
    case `loginResetPasswordTitle`: return loginResetPasswordTitle;
    case `loginResetPasswordWelcomeTitle`: return loginResetPasswordWelcomeTitle;
    case `loginScreenTitle`: return loginScreenTitle;
    case `meetCashboostBulletsGetMoreCash`: return meetCashboostBulletsGetMoreCash;
    case `meetCashboostBulletsLevels`: return meetCashboostBulletsLevels;
    case `meetCashboostBulletsNoCreditCheck`: return meetCashboostBulletsNoCreditCheck;
    case `meetCashboostBulletsNoHiddenFees`: return meetCashboostBulletsNoHiddenFees;
    case `meetCashboostBulletsUnlimitedAdvancesTitle`: return meetCashboostBulletsUnlimitedAdvancesTitle;
    case `meetCashboostCTATitle`: return meetCashboostCTATitle;
    case `meetCashboostGridPlus`: return meetCashboostGridPlus;
    case `meetCashboostLevelsCTA`: return meetCashboostLevelsCTA;
    case `meetCashboostSubtitle`: return meetCashboostSubtitle;
    case `meetCashboostTitle`: return meetCashboostTitle;
    case `multidigitPhoneNumberErrorInvalidCVV`: return multidigitPhoneNumberErrorInvalidCVV;
    case `multidigitPhoneNumberErrorInvalidLastFour`: return multidigitPhoneNumberErrorInvalidLastFour;
    case `multidigitPhoneNumberErrorInvalidPIN`: return multidigitPhoneNumberErrorInvalidPIN;
    case `multidigitPhoneNumberErrorInvalidPhoneNumber`: return multidigitPhoneNumberErrorInvalidPhoneNumber;
    case `multidigitPhoneNumberErrorMismatchedPin`: return multidigitPhoneNumberErrorMismatchedPin;
    case `multidigitSSNErrorInvalidSSN`: return multidigitSSNErrorInvalidSSN;
    case `offboardingActionNotSupportedMessage`: return offboardingActionNotSupportedMessage;
    case `offboardingActionNotSupportedTitle`: return offboardingActionNotSupportedTitle;
    case `offboardingLeaveAReviewButton`: return offboardingLeaveAReviewButton;
    case `offboardingNeedMoreHelpButton`: return offboardingNeedMoreHelpButton;
    case `onboardingHomeBoostCheckoutButton`: return onboardingHomeBoostCheckoutButton;
    case `onboardingHomeBoostCheckoutButtonSeeDetails`: return onboardingHomeBoostCheckoutButtonSeeDetails;
    case `onboardingHomeBoostCheckoutMessage`: return onboardingHomeBoostCheckoutMessage;
    case `onboardingHomeBoostCheckoutTitle`: return onboardingHomeBoostCheckoutTitle;
    case `onboardingHomeBoostHeading`: return onboardingHomeBoostHeading;
    case `onboardingHomeFooterUnsupportedContactSupportButtonText`: return onboardingHomeFooterUnsupportedContactSupportButtonText;
    case `onboardingHomeFooterUnsupportedFallbackHeader`: return onboardingHomeFooterUnsupportedFallbackHeader;
    case `onboardingHomeFooterUnsupportedGetGridPlusActionButtonText`: return onboardingHomeFooterUnsupportedGetGridPlusActionButtonText;
    case `onboardingHomeFooterUnsupportedGetGridPlusDescription`: return onboardingHomeFooterUnsupportedGetGridPlusDescription;
    case `onboardingHomeFooterUnsupportedGetGridPlusHeader`: return onboardingHomeFooterUnsupportedGetGridPlusHeader;
    case `onboardingHomeFooterUnsupportedGetGridPlusInstructions`: return onboardingHomeFooterUnsupportedGetGridPlusInstructions;
    case `onboardingHomeFooterUnsupportedHeader`: return onboardingHomeFooterUnsupportedHeader;
    case `onboardingHomeFooterUnsupportedInstructions`: return onboardingHomeFooterUnsupportedInstructions;
    case `onboardingHomeFooterUnsupportedLeaveAReviewButtonText`: return onboardingHomeFooterUnsupportedLeaveAReviewButtonText;
    case `onboardingHomeFooterUnsupportedTitle`: return onboardingHomeFooterUnsupportedTitle;
    case `onboardingHomeFooterWaitingOnBoostButtonEnablePush`: return onboardingHomeFooterWaitingOnBoostButtonEnablePush;
    case `onboardingHomeFooterWaitingOnBoostHeader`: return onboardingHomeFooterWaitingOnBoostHeader;
    case `onboardingHomeFooterWaitingOnBoostHeaderWaivedFee`: return onboardingHomeFooterWaitingOnBoostHeaderWaivedFee;
    case `onboardingHomeFooterWaitingOnBoostTitle`: return onboardingHomeFooterWaitingOnBoostTitle;
    case `onboardingHomeFooterWorkflowsButtonInProgress`: return onboardingHomeFooterWorkflowsButtonInProgress;
    case `onboardingHomeFooterWorkflowsButtonReady`: return onboardingHomeFooterWorkflowsButtonReady;
    case `onboardingHomeFooterWorkflowsButtonReadyWithProgress`: return onboardingHomeFooterWorkflowsButtonReadyWithProgress;
    case `onboardingHomeFooterWorkflowsHeader`: return onboardingHomeFooterWorkflowsHeader;
    case `onboardingHomeProgressHouseholdBody`: return onboardingHomeProgressHouseholdBody;
    case `onboardingHomeProgressHouseholdHeadline`: return onboardingHomeProgressHouseholdHeadline;
    case `onboardingHomeProgressPersonalBody`: return onboardingHomeProgressPersonalBody;
    case `onboardingHomeProgressPersonalHeadline`: return onboardingHomeProgressPersonalHeadline;
    case `onboardingHomeProgressRingProgress`: return onboardingHomeProgressRingProgress;
    case `onboardingHomeWaitingOnBoostLoading`: return onboardingHomeWaitingOnBoostLoading;
    case `payBoostUnderConstructionBannerSubtitle`: return payBoostUnderConstructionBannerSubtitle;
    case `payBoostUnderConstructionBannerTitle`: return payBoostUnderConstructionBannerTitle;
    case `payBoostUnderConstructionTitle`: return payBoostUnderConstructionTitle;
    case `payEverythingBottomSheetAddPaymentMethod`: return payEverythingBottomSheetAddPaymentMethod;
    case `payEverythingBottomSheetAdvanceAmount`: return payEverythingBottomSheetAdvanceAmount;
    case `payEverythingBottomSheetAmountFallback`: return payEverythingBottomSheetAmountFallback;
    case `payEverythingBottomSheetCardTitle`: return payEverythingBottomSheetCardTitle;
    case `payEverythingBottomSheetChangePaymentMethodCTA`: return payEverythingBottomSheetChangePaymentMethodCTA;
    case `payEverythingBottomSheetCreditLineBalance`: return payEverythingBottomSheetCreditLineBalance;
    case `payEverythingBottomSheetDateTitle`: return payEverythingBottomSheetDateTitle;
    case `payEverythingBottomSheetDefaultPaymentMethod`: return payEverythingBottomSheetDefaultPaymentMethod;
    case `payEverythingBottomSheetDeliveryFee`: return payEverythingBottomSheetDeliveryFee;
    case `payEverythingBottomSheetDiscount`: return payEverythingBottomSheetDiscount;
    case `payEverythingBottomSheetMakePayment`: return payEverythingBottomSheetMakePayment;
    case `payEverythingBottomSheetNegativeBalance`: return payEverythingBottomSheetNegativeBalance;
    case `payEverythingBottomSheetPayButtonTitle`: return payEverythingBottomSheetPayButtonTitle;
    case `payEverythingBottomSheetPaymentAmount`: return payEverythingBottomSheetPaymentAmount;
    case `payEverythingBottomSheetProcessing`: return payEverythingBottomSheetProcessing;
    case `payEverythingBottomSheetRemainingBalance`: return payEverythingBottomSheetRemainingBalance;
    case `payEverythingBottomSheetSubscriptionFee`: return payEverythingBottomSheetSubscriptionFee;
    case `payEverythingBottomSheetSubscriptionFeePlural`: return payEverythingBottomSheetSubscriptionFeePlural;
    case `payEverythingBottomSheetSummary`: return payEverythingBottomSheetSummary;
    case `payEverythingBottomSheetTotalDue`: return payEverythingBottomSheetTotalDue;
    case `payEverythingBottomSheetTransferFrom`: return payEverythingBottomSheetTransferFrom;
    case `payEverythingCashboostTileCTATitle`: return payEverythingCashboostTileCTATitle;
    case `payEverythingCashboostTileTitle`: return payEverythingCashboostTileTitle;
    case `payEverythingChooseAmountChooseAmount`: return payEverythingChooseAmountChooseAmount;
    case `payEverythingChooseAmountContinue`: return payEverythingChooseAmountContinue;
    case `payEverythingChooseAmountMaximumPaymenteError`: return payEverythingChooseAmountMaximumPaymenteError;
    case `payEverythingChooseAmountMinimumPaymenteError`: return payEverythingChooseAmountMinimumPaymenteError;
    case `payEverythingChooseAmountTotalDue`: return payEverythingChooseAmountTotalDue;
    case `payEverythingInvoiceFailedMessage`: return payEverythingInvoiceFailedMessage;
    case `payEverythingInvoiceFailedTitle`: return payEverythingInvoiceFailedTitle;
    case `payEverythingInvoiceMismatchMessage`: return payEverythingInvoiceMismatchMessage;
    case `payEverythingInvoiceMismatchTitle`: return payEverythingInvoiceMismatchTitle;
    case `payEverythingNegativeBalanceAlertACTA`: return payEverythingNegativeBalanceAlertACTA;
    case `payEverythingNegativeBalanceAlertASubtitle`: return payEverythingNegativeBalanceAlertASubtitle;
    case `payEverythingNegativeBalanceAlertATitle`: return payEverythingNegativeBalanceAlertATitle;
    case `payEverythingNegativeBalanceAlertBCTA`: return payEverythingNegativeBalanceAlertBCTA;
    case `payEverythingNegativeBalanceAlertBSubtitle`: return payEverythingNegativeBalanceAlertBSubtitle;
    case `payEverythingNegativeBalanceAlertBTitle`: return payEverythingNegativeBalanceAlertBTitle;
    case `payEverythingNegativeBalanceAlertCCTA`: return payEverythingNegativeBalanceAlertCCTA;
    case `payEverythingNegativeBalanceAlertCSubtitle`: return payEverythingNegativeBalanceAlertCSubtitle;
    case `payEverythingNegativeBalanceAlertCTitle`: return payEverythingNegativeBalanceAlertCTitle;
    case `payEverythingPaymentFailedBadCard`: return payEverythingPaymentFailedBadCard;
    case `payEverythingPaymentFailedDeclineAccountClosed`: return payEverythingPaymentFailedDeclineAccountClosed;
    case `payEverythingPaymentFailedDeclineInsufficientFunds`: return payEverythingPaymentFailedDeclineInsufficientFunds;
    case `payEverythingPaymentFailedDeclineInvalidEntries`: return payEverythingPaymentFailedDeclineInvalidEntries;
    case `payEverythingPaymentFailedGenericDecline`: return payEverythingPaymentFailedGenericDecline;
    case `payEverythingPaymentFailedGenericSystemError`: return payEverythingPaymentFailedGenericSystemError;
    case `payEverythingPaymentFailedNotSetup`: return payEverythingPaymentFailedNotSetup;
    case `payEverythingPaymentKYCIssue`: return payEverythingPaymentKYCIssue;
    case `payEverythingPaymentsAdvanceAmount`: return payEverythingPaymentsAdvanceAmount;
    case `payEverythingPaymentsAdvanceDeliveryFee`: return payEverythingPaymentsAdvanceDeliveryFee;
    case `payEverythingPaymentsCreditLineBalance`: return payEverythingPaymentsCreditLineBalance;
    case `payEverythingPaymentsGridCardFee`: return payEverythingPaymentsGridCardFee;
    case `payEverythingPaymentsHistoryDetailsScreenAccount`: return payEverythingPaymentsHistoryDetailsScreenAccount;
    case `payEverythingPaymentsHistoryDetailsScreenDate`: return payEverythingPaymentsHistoryDetailsScreenDate;
    case `payEverythingPaymentsHistoryDetailsScreenDisputed`: return payEverythingPaymentsHistoryDetailsScreenDisputed;
    case `payEverythingPaymentsHistoryDetailsScreenPayment`: return payEverythingPaymentsHistoryDetailsScreenPayment;
    case `payEverythingPaymentsHistoryDetailsScreenPaymentDetailsAdvanceAmount`: return payEverythingPaymentsHistoryDetailsScreenPaymentDetailsAdvanceAmount;
    case `payEverythingPaymentsHistoryDetailsScreenPaymentDetailsDeliveryFee`: return payEverythingPaymentsHistoryDetailsScreenPaymentDetailsDeliveryFee;
    case `payEverythingPaymentsHistoryDetailsScreenPaymentDetailsSubscriptionFee`: return payEverythingPaymentsHistoryDetailsScreenPaymentDetailsSubscriptionFee;
    case `payEverythingPaymentsHistoryDetailsScreenPaymentDetailsTitle`: return payEverythingPaymentsHistoryDetailsScreenPaymentDetailsTitle;
    case `payEverythingPaymentsHistoryDetailsScreenPaymentDetailsTotal`: return payEverythingPaymentsHistoryDetailsScreenPaymentDetailsTotal;
    case `payEverythingPaymentsHistoryDetailsScreenPaymentProblemCTA`: return payEverythingPaymentsHistoryDetailsScreenPaymentProblemCTA;
    case `payEverythingPaymentsHistoryDetailsScreenPaymentRefund`: return payEverythingPaymentsHistoryDetailsScreenPaymentRefund;
    case `payEverythingPaymentsHistoryDetailsScreenRefund`: return payEverythingPaymentsHistoryDetailsScreenRefund;
    case `payEverythingPaymentsHistoryDetailsScreenStatus`: return payEverythingPaymentsHistoryDetailsScreenStatus;
    case `payEverythingPaymentsHistoryDetailsScreenStatusPending`: return payEverythingPaymentsHistoryDetailsScreenStatusPending;
    case `payEverythingPaymentsHistoryDetailsScreenStatusPosted`: return payEverythingPaymentsHistoryDetailsScreenStatusPosted;
    case `payEverythingPaymentsHistoryDetailsScreenType`: return payEverythingPaymentsHistoryDetailsScreenType;
    case `payEverythingPaymentsHistoryRowTitle`: return payEverythingPaymentsHistoryRowTitle;
    case `payEverythingPaymentsHistoryScreenEndOfList`: return payEverythingPaymentsHistoryScreenEndOfList;
    case `payEverythingPaymentsHistoryScreenNoPayments`: return payEverythingPaymentsHistoryScreenNoPayments;
    case `payEverythingPaymentsHistoryScreenPendingSection`: return payEverythingPaymentsHistoryScreenPendingSection;
    case `payEverythingPaymentsHistoryScreenPostedSection`: return payEverythingPaymentsHistoryScreenPostedSection;
    case `payEverythingPaymentsHistoryScreenTitle`: return payEverythingPaymentsHistoryScreenTitle;
    case `payEverythingPaymentsNegativeBalance`: return payEverythingPaymentsNegativeBalance;
    case `payEverythingPaymentsSubscriptionFee`: return payEverythingPaymentsSubscriptionFee;
    case `payEverythingPaymentsSubscriptionFeePlural`: return payEverythingPaymentsSubscriptionFeePlural;
    case `payEverythingSettingsManageSubscription`: return payEverythingSettingsManageSubscription;
    case `payEverythingSettingsManageSubscriptionActive`: return payEverythingSettingsManageSubscriptionActive;
    case `payEverythingSettingsManageSubscriptionNextPayment`: return payEverythingSettingsManageSubscriptionNextPayment;
    case `payEverythingSettingsPaymentHistory`: return payEverythingSettingsPaymentHistory;
    case `payEverythingSettingsPaymentHistoryEndOfList`: return payEverythingSettingsPaymentHistoryEndOfList;
    case `payEverythingSettingsPaymentHistoryGridBalancePayment`: return payEverythingSettingsPaymentHistoryGridBalancePayment;
    case `payEverythingSettingsPaymentHistoryNoPayments`: return payEverythingSettingsPaymentHistoryNoPayments;
    case `payEverythingSettingsPaymentHistoryPending`: return payEverythingSettingsPaymentHistoryPending;
    case `payEverythingSettingsPaymentHistoryPosted`: return payEverythingSettingsPaymentHistoryPosted;
    case `payEverythingSettingsPaymentHistoryTitle`: return payEverythingSettingsPaymentHistoryTitle;
    case `payEverythingSuccessScreenClose`: return payEverythingSuccessScreenClose;
    case `payEverythingSuccessScreenSubtitle`: return payEverythingSuccessScreenSubtitle;
    case `payEverythingSuccessScreenTitle`: return payEverythingSuccessScreenTitle;
    case `payEverythingYourSubscriptionOverdue`: return payEverythingYourSubscriptionOverdue;
    case `payboostAllDocumentsUploadedEmptyState`: return payboostAllDocumentsUploadedEmptyState;
    case `payboostAllDocumentsUploadedSubtitle`: return payboostAllDocumentsUploadedSubtitle;
    case `payboostAllDocumentsUploadedTitle`: return payboostAllDocumentsUploadedTitle;
    case `payboostBoostedCurrentTitle`: return payboostBoostedCurrentTitle;
    case `payboostBoostedLabel`: return payboostBoostedLabel;
    case `payboostBoostedLastReceivedDate`: return payboostBoostedLastReceivedDate;
    case `payboostBoostedPendingCardDate`: return payboostBoostedPendingCardDate;
    case `payboostBoostedPendingCardTitle`: return payboostBoostedPendingCardTitle;
    case `payboostBoostedPushNotificationSubtitle`: return payboostBoostedPushNotificationSubtitle;
    case `payboostBoostedPushNotificationTitle`: return payboostBoostedPushNotificationTitle;
    case `payboostBoostedSubtitle`: return payboostBoostedSubtitle;
    case `payboostCardTodoTileTitle`: return payboostCardTodoTileTitle;
    case `payboostCheckoutIntroHasCardBody`: return payboostCheckoutIntroHasCardBody;
    case `payboostCheckoutIntroHasCardCTA`: return payboostCheckoutIntroHasCardCTA;
    case `payboostCheckoutIntroHasCardTitle`: return payboostCheckoutIntroHasCardTitle;
    case `payboostCheckoutIntroNeedsCardBody`: return payboostCheckoutIntroNeedsCardBody;
    case `payboostCheckoutIntroNeedsCardCTA`: return payboostCheckoutIntroNeedsCardCTA;
    case `payboostCheckoutIntroNeedsCardTitle`: return payboostCheckoutIntroNeedsCardTitle;
    case `payboostDisqualifiedErrorMessage`: return payboostDisqualifiedErrorMessage;
    case `payboostDisqualifiedGeneralReason`: return payboostDisqualifiedGeneralReason;
    case `payboostDisqualifiedReasonEmploymentBlocked`: return payboostDisqualifiedReasonEmploymentBlocked;
    case `payboostDisqualifiedTitle`: return payboostDisqualifiedTitle;
    case `payboostOnboardingTileMessage`: return payboostOnboardingTileMessage;
    case `payboostOnboardingTileResumeCtaTitle`: return payboostOnboardingTileResumeCtaTitle;
    case `payboostOnboardingTileSetupCtaTitle`: return payboostOnboardingTileSetupCtaTitle;
    case `payboostOnboardingTileTitle`: return payboostOnboardingTileTitle;
    case `payboostPayrollConnectIntroButtonTitle`: return payboostPayrollConnectIntroButtonTitle;
    case `payboostPayrollConnectIntroFirstMarker`: return payboostPayrollConnectIntroFirstMarker;
    case `payboostPayrollConnectIntroFirstValueProp`: return payboostPayrollConnectIntroFirstValueProp;
    case `payboostPayrollConnectIntroSecondMarker`: return payboostPayrollConnectIntroSecondMarker;
    case `payboostPayrollConnectIntroSecondValueProp`: return payboostPayrollConnectIntroSecondValueProp;
    case `payboostPayrollConnectIntroThirdMarker`: return payboostPayrollConnectIntroThirdMarker;
    case `payboostPayrollConnectIntroThirdValueProp`: return payboostPayrollConnectIntroThirdValueProp;
    case `payboostPayrollConnectIntroTitle`: return payboostPayrollConnectIntroTitle;
    case `payboostPayroollConnectIntroNavBarTitle`: return payboostPayroollConnectIntroNavBarTitle;
    case `payboostReviewResultsBenefitExplanationBody`: return payboostReviewResultsBenefitExplanationBody;
    case `payboostReviewResultsButton`: return payboostReviewResultsButton;
    case `payboostReviewResultsPayoutExplanationBody`: return payboostReviewResultsPayoutExplanationBody;
    case `payboostReviewResultsPayoutExplanationTitle`: return payboostReviewResultsPayoutExplanationTitle;
    case `payboostReviewResultsTitle`: return payboostReviewResultsTitle;
    case `payboostTileBoostReadyBody`: return payboostTileBoostReadyBody;
    case `payboostTileBoostReadyButton`: return payboostTileBoostReadyButton;
    case `payboostTileBoostReadyTitle`: return payboostTileBoostReadyTitle;
    case `payboostTileContinueW4Cta`: return payboostTileContinueW4Cta;
    case `payboostTileWaitingForBoostButton`: return payboostTileWaitingForBoostButton;
    case `payboostTileWaitingForBoostEstimate`: return payboostTileWaitingForBoostEstimate;
    case `payboostTileWaitingForBoostTitle`: return payboostTileWaitingForBoostTitle;
    case `payboostUploadDocsContinueButton`: return payboostUploadDocsContinueButton;
    case `payboostUploadDocsDefaultErrorMessage`: return payboostUploadDocsDefaultErrorMessage;
    case `payboostUploadDocsDeleteConfirmButton`: return payboostUploadDocsDeleteConfirmButton;
    case `payboostUploadDocsDeleteTitle`: return payboostUploadDocsDeleteTitle;
    case `payboostUploadDocsFileSizeLimitTitle`: return payboostUploadDocsFileSizeLimitTitle;
    case `payboostUploadDocsOptionsSubtitle`: return payboostUploadDocsOptionsSubtitle;
    case `payboostUploadDocsOptionsTitle`: return payboostUploadDocsOptionsTitle;
    case `payboostUploadDocsRetryNoButton`: return payboostUploadDocsRetryNoButton;
    case `payboostUploadDocsRetryTitle`: return payboostUploadDocsRetryTitle;
    case `payboostUploadDocsRetryYesButton`: return payboostUploadDocsRetryYesButton;
    case `payboostUploadDocsSkipButton`: return payboostUploadDocsSkipButton;
    case `payboostUploadDocsSkipNoButton`: return payboostUploadDocsSkipNoButton;
    case `payboostUploadDocsSkipWarningTitle`: return payboostUploadDocsSkipWarningTitle;
    case `payboostUploadDocsSkipYesButton`: return payboostUploadDocsSkipYesButton;
    case `payboostUploadDocsStartUploadingTitle`: return payboostUploadDocsStartUploadingTitle;
    case `payboostUploadDocsTakePictureButton`: return payboostUploadDocsTakePictureButton;
    case `payboostUploadDocsUploadFilesButton`: return payboostUploadDocsUploadFilesButton;
    case `payboostUploadDocsUploadPhotosButton`: return payboostUploadDocsUploadPhotosButton;
    case `payboostUploadDocsViewAllDocsButton`: return payboostUploadDocsViewAllDocsButton;
    case `payboostW4DataCaptureCTA`: return payboostW4DataCaptureCTA;
    case `payboostW4DataCaptureFirstName`: return payboostW4DataCaptureFirstName;
    case `payboostW4DataCaptureGeneratingW4`: return payboostW4DataCaptureGeneratingW4;
    case `payboostW4DataCaptureLastName`: return payboostW4DataCaptureLastName;
    case `payboostW4DataCaptureReassurance`: return payboostW4DataCaptureReassurance;
    case `payboostW4DataCaptureSSN`: return payboostW4DataCaptureSSN;
    case `payboostW4DataCaptureSSNBottomSheetDescription`: return payboostW4DataCaptureSSNBottomSheetDescription;
    case `payboostW4DataCaptureSSNBottomSheetSubtitle`: return payboostW4DataCaptureSSNBottomSheetSubtitle;
    case `payboostW4DataCaptureSSNBottomSheetTitle`: return payboostW4DataCaptureSSNBottomSheetTitle;
    case `payboostW4DataCaptureSSNQuestion`: return payboostW4DataCaptureSSNQuestion;
    case `payboostW4DataCaptureStreetAddressCity`: return payboostW4DataCaptureStreetAddressCity;
    case `payboostW4DataCaptureStreetAddressOne`: return payboostW4DataCaptureStreetAddressOne;
    case `payboostW4DataCaptureStreetAddressState`: return payboostW4DataCaptureStreetAddressState;
    case `payboostW4DataCaptureStreetAddressTwo`: return payboostW4DataCaptureStreetAddressTwo;
    case `payboostW4DataCaptureStreetAddressZip`: return payboostW4DataCaptureStreetAddressZip;
    case `payboostW4DataCaptureTitle`: return payboostW4DataCaptureTitle;
    case `payboostW4ReviewButton`: return payboostW4ReviewButton;
    case `payboostW4ReviewMoreInfoCta`: return payboostW4ReviewMoreInfoCta;
    case `payboostW4ReviewTitle`: return payboostW4ReviewTitle;
    case `payboostW4SignatureClear`: return payboostW4SignatureClear;
    case `payboostW4SignatureDocumentCreationInProgress`: return payboostW4SignatureDocumentCreationInProgress;
    case `payboostW4SignatureHeader`: return payboostW4SignatureHeader;
    case `payboostW4SignatureSignButton`: return payboostW4SignatureSignButton;
    case `payboostW4SignatureTitle`: return payboostW4SignatureTitle;
    case `paymentHistoryRowGridPlusTitle`: return paymentHistoryRowGridPlusTitle;
    case `paymentHistoryRowOverdue`: return paymentHistoryRowOverdue;
    case `paymentHistoryRowPayNowCTA`: return paymentHistoryRowPayNowCTA;
    case `paymentHistoryRowStateOpen`: return paymentHistoryRowStateOpen;
    case `paymentHistoryRowStatePaid`: return paymentHistoryRowStatePaid;
    case `paymentHistoryRowStateUnpaid`: return paymentHistoryRowStateUnpaid;
    case `paymentMethodGridCardName`: return paymentMethodGridCardName;
    case `paymentsCardFormFieldCvcPlaceholder`: return paymentsCardFormFieldCvcPlaceholder;
    case `paymentsCardFormFieldCvcTitle`: return paymentsCardFormFieldCvcTitle;
    case `paymentsCardFormFieldExpirationPlaceholder`: return paymentsCardFormFieldExpirationPlaceholder;
    case `paymentsCardFormFieldExpirationTitle`: return paymentsCardFormFieldExpirationTitle;
    case `paymentsCardFormFieldNumberPlaceholder`: return paymentsCardFormFieldNumberPlaceholder;
    case `paymentsCardFormFieldNumberTitle`: return paymentsCardFormFieldNumberTitle;
    case `paymentsCardFormFieldZipPlaceholder`: return paymentsCardFormFieldZipPlaceholder;
    case `paymentsCardFormFieldZipTitle`: return paymentsCardFormFieldZipTitle;
    case `paymentsMethodsAddButtonSave`: return paymentsMethodsAddButtonSave;
    case `paymentsMethodsAddTitle`: return paymentsMethodsAddTitle;
    case `paymentsMethodsDeleteMethodAlertActionCancel`: return paymentsMethodsDeleteMethodAlertActionCancel;
    case `paymentsMethodsDeleteMethodAlertActionDelete`: return paymentsMethodsDeleteMethodAlertActionDelete;
    case `paymentsMethodsDeleteMethodAlertLoadingText`: return paymentsMethodsDeleteMethodAlertLoadingText;
    case `paymentsMethodsDeleteMethodAlertMessage`: return paymentsMethodsDeleteMethodAlertMessage;
    case `paymentsMethodsDeleteMethodAlertTitle`: return paymentsMethodsDeleteMethodAlertTitle;
    case `paymentsMethodsListScreenAddCard`: return paymentsMethodsListScreenAddCard;
    case `paymentsMethodsListScreenAddDebitCard`: return paymentsMethodsListScreenAddDebitCard;
    case `paymentsMethodsListScreenSavedCards`: return paymentsMethodsListScreenSavedCards;
    case `paymentsMethodsListScreenTitle`: return paymentsMethodsListScreenTitle;
    case `paymentsMethodsRowAmex`: return paymentsMethodsRowAmex;
    case `paymentsMethodsRowCardNumber`: return paymentsMethodsRowCardNumber;
    case `paymentsMethodsRowDiscover`: return paymentsMethodsRowDiscover;
    case `paymentsMethodsRowExpirationDate`: return paymentsMethodsRowExpirationDate;
    case `paymentsMethodsRowIsDefault`: return paymentsMethodsRowIsDefault;
    case `paymentsMethodsRowMastercard`: return paymentsMethodsRowMastercard;
    case `paymentsMethodsRowPaymentMethod`: return paymentsMethodsRowPaymentMethod;
    case `paymentsMethodsRowRemove`: return paymentsMethodsRowRemove;
    case `paymentsMethodsRowSetDefault`: return paymentsMethodsRowSetDefault;
    case `paymentsMethodsRowVisa`: return paymentsMethodsRowVisa;
    case `paymentsMethodsTitleApplePay`: return paymentsMethodsTitleApplePay;
    case `paymentsSettingsPaymentHistoryEmptyStateMessage`: return paymentsSettingsPaymentHistoryEmptyStateMessage;
    case `paymentsSettingsPaymentHistoryFooterMessage`: return paymentsSettingsPaymentHistoryFooterMessage;
    case `paymentsSettingsPaymentHistorytitle`: return paymentsSettingsPaymentHistorytitle;
    case `paymentsSettingsRowAddpaymentmethod`: return paymentsSettingsRowAddpaymentmethod;
    case `paymentsSettingsRowPaymentmethod`: return paymentsSettingsRowPaymentmethod;
    case `paymentsSettingsTitle`: return paymentsSettingsTitle;
    case `paymentsTagDraft`: return paymentsTagDraft;
    case `paymentsTagOpen`: return paymentsTagOpen;
    case `paymentsTagPaid`: return paymentsTagPaid;
    case `paymentsTagUncollectible`: return paymentsTagUncollectible;
    case `payrollConnectAcknowledgeFirstSuccessQuestion`: return payrollConnectAcknowledgeFirstSuccessQuestion;
    case `payrollConnectAcknowledgeFirstSuccessQuestionFallback`: return payrollConnectAcknowledgeFirstSuccessQuestionFallback;
    case `payrollConnectComponentAskForASecondButtonNo`: return payrollConnectComponentAskForASecondButtonNo;
    case `payrollConnectComponentAskForASecondButtonYes`: return payrollConnectComponentAskForASecondButtonYes;
    case `payrollConnectComponentAskForASecondQuestion`: return payrollConnectComponentAskForASecondQuestion;
    case `payrollConnectComponentAskForAnotherButtonNo`: return payrollConnectComponentAskForAnotherButtonNo;
    case `payrollConnectComponentAskForAnotherButtonYes`: return payrollConnectComponentAskForAnotherButtonYes;
    case `payrollConnectComponentAskForAnotherQuestion`: return payrollConnectComponentAskForAnotherQuestion;
    case `payrollConnectComponentAskForCredentialsButtonNo`: return payrollConnectComponentAskForCredentialsButtonNo;
    case `payrollConnectComponentAskForCredentialsButtonYes`: return payrollConnectComponentAskForCredentialsButtonYes;
    case `payrollConnectComponentAskForCredentialsQuestion`: return payrollConnectComponentAskForCredentialsQuestion;
    case `payrollConnectComponentDataEntryRequiredButton`: return payrollConnectComponentDataEntryRequiredButton;
    case `payrollConnectComponentDataEntryRequiredConclusionBody`: return payrollConnectComponentDataEntryRequiredConclusionBody;
    case `payrollConnectComponentDataEntryRequiredConclusionSkipText`: return payrollConnectComponentDataEntryRequiredConclusionSkipText;
    case `payrollConnectComponentDataEntryRequiredConclusionTitle`: return payrollConnectComponentDataEntryRequiredConclusionTitle;
    case `payrollConnectComponentDataEntryRequiredIntroButtonContinue`: return payrollConnectComponentDataEntryRequiredIntroButtonContinue;
    case `payrollConnectComponentDataEntryRequiredIntroTextBullet1`: return payrollConnectComponentDataEntryRequiredIntroTextBullet1;
    case `payrollConnectComponentDataEntryRequiredIntroTextBullet2`: return payrollConnectComponentDataEntryRequiredIntroTextBullet2;
    case `payrollConnectComponentDataEntryRequiredIntroTextBullet3`: return payrollConnectComponentDataEntryRequiredIntroTextBullet3;
    case `payrollConnectComponentDataEntryRequiredIntroTextMain`: return payrollConnectComponentDataEntryRequiredIntroTextMain;
    case `payrollConnectComponentDataEntryRequiredIntroTitle`: return payrollConnectComponentDataEntryRequiredIntroTitle;
    case `payrollConnectComponentDataEntryRequiredQuestion`: return payrollConnectComponentDataEntryRequiredQuestion;
    case `payrollConnectComponentDataEntryRequiredSkipCredentialsQuestion`: return payrollConnectComponentDataEntryRequiredSkipCredentialsQuestion;
    case `payrollConnectComponentLoadingButton`: return payrollConnectComponentLoadingButton;
    case `payrollConnectComponentLoadingQuestion`: return payrollConnectComponentLoadingQuestion;
    case `payrollConnectComponentReadyToAdvanceButton`: return payrollConnectComponentReadyToAdvanceButton;
    case `payrollConnectComponentReadyToAdvanceQuestion`: return payrollConnectComponentReadyToAdvanceQuestion;
    case `payrollConnectComponentWaitingOnFirstConnectionButton`: return payrollConnectComponentWaitingOnFirstConnectionButton;
    case `payrollConnectComponentWaitingOnFirstConnectionQuestion`: return payrollConnectComponentWaitingOnFirstConnectionQuestion;
    case `payrollConnectComponentWaitingOnFirstGoodConnectionButton`: return payrollConnectComponentWaitingOnFirstGoodConnectionButton;
    case `payrollConnectComponentWaitingOnFirstGoodConnectionQuestion`: return payrollConnectComponentWaitingOnFirstGoodConnectionQuestion;
    case `payrollConnectIntroButtonContinue`: return payrollConnectIntroButtonContinue;
    case `payrollConnectIntroButtonDontKnow`: return payrollConnectIntroButtonDontKnow;
    case `payrollConnectIntroHelpMessage`: return payrollConnectIntroHelpMessage;
    case `payrollConnectIntroHelpTitle`: return payrollConnectIntroHelpTitle;
    case `payrollConnectIntroTextBullet1`: return payrollConnectIntroTextBullet1;
    case `payrollConnectIntroTextBullet2`: return payrollConnectIntroTextBullet2;
    case `payrollConnectIntroTextBullet3`: return payrollConnectIntroTextBullet3;
    case `payrollConnectIntroTextMain`: return payrollConnectIntroTextMain;
    case `payrollConnectIntroTitle`: return payrollConnectIntroTitle;
    case `payrollConnectPaylocityCompanyIdentifierFieldPlaceholder`: return payrollConnectPaylocityCompanyIdentifierFieldPlaceholder;
    case `payrollConnectPaylocityCompanyIdentifierFieldTitle`: return payrollConnectPaylocityCompanyIdentifierFieldTitle;
    case `payrollConnectSandboxCompanyIdentifierFieldPlaceholder`: return payrollConnectSandboxCompanyIdentifierFieldPlaceholder;
    case `payrollConnectSandboxCompanyIdentifierFieldTitle`: return payrollConnectSandboxCompanyIdentifierFieldTitle;
    case `payrollConnectSandboxCompanyLoginURLFieldPlaceholder`: return payrollConnectSandboxCompanyLoginURLFieldPlaceholder;
    case `payrollConnectSandboxCompanyLoginURLFieldTitle`: return payrollConnectSandboxCompanyLoginURLFieldTitle;
    case `payrollConnectUltiproCompanyLoginUrlFieldBase`: return payrollConnectUltiproCompanyLoginUrlFieldBase;
    case `payrollConnectUltiproCompanyLoginUrlFieldPlaceholder`: return payrollConnectUltiproCompanyLoginUrlFieldPlaceholder;
    case `payrollConnectUltiproCompanyLoginUrlFieldPostfix`: return payrollConnectUltiproCompanyLoginUrlFieldPostfix;
    case `payrollConnectUltiproCompanyLoginUrlFieldTitle`: return payrollConnectUltiproCompanyLoginUrlFieldTitle;
    case `payrollConnectionAtLeastOneValidConnectionIsRequired`: return payrollConnectionAtLeastOneValidConnectionIsRequired;
    case `payrollConnectionStatusAccountBlockedErrorText`: return payrollConnectionStatusAccountBlockedErrorText;
    case `payrollConnectionStatusCredentialExpirationPendingErrorText`: return payrollConnectionStatusCredentialExpirationPendingErrorText;
    case `payrollConnectionStatusCredentialsExpiredErrorText`: return payrollConnectionStatusCredentialsExpiredErrorText;
    case `payrollConnectionStatusCredentialsInvalidErrorText`: return payrollConnectionStatusCredentialsInvalidErrorText;
    case `payrollConnectionStatusCredentialsNotProvidedErrorText`: return payrollConnectionStatusCredentialsNotProvidedErrorText;
    case `payrollConnectionStatusCredentialsNotTestableErrorText`: return payrollConnectionStatusCredentialsNotTestableErrorText;
    case `payrollConnectionStatusUltiproCredentialsInvalidErrorText`: return payrollConnectionStatusUltiproCredentialsInvalidErrorText;
    case `payrollCredentialsAlertWaitingBody`: return payrollCredentialsAlertWaitingBody;
    case `payrollCredentialsAlertWaitingTitle`: return payrollCredentialsAlertWaitingTitle;
    case `payrollCredentialsButtonPasswordConfirmationButton`: return payrollCredentialsButtonPasswordConfirmationButton;
    case `payrollCredentialsButtonPasswordConfirmationMessage`: return payrollCredentialsButtonPasswordConfirmationMessage;
    case `payrollCredentialsButtonPasswordConfirmationMessageInstitutionNameFallback`: return payrollCredentialsButtonPasswordConfirmationMessageInstitutionNameFallback;
    case `payrollCredentialsButtonPasswordConfirmationTitle`: return payrollCredentialsButtonPasswordConfirmationTitle;
    case `payrollCredentialsButtonPasswordReset`: return payrollCredentialsButtonPasswordReset;
    case `payrollCredentialsButtonSubmit`: return payrollCredentialsButtonSubmit;
    case `payrollCredentialsChallengeQButton`: return payrollCredentialsChallengeQButton;
    case `payrollCredentialsChallengeQChooseOnePlaceholder`: return payrollCredentialsChallengeQChooseOnePlaceholder;
    case `payrollCredentialsChallengeQError`: return payrollCredentialsChallengeQError;
    case `payrollCredentialsChallengeQPlaceholder`: return payrollCredentialsChallengeQPlaceholder;
    case `payrollCredentialsChallengeQTitle`: return payrollCredentialsChallengeQTitle;
    case `payrollCredentialsErrorButtonChat`: return payrollCredentialsErrorButtonChat;
    case `payrollCredentialsErrorButtonContinue`: return payrollCredentialsErrorButtonContinue;
    case `payrollCredentialsErrorButtonRetry`: return payrollCredentialsErrorButtonRetry;
    case `payrollCredentialsErrorLabelDefaultFailure`: return payrollCredentialsErrorLabelDefaultFailure;
    case `payrollCredentialsErrorLabelDefaultRetry`: return payrollCredentialsErrorLabelDefaultRetry;
    case `payrollCredentialsFieldPasswordPlaceholder`: return payrollCredentialsFieldPasswordPlaceholder;
    case `payrollCredentialsFieldUsernamePlaceholder`: return payrollCredentialsFieldUsernamePlaceholder;
    case `payrollCredentialsFinePrintManualProvider`: return payrollCredentialsFinePrintManualProvider;
    case `payrollCredentialsSuccessButtonContinue`: return payrollCredentialsSuccessButtonContinue;
    case `payrollCredentialsSuccessLabelInfo`: return payrollCredentialsSuccessLabelInfo;
    case `payrollCredentialsTitleChallenge`: return payrollCredentialsTitleChallenge;
    case `payrollCredentialsTitleChallengeError`: return payrollCredentialsTitleChallengeError;
    case `payrollCredentialsTitleFailure`: return payrollCredentialsTitleFailure;
    case `payrollCredentialsTitleLoading`: return payrollCredentialsTitleLoading;
    case `payrollCredentialsTitleReady`: return payrollCredentialsTitleReady;
    case `payrollCredentialsTitleSuccess`: return payrollCredentialsTitleSuccess;
    case `payrollJobNameButtonContinue`: return payrollJobNameButtonContinue;
    case `payrollJobNameQuestionDefault`: return payrollJobNameQuestionDefault;
    case `payrollJobNameQuestionFifth`: return payrollJobNameQuestionFifth;
    case `payrollJobNameQuestionFirst`: return payrollJobNameQuestionFirst;
    case `payrollJobNameQuestionFirstSubtext`: return payrollJobNameQuestionFirstSubtext;
    case `payrollJobNameQuestionFourth`: return payrollJobNameQuestionFourth;
    case `payrollJobNameQuestionNext`: return payrollJobNameQuestionNext;
    case `payrollJobNameQuestionSecond`: return payrollJobNameQuestionSecond;
    case `payrollJobNameQuestionThird`: return payrollJobNameQuestionThird;
    case `payrollJobNameTextFieldPlaceholder`: return payrollJobNameTextFieldPlaceholder;
    case `payrollJobNameTitle`: return payrollJobNameTitle;
    case `payrollPaystubUploadButtonLabelContinue`: return payrollPaystubUploadButtonLabelContinue;
    case `payrollPaystubUploadDescription`: return payrollPaystubUploadDescription;
    case `payrollPaystubUploadLatestPaystubButtonLabel`: return payrollPaystubUploadLatestPaystubButtonLabel;
    case `payrollPaystubUploadStepOne`: return payrollPaystubUploadStepOne;
    case `payrollPaystubUploadStepOneButtonLabel`: return payrollPaystubUploadStepOneButtonLabel;
    case `payrollPaystubUploadStepOneButtonLabelDone`: return payrollPaystubUploadStepOneButtonLabelDone;
    case `payrollPaystubUploadStepOneDescripton`: return payrollPaystubUploadStepOneDescripton;
    case `payrollPaystubUploadStepThree`: return payrollPaystubUploadStepThree;
    case `payrollPaystubUploadStepThreeDescripton`: return payrollPaystubUploadStepThreeDescripton;
    case `payrollPaystubUploadStepTwo`: return payrollPaystubUploadStepTwo;
    case `payrollPaystubUploadStepTwoDescripton`: return payrollPaystubUploadStepTwoDescripton;
    case `payrollPaystubUploadTitle`: return payrollPaystubUploadTitle;
    case `payrollProviderButtonOtherProvider`: return payrollProviderButtonOtherProvider;
    case `payrollProviderHelp`: return payrollProviderHelp;
    case `payrollProviderIntroButtonsNo`: return payrollProviderIntroButtonsNo;
    case `payrollProviderIntroButtonsYes`: return payrollProviderIntroButtonsYes;
    case `payrollProviderIntroQuestion`: return payrollProviderIntroQuestion;
    case `payrollProviderIntroSubtext`: return payrollProviderIntroSubtext;
    case `payrollProviderIntroTitle`: return payrollProviderIntroTitle;
    case `payrollProviderLabelSubtitle`: return payrollProviderLabelSubtitle;
    case `payrollProviderLabelTitle`: return payrollProviderLabelTitle;
    case `payrollProviderNameButtonContinue`: return payrollProviderNameButtonContinue;
    case `payrollProviderNameTextFieldPlaceholder`: return payrollProviderNameTextFieldPlaceholder;
    case `payrollProviderNameTextQuestion`: return payrollProviderNameTextQuestion;
    case `payrollProviderSearchBlankTextInfo`: return payrollProviderSearchBlankTextInfo;
    case `payrollProviderSearchInstructionsTextPrimary`: return payrollProviderSearchInstructionsTextPrimary;
    case `payrollProviderSearchInstructionsTextSecondary`: return payrollProviderSearchInstructionsTextSecondary;
    case `payrollProviderSearchNoResultsButtonAddProvider`: return payrollProviderSearchNoResultsButtonAddProvider;
    case `payrollProviderSearchNoResultsText`: return payrollProviderSearchNoResultsText;
    case `payrollProviderTitle`: return payrollProviderTitle;
    case `profileAppLockReason`: return profileAppLockReason;
    case `profileBoostcardSettingsTitle`: return profileBoostcardSettingsTitle;
    case `profileCell2FADisabledSubtitle`: return profileCell2FADisabledSubtitle;
    case `profileCell2FATitle`: return profileCell2FATitle;
    case `profileCellBiometryTitle`: return profileCellBiometryTitle;
    case `profileCellNotificationsTitle`: return profileCellNotificationsTitle;
    case `profileCellSignOut`: return profileCellSignOut;
    case `profileEditButtonSave`: return profileEditButtonSave;
    case `profileEditEmailTitle`: return profileEditEmailTitle;
    case `profileEditFirstNameTitle`: return profileEditFirstNameTitle;
    case `profileEditLastNameTitle`: return profileEditLastNameTitle;
    case `profileEditOnboardalertMessage`: return profileEditOnboardalertMessage;
    case `profileEditOnboardalertTitle`: return profileEditOnboardalertTitle;
    case `profileEditPasswordTitle`: return profileEditPasswordTitle;
    case `profileEditPhoneTitle`: return profileEditPhoneTitle;
    case `profileLinkedAccountsAddNewConnection`: return profileLinkedAccountsAddNewConnection;
    case `profileLinkedAccountsRenewLink`: return profileLinkedAccountsRenewLink;
    case `profilePauseAlertMessage`: return profilePauseAlertMessage;
    case `profilePauseAlertOption1Month`: return profilePauseAlertOption1Month;
    case `profilePauseAlertOption3Month`: return profilePauseAlertOption3Month;
    case `profilePauseAlertOptionCancel`: return profilePauseAlertOptionCancel;
    case `profilePauseAlertOptionIndefinitely`: return profilePauseAlertOptionIndefinitely;
    case `profilePauseAlertTitle`: return profilePauseAlertTitle;
    case `profilePaymentplanIntervalDay`: return profilePaymentplanIntervalDay;
    case `profilePaymentplanIntervalFallback`: return profilePaymentplanIntervalFallback;
    case `profilePaymentplanIntervalMonth`: return profilePaymentplanIntervalMonth;
    case `profilePaymentplanIntervalOnce`: return profilePaymentplanIntervalOnce;
    case `profilePaymentplanIntervalWeek`: return profilePaymentplanIntervalWeek;
    case `profilePaymentplanIntervalYear`: return profilePaymentplanIntervalYear;
    case `profileRowAccountClose`: return profileRowAccountClose;
    case `profileRowAccountsBoostCard`: return profileRowAccountsBoostCard;
    case `profileRowAccountsBoostCardStateActive`: return profileRowAccountsBoostCardStateActive;
    case `profileRowAccountsBoostCardStateBlocked`: return profileRowAccountsBoostCardStateBlocked;
    case `profileRowAccountsBoostCardStateClosed`: return profileRowAccountsBoostCardStateClosed;
    case `profileRowAccountsBoostCardStateSetup`: return profileRowAccountsBoostCardStateSetup;
    case `profileRowAccountsHeader`: return profileRowAccountsHeader;
    case `profileRowEmail`: return profileRowEmail;
    case `profileRowEmailVerified`: return profileRowEmailVerified;
    case `profileRowFirstName`: return profileRowFirstName;
    case `profileRowLastName`: return profileRowLastName;
    case `profileRowMembershipHeader`: return profileRowMembershipHeader;
    case `profileRowMembershipLevelGridBasic`: return profileRowMembershipLevelGridBasic;
    case `profileRowMembershipLevelGridNone`: return profileRowMembershipLevelGridNone;
    case `profileRowMembershipLevelGridPlus`: return profileRowMembershipLevelGridPlus;
    case `profileRowPassword`: return profileRowPassword;
    case `profileRowPhone`: return profileRowPhone;
    case `profileRowPhoneVerified`: return profileRowPhoneVerified;
    case `profileRowSubscription`: return profileRowSubscription;
    case `profileRowSubscriptionHeader`: return profileRowSubscriptionHeader;
    case `profileRowSubscriptionIntervalDay`: return profileRowSubscriptionIntervalDay;
    case `profileRowSubscriptionIntervalMonth`: return profileRowSubscriptionIntervalMonth;
    case `profileRowSubscriptionIntervalOnce`: return profileRowSubscriptionIntervalOnce;
    case `profileRowSubscriptionIntervalWeek`: return profileRowSubscriptionIntervalWeek;
    case `profileRowSubscriptionIntervalYear`: return profileRowSubscriptionIntervalYear;
    case `profileRowSubscriptionPause`: return profileRowSubscriptionPause;
    case `profileRowSubscriptionPauseDescription`: return profileRowSubscriptionPauseDescription;
    case `profileRowSubscriptionPauseDescriptionFinite`: return profileRowSubscriptionPauseDescriptionFinite;
    case `profileRowSubscriptionPauseDescriptionIndefinite`: return profileRowSubscriptionPauseDescriptionIndefinite;
    case `profileRowSubscriptionResume`: return profileRowSubscriptionResume;
    case `profileSectionHeaderLinkedAccounts`: return profileSectionHeaderLinkedAccounts;
    case `profileSectionHeaderPrivacy`: return profileSectionHeaderPrivacy;
    case `profileSectionHeaderSecurity`: return profileSectionHeaderSecurity;
    case `profileStatsHeader`: return profileStatsHeader;
    case `profileStatsHeaderDefault`: return profileStatsHeaderDefault;
    case `profileSubscriptionBillingHistoryTitle`: return profileSubscriptionBillingHistoryTitle;
    case `profileSubscriptionDescriptionCancelSubscriptionCTA`: return profileSubscriptionDescriptionCancelSubscriptionCTA;
    case `profileSubscriptionDescriptionCloseAccountCTA`: return profileSubscriptionDescriptionCloseAccountCTA;
    case `profileSubscriptionDescriptionDeleteAccountCTA`: return profileSubscriptionDescriptionDeleteAccountCTA;
    case `profileSubscriptionDescriptionPrice`: return profileSubscriptionDescriptionPrice;
    case `profileSubscriptionGridPlusPrice`: return profileSubscriptionGridPlusPrice;
    case `profileSubscriptionGridPlusPriceExplanation`: return profileSubscriptionGridPlusPriceExplanation;
    case `profileSubscriptionGridPrice`: return profileSubscriptionGridPrice;
    case `profileSubscriptionInvoiceFreeAmount`: return profileSubscriptionInvoiceFreeAmount;
    case `profileSubscriptionInvoiceTitle`: return profileSubscriptionInvoiceTitle;
    case `profileSubscriptionOverdue`: return profileSubscriptionOverdue;
    case `profileSubscriptionOverduePaymentsTitle`: return profileSubscriptionOverduePaymentsTitle;
    case `profileSubscriptionRenewsAt`: return profileSubscriptionRenewsAt;
    case `profileSubscrpitionDescriptionMembershipRowTitle`: return profileSubscrpitionDescriptionMembershipRowTitle;
    case `profileSubscrpitionDescriptionTitle`: return profileSubscrpitionDescriptionTitle;
    case `profileTitle`: return profileTitle;
    case `purchaseHistoryOneTimePayboost`: return purchaseHistoryOneTimePayboost;
    case `purchaseHistoryOneTimePayboost60Pct`: return purchaseHistoryOneTimePayboost60Pct;
    case `purchaseHistoryOneTimePayboost80Pct`: return purchaseHistoryOneTimePayboost80Pct;
    case `purchaseHistorySubscriptionTitle`: return purchaseHistorySubscriptionTitle;
    case `purchaseHistoryUnknownProduct`: return purchaseHistoryUnknownProduct;
    case `pushNotificationsInvitationScreenCTATitle`: return pushNotificationsInvitationScreenCTATitle;
    case `pushNotificationsInvitationScreenLinkCTATitle`: return pushNotificationsInvitationScreenLinkCTATitle;
    case `pushNotificationsInvitationScreenSubtitle`: return pushNotificationsInvitationScreenSubtitle;
    case `pushNotificationsInvitationScreenTitle`: return pushNotificationsInvitationScreenTitle;
    case `qualificationCriteriaIdv`: return qualificationCriteriaIdv;
    case `qualificationCriteriaIdvAllCornersVisible`: return qualificationCriteriaIdvAllCornersVisible;
    case `qualificationCriteriaIdvContentSubtitle`: return qualificationCriteriaIdvContentSubtitle;
    case `qualificationCriteriaIdvContentTitle`: return qualificationCriteriaIdvContentTitle;
    case `qualificationCriteriaIdvCta`: return qualificationCriteriaIdvCta;
    case `qualificationCriteriaIdvFaceAndNameVisible`: return qualificationCriteriaIdvFaceAndNameVisible;
    case `qualificationCriteriaIdvHighResolutionAndColor`: return qualificationCriteriaIdvHighResolutionAndColor;
    case `qualificationCriteriaIdvTableHeader`: return qualificationCriteriaIdvTableHeader;
    case `qualificationCriteriaIdvTitle`: return qualificationCriteriaIdvTitle;
    case `qualificationCriteriaIdvValidNonExpiredDocument`: return qualificationCriteriaIdvValidNonExpiredDocument;
    case `referralProgramWebPickupDescription`: return referralProgramWebPickupDescription;
    case `referralProgramWebPickupEmailInputLabel`: return referralProgramWebPickupEmailInputLabel;
    case `referralProgramWebPickupEmailInputPlaceholder`: return referralProgramWebPickupEmailInputPlaceholder;
    case `referralProgramWebPickupSubmitEmailButton`: return referralProgramWebPickupSubmitEmailButton;
    case `referralProgramWebPickupSuccess`: return referralProgramWebPickupSuccess;
    case `referralProgramWebPickupSuccessHelp`: return referralProgramWebPickupSuccessHelp;
    case `referralProgramWebPickupSuccessSubtitle`: return referralProgramWebPickupSuccessSubtitle;
    case `referralProgramWebPickupTitle`: return referralProgramWebPickupTitle;
    case `rewardsCheckoutReferred`: return rewardsCheckoutReferred;
    case `rewardsReferralsBody`: return rewardsReferralsBody;
    case `rewardsReferralsButonHelp`: return rewardsReferralsButonHelp;
    case `rewardsReferralsButtonShare`: return rewardsReferralsButtonShare;
    case `rewardsReferralsHelpMessage`: return rewardsReferralsHelpMessage;
    case `rewardsReferralsHelpTitle`: return rewardsReferralsHelpTitle;
    case `rewardsReferralsTitle`: return rewardsReferralsTitle;
    case `rewardsRewardsBody`: return rewardsRewardsBody;
    case `rewardsRewardsCaption`: return rewardsRewardsCaption;
    case `rewardsTabReferrals`: return rewardsTabReferrals;
    case `rewardsTabRewards`: return rewardsTabRewards;
    case `rewardsTitle`: return rewardsTitle;
    case `sadfaceMessage`: return sadfaceMessage;
    case `sadfaceVCChatCTA`: return sadfaceVCChatCTA;
    case `sadfaceVCChatUnavailableCTA`: return sadfaceVCChatUnavailableCTA;
    case `sadfaceVCTitle`: return sadfaceVCTitle;
    case `securityAuthReason`: return securityAuthReason;
    case `securityBiometryFaceid`: return securityBiometryFaceid;
    case `securityBiometryPasscode`: return securityBiometryPasscode;
    case `securityBiometryTouchid`: return securityBiometryTouchid;
    case `securityBiometryUnknown`: return securityBiometryUnknown;
    case `setupCreditLineIntroBody`: return setupCreditLineIntroBody;
    case `setupCreditLineIntroBody2`: return setupCreditLineIntroBody2;
    case `setupCreditLineIntroCTA`: return setupCreditLineIntroCTA;
    case `setupCreditLineIntroFineText`: return setupCreditLineIntroFineText;
    case `setupCreditLineIntroHeader`: return setupCreditLineIntroHeader;
    case `setupCreditLineIntroTitle`: return setupCreditLineIntroTitle;
    case `sideMenuAnonymousUserEmail`: return sideMenuAnonymousUserEmail;
    case `sideMenuAnonymousUserInitials`: return sideMenuAnonymousUserInitials;
    case `sideMenuAnonymousUserName`: return sideMenuAnonymousUserName;
    case `sideMenuDocuments`: return sideMenuDocuments;
    case `sideMenuLegal`: return sideMenuLegal;
    case `sideMenuLegalLink`: return sideMenuLegalLink;
    case `sideMenuLinkedAccounts`: return sideMenuLinkedAccounts;
    case `sideMenuLogout`: return sideMenuLogout;
    case `sideMenuPayment`: return sideMenuPayment;
    case `sideMenuProfile`: return sideMenuProfile;
    case `sideMenuRating`: return sideMenuRating;
    case `sideMenuRewards`: return sideMenuRewards;
    case `sideMenuSettings`: return sideMenuSettings;
    case `sideMenuSupport`: return sideMenuSupport;
    case `sidebarConnectedAccountsBankAccountsHeader`: return sidebarConnectedAccountsBankAccountsHeader;
    case `sidebarConnectedAccountsDebitCardsHeader`: return sidebarConnectedAccountsDebitCardsHeader;
    case `sidebarConnectedAccountsLinkNewAccountButtonTitle`: return sidebarConnectedAccountsLinkNewAccountButtonTitle;
    case `sidebarConnectedAccountsLinkNewDebitCardButtonTitle`: return sidebarConnectedAccountsLinkNewDebitCardButtonTitle;
    case `sidebarConnectedAccountsPayrollHeader`: return sidebarConnectedAccountsPayrollHeader;
    case `sidebarProfileMenuTtile`: return sidebarProfileMenuTtile;
    case `statementCreditAlreadyProcessing`: return statementCreditAlreadyProcessing;
    case `statementCreditRedemptionNotPossible`: return statementCreditRedemptionNotPossible;
    case `staticAssetBankInstitutionManifestUrl`: return staticAssetBankInstitutionManifestUrl;
    case `streaksLevelCheckinsRequired`: return streaksLevelCheckinsRequired;
    case `streaksLevelCheckinsRequiredSingular`: return streaksLevelCheckinsRequiredSingular;
    case `streaksLevelIntervalSubtext`: return streaksLevelIntervalSubtext;
    case `streaksLevelOneTitle`: return streaksLevelOneTitle;
    case `streaksLevelThreeTitle`: return streaksLevelThreeTitle;
    case `streaksLevelTwoTitle`: return streaksLevelTwoTitle;
    case `stripeCardErrorDeclineCodeCardNotSupported`: return stripeCardErrorDeclineCodeCardNotSupported;
    case `stripeCardErrorDeclineCodeExpiredCard`: return stripeCardErrorDeclineCodeExpiredCard;
    case `stripeCardErrorDeclineCodeIncorrectCVC`: return stripeCardErrorDeclineCodeIncorrectCVC;
    case `stripeCardErrorDeclineCodeIncorrectNumber`: return stripeCardErrorDeclineCodeIncorrectNumber;
    case `stripeCardErrorDeclineCodeIncorrectZip`: return stripeCardErrorDeclineCodeIncorrectZip;
    case `stripeCardErrorDeclineCodeInsufficientFunds`: return stripeCardErrorDeclineCodeInsufficientFunds;
    case `stripeCardErrorDeclineCodeProcessingError`: return stripeCardErrorDeclineCodeProcessingError;
    case `stripeCardErrorDefault`: return stripeCardErrorDefault;
    case `stripeErrorDefault`: return stripeErrorDefault;
    case `stripeInvoicePendingError`: return stripeInvoicePendingError;
    case `subscriptionPayNowAcceptTermsLinkAlgoQueryTermsAttribution`: return subscriptionPayNowAcceptTermsLinkAlgoQueryTermsAttribution;
    case `subscriptionPayNowAddPaymentMethodTitle`: return subscriptionPayNowAddPaymentMethodTitle;
    case `subscriptionPayNowTerms`: return subscriptionPayNowTerms;
    case `subscriptionPayNowTermsTermsLinkAlgoQuery`: return subscriptionPayNowTermsTermsLinkAlgoQuery;
    case `subscriptionsPayNowAmountFallback`: return subscriptionsPayNowAmountFallback;
    case `subscriptionsPayNowCardTitle`: return subscriptionsPayNowCardTitle;
    case `subscriptionsPayNowChangePaymentMethodCTA`: return subscriptionsPayNowChangePaymentMethodCTA;
    case `subscriptionsPayNowContinueCTA`: return subscriptionsPayNowContinueCTA;
    case `subscriptionsPayNowDateTitle`: return subscriptionsPayNowDateTitle;
    case `subscriptionsPayNowDefaultPaymentMethod`: return subscriptionsPayNowDefaultPaymentMethod;
    case `subscriptionsPayNowErrorDescription`: return subscriptionsPayNowErrorDescription;
    case `subscriptionsPayNowErrorDismissCTA`: return subscriptionsPayNowErrorDismissCTA;
    case `subscriptionsPayNowErrorTitle`: return subscriptionsPayNowErrorTitle;
    case `subscriptionsPayNowMakePayment`: return subscriptionsPayNowMakePayment;
    case `subscriptionsPayNowProcessing`: return subscriptionsPayNowProcessing;
    case `subscriptionsPayNowSubscriptionFee`: return subscriptionsPayNowSubscriptionFee;
    case `subscriptionsPayNowTitle`: return subscriptionsPayNowTitle;
    case `subscriptionsPayNowTotalDue`: return subscriptionsPayNowTotalDue;
    case `subscriptionsPayNowTransferFrom`: return subscriptionsPayNowTransferFrom;
    case `supportMenuCustomerServiceCall`: return supportMenuCustomerServiceCall;
    case `supportMenuCustomerSupportCloseHour`: return supportMenuCustomerSupportCloseHour;
    case `supportMenuCustomerSupportDays`: return supportMenuCustomerSupportDays;
    case `supportMenuCustomerSupportHours`: return supportMenuCustomerSupportHours;
    case `supportMenuCustomerSupportHoursDynamic`: return supportMenuCustomerSupportHoursDynamic;
    case `supportMenuCustomerSupportOpenHour`: return supportMenuCustomerSupportOpenHour;
    case `supportMenuCustomerSupportTitle`: return supportMenuCustomerSupportTitle;
    case `supportMenuRowChatWithUs`: return supportMenuRowChatWithUs;
    case `supportMenuRowHeaderCustomerService`: return supportMenuRowHeaderCustomerService;
    case `supportMenuRowHeaderFAQ`: return supportMenuRowHeaderFAQ;
    case `supportMenuRowHeaderFeedback`: return supportMenuRowHeaderFeedback;
    case `supportMenuRowHeaderGetHelp`: return supportMenuRowHeaderGetHelp;
    case `supportMenuRowHeaderLegal`: return supportMenuRowHeaderLegal;
    case `supportMenuRowHeaderMonthlyStatements`: return supportMenuRowHeaderMonthlyStatements;
    case `supportMenuRowHelpCenter`: return supportMenuRowHelpCenter;
    case `supportMenuRowTitleAgreements`: return supportMenuRowTitleAgreements;
    case `supportMenuRowTitleBoostCardCloseAccount`: return supportMenuRowTitleBoostCardCloseAccount;
    case `supportMenuRowTitleCustomerFAQ`: return supportMenuRowTitleCustomerFAQ;
    case `supportMenuRowTitleCustomerService`: return supportMenuRowTitleCustomerService;
    case `supportMenuRowTitleFeedback`: return supportMenuRowTitleFeedback;
    case `supportMenuRowTitleLegal`: return supportMenuRowTitleLegal;
    case `supportMenuRowTitleMonthlyStatements`: return supportMenuRowTitleMonthlyStatements;
    case `supportMenuRowURLFAQ`: return supportMenuRowURLFAQ;
    case `supportMenuRowURLHelpCenter`: return supportMenuRowURLHelpCenter;
    case `supportMenuRowURLLegal`: return supportMenuRowURLLegal;
    case `supportMenuTitle`: return supportMenuTitle;
    case `supportMonthlyStatementsNoStatements`: return supportMonthlyStatementsNoStatements;
    case `supportMonthlyStatementsTitle`: return supportMonthlyStatementsTitle;
    case `synapseBankAccountNotReadyForTransfers`: return synapseBankAccountNotReadyForTransfers;
    case `synapseBoostCardUnsupportedStateError`: return synapseBoostCardUnsupportedStateError;
    case `synapseGoogleUndeliverableAddressError`: return synapseGoogleUndeliverableAddressError;
    case `synapseIncorrectUnitAddressError`: return synapseIncorrectUnitAddressError;
    case `synapseInsufficientFundsTransferError`: return synapseInsufficientFundsTransferError;
    case `synapseMismatchedCreditCardPinError`: return synapseMismatchedCreditCardPinError;
    case `temporaryCardAddToWalletScreenAppleSubtitle`: return temporaryCardAddToWalletScreenAppleSubtitle;
    case `temporaryCardAddToWalletScreenAppleTitle`: return temporaryCardAddToWalletScreenAppleTitle;
    case `temporaryCardAddToWalletScreenGoogleCardCvv`: return temporaryCardAddToWalletScreenGoogleCardCvv;
    case `temporaryCardAddToWalletScreenGoogleCardExpiracy`: return temporaryCardAddToWalletScreenGoogleCardExpiracy;
    case `temporaryCardAddToWalletScreenGoogleCardName`: return temporaryCardAddToWalletScreenGoogleCardName;
    case `temporaryCardAddToWalletScreenGoogleCardNumber`: return temporaryCardAddToWalletScreenGoogleCardNumber;
    case `temporaryCardAddToWalletScreenGoogleSubtitle`: return temporaryCardAddToWalletScreenGoogleSubtitle;
    case `temporaryCardAddToWalletScreenGoogleTitle`: return temporaryCardAddToWalletScreenGoogleTitle;
    case `temporaryCardAddToWalletScreenSkipCTA`: return temporaryCardAddToWalletScreenSkipCTA;
    case `temporaryCardAddToWalletScreenWarning`: return temporaryCardAddToWalletScreenWarning;
    case `temporaryCardBoostCardScreenTitle`: return temporaryCardBoostCardScreenTitle;
    case `temporaryCardBoostCardScreenWarning`: return temporaryCardBoostCardScreenWarning;
    case `temporaryCardDeactivatedHeadsUpScreenCTA`: return temporaryCardDeactivatedHeadsUpScreenCTA;
    case `temporaryCardDeactivatedHeadsUpScreenSubtitle`: return temporaryCardDeactivatedHeadsUpScreenSubtitle;
    case `temporaryCardDeactivatedHeadsUpScreenTitle`: return temporaryCardDeactivatedHeadsUpScreenTitle;
    case `temporaryCardGetTemporaryCardCTA`: return temporaryCardGetTemporaryCardCTA;
    case `temporaryCardIntroScreenCTA`: return temporaryCardIntroScreenCTA;
    case `temporaryCardIntroScreenCTAClose`: return temporaryCardIntroScreenCTAClose;
    case `temporaryCardIntroScreenExplainItem1`: return temporaryCardIntroScreenExplainItem1;
    case `temporaryCardIntroScreenExplainItem2`: return temporaryCardIntroScreenExplainItem2;
    case `temporaryCardIntroScreenExplainItem2Google`: return temporaryCardIntroScreenExplainItem2Google;
    case `temporaryCardIntroScreenExplainItem3`: return temporaryCardIntroScreenExplainItem3;
    case `temporaryCardIntroScreenExplanationTitle`: return temporaryCardIntroScreenExplanationTitle;
    case `temporaryCardIntroScreenSubtitle`: return temporaryCardIntroScreenSubtitle;
    case `temporaryCardIntroScreenTitle`: return temporaryCardIntroScreenTitle;
    case `temporaryCardWrong4DigitsScreenCTA`: return temporaryCardWrong4DigitsScreenCTA;
    case `temporaryCardWrong4DigitsScreenSubitle`: return temporaryCardWrong4DigitsScreenSubitle;
    case `temporaryCardWrong4DigitsScreenTitle`: return temporaryCardWrong4DigitsScreenTitle;
    case `transactionConfirmationSpinnerBoxTitle`: return transactionConfirmationSpinnerBoxTitle;
    case `transactionInfoTypeACHTransferDeposit`: return transactionInfoTypeACHTransferDeposit;
    case `transactionInfoTypeACHTransferWithdrawal`: return transactionInfoTypeACHTransferWithdrawal;
    case `transactionInfoTypeCashBack`: return transactionInfoTypeCashBack;
    case `transactionInfoTypeCashBoostAdvance`: return transactionInfoTypeCashBoostAdvance;
    case `transactionInfoTypeCashBoostRepayment`: return transactionInfoTypeCashBoostRepayment;
    case `transactionInfoTypeCashDeposit`: return transactionInfoTypeCashDeposit;
    case `transactionInfoTypeCashWithdrawal`: return transactionInfoTypeCashWithdrawal;
    case `transactionInfoTypeCreditCardCashAdvance`: return transactionInfoTypeCreditCardCashAdvance;
    case `transactionInfoTypeCreditCardCharge`: return transactionInfoTypeCreditCardCharge;
    case `transactionInfoTypeCreditCardRefund`: return transactionInfoTypeCreditCardRefund;
    case `transactionInfoTypeDebitCardCharge`: return transactionInfoTypeDebitCardCharge;
    case `transactionInfoTypeDebitCardPushToDebit`: return transactionInfoTypeDebitCardPushToDebit;
    case `transactionInfoTypeDebitCardRefund`: return transactionInfoTypeDebitCardRefund;
    case `transactionInfoTypeFee`: return transactionInfoTypeFee;
    case `transactionInfoViewIssueCTA`: return transactionInfoViewIssueCTA;
    case `transactionInfoViewRowCategory`: return transactionInfoViewRowCategory;
    case `transactionInfoViewRowDate`: return transactionInfoViewRowDate;
    case `transactionInfoViewRowFrom`: return transactionInfoViewRowFrom;
    case `transactionInfoViewRowStatus`: return transactionInfoViewRowStatus;
    case `transactionInfoViewRowStatusCanceled`: return transactionInfoViewRowStatusCanceled;
    case `transactionInfoViewRowStatusDisputeLost`: return transactionInfoViewRowStatusDisputeLost;
    case `transactionInfoViewRowStatusDisputePending`: return transactionInfoViewRowStatusDisputePending;
    case `transactionInfoViewRowStatusDisputeWon`: return transactionInfoViewRowStatusDisputeWon;
    case `transactionInfoViewRowStatusFailed`: return transactionInfoViewRowStatusFailed;
    case `transactionInfoViewRowStatusInDispute`: return transactionInfoViewRowStatusInDispute;
    case `transactionInfoViewRowStatusPending`: return transactionInfoViewRowStatusPending;
    case `transactionInfoViewRowStatusPosted`: return transactionInfoViewRowStatusPosted;
    case `transactionInfoViewRowStatusUnknown`: return transactionInfoViewRowStatusUnknown;
    case `transactionInfoViewRowTo`: return transactionInfoViewRowTo;
    case `transactionInfoViewRowType`: return transactionInfoViewRowType;
    case `transactionInfoViewRowVendor`: return transactionInfoViewRowVendor;
    case `transactionInitiatedModalCTA`: return transactionInitiatedModalCTA;
    case `transactionInitiatedModalContent`: return transactionInitiatedModalContent;
    case `transactionInitiatedModalTitle`: return transactionInitiatedModalTitle;
    case `transactionNotEligibleForDispute`: return transactionNotEligibleForDispute;
    case `twoFAChangePhoneNumber`: return twoFAChangePhoneNumber;
    case `twoFAEmailVerificationChangeEmail`: return twoFAEmailVerificationChangeEmail;
    case `twoFAEmailVerificationChangeEmailTitle`: return twoFAEmailVerificationChangeEmailTitle;
    case `twoFAEmailVerificationResend`: return twoFAEmailVerificationResend;
    case `twoFAEmailVerificationTitle`: return twoFAEmailVerificationTitle;
    case `twoFAInstructions`: return twoFAInstructions;
    case `twoFAMoreInfo`: return twoFAMoreInfo;
    case `twoFAPhoneVerificationInstructions`: return twoFAPhoneVerificationInstructions;
    case `twoFAPhoneVerificationMoreInfo`: return twoFAPhoneVerificationMoreInfo;
    case `twoFAPhoneVerificationVerifyPhone`: return twoFAPhoneVerificationVerifyPhone;
    case `twoFAResend`: return twoFAResend;
    case `twoFAVerifying`: return twoFAVerifying;
    case `updateAppMessage`: return updateAppMessage;
    case `updateAppTitle`: return updateAppTitle;
    case `validationFederalIncomeTaxLessThanPrevious`: return validationFederalIncomeTaxLessThanPrevious;
    case `validationFederalIncomeTaxTooHigh`: return validationFederalIncomeTaxTooHigh;
    case `validationFederalIncomeTaxTooHighSecondAttempt`: return validationFederalIncomeTaxTooHighSecondAttempt;
    case `validationFederalIncomeTaxYTDTooHigh`: return validationFederalIncomeTaxYTDTooHigh;
    case `validationFederalIncomeTaxYTDTooHighSecondAttempt`: return validationFederalIncomeTaxYTDTooHighSecondAttempt;
    case `validationGrossIncomeLessThanPrevious`: return validationGrossIncomeLessThanPrevious;
    case `validationGrossIncomeTooHigh`: return validationGrossIncomeTooHigh;
    case `validationGrossIncomeTooHighSecondAttempt`: return validationGrossIncomeTooHighSecondAttempt;
    case `validationGrossIncomeYTDTooHigh`: return validationGrossIncomeYTDTooHigh;
    case `validationGrossIncomeYTDTooHighSecondAttempt`: return validationGrossIncomeYTDTooHighSecondAttempt;
    case `validationNetIncomeHigherThanGross`: return validationNetIncomeHigherThanGross;
    case `validationNetIncomeTooHigh`: return validationNetIncomeTooHigh;
    case `validationNetIncomeTooHighSecondAttempt`: return validationNetIncomeTooHighSecondAttempt;
    case `validationStateTaxHigherThanGross`: return validationStateTaxHigherThanGross;
    case `validationStateTaxLessThanPrevious`: return validationStateTaxLessThanPrevious;
    case `verifyBankAccountScreenFailedCTA`: return verifyBankAccountScreenFailedCTA;
    case `verifyBankAccountScreenFailedSubtitle`: return verifyBankAccountScreenFailedSubtitle;
    case `verifyBankAccountScreenFailedTitle`: return verifyBankAccountScreenFailedTitle;
    case `verifyBankAccountStepContinueButton`: return verifyBankAccountStepContinueButton;
    case `verifyBankAccountStepHeaderTitle`: return verifyBankAccountStepHeaderTitle;
    case `verifyBankAccountStepHelpButton`: return verifyBankAccountStepHelpButton;
    case `verifyBankAccountStepHelpCTA`: return verifyBankAccountStepHelpCTA;
    case `verifyBankAccountStepHelpMessage`: return verifyBankAccountStepHelpMessage;
    case `verifyBankAccountStepTitle`: return verifyBankAccountStepTitle;
    case `verifyBankAccountWorkflowCompletionBody`: return verifyBankAccountWorkflowCompletionBody;
    case `verifyBankAccountWorkflowCompletionCTA`: return verifyBankAccountWorkflowCompletionCTA;
    case `waitingForVerificationLongSubtitle`: return waitingForVerificationLongSubtitle;
    case `waitingForVerificationLongTitle`: return waitingForVerificationLongTitle;
    case `waitingForVerificationSubtitle`: return waitingForVerificationSubtitle;
    case `waitingForVerificationTitle`: return waitingForVerificationTitle;
    case `welcomeEmailFieldPlaceholder`: return welcomeEmailFieldPlaceholder;
    case `welcomeForgotPasswordAlertMessage`: return welcomeForgotPasswordAlertMessage;
    case `welcomeForgotPasswordAlertTitle`: return welcomeForgotPasswordAlertTitle;
    case `welcomeLoginButton`: return welcomeLoginButton;
    case `welcomeOnboardingButton`: return welcomeOnboardingButton;
    case `welcomePasswordFieldPlaceholder`: return welcomePasswordFieldPlaceholder;
    case `welcomePreonboardingButton`: return welcomePreonboardingButton;
    case `welcomePreonboardingButtonNextPage`: return welcomePreonboardingButtonNextPage;
    case `welcomeTagline`: return welcomeTagline;
    case `welcomeTagline2`: return welcomeTagline2;
    case `welcomeTagline3`: return welcomeTagline3;
    case `welcomeWorkflowTextViewDefaultPlaceholder`: return welcomeWorkflowTextViewDefaultPlaceholder;
    case `workflowBoostResultOffboardingReasonGridPlus`: return workflowBoostResultOffboardingReasonGridPlus;
    case `workflowGenericHelpModalDone`: return workflowGenericHelpModalDone;
    case `workflowMonthlyConclusionDoneBody`: return workflowMonthlyConclusionDoneBody;
    case `workflowMonthlyConclusionDoneContinueButton`: return workflowMonthlyConclusionDoneContinueButton;
    case `workflowMonthlyConclusionDoneTitle`: return workflowMonthlyConclusionDoneTitle;
    case `workflowMonthlyConclusionWaitingBody`: return workflowMonthlyConclusionWaitingBody;
    case `workflowMonthlyConclusionWaitingFinePrint`: return workflowMonthlyConclusionWaitingFinePrint;
    case `workflowMonthlyConclusionWaitingTitleDecrease`: return workflowMonthlyConclusionWaitingTitleDecrease;
    case `workflowMonthlyConclusionWaitingTitleIncrease`: return workflowMonthlyConclusionWaitingTitleIncrease;
    case `workflowMonthlyIntroButtonGo`: return workflowMonthlyIntroButtonGo;
    case `workflowMonthlyIntroButtonSkip`: return workflowMonthlyIntroButtonSkip;
    case `workflowStepContinueButtonTitle`: return workflowStepContinueButtonTitle;
    case `workflowStepNextButtonTitle`: return workflowStepNextButtonTitle;
    case `workflowsAddressStepTitle`: return workflowsAddressStepTitle;
    case `workflowsBoostCardActivationGroupTitle`: return workflowsBoostCardActivationGroupTitle;
    case `workflowsBoostCardActivationGroupsFourDigitPinStepConfirmationTitle`: return workflowsBoostCardActivationGroupsFourDigitPinStepConfirmationTitle;
    case `workflowsBoostCardActivationGroupsFourDigitPinStepHelpText`: return workflowsBoostCardActivationGroupsFourDigitPinStepHelpText;
    case `workflowsBoostCardActivationGroupsFourDigitPinStepHelpTitle`: return workflowsBoostCardActivationGroupsFourDigitPinStepHelpTitle;
    case `workflowsBoostCardActivationGroupsFourDigitPinStepTitle`: return workflowsBoostCardActivationGroupsFourDigitPinStepTitle;
    case `workflowsBoostCardActivationGroupsLastFourCardNumberStepHelpText`: return workflowsBoostCardActivationGroupsLastFourCardNumberStepHelpText;
    case `workflowsBoostCardActivationGroupsLastFourCardNumberStepHelpTitle`: return workflowsBoostCardActivationGroupsLastFourCardNumberStepHelpTitle;
    case `workflowsBoostCardActivationGroupsLastFourCardNumberStepTitle`: return workflowsBoostCardActivationGroupsLastFourCardNumberStepTitle;
    case `workflowsBoostCardActivationGroupsThreeDigitCVVStepHelpText`: return workflowsBoostCardActivationGroupsThreeDigitCVVStepHelpText;
    case `workflowsBoostCardActivationGroupsThreeDigitCVVStepHelpTitle`: return workflowsBoostCardActivationGroupsThreeDigitCVVStepHelpTitle;
    case `workflowsBoostCardActivationGroupsThreeDigitCVVStepTitle`: return workflowsBoostCardActivationGroupsThreeDigitCVVStepTitle;
    case `workflowsBoostCardActivationSuccessCta`: return workflowsBoostCardActivationSuccessCta;
    case `workflowsBoostCardActivationSuccessMessage`: return workflowsBoostCardActivationSuccessMessage;
    case `workflowsBoostCardActivationSuccessTitle`: return workflowsBoostCardActivationSuccessTitle;
    case `workflowsBoostCardActivationTitle`: return workflowsBoostCardActivationTitle;
    case `workflowsBoostCardGroupsAddressStepHelpText`: return workflowsBoostCardGroupsAddressStepHelpText;
    case `workflowsBoostCardGroupsAddressStepHelpTitle`: return workflowsBoostCardGroupsAddressStepHelpTitle;
    case `workflowsBoostCardGroupsAddressStepTitle`: return workflowsBoostCardGroupsAddressStepTitle;
    case `workflowsBoostCardGroupsBoostCardActivationCardCVVStepHelpText`: return workflowsBoostCardGroupsBoostCardActivationCardCVVStepHelpText;
    case `workflowsBoostCardGroupsBoostCardActivationCardCVVStepHelpTitle`: return workflowsBoostCardGroupsBoostCardActivationCardCVVStepHelpTitle;
    case `workflowsBoostCardGroupsBoostCardActivationCardCVVStepTitle`: return workflowsBoostCardGroupsBoostCardActivationCardCVVStepTitle;
    case `workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepHelpText`: return workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepHelpText;
    case `workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepHelpTitle`: return workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepHelpTitle;
    case `workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepTitle`: return workflowsBoostCardGroupsBoostCardActivationCardCreatePINStepTitle;
    case `workflowsBoostCardGroupsBoostCardActivationCardLastFourStepHelpText`: return workflowsBoostCardGroupsBoostCardActivationCardLastFourStepHelpText;
    case `workflowsBoostCardGroupsBoostCardActivationCardLastFourStepHelpTitle`: return workflowsBoostCardGroupsBoostCardActivationCardLastFourStepHelpTitle;
    case `workflowsBoostCardGroupsBoostCardActivationCardLastFourStepTitle`: return workflowsBoostCardGroupsBoostCardActivationCardLastFourStepTitle;
    case `workflowsBoostCardGroupsBoostCardCheckoutStepHelpText`: return workflowsBoostCardGroupsBoostCardCheckoutStepHelpText;
    case `workflowsBoostCardGroupsBoostCardCheckoutStepHelpTitle`: return workflowsBoostCardGroupsBoostCardCheckoutStepHelpTitle;
    case `workflowsBoostCardGroupsBoostCardCheckoutStepTitle`: return workflowsBoostCardGroupsBoostCardCheckoutStepTitle;
    case `workflowsBoostCardGroupsBoostCardInformationStepHelpText`: return workflowsBoostCardGroupsBoostCardInformationStepHelpText;
    case `workflowsBoostCardGroupsBoostCardInformationStepHelpTitle`: return workflowsBoostCardGroupsBoostCardInformationStepHelpTitle;
    case `workflowsBoostCardGroupsBoostCardInformationStepTitle`: return workflowsBoostCardGroupsBoostCardInformationStepTitle;
    case `workflowsBoostCardGroupsBoostCardQualificationStepHelpText`: return workflowsBoostCardGroupsBoostCardQualificationStepHelpText;
    case `workflowsBoostCardGroupsBoostCardQualificationStepHelpTitle`: return workflowsBoostCardGroupsBoostCardQualificationStepHelpTitle;
    case `workflowsBoostCardGroupsBoostCardQualificationStepTitle`: return workflowsBoostCardGroupsBoostCardQualificationStepTitle;
    case `workflowsBoostCardGroupsConfirmEmailStepHelpText`: return workflowsBoostCardGroupsConfirmEmailStepHelpText;
    case `workflowsBoostCardGroupsConfirmEmailStepHelpTitle`: return workflowsBoostCardGroupsConfirmEmailStepHelpTitle;
    case `workflowsBoostCardGroupsConfirmEmailStepTitle`: return workflowsBoostCardGroupsConfirmEmailStepTitle;
    case `workflowsBoostCardGroupsConfirmLastFourSsnStepHelpText`: return workflowsBoostCardGroupsConfirmLastFourSsnStepHelpText;
    case `workflowsBoostCardGroupsConfirmLastFourSsnStepHelpTitle`: return workflowsBoostCardGroupsConfirmLastFourSsnStepHelpTitle;
    case `workflowsBoostCardGroupsConfirmLastFourSsnStepTitle`: return workflowsBoostCardGroupsConfirmLastFourSsnStepTitle;
    case `workflowsBoostCardGroupsConfirmPhoneNumberStepHelpText`: return workflowsBoostCardGroupsConfirmPhoneNumberStepHelpText;
    case `workflowsBoostCardGroupsConfirmPhoneNumberStepHelpTitle`: return workflowsBoostCardGroupsConfirmPhoneNumberStepHelpTitle;
    case `workflowsBoostCardGroupsConfirmPhoneNumberStepTitle`: return workflowsBoostCardGroupsConfirmPhoneNumberStepTitle;
    case `workflowsBoostCardGroupsFullSSNStepHelpText`: return workflowsBoostCardGroupsFullSSNStepHelpText;
    case `workflowsBoostCardGroupsFullSSNStepHelpTitle`: return workflowsBoostCardGroupsFullSSNStepHelpTitle;
    case `workflowsBoostCardGroupsFullSSNStepTitle`: return workflowsBoostCardGroupsFullSSNStepTitle;
    case `workflowsBoostCardGroupsMFAPinStepHelpText`: return workflowsBoostCardGroupsMFAPinStepHelpText;
    case `workflowsBoostCardGroupsMFAPinStepHelpTitle`: return workflowsBoostCardGroupsMFAPinStepHelpTitle;
    case `workflowsBoostCardGroupsMFAPinStepTitle`: return workflowsBoostCardGroupsMFAPinStepTitle;
    case `workflowsBoostCardGroupsPhoneNumberStepHelpText`: return workflowsBoostCardGroupsPhoneNumberStepHelpText;
    case `workflowsBoostCardGroupsPhoneNumberStepHelpTitle`: return workflowsBoostCardGroupsPhoneNumberStepHelpTitle;
    case `workflowsBoostCardGroupsPhoneNumberStepTitle`: return workflowsBoostCardGroupsPhoneNumberStepTitle;
    case `workflowsBoostCardGroupsSSNStepHelpText`: return workflowsBoostCardGroupsSSNStepHelpText;
    case `workflowsBoostCardGroupsSSNStepHelpTitle`: return workflowsBoostCardGroupsSSNStepHelpTitle;
    case `workflowsBoostCardGroupsSSNStepTitle`: return workflowsBoostCardGroupsSSNStepTitle;
    case `workflowsBoostCardGroupsTermsOfServiceStepHelpText`: return workflowsBoostCardGroupsTermsOfServiceStepHelpText;
    case `workflowsBoostCardGroupsTermsOfServiceStepHelpTitle`: return workflowsBoostCardGroupsTermsOfServiceStepHelpTitle;
    case `workflowsBoostCardGroupsTermsOfServiceStepTitle`: return workflowsBoostCardGroupsTermsOfServiceStepTitle;
    case `workflowsBoostCardSetupGroupTitle`: return workflowsBoostCardSetupGroupTitle;
    case `workflowsBoostCardSetupTitle`: return workflowsBoostCardSetupTitle;
    case `workflowsBoostResultOffboardingReasonIncomeTooHigh`: return workflowsBoostResultOffboardingReasonIncomeTooHigh;
    case `workflowsBoostResultOffboardingReasonNoEligibleBoost`: return workflowsBoostResultOffboardingReasonNoEligibleBoost;
    case `workflowsBoostResultOffboardingReasonNoKnownIncome`: return workflowsBoostResultOffboardingReasonNoKnownIncome;
    case `workflowsBoostResultOffboardingReasonNoRefundWithholdings`: return workflowsBoostResultOffboardingReasonNoRefundWithholdings;
    case `workflowsBoostResultOffboardingReasonOwesMoney`: return workflowsBoostResultOffboardingReasonOwesMoney;
    case `workflowsBoostResultOffboardingReasonUnknownPayrollIssue`: return workflowsBoostResultOffboardingReasonUnknownPayrollIssue;
    case `workflowsBoostResultOffboardingTitle`: return workflowsBoostResultOffboardingTitle;
    case `workflowsCashBoostGroupsCashBoostAcceptStepHelpText`: return workflowsCashBoostGroupsCashBoostAcceptStepHelpText;
    case `workflowsCashBoostGroupsCashBoostAcceptStepHelpTitle`: return workflowsCashBoostGroupsCashBoostAcceptStepHelpTitle;
    case `workflowsCashBoostGroupsCashBoostAcceptStepTitle`: return workflowsCashBoostGroupsCashBoostAcceptStepTitle;
    case `workflowsCashBoostGroupsCashBoostAddressStepHelpText`: return workflowsCashBoostGroupsCashBoostAddressStepHelpText;
    case `workflowsCashBoostGroupsCashBoostAddressStepHelpTitle`: return workflowsCashBoostGroupsCashBoostAddressStepHelpTitle;
    case `workflowsCashBoostGroupsCashBoostAddressStepTitle`: return workflowsCashBoostGroupsCashBoostAddressStepTitle;
    case `workflowsCashBoostGroupsCashBoostCheckoutStepHelpText`: return workflowsCashBoostGroupsCashBoostCheckoutStepHelpText;
    case `workflowsCashBoostGroupsCashBoostCheckoutStepHelpTitle`: return workflowsCashBoostGroupsCashBoostCheckoutStepHelpTitle;
    case `workflowsCashBoostGroupsCashBoostCheckoutStepTitle`: return workflowsCashBoostGroupsCashBoostCheckoutStepTitle;
    case `workflowsCashBoostGroupsCashBoostCollectFootprintStepHelpText`: return workflowsCashBoostGroupsCashBoostCollectFootprintStepHelpText;
    case `workflowsCashBoostGroupsCashBoostCollectFootprintStepHelpTitle`: return workflowsCashBoostGroupsCashBoostCollectFootprintStepHelpTitle;
    case `workflowsCashBoostGroupsCashBoostCollectFootprintStepTitle`: return workflowsCashBoostGroupsCashBoostCollectFootprintStepTitle;
    case `workflowsCashBoostGroupsCashBoostIntroStepHelpText`: return workflowsCashBoostGroupsCashBoostIntroStepHelpText;
    case `workflowsCashBoostGroupsCashBoostIntroStepHelpTitle`: return workflowsCashBoostGroupsCashBoostIntroStepHelpTitle;
    case `workflowsCashBoostGroupsCashBoostIntroStepTitle`: return workflowsCashBoostGroupsCashBoostIntroStepTitle;
    case `workflowsCashBoostGroupsCashBoostLastFourSsnStepHelpText`: return workflowsCashBoostGroupsCashBoostLastFourSsnStepHelpText;
    case `workflowsCashBoostGroupsCashBoostLastFourSsnStepHelpTitle`: return workflowsCashBoostGroupsCashBoostLastFourSsnStepHelpTitle;
    case `workflowsCashBoostGroupsCashBoostLastFourSsnStepTitle`: return workflowsCashBoostGroupsCashBoostLastFourSsnStepTitle;
    case `workflowsCashBoostGroupsCashBoostPhoneNumberStepHelpText`: return workflowsCashBoostGroupsCashBoostPhoneNumberStepHelpText;
    case `workflowsCashBoostGroupsCashBoostPhoneNumberStepHelpTitle`: return workflowsCashBoostGroupsCashBoostPhoneNumberStepHelpTitle;
    case `workflowsCashBoostGroupsCashBoostPhoneNumberStepTextFieldTitle`: return workflowsCashBoostGroupsCashBoostPhoneNumberStepTextFieldTitle;
    case `workflowsCashBoostGroupsCashBoostPhoneNumberStepTitle`: return workflowsCashBoostGroupsCashBoostPhoneNumberStepTitle;
    case `workflowsCashBoostGroupsCashBoostQualificationStepHelpText`: return workflowsCashBoostGroupsCashBoostQualificationStepHelpText;
    case `workflowsCashBoostGroupsCashBoostQualificationStepHelpTitle`: return workflowsCashBoostGroupsCashBoostQualificationStepHelpTitle;
    case `workflowsCashBoostGroupsCashBoostQualificationStepTitle`: return workflowsCashBoostGroupsCashBoostQualificationStepTitle;
    case `workflowsCashBoostGroupsConnectBankAccountStepHelpText`: return workflowsCashBoostGroupsConnectBankAccountStepHelpText;
    case `workflowsCashBoostGroupsConnectBankAccountStepHelpTitle`: return workflowsCashBoostGroupsConnectBankAccountStepHelpTitle;
    case `workflowsCashBoostGroupsConnectBankAccountStepTitle`: return workflowsCashBoostGroupsConnectBankAccountStepTitle;
    case `workflowsCashBoostGroupsConnectDebitCardStepHelpText`: return workflowsCashBoostGroupsConnectDebitCardStepHelpText;
    case `workflowsCashBoostGroupsConnectDebitCardStepHelpTitle`: return workflowsCashBoostGroupsConnectDebitCardStepHelpTitle;
    case `workflowsCashBoostGroupsConnectDebitCardStepTitle`: return workflowsCashBoostGroupsConnectDebitCardStepTitle;
    case `workflowsCashBoostGroupsLegalNameAndDateOfBirthStepHelpText`: return workflowsCashBoostGroupsLegalNameAndDateOfBirthStepHelpText;
    case `workflowsCashBoostGroupsLegalNameAndDateOfBirthStepHelpTitle`: return workflowsCashBoostGroupsLegalNameAndDateOfBirthStepHelpTitle;
    case `workflowsCashBoostGroupsLegalNameAndDateOfBirthStepTitle`: return workflowsCashBoostGroupsLegalNameAndDateOfBirthStepTitle;
    case `workflowsCashBoostGroupsPreSelectBankAccountStepHelpText`: return workflowsCashBoostGroupsPreSelectBankAccountStepHelpText;
    case `workflowsCashBoostGroupsPreSelectBankAccountStepHelpTitle`: return workflowsCashBoostGroupsPreSelectBankAccountStepHelpTitle;
    case `workflowsCashBoostGroupsPreSelectBankAccountStepTitle`: return workflowsCashBoostGroupsPreSelectBankAccountStepTitle;
    case `workflowsCashBoostGroupsSetupStripeStepHelpText`: return workflowsCashBoostGroupsSetupStripeStepHelpText;
    case `workflowsCashBoostGroupsSetupStripeStepHelpTitle`: return workflowsCashBoostGroupsSetupStripeStepHelpTitle;
    case `workflowsCashBoostGroupsSetupStripeStepTitle`: return workflowsCashBoostGroupsSetupStripeStepTitle;
    case `workflowsCashBoostGroupsVerifyBankAccountStepHelpText`: return workflowsCashBoostGroupsVerifyBankAccountStepHelpText;
    case `workflowsCashBoostGroupsVerifyBankAccountStepHelpTitle`: return workflowsCashBoostGroupsVerifyBankAccountStepHelpTitle;
    case `workflowsCashBoostGroupsVerifyBankAccountStepTitle`: return workflowsCashBoostGroupsVerifyBankAccountStepTitle;
    case `workflowsCashBoostSetupGroupsTitle`: return workflowsCashBoostSetupGroupsTitle;
    case `workflowsCashBoostSetupTitle`: return workflowsCashBoostSetupTitle;
    case `workflowsClaimsApplicationGroupsCompanyRoleConfirmationTitle`: return workflowsClaimsApplicationGroupsCompanyRoleConfirmationTitle;
    case `workflowsClaimsApplicationGroupsCompanyRoleHelpText`: return workflowsClaimsApplicationGroupsCompanyRoleHelpText;
    case `workflowsClaimsApplicationGroupsCompanyRoleHelpTitle`: return workflowsClaimsApplicationGroupsCompanyRoleHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadHelpText`: return workflowsClaimsApplicationGroupsDocumentsUploadHelpText;
    case `workflowsClaimsApplicationGroupsDocumentsUploadHelpTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadIntroHelpText`: return workflowsClaimsApplicationGroupsDocumentsUploadIntroHelpText;
    case `workflowsClaimsApplicationGroupsDocumentsUploadIntroHelpTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadIntroHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadIntroStepHelpTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadIntroStepHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadIntroTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadIntroTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadLastPayStubHelpText`: return workflowsClaimsApplicationGroupsDocumentsUploadLastPayStubHelpText;
    case `workflowsClaimsApplicationGroupsDocumentsUploadLastPayStubHelpTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadLastPayStubHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubDocumentPrompt`: return workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubDocumentPrompt;
    case `workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubDocumentType`: return workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubDocumentType;
    case `workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadLastPaystubTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPayStubHelpText`: return workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPayStubHelpText;
    case `workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPayStubHelpTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPayStubHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubDocumentPrompt`: return workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubDocumentPrompt;
    case `workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubDocumentType`: return workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubDocumentType;
    case `workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadSecondLastPaystubTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterDocumentType`: return workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterDocumentType;
    case `workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterHelpText`: return workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterHelpText;
    case `workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterHelpTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadStateUnemployementLetterTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterDocumentType`: return workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterDocumentType;
    case `workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterHelpText`: return workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterHelpText;
    case `workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterHelpTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadTerminationLetterTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPayStubHelpText`: return workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPayStubHelpText;
    case `workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPayStubHelpTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPayStubHelpTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubDocumentPrompt`: return workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubDocumentPrompt;
    case `workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubDocumentType`: return workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubDocumentType;
    case `workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadThirdLastPaystubTitle;
    case `workflowsClaimsApplicationGroupsDocumentsUploadTitle`: return workflowsClaimsApplicationGroupsDocumentsUploadTitle;
    case `workflowsClaimsApplicationGroupsEmployeeTypeConfirmationTitle`: return workflowsClaimsApplicationGroupsEmployeeTypeConfirmationTitle;
    case `workflowsClaimsApplicationGroupsEmployeeTypeHelpText`: return workflowsClaimsApplicationGroupsEmployeeTypeHelpText;
    case `workflowsClaimsApplicationGroupsEmployeeTypeHelpTitle`: return workflowsClaimsApplicationGroupsEmployeeTypeHelpTitle;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationDateHelpText`: return workflowsClaimsApplicationGroupsEmploymentTerminationDateHelpText;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationDateHelpTitle`: return workflowsClaimsApplicationGroupsEmploymentTerminationDateHelpTitle;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationDateQuestion`: return workflowsClaimsApplicationGroupsEmploymentTerminationDateQuestion;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationDateTitle`: return workflowsClaimsApplicationGroupsEmploymentTerminationDateTitle;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonHelpContentText`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonHelpContentText;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonHelpContentTitle`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonHelpContentTitle;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonQuestion`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonQuestion;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonTitle`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonTitle;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesFired`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesFired;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesLayoff`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesLayoff;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesVoluntary`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeChoicesVoluntary;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeHelpContentTitle`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeHelpContentTitle;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeHelpContextText`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeHelpContextText;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeQuestion`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeQuestion;
    case `workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeTitle`: return workflowsClaimsApplicationGroupsEmploymentTerminationReasonTypeTitle;
    case `workflowsClaimsApplicationGroupsHowItWorksHelpText`: return workflowsClaimsApplicationGroupsHowItWorksHelpText;
    case `workflowsClaimsApplicationGroupsHowItWorksHelpTitle`: return workflowsClaimsApplicationGroupsHowItWorksHelpTitle;
    case `workflowsClaimsApplicationGroupsHowItWorksTitle`: return workflowsClaimsApplicationGroupsHowItWorksTitle;
    case `workflowsClaimsApplicationGroupsIntroHelpText`: return workflowsClaimsApplicationGroupsIntroHelpText;
    case `workflowsClaimsApplicationGroupsIntroHelpTitle`: return workflowsClaimsApplicationGroupsIntroHelpTitle;
    case `workflowsClaimsApplicationGroupsIntroTitle`: return workflowsClaimsApplicationGroupsIntroTitle;
    case `workflowsClaimsApplicationGroupsJobHistoryConfirmationHelpContentText`: return workflowsClaimsApplicationGroupsJobHistoryConfirmationHelpContentText;
    case `workflowsClaimsApplicationGroupsJobHistoryConfirmationHelpContentTitle`: return workflowsClaimsApplicationGroupsJobHistoryConfirmationHelpContentTitle;
    case `workflowsClaimsApplicationGroupsJobHistoryConfirmationQuestion`: return workflowsClaimsApplicationGroupsJobHistoryConfirmationQuestion;
    case `workflowsClaimsApplicationGroupsJobHistoryConfirmationTitle`: return workflowsClaimsApplicationGroupsJobHistoryConfirmationTitle;
    case `workflowsClaimsApplicationGroupsPayoutInfoHelpContentText`: return workflowsClaimsApplicationGroupsPayoutInfoHelpContentText;
    case `workflowsClaimsApplicationGroupsPayoutInfoHelpContentTitle`: return workflowsClaimsApplicationGroupsPayoutInfoHelpContentTitle;
    case `workflowsClaimsApplicationGroupsPayoutInfoTitle`: return workflowsClaimsApplicationGroupsPayoutInfoTitle;
    case `workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationHelpContentText`: return workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationHelpContentText;
    case `workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationHelpContentTitle`: return workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationHelpContentTitle;
    case `workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationQuestion`: return workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationQuestion;
    case `workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationTitle`: return workflowsClaimsApplicationGroupsPolicyTermsNotModifiedConfirmationTitle;
    case `workflowsClaimsApplicationGroupsPostSubmissionInfoHelpText`: return workflowsClaimsApplicationGroupsPostSubmissionInfoHelpText;
    case `workflowsClaimsApplicationGroupsPostSubmissionInfoHelpTitle`: return workflowsClaimsApplicationGroupsPostSubmissionInfoHelpTitle;
    case `workflowsClaimsApplicationGroupsPostSubmissionInfoTitle`: return workflowsClaimsApplicationGroupsPostSubmissionInfoTitle;
    case `workflowsClaimsApplicationGroupsSignatureHelpContentText`: return workflowsClaimsApplicationGroupsSignatureHelpContentText;
    case `workflowsClaimsApplicationGroupsSignatureHelpContentTitle`: return workflowsClaimsApplicationGroupsSignatureHelpContentTitle;
    case `workflowsClaimsApplicationGroupsSignatureTitle`: return workflowsClaimsApplicationGroupsSignatureTitle;
    case `workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationHelpContentText`: return workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationHelpContentText;
    case `workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationHelpContentTitle`: return workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationHelpContentTitle;
    case `workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationQuestion`: return workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationQuestion;
    case `workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationTitle`: return workflowsClaimsApplicationGroupsStateUnemploymentBenefitsConfirmationTitle;
    case `workflowsClaimsApplicationGroupsTitle`: return workflowsClaimsApplicationGroupsTitle;
    case `workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesBankAccount`: return workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesBankAccount;
    case `workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesDebitCard`: return workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesDebitCard;
    case `workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesPhysicalCheck`: return workflowsClaimsApplicationGroupsUnemploymentCheckLocationChoicesPhysicalCheck;
    case `workflowsClaimsApplicationGroupsUnemploymentCheckLocationHelpContentTitle`: return workflowsClaimsApplicationGroupsUnemploymentCheckLocationHelpContentTitle;
    case `workflowsClaimsApplicationGroupsUnemploymentCheckLocationHelpContextText`: return workflowsClaimsApplicationGroupsUnemploymentCheckLocationHelpContextText;
    case `workflowsClaimsApplicationGroupsUnemploymentCheckLocationQuestion`: return workflowsClaimsApplicationGroupsUnemploymentCheckLocationQuestion;
    case `workflowsClaimsApplicationGroupsUnemploymentCheckLocationTitle`: return workflowsClaimsApplicationGroupsUnemploymentCheckLocationTitle;
    case `workflowsClaimsApplicationTitle`: return workflowsClaimsApplicationTitle;
    case `workflowsComponentsAmountButtonConfirm`: return workflowsComponentsAmountButtonConfirm;
    case `workflowsComponentsAmountPlaceholder`: return workflowsComponentsAmountPlaceholder;
    case `workflowsComponentsCatchupButtonFederalTaxes`: return workflowsComponentsCatchupButtonFederalTaxes;
    case `workflowsComponentsCatchupButtonGrossIncome`: return workflowsComponentsCatchupButtonGrossIncome;
    case `workflowsComponentsCatchupButtonSkip`: return workflowsComponentsCatchupButtonSkip;
    case `workflowsComponentsCatchupButtonStateTaxes`: return workflowsComponentsCatchupButtonStateTaxes;
    case `workflowsComponentsCatchupButtonSubmit`: return workflowsComponentsCatchupButtonSubmit;
    case `workflowsComponentsCatchupTitle`: return workflowsComponentsCatchupTitle;
    case `workflowsComponentsChoiceAnswerNone`: return workflowsComponentsChoiceAnswerNone;
    case `workflowsComponentsChoiceButtonConfirm`: return workflowsComponentsChoiceButtonConfirm;
    case `workflowsComponentsChoiceButtonSkip`: return workflowsComponentsChoiceButtonSkip;
    case `workflowsComponentsCreateJobButtonConfirm`: return workflowsComponentsCreateJobButtonConfirm;
    case `workflowsComponentsCurrencyPlaceholder`: return workflowsComponentsCurrencyPlaceholder;
    case `workflowsComponentsDataEntryButtonSubmit`: return workflowsComponentsDataEntryButtonSubmit;
    case `workflowsComponentsDateButtonConfirm`: return workflowsComponentsDateButtonConfirm;
    case `workflowsComponentsDateErrorInvalidInput`: return workflowsComponentsDateErrorInvalidInput;
    case `workflowsComponentsDateErrorTooHigh`: return workflowsComponentsDateErrorTooHigh;
    case `workflowsComponentsDateErrorTooLow`: return workflowsComponentsDateErrorTooLow;
    case `workflowsComponentsDatePlaceholder`: return workflowsComponentsDatePlaceholder;
    case `workflowsComponentsDocumentButtonDocumentRequestsFiles`: return workflowsComponentsDocumentButtonDocumentRequestsFiles;
    case `workflowsComponentsDocumentButtonDocumentRequestsPhotos`: return workflowsComponentsDocumentButtonDocumentRequestsPhotos;
    case `workflowsComponentsDocumentButtonDocumentRequestsScan`: return workflowsComponentsDocumentButtonDocumentRequestsScan;
    case `workflowsComponentsDocumentButtonDocumentRequestsSkip`: return workflowsComponentsDocumentButtonDocumentRequestsSkip;
    case `workflowsComponentsDocumentButtonDoneContinue`: return workflowsComponentsDocumentButtonDoneContinue;
    case `workflowsComponentsDocumentButtonIntroContinue`: return workflowsComponentsDocumentButtonIntroContinue;
    case `workflowsComponentsDocumentButtonIntroSkip`: return workflowsComponentsDocumentButtonIntroSkip;
    case `workflowsComponentsDocumentDeliveryButtonConfirm`: return workflowsComponentsDocumentDeliveryButtonConfirm;
    case `workflowsComponentsDocumentDeliveryLabelNoFiles`: return workflowsComponentsDocumentDeliveryLabelNoFiles;
    case `workflowsComponentsDocumentPromptDefaultDocumentRequestTitle`: return workflowsComponentsDocumentPromptDefaultDocumentRequestTitle;
    case `workflowsComponentsDocumentPromptDone`: return workflowsComponentsDocumentPromptDone;
    case `workflowsComponentsDocumentPromptFirst`: return workflowsComponentsDocumentPromptFirst;
    case `workflowsComponentsDocumentPromptIntro`: return workflowsComponentsDocumentPromptIntro;
    case `workflowsComponentsDocumentPromptLast`: return workflowsComponentsDocumentPromptLast;
    case `workflowsComponentsDocumentPromptNext`: return workflowsComponentsDocumentPromptNext;
    case `workflowsComponentsDocumentPromptOnly`: return workflowsComponentsDocumentPromptOnly;
    case `workflowsComponentsInterstitialCaption`: return workflowsComponentsInterstitialCaption;
    case `workflowsComponentsInterstitialMessage`: return workflowsComponentsInterstitialMessage;
    case `workflowsComponentsJobsAddRowTitle`: return workflowsComponentsJobsAddRowTitle;
    case `workflowsComponentsJobsRowAction`: return workflowsComponentsJobsRowAction;
    case `workflowsComponentsJobsRowLabelIndex`: return workflowsComponentsJobsRowLabelIndex;
    case `workflowsComponentsJobsRowLabelTitleDefault`: return workflowsComponentsJobsRowLabelTitleDefault;
    case `workflowsComponentsJobsRowProvidedNotTestable`: return workflowsComponentsJobsRowProvidedNotTestable;
    case `workflowsComponentsMonthlyBreakdownButtonContinue`: return workflowsComponentsMonthlyBreakdownButtonContinue;
    case `workflowsComponentsMonthlyBreakdownHeaderTakeHomePay`: return workflowsComponentsMonthlyBreakdownHeaderTakeHomePay;
    case `workflowsComponentsPayrollButtonBegin`: return workflowsComponentsPayrollButtonBegin;
    case `workflowsComponentsPayrollButtonContinue`: return workflowsComponentsPayrollButtonContinue;
    case `workflowsComponentsPayrollConnectionCredentialsInvalidErrorText`: return workflowsComponentsPayrollConnectionCredentialsInvalidErrorText;
    case `workflowsComponentsPayrollConnectionInputIncompleteErrorText`: return workflowsComponentsPayrollConnectionInputIncompleteErrorText;
    case `workflowsComponentsPayrollConnectionStateInvalidErrorText`: return workflowsComponentsPayrollConnectionStateInvalidErrorText;
    case `workflowsComponentsPayrollQuestionDone`: return workflowsComponentsPayrollQuestionDone;
    case `workflowsComponentsPayrollQuestionError`: return workflowsComponentsPayrollQuestionError;
    case `workflowsComponentsPayrollQuestionErrorDefaultJobName`: return workflowsComponentsPayrollQuestionErrorDefaultJobName;
    case `workflowsComponentsPayrollQuestionFirstJob`: return workflowsComponentsPayrollQuestionFirstJob;
    case `workflowsComponentsPayrollQuestionNextJob`: return workflowsComponentsPayrollQuestionNextJob;
    case `workflowsComponentsPayrollQuestionSecondJob`: return workflowsComponentsPayrollQuestionSecondJob;
    case `workflowsComponentsPermissionsNotificationsButtonAction`: return workflowsComponentsPermissionsNotificationsButtonAction;
    case `workflowsComponentsPermissionsNotificationsButtonSkip`: return workflowsComponentsPermissionsNotificationsButtonSkip;
    case `workflowsComponentsPermissionsNotificationsTitle`: return workflowsComponentsPermissionsNotificationsTitle;
    case `workflowsComponentsReviewPayboostImpactMessage`: return workflowsComponentsReviewPayboostImpactMessage;
    case `workflowsComponentsReviewPayboostImpactMessageBoldedSubtext`: return workflowsComponentsReviewPayboostImpactMessageBoldedSubtext;
    case `workflowsComponentsReviewPayboostImpactTakeHomePayTitle`: return workflowsComponentsReviewPayboostImpactTakeHomePayTitle;
    case `workflowsComponentsReviewPayboostImpactTaxesTitle`: return workflowsComponentsReviewPayboostImpactTaxesTitle;
    case `workflowsComponentsReviewPayboostImpactTitle`: return workflowsComponentsReviewPayboostImpactTitle;
    case `workflowsComponentsReviewPayboostImpactWithoutGridSubtitle`: return workflowsComponentsReviewPayboostImpactWithoutGridSubtitle;
    case `workflowsComponentsSignupButtonConfirmTerms`: return workflowsComponentsSignupButtonConfirmTerms;
    case `workflowsComponentsSignupChoiceAgreeToTerms`: return workflowsComponentsSignupChoiceAgreeToTerms;
    case `workflowsComponentsTextButtonContinue`: return workflowsComponentsTextButtonContinue;
    case `workflowsComponentsTextButtonMultiline`: return workflowsComponentsTextButtonMultiline;
    case `workflowsComponentsTextButtonSingleline`: return workflowsComponentsTextButtonSingleline;
    case `workflowsComponentsYesnoNo`: return workflowsComponentsYesnoNo;
    case `workflowsComponentsYesnoYes`: return workflowsComponentsYesnoYes;
    case `workflowsConfirmEmailInstruction`: return workflowsConfirmEmailInstruction;
    case `workflowsConnectBankAccountGroupsTitle`: return workflowsConnectBankAccountGroupsTitle;
    case `workflowsConnectBankAccountTitle`: return workflowsConnectBankAccountTitle;
    case `workflowsConnectDebitCardGroupsTitle`: return workflowsConnectDebitCardGroupsTitle;
    case `workflowsConnectDebitCardTitle`: return workflowsConnectDebitCardTitle;
    case `workflowsCreditBuilderGroupsCreditBuilderCheckoutStepHelpText`: return workflowsCreditBuilderGroupsCreditBuilderCheckoutStepHelpText;
    case `workflowsCreditBuilderGroupsCreditBuilderCheckoutStepHelpTitle`: return workflowsCreditBuilderGroupsCreditBuilderCheckoutStepHelpTitle;
    case `workflowsCreditBuilderGroupsCreditBuilderCheckoutStepTitle`: return workflowsCreditBuilderGroupsCreditBuilderCheckoutStepTitle;
    case `workflowsCreditBuilderGroupsCreditBuilderIntroStepHelpText`: return workflowsCreditBuilderGroupsCreditBuilderIntroStepHelpText;
    case `workflowsCreditBuilderGroupsCreditBuilderIntroStepHelpTitle`: return workflowsCreditBuilderGroupsCreditBuilderIntroStepHelpTitle;
    case `workflowsCreditBuilderGroupsCreditBuilderIntroStepTitle`: return workflowsCreditBuilderGroupsCreditBuilderIntroStepTitle;
    case `workflowsCreditBuilderGroupsKBAStepHelpText`: return workflowsCreditBuilderGroupsKBAStepHelpText;
    case `workflowsCreditBuilderGroupsKBAStepHelpTitle`: return workflowsCreditBuilderGroupsKBAStepHelpTitle;
    case `workflowsCreditBuilderGroupsKBAStepTitle`: return workflowsCreditBuilderGroupsKBAStepTitle;
    case `workflowsCreditBuilderSetupGroupTitle`: return workflowsCreditBuilderSetupGroupTitle;
    case `workflowsCreditBuilderSetupTitle`: return workflowsCreditBuilderSetupTitle;
    case `workflowsDefaultsHelpText`: return workflowsDefaultsHelpText;
    case `workflowsDefaultsHelpTitle`: return workflowsDefaultsHelpTitle;
    case `workflowsDefaultsTitle`: return workflowsDefaultsTitle;
    case `workflowsExtraInfoGroupsFinancialCatchupFederalTaxesPlaceholder`: return workflowsExtraInfoGroupsFinancialCatchupFederalTaxesPlaceholder;
    case `workflowsExtraInfoGroupsFinancialCatchupFederalTaxesTitle`: return workflowsExtraInfoGroupsFinancialCatchupFederalTaxesTitle;
    case `workflowsExtraInfoGroupsFinancialCatchupGrossIncomePlaceholder`: return workflowsExtraInfoGroupsFinancialCatchupGrossIncomePlaceholder;
    case `workflowsExtraInfoGroupsFinancialCatchupGrossIncomeTitle`: return workflowsExtraInfoGroupsFinancialCatchupGrossIncomeTitle;
    case `workflowsExtraInfoGroupsFinancialCatchupHelpContentText`: return workflowsExtraInfoGroupsFinancialCatchupHelpContentText;
    case `workflowsExtraInfoGroupsFinancialCatchupHelpContentTitle`: return workflowsExtraInfoGroupsFinancialCatchupHelpContentTitle;
    case `workflowsExtraInfoGroupsFinancialCatchupStateTaxesPlaceholder`: return workflowsExtraInfoGroupsFinancialCatchupStateTaxesPlaceholder;
    case `workflowsExtraInfoGroupsFinancialCatchupStateTaxesTitle`: return workflowsExtraInfoGroupsFinancialCatchupStateTaxesTitle;
    case `workflowsExtraInfoGroupsFinancialCatchupText`: return workflowsExtraInfoGroupsFinancialCatchupText;
    case `workflowsExtraInfoGroupsFinancialCatchupTitle`: return workflowsExtraInfoGroupsFinancialCatchupTitle;
    case `workflowsExtraInfoGroupsFinancialGroupTitle`: return workflowsExtraInfoGroupsFinancialGroupTitle;
    case `workflowsExtraInfoIntroText`: return workflowsExtraInfoIntroText;
    case `workflowsExtraInfoTitle`: return workflowsExtraInfoTitle;
    case `workflowsFallbacksDocumentType`: return workflowsFallbacksDocumentType;
    case `workflowsFinancialGroupsCovid19ChoiceFurloughed`: return workflowsFinancialGroupsCovid19ChoiceFurloughed;
    case `workflowsFinancialGroupsCovid19ChoiceHoursReduced`: return workflowsFinancialGroupsCovid19ChoiceHoursReduced;
    case `workflowsFinancialGroupsCovid19ChoiceLaidOff`: return workflowsFinancialGroupsCovid19ChoiceLaidOff;
    case `workflowsFinancialGroupsCovid19ChoiceOther`: return workflowsFinancialGroupsCovid19ChoiceOther;
    case `workflowsFinancialGroupsCovid19ChoiceRecievingUnemployment`: return workflowsFinancialGroupsCovid19ChoiceRecievingUnemployment;
    case `workflowsFinancialGroupsCovid19ChoiceTipsReduced`: return workflowsFinancialGroupsCovid19ChoiceTipsReduced;
    case `workflowsFinancialGroupsCovid19CustomSkipText`: return workflowsFinancialGroupsCovid19CustomSkipText;
    case `workflowsFinancialGroupsCovid19HelpText`: return workflowsFinancialGroupsCovid19HelpText;
    case `workflowsFinancialGroupsCovid19HelpTitle`: return workflowsFinancialGroupsCovid19HelpTitle;
    case `workflowsFinancialGroupsCovid19QuestionText`: return workflowsFinancialGroupsCovid19QuestionText;
    case `workflowsFinancialGroupsCovid19Title`: return workflowsFinancialGroupsCovid19Title;
    case `workflowsFinancialGroupsDocumentsStepsInvestmentStatementsDocumentType`: return workflowsFinancialGroupsDocumentsStepsInvestmentStatementsDocumentType;
    case `workflowsFinancialGroupsDocumentsStepsInvestmentStatementsHelpText`: return workflowsFinancialGroupsDocumentsStepsInvestmentStatementsHelpText;
    case `workflowsFinancialGroupsDocumentsStepsInvestmentStatementsHelpTitle`: return workflowsFinancialGroupsDocumentsStepsInvestmentStatementsHelpTitle;
    case `workflowsFinancialGroupsDocumentsStepsInvestmentStatementsTitle`: return workflowsFinancialGroupsDocumentsStepsInvestmentStatementsTitle;
    case `workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnDocumentType`: return workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnDocumentType;
    case `workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnHelpText`: return workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnHelpText;
    case `workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnHelpTitle`: return workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnHelpTitle;
    case `workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnTitle`: return workflowsFinancialGroupsDocumentsStepsLastYearsTaxReturnTitle;
    case `workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsHelpText`: return workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsHelpText;
    case `workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsHelpTitle`: return workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsHelpTitle;
    case `workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsTitle`: return workflowsFinancialGroupsDocumentsStepsOnboardingDocRequestsTitle;
    case `workflowsFinancialGroupsDocumentsStepsRetirementStatementsDocumentType`: return workflowsFinancialGroupsDocumentsStepsRetirementStatementsDocumentType;
    case `workflowsFinancialGroupsDocumentsStepsRetirementStatementsHelpText`: return workflowsFinancialGroupsDocumentsStepsRetirementStatementsHelpText;
    case `workflowsFinancialGroupsDocumentsStepsRetirementStatementsHelpTitle`: return workflowsFinancialGroupsDocumentsStepsRetirementStatementsHelpTitle;
    case `workflowsFinancialGroupsDocumentsStepsRetirementStatementsTitle`: return workflowsFinancialGroupsDocumentsStepsRetirementStatementsTitle;
    case `workflowsFinancialGroupsDocumentsTitle`: return workflowsFinancialGroupsDocumentsTitle;
    case `workflowsFinancialGroupsPaydayStepsLastPayDateHelpText`: return workflowsFinancialGroupsPaydayStepsLastPayDateHelpText;
    case `workflowsFinancialGroupsPaydayStepsLastPayDateHelpTitle`: return workflowsFinancialGroupsPaydayStepsLastPayDateHelpTitle;
    case `workflowsFinancialGroupsPaydayStepsLastPayDateQuestion`: return workflowsFinancialGroupsPaydayStepsLastPayDateQuestion;
    case `workflowsFinancialGroupsPaydayStepsLastPayDateTitle`: return workflowsFinancialGroupsPaydayStepsLastPayDateTitle;
    case `workflowsFinancialGroupsPayrollStepsCalculatingBoostHelpText`: return workflowsFinancialGroupsPayrollStepsCalculatingBoostHelpText;
    case `workflowsFinancialGroupsPayrollStepsCalculatingBoostHelpTitle`: return workflowsFinancialGroupsPayrollStepsCalculatingBoostHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsCalculatingBoostPrompt`: return workflowsFinancialGroupsPayrollStepsCalculatingBoostPrompt;
    case `workflowsFinancialGroupsPayrollStepsCalculatingBoostTitle`: return workflowsFinancialGroupsPayrollStepsCalculatingBoostTitle;
    case `workflowsFinancialGroupsPayrollStepsConnectPayrollAccountHelpText`: return workflowsFinancialGroupsPayrollStepsConnectPayrollAccountHelpText;
    case `workflowsFinancialGroupsPayrollStepsConnectPayrollAccountHelpTitle`: return workflowsFinancialGroupsPayrollStepsConnectPayrollAccountHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsConnectPayrollAccountTitle`: return workflowsFinancialGroupsPayrollStepsConnectPayrollAccountTitle;
    case `workflowsFinancialGroupsPayrollStepsConnectPayrollHelpText`: return workflowsFinancialGroupsPayrollStepsConnectPayrollHelpText;
    case `workflowsFinancialGroupsPayrollStepsConnectPayrollHelpTitle`: return workflowsFinancialGroupsPayrollStepsConnectPayrollHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsConnectPayrollPrompt`: return workflowsFinancialGroupsPayrollStepsConnectPayrollPrompt;
    case `workflowsFinancialGroupsPayrollStepsConnectPayrollTitle`: return workflowsFinancialGroupsPayrollStepsConnectPayrollTitle;
    case `workflowsFinancialGroupsPayrollStepsDraftW4ReviewHelpText`: return workflowsFinancialGroupsPayrollStepsDraftW4ReviewHelpText;
    case `workflowsFinancialGroupsPayrollStepsDraftW4ReviewHelpTitle`: return workflowsFinancialGroupsPayrollStepsDraftW4ReviewHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsDraftW4ReviewPrompt`: return workflowsFinancialGroupsPayrollStepsDraftW4ReviewPrompt;
    case `workflowsFinancialGroupsPayrollStepsDraftW4ReviewTitle`: return workflowsFinancialGroupsPayrollStepsDraftW4ReviewTitle;
    case `workflowsFinancialGroupsPayrollStepsNumJobsHelpText`: return workflowsFinancialGroupsPayrollStepsNumJobsHelpText;
    case `workflowsFinancialGroupsPayrollStepsNumJobsHelpTitle`: return workflowsFinancialGroupsPayrollStepsNumJobsHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsNumJobsPlaceholder`: return workflowsFinancialGroupsPayrollStepsNumJobsPlaceholder;
    case `workflowsFinancialGroupsPayrollStepsNumJobsQuestion`: return workflowsFinancialGroupsPayrollStepsNumJobsQuestion;
    case `workflowsFinancialGroupsPayrollStepsNumJobsTitle`: return workflowsFinancialGroupsPayrollStepsNumJobsTitle;
    case `workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsHelpText`: return workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsHelpText;
    case `workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsHelpTitle`: return workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsPrompt`: return workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsPrompt;
    case `workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsTitle`: return workflowsFinancialGroupsPayrollStepsOnboardingDocUploadsTitle;
    case `workflowsFinancialGroupsPayrollStepsProjectionResultHelpText`: return workflowsFinancialGroupsPayrollStepsProjectionResultHelpText;
    case `workflowsFinancialGroupsPayrollStepsProjectionResultHelpTitle`: return workflowsFinancialGroupsPayrollStepsProjectionResultHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsProjectionResultPrompt`: return workflowsFinancialGroupsPayrollStepsProjectionResultPrompt;
    case `workflowsFinancialGroupsPayrollStepsProjectionResultTitle`: return workflowsFinancialGroupsPayrollStepsProjectionResultTitle;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsChoices401K`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsChoices401K;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesIra`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesIra;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesPension`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesPension;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesRoth401K`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesRoth401K;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesRothIra`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsChoicesRothIra;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsHelpText`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsHelpText;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsHelpTitle`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsQuestion`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsQuestion;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsSkip`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsSkip;
    case `workflowsFinancialGroupsPayrollStepsRetirementAccountsTitle`: return workflowsFinancialGroupsPayrollStepsRetirementAccountsTitle;
    case `workflowsFinancialGroupsPayrollStepsW4FormCreationHelpText`: return workflowsFinancialGroupsPayrollStepsW4FormCreationHelpText;
    case `workflowsFinancialGroupsPayrollStepsW4FormCreationHelpTitle`: return workflowsFinancialGroupsPayrollStepsW4FormCreationHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsW4FormCreationTitle`: return workflowsFinancialGroupsPayrollStepsW4FormCreationTitle;
    case `workflowsFinancialGroupsPayrollStepsW4FormSignatureHelpText`: return workflowsFinancialGroupsPayrollStepsW4FormSignatureHelpText;
    case `workflowsFinancialGroupsPayrollStepsW4FormSignatureHelpTitle`: return workflowsFinancialGroupsPayrollStepsW4FormSignatureHelpTitle;
    case `workflowsFinancialGroupsPayrollStepsW4FormSignaturePlaceholder`: return workflowsFinancialGroupsPayrollStepsW4FormSignaturePlaceholder;
    case `workflowsFinancialGroupsPayrollStepsW4FormSignaturePrompt`: return workflowsFinancialGroupsPayrollStepsW4FormSignaturePrompt;
    case `workflowsFinancialGroupsPayrollStepsW4FormSignatureTitle`: return workflowsFinancialGroupsPayrollStepsW4FormSignatureTitle;
    case `workflowsFinancialGroupsPayrollTitle`: return workflowsFinancialGroupsPayrollTitle;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceAMonth`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceAMonth;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceAWeek`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceAWeek;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceEveryTwoWeeks`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOnceEveryTwoWeeks;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOther`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesOther;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesTwiceAMonth`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyChoicesTwiceAMonth;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyHelpText`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyHelpText;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyHelpTitle`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyHelpTitle;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyQuestion`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyQuestion;
    case `workflowsFinancialGroupsPeriodFrequencyPayFrequencyTitle`: return workflowsFinancialGroupsPeriodFrequencyPayFrequencyTitle;
    case `workflowsFinancialIntroText`: return workflowsFinancialIntroText;
    case `workflowsFinancialOffboardingNoLastYearReturnDescription`: return workflowsFinancialOffboardingNoLastYearReturnDescription;
    case `workflowsFinancialOffboardingNoLastYearReturnTitle`: return workflowsFinancialOffboardingNoLastYearReturnTitle;
    case `workflowsFinancialOffboardingUnsupportedConnectionDescription`: return workflowsFinancialOffboardingUnsupportedConnectionDescription;
    case `workflowsFinancialOffboardingUnsupportedConnectionTitle`: return workflowsFinancialOffboardingUnsupportedConnectionTitle;
    case `workflowsFinancialOffboardingUnsupportedNoGridEligibleJobsDescription`: return workflowsFinancialOffboardingUnsupportedNoGridEligibleJobsDescription;
    case `workflowsFinancialOffboardingUnsupportedNoGridEligibleJobsTitle`: return workflowsFinancialOffboardingUnsupportedNoGridEligibleJobsTitle;
    case `workflowsFinancialOffboardingUnsupportedNoJobsDescription`: return workflowsFinancialOffboardingUnsupportedNoJobsDescription;
    case `workflowsFinancialOffboardingUnsupportedNoJobsTitle`: return workflowsFinancialOffboardingUnsupportedNoJobsTitle;
    case `workflowsFinancialOffboardingUnsupportedTooManyJobsDescription`: return workflowsFinancialOffboardingUnsupportedTooManyJobsDescription;
    case `workflowsFinancialOffboardingUnsupportedTooManyJobsTitle`: return workflowsFinancialOffboardingUnsupportedTooManyJobsTitle;
    case `workflowsFinancialTitle`: return workflowsFinancialTitle;
    case `workflowsGridCardGroupsPayBoostCheckoutStepHelpText`: return workflowsGridCardGroupsPayBoostCheckoutStepHelpText;
    case `workflowsGridCardGroupsPayBoostCheckoutStepHelpTitle`: return workflowsGridCardGroupsPayBoostCheckoutStepHelpTitle;
    case `workflowsGridCardGroupsPayBoostCheckoutStepTitle`: return workflowsGridCardGroupsPayBoostCheckoutStepTitle;
    case `workflowsGridCardGroupsTemporaryGridCardHowItWorksStepHelpText`: return workflowsGridCardGroupsTemporaryGridCardHowItWorksStepHelpText;
    case `workflowsGridCardGroupsTemporaryGridCardHowItWorksStepHelpTitle`: return workflowsGridCardGroupsTemporaryGridCardHowItWorksStepHelpTitle;
    case `workflowsGridCardGroupsTemporaryGridCardHowItWorksStepTitle`: return workflowsGridCardGroupsTemporaryGridCardHowItWorksStepTitle;
    case `workflowsGridCardGroupsTemporaryGridCardMobileWalletStepHelpText`: return workflowsGridCardGroupsTemporaryGridCardMobileWalletStepHelpText;
    case `workflowsGridCardGroupsTemporaryGridCardMobileWalletStepHelpTitle`: return workflowsGridCardGroupsTemporaryGridCardMobileWalletStepHelpTitle;
    case `workflowsGridCardGroupsTemporaryGridCardMobileWalletStepTitle`: return workflowsGridCardGroupsTemporaryGridCardMobileWalletStepTitle;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenHelpText`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenPlaceholder`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenPlaceholder;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenQuestion`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsHelpText`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsPlaceholder`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsPlaceholder;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsQuestion`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsTitle`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenStudentsTitle;
    case `workflowsHouseholdGroupsHouseholdStepsAdultChildrenTitle`: return workflowsHouseholdGroupsHouseholdStepsAdultChildrenTitle;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesBoughtHouseText`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesBoughtHouseText;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesChildBornText`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesChildBornText;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesMarriedText`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesMarriedText;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesMovedText`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesMovedText;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesOtherText`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesOtherText;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesSwitchedJobsText`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearChoicesSwitchedJobsText;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearHelpText`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearQuestion`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearSkip`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearSkip;
    case `workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearTitle`: return workflowsHouseholdGroupsHouseholdStepsBigChangesThisYearTitle;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareHelpText`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCarePlaceholder`: return workflowsHouseholdGroupsHouseholdStepsChildrenCarePlaceholder;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareQuestion`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareTitle`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareTitle;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareV2HelpText`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareV2HelpText;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareV2HelpTitle`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareV2HelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Placeholder`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Placeholder;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Question`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Question;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Title`: return workflowsHouseholdGroupsHouseholdStepsChildrenCareV2Title;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenHelpText`: return workflowsHouseholdGroupsHouseholdStepsChildrenHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsChildrenHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenPlaceholder`: return workflowsHouseholdGroupsHouseholdStepsChildrenPlaceholder;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenQuestion`: return workflowsHouseholdGroupsHouseholdStepsChildrenQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenTitle`: return workflowsHouseholdGroupsHouseholdStepsChildrenTitle;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenV2HelpText`: return workflowsHouseholdGroupsHouseholdStepsChildrenV2HelpText;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenV2HelpTitle`: return workflowsHouseholdGroupsHouseholdStepsChildrenV2HelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenV2Placeholder`: return workflowsHouseholdGroupsHouseholdStepsChildrenV2Placeholder;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenV2Question`: return workflowsHouseholdGroupsHouseholdStepsChildrenV2Question;
    case `workflowsHouseholdGroupsHouseholdStepsChildrenV2Title`: return workflowsHouseholdGroupsHouseholdStepsChildrenV2Title;
    case `workflowsHouseholdGroupsHouseholdStepsCitizenshipHelpText`: return workflowsHouseholdGroupsHouseholdStepsCitizenshipHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsCitizenshipHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsCitizenshipHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsCitizenshipQuestion`: return workflowsHouseholdGroupsHouseholdStepsCitizenshipQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsCitizenshipTitle`: return workflowsHouseholdGroupsHouseholdStepsCitizenshipTitle;
    case `workflowsHouseholdGroupsHouseholdStepsDependentCareHelpText`: return workflowsHouseholdGroupsHouseholdStepsDependentCareHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsDependentCareHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsDependentCareHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsDependentCarePlaceholder`: return workflowsHouseholdGroupsHouseholdStepsDependentCarePlaceholder;
    case `workflowsHouseholdGroupsHouseholdStepsDependentCareQuestion`: return workflowsHouseholdGroupsHouseholdStepsDependentCareQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsDependentCareTitle`: return workflowsHouseholdGroupsHouseholdStepsDependentCareTitle;
    case `workflowsHouseholdGroupsHouseholdStepsDependentsHelpText`: return workflowsHouseholdGroupsHouseholdStepsDependentsHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsDependentsHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsDependentsHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsDependentsPlaceholder`: return workflowsHouseholdGroupsHouseholdStepsDependentsPlaceholder;
    case `workflowsHouseholdGroupsHouseholdStepsDependentsQuestion`: return workflowsHouseholdGroupsHouseholdStepsDependentsQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsDependentsTitle`: return workflowsHouseholdGroupsHouseholdStepsDependentsTitle;
    case `workflowsHouseholdGroupsHouseholdStepsHealthInsuranceHelpText`: return workflowsHouseholdGroupsHouseholdStepsHealthInsuranceHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsHealthInsuranceHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsHealthInsuranceHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsHealthInsuranceQuestion`: return workflowsHouseholdGroupsHouseholdStepsHealthInsuranceQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsHealthInsuranceTitle`: return workflowsHouseholdGroupsHouseholdStepsHealthInsuranceTitle;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsHelpText`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsPlaceholder`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsPlaceholder;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsQuestion`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsTitle`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsTitle;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoHelpText`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoHelpText;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoHelpTitle`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoHelpTitle;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoQuestion`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoQuestion;
    case `workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoTitle`: return workflowsHouseholdGroupsHouseholdStepsNonChildDependentsYesNoTitle;
    case `workflowsHouseholdGroupsHouseholdTitle`: return workflowsHouseholdGroupsHouseholdTitle;
    case `workflowsHouseholdIntroIllustration`: return workflowsHouseholdIntroIllustration;
    case `workflowsHouseholdIntroText`: return workflowsHouseholdIntroText;
    case `workflowsHouseholdOffboardingCitizenshipDescription`: return workflowsHouseholdOffboardingCitizenshipDescription;
    case `workflowsHouseholdOffboardingCitizenshipTitle`: return workflowsHouseholdOffboardingCitizenshipTitle;
    case `workflowsHouseholdTitle`: return workflowsHouseholdTitle;
    case `workflowsInputTextButtonSubmit`: return workflowsInputTextButtonSubmit;
    case `workflowsIntroButtonGetStarted`: return workflowsIntroButtonGetStarted;
    case `workflowsIntroProgress`: return workflowsIntroProgress;
    case `workflowsKycFixGroupTitle`: return workflowsKycFixGroupTitle;
    case `workflowsKycFixGroupsGovernmentIDUploadStepHelpText`: return workflowsKycFixGroupsGovernmentIDUploadStepHelpText;
    case `workflowsKycFixGroupsGovernmentIDUploadStepHelpTitle`: return workflowsKycFixGroupsGovernmentIDUploadStepHelpTitle;
    case `workflowsKycFixGroupsGovernmentIDUploadStepTitle`: return workflowsKycFixGroupsGovernmentIDUploadStepTitle;
    case `workflowsKycFixGroupsIssuingStateStepsStateHelpText`: return workflowsKycFixGroupsIssuingStateStepsStateHelpText;
    case `workflowsKycFixGroupsIssuingStateStepsStateHelpTitle`: return workflowsKycFixGroupsIssuingStateStepsStateHelpTitle;
    case `workflowsKycFixGroupsIssuingStateStepsStateQuestion`: return workflowsKycFixGroupsIssuingStateStepsStateQuestion;
    case `workflowsKycFixGroupsIssuingStateStepsStateTitle`: return workflowsKycFixGroupsIssuingStateStepsStateTitle;
    case `workflowsKycFixTitle`: return workflowsKycFixTitle;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsDocumentType`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsDocumentType;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsHelpText`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsHelpText;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsHelpTitle`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsHelpTitle;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsTitle`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsInvestmentStatementsTitle;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsDocumentType`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsDocumentType;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsHelpText`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsHelpText;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsHelpTitle`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsHelpTitle;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsTitle`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsRetirementStatementsTitle;
    case `workflowsMonthlyCheckinGroupsCheckinAdditionalDocsTitle`: return workflowsMonthlyCheckinGroupsCheckinAdditionalDocsTitle;
    case `workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewText`: return workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewText;
    case `workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewTitle`: return workflowsMonthlyCheckinGroupsCheckinBoostPlanReviewTitle;
    case `workflowsMonthlyCheckinGroupsCheckinChangesThisMonthHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinChangesThisMonthHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinChangesThisMonthHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinChangesThisMonthHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinChangesThisMonthPlaceholder`: return workflowsMonthlyCheckinGroupsCheckinChangesThisMonthPlaceholder;
    case `workflowsMonthlyCheckinGroupsCheckinChangesThisMonthText`: return workflowsMonthlyCheckinGroupsCheckinChangesThisMonthText;
    case `workflowsMonthlyCheckinGroupsCheckinChangesThisMonthTitle`: return workflowsMonthlyCheckinGroupsCheckinChangesThisMonthTitle;
    case `workflowsMonthlyCheckinGroupsCheckinEmploymentChangeHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinEmploymentChangeHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinEmploymentChangeHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinEmploymentChangeHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinEmploymentChangeTitle`: return workflowsMonthlyCheckinGroupsCheckinEmploymentChangeTitle;
    case `workflowsMonthlyCheckinGroupsCheckinLastPayStatementHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinLastPayStatementHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinLastPayStatementHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinLastPayStatementHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinLastPayStatementText`: return workflowsMonthlyCheckinGroupsCheckinLastPayStatementText;
    case `workflowsMonthlyCheckinGroupsCheckinLastPayStatementTitle`: return workflowsMonthlyCheckinGroupsCheckinLastPayStatementTitle;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthChangeInEmployment`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthChangeInEmployment;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthChangeOther`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthChangeOther;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHavingAChild`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHavingAChild;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthMaritalStatusChange`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthMaritalStatusChange;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthMovedYourResidence`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthMovedYourResidence;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthNone`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthNone;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthText`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthText;
    case `workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthTitle`: return workflowsMonthlyCheckinGroupsCheckinLifeEventsLastMonthTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionGrossPayTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionGrossPayTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemBasepay`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemBasepay;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemBoost`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemBoost;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemNetBasePay`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeLineItemNetBasePay;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTakeHomeTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemFederal`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemFederal;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemPosttax`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemPosttax;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemPretax`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemPretax;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemState`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesLineItemState;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownSectionTaxesTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownText`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownText;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyBreakdownTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesCustomSkipText`: return workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesCustomSkipText;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesPlaceholder`: return workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesPlaceholder;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesText`: return workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesText;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyExpensesTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationContentText`: return workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationContentText;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationContentTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationTitle`: return workflowsMonthlyCheckinGroupsCheckinMonthlyWaitForBoostCalculationTitle;
    case `workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceTitle`: return workflowsMonthlyCheckinGroupsCheckinReviewBoostPerformanceTitle;
    case `workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusHelpContentText`: return workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusHelpContentText;
    case `workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusHelpContentTitle`: return workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusHelpContentTitle;
    case `workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusTitle`: return workflowsMonthlyCheckinGroupsCheckinReviewLoyaltyStatusTitle;
    case `workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationHelpText`: return workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationHelpText;
    case `workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationHelpTitle`: return workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationHelpTitle;
    case `workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationTitle`: return workflowsMonthlyCheckinGroupsCheckinUpdateConnectionInformationTitle;
    case `workflowsMonthlyCheckinHeaderTitleApr`: return workflowsMonthlyCheckinHeaderTitleApr;
    case `workflowsMonthlyCheckinHeaderTitleAug`: return workflowsMonthlyCheckinHeaderTitleAug;
    case `workflowsMonthlyCheckinHeaderTitleDec`: return workflowsMonthlyCheckinHeaderTitleDec;
    case `workflowsMonthlyCheckinHeaderTitleFeb`: return workflowsMonthlyCheckinHeaderTitleFeb;
    case `workflowsMonthlyCheckinHeaderTitleJan`: return workflowsMonthlyCheckinHeaderTitleJan;
    case `workflowsMonthlyCheckinHeaderTitleJul`: return workflowsMonthlyCheckinHeaderTitleJul;
    case `workflowsMonthlyCheckinHeaderTitleJun`: return workflowsMonthlyCheckinHeaderTitleJun;
    case `workflowsMonthlyCheckinHeaderTitleMar`: return workflowsMonthlyCheckinHeaderTitleMar;
    case `workflowsMonthlyCheckinHeaderTitleMay`: return workflowsMonthlyCheckinHeaderTitleMay;
    case `workflowsMonthlyCheckinHeaderTitleNov`: return workflowsMonthlyCheckinHeaderTitleNov;
    case `workflowsMonthlyCheckinHeaderTitleOct`: return workflowsMonthlyCheckinHeaderTitleOct;
    case `workflowsMonthlyCheckinHeaderTitleSep`: return workflowsMonthlyCheckinHeaderTitleSep;
    case `workflowsMonthlyCheckinStubTitleApr`: return workflowsMonthlyCheckinStubTitleApr;
    case `workflowsMonthlyCheckinStubTitleAug`: return workflowsMonthlyCheckinStubTitleAug;
    case `workflowsMonthlyCheckinStubTitleDec`: return workflowsMonthlyCheckinStubTitleDec;
    case `workflowsMonthlyCheckinStubTitleFeb`: return workflowsMonthlyCheckinStubTitleFeb;
    case `workflowsMonthlyCheckinStubTitleJan`: return workflowsMonthlyCheckinStubTitleJan;
    case `workflowsMonthlyCheckinStubTitleJul`: return workflowsMonthlyCheckinStubTitleJul;
    case `workflowsMonthlyCheckinStubTitleJun`: return workflowsMonthlyCheckinStubTitleJun;
    case `workflowsMonthlyCheckinStubTitleMar`: return workflowsMonthlyCheckinStubTitleMar;
    case `workflowsMonthlyCheckinStubTitleMay`: return workflowsMonthlyCheckinStubTitleMay;
    case `workflowsMonthlyCheckinStubTitleNov`: return workflowsMonthlyCheckinStubTitleNov;
    case `workflowsMonthlyCheckinStubTitleOct`: return workflowsMonthlyCheckinStubTitleOct;
    case `workflowsMonthlyCheckinStubTitleSep`: return workflowsMonthlyCheckinStubTitleSep;
    case `workflowsMonthlyCheckinTileCTAButton`: return workflowsMonthlyCheckinTileCTAButton;
    case `workflowsMonthlyCheckinTileLabel`: return workflowsMonthlyCheckinTileLabel;
    case `workflowsMonthlyCheckinTileSkipButton`: return workflowsMonthlyCheckinTileSkipButton;
    case `workflowsMonthlyCheckinTileTitle`: return workflowsMonthlyCheckinTileTitle;
    case `workflowsMonthlyCheckinWorkflowTextApr`: return workflowsMonthlyCheckinWorkflowTextApr;
    case `workflowsMonthlyCheckinWorkflowTextAug`: return workflowsMonthlyCheckinWorkflowTextAug;
    case `workflowsMonthlyCheckinWorkflowTextDec`: return workflowsMonthlyCheckinWorkflowTextDec;
    case `workflowsMonthlyCheckinWorkflowTextFeb`: return workflowsMonthlyCheckinWorkflowTextFeb;
    case `workflowsMonthlyCheckinWorkflowTextJan`: return workflowsMonthlyCheckinWorkflowTextJan;
    case `workflowsMonthlyCheckinWorkflowTextJul`: return workflowsMonthlyCheckinWorkflowTextJul;
    case `workflowsMonthlyCheckinWorkflowTextJun`: return workflowsMonthlyCheckinWorkflowTextJun;
    case `workflowsMonthlyCheckinWorkflowTextMar`: return workflowsMonthlyCheckinWorkflowTextMar;
    case `workflowsMonthlyCheckinWorkflowTextMay`: return workflowsMonthlyCheckinWorkflowTextMay;
    case `workflowsMonthlyCheckinWorkflowTextNov`: return workflowsMonthlyCheckinWorkflowTextNov;
    case `workflowsMonthlyCheckinWorkflowTextOct`: return workflowsMonthlyCheckinWorkflowTextOct;
    case `workflowsMonthlyCheckinWorkflowTextSep`: return workflowsMonthlyCheckinWorkflowTextSep;
    case `workflowsOffboardingHelpContentMessage`: return workflowsOffboardingHelpContentMessage;
    case `workflowsOffboardingHelpContentTitle`: return workflowsOffboardingHelpContentTitle;
    case `workflowsOffboardingPill`: return workflowsOffboardingPill;
    case `workflowsOffboardingTitle`: return workflowsOffboardingTitle;
    case `workflowsPayboostSetupGroupsGridCardTitle`: return workflowsPayboostSetupGroupsGridCardTitle;
    case `workflowsPayboostSetupGroupsPayrollTitle`: return workflowsPayboostSetupGroupsPayrollTitle;
    case `workflowsPayboostSetupTitle`: return workflowsPayboostSetupTitle;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceAMonth`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceAMonth;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceAWeek`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceAWeek;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceEveryTwoWeeks`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOnceEveryTwoWeeks;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOther`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesOther;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesTwiceAMonth`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyChoicesTwiceAMonth;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyHelpText`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyHelpText;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyHelpTitle`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyHelpTitle;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyQuestion`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyQuestion;
    case `workflowsPayrollDataEntryPayPeriodGroupPayFrequencyTitle`: return workflowsPayrollDataEntryPayPeriodGroupPayFrequencyTitle;
    case `workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesBegininningTitle`: return workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesBegininningTitle;
    case `workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesEndingTitle`: return workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesEndingTitle;
    case `workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesHelpText`: return workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesHelpText;
    case `workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesHelpTitle`: return workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesHelpTitle;
    case `workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesQuestion`: return workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesQuestion;
    case `workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesTitle`: return workflowsPayrollDataEntryPayPeriodGroupPayPeriodDatesTitle;
    case `workflowsPayrollDataEntryPayPeriodGroupTitle`: return workflowsPayrollDataEntryPayPeriodGroupTitle;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeFederal`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeFederal;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeGross`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeGross;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeHelpText`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeHelpText;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeHelpTitle`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeHelpTitle;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeMedicare`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeMedicare;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeNet`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeNet;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeQuestion`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeQuestion;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeSocialSecurity`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeSocialSecurity;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeState`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeState;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeTitle`: return workflowsPayrollDataEntryPayPeriodIncomeGroupPayPeriodIncomeTitle;
    case `workflowsPayrollDataEntryPayPeriodIncomeGroupTitle`: return workflowsPayrollDataEntryPayPeriodIncomeGroupTitle;
    case `workflowsPayrollDataEntryPaystubDocRequestPrompt`: return workflowsPayrollDataEntryPaystubDocRequestPrompt;
    case `workflowsPayrollDataEntryPaystubDocsDocumentType`: return workflowsPayrollDataEntryPaystubDocsDocumentType;
    case `workflowsPayrollDataEntryPaystubDocsGroupTitle`: return workflowsPayrollDataEntryPaystubDocsGroupTitle;
    case `workflowsPayrollDataEntryPaystubDocsHelpContentText`: return workflowsPayrollDataEntryPaystubDocsHelpContentText;
    case `workflowsPayrollDataEntryPaystubDocsHelpContentTitle`: return workflowsPayrollDataEntryPaystubDocsHelpContentTitle;
    case `workflowsPayrollDataEntryPaystubDocsTitle`: return workflowsPayrollDataEntryPaystubDocsTitle;
    case `workflowsPayrollDataEntryTitle`: return workflowsPayrollDataEntryTitle;
    case `workflowsPayrollDataEntryValidationWarningMessage`: return workflowsPayrollDataEntryValidationWarningMessage;
    case `workflowsPayrollDataEntryYTDIncomeGroupTitle`: return workflowsPayrollDataEntryYTDIncomeGroupTitle;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeFederal`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeFederal;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeGross`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeGross;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeHelpText`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeHelpText;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeHelpTitle`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeHelpTitle;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeMedicare`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeMedicare;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeNet`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeNet;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeQuestion`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeQuestion;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeSocialSecurity`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeSocialSecurity;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeState`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeState;
    case `workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeTitle`: return workflowsPayrollDataEntryYTDIncomeGroupYTDIncomeTitle;
    case `workflowsPersonalGroupsAccountInformationStepsPushPermissionsDescription`: return workflowsPersonalGroupsAccountInformationStepsPushPermissionsDescription;
    case `workflowsPersonalGroupsAccountInformationStepsPushPermissionsText`: return workflowsPersonalGroupsAccountInformationStepsPushPermissionsText;
    case `workflowsPersonalGroupsAccountInformationStepsPushPermissionsTitle`: return workflowsPersonalGroupsAccountInformationStepsPushPermissionsTitle;
    case `workflowsPersonalGroupsAccountInformationStepsSignupEmailHelpMessage`: return workflowsPersonalGroupsAccountInformationStepsSignupEmailHelpMessage;
    case `workflowsPersonalGroupsAccountInformationStepsSignupEmailHelpTitle`: return workflowsPersonalGroupsAccountInformationStepsSignupEmailHelpTitle;
    case `workflowsPersonalGroupsAccountInformationStepsSignupEmailPlaceholder`: return workflowsPersonalGroupsAccountInformationStepsSignupEmailPlaceholder;
    case `workflowsPersonalGroupsAccountInformationStepsSignupEmailPrompt`: return workflowsPersonalGroupsAccountInformationStepsSignupEmailPrompt;
    case `workflowsPersonalGroupsAccountInformationStepsSignupEmailTitle`: return workflowsPersonalGroupsAccountInformationStepsSignupEmailTitle;
    case `workflowsPersonalGroupsAccountInformationStepsSignupPasswordHelpMessage`: return workflowsPersonalGroupsAccountInformationStepsSignupPasswordHelpMessage;
    case `workflowsPersonalGroupsAccountInformationStepsSignupPasswordHelpTitle`: return workflowsPersonalGroupsAccountInformationStepsSignupPasswordHelpTitle;
    case `workflowsPersonalGroupsAccountInformationStepsSignupPasswordPlaceholder`: return workflowsPersonalGroupsAccountInformationStepsSignupPasswordPlaceholder;
    case `workflowsPersonalGroupsAccountInformationStepsSignupPasswordPrompt`: return workflowsPersonalGroupsAccountInformationStepsSignupPasswordPrompt;
    case `workflowsPersonalGroupsAccountInformationStepsSignupPasswordQuestionOnly`: return workflowsPersonalGroupsAccountInformationStepsSignupPasswordQuestionOnly;
    case `workflowsPersonalGroupsAccountInformationStepsSignupPasswordRequirements`: return workflowsPersonalGroupsAccountInformationStepsSignupPasswordRequirements;
    case `workflowsPersonalGroupsAccountInformationStepsSignupPasswordTitle`: return workflowsPersonalGroupsAccountInformationStepsSignupPasswordTitle;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTerms`: return workflowsPersonalGroupsAccountInformationStepsSignupTerms;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTermsHelpMessage`: return workflowsPersonalGroupsAccountInformationStepsSignupTermsHelpMessage;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTermsHelpTitle`: return workflowsPersonalGroupsAccountInformationStepsSignupTermsHelpTitle;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkPrivacy`: return workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkPrivacy;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkPrivacyUrl`: return workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkPrivacyUrl;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkToc`: return workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkToc;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkTocUrl`: return workflowsPersonalGroupsAccountInformationStepsSignupTermsLinkTocUrl;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTermsPrompt`: return workflowsPersonalGroupsAccountInformationStepsSignupTermsPrompt;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTermsTitle`: return workflowsPersonalGroupsAccountInformationStepsSignupTermsTitle;
    case `workflowsPersonalGroupsAccountInformationStepsSignupTitle`: return workflowsPersonalGroupsAccountInformationStepsSignupTitle;
    case `workflowsPersonalGroupsAccountInformationTitle`: return workflowsPersonalGroupsAccountInformationTitle;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesConsultant`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesConsultant;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesInterestDividend`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesInterestDividend;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesInvestmentStock`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesInvestmentStock;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesOnDemand`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesOnDemand;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesRentalProperty`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesRentalProperty;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesRetired`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesRetired;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesSalaryHourly`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesSalaryHourly;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesSocialSecurity`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeChoicesSocialSecurity;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeHelpText`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeHelpText;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeHelpTitle`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeQuestion`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeQuestion;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeSkip`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeSkip;
    case `workflowsPersonalGroupsPersonalStepsAdditionalIncomeTitle`: return workflowsPersonalGroupsPersonalStepsAdditionalIncomeTitle;
    case `workflowsPersonalGroupsPersonalStepsAttributionChoicesChime`: return workflowsPersonalGroupsPersonalStepsAttributionChoicesChime;
    case `workflowsPersonalGroupsPersonalStepsAttributionChoicesFriend`: return workflowsPersonalGroupsPersonalStepsAttributionChoicesFriend;
    case `workflowsPersonalGroupsPersonalStepsAttributionChoicesSearch`: return workflowsPersonalGroupsPersonalStepsAttributionChoicesSearch;
    case `workflowsPersonalGroupsPersonalStepsAttributionChoicesSocialMedia`: return workflowsPersonalGroupsPersonalStepsAttributionChoicesSocialMedia;
    case `workflowsPersonalGroupsPersonalStepsAttributionChoicesSquare`: return workflowsPersonalGroupsPersonalStepsAttributionChoicesSquare;
    case `workflowsPersonalGroupsPersonalStepsAttributionHelpText`: return workflowsPersonalGroupsPersonalStepsAttributionHelpText;
    case `workflowsPersonalGroupsPersonalStepsAttributionHelpTitle`: return workflowsPersonalGroupsPersonalStepsAttributionHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsAttributionQuestion`: return workflowsPersonalGroupsPersonalStepsAttributionQuestion;
    case `workflowsPersonalGroupsPersonalStepsAttributionSkip`: return workflowsPersonalGroupsPersonalStepsAttributionSkip;
    case `workflowsPersonalGroupsPersonalStepsAttributionTitle`: return workflowsPersonalGroupsPersonalStepsAttributionTitle;
    case `workflowsPersonalGroupsPersonalStepsDobHelpText`: return workflowsPersonalGroupsPersonalStepsDobHelpText;
    case `workflowsPersonalGroupsPersonalStepsDobHelpTitle`: return workflowsPersonalGroupsPersonalStepsDobHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsDobQuestion`: return workflowsPersonalGroupsPersonalStepsDobQuestion;
    case `workflowsPersonalGroupsPersonalStepsDobTitle`: return workflowsPersonalGroupsPersonalStepsDobTitle;
    case `workflowsPersonalGroupsPersonalStepsEmploymentVerificationHelpText`: return workflowsPersonalGroupsPersonalStepsEmploymentVerificationHelpText;
    case `workflowsPersonalGroupsPersonalStepsEmploymentVerificationHelpTitle`: return workflowsPersonalGroupsPersonalStepsEmploymentVerificationHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsEmploymentVerificationQuestion`: return workflowsPersonalGroupsPersonalStepsEmploymentVerificationQuestion;
    case `workflowsPersonalGroupsPersonalStepsEmploymentVerificationTitle`: return workflowsPersonalGroupsPersonalStepsEmploymentVerificationTitle;
    case `workflowsPersonalGroupsPersonalStepsFilingStatusChoicesMarriedJointly`: return workflowsPersonalGroupsPersonalStepsFilingStatusChoicesMarriedJointly;
    case `workflowsPersonalGroupsPersonalStepsFilingStatusChoicesMarriedSeparately`: return workflowsPersonalGroupsPersonalStepsFilingStatusChoicesMarriedSeparately;
    case `workflowsPersonalGroupsPersonalStepsFilingStatusChoicesSingle`: return workflowsPersonalGroupsPersonalStepsFilingStatusChoicesSingle;
    case `workflowsPersonalGroupsPersonalStepsFilingStatusHelpText`: return workflowsPersonalGroupsPersonalStepsFilingStatusHelpText;
    case `workflowsPersonalGroupsPersonalStepsFilingStatusHelpTitle`: return workflowsPersonalGroupsPersonalStepsFilingStatusHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsFilingStatusQuestion`: return workflowsPersonalGroupsPersonalStepsFilingStatusQuestion;
    case `workflowsPersonalGroupsPersonalStepsFilingStatusTitle`: return workflowsPersonalGroupsPersonalStepsFilingStatusTitle;
    case `workflowsPersonalGroupsPersonalStepsFirstNameHelpText`: return workflowsPersonalGroupsPersonalStepsFirstNameHelpText;
    case `workflowsPersonalGroupsPersonalStepsFirstNameHelpTitle`: return workflowsPersonalGroupsPersonalStepsFirstNameHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsFirstNamePlaceholder`: return workflowsPersonalGroupsPersonalStepsFirstNamePlaceholder;
    case `workflowsPersonalGroupsPersonalStepsFirstNameQuestion`: return workflowsPersonalGroupsPersonalStepsFirstNameQuestion;
    case `workflowsPersonalGroupsPersonalStepsFirstNameTitle`: return workflowsPersonalGroupsPersonalStepsFirstNameTitle;
    case `workflowsPersonalGroupsPersonalStepsInterstitial1Description`: return workflowsPersonalGroupsPersonalStepsInterstitial1Description;
    case `workflowsPersonalGroupsPersonalStepsInterstitial1Text`: return workflowsPersonalGroupsPersonalStepsInterstitial1Text;
    case `workflowsPersonalGroupsPersonalStepsInterstitial2Description`: return workflowsPersonalGroupsPersonalStepsInterstitial2Description;
    case `workflowsPersonalGroupsPersonalStepsInterstitial2Text`: return workflowsPersonalGroupsPersonalStepsInterstitial2Text;
    case `workflowsPersonalGroupsPersonalStepsInterstitial3Description`: return workflowsPersonalGroupsPersonalStepsInterstitial3Description;
    case `workflowsPersonalGroupsPersonalStepsInterstitial3Text`: return workflowsPersonalGroupsPersonalStepsInterstitial3Text;
    case `workflowsPersonalGroupsPersonalStepsInterstitial4Description`: return workflowsPersonalGroupsPersonalStepsInterstitial4Description;
    case `workflowsPersonalGroupsPersonalStepsInterstitial4Text`: return workflowsPersonalGroupsPersonalStepsInterstitial4Text;
    case `workflowsPersonalGroupsPersonalStepsInterstitialCompletionText`: return workflowsPersonalGroupsPersonalStepsInterstitialCompletionText;
    case `workflowsPersonalGroupsPersonalStepsInterstitialTitle`: return workflowsPersonalGroupsPersonalStepsInterstitialTitle;
    case `workflowsPersonalGroupsPersonalStepsLastNameHelpText`: return workflowsPersonalGroupsPersonalStepsLastNameHelpText;
    case `workflowsPersonalGroupsPersonalStepsLastNameHelpTitle`: return workflowsPersonalGroupsPersonalStepsLastNameHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsLastNamePlaceholder`: return workflowsPersonalGroupsPersonalStepsLastNamePlaceholder;
    case `workflowsPersonalGroupsPersonalStepsLastNameQuestion`: return workflowsPersonalGroupsPersonalStepsLastNameQuestion;
    case `workflowsPersonalGroupsPersonalStepsLastNameTitle`: return workflowsPersonalGroupsPersonalStepsLastNameTitle;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesConsultant`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesConsultant;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesOnDemand`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesOnDemand;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesOther`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesOther;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesRetired`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesRetired;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesSalaryHourly`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeChoicesSalaryHourly;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeHelpText`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeHelpText;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeHelpTitle`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeQuestion`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeQuestion;
    case `workflowsPersonalGroupsPersonalStepsPrimaryIncomeTitle`: return workflowsPersonalGroupsPersonalStepsPrimaryIncomeTitle;
    case `workflowsPersonalGroupsPersonalStepsSocialHandleHelpText`: return workflowsPersonalGroupsPersonalStepsSocialHandleHelpText;
    case `workflowsPersonalGroupsPersonalStepsSocialHandleHelpTitle`: return workflowsPersonalGroupsPersonalStepsSocialHandleHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsSocialHandlePlaceholderText`: return workflowsPersonalGroupsPersonalStepsSocialHandlePlaceholderText;
    case `workflowsPersonalGroupsPersonalStepsSocialHandleQuestion`: return workflowsPersonalGroupsPersonalStepsSocialHandleQuestion;
    case `workflowsPersonalGroupsPersonalStepsSocialHandleSkipText`: return workflowsPersonalGroupsPersonalStepsSocialHandleSkipText;
    case `workflowsPersonalGroupsPersonalStepsSocialHandleTitle`: return workflowsPersonalGroupsPersonalStepsSocialHandleTitle;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateAlabama`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateAlabama;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateAlaska`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateAlaska;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateArizona`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateArizona;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateArkansas`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateArkansas;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateCalifornia`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateCalifornia;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateColorado`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateColorado;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateConnecticut`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateConnecticut;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateDelaware`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateDelaware;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateDistrictOfColumbia`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateDistrictOfColumbia;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateFlorida`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateFlorida;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateGeorgia`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateGeorgia;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateHawaii`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateHawaii;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateIdaho`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateIdaho;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateIllinois`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateIllinois;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateIndiana`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateIndiana;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateIowa`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateIowa;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateKansas`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateKansas;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateKentucky`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateKentucky;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateLouisiana`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateLouisiana;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateMaine`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateMaine;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateMaryland`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateMaryland;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateMassachusetts`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateMassachusetts;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateMichigan`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateMichigan;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateMinnesota`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateMinnesota;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateMississippi`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateMississippi;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateMissouri`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateMissouri;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateMontana`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateMontana;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateNebraska`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateNebraska;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateNevada`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateNevada;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewHampshire`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewHampshire;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewJersey`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewJersey;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewMexico`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewMexico;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewYork`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateNewYork;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateNorthCarolina`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateNorthCarolina;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateNorthDakota`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateNorthDakota;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateOhio`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateOhio;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateOklahoma`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateOklahoma;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateOregon`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateOregon;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStatePennsylvania`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStatePennsylvania;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateRhodeIsland`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateRhodeIsland;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateSouthCarolina`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateSouthCarolina;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateSouthDakota`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateSouthDakota;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateTennessee`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateTennessee;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateTexas`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateTexas;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateUtah`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateUtah;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateVermont`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateVermont;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateVirginia`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateVirginia;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateWashington`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateWashington;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateWestVirginia`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateWestVirginia;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateWisconsin`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateWisconsin;
    case `workflowsPersonalGroupsPersonalStepsStateChoices00USStateWyoming`: return workflowsPersonalGroupsPersonalStepsStateChoices00USStateWyoming;
    case `workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryAmericanSamoa`: return workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryAmericanSamoa;
    case `workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryGuam`: return workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryGuam;
    case `workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryNorthernMarianaIslands`: return workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryNorthernMarianaIslands;
    case `workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryPuertoRico`: return workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryPuertoRico;
    case `workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryVirginIslands`: return workflowsPersonalGroupsPersonalStepsStateChoices10TerritoryVirginIslands;
    case `workflowsPersonalGroupsPersonalStepsStateChoices20Other`: return workflowsPersonalGroupsPersonalStepsStateChoices20Other;
    case `workflowsPersonalGroupsPersonalStepsStateHelpText`: return workflowsPersonalGroupsPersonalStepsStateHelpText;
    case `workflowsPersonalGroupsPersonalStepsStateHelpTitle`: return workflowsPersonalGroupsPersonalStepsStateHelpTitle;
    case `workflowsPersonalGroupsPersonalStepsStateQuestion`: return workflowsPersonalGroupsPersonalStepsStateQuestion;
    case `workflowsPersonalGroupsPersonalStepsStateTitle`: return workflowsPersonalGroupsPersonalStepsStateTitle;
    case `workflowsPersonalGroupsPersonalTitle`: return workflowsPersonalGroupsPersonalTitle;
    case `workflowsPersonalOffboardingMinorDescription`: return workflowsPersonalOffboardingMinorDescription;
    case `workflowsPersonalOffboardingMinorTitle`: return workflowsPersonalOffboardingMinorTitle;
    case `workflowsPersonalOffboardingUnemployedDescription`: return workflowsPersonalOffboardingUnemployedDescription;
    case `workflowsPersonalOffboardingUnemployedTitle`: return workflowsPersonalOffboardingUnemployedTitle;
    case `workflowsPersonalOffboardingUnsupportedFilingTypeDescription`: return workflowsPersonalOffboardingUnsupportedFilingTypeDescription;
    case `workflowsPersonalOffboardingUnsupportedFilingTypeTitle`: return workflowsPersonalOffboardingUnsupportedFilingTypeTitle;
    case `workflowsPersonalOffboardingUnsupportedPrimaryIncomeDescription`: return workflowsPersonalOffboardingUnsupportedPrimaryIncomeDescription;
    case `workflowsPersonalOffboardingUnsupportedPrimaryIncomeTitle`: return workflowsPersonalOffboardingUnsupportedPrimaryIncomeTitle;
    case `workflowsPersonalOffboardingUnsupportedStateDescription`: return workflowsPersonalOffboardingUnsupportedStateDescription;
    case `workflowsPersonalOffboardingUnsupportedStateTitle`: return workflowsPersonalOffboardingUnsupportedStateTitle;
    case `workflowsPersonalTitle`: return workflowsPersonalTitle;
    case `workflowsPhoneNumberStepTitle`: return workflowsPhoneNumberStepTitle;
    case `workflowsPreSelectBankAccountGroupsTitle`: return workflowsPreSelectBankAccountGroupsTitle;
    case `workflowsPreSelectBankAccountNoBankFound`: return workflowsPreSelectBankAccountNoBankFound;
    case `workflowsPreSelectBankAccountNoBankFoundContinueCTA`: return workflowsPreSelectBankAccountNoBankFoundContinueCTA;
    case `workflowsPreSelectBankAccountSearchPlaceholder`: return workflowsPreSelectBankAccountSearchPlaceholder;
    case `workflowsPreSelectBankAccountTitle`: return workflowsPreSelectBankAccountTitle;
    case `workflowsSubsequentAdvanceGroupsTitle`: return workflowsSubsequentAdvanceGroupsTitle;
    case `workflowsSubsequentAdvanceTitle`: return workflowsSubsequentAdvanceTitle;
    case `workflowsUnemploymentApplicationGroupsAnnualPayHelpContentTitle`: return workflowsUnemploymentApplicationGroupsAnnualPayHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsAnnualPayHelpContextText`: return workflowsUnemploymentApplicationGroupsAnnualPayHelpContextText;
    case `workflowsUnemploymentApplicationGroupsAnnualPayPlaceholder`: return workflowsUnemploymentApplicationGroupsAnnualPayPlaceholder;
    case `workflowsUnemploymentApplicationGroupsAnnualPayQuestion`: return workflowsUnemploymentApplicationGroupsAnnualPayQuestion;
    case `workflowsUnemploymentApplicationGroupsAnnualPayTitle`: return workflowsUnemploymentApplicationGroupsAnnualPayTitle;
    case `workflowsUnemploymentApplicationGroupsAreYouADirectorHelpContentTitle`: return workflowsUnemploymentApplicationGroupsAreYouADirectorHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsAreYouADirectorHelpContextText`: return workflowsUnemploymentApplicationGroupsAreYouADirectorHelpContextText;
    case `workflowsUnemploymentApplicationGroupsAreYouADirectorQuestion`: return workflowsUnemploymentApplicationGroupsAreYouADirectorQuestion;
    case `workflowsUnemploymentApplicationGroupsAreYouADirectorTitle`: return workflowsUnemploymentApplicationGroupsAreYouADirectorTitle;
    case `workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusHelpContentText`: return workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusHelpContentText;
    case `workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusHelpContentTitle`: return workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusQuestion`: return workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusQuestion;
    case `workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusTitle`: return workflowsUnemploymentApplicationGroupsCurrentEmploymentStatusTitle;
    case `workflowsUnemploymentApplicationGroupsDobHelpText`: return workflowsUnemploymentApplicationGroupsDobHelpText;
    case `workflowsUnemploymentApplicationGroupsDobHelpTitle`: return workflowsUnemploymentApplicationGroupsDobHelpTitle;
    case `workflowsUnemploymentApplicationGroupsDobQuestion`: return workflowsUnemploymentApplicationGroupsDobQuestion;
    case `workflowsUnemploymentApplicationGroupsEmployedFor3MonthsHelpContentText`: return workflowsUnemploymentApplicationGroupsEmployedFor3MonthsHelpContentText;
    case `workflowsUnemploymentApplicationGroupsEmployedFor3MonthsHelpContentTitle`: return workflowsUnemploymentApplicationGroupsEmployedFor3MonthsHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsEmployedFor3MonthsQuestion`: return workflowsUnemploymentApplicationGroupsEmployedFor3MonthsQuestion;
    case `workflowsUnemploymentApplicationGroupsEmployedFor3MonthsTitle`: return workflowsUnemploymentApplicationGroupsEmployedFor3MonthsTitle;
    case `workflowsUnemploymentApplicationGroupsEmployerCityHelpText`: return workflowsUnemploymentApplicationGroupsEmployerCityHelpText;
    case `workflowsUnemploymentApplicationGroupsEmployerCityHelpTitle`: return workflowsUnemploymentApplicationGroupsEmployerCityHelpTitle;
    case `workflowsUnemploymentApplicationGroupsEmployerCityPlaceholder`: return workflowsUnemploymentApplicationGroupsEmployerCityPlaceholder;
    case `workflowsUnemploymentApplicationGroupsEmployerCityQuestion`: return workflowsUnemploymentApplicationGroupsEmployerCityQuestion;
    case `workflowsUnemploymentApplicationGroupsEmployerCityTitle`: return workflowsUnemploymentApplicationGroupsEmployerCityTitle;
    case `workflowsUnemploymentApplicationGroupsEmployerIsNonProfitHelpContentText`: return workflowsUnemploymentApplicationGroupsEmployerIsNonProfitHelpContentText;
    case `workflowsUnemploymentApplicationGroupsEmployerIsNonProfitHelpContentTitle`: return workflowsUnemploymentApplicationGroupsEmployerIsNonProfitHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsEmployerIsNonProfitQuestion`: return workflowsUnemploymentApplicationGroupsEmployerIsNonProfitQuestion;
    case `workflowsUnemploymentApplicationGroupsEmployerIsNonProfitTitle`: return workflowsUnemploymentApplicationGroupsEmployerIsNonProfitTitle;
    case `workflowsUnemploymentApplicationGroupsEmployerNameHelpText`: return workflowsUnemploymentApplicationGroupsEmployerNameHelpText;
    case `workflowsUnemploymentApplicationGroupsEmployerNameHelpTitle`: return workflowsUnemploymentApplicationGroupsEmployerNameHelpTitle;
    case `workflowsUnemploymentApplicationGroupsEmployerNamePlaceholder`: return workflowsUnemploymentApplicationGroupsEmployerNamePlaceholder;
    case `workflowsUnemploymentApplicationGroupsEmployerNameQuestion`: return workflowsUnemploymentApplicationGroupsEmployerNameQuestion;
    case `workflowsUnemploymentApplicationGroupsEmployerNameTitle`: return workflowsUnemploymentApplicationGroupsEmployerNameTitle;
    case `workflowsUnemploymentApplicationGroupsEmployerStateHelpText`: return workflowsUnemploymentApplicationGroupsEmployerStateHelpText;
    case `workflowsUnemploymentApplicationGroupsEmployerStateHelpTitle`: return workflowsUnemploymentApplicationGroupsEmployerStateHelpTitle;
    case `workflowsUnemploymentApplicationGroupsEmployerStateQuestion`: return workflowsUnemploymentApplicationGroupsEmployerStateQuestion;
    case `workflowsUnemploymentApplicationGroupsEmployerStateTitle`: return workflowsUnemploymentApplicationGroupsEmployerStateTitle;
    case `workflowsUnemploymentApplicationGroupsExpectingTerminationHelpContentTitle`: return workflowsUnemploymentApplicationGroupsExpectingTerminationHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsExpectingTerminationHelpContextText`: return workflowsUnemploymentApplicationGroupsExpectingTerminationHelpContextText;
    case `workflowsUnemploymentApplicationGroupsExpectingTerminationQuestion`: return workflowsUnemploymentApplicationGroupsExpectingTerminationQuestion;
    case `workflowsUnemploymentApplicationGroupsExpectingTerminationTitle`: return workflowsUnemploymentApplicationGroupsExpectingTerminationTitle;
    case `workflowsUnemploymentApplicationGroupsFirstNameHelpText`: return workflowsUnemploymentApplicationGroupsFirstNameHelpText;
    case `workflowsUnemploymentApplicationGroupsFirstNameHelpTitle`: return workflowsUnemploymentApplicationGroupsFirstNameHelpTitle;
    case `workflowsUnemploymentApplicationGroupsFirstNamePlaceholder`: return workflowsUnemploymentApplicationGroupsFirstNamePlaceholder;
    case `workflowsUnemploymentApplicationGroupsFirstNameQuestion`: return workflowsUnemploymentApplicationGroupsFirstNameQuestion;
    case `workflowsUnemploymentApplicationGroupsFirstNameTitle`: return workflowsUnemploymentApplicationGroupsFirstNameTitle;
    case `workflowsUnemploymentApplicationGroupsFulltimeHelpContentTitle`: return workflowsUnemploymentApplicationGroupsFulltimeHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsFulltimeHelpContextText`: return workflowsUnemploymentApplicationGroupsFulltimeHelpContextText;
    case `workflowsUnemploymentApplicationGroupsFulltimeQuestion`: return workflowsUnemploymentApplicationGroupsFulltimeQuestion;
    case `workflowsUnemploymentApplicationGroupsFulltimeTitle`: return workflowsUnemploymentApplicationGroupsFulltimeTitle;
    case `workflowsUnemploymentApplicationGroupsHowItWorksIntroHelpText`: return workflowsUnemploymentApplicationGroupsHowItWorksIntroHelpText;
    case `workflowsUnemploymentApplicationGroupsHowItWorksIntroHelpTitle`: return workflowsUnemploymentApplicationGroupsHowItWorksIntroHelpTitle;
    case `workflowsUnemploymentApplicationGroupsHowItWorksIntroTitle`: return workflowsUnemploymentApplicationGroupsHowItWorksIntroTitle;
    case `workflowsUnemploymentApplicationGroupsImpendingLayoffHelpContentTitle`: return workflowsUnemploymentApplicationGroupsImpendingLayoffHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsImpendingLayoffHelpContextText`: return workflowsUnemploymentApplicationGroupsImpendingLayoffHelpContextText;
    case `workflowsUnemploymentApplicationGroupsImpendingLayoffQuestion`: return workflowsUnemploymentApplicationGroupsImpendingLayoffQuestion;
    case `workflowsUnemploymentApplicationGroupsImpendingLayoffTitle`: return workflowsUnemploymentApplicationGroupsImpendingLayoffTitle;
    case `workflowsUnemploymentApplicationGroupsIntroHelpText`: return workflowsUnemploymentApplicationGroupsIntroHelpText;
    case `workflowsUnemploymentApplicationGroupsIntroHelpTitle`: return workflowsUnemploymentApplicationGroupsIntroHelpTitle;
    case `workflowsUnemploymentApplicationGroupsIntroTitle`: return workflowsUnemploymentApplicationGroupsIntroTitle;
    case `workflowsUnemploymentApplicationGroupsJobTitlePlaceholder`: return workflowsUnemploymentApplicationGroupsJobTitlePlaceholder;
    case `workflowsUnemploymentApplicationGroupsJobTitleQuestion`: return workflowsUnemploymentApplicationGroupsJobTitleQuestion;
    case `workflowsUnemploymentApplicationGroupsJobTitleStepHelpText`: return workflowsUnemploymentApplicationGroupsJobTitleStepHelpText;
    case `workflowsUnemploymentApplicationGroupsJobTitleStepHelpTitle`: return workflowsUnemploymentApplicationGroupsJobTitleStepHelpTitle;
    case `workflowsUnemploymentApplicationGroupsJobTitleStepTitle`: return workflowsUnemploymentApplicationGroupsJobTitleStepTitle;
    case `workflowsUnemploymentApplicationGroupsJobTypeChoicesIndependentContractor`: return workflowsUnemploymentApplicationGroupsJobTypeChoicesIndependentContractor;
    case `workflowsUnemploymentApplicationGroupsJobTypeChoicesSalaryHourly`: return workflowsUnemploymentApplicationGroupsJobTypeChoicesSalaryHourly;
    case `workflowsUnemploymentApplicationGroupsJobTypeChoicesSeasonalWorker`: return workflowsUnemploymentApplicationGroupsJobTypeChoicesSeasonalWorker;
    case `workflowsUnemploymentApplicationGroupsJobTypeChoicesSelfEmployed`: return workflowsUnemploymentApplicationGroupsJobTypeChoicesSelfEmployed;
    case `workflowsUnemploymentApplicationGroupsJobTypeHelpContentTitle`: return workflowsUnemploymentApplicationGroupsJobTypeHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsJobTypeHelpContextText`: return workflowsUnemploymentApplicationGroupsJobTypeHelpContextText;
    case `workflowsUnemploymentApplicationGroupsJobTypeQuestion`: return workflowsUnemploymentApplicationGroupsJobTypeQuestion;
    case `workflowsUnemploymentApplicationGroupsJobTypeTitle`: return workflowsUnemploymentApplicationGroupsJobTypeTitle;
    case `workflowsUnemploymentApplicationGroupsLastNameHelpText`: return workflowsUnemploymentApplicationGroupsLastNameHelpText;
    case `workflowsUnemploymentApplicationGroupsLastNameHelpTitle`: return workflowsUnemploymentApplicationGroupsLastNameHelpTitle;
    case `workflowsUnemploymentApplicationGroupsLastNamePlaceholder`: return workflowsUnemploymentApplicationGroupsLastNamePlaceholder;
    case `workflowsUnemploymentApplicationGroupsLastNameQuestion`: return workflowsUnemploymentApplicationGroupsLastNameQuestion;
    case `workflowsUnemploymentApplicationGroupsLastNameTitle`: return workflowsUnemploymentApplicationGroupsLastNameTitle;
    case `workflowsUnemploymentApplicationGroupsOperatingFor3YearsHelpContentText`: return workflowsUnemploymentApplicationGroupsOperatingFor3YearsHelpContentText;
    case `workflowsUnemploymentApplicationGroupsOperatingFor3YearsHelpContentTitle`: return workflowsUnemploymentApplicationGroupsOperatingFor3YearsHelpContentTitle;
    case `workflowsUnemploymentApplicationGroupsOperatingFor3YearsQuestion`: return workflowsUnemploymentApplicationGroupsOperatingFor3YearsQuestion;
    case `workflowsUnemploymentApplicationGroupsOperatingFor3YearsTitle`: return workflowsUnemploymentApplicationGroupsOperatingFor3YearsTitle;
    case `workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesOnDemand`: return workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesOnDemand;
    case `workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSalaryHourly`: return workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSalaryHourly;
    case `workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSeasonalWorker`: return workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSeasonalWorker;
    case `workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSelfEmployed`: return workflowsUnemploymentApplicationGroupsPrimaryIncomeChoicesSelfEmployed;
    case `workflowsUnemploymentApplicationGroupsStepsDobTitle`: return workflowsUnemploymentApplicationGroupsStepsDobTitle;
    case `workflowsUnemploymentApplicationGroupsTitle`: return workflowsUnemploymentApplicationGroupsTitle;
    case `workflowsUnemploymentApplicationTitle`: return workflowsUnemploymentApplicationTitle;
    case `zendeskGenericFailureMessage`: return zendeskGenericFailureMessage;
    default: return null;
    }
}
